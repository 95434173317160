import React, {useEffect, useState} from 'react';
import './style.css';
import PageTitleCard from '../../CommonComponents/PageTitleCard';
import PageHeading from '../../CommonComponents/PageHeading';
import EventListingPbssChapters from '../../CommonComponents/EventListing/EventListingPbssChapters';
import Event2Listing from '../../CommonComponents/EventListing2.0';
import EventInfoCard from '../../CommonComponents/EventHighlights';
import SponsorListing from '../../CommonComponents/SponsorListing';
import {useSelector, useDispatch} from 'react-redux';
import ChapterPageheroText from '../../CommonComponents/ChapterPageHeroText';
import {Col, Row} from 'antd';
import PastEventsListing from '../../CommonComponents/PastEventsListing';
import CommonWantToSeeAdsListing from '../../CommonComponents/CommonWantToSeeAdsListing';
import RecentOpeningsJobs from '../../CommonComponents/RecentOpenings/jobs';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import {
  DefaultPaginationValuesForPastEvents,
  LoadMoreSize,
  createSelectionOptionsUtil,
  sponsorDataMarquee,
} from '../../../Util/commonUtility';
import {
  getAllPastEvents,
  getAllUserEvents,
  getEventsByChapters,
} from '../../../Store/Actions/events';

import moment from 'moment';
import {useParams} from 'react-router-dom';
import {getAllChapters, getAllEventTypes} from '../../../Store/Actions';
import {ApplicationString} from '../../../Localization/Locales/en';
import CommonHeroText from '../../CommonComponents/CommonHeroText';
import PbssInMap from '../../CommonComponents/PbssInCommonMap';
import ChapterBannerComponent from '../../CommonComponents/ChapterBannerComponent';
const PbssChapterComponent = ({
  allApprovedJobsData,
  eventData,
  handleNext,
  handlePrev,
  handleYearFilter,
  pagination,
  totalEventCount,
  handleLoadMore,
  pastEventData,
  handleChapterFilter,
  handleEventTypeFilter,
  filterBydate,
  chaptersList,
  eventTypeList,
  countLoadMoreData,
  filteredEvents,
  selectedEventTypeValue,
  selectedChapterValue,
  selectedDateValue,
  dateToggle,
  dateOrderHandler,
  handlePaginationChange,
  normalizedName,
  totalPastEventsPages,
  selectedYearValue,
}) => {
  let dispatch = useDispatch();
  const {chapterName} = useParams();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const getChapterWelcomeLine = (chapter) => {
    switch (chapter) {
      case 'Boston':
        return {
          message: (
            <>
              Welcome to the{' '}
              <label className='chapter-welcomeline'>Boston</label>{' '}
              Pharmaceutical & BioScience Society, a chapter of PBSS
              International
            </>
          ),
          hideSubheading: false,
        };
      case 'Vancouver':
        return {
          message: (
            <>
              Welcome to the{' '}
              <label className='chapter-welcomeline'>Vancouver</label>{' '}
              Pharmaceutical & BioScience Society, a chapter of PBSS
              International
            </>
          ),
          hideSubheading: false,
        };
      case 'Korea':
        return {
          message: (
            <>
              Welcome to the{' '}
              <label className='chapter-welcomeline'>Korea</label>{' '}
              Pharmaceutical & BioScience Society, a chapter of PBSS
              International
            </>
          ),
          hideSubheading: false,
        };
      default:
        return {
          message:
            'Welcome to the Pharmaceutical & BioScience Society International',
          hideSubheading: true, // Set to true to hide the subheading
        };
    }
  };
  const {message, hideSubheading} = getChapterWelcomeLine(normalizedName);

  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRowChapter'>
        <Col span={24}>
          <ChapterBannerComponent
            chaptersList={chaptersList}
            pageHeadingComponent={
              <ChapterPageheroText
                heading={message}
                chapterHide={hideSubheading}
                subheading={hideSubheading ? normalizedName : ''}
                bgColor='#f6f9fc'
              />
            }
          />
        </Col>
        <Col span={24}>
          <PbssInMap mapSize={300} chaptersList={chaptersList} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Event2Listing
            // allApprovedJobsData={allApprovedJobsData}
            // allApprovedAdsData={allApprovedAdsData}
            // handleNext={handleNext}
            // handlePrev={handlePrev}
            // handleNextAds={handleNextAds}
            // handlePrevAds={handlePrevAds}
            filteredEvents={filteredEvents}
            handleChapterFilter={handleChapterFilter}
            handleEventTypeFilter={handleEventTypeFilter}
            filterBydate={filterBydate}
            isChapSelect={true}
            chaptersList={chaptersList}
            eventTypeList={eventTypeList}
            countLoadMoreData={countLoadMoreData}
            totalEventCount={totalEventCount}
            handleLoadMore={handleLoadMore}
            selectedChapterValue={selectedChapterValue}
            selectedEventTypeValue={selectedEventTypeValue}
            selectedDateValue={selectedDateValue}
            dateToggle={dateToggle}
            dateOrderHandler={dateOrderHandler}
          />
        </Col>
        <Col span={24}>
          <PastEventsListing
            handlePaginationChange={(page, pageSize) => {
              handlePaginationChange(page, pageSize);
            }}
            pastEventData={pastEventData}
            pagination={pagination}
            handleYearFilter={handleYearFilter}
            totalPastEventsPages={totalPastEventsPages}
            selectedYearValue={selectedYearValue}
            usedForBio={true}
          />
        </Col>
        <Col span={24}>
          <SponsorListing sponsorData={sponsorDataMarquee} addScroll={true} />
        </Col>

        <Col span={24}>
          <RecentOpeningsJobs
            title='Current Job Openings'
            buttonName='View Details'
            data={allApprovedJobsData}
            flex={true}
            handleNext={handleNext}
            handlePrev={handlePrev}
            home={true}
          />
        </Col>
      </Row>
      {/* <PageTitleCard
        // imageUrl='https://images.unsplash.com/photo-1524836613456-ddf4af23321c?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        pageTitle={`Welcome to ${
          eventData?.length > 0 ? eventData[0]?.eventChapter : ''
        }`}
        description=' working in diverse organizations such as the biotechnology and pharmaceutical industries.'
        buttonText='Learn More'
      />
      <PageHeading
        border={false}
        title={`PBSS - ${
          eventData?.length > 0 ? eventData[0]?.eventChapter : ''
        }`}
        description={'Pharmaceutical & Bioscience Society '}
      /> */}

      {/* <EventListingPbssChapters
        handleLoadMore={handleLoadMore}
        eventData={eventData}
        totalEventCount={totalEventCount}
      /> */}

      {/* <EventInfoCard
        date='Sept 12 | Tuesday'
        eventName='Clinical Pharmacology Event | 2023'
        description='Clinical pharmacology is an integral component of drug development...'
        location='Conference at SF- Bay'
        imageSrc='/rectangle-107@2x.png'
      /> */}
    </React.Fragment>
  );
};
export default PbssChapterComponent;

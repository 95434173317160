import React from 'react';
import './style.css';

const FormTitle = (props) => {
  return (
    <div className='login-title'>
      <p>{props.title}</p>
      <span>
        {props.description} | <label>{props.additionalLabel}</label>
      </span>
    </div>
  );
};

export default FormTitle;

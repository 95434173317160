import React from 'react';
import PaymentHistoryContainer from '../../../Containers/UserContainer/PaymentHistory';

const PaymentHistoryScreen = () => {
  return (
    <React.Fragment>
      <PaymentHistoryContainer />
    </React.Fragment>
  );
};

export default PaymentHistoryScreen;

import React, {useEffect, useState} from 'react';
import StemOutReachComponent from '../../../Components/UserComponents/StemOutreach';
import {getStemById} from '../../../Store/Actions/stem';
import {useDispatch, useSelector} from 'react-redux';

const StemOutReachContainer = () => {
  const dispatch = useDispatch();
  const [stemData, setStemData] = useState();
  const {allStems} = useSelector(({stem}) => stem);

  useEffect(() => {
    if (allStems) {
      setStemData(allStems?.data?.stem[0]);
    }
  }, [allStems]);

  useEffect(() => {
    fetchStemById();
  }, []);

  const fetchStemById = () => {
    dispatch(getStemById());
  };

  return (
    <div>
      <StemOutReachComponent stemData={stemData} />
    </div>
  );
};

export default StemOutReachContainer;

import * as React from 'react';
const DownArrowSvg = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={17}
    height={19}
    viewBox='0 0 11 13'
    fill='none'
    {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.50001 0.5C5.87972 0.5 6.18751 0.79848 6.18751 1.16667V10.2239L9.82638 6.69528C10.0949 6.43494 10.5302 6.43494 10.7986 6.69528C11.0671 6.95561 11.0671 7.37774 10.7986 7.63808L5.98614 12.3047C5.71767 12.5651 5.28235 12.5651 5.01388 12.3047L0.201362 7.63808C-0.0671207 7.37774 -0.0671207 6.95561 0.201362 6.69528C0.469852 6.43494 0.90515 6.43494 1.17364 6.69528L4.81251 10.2239V1.16667C4.81251 0.79848 5.1203 0.5 5.50001 0.5Z'
      fill='#2F57A4'
    />
  </svg>
);
export default DownArrowSvg;

import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import './styles.css';
import {Tag} from 'antd';
import {formatTimeZoneString} from './../../../Util/commonUtility';

const TimezoneDisplay = ({selectedTimeZone}) => {
  const timeZone = moment.tz.zone(selectedTimeZone)?.abbr(360);
  return (
    <div className='timezoneContainer'>
      {selectedTimeZone && (
        <div className='timeZone-warper'>
          <div className='timezoneLabel'>Time in {selectedTimeZone} :</div>
          <Tag className='timeZone-tag'>
            <div className='timezoneValue'>
              {/* {moment
                .tz(new Date(), selectedTimeZone)
                .format('D MMM YYYY h:mm A - z')} */}
              {moment
                .tz(new Date(), selectedTimeZone)
                .format('D MMM YYYY h:mm A')}
              - {formatTimeZoneString(timeZone)}
            </div>
          </Tag>
        </div>
      )}
    </div>
  );
};

export default TimezoneDisplay;

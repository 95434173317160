import {GET_STEM, UPDATE_STEM} from '../../Constant/ActionTypes';

const initialState = {
  allStems: false,
  isUpdateStem: false,
};
const StemReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STEM:
      return {
        ...state,
        allStems: action.payload,
      };
    case UPDATE_STEM:
      return {
        ...state,
        isUpdateStem: true,
        allStems: false,
      };
    default:
      return state;
  }
};

export default StemReducer;

import React, {useEffect} from 'react';
import CustomCollapse from '../../CommonComponents/CommonAccordian';
import PageTitleCard from '../../CommonComponents/PageTitleCard';
import PageHeading from '../../CommonComponents/PageHeading';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import CommonInfoCard from '../../CommonComponents/CommonPolicyCard';
import VendorPolicy from './StaticCard';
import vendorImg from '../../../Assets/sponsorImg.png';
import {Col, Row} from 'antd';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import {ApplicationString} from '../../../Localization/Locales/en';

const SponsorPolicyComponent = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        <Col span={24}>
          <SponsorOptionPageHeroText
            title={ApplicationString['userSponsorPolicyPage.title']}
            subTitle={ApplicationString['userSponsorPolicyPage.subtitle']}
          />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <CommonInfoCard
              content={<VendorPolicy />}
              title={'Refund Policy For vendors'}
              isVendor={false}
              imgSrc={
                'https://images.unsplash.com/photo-1560523160-754a9e25c68f?auto=format&fit=crop&q=80&w=2036&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
              }
            />
            <CustomCollapse />
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SponsorPolicyComponent;

import React, {useEffect, useState} from 'react';
import {
  getAllApprovedAds,
  getAllApprovedJobs,
  getAllChapters,
  getAllEventTypes,
  getAllEventTypesByQuery,
} from '../../../Store/Actions';
import {useDispatch, useSelector} from 'react-redux';
import PbssChapterComponent from '../../../Components/UserComponents/PbssChapter';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {
  DefaultPaginationValuesForPastEvents,
  LoadMoreSize,
  createSelectionOptionsUtil,
} from './../../../Util/commonUtility';
import {
  getAllPastEvents,
  getAllUserEvents,
  getEventsByChapters,
} from '../../../Store/Actions/events';
import selectedStarSvg from '../../../Assets/selectedStarSvg.svg';
import mapStarMarker from '../../../Assets/svgs/star.svg';
import pinSvg from '../../../Assets/svgs/pin.svg';
import selectedPinSvg from '../../../Assets/svgs/selectedPin.svg';

const PbssChapterContainer = () => {
  const {chapterName} = useParams();
  const {getApprovedJobs} = useSelector(({jobs}) => jobs);
  const {getApprovedAds} = useSelector(({ads}) => ads);
  const [allApprovedJobsData, setAllApprovedJobsData] = useState([]);
  const [allApprovedAdsData, setAllApprovedAdsData] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(2);
  const [startIndexAds, setStartIndexAds] = useState(0);
  const [endIndexAds, setEndIndexAds] = useState(3);
  const [totalJobs, setTotalJobs] = useState(0);
  const [totalAds, setTotalAds] = useState(0);
  const {allEvents, allUserEvents} = useSelector(({events}) => events);
  const [eventData, setEventData] = useState([]);
  const [getAllEventData, setGetAllEventData] = useState();
  const {chapterId} = useParams();
  const [selectedChapterValue, setSelectedChapterValue] = useState('');
  const [selectedEventTypeValue, setSelectedEventTypeValue] = useState('');
  const [selectedDateValue, setSelectedDateValue] = useState('');
  const [eventDataChapters, setEventDataChapters] = useState([]);
  const {getEventsDataByChapters} = useSelector(({events}) => events);
  const [getAllEventDataByChapter, setGetAllEventDataByChapter] = useState();
  const {allChapters} = useSelector(({chapters}) => chapters);
  const {allEventTypesQueryData} = useSelector(({eventTypes}) => eventTypes);
  const [chaptersList, setChaptersList] = useState([]);
  const [eventTypesList, setEventTypesList] = useState([]);
  const [countData, setCountData] = useState(LoadMoreSize);
  const [countLoadMoreData, setCountLoadMoreData] = useState(2);
  const [totalEventCount, setTotalEventCount] = useState(0);
  const [normalizedName, setNormalizedName] = useState(chapterName);
  const {defaultChapterValue} = useSelector(({chapters}) => chapters);
  const [loggedIn, setLoggedIn] = useState(false);
  const [pastEventData, setPastEventData] = useState();
  const [getAllPastEventData, setGetAllPastEventData] = useState();
  const {allPastEvents} = useSelector(({events}) => events);
  const [selectedYearValue, setSelectedYearValue] = React.useState('');
  const [pagination, setPagination] = React.useState({
    page: DefaultPaginationValuesForPastEvents.PAGE,
    pagecount: DefaultPaginationValuesForPastEvents.PAGE_SIZE,
    totalPages: DefaultPaginationValuesForPastEvents.TOTAL_PAGES,
  });
  let dispatch = useDispatch();
  const [dateToggle, setdateToggle] = useState(false);
  const [totalPastEventsPages, setTotalPastEventsPages] = useState();
  const [fetchedChapterIds, setFetchedChapterIds] = useState([]);

  const dateOrderHandler = () => {
    if (dateToggle) {
      dispatch(
        getAllUserEvents(selectedEventTypeValue, selectedChapterValue, 'desc'),
      );
    } else {
      dispatch(
        getAllUserEvents(selectedEventTypeValue, selectedChapterValue, 'asc'),
      );
    }
    setdateToggle(!dateToggle);
  };
  useEffect(() => {
    dispatch(
      getAllUserEvents(
        selectedChapterValue,
        selectedEventTypeValue,
        selectedDateValue,
      ),
    );
  }, []);

  // Map custom tooltip for chapter selection
  const allMapStars = document.querySelectorAll('.jvm-marker');
  const filteredChapter = allChapters?.data?.chapters.filter(
    (chap) => chap?.is_visible_on_map,
  );

  let mapTooltip;
  allMapStars.forEach((star) => {
    star.addEventListener('mouseover', (e) => {
      const tip = document.querySelector('.jvm-tooltip');
      if (!tip) {
        mapTooltip = document.createElement('div');
        mapTooltip.classList.add('map-tooltip');
        mapTooltip.style.left = `${e.clientX - 40}px`;
        mapTooltip.style.top = `${e.clientY - 30}px`;
        mapTooltip.textContent =
          filteredChapter[e.target.getAttribute('data-index')]?.name;
        document.body.appendChild(mapTooltip);
      }
    });

    star.addEventListener('mouseleave', (e) => {
      if (mapTooltip) {
        document.body.removeChild(mapTooltip);
        mapTooltip = null;
      }
    });
  });

  // Highlight the selected chapter on the map
  useEffect(() => {
    let activeChapIndex = filteredChapter?.findIndex(
      (chap) => chap?.name === normalizedName,
    );
    let mapStar;
    const mouseEnterHandler = (e) => {
      e.target.setAttribute('href', selectedPinSvg);
    };
    const mouseLeaveHandler = (e) => {
      e.target.setAttribute('href', selectedPinSvg);
    };

    const intervalId = setInterval(() => {
      mapStar = document.querySelector(
        `.jvm-marker[data-index="${activeChapIndex}"]`,
      );
      if (mapStar) {
        mapStar.setAttribute('href', selectedPinSvg);
        mapStar?.addEventListener('mouseenter', mouseEnterHandler);
        mapStar?.addEventListener('mouseleave', mouseLeaveHandler);
        clearInterval(intervalId);
      }
    }, 50);

    return () => {
      activeChapIndex = null;
      mapStar?.setAttribute('href', pinSvg);
      mapStar?.removeEventListener('mouseenter', mouseEnterHandler);
      mapStar?.removeEventListener('mouseleave', mouseLeaveHandler);
    };
  }, [normalizedName, allChapters]);

  const handleNext = () => {
    if (endIndex + 1 < totalJobs) {
      setStartIndex(endIndex + 1);
      setEndIndex(endIndex + 3);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 3);
      setEndIndex(endIndex - 3);
    }
  };
  const handleNextAds = () => {
    if (endIndexAds + 1 < totalAds) {
      setStartIndexAds(endIndexAds + 1);
      setEndIndexAds(endIndexAds + 4);
    }
  };

  const handlePrevAds = () => {
    if (startIndexAds > 0) {
      setStartIndexAds(startIndexAds - 4);
      setEndIndexAds(endIndexAds - 4);
    }
  };

  useEffect(() => {
    if (getApprovedJobs) {
      const Data = getApprovedJobs?.data?.jobs?.slice(startIndex, endIndex + 1);
      setAllApprovedJobsData(Data);
      setTotalJobs(getApprovedJobs?.data?.totalCount);
    } else {
      dispatch(getAllApprovedJobs());
    }
  }, [getApprovedJobs, startIndex, endIndex]);

  useEffect(() => {
    if (getApprovedAds) {
      const Data = getApprovedAds?.data?.ads?.slice(
        startIndexAds,
        endIndexAds + 1,
      );
      setAllApprovedAdsData(Data);
      setTotalAds(getApprovedAds?.data?.totalCount);
    } else {
      dispatch(getAllApprovedAds());
    }
  }, [getApprovedAds, startIndexAds, endIndexAds]);

  // useEffect(() => {
  //   const id = allChapters?.data?.chapters?.find(
  //     (item) => item.name == chapterName,
  //   )?.id;
  //   if (id) {
  //     if (
  //       getEventsDataByChapters?.data?.events.length > 0
  //         ? getEventsDataByChapters?.data?.events[0]?.local_chapter_id ===
  //           parseInt(id)
  //         : getEventsDataByChapters
  //     ) {
  //       const Data =
  //         getEventsDataByChapters?.data?.events.length > 0
  //           ? getEventsDataByChapters?.data?.events?.slice(0, countData)
  //           : getEventsDataByChapters?.data?.events;
  //       setGetAllEventDataByChapter(Data);
  //       setTotalEventCount(getEventsDataByChapters?.data?.totalCount);
  //     } else {
  //       dispatch(getEventsByChapters(id));
  //     }
  //   }
  // }, [chapterName, allChapters, countData, getEventsDataByChapters, dispatch]);

  // useEffect(() => {
  //   if (getAllEventDataByChapter) {
  //     const updatedEventData =
  //       getAllEventDataByChapter.length > 0
  //         ? getAllEventDataByChapter.map((item) => {
  //             return {
  //               key: item.id,
  //               id: item?.id,
  //               sponsorshipAvailable: `${
  //                 Array.isArray(item.sponsors) ? item.sponsors.length : 0
  //               } Sponsorship Available`,
  //               speakers: Array.isArray(item.speakers)
  //                 ? item.speakers.map((speakers) => {
  //                     return (
  //                       <div key={speakers}>
  //                         {speakers.first_name + ' ' + speakers.last_name}
  //                       </div>
  //                     );
  //                   })
  //                 : '',
  //               date: item.date,
  //               eventName: item.name,
  //               registerText: 'Register Now',
  //               location: item.place,
  //               price: '295',
  //               category: 'PBSS International Boston - PBSS',
  //               eventType: item?.event_type_name,
  //               eventChapter: item?.local_chapter_name,
  //             };
  //           })
  //         : null;
  //     setEventData(updatedEventData);
  //   }
  // }, [getAllEventDataByChapter]);

  // const handleLoadMore = () => {
  //   // dispatch(getAllEvents());
  //   setCountData(countData * 2);
  // };

  //old useEfect For calling the Chapter Listing
  //  useEffect(() => {
  //    const id = allChapters?.data?.chapters?.find(
  //      (item) => item.normalized_name == chapterName,
  //    )?.id;
  //    setNormalizedName(
  //      allChapters?.data?.chapters?.find(
  //        (item) => item.normalized_name == chapterName,
  //      )?.name,
  //    );
  //    if (
  //      allUserEvents?.data?.events.length > 0
  //        ? allUserEvents?.data?.events[0]?.local_chapter_id === parseInt(id)
  //        : allUserEvents
  //    ) {
  //      const Data = allUserEvents?.data?.events.slice(0, countData);
  //      setGetAllEventData(Data);
  //      setTotalEventCount(allUserEvents?.data?.totalCount);
  //    } else {
  //      dispatch(getAllUserEvents(selectedEventTypeValue, id, selectedDateValue));
  //    }
  //  }, [allUserEvents, countData]);

  useEffect(() => {
    const id = allChapters?.data?.chapters?.find(
      (item) => item.normalized_name === chapterName,
    )?.id;

    setNormalizedName(
      allChapters?.data?.chapters?.find(
        (item) => item.normalized_name === chapterName,
      )?.name,
    );
    if (!fetchedChapterIds.includes(id)) {
      dispatch(getAllUserEvents(selectedEventTypeValue, id, selectedDateValue));
      setFetchedChapterIds([...fetchedChapterIds, id]);
    } else {
      const Data = allUserEvents?.data?.events;
      setGetAllEventData(Data);
      setTotalEventCount(allUserEvents?.data?.totalCount);
    }
    return () => {
      setFetchedChapterIds(
        fetchedChapterIds.filter((chapterId) => chapterId !== id),
      );
    };
  }, [
    allUserEvents,
    countData,
    chapterName,
    selectedEventTypeValue,
    selectedDateValue,
    allChapters,
  ]);

  useEffect(() => {
    if (getAllEventData) {
      const updatedEventData = getAllEventData.map((item) => {
        return {
          key: item.id,
          status: item.status,
          sponsorshipAvailable: `${
            Array.isArray(item.sponsors) ? item.sponsors.length : 0
          } Sponsorship Available`,
          speakers: Array.isArray(item.speakers)
            ? item.speakers.map((speakers) => {
                return speakers.first_name + ' ' + speakers.last_name + ',';
              })
            : '',
          date: moment(item.date).utcOffset(0).format('MMM DD'),
          dateObject: item.formattedDateTime,
          eventName: item.name,
          eventDetails: item.details,
          location: item.place,
          registerText:
            Number(item.status) === 2 ? 'Registration Closed' : 'Register Now',
          price: '295',
          category: 'PBSS International Boston - PBSS',
          eventType: item.event_type_name,
          eventChapter: item?.local_chapter_name,
        };
      });
      setEventData(updatedEventData);
    }
  }, [getAllEventData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [chapterName]);

  useEffect(() => {
    if (allChapters) {
      const visibleChapters = allChapters?.data?.chapters?.filter((chapter) => {
        return chapter?.is_visible_on_map === true;
      });
      const chapters = createSelectionOptionsUtil(
        visibleChapters,
        'id',
        'name',
      );
      setChaptersList([...chapters, {value: 'clear', label: 'Clear Filter'}]);
    } else {
      dispatch(getAllChapters());
    }
  }, [allChapters, dispatch]);

  useEffect(() => {
    if (allEventTypesQueryData?.forMaster) {
      const eventTypes = createSelectionOptionsUtil(
        allEventTypesQueryData?.forMaster?.data?.eventTypes,
        'id',
        'name',
      );
      setEventTypesList([{value: '', label: 'Clear Filter'}, ...eventTypes]);
    } else {
      dispatch(
        getAllEventTypesByQuery('', '', '', {
          page: 1,
          pageCount: 100,
        }),
      );
    }
  }, [allEventTypesQueryData?.forMaster, dispatch]);

  useEffect(() => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      setLoggedIn(true);
    }
  }, []);

  const handlePaginationChange = (current, pageSize) => {
    setPagination({
      ...pagination,
      page: current,
    });
  };
  const handleYearFilter = (value) => {
    if (value === 'clear') {
      dispatch(getAllPastEvents(pagination, ''));
      setSelectedYearValue('');
    } else {
      dispatch(getAllPastEvents(pagination, value));
      setSelectedYearValue(value);
    }
  };

  useEffect(() => {
    if (allPastEvents) {
      dispatch(getAllPastEvents(pagination, selectedYearValue));
    }
  }, [pagination.page]);

  useEffect(() => {
    if (allPastEvents) {
      const Data = allPastEvents?.data?.events;
      setGetAllPastEventData(Data);
      setPagination({
        ...pagination,
        page: allPastEvents?.data?.currentPage,
        totalPages: allPastEvents?.data?.totalPage,
        totalCount: allPastEvents?.data?.totalCount,
      });
      setTotalPastEventsPages(allPastEvents?.data?.totalPage);
    } else {
      dispatch(getAllPastEvents(pagination, selectedYearValue));
    }
  }, [allPastEvents]);

  useEffect(() => {
    if (getAllPastEventData) {
      const updatedPastEventData = getAllPastEventData.map((item) => {
        return {
          key: item.id,
          name: item.name,
          event_date: moment(item.event_start_date_time)
            .utcOffset(0)
            .format('DD MMM YYYY'),
          event_type: item.event_type_name,
          chapter_name: item?.local_chapter_normalized_name
            ? item?.local_chapter_normalized_name
            : item.local_chapter_name,
        };
      });
      setPastEventData(updatedPastEventData);
    }
  }, [getAllPastEventData]);

  // useEffect(() => {
  //   if (getEventsDataByChapters) {
  //     const Data = getEventsDataByChapters?.data?.events?.slice(0, countData);
  //     setGetAllEventDataByChapter(Data);
  //   }
  // }, [getEventsDataByChapters, countData]);

  // useEffect(() => {
  //   if (loggedIn && defaultChapterValue !== false) {
  //     dispatch(getEventsByChapters(defaultChapterValue));
  //   }
  // }, [loggedIn, dispatch, defaultChapterValue]);

  // useEffect(() => {
  //   if (getAllEventDataByChapter) {
  //     const updatedEventData =
  //       getAllEventDataByChapter.length > 0
  //         ? getAllEventDataByChapter.map((item) => {
  //             return {
  //               key: item.id,
  //               sponsorshipAvailable: `${
  //                 Array.isArray(item.sponsors) ? item.sponsors.length : 0
  //               } Sponsorship Available`,
  //               speakers: Array.isArray(item.speakers)
  //                 ? item.speakers.map((speakers) => {
  //                     return (
  //                       <div key={speakers}>
  //                         {speakers.first_name + ' ' + speakers.last_name}
  //                       </div>
  //                     );
  //                   })
  //                 : '',
  //               date: moment(item.date).utcOffset(0).format('MMM DD'),
  //               dateObject: item.formattedDateTime,
  //               eventName: item.name,
  //               eventDetails: item.details,
  //               location: item.place,
  //               registerText: 'Register Now',
  //               location: item.place,
  //               price: '295',
  //               category: 'PBSS International Boston - PBSS',
  //               eventType: item?.event_type_name,
  //               eventChapter: item?.chapter_name,
  //             };
  //           })
  //         : null;
  //     setEventDataChapters(updatedEventData);
  //   }
  // }, [getAllEventDataByChapter]);
  const [selectedCategory, setSelectedCategory] = useState('All Events');
  const [activeButton, setActiveButton] = useState(null);

  const filteredEvents = eventData;
  // selectedCategory === 'All Events' ? eventData : eventDataChapters;

  const handleChapterFilter = (value) => {
    if (value === 'clear') {
      dispatch(getAllUserEvents(selectedEventTypeValue, '', selectedDateValue));
      setSelectedChapterValue('');
    } else {
      dispatch(
        getAllUserEvents(selectedEventTypeValue, value, selectedDateValue),
        setSelectedChapterValue(value),
      );
    }
    setCountLoadMoreData(2);
  };
  const handleEventTypeFilter = (value) => {
    if (value === 'clear') {
      dispatch(getAllUserEvents('', selectedChapterValue, selectedDateValue));
      setSelectedEventTypeValue('');
    } else {
      dispatch(
        getAllUserEvents(value, selectedChapterValue, selectedDateValue),
        setSelectedEventTypeValue(value),
      );
    }
    setCountLoadMoreData(2);
  };

  const handleLoadMore = () => {
    setCountData(countData * 2);
    setCountLoadMoreData(countLoadMoreData * 2);
  };

  const filterBydate = (selectedDate) => {
    setCountLoadMoreData(2);
    const StartDate = Array.isArray(selectedDate)
      ? moment(new Date(selectedDate[0])).format('YYYY-MM-DD')
      : '';
    const EndDate = Array.isArray(selectedDate)
      ? moment(new Date(selectedDate[1])).format('YYYY-MM-DD')
      : '';
    const FullDate = StartDate + ',' + EndDate;
    setSelectedDateValue(FullDate);
    dispatch(
      getAllUserEvents(selectedEventTypeValue, selectedChapterValue, FullDate),
    );
  };

  return (
    <PbssChapterComponent
      allApprovedJobsData={allApprovedJobsData}
      allApprovedAdsData={allApprovedAdsData}
      handleNext={handleNext}
      handlePrev={handlePrev}
      handleNextAds={handleNextAds}
      handlePrevAds={handlePrevAds}
      eventData={eventData}
      totalEventCount={totalEventCount}
      handleLoadMore={handleLoadMore}
      normalizedName={normalizedName}
      pastEventData={pastEventData}
      handleChapterFilter={handleChapterFilter}
      handleEventTypeFilter={handleEventTypeFilter}
      filterBydate={filterBydate}
      chaptersList={chaptersList}
      eventTypeList={eventTypesList}
      countLoadMoreData={countLoadMoreData}
      filteredEvents={filteredEvents}
      pagination={pagination}
      handleYearFilter={handleYearFilter}
      handlePaginationChange={(page, pageSize) => {
        handlePaginationChange(page, pageSize);
      }}
      selectedEventTypeValue={selectedEventTypeValue}
      selectedChapterValue={selectedChapterValue}
      selectedDateValue={selectedDateValue}
      selectedYearValue={selectedYearValue}
      dateToggle={dateToggle}
      dateOrderHandler={dateOrderHandler}
      totalPastEventsPages={totalPastEventsPages}
    />
  );
};

export default PbssChapterContainer;

import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import {Button, Col, Row} from 'antd';
import NavigationComponent from '../CommonBackButton';
import {ApplicationString} from '../../../Localization/Locales/en';
import {RollbackOutlined} from '@ant-design/icons';
import './style.css';
import {useEffect} from 'react';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import stripeLogo from '../../../Assets/stripe-logo.png';
import {
  fetchError,
  fetchSuccess,
  getAllChapters,
  postJobPaymentSuccess,
  resetJobPayment,
  showMessage,
} from '../../../Store/Actions';
import {resetSpecialPayment} from '../../../Store/Actions/specialPayment';
import useStripeSubmit from '../../../hooks/useStripeSubmit';
import {
  formatCurrency,
  formatCurrencyByLocalChapter,
} from '../../../Util/commonUtility';

const CommonPaymentCheckoutForm = ({
  dispatchSuccess,
  goBackURL,
  price,
  DataId,
  resetPayment,
  successUrl,
  currencyDetails = false,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const localChapterData = useSelector(
    (state) => state.chapters.localChapterData,
  );
  useEffect(() => {
    if (!localChapterData) {
      dispatch(getAllChapters());
    }
  }, [localChapterData]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  const handleSubmit = useStripeSubmit(
    successUrl,
    successUrl,
    (id, intent) => dispatchSuccess(id, intent),
    DataId,
    () => {
      dispatch(resetPayment());
    },
  );

  const returnButtonhandler = () => {
    navigate(goBackURL);
    dispatch(resetPayment());
  };

  return (
    <Row gutter={[0, 28]}>
      {goBackURL && (
        <Col span={24}>
          <div className='btn-main-container'>
            <button onClick={returnButtonhandler}>
              {' '}
              <span className='btn-icon-box'>
                <RollbackOutlined />
              </span>
              {
                ApplicationString[
                  'specialPaymentFormComponent.returnButton.returnToNormalForm'
                ]
              }{' '}
            </button>
          </div>
        </Col>
      )}
      <Col span={24}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}>
          <Row gutter={[0, 0]} className='payment-checkout-form'>
            <Col span={24}>
              <div className='payment-form-container'>
                <div className='payment-form-heading-container common-grid'>
                  <div className='payment-form-heading'>
                    <label>Pay with credit card</label>
                  </div>
                  {/* <div className='stripe-logo'>
                    <img src={stripeLogo} alt='stripe' />
                  </div> */}
                </div>
                <PaymentElement />
              </div>
            </Col>
            <Col span={24}>
              <div className={'specialPaymentConfimAndPayContainer'}>
                <span>
                  {currencyDetails
                    ? formatCurrencyByLocalChapter(price, currencyDetails)
                    : formatCurrencyByLocalChapter(price, localChapterData)}
                </span>
                <button
                  className='confirmation-action-button confirmation-action-button-specialPayment'
                  disabled={!stripe}>
                  Confirm & Pay
                </button>
              </div>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};
//CommonPaymentCheckoutFormContainer

export default CommonPaymentCheckoutForm;

import {
  DELETE_EVENTS_BY_ID,
  DUPLICATE_EVENTS_BY_ID,
  GET_ALL_EVENTS,
  GET_ALL_EVENTS_BY_QUERY,
  GET_EVENTS_BY_CHAPTERS,
  GET_EVENTS_BY_ID,
  POST_EVENTS,
  UPDATE_EVENTS_BY_ID,
  GET_ALL_MY_EVENTS,
  UPDATE_EVENT_STATUS,
  PAYMENT_EVENT_REGISTRATION_BY_EVENT_ID,
  UPDATE_EVENT_REFUND_STATUS,
  GET_ALL_USER_EVENTS,
  GET_ALL_PAST_EVENTS,
  GET_ALL_EVENTS_BY_QUERY_SEARCH,
  RESET_EVENT_EDIT_DATA,
  GET_ALL_MY_EVENTS_CART,
} from '../../Constant/ActionTypes';

const initialState = {
  allEvents: false,
  allUserEvents: false,
  allPastEvents: false,
  allMyEvents: false,
  allMyEventsCard: false,
  getEventsData: false,
  isCreateEvents: false,
  isUpdateEvents: false,
  allEventsQueryData: {
    forSearch: false,
    forMaster: false,
  },
  getEventsDataByUser: false,
  getEventsDataByChapters: false,
  isDuplicateEvents: false,
  isPaymentUpdateEventsStatus: false,
  isUpdateEventsStatus: false,
  isRefundEvents: false,
};

const EventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EVENTS:
      return {
        ...state,
        allEvents: action.payload,
        isCreateEvents: false,
        isUpdateEvents: false,
        isDuplicateEvents: false,
        isUpdateEventsStatus: false,
        isRefundEvents: false,
      };
    case GET_ALL_USER_EVENTS:
      return {
        ...state,
        allUserEvents: action.payload,
        isCreateEvents: false,
        isUpdateEvents: false,
        isDuplicateEvents: false,
        isUpdateEventsStatus: false,
        isRefundEvents: false,
      };
    case GET_ALL_PAST_EVENTS:
      return {
        ...state,
        allPastEvents: action.payload,
        isCreateEvents: false,
        isUpdateEvents: false,
        isDuplicateEvents: false,
        isUpdateEventsStatus: false,
        isRefundEvents: false,
      };

    case GET_ALL_MY_EVENTS:
      return {
        ...state,
        allMyEvents: action.payload,
        isPaymentUpdateEventsStatus: false,
      };
    case GET_ALL_MY_EVENTS_CART:
      return {
        ...state,
        allMyEventsCard: action.payload,
        isPaymentUpdateEventsStatus: false,
      };

    case GET_ALL_EVENTS_BY_QUERY:
      return {
        ...state,
        allEventsQueryData: {
          ...state.allEventsQueryData,
          forMaster: action.payload,
        },
        isCreateEvents: false,
        isUpdateEvents: false,
        isDuplicateEvents: false,
        isUpdateEventsStatus: false,
        isRefundEvents: false,
      };
    case GET_ALL_EVENTS_BY_QUERY_SEARCH:
      return {
        ...state,
        allEventsQueryData: {
          ...state.allEventsQueryData,
          forSearch: action.payload,
        },
        isCreateEvents: false,
        isUpdateEvents: false,
        isDuplicateEvents: false,
        isUpdateEventsStatus: false,
        isRefundEvents: false,
      };
    case GET_EVENTS_BY_ID:
      return {
        ...state,
        getEventsData: action.payload,
      };
    case GET_EVENTS_BY_CHAPTERS:
      return {
        ...state,
        getEventsDataByChapters: action.payload,
      };
    case POST_EVENTS:
      return {
        ...state,
        isCreateEvents: true,
        allEvents: false,
        allEventsQueryData: false,
        allUserEvents: false,
      };
    case UPDATE_EVENTS_BY_ID:
      return {
        ...state,
        isUpdateEvents: true,
        allEvents: false,
        allEventsQueryData: false,
        allUserEvents: false,
        getEventsData: false,
      };
    case UPDATE_EVENT_STATUS:
      return {
        ...state,
        isUpdateEventsStatus: true,
        allEvents: false,
        allEventsQueryData: false,
        allUserEvents: false,
      };
    case UPDATE_EVENT_REFUND_STATUS:
      return {
        ...state,
        isRefundEvents: true,
        allEventsQueryData: false,
        allEvents: false,
        allMyEvents: false,
      };
    case DUPLICATE_EVENTS_BY_ID:
      return {
        ...state,
        isDuplicateEvents: true,
        allEvents: false,
        allEventsQueryData: false,
        allUserEvents: false,
        getEventsData: false,
      };
    case DELETE_EVENTS_BY_ID:
      return {
        ...state,
        allEvents: false,
        allEventsQueryData: false,
        allUserEvents: false,
        allPastEvents: false,
      };

    case PAYMENT_EVENT_REGISTRATION_BY_EVENT_ID:
      return {
        ...state,
        isPaymentUpdateEventsStatus: true,
        allMyEvents: false,
      };

    case RESET_EVENT_EDIT_DATA:
      return {
        ...state,
        getEventsData: false,
      };

    default:
      return state;
  }
};

export default EventsReducer;

import {
  DELETE_JOBFEES_BY_ID,
  GET_ALL_JOBFEES,
  GET_JOBFEES_BY_ID,
  POST_JOBFEES,
  UPDATE_JOBFEES_BY_ID,
} from '../../Constant/ActionTypes';

const initialState = {
  allJobFees: false,
  getJobFeesData: false,
  isCreateJobFees: false,
  isUpdateJobFees: false,
};

const JobFeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_JOBFEES:
      return {
        ...state,
        allJobFees: action.payload,
        isCreateJobFees: false,
        isUpdateJobFees: false,
      };
    case UPDATE_JOBFEES_BY_ID:
      return {
        ...state,
        allJobFees: false,
        isUpdateJobFees: true,
      };
    case GET_JOBFEES_BY_ID:
      return {
        ...state,
        getJobFeesData: action.payload,
      };
    case POST_JOBFEES:
      return {
        ...state,
        allJobFees: false,
        isCreateJobFees: true,
      };
    case DELETE_JOBFEES_BY_ID:
      return {
        ...state,
        allJobFees: false,
      };
    default:
      return state;
  }
};

export default JobFeesReducer;

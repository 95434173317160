import {useCallback} from 'react';
import debounce from 'lodash.debounce';

function useDebouncedCallback(callback, delay) {
  // Create a debounced version of the callback
  // that delays invoking the callback until after `delay` milliseconds have passed
  // since the last time the debounced callback was invoked.
  const debouncedFn = useCallback(
    debounce((...args) => callback(...args), delay),
    [callback, delay], // Only call the effect if the callback or delay changes
  );

  return debouncedFn;
}

export default useDebouncedCallback;

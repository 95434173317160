// sponsorAttendeesActions.js

import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  DELETE_SPONSOR_ATTENDEE_BY_ID,
  UPDATE_SPONSOR_ATTENDEE_BY_ID,
  GET_ALL_SPONSOR_ATTENDEES,
  ADD_SPONSOR_SUCCESS,
  SHOW_MESSAGE,
  SOMETHING_WENT_WRONG,
  GET_EVENT_OPTIONS_BY_EVENT_ID,
  DELETE_ATTENDEES_BY_ID,
} from '../../Constant/ActionTypes';
import Urls from '../../Constant/Urls';
import {httpClient} from '../../Api/client';
import {handleJwtExpireMsg} from '../../Util/commonUtility';

// Action creator to add sponsor attendees
export const addSponsorAttendees = (data, setSponsorCreatedSuccessFully) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.post(Urls.SPONSOR_ATTENDEES_ADD, data);
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: response?.data?.message});
        dispatch({type: ADD_SPONSOR_SUCCESS, payload: response.data});
        dispatch({type: GET_EVENT_OPTIONS_BY_EVENT_ID, payload: false});
        setSponsorCreatedSuccessFully && setSponsorCreatedSuccessFully(true);
      }
    } catch (error) {
      dispatch({type: ADD_SPONSOR_SUCCESS});
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

// Action creator to update sponsor attendee by ID
export const updateSponsorAttendeeById = (data, id) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.patch(
        Urls.SPONSOR_ATTENDEES_UPDATE.replace('#{attendeeId}', id),
        data,
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: UPDATE_SPONSOR_ATTENDEE_BY_ID,
          payload: response?.data,
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

// Action creator to fetch all sponsor attendees
export const fetchSponsorAttendees = (id) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.get(
        Urls.SPONSOR_ATTENDEES_GET_ALL.replace('#{attendeeId}', id),
      );

      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_SPONSOR_ATTENDEES, payload: response?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

// Action creator to delete sponsor attendee by ID
export const deleteSponsorAttendeeById = (id) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.delete(
        Urls.SPONSOR_ATTENDEES_DELETE.replace('#{attendeeId}', id),
      );

      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_SPONSOR_ATTENDEE_BY_ID, payload: id});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteUnPaidSponsorshipFromCart = (eventId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.delete(
        Urls.SPONSOR_REMOVE_FROM_CART.replace('#{eventId}', eventId),
      );

      if (response.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_EVENT_OPTIONS_BY_EVENT_ID, payload: false});
        dispatch({type: DELETE_ATTENDEES_BY_ID});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

import React, {useEffect, useState} from 'react';
import JobFeeComponent from '../../../Components/AdminComponents/JobFees';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import {useDispatch, useSelector} from 'react-redux';
import {deleteJobFeesById, getAllJobFees} from '../../../Store/Actions/jobFees';
import {getUserDetails} from '../../../Store/Actions';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';

const JobFeeContainer = () => {
  const dispatch = useDispatch();
  const {allJobFees} = useSelector(({jobFees}) => jobFees);
  const [jobFeeData, setJobFeeData] = useState();
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.jobFees,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.jobFees,
  );

  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pagecount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });

  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  useEffect(() => {
    if (allJobFees) {
      setJobFeeData(allJobFees?.data?.jobFees);
      setPagination({
        ...pagination,
        page: allJobFees?.data?.currentPage,
        totalPages: allJobFees?.data?.totalPage,
        totalCount: allJobFees?.data?.totalCount,
      });
    } else {
      fetchJobFee();
    }
  }, [allJobFees?.data?.jobFees]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchJobFee();
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('jobFees', pageSize));
    dispatch(setPageNumber('jobFees', current));
    setPagination({
      ...pagination,
      page: current,
      pagecount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };

  const fetchJobFee = async (searchValues) => {
    try {
      const sortField = sortParams.field || '';
      const sortOrder = sortParams.order || '';
      const searchQueryData = queryString(searchValues);
      dispatch(
        getAllJobFees(pagination, sortField, sortOrder, searchQueryData),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const deleteJobFee = async (id) => {
    try {
      dispatch(deleteJobFeesById(id));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <JobFeeComponent
        allJobFeeData={jobFeeData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        deleteJobFee={(id) => {
          deleteJobFee(id);
        }}
        fetchJobFee={(searchValues) => {
          fetchJobFee(searchValues);
        }}
        handleTableChange={handleTableChange}
        sortParams={sortParams}
      />
    </div>
  );
};

export default JobFeeContainer;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  DefaultPaginationValues,
  createSelectionOptionsUtil,
  queryString,
} from '../../../Util/commonUtility';
import {
  deleteCouponCodesById,
  getAllCouponCodes,
  getAllCouponCodesWithQuery,
  getUserDetails,
} from '../../../Store/Actions';
import CouponCodesComponent from '../../../Components/AdminComponents/CouponCodes';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';
import {getAllEventsByQueryStringSearch} from '../../../Store/Actions/events';

const CouponCodesContainer = () => {
  const dispatch = useDispatch();
  const {allCouponCodesWithQuery} = useSelector(({couponCodes}) => couponCodes);
  const [couponCodesData, setCouponCodesData] = useState();
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.couponCodes,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.couponCodes,
  );
  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pagecount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  const searchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.coupon_codes,
  );
  const {allEventsQueryData} = useSelector(({events}) => events);
  const [eventsList, setEventsList] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (allCouponCodesWithQuery) {
      setCouponCodesData(allCouponCodesWithQuery?.data?.couponCodes);
      setPagination({
        ...pagination,
        page: allCouponCodesWithQuery?.data?.currentPage,
        totalPages: allCouponCodesWithQuery?.data?.totalPage,
        totalCount: allCouponCodesWithQuery?.data?.totalCount,
      });
    } else {
      fetchCouponCodes();
    }
  }, [allCouponCodesWithQuery]);

  useEffect(() => {
    if (allEventsQueryData.forSearch) {
      setEventsList(
        createSelectionOptionsUtil(
          allEventsQueryData.forSearch?.data?.events,
          'id',
          'name',
        ),
      );
      setFetching(false);
    } else {
      dispatch(
        getAllEventsByQueryStringSearch('', '', '', {
          page: 1,
          pageCount: 10,
        }),
      );
    }
  }, [allEventsQueryData.forSearch]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchCouponCodes(searchValues);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  useEffect(() => {
    if (searchValues) {
      dispatch(setPageNumber('couponCodes', 1));
      setPagination({
        ...pagination,
        page: 1,
      });
      setPaginationAndSortChanged(true);
    }
  }, [searchValues]);

  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('couponCodes', pageSize));
    dispatch(setPageNumber('couponCodes', current));

    setPagination({
      ...pagination,
      page: current,
      pagecount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };

  const fetchCouponCodes = (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllCouponCodesWithQuery(
        pagination,
        sortField,
        sortOrder,
        searchQueryData,
      ),
    );
  };
  const deleteCouponCodes = (id) => {
    dispatch(deleteCouponCodesById(id));
  };

  return (
    <div>
      <CouponCodesComponent
        allCouponCodesData={couponCodesData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        handleTableChange={handleTableChange}
        sortParams={sortParams}
        fetchCouponCodes={(searchValues) => {
          fetchCouponCodes(searchValues);
        }}
        deleteCouponCodes={(id) => {
          deleteCouponCodes(id);
        }}
        searchValues={searchValues}
        eventsList={eventsList}
        setEventsList={setEventsList}
        fetching={fetching}
        setFetching={setFetching}
      />
    </div>
  );
};

export default CouponCodesContainer;

import React, {useEffect, useState} from 'react';
import Modal from 'antd/es/modal/Modal';
import {
  Card,
  Form,
  Input,
  DatePicker,
  Button,
  Upload,
  Row,
  Col,
  Alert,
  message,
  Divider,
  Select,
} from 'antd';
import {ApiFilled, CloseCircleFilled, UploadOutlined} from '@ant-design/icons';
import {useDispatch} from 'react-redux';
import dayjs from 'dayjs';
import {createJobs, editJobs} from '../../../../Store/Actions/jobs';
import CommonEditor from '../../../CommonComponents/TextEditor';
import {applicationConfig} from '../../../../Util/config';
import {useNavigate} from 'react-router-dom';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {
  NoEmptySpaceInput,
  UploadFileMaxSize,
  disablePrevDatesHandler,
  removeHttpsFromUrl,
  urlInputValidator,
  validateMonthsInput,
} from '../../../../Util/commonUtility';
import {FETCH_ERROR} from '../../../../Constant/ActionTypes';

const JobsFormComponent = (props) => {
  const {isModalOpen, setIsModalOpen, editData, jobFeesOptions, zeroFeeData} =
    props;
  const [editorDescState, setEditorDescState] = useState(`<p></p>`);
  const [editorReqState, setEditorReqState] = useState(`<p></p>`);
  const [validFileList, setValidFileList] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = (values) => {
    const addHttpsToAttachmentUrl = `https://${values?.attachment_url}`;

    const data = new FormData();
    // const file_logo = values.file_logo
    //   ? values.file_logo.map((item) => {
    //       data.append('logos', item.originFileObj);
    //     })
    //   : null;
    editData?.jobLogo
      ? editData?.jobLogo?.id === validFileList[0]?.uid
        ? data.append('logo_ids', `[${editData?.jobLogo?.id}]`)
        : data.append('logos', validFileList[0])
      : data.append('logos', validFileList[0] || []);

    data.append('position', values?.position || '');
    data.append(
      'start_date',
      values?.start_date ? dayjs(values?.start_date).format('YYYY-MM-DD') : '',
    );
    data.append('employer', values?.employer || '');
    data.append('description', editorDescState);
    // data.append('vacancy', values?.vacancy || 5);
    data.append('location', values?.location || '');
    data.append('requirement', editorReqState);
    data.append('contact_info', values?.contact_info || '');
    // data.append(
    //   'end_date',
    //   values?.end_date ? dayjs(values?.end_date).format('YYYY-MM-DD') : '',
    // );
    data.append('months', values?.months ? values?.months : '');
    data.append('attachment_url', addHttpsToAttachmentUrl || '');
    data.append(
      'job_fee_id',
      values?.job_fee_id ? parseInt(values?.job_fee_id) : undefined,
    );
    if (editData?.jobStatus === 'draft') {
      dispatch(
        editJobs(data, editData.jobId, form, null, null, setIsModalOpen),
      );
    } else {
      const isZeroFee = values?.job_fee_id === zeroFeeData[0]?.id;
      dispatch(
        createJobs(
          data,
          form,
          navigate,
          setIsModalOpen,
          setValidFileList,
          isZeroFee,
        ),
      );
    }
  };
  const handleCancel = () => {
    form.resetFields();
  };
  const handleClose = () => {
    setIsModalOpen(false);
    form.resetFields();
    setValidFileList((prev) => []);
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const beforeUploadLogos = (file) => {
    const reader = new FileReader();
    const fileSize = file.size / 1024;

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        if (width !== 250 && height !== 250) {
          message.error('Image must be 250px x 250px!');
          return Upload.LIST_IGNORE;
        }
      };
      if (fileSize > 2) {
        message.error('File size must not exceed 2MB!');
        return Upload.LIST_IGNORE;
      }
      return true;
    };

    reader.readAsDataURL(file);
  };
  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  // const uploadPropsLogos = {
  //   name: 'fileLogos',
  //   accept: 'image/*, application/pdf',
  //   multiple: false,
  //   maxCount: 1,
  //   listType: 'picture',
  // };

  const filesFormats = ['image/png', 'image/jpeg', 'image/jpg'];

  const uploadProps = {
    name: 'file',
    multiple: true,
    maxCount: 10,
    // listType: 'picture',
    beforeUpload: (file) => {
      const isRightFormat = filesFormats.includes(file.type);
      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'You can only upload png, jpg, jpeg files',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSize;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSize}MB!`,
        });
        return false;
      }
      if (file?.type?.includes('image')) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            if (img.width >= 200 && img.height >= 200) {
              setValidFileList((prev) => [file]);
              resolve(isRightFormat && isLt2M);
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Image must be minimum 200x200!',
              });
              setValidFileList((prev) =>
                prev.filter((f) => f.uid !== file.uid),
              );
              reject();
            }
          };
          img.onerror = reject;
        });
      } else {
        setValidFileList((prev) => [file]);
        return isRightFormat && isLt2M;
      }
    },
  };

  const removeFileHandler = (file) => {
    setValidFileList((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
  };

  useEffect(() => {
    if (editData) {
      const findJobFeesId = jobFeesOptions?.find(
        (obj) => obj?.details === editData?.job_fee_note,
      )?.id;
      form.setFieldsValue({
        position: editData?.jobTitle,
        employer: editData?.jobEmployer,
        location: editData?.jobLocation,
        contact_info: editData?.jobContactInfo,
        attachment_url: removeHttpsFromUrl(editData?.jobUrl),
        description: editData?.jobDescription,
        requirement: editData?.jobRequirements,
        // logos: editData?.jobLogo && {
        //   uid: editData?.jobLogo?.id,
        //   name: editData?.jobLogo?.name,
        //   status: 'done',
        //   url: `${applicationConfig.UPLOAD_URL}/${editData?.jobLogo?.name}`,
        // },
        start_date: editData?.jobStartDate
          ? dayjs(editData?.jobStartDate)
          : null,
        months: editData?.months,
        job_fee_id: findJobFeesId,
      });
      setEditorDescState(editData?.jobDescription);
      setEditorReqState(editData?.jobRequirements);
    }
  });

  useEffect(() => {
    if (isModalOpen) {
      editData?.jobLogo &&
        setValidFileList((prev) => [
          {
            uid: editData?.jobLogo?.id,
            name: editData?.jobLogo?.name,
            status: 'done',
            url: `${applicationConfig.UPLOAD_URL}/${editData?.jobLogo?.name}`,
          },
        ]);
    }
  }, [isModalOpen]);

  return (
    <div className='form-container'>
      <Modal
        bodyStyle={{overflowY: 'scroll', maxHeight: 'calc(100vh - 200px)'}}
        open={isModalOpen}
        className='common-form-modal'
        prefixCls='customized-modal-v2'
        footer={null}
        onCancel={handleClose}
        closeIcon={
          <CloseCircleFilled
            className='close-icon-modal-v2'
            id='UserJobsComponent-closeButton-addJobs'
          />
        }>
        <Card
          title={
            <div className='modal-container-v2'>
              <div>
                <span className='modal-heading-v2'>
                  {ApplicationString['userJobsComponent.heading']}
                </span>
              </div>
              <div>
                <span className='modal-desc-v2'>
                  {ApplicationString['userJobsComponent.subheading.part1']}

                  {ApplicationString['userJobsComponent.subheading.part2']}
                </span>
              </div>
            </div>
          }
          bordered={false}
          className='form-card'>
          <div className='main-content-container'>
            <Form onFinish={onFinish} form={form}>
              <Row>
                <Col span={24}>
                  <div className='common-input-container-v2'>
                    <Form.Item
                      name='position'
                      rules={[
                        {
                          required: true,
                          message: 'Please fill out position!',
                        },
                        {
                          validator: (_, value) =>
                            NoEmptySpaceInput(value, 'Position'),
                        },
                      ]}>
                      <Input
                        placeholder='Position / Designation'
                        className='form-input-v2'
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col span={12}>
                  <div className='common-input-container-v2'>
                    <Form.Item
                      name='employer'
                      rules={[
                        {
                          required: true,
                          message: 'Please fill out employer name!',
                        },
                        {
                          validator: (_, value) =>
                            NoEmptySpaceInput(value, 'Employer'),
                        },
                      ]}>
                      <Input
                        placeholder='Employer name'
                        className='form-input-v2'
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div className='common-input-container-v2'>
                    <Form.Item
                      name='location'
                      rules={[
                        {
                          required: true,
                          message: 'Please fill out location!',
                        },
                        {
                          validator: (_, value) =>
                            NoEmptySpaceInput(value, 'Location'),
                        },
                      ]}>
                      <Input placeholder='Location' className='form-input-v2' />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col span={12}>
                  <div className='common-input-container-v2'>
                    <Form.Item
                      name='contact_info'
                      rules={[
                        {
                          validator: (_, value) =>
                            NoEmptySpaceInput(value, 'Contact info'),
                        },
                      ]}>
                      <Input
                        placeholder={'Contact information'}
                        className='form-input-v2'
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div className='common-input-container-v2'>
                    <Form.Item
                      name='attachment_url'
                      rules={[
                        {
                          required: true,
                          message: 'Please fill out attachment url!',
                        },
                        {validator: urlInputValidator},
                      ]}>
                      <Input
                        placeholder='External url / job poster address'
                        prefixCls='attachment-url-input-v2'
                        addonBefore='https://'
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col span={12}>
                  <div>
                    <Form.Item
                      name='start_date'
                      rules={[
                        {
                          required: true,
                          message: 'Please select start date!',
                        },
                      ]}>
                      <DatePicker
                        disabledDate={(current) =>
                          disablePrevDatesHandler(current, true)
                        }
                        placeholder='Start date of Job'
                        prefixCls='form-input-datepicker-v2'
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Form.Item
                      name={'months'}
                      className='common-input-container-v2'
                      rules={[
                        {
                          required: true,
                          message: '',
                        },
                        {
                          validator: (_, value) =>
                            validateMonthsInput(value, 'Months'),
                        },
                      ]}>
                      <Input
                        placeholder='Enter the months of the job'
                        className='form-input-v2'
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className='common-input-container-v2'>
                    <Form.Item
                      name='job_fee_id'
                      rules={[
                        {
                          required: true,
                          message: 'Please select job type!',
                        },
                      ]}>
                      <Select
                        className='form-select-v2'
                        placeholder={`Select jobs type`}
                        options={jobFeesOptions}></Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className='common-input-container-v2'>
                    <Form.Item
                      name='description'
                      rules={[
                        {
                          required: true,
                          message: 'Please fill out description!',
                        },
                      ]}>
                      <CommonEditor
                        v2={true}
                        removeExtraField={true}
                        value={editorDescState}
                        onChange={setEditorDescState}
                        placeholder='Jobs description'
                        text={'jobs_form_description'}
                        placeholderValue={'job description'}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className='common-input-container-v2'>
                    <Form.Item
                      name='requirement'
                      rules={[
                        {
                          required: true,
                          message: 'Please fill out requirement!',
                        },
                      ]}>
                      <CommonEditor
                        v2={true}
                        removeExtraField={true}
                        value={editorReqState}
                        onChange={setEditorReqState}
                        placeholder='Jobs requirement'
                        text={'jobs_form_requirement'}
                        placeholderValue={'job requirement'}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className='common-input-container-v2 upload'>
                    <Form.Item
                      name='file_logo'
                      // valuePropName='fileList'
                      getValueFromEvent={normFile}>
                      <Upload
                        {...uploadProps}
                        accept='image/*'
                        customRequest={dummyRequest}
                        onRemove={removeFileHandler}
                        fileList={validFileList}
                        showUploadList={true}>
                        <div>
                          <UploadOutlined className='upload-logo-v2' />
                          <div>
                            <span className='upload-text-logos-v2'>
                              {
                                ApplicationString[
                                  'userJobsComponent.uploadLogoText'
                                ]
                              }
                            </span>
                          </div>
                          <div className='upload-type-admin'>
                            <span className='upload-desc-v2'>
                              Accepts only image files (png, jpg, jpeg)
                            </span>
                            <br />
                            <span className='upload-desc-v2'>
                              {`Maximum file size is ${UploadFileMaxSize}MB.`}
                            </span>
                          </div>
                        </div>
                      </Upload>
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              {/* <Divider /> */}
              <div className='modal-footer-container'>
                <div>
                  {/* <span className='payment-text-v2'>
                    First, complete the payment to proceed with the listing
                    process.
                  </span> */}
                  {/* <div className='alert-info'>
                    <Alert
                      message={
                        <div className='alert-container'>
                          <div>
                            <span className='alert-heading'>
                              {' '}
                              New Job listing Added Successfully in queue !
                            </span>
                          </div>
                          <div>
                            <span className='alert-desc'>
                              it will take 24 Hours to be listed & once
                              moderator approve the details & Payment status
                            </span>
                          </div>
                        </div>
                      }
                      type='success'
                      closeIcon={<CloseCircleFilled className='close-icon' />}
                    />
                  </div> */}
                </div>
                <div className='modal-footer-button'>
                  <Button
                    htmlType='submit'
                    id='UserJobsComponent-submitButton-addJobs'
                    className='checkout-button-v2'>
                    <span className='checkout-text-v2'>
                      {editData?.jobStatus === 'draft'
                        ? ApplicationString['userText.save']
                        : ApplicationString[
                            'userJobsComponent.submit&CheckoutText'
                          ]}{' '}
                    </span>
                  </Button>
                  <Button
                    id='UserJobsComponent-cancelButton-addJobs'
                    className='cancel-button-v2'
                    onClick={handleClose}>
                    <span className='cancel-text-v2'>
                      {ApplicationString['userText.cancel']}
                    </span>
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Card>
      </Modal>
    </div>
  );
};

export default JobsFormComponent;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import ChaptersComponent from '../../../../AdminComponents/Chapters';
import CommonPageHeroText from '../../../../CommonComponents/CommonPageHeroText';
import SectionLayout from '../../../../CommonComponents/Layout/sectionLayout';
import PromotionalCodeInput from '../../../../CommonComponents/CouponCodeInput';
import CartConfirmationAction from '../../../../CommonComponents/CommonCartConfirmaton';
import {postJobPayment} from '../../../../../Store/Actions';
import JobCartTable from '../JobCartTable';
import {useNavigate} from 'react-router-dom';
import {validatePaymentPrice} from '../../../../../Util/commonUtility';
import NavigationComponent from '../../../../CommonComponents/CommonBackButton';

const NormalJobsCartComponent = ({jobData, jobFeesOptions}) => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const navigate = useNavigate();
  const [isJobEditing, setISJobEditing] = useState();
  const handlePaymentClick = () => {
    if (validatePaymentPrice(jobData[0]?.job_fee, dispatch)) return;
    dispatch(postJobPayment({jobsId: parseInt(id)}, navigate));
  };

  return (
    <div className='common-cart-container'>
      <div>
        <SectionLayout>
          <div className='common-cart-table-conatiner'>
            <NavigationComponent
              name={'Go Back to Job Posting'}
              urlPath={`jobs`}
            />
            <JobCartTable
              jobsData={jobData}
              text='Job'
              jobFeesOptions={jobFeesOptions}
              setISJobEditing={setISJobEditing}
            />
          </div>
          <div>
            <CartConfirmationAction
              heading='Total amount to be paid'
              price={jobData?.length > 0 && jobData[0]?.job_fee}
              onPay={handlePaymentClick}
              isJobEditing={isJobEditing}
            />
          </div>
        </SectionLayout>
      </div>
    </div>
  );
};

export default NormalJobsCartComponent;

import {Button, Divider, Space} from 'antd';
import React from 'react';
import './style.css';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {ApplicationString} from '../../../../Localization/Locales/en';

const FoodOptionsExpandable = ({record, updateRecord, collapseRow}) => {
  const handleCollapse = () => {
    collapseRow(record.key);
  };
  const handleFoodOptionChange = (type, value) => {
    const updatedFoodOptions = record.foodOptions.map((option) => {
      if (option.type === type) {
        return {...option, quantity: value};
      }
      return option;
    });

    const updatedRecord = {...record, foodOptions: updatedFoodOptions};
    updateRecord(updatedRecord);
    calculateTotalPrice(updatedRecord);
  };

  const calculateTotalPrice = (record) => {
    const totalPrice = record.foodOptions.reduce(
      (total, option) => total + option.quantity * option.price,
      0,
    );
    const updatedRecord = {...record, price: totalPrice};
    updateRecord(updatedRecord);
  };

  return (
    <div className='food-option-conatiner'>
      <div className='food-option-sub'>
        {record?.foodOptions.map((option) => (
          <div className='food-option-card' key={option.type}>
            <span className='price-div'>
              {option.type} <p>${option.price}</p>
            </span>
            <Divider />
            <Space>
              <Button
                className='add-item-button'
                disabled={option.quantity === 0}
                onClick={() =>
                  handleFoodOptionChange(option.type, option.quantity - 1)
                }>
                -
              </Button>
              {option.quantity}
              <Button
                className='add-item-button'
                onClick={() =>
                  handleFoodOptionChange(option.type, option.quantity + 1)
                }>
                +
              </Button>
            </Space>
          </div>
        ))}
      </div>
      <div className='price-button-con'>
        <div className='food-option-card'>
          <span className='total-text'>
            Total <p>${record.price}</p>
          </span>
        </div>
        <span className='button-collapse-container'>
          <Button
            name='Done'
            type='text'
            style={{color: 'green'}}
            onClick={() => handleCollapse()}>
            <div className='act'>
              <CheckOutlined />
              <p>{ApplicationString['userText.done']}</p>
            </div>
          </Button>
          <Button
            type='text'
            style={{color: 'red'}}
            onClick={() => handleCollapse()}>
            <div className='act'>
              <CloseOutlined />
              <p>{ApplicationString['userText.cancel']}</p>
            </div>
          </Button>
        </span>
      </div>
    </div>
  );
};

export default FoodOptionsExpandable;

import {Modal, Button, Table, Tag, Divider, Alert, Tabs, message} from 'antd';
import {
  EventRegistrationStatus,
  formatCurrency,
  getStatusInfoOfEventRegistration,
} from '../../../../../Util/commonUtility';
import dayjs from 'dayjs';
import {ApplicationString} from '../../../../../Localization/Locales/en';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';

const categorizeAndGroupByTransitionId = (attendees) => {
  // Filter attendees to include only those with a status other than To be Paid
  const filteredAttendees = attendees?.filter(
    (attendee) => attendee?.status !== EventRegistrationStatus.TO_BE_PAID,
  );

  const grouped = filteredAttendees?.reduce((acc, attendee) => {
    const transactionId = attendee?.transaction_id;
    if (!acc[transactionId]) {
      acc[transactionId] = [];
    }
    acc[transactionId].push(attendee);
    return acc;
  }, {});

  return Object.keys(grouped)?.map((key, index) => ({
    key: key,
    label: `Payment ${index + 1}`,
    attendees: grouped[key],
  }));
};

const filterAttendeesByStatus = (sponsors, status = 1) => {
  return sponsors?.filter((attendee) => attendee?.status === status);
};

const findSponsorshipEventOptionId = (registrations) =>
  registrations
    ?.map(
      (registration) =>
        registration?.event_registration_options?.sponsorship?.event_option_id,
    )
    .find((eventOptionId) => eventOptionId !== undefined);

const EventDetailsModal = ({
  eventName,
  paidEvent,
  attendees,
  sponsors,
  paymentStatus,
  onCancel,
  isOpen,
  eventOptionId,
  eventRegistrationType,
  handleRefund,
  filteredAttendees,
  eventStartDate,
  eventId,
  // payment_mode,
  // refund_amount,
  // credit_amount,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState('');
  const [transactionId, setTransactionId] = useState(null);
  const [activeTabOptionId, setActiveTabOptionId] = useState([]);
  const [activeTabTotalCost, setActiveTabTotalCost] = useState(0);
  const [toBePaidEventRegistration, setToBePaidRegistration] = useState([]);
  const [eventRegistrationTypeData, setEventRegistrationTypeData] = useState(
    [],
  );

  const refundMode = (isBankRefund, refund_status) => {
    if (refund_status) {
      return isBankRefund ? 'PBSS Credit' : 'Refund to Bank';
    } else {
      return 'N/A';
    }
  };

  const handleEditAttendees = () => {
    if (eventRegistrationType === 'sponsorship') {
      navigate(
        `/eventSponsorCart/${eventId}/${activeTabOptionId}/${transactionId}`,
      );
    } else {
      navigate(`/attendeesCart/${eventId}/${transactionId}`);
    }
  };

  const handleTabKey = (key) => {
    setActiveTabKey(key);
  };

  // hide the edit registration cta if their is only one sponsor
  const isOnlyOneSponsor = (data, eventRegistrationType) => {
    const validData = Array.isArray(data) ? data : [];
    if (eventRegistrationType === 'sponsorship') {
      const sponsors = validData?.filter((item) => item?.is_sponsor === true);
      const nonSponsors = validData?.filter(
        (item) => item?.is_sponsor === false,
      );
      const totalSponsors = sponsors?.length + nonSponsors?.length;
      if (totalSponsors <= 1) {
        return false;
      }
    }
    return true;
  };

  // event cta for refund and edit registration
  const renderButtons = () => {
    const isEventExpired = eventStartDate
      ? dayjs(eventStartDate).isBefore(dayjs(), 'minute')
      : false;
    const isPaymentStatusToBePaid = paymentStatus === 'To Be Paid';

    const isEventStartDateWithin14Days = eventStartDate
      ? dayjs().diff(dayjs(eventStartDate), 'day') <= -14
      : false;

    const isOnlyOneSponsorship = isOnlyOneSponsor(data, eventRegistrationType);
    const activeTabData = eventRegistrationTypeData?.find(
      (group) => group?.key === activeTabKey,
    );
    const payment_id_null = activeTabData?.key === 'null' ? false : true;

    // hide the event registration if event expired and show message of event expired
    if (isEventExpired) {
      return (
        <Alert
          prefixCls='attendees-description-alert'
          message={ApplicationString['userEventDetailsModal.alertMsg.expired']}
          type='info'
          showIcon
        />
      );
    } else {
      const buttons = [];

      if (
        (isOnlyOneSponsorship &&
          payment_id_null &&
          isEventStartDateWithin14Days) ||
        isPaymentStatusToBePaid
      ) {
        buttons.push(
          <Button
            type='primary'
            className='common-button-refund'
            key='cancel'
            onClick={handleEditAttendees}>
            Edit Registration
          </Button>,
        );
      }
      return buttons;
    }
  };

  // show the data based on event registration type
  useEffect(() => {
    let categorizedData;
    if (eventRegistrationType === 'sponsorship') {
      categorizedData = categorizeAndGroupByTransitionId(sponsors);
    } else {
      categorizedData = categorizeAndGroupByTransitionId(attendees);
    }
    setEventRegistrationTypeData(categorizedData.reverse());
  }, [eventRegistrationType, attendees, sponsors]);

  useEffect(() => {
    if (eventRegistrationTypeData?.length > 0) {
      setActiveTabKey(eventRegistrationTypeData[0]?.key);
    }
  }, [eventRegistrationTypeData, eventRegistrationType]);

  // set initial data for active tab
  useEffect(() => {
    if (eventRegistrationTypeData?.length > 0 && activeTabKey) {
      const activeTabData = eventRegistrationTypeData?.find(
        (group) => group?.key === activeTabKey,
      );
      if (activeTabData) {
        const totalCost = activeTabData?.attendees?.reduce((sum, attendee) => {
          return sum + parseFloat(attendee?.total_cost);
        }, 0);
        const transactionId = activeTabData?.key || null;
        setData(activeTabData ? activeTabData?.attendees : []);
        setActiveTabOptionId(
          findSponsorshipEventOptionId(
            activeTabData ? activeTabData?.attendees : [],
          ),
        );
        setTransactionId(transactionId);
        setActiveTabTotalCost(totalCost);
      }
    }
  }, [activeTabKey, eventRegistrationType, attendees, sponsors]);

  // set to_be_paid data based on event registration type
  useEffect(() => {
    if (eventRegistrationType === 'sponsorship') {
      setToBePaidRegistration(filterAttendeesByStatus(sponsors));
    } else {
      setToBePaidRegistration(filterAttendeesByStatus(attendees));
    }
  }, [sponsors, attendees, eventRegistrationType]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      editable: true,
      width: '8%',
      render: (text, record) => {
        return (
          <Tag prefixCls='regularAttendeesTag'>
            {record.attendee_first_name} {record.attendee_last_name}
          </Tag>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'attendee_email',
      key: 'email',
      width: '15%',
      editable: true,
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },
    {
      title: 'Company/Affiliation',
      dataIndex: 'attendee_company',
      key: 'company',
      width: '12%',
      editable: true,
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },
    {
      title: 'Refunded Amount',
      dataIndex: 'refunded_amount',
      width: '8%',
      render: (text) => {
        return (
          <Tag prefixCls='regularAttendeesTag'>{formatCurrency(text)}</Tag>
        );
      },
    },
    {
      title: 'Mode of Refund',
      dataIndex: 'refund_to_account',
      width: '8%',
      render: (_, record) => {
        const refund_mode = record?.refund_to_account;
        const refund_status = record?.status == 0;
        return (
          <Tag prefixCls='regularAttendeesTag'>
            {refundMode(refund_mode, refund_status)}
          </Tag>
        );
      },
    },
    {
      title: 'Registration Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (status) => {
        const {color, label} = getStatusInfoOfEventRegistration(status);
        return <Tag prefixCls='regularAttendeesTag'>{label}</Tag>;
      },
    },
    {
      title: 'Total Cost',
      dataIndex: 'total_cost',
      width: '5%',
      render: (text) => {
        return (
          <h4 className='regularAttendees-price'>{formatCurrency(text)}</h4>
        );
      },
    },
  ];

  const titleWithButton = (
    <div className='modal-title-wrapper'>
      <div className='modal-title-buttons'>{renderButtons()}</div>
    </div>
  );

  const noFilteredAttendees = filteredAttendees.length < 0;

  const tabItems = eventRegistrationTypeData?.map((group) => ({
    key: group.key,
    label: group.label,
    children: (
      <>
        <div className='table-edit-container'>
          <p className='table-total-cost'>
            Total Cost :{' '}
            {formatCurrency(activeTabTotalCost > 0 ? activeTabTotalCost : 0)}
          </p>
          {titleWithButton && titleWithButton}
        </div>
        <Table
          prefixCls='event-details-table'
          dataSource={data}
          scroll={{x: 1000}}
          columns={columns}
          size='small'
          pagination={false}
        />
      </>
    ),
  }));

  return (
    <div className='modal-container'>
      <div className='event-details-modal-container'>
        {noFilteredAttendees && (
          <div key='cancel' className='update-footer'>
            <Button
              type='primary'
              className={`${'common-button-refund'}`}
              key='cancel'
              onClick={handleRefund}>
              Ask for Refund
            </Button>
          </div>
        )}
        <div className='evente-details-modal-wapper'>
          {/* <div>
            <p>
              <strong>Event Name:</strong> {eventName}
            </p>
            <p>
              <strong>Status of Payment:</strong> {paymentStatus}
            </p>
            {payment_mode && (
              <p>
                <strong>Mode of Refund :</strong> {payment_mode}
              </p>
            )}
          </div> */}
          {/* <div>
            {refund_amount && (
              <p>
                <strong>Refunded Amount:</strong> {refund_amount}
              </p>
            )}
            {credit_amount && (
              <p>
                <strong>Credit Amount:</strong> {credit_amount}
              </p>
            )}
          </div> */}
        </div>
        {!paidEvent ? (
          <Table
            prefixCls='event-details-table'
            dataSource={toBePaidEventRegistration}
            scroll={{x: 1000}}
            columns={columns}
            size='small'
            pagination={false}
          />
        ) : (
          <Tabs
            prefixCls='user-event-card'
            activeKey={activeTabKey}
            onChange={(key) => handleTabKey(key)}
            items={tabItems}
          />
        )}
      </div>
    </div>
  );
};

export default EventDetailsModal;

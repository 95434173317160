import React, {useState, useEffect} from 'react';
import AdFeesFormComponent from '../../../../Components/AdminComponents/AdFees/AdFeesForm';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  createAdFees,
  editAdFees,
  getAdFeesById,
} from '../../../../Store/Actions/adFees';

const AdFeesFormContainer = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [editData, setEditData] = useState();
  const {getAdFeesData} = useSelector(({adFees}) => adFees);

  useEffect(() => {
    if (getAdFeesData) {
      setEditData(getAdFeesData?.data);
    }
  }, [getAdFeesData]);

  const postAdFees = async (Data) => {
    dispatch(createAdFees(Data, navigate));
  };

  const updateAdFees = async (Data, id) => {
    dispatch(editAdFees(Data, id, navigate));
  };

  const fetchAdFeesById = async (id) => {
    dispatch(getAdFeesById(id));
  };
  return (
    <div>
      <AdFeesFormComponent
        postAdFees={(Data) => {
          postAdFees(Data);
        }}
        updateAdFees={(id, Data) => {
          updateAdFees(id, Data);
        }}
        fetchAdFeesById={(id) => {
          fetchAdFeesById(id);
        }}
        EditData={editData}
      />
    </div>
  );
};

export default AdFeesFormContainer;

import React, {useEffect, useState} from 'react';
import {getAllApprovedJobs} from '../../../../Store/Actions';
import ApprovedJobListing from '../../../../Components/UserComponents/Jobs/ApprovedJobListing';
import {useDispatch, useSelector} from 'react-redux';

const ApprovedJobsContainer = () => {
  const {getApprovedJobs} = useSelector(({jobs}) => jobs);
  const [allApprovedJobsData, setAllApprovedJobsData] = useState([]);
  //   const [totalJobs, setTotalJobs] = useState(0);
  let dispatch = useDispatch();

  useEffect(() => {
    if (getApprovedJobs) {
      const Data = getApprovedJobs?.data?.jobs;
      setAllApprovedJobsData(Data);
    } else {
      dispatch(getAllApprovedJobs());
    }
  }, [getApprovedJobs]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div>
      <ApprovedJobListing allApprovedJobsData={allApprovedJobsData} />
    </div>
  );
};

export default ApprovedJobsContainer;

import React, {useEffect} from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import Event2Listing from '../../CommonComponents/EventListing2.0';
import CustomCollapse from '../../CommonComponents/CommonAccordian';
import CommitteeListing from '../../CommonComponents/CommitteeListing';
import './style.css';
import {Col, Row} from 'antd';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import StandingCommiteeListing from '../../CommonComponents/StandingCommiteeListing';
import {ApplicationString} from '../../../Localization/Locales/en';
import {CommitteeDataFromPBSSORG} from '../../CommonComponents/StandingCommiteeListing/committe';
import aboutImage from '../../../Assets/AboutUs.jpg';

const AboutUsComponent = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        <Col span={24}>
          <SponsorOptionPageHeroText title={'About PBSS'} />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <div className='about-us-container'>
              {/* <img src='https://images.unsplash.com/photo-1531058020387-3be344556be6?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' /> */}
              <label>{ApplicationString['userAboutUsComponent.heading']}</label>
              <p>{ApplicationString['userAboutUsComponent.paragraph']}</p>
            </div>
          </SectionLayout>
        </Col>
        <Col span={24}>
          <div className='commite-sub-container'>
            <SectionLayout>
              <div className='commite-sub-wapper'>
                <div className='commitee-left-section'>
                  <label>
                    {ApplicationString['userAboutUsComponent.ourMission']}
                  </label>
                  <p>
                    {
                      ApplicationString[
                        'userAboutUsComponent.ourMissionParagraph'
                      ]
                    }
                  </p>
                </div>
                <img src={aboutImage} />
              </div>{' '}
            </SectionLayout>
          </div>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <StandingCommiteeListing data={CommitteeDataFromPBSSORG} />
          </SectionLayout>
        </Col>

        <Col span={24}>
          <SectionLayout>
            <CustomCollapse />
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AboutUsComponent;

import React, {useEffect, useState} from 'react';
import {Button, Card, DatePicker, Form, Input, Select, InputNumber} from 'antd';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {
  NoEmptySpaceInput,
  OnlyAlphabetInputValidator,
  NumberWithDecimalValidation,
  PriceLimitationValidation,
  NumberWithoutDecimalValidation,
} from '../../../../Util/commonUtility';
import {ApplicationString} from '../../../../Localization/Locales/en';
const EventOptionGroupsFormComponent = (props) => {
  const [submit, setSubmit] = useState(false);
  const [submitLabel, setSubmitLabel] = useState(
    ApplicationString['common.button.submitForm'],
  );
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const {
    postEventOptionGroups,
    EditData,
    updateEventOptionGroups,
    fetchEventOptionGroupsById,
  } = props;

  useEffect(() => {
    if (id) {
      fetchEventOptionGroupsById(id);
      setSubmitLabel(ApplicationString['common.button.updateForm']);
    }
  }, []);
  useEffect(() => {
    if (id) {
      EditData &&
        form.setFieldsValue({
          ...EditData,
        });
    }
  }, [EditData]);
  const onFinish = (values) => {
    const Data = {
      ...values,
      price: values?.price ? parseInt(values?.price) : undefined,
    };
    if (id) {
      updateEventOptionGroups(Data, EditData.id);
      // form.resetFields();
    } else {
      postEventOptionGroups(Data);
      // form.resetFields();
    }
    setSubmit(true);
  };
  const {id} = useParams();
  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };
  const onReset = () => {
    form.resetFields();
    setSubmit(false);
  };
  const backToEventOptionGroups = () => {
    navigate('/admin/eventOptionGroups');
  };
  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          onFinish={onFinish}
          layout='vertical'
          initialValues={''}
          className='two-column-form'
          onFinishFailed={onFinishFailed}
          autoComplete='off'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Name'
                name='name'
                rules={[
                  {
                    required: true,
                    message: 'Event option group name should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidator(
                        value,
                        'Event option group name',
                      ),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter event option group name'
                />
              </Form.Item>
              <Form.Item
                label='Details'
                name='details'
                rules={[
                  {
                    required: true,
                    message: 'Details should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Details'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter details for event option group '
                />
              </Form.Item>
              <Form.Item
                label='Price'
                name='price'
                rules={[
                  {
                    validator: (_, value) =>
                      NumberWithoutDecimalValidation(value, 'Price', 9999999),
                  },
                ]}>
                <Input
                  // maxLength={6}
                  prefixCls='common-input-user'
                  className='genral-form'
                  min={0}
                  controls={false}
                  placeholder='Enter price for event option group'
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item>
            <div className='button-container'>
              <Button
                className='common-submit-login common-form-submit common-form-cancel'
                onClick={() => {
                  backToEventOptionGroups();
                }}>
                {ApplicationString['common.button.backForm']}
              </Button>
              <div className='form-flex-button'>
                <Button
                  type='primary'
                  htmlType='submit'
                  //disabled={submit ? true : false}
                  className='common-submit-login common-form-submit'>
                  {submitLabel}
                </Button>
                <Button
                  className='common-submit-login common-form-submit common-form-cancel'
                  htmlType='button'
                  id='JobsComponent-button-clear'
                  onClick={onReset}>
                  {ApplicationString['dynamicSearch.button.reset']}
                </Button>{' '}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default EventOptionGroupsFormComponent;

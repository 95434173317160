import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import LoginComponent from '../../../Components/UserComponents/Login';
import {getAllApprovedAds, getAllApprovedJobs} from '../../../Store/Actions';

const LoginContainer = () => {
  let dispatch = useDispatch();
  const {getApprovedJobs} = useSelector(({jobs}) => jobs);
  const {getApprovedAds} = useSelector(({ads}) => ads);
  const [allApprovedJobsData, setAllApprovedJobsData] = useState([]);
  const [allApprovedAdsData, setAllApprovedAdsData] = useState([]);

  useEffect(() => {
    if (getApprovedJobs) {
      const Data = getApprovedJobs?.data?.jobs.slice(0, 3);
      setAllApprovedJobsData(Data);
    } else {
      dispatch(getAllApprovedJobs());
    }
  }, [getApprovedJobs]);

  useEffect(() => {
    if (getApprovedAds) {
      const Data = getApprovedAds?.data?.ads.slice(0, 3);
      setAllApprovedAdsData(Data);
    } else {
      dispatch(getAllApprovedAds());
    }
  }, [getApprovedAds]);

  return (
    <LoginComponent
      allApprovedJobsData={allApprovedJobsData}
      allApprovedAdsData={allApprovedAdsData}
    />
  );
};

export default LoginContainer;

import React, {useEffect, useState} from 'react';
import PaymentHistoryComponent from '../../../Components/UserComponents/PaymentHistory';
import {useDispatch, useSelector} from 'react-redux';
import {LoadMoreSize} from '../../../Util/commonUtility';

const PaymentHistoryContainer = () => {
  let dispatch = useDispatch();
  const [countData, setCountData] = useState(LoadMoreSize);
  const [countLoadMoreData, setCountLoadMoreData] = useState(2);
  const [selectedPaymentStatusValue, setSelectedPaymentStatusValue] =
    useState('');
  const [getAllUserPaymentData, setGetAllUserPaymentData] = useState();
  // const {allPayments} = useSelector(({payments}) => payments);

  const dataSource = [
    {
      key: '1',
      amount: 100,
      paymentMethod: 'Credit Card',
      description: 'Payment for services',
      email: 'Exam@gmail.com',
      date: '2024-01-25',
      status: 'paid',
    },
    {
      key: '1',
      amount: 100,
      paymentMethod: 'Credit Card',
      description: 'Payment for services',
      email: 'Exam@gmail.com',
      date: '2024-01-25',
      status: 'pending',
    },
    {
      key: '1',
      amount: 100,
      paymentMethod: 'Credit Card',
      description: 'Payment for services',
      email: 'Exam@gmail.com',
      date: '2024-01-25',
      status: 'refund',
    },
    {
      key: '1',
      amount: 100,
      paymentMethod: 'Credit Card',
      description: 'Payment for services',
      email: 'Exam@gmail.com',
      date: '2024-01-25',
      status: 'failed',
    },
    {
      key: '1',
      amount: 100,
      paymentMethod: 'Credit Card',
      description: 'Payment for services',
      email: 'Exam@gmail.com',
      date: '2024-01-25',
      status: 'non-refund',
    },
    // Add more data as needed
  ];

  // const handlePaymentStatusFilter = (value) => {
  //   if (value === 'all') {
  //     dispatch(getAllUserPaymentData(value));
  //     setSelectedPaymentStatusValue('all');
  //   } else {
  //     dispatch(
  //       getAllUserPaymentData(value),
  //       setSelectedPaymentStatusValue(value),
  //     );
  //   }
  //   setCountLoadMoreData(2);
  // };

  // const handleLoadMore = () => {
  //   setCountData(countData * 2);
  //   setCountLoadMoreData(countLoadMoreData * 2);
  // };

  // useEffect(() => {
  //   if (allPayments) {
  //     const Data = allPayments?.data?.events.slice(0, countData);
  //     setGetAllUserPaymentData(Data);
  //   } else {
  //     dispatch(getAllUserPaymentData());
  //     setGetAllUserPaymentData(dataSource);
  //   }
  // }, [allPayments, countData]);

  return (
    <React.Fragment>
      <PaymentHistoryComponent
        getAllUserPaymentData={dataSource}
        // handleLoadMore={handleLoadMore}
      />
    </React.Fragment>
  );
};

export default PaymentHistoryContainer;

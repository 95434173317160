import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import EventOptionGroupsComponent from '../../../Components/AdminComponents/EventOptionGroups';
import {
  deleteEventOptionGroupsById,
  getAllEventOptionGroupsByQueryString,
} from './../../../Store/Actions/eventOptionGroups';
import {getUserDetails} from '../../../Store/Actions';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';

const EventOptionGroupsContainer = () => {
  const dispatch = useDispatch();
  const {allEventOptionGroupsQueryData} = useSelector(
    ({eventOptionGroups}) => eventOptionGroups,
  );

  const [eventOptionGroupsData, setEventOptionGroupsData] = useState();
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.eventOptionGroups,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.eventOptionGroups,
  );

  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pagecount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  const searchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.events_option_groups,
  );

  useEffect(() => {
    if (allEventOptionGroupsQueryData) {
      setEventOptionGroupsData(
        allEventOptionGroupsQueryData?.data?.eventOptionGroups,
      );
      setPagination({
        ...pagination,
        page: allEventOptionGroupsQueryData?.data?.currentPage,
        totalPages: allEventOptionGroupsQueryData?.data?.totalPage,
        totalCount: allEventOptionGroupsQueryData?.data?.totalCount,
      });
    } else {
      fetchEventOptionGroups();
    }
  }, [allEventOptionGroupsQueryData?.data?.eventOptionGroups]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchEventOptionGroups(searchValues);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  useEffect(() => {
    if (searchValues) {
      dispatch(setPageNumber('eventOptionGroups', 1));
      setPagination({
        ...pagination,
        page: 1,
      });
      setPaginationAndSortChanged(true);
    }
  }, [searchValues]);

  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('eventOptionGroups', pageSize));
    dispatch(setPageNumber('eventOptionGroups', current));
    setPagination({
      ...pagination,
      page: current,
      pagecount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };

  const fetchEventOptionGroups = (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllEventOptionGroupsByQueryString(
        pagination,
        sortField,
        sortOrder,
        searchQueryData,
      ),
    );
  };

  const deleteEventOptionGroups = (id) => {
    dispatch(deleteEventOptionGroupsById(id));
  };

  return (
    <div>
      <EventOptionGroupsComponent
        alleventOptionGroupsData={eventOptionGroupsData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        handleTableChange={handleTableChange}
        sortParams={sortParams}
        fetchEventOptionGroups={(searchValues) => {
          fetchEventOptionGroups(searchValues);
        }}
        deleteEventOptionGroups={(id) => {
          deleteEventOptionGroups(id);
        }}
        searchValues={searchValues}
      />
    </div>
  );
};

export default EventOptionGroupsContainer;

import React from 'react';
import PaymentCheckoutContainer from '../../../Containers/UserContainer/PaymentCheckoutForm';

const PaymentCheckoutScreen = () => {
  return (
    <React.Fragment>
      <PaymentCheckoutContainer />
    </React.Fragment>
  );
};

export default PaymentCheckoutScreen;

import {
  GET_ALL_EMAILCODES,
  ADD_EMAILCODE_SUCCESS,
  UPDATE_EMAILCODE_BY_ID,
  DELETE_EMAILCODE_BY_ID,
  FETCH_EMAILCODE_BY_ID,
  GET_ALL_EMAILCODES_SEARCH,
  SET_EMAIL_TEMPLATE_TYPE,
} from '../../Constant/ActionTypes';

const initialState = {
  allEmailCodes: {
    forSearch: false,
    forMaster: false,
  },
  isCreatedEmailCode: false,
  isUpdateEmailCode: false,
  fetchEditData: false,
  emailTemplateTypeValue: false,
};

const EmailCodesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EMAILCODES:
      return {
        ...state,
        allEmailCodes: {
          ...state.allEmailCodes,
          forMaster: action.payload,
        },
        isCreatedEmailCode: false,
        isUpdateEmailCode: false,
      };
    case GET_ALL_EMAILCODES_SEARCH:
      return {
        ...state,
        allEmailCodes: {
          ...state.allEmailCodes,
          forSearch: action.payload,
        },
        isCreatedEmailCode: false,
        isUpdateEmailCode: false,
      };
    case FETCH_EMAILCODE_BY_ID:
      return {
        ...state,
        fetchEditData: action.payload,
      };
    case ADD_EMAILCODE_SUCCESS:
      return {
        ...state,
        isCreatedEmailCode: true,
        allEmailCodes: false,
      };
    case UPDATE_EMAILCODE_BY_ID:
      return {
        ...state,
        isUpdateEmailCode: true,
        allEmailCodes: false,
      };
    case DELETE_EMAILCODE_BY_ID:
      return {
        ...state,
        allEmailCodes: false,
      };
    case SET_EMAIL_TEMPLATE_TYPE:
      return {
        ...state,
        emailTemplateTypeValue: action.payload,
      };
    default:
      return state;
  }
};

export default EmailCodesReducer;

import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_END,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INVALID,
  INVALID_REFRESH_TOKEN_PROVIDE,
  RESET_PASSWORD_SUCCESS,
  SEND_MAil_SUCCESS,
  SHOW_MESSAGE,
  SOMETHING_WENT_WRONG,
  VALIDATE_UNSUBSCRIBE_SUCCESS,
} from '../../Constant/ActionTypes';

import Urls from '../../Constant/Urls';
import {httpClient, setAuthToken} from '../../Api/client';
import {getLocalStorageUserInfo} from '../../Util/commonUtility';

export const handleNavigation = (navigate, isUserRegister, userRole) => {
  if (navigate && isUserRegister) {
    navigate(`/eventDetails/${isUserRegister}`);
  } else {
    if (userRole === 'admin') {
      navigate('/admin/events');
    } else {
      navigate('/myEvents');
    }
  }
};

export const signUpUser = (userDetails, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.post(Urls.ACCOUNT_REGISTER, userDetails);
      if (data?.status === HttpCode.Ok) {
        navigate('/login');
        dispatch({type: FETCH_SUCCESS});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const signIn = (userDetails, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.post(Urls.ACCOUNT_LOGIN, userDetails);
      if (data?.status === HttpCode.Ok) {
        setAuthToken(data.data.data.token);
        localStorage.setItem('userInfo', JSON.stringify(data?.data?.data));
        localStorage.setItem('refreshToken', data?.data?.data?.refreshToken);
        const isGuestUserRegister = localStorage.getItem('isGuestUserRegister');
        const userRole = data?.data?.data?.role;
        // if (data.data.data.role === 'admin') {
        //   navigate('/');
        // } else {
        //   navigate('/');
        // }
        navigate && handleNavigation(navigate, isGuestUserRegister, userRole);
        dispatch({type: FETCH_SUCCESS});
      }
    } catch (error) {
      let errorMessage = '';

      switch (error?.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const resetSendMail = (Data) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.post(Urls.ACCOUNT_RESET_SEND_MAIL, Data);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SEND_MAil_SUCCESS, payload: true});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const verifyResetPasswordToken = (token, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.ACCOUNT_VERIFY_RESET_TOKEN}?token=${token}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.Unauthorized:
          errorMessage = '';
          navigate('/login');
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updatePassword = (Data, token, navigate, updatePassForm) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.post(
        `${Urls.ACCOUNT_RESET_UPDATE_PASSWORD}?token=${token}`,
        Data,
      );
      let successMessage = '';
      if (data?.status === HttpCode.Ok) {
        successMessage = RESET_PASSWORD_SUCCESS;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        navigate && navigate('/login');
        updatePassForm && updatePassForm.resetFields();
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

const stopLoading = () => {
  return async (dispatch) => {
    dispatch({type: FETCH_END});
  };
};

export const getRefreshToken = async () => {
  try {
    let refreshTokenOld = localStorage.getItem('refreshToken');
    let data = await httpClient.post(Urls.ACCOUNT_REFRESH_TOKEN, {
      refreshToken: refreshTokenOld,
    });
    let token = data.data.data.token;
    let refreshToken = data.data.data.refreshToken;
    let userInfo = getLocalStorageUserInfo();
    userInfo.token = token;
    userInfo.refreshToken = refreshToken;
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    localStorage.setItem('refreshToken', refreshToken);
    return {token, refreshToken};
  } catch (error) {
    stopLoading();
    const errorObject = {
      response: {
        status: 400,
        data: {
          errorMessage: INVALID_REFRESH_TOKEN_PROVIDE,
        },
      },
    };
    throw errorObject;
  }
};

export const unsubscribeUser = (Data, setUnsubscribeSuccess) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.post(Urls.ACCOUNT_UNSUBSCRIBE_USER, Data);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        setUnsubscribeSuccess(true);
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const validateUnsubscribe = (Data) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.post(
        Urls.ACCOUNT_UNSUBSCRIBE_VERIFY_TOKEN,
        Data,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useStripe, useElements} from '@stripe/react-stripe-js';
import {fetchError} from '../Store/Actions';

// Description: This hook is used to handle submit the stripe payment
// use this hook in the conponent wrapper with Elements component from stripe

const useStripeSubmit = (
  paymentFailedUrl,
  paymentSuccessUrl,
  dispatchSuccess,
  id,
  resetSecret,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const submitPayment = async () => {
    if (!stripe || !elements) return;
    try {
      const result = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });
      if (
        result?.status === 'succeeded' ||
        result?.paymentIntent?.status === 'succeeded'
      ) {
        resetSecret();
        id
          ? dispatchSuccess(id, result?.paymentIntent?.id)
          : dispatchSuccess(result?.paymentIntent?.id);
        navigate(`${paymentSuccessUrl ? paymentSuccessUrl : '/'}`);
      } else {
        dispatch(
          fetchError(
            `${
              result?.error?.message
                ? result?.error?.message
                : 'Payment Failed, Please try again'
            }`,
          ),
        );
      }
    } catch (error) {
      dispatch(
        fetchError(
          `${
            error?.error?.message
              ? error?.error?.message
              : 'Payment Failed, Please try again'
          }`,
        ),
      );
    }
  };

  return submitPayment;
};

export default useStripeSubmit;

import React, {useEffect, useState} from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import PageHeading from '../../CommonComponents/PageHeading';
import {
  Table,
  Select,
  Button,
  Form,
  Popconfirm,
  Tag,
  Skeleton,
  Row,
  Col,
} from 'antd';
import {
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import EditableCell from '../../CommonComponents/EditableCell';
import './style.css';
import ConfirmationAction from '../../CommonComponents/PaymentConfirmationAction';
import FoodOptionsExpandable from '../AddAttendees/ExpandForm';
import CommonAlert from '../../CommonComponents/CommonAlert';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  filterFoodOptionColumnsForAttendee,
  formValueChangeHandler,
  formatCurrency,
  validatePaymentPrice,
} from '../../../Util/commonUtility';
import PromotionalCodeInputCart from '../../CommonComponents/CouponCodeInputCart';
import CustomCollapse from '../../CommonComponents/CommonAccordian';
import DashboardContainer from './../../../Containers/AdminContainer/Dashboard/index';
import NavigationComponent from '../../CommonComponents/CommonBackButton';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import {ApplicationString} from '../../../Localization/Locales/en';
import {FETCH_ERROR} from '../../../Constant/ActionTypes';

const {Option} = Select;

const AttendeesCartComponent = ({
  attendeesData,
  allEventOptionsFoodData,
  allEventOptionsRegistrationData,
  companiesData,
  totalCartPrice,
  editAttendeeById,
  deleteAttendeeById,
  applyCouponCode,
  paymentByUserRegistration,
  appliedCartCoupon,
  isSponsorAvailable,
  eventType,
  eventName,
}) => {
  const {id, transactionId} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.id === editingKey;
  const [expandedRowKeys, setExpandedRowKeys] = useState();
  const [isAddAttendee, setIsAddAttendee] = useState(false);
  const [couponVerifiedAttendee, setCouponVerifiedAttendee] = useState(false);
  const [couponVerifiedCart, setCouponVerifiedCart] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const handleConfirm = (data) => {
    if (validatePaymentPrice(totalCartPrice?.discounted_price, dispatch)) {
      return;
    }
    paymentByUserRegistration(id);
  };

  const edit = (record) => {
    const EditData = {
      ...record,
      attendee_email_confirm: record?.attendee_email
        ? record?.attendee_email
        : '',
      food_option: record?.food_option
        ? record?.food_option?.event_option_id
        : 'no-food',
      registration_option: record?.registration_option?.event_option_id,
      coupon_code: record?.discount_code,
    };
    form.setFieldsValue({
      ...EditData,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setIsAddAttendee(false);
    setEditingKey('');
  };

  const save = async (attendeeData) => {
    try {
      const row = await form.validateFields();
      const EditData = {
        ...row,
        food_option:
          row.food_option === 'no-food' ||
          eventType?.toLowerCase() === 'webcast'
            ? {}
            : {
                type: 'food',
                event_option_id: row?.food_option,
                qty: 1,
                id: attendeeData?.event_registration_options?.food?.id,
              },
        registration_option: {
          type: 'registration',
          event_option_id: row?.registration_option,
          qty: 1,
          id: attendeeData?.event_registration_options?.registration?.id,
        },
      };
      editAttendeeById(
        EditData,
        attendeeData.id,
        setEditingKey,
        form,
        setIsAddAttendee,
      );
    } catch (errInfo) {
      console.error('Validation Failed:', errInfo);
    }
  };

  useEffect(() => {
    if (attendeesData?.length === 0) {
      const loading = !attendeesData || attendeesData.length === 0;
      setLoading(loading);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [attendeesData]);

  const updateRecord = (record) => {
    const newData = [...data];
    const index = newData.findIndex((item) => record.id === item.id);
    newData[index] = record;
    setData(newData);
  };

  const handleCancel = () => {
    navigate(`/addAttendees/${id}`);
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      editable: true,
      width: '15%',
      render: (text, record) => {
        return (
          <Tag prefixCls='regularAttendeesTag'>
            {record.attendee_first_name} {record.attendee_last_name}
          </Tag>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'attendee_email',
      key: 'email',
      width: '18%',
      editable: true,
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },

    {
      title: 'Option',
      dataIndex: 'option',
      key: 'registration_and_food_option',
      width: '20%',
      editable: true,
      render: (_, record) => {
        const registrationName = record.registration_option?.name;
        const registrationPrice = record.registration_option?.price;
        const foodName = record.food_option?.name || 'No Food';
        const foodPrice = record.food_option?.price;
        return (
          <div className='option-tag-container'>
            <Tag prefixCls='regularAttendeesTag'>
              Registration: {registrationName} -{' '}
              {formatCurrency(registrationPrice, 'USD')}
            </Tag>
            {eventType !== 'Webcast' && (
              <Tag prefixCls='regularAttendeesTag'>
                Food: {foodName}{' '}
                {foodPrice ? '- ' + formatCurrency(foodPrice, 'USD') : ''}
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: 'Company/Affiliation',
      dataIndex: 'attendee_company',
      key: 'company',
      width: '15%',
      editable: true,
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },
    {
      title: 'Price',
      dataIndex: 'total_cost',
      width: '5%',
      render: (text) => {
        return (
          <h4 className='regularAttendees-price'>{formatCurrency(text)}</h4>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '6%',
      // render: (_, record) => {
      //   const editable = isEditing(record);
      //   return (
      //     <div>
      //       {editable ? (
      //         <span>
      //           <Button
      //             type='text'
      //             icon={<SaveOutlined />}
      //             onClick={() => save(record)}
      //           />
      //           <Button type='text' icon={<CloseOutlined />} onClick={cancel} />
      //         </span>
      //       ) : (
      //         <span>
      //           <Button
      //             type='text'
      //             icon={<EditOutlined />}
      //             disabled={editingKey !== ''}
      //             onClick={() => edit(record)}
      //           />
      //           <Popconfirm
      //             title='Delete the attendee'
      //             description='Are you sure you want to delete?'
      //             onConfirm={() => deleteAttendeeById(record.id)}
      //             okText='Yes'
      //             cancelText='No'
      //             okType='danger'
      //             icon={
      //               <QuestionCircleOutlined
      //                 style={{
      //                   color: 'red',
      //                 }}
      //               />
      //             }>
      //             <Button type='text' icon={<DeleteOutlined />} />
      //           </Popconfirm>
      //         </span>
      //       )}
      //     </div>
      //   );
      // },
      render: (_, record) => {
        return (
          <AdminActionsDropdown
            record={record}
            usedForAttendeeTable={true}
            editBool={true}
            deleteBool={false}
            attendeeEditingKey={editingKey}
            handleCancelClick={cancel}
            handleSaveClick={save}
            handleEditClick={edit}
            handleDeleteClick={() => deleteAttendeeById(record.id)}
            setIsAddAttendee={setIsAddAttendee}
          />
        );
      },
    },
  ];

  const mergedColumns = filterFoodOptionColumnsForAttendee(
    columns,
    eventType,
    isEditing,
  );

  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        <Col span={24}>
          <div>
            {transactionId ? (
              <SponsorOptionPageHeroText
                title={
                  ApplicationString['userAttendeeCartComponent.afterPayment']
                }
                subTitle={
                  eventName ||
                  ApplicationString[
                    'userAttendeeCartComponent.subHeading.afterPayment'
                  ]
                }
                hideDivider={true}
              />
            ) : (
              <SponsorOptionPageHeroText
                title={
                  ApplicationString['userAttendeeCartComponent.confirm&PayText']
                }
                subTitle={
                  eventName ||
                  ApplicationString['userAttendeeCartComponent.subHeading']
                }
                extra={
                  isSponsorAvailable ? (
                    <Button
                      onClick={() => {
                        navigate(`/sponsorshipOption/${id}`);
                      }}
                      className='reg-navigation-btn'>
                      {
                        ApplicationString[
                          'userAddAttendeeComponent.registerAs.sponsorText'
                        ]
                      }
                    </Button>
                  ) : (
                    ''
                  )
                }
                hideDivider={true}
              />
            )}
          </div>
        </Col>
        {!transactionId && (
          <Col span={24}>
            <SectionLayout>
              <NavigationComponent
                name={
                  ApplicationString[
                    'userAttendeeCartComponent.button.goBacktoAddAttendeeText'
                  ]
                }
                urlPath={`addAttendees/${id}`}
              />
            </SectionLayout>
          </Col>
        )}
        <Col span={24}>
          <SectionLayout>
            <Form
              form={form}
              onValuesChange={(changedValues) =>
                formValueChangeHandler(form, changedValues)
              }>
              <Skeleton
                loading={loading}
                prefixCls='table-skeleton'
                active
                paragraph={{rows: attendeesData?.length}}>
                <div className='attendees-label'>
                  {
                    ApplicationString[
                      'userAddAttendeeComponent.regularAttendee.label'
                    ]
                  }
                </div>
                <Table
                  prefixCls='attendees-table-container'
                  showExpandColumn={false}
                  components={{
                    body: {
                      cell: (props) => {
                        return (
                          <EditableCell
                            allEventOptionsFoodData={allEventOptionsFoodData}
                            allEventOptionsRegistrationData={
                              allEventOptionsRegistrationData
                            }
                            eventType={eventType}
                            companiesData={companiesData}
                            couponVerifiedAttendee={couponVerifiedAttendee}
                            setCouponVerifiedAttendee={
                              setCouponVerifiedAttendee
                            }
                            applyCouponCode={applyCouponCode}
                            attendeesData={attendeesData}
                            transactionId={transactionId}
                            id={id}
                            {...props}
                          />
                        );
                      },
                    },
                  }}
                  pagination={false}
                  scroll={{x: columns?.length * 150}}
                  dataSource={attendeesData}
                  columns={mergedColumns}
                  expandable={{
                    onExpand: (expanded, record) => {
                      return onExpand(expanded, record);
                    },
                    expandedRowKeys: expandedRowKeys,
                    expandedRowRender: (record, index, indent, expanded) => {
                      return (
                        <>
                          {expanded && (
                            <FoodOptionsExpandable
                              record={record}
                              updateRecord={updateRecord}
                              collapseRow={() => collapseRow(record.key)}
                            />
                          )}
                        </>
                      );
                    },
                  }}
                />
              </Skeleton>
            </Form>
          </SectionLayout>
        </Col>
        {!transactionId && (
          <>
            <Col span={24}>
              <SectionLayout>
                <PromotionalCodeInputCart
                  title={
                    ApplicationString[
                      'userAttendeeCartComponent.couponCodeTitle'
                    ]
                  }
                  buttonText={
                    ApplicationString[
                      'userAttendeeCartComponent.couponCodeButtonText'
                    ]
                  }
                  couponVerifiedCart={couponVerifiedCart}
                  onRedeemClick={(Data) => {
                    applyCouponCode({
                      ...Data,
                      eventId: parseInt(id),
                    });
                  }}
                  appliedCartCoupon={appliedCartCoupon}
                />
              </SectionLayout>
            </Col>
            <Col span={24}>
              <SectionLayout>
                <ConfirmationAction
                  heading={
                    ApplicationString[
                      'userAttendeeCartComponent.totalAmountToBePaid'
                    ]
                  }
                  data={attendeesData}
                  submitName={
                    ApplicationString[
                      'userAttendeeCartComponent.confirm&PayText'
                    ]
                  }
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                  totalCartPrice={totalCartPrice}
                  disable={isAddAttendee}
                />
              </SectionLayout>
            </Col>
          </>
        )}
        <Col span={24}>
          <SectionLayout>
            <CustomCollapse />
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AttendeesCartComponent;

import {
  GET_ALL_USERS,
  FETCH_USER_BY_ID,
  UPDATE_USER_BY_ID,
  DELETE_USER_BY_ID,
  GET_ALL_USERS_BY_QUERY,
  GET_USER_DETAILS,
  ADD_USERS,
  GET_ALL_USERS_WITHOUT_PAGINATION,
  UPDATE_USERS_STATUS,
  GET_ALL_USERS_BY_QUERY_SEARCH,
  FETCH_USER_BY_ID_FOR_UPDATE_PROFILE,
} from '../../Constant/ActionTypes';

const initialState = {
  allUsers: false,
  allUsersWithoutPagination: false,
  isUpdateUserStatus: false,
  isUpdateUser: false,
  isCreateUser: false,
  fetchUserData: false,
  fetchUserDataForUpdateProfile: false,
  userDetails: false,
  allUsersByQuery: {
    forSearch: false,
    forMaster: false,
  },
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
        isCreateUser: false,
        isUpdateUser: false,
      };
    case GET_ALL_USERS_WITHOUT_PAGINATION:
      return {
        ...state,
        allUsersWithoutPagination: action?.payload,
        isCreateUser: false,
        isUpdateUser: false,
      };
    case GET_ALL_USERS_BY_QUERY:
      return {
        ...state,
        allUsersByQuery: {
          ...state.allUsersByQuery,
          forMaster: action.payload,
        },
        isCreateUser: false,
        isUpdateUser: false,
      };
    case GET_ALL_USERS_BY_QUERY_SEARCH:
      return {
        ...state,
        allUsersByQuery: {
          ...state.allUsersByQuery,
          forSearch: action.payload,
        },
        isCreateUser: false,
        isUpdateUser: false,
      };
    case UPDATE_USERS_STATUS:
      return {
        ...state,
        isUpdateUserStatus: true,
        allUsers: false,
        allUsersByQuery: false,
      };
    case GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        isUpdateUser: false,
        isCreateUser: false,
      };
    case ADD_USERS:
      return {
        ...state,
        isCreateUser: true,
        allUsers: false,
        isUpdateUser: false,
        allUsersByQuery: false,
      };
    case FETCH_USER_BY_ID:
      return {
        ...state,
        fetchUserData: action.payload,
      };
    case FETCH_USER_BY_ID_FOR_UPDATE_PROFILE:
      return {
        ...state,
        fetchUserDataForUpdateProfile: action.payload,
      };
    case UPDATE_USER_BY_ID:
      return {
        ...state,
        isUpdateUser: true,
        allUsers: false,
        allUsersByQuery: false,
      };
    case DELETE_USER_BY_ID:
      return {
        ...state,
        allUsers: false,
        allUsersByQuery: false,
      };
    default:
      return state;
  }
};

export default UserReducer;

import {
  GET_ALL_CHAPTERS,
  ADD_CHAPTER_SUCCESS,
  FETCH_CHAPTER_BY_ID,
  DELETE_CHAPTER_BY_ID,
  UPDATE_CHAPTER_BY_ID,
  GET_ALL_CHAPTERS_BY_QUERY,
  CHAPTERS,
  SET_CHAPTER_BY_ID,
  SET_SIDE_BAR_STATE,
  SET_FORM_STATE_SUCESS,
  SET_EVENT_NAME_STATE,
  SET_MENU_ROUTE,
  SET_PAYMENT_ROUTE,
  SET_LOCAL_CHAPTER_DETAILS,
  GET_ALL_CHAPTERS_BY_QUERY_FOR_SEARCH,
} from '../../Constant/ActionTypes';

const initialState = {
  allChapters: false,
  allChaptersQueryData: {
    forSearch: false,
    forMaster: false,
  },
  isCreateChapter: false,
  isUpdateChapter: false,
  fetchChapterData: false,
  defaultChapterValue: false,
  sideBarState: true,
  defaultFormState: false,
  defaultEventNameState: false,
  setMenuRouteState: false,
  paymentRouteState: false,
  localChapterData: false,
};

const ChapterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CHAPTERS:
      return {
        ...state,
        allChapters: action.payload,
        isCreateChapter: false,
        isUpdateChapter: false,
      };
    case SET_LOCAL_CHAPTER_DETAILS:
      return {
        ...state,
        localChapterData: action.payload,
      };
    case FETCH_CHAPTER_BY_ID:
      return {
        ...state,
        fetchChapterData: action.payload,
      };
    case GET_ALL_CHAPTERS_BY_QUERY:
      return {
        ...state,
        allChaptersQueryData: {
          ...state.allChaptersQueryData,
          forMaster: action.payload,
        },
        isCreateChapter: false,
        isUpdateChapter: false,
      };
    case GET_ALL_CHAPTERS_BY_QUERY_FOR_SEARCH:
      return {
        ...state,
        allChaptersQueryData: {
          ...state.allChaptersQueryData,
          forSearch: action.payload,
        },
        isCreateChapter: false,
        isUpdateChapter: false,
      };
    case ADD_CHAPTER_SUCCESS:
      return {
        ...state,
        isCreateChapter: true,
        allChaptersQueryData: false,
        allChapters: false,
      };
    case UPDATE_CHAPTER_BY_ID:
      return {
        ...state,
        isUpdateChapter: true,
        allChaptersQueryData: false,
        allChapters: false,
      };
    case DELETE_CHAPTER_BY_ID:
      return {
        ...state,
        allChapters: false,
        allChaptersQueryData: false,
      };
    case SET_CHAPTER_BY_ID:
      return {
        ...state,
        defaultChapterValue: action.payload,
      };
    case SET_SIDE_BAR_STATE:
      return {
        ...state,
        sideBarState: action.payload,
      };
    case SET_FORM_STATE_SUCESS:
      return {
        ...state,
        defaultFormState: action.payload,
      };
    case SET_EVENT_NAME_STATE:
      return {
        ...state,
        defaultEventNameState: action.payload,
      };
    case SET_MENU_ROUTE:
      return {
        ...state,
        setMenuRouteState: action.payload,
      };
    // case SET_PAYMENT_ROUTE:
    //   return {
    //     ...state,
    //     paymentRouteState: action.payload,
    //   };
    default:
      return state;
  }
};

export default ChapterReducer;

import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  POST_SPECIAL_PAYMENT_DETAILS,
  SOMETHING_WENT_WRONG,
  SHOW_MESSAGE,
  POST_SPECIAL_PAYMENT_DETAILS_SUCCESS,
  GET_ALL_SPECIAL_PAYMENT_DETAILS,
  POST_TOKEN_DATA,
} from '../../Constant/ActionTypes';
import Urls from '../../Constant/Urls';
import {httpClient} from '../../Api/client';
import {commonSortOrder, handleJwtExpireMsg} from '../../Util/commonUtility';
import {useNavigate} from 'react-router-dom';
// Admin Special Payment Post Actions
export const postSpecialPaymentDetails = (data, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let response = await httpClient.post(Urls.SPECIAL_PAYMENT, data);
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: POST_SPECIAL_PAYMENT_DETAILS,
        });
        dispatch({type: SHOW_MESSAGE, payload: response?.data?.message});
        navigate && navigate('/admin/specialPayment');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

// User Special Payment Post Actions
export const postSpecialPaymentDetailsSuccess = (data, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let response = await httpClient.post(Urls.SPECIAL_PAYMENT_SUCCESS, {
        paymentIntentId: data,
      });
      if (response?.status === HttpCode.Ok) {
        dispatch({type: POST_SPECIAL_PAYMENT_DETAILS_SUCCESS});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: response?.data?.message});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

// User Special Payment Post Token Actions
export const postSpecialPaymentToken = (data) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let response = await httpClient.post(Urls.SPECIAL_PAYMENT_SECRET, {
        token: data,
      });
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: POST_TOKEN_DATA,
          payload: response?.data?.data,
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const resetSpecialPayment = () => {
  return (dispatch) => {
    dispatch({type: 'RESET_SPECIAL_PAYMENT'});
  };
};

// Admin Special Payment Get Actions
export const getAllSpecialPaymentDetails = (
  pagination,
  sortOrder,
  sortField = '',
  searchQueryData = '',
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.SPECIAL_PAYMENT}?page=${pagination.page}&pageCount=${
          pagination.pageCount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${searchQueryData}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: GET_ALL_SPECIAL_PAYMENT_DETAILS,
          payload: data?.data?.data,
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

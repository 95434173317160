import {Button, Col, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import CheckoutForm from '../../CommonComponents/CheckoutForm';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import NavigationComponent from '../../CommonComponents/CommonBackButton';
import {createStipePromise} from '../../../Util/commonUtility';
import {useDispatch, useSelector} from 'react-redux';
import CommonPaymentCheckoutForm from '../../CommonComponents/CommonPaymentCheckoutForm';
import {useNavigate, useParams} from 'react-router-dom';
import {
  postEventPaymentSuccess,
  resetEventPayment,
} from '../../../Store/Actions/eventRegister';

const PaymentCheckoutComponent = ({
  attendeesData,
  totalCartPrice,
  getAttendeeByEventId,
}) => {
  const {id, attendeeType, optionId} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {clientSecret, publishable_key} = useSelector(
    ({eventRegister}) => eventRegister?.eventRegistrationClientKeys,
  );
  const stripePromise = createStipePromise(`${publishable_key}`);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    if (!clientSecret) {
      navigate('/');
    }
  }, [clientSecret]);

  useEffect(() => {
    if (id) {
      getAttendeeByEventId(id);
    }
  }, [id]);

  const dispatchSuccess = (id, intent) => {
    dispatch(
      postEventPaymentSuccess(
        {
          eventId: id,
          paymentIntentId: intent,
          type: attendeeType,
        },
        navigate,
      ),
    );
  };

  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        <Col span={24}>
          <SponsorOptionPageHeroText
            title={'Payment'}
            subTitle={'Check your payment details'}
          />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <NavigationComponent
              name={'Back to cart'}
              urlPath={
                attendeeType === 'sponsor'
                  ? `eventSponsorCart/${id}/${optionId}`
                  : `attendeesCart/${id}`
              }
            />
          </SectionLayout>
        </Col>
        <Col span={24}>
          {clientSecret && (
            <SectionLayout>
              <Elements options={options} stripe={stripePromise}>
                <div className='card-stripe-container'>
                  <CommonPaymentCheckoutForm
                    clientSecret={clientSecret}
                    price={totalCartPrice?.discounted_price}
                    DataId={parseInt(id)}
                    resetPayment={resetEventPayment}
                    dispatchSuccess={(id, intent) => {
                      dispatchSuccess(id, intent);
                    }}
                    successUrl={'/paymentSuccess/myEvents'}
                  />
                </div>
              </Elements>
            </SectionLayout>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PaymentCheckoutComponent;

import React, {useEffect, useState} from 'react';
import PaymentCheckoutComponent from '../../../Components/UserComponents/PaymentCheckoutForm';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {getAttendesByUserAndEventId} from '../../../Store/Actions/eventRegister';

const PaymentCheckoutContainer = () => {
  const {id, attendeeType} = useParams();
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [attendeesData, setAttendeesData] = useState([]);
  const {allAttendees} = useSelector(({eventRegister}) => eventRegister);

  useEffect(() => {
    if (allAttendees) {
      setAttendeesData(allAttendees?.data);
      setTotalCartPrice({
        total_price_per_cart: allAttendees?.data?.total_price_per_cart,
        discount: allAttendees?.data?.discount,
        discounted_price: allAttendees?.data?.discounted_price,
      });
    } else {
      getAttendeeByEventId(id);
    }
  }, [allAttendees]);

  const getAttendeeByEventId = (id) => {
    dispatch(getAttendesByUserAndEventId(id, attendeeType));
  };

  return (
    <React.Fragment>
      <PaymentCheckoutComponent
        attendeesData={attendeesData}
        totalCartPrice={totalCartPrice}
        getAttendeeByEventId={(id) => {
          getAttendeeByEventId(id);
        }}
      />
    </React.Fragment>
  );
};

export default PaymentCheckoutContainer;

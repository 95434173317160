import React from 'react';
import AdsCartContainer from './../../../../Containers/UserContainer/Ads/AdsCart/index';

const AdsCartScreen = () => {
  return (
    <div>
      <AdsCartContainer />
    </div>
  );
};

export default AdsCartScreen;

import React, {useEffect, useState} from 'react';
import {logo, smallLogo} from '../../../Resource/images';
import {Divider, Menu, Layout, Button, Select, Dropdown, Tooltip} from 'antd';
import {
  BookOutlined,
  CaretDownOutlined,
  DesktopOutlined,
  DollarOutlined,
  DownOutlined,
  EnvironmentOutlined,
  FileOutlined,
  FileZipOutlined,
  KeyOutlined,
  LoginOutlined,
  LogoutOutlined,
  MailOutlined,
  PartitionOutlined,
  PieChartOutlined,
  ProfileOutlined,
  SettingOutlined,
  SolutionOutlined,
  SoundOutlined,
  TeamOutlined,
  UserOutlined,
  UserSwitchOutlined,
  VideoCameraAddOutlined,
} from '@ant-design/icons';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import Logout from '../../../Assets/Log-out.svg';
import AccoutSettings from '../../../Assets/Group.svg';
import {useJWTAuthActions} from '../../../Api/JWTAuthProvider';
import {getUserDetails, setSidebarState} from '../../../Store/Actions';
import {useDispatch, useSelector} from 'react-redux';
const {Sider} = Layout;

const navItems = [
  {
    id: 'events-1',
    label: 'Events',
    key: 'events',
    icon: <EnvironmentOutlined />,
  },
  // {
  //   label: 'Event Registration',
  //   key: 'eventRegistration',
  //   icon: <TeamOutlined />,
  // },
  {
    id: 'jobs-2',
    label: 'Jobs',
    key: 'jobs',
    icon: <SolutionOutlined />,
  },
  {
    id: 'ads-3',
    label: 'Ads',
    key: 'ads',
    icon: <VideoCameraAddOutlined />,
  },
  // {
  //   label: 'Event Options',
  //   key: 'eventOptions',
  //   icon: <EnvironmentOutlined />,
  // },
  {
    id: 'sponsors-4',
    label: 'Sponsors',
    key: 'sponsors',
    icon: <TeamOutlined />,
  },
  // {
  //   id: 'speakers-5',
  //   label: 'Speakers',
  //   key: 'speakers',
  //   icon: <SoundOutlined />,
  // },
  {
    id: 'users-6',
    label: 'Member Attendees',
    key: 'memberAttendees',
    icon: <UserOutlined />,
    // disabled: true,
  },
  {
    id: 'emailRemember-7',
    label: 'Email To Members',
    key: 'emailToMembers',
    icon: <MailOutlined />,
  },
  {
    id: 'specialPayment-8',
    label: 'Special Payment',
    key: 'specialPayment',
    icon: <DollarOutlined />,
  },

  {
    id: 'masters-9',
    label: 'Masters',
    key: 'Sponsorship',
    icon: <SettingOutlined />,
    key: 'masters',
    // icon: <SettingOutlined />,
    children: [
      {
        id: 'chapters-10',
        label: 'Chapters',
        key: 'chapters',
        icon: <BookOutlined />,
      },

      {
        id: 'jobFees-11',
        label: 'Job Fees',
        key: 'jobfees',
        icon: <DollarOutlined />,
      },
      {
        id: 'adFees-12',
        label: 'Ad Fees',
        key: 'adfees',
        icon: <DollarOutlined />,
      },
      {
        id: 'interests-13',
        label: 'Interests',
        key: 'interests',
        icon: <KeyOutlined />,
      },

      {
        id: 'emailTemplates-14',
        label: 'Email Templates',
        key: 'emailTemplates',
        icon: <MailOutlined />,
      },
      {
        id: 'companies-15',
        label: 'Companies',
        key: 'companies',
        icon: <PartitionOutlined />,
      },
      {
        id: 'couponCodes-16',
        label: 'Coupon Codes',
        key: 'couponCodes',
        icon: <FileZipOutlined />,
      },
      // {
      //   id: 'eventOptionGroups-17',
      //   label: 'Event Option Groups',
      //   key: 'eventOptionGroups',
      //   icon: <EnvironmentOutlined />,
      // },
      // {
      //   label: 'Event Email Templates Scheduler',
      //   key: 'eventEmailTemplatesScheduler',
      //   icon: <EnvironmentOutlined />,
      // },
      {
        id: 'eventTypes-18',
        label: 'Event Types',
        key: 'eventTypes',
        icon: <EnvironmentOutlined />,
      },
      {
        id: 'stem-19',
        label: 'STEM',
        key: 'stem',
        icon: <VideoCameraAddOutlined />,
      },
    ],
  },
];

const SideBarMenu = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState('Admin');
  const [selectedMenuItem, setSelectedMenuItem] = useState('mail');
  const {sideBarState} = useSelector(({chapters}) => chapters);
  const {userDetails} = useSelector(({users}) => users);

  useEffect(() => {
    if (!userDetails.data) {
      dispatch(getUserDetails());
    }
  }, [userDetails.data]);

  const onMenuItemClick = (e) => {
    setSelectedMenuItem(e.key);
    navigate(`/admin/${e.key}`);
  };
  const {logout} = useJWTAuthActions();

  const handleLogoutClick = () => {
    logout();
  };

  const handalCollaped = (value) => {
    dispatch(setSidebarState(value));
  };

  const findSelectedMenuItem = (navItems, url) => {
    for (const item of navItems) {
      if (url.includes(item.key)) {
        return item.key;
      } else if (item.children) {
        const childResult = findSelectedMenuItem(item.children, url);
        if (childResult) {
          return childResult;
        }
      }
    }
    return null;
  };

  const handleSelectChange = (value) => {
    setSelectedRole(value);
    if (value === 'admin') {
      navigate('/admin/events');
    } else if (value === 'user') {
      navigate('/');
    }
  };

  useEffect(() => {
    const url = location.pathname.replace('/admin/', '');
    const selectedMenuItem = findSelectedMenuItem(navItems, url);
    if (selectedMenuItem) {
      setSelectedMenuItem(selectedMenuItem);
    }
  }, [location]);

  useEffect(() => {
    const userRole = localStorage.getItem('userInfo');
    if (userRole?.role === 'admin') {
      setSelectedRole('admin');
    } else if (userRole?.role === 'user') {
      setSelectedRole('user');
    }
  }, []);

  const menu = (
    // <Menu>
    //   <Select
    //     id='user-select'
    //     prefixCls='chapter-extend-collapse'
    //     showSearch={false}
    //     optionFilterProp='children'
    //     placeholder={'Select'}
    //     // options={[
    //     //   {label: 'Admin', value: 'admin'},
    //     //   {label: 'Switch to User View', value: 'user'},
    //     // ]}
    //     suffixIcon={<CaretDownOutlined />}
    //     prefixIcon={<UserOutlined />}
    //     value={selectedRole}
    //     // onChange={handleSelectChange}
    //   >
    //     <option value='admin'>Admin</option>
    //     <option value='user'>Switch to User View</option>
    //   </Select>
    //   {/* Add more Menu.Items for additional options if needed */}
    // </Menu>

    // <Link to={'/'}>
    //   <UserOutlined />
    // </Link>
    <span></span>
  );

  return (
    <Sider
      prefixCls='menu-sidebar-component'
      collapsible
      collapsed={sideBarState}
      onCollapse={(value) => handalCollaped(value)}
      theme='light'>
      <div className='demo-logo-vertical'>
        <Link to='/'>
          <img
            className={sideBarState ? 'small-logo' : 'header-logo text-center'}
            src={sideBarState ? smallLogo : logo}
            alt='logo'
          />
        </Link>
      </div>
      <Menu
        id={navItems.id}
        defaultSelectedKeys={['1']}
        // onClick={onMenuItemClick}
        selectedKeys={[selectedMenuItem]}
        // items={navItems}
        prefixCls={sideBarState ? 'sidebar-menu-mini' : 'sidebar-menu'}
        theme='light'
        mode='inline'>
        {navItems.map((item) =>
          item.children ? (
            <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
              {item.children.map((child) => (
                <Menu.Item key={child.key} icon={child.icon}>
                  <Link to={`/admin/${child.key}`}>{child.label}</Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ) : (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={`/admin/${item.key}`}>{item.label}</Link>
            </Menu.Item>
          ),
        )}
      </Menu>
      <div
        className={`sidebar-botton-container ${
          sideBarState ? 'sidebar-extend-mini' : ''
        }`}>
        {/* <Button onClick={'add your function here !'}>
          <img src={AccoutSettings} />
          {sideBarState ? '' : 'Profile Settings'}
        </Button> */}

        {!sideBarState ? (
          <Link to={'/'} className='switch-to-user-link'>
            <Button id='adminSide-switch-to-user-view' type='default'>
              Switch to User View
            </Button>
          </Link>
        ) : (
          <Tooltip placement='right' title='Switch to User View'>
            <Dropdown overlay={menu} placement='bottomLeft'>
              <Link to={'/'} className='switch-to-user-link-collapsed'>
                <Button type='default'>
                  <UserOutlined />
                </Button>
              </Link>
            </Dropdown>
          </Tooltip>
        )}
        <Link
          to={'/login'}
          className={
            sideBarState
              ? 'switch-to-user-link-collapsed'
              : 'switch-to-user-link'
          }>
          {sideBarState ? (
            <Tooltip placement='right' title='Logout'>
              <Button
                id='adminSidebar-logOut-button'
                type='default'
                onClick={handleLogoutClick}>
                <img src={Logout} />
              </Button>
            </Tooltip>
          ) : (
            <Button
              id='adminSidebar-logOut-button'
              type='default'
              onClick={handleLogoutClick}>
              <img src={Logout} />
              Logout
            </Button>
          )}
        </Link>
      </div>
    </Sider>
  );
};
export default SideBarMenu;

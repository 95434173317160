import React, {useEffect, useState} from 'react';
import UserFormComponent from '../../../../Components/AdminComponents/Users/UserForm';
import {
  addUsers,
  fetchUsersById,
  getAllChapters,
  getAllChaptersByQueryString,
  getAllChaptersByQueryStringForSearch,
  getAllKeywords,
  getAllKeywordsByQuerySearch,
  resetKeywordSearchData,
  updateUserByAdminId,
} from '../../../../Store/Actions';
import {useDispatch, useSelector} from 'react-redux';
import {createSelectionOptionsUtil} from '../../../../Util/commonUtility';
import {useNavigate} from 'react-router-dom';
import {createSelectionForAdminOptionsUtil} from './../../../../Util/commonUtility';

const UserFormContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {fetchUserData} = useSelector(({users}) => users);
  const {allChapters} = useSelector(({chapters}) => chapters);
  // const {allKeywords} = useSelector(({keywords}) => keywords);
  const [editData, setEditData] = useState();
  const [chapterList, setChapterList] = useState([]);
  const [keywordsOptions, SetKeywordsOptions] = useState([]);
  const {allChaptersQueryData} = useSelector(({chapters}) => chapters);
  const {allKeywordsQueryData} = useSelector(({keywords}) => keywords);
  const [fetching, setFetching] = useState(false);
  const [chapterOptions, setChapterOptions] = useState();

  useEffect(() => {
    dispatch(resetKeywordSearchData(false));
  }, []);

  useEffect(() => {
    if (fetchUserData) {
      setEditData(fetchUserData?.data);
    }
  }, [fetchUserData]);

  useEffect(() => {
    if (allChaptersQueryData?.forSearch) {
      const visibleChapters =
        allChaptersQueryData?.forSearch?.data?.chapters?.filter((chapter) => {
          return chapter?.is_visible_on_map === true;
        });
      setChapterOptions(
        createSelectionForAdminOptionsUtil(visibleChapters, 'id', 'name'),
      );

      setFetching(false);
    } else {
      dispatch(
        getAllChaptersByQueryStringForSearch('', '', '', {
          page: 1,
          pageCount: 100,
        }),
      );
    }
  }, [allChaptersQueryData?.forSearch?.data?.chapters]);

  useEffect(() => {
    if (allKeywordsQueryData.forSearch) {
      SetKeywordsOptions(
        createSelectionOptionsUtil(
          allKeywordsQueryData.forSearch?.data?.keywords,
          'id',
          'name',
        ),
      );
      setFetching(false);
    } else {
      dispatch(
        getAllKeywordsByQuerySearch('', '', '', {
          page: 1,
          pageCount: 10,
        }),
      );
    }
  }, [allKeywordsQueryData.forSearch]);

  useEffect(() => {
    if (allChapters) {
      const visibleChapters = allChapters?.data?.chapters?.filter((chapter) => {
        return chapter?.is_visible_on_map === true;
      });
      setChapterList(
        createSelectionForAdminOptionsUtil(visibleChapters, 'id', 'name'),
      );
    } else {
      dispatch(getAllChapters());
    }
  }, [allChapters]);

  // useEffect(() => {
  //   if (allKeywords) {
  //     setKeywordsList(
  //       createSelectionOptionsUtil(allKeywords?.data?.keywords, 'name', 'name'),
  //     );
  //   } else {
  //     dispatch(getAllKeywords());
  //   }
  // }, [allKeywords]);

  const updateUser = async (Data, id) => {
    dispatch(updateUserByAdminId(Data, id, navigate));
  };

  const fetchUserById = async (id) => {
    dispatch(fetchUsersById(id));
  };
  const postUser = async (Data) => {
    dispatch(addUsers(Data, navigate));
  };

  return (
    <div>
      <UserFormComponent
        updateUser={(id, Data) => {
          updateUser(id, Data);
        }}
        fetchUserById={(id) => {
          fetchUserById(id);
        }}
        postUser={(Data) => {
          postUser(Data);
        }}
        editData={editData}
        chaptersList={chapterList}
        chapterOptions={chapterOptions}
        setChapterOptions={setChapterOptions}
        keywordsOptions={keywordsOptions}
        SetKeywordsOptions={SetKeywordsOptions}
        fetching={fetching}
        setFetching={setFetching}
      />
    </div>
  );
};

export default UserFormContainer;

import React, {useRef} from 'react';
import JoditEditor from 'jodit-react';
import './style.css';

const CommonEditor = ({
  value,
  onChange,
  placeholder,
  disabled,
  Variables,
  customVariables,
  v2 = false,
  removeExtraField = false,
  text,
  placeholderValue = 'your details',
}) => {
  const editor = useRef(null);
  const handleBlur = (newContent) => {
    if (onChange) {
      onChange(newContent);
    }
  };
  const config = {
    placeholder: `Enter ${placeholderValue} here`,
    uploader: {
      url: 'https://pbss-api.blobstation.dev/v1/templatePhotos',
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem('token') || '', // Handle potential null/undefined
      },
      data: function (files) {
        var formData = new FormData();
        for (var i = 0; i < files.length; i++) {
          formData.append('photos', files[i]); // Confirm the key with the server-side implementation
        }
        return formData;
      },
    },
    createAttributes: {
      table: {
        style:
          'border:1px solid #C5C6C8FF;border-collapse:collapse;width: 100%;',
      },
      tr: {style: ' border: 1px solid #C5C6C8FF;'},
      td: {style: ' border: 1px solid #C5C6C8FF;'},
    },
    filebrowser: {
      ajax: {
        url: 'https://pbss-api.blobstation.dev/v1/templatePhotos',
        headers: {
          Authorization: localStorage.getItem('token') || '', // Handle potential null/undefined
        },
        method: 'GET',
        error: function (data) {
          console.error('Filebrowser AJAX error:', data); // Log the entire data object for better error insight
        },
      },
    },
    buttons: removeExtraField
      ? 'bold,italic,underline,fontsize,font,|,ul,ol,align,|,undo,redo,source,|,copy,paste,link,|'
      : 'bold,italic,underline,fontsize,font,brush,|,ul,ol,align,|,undo,redo,source,|,copy,paste,link,|',
    toolbarAdaptive: false,
    style: {
      fontSize: '14px',
      fontFamily: 'inter',
      backgroundColor: '#f8f8f8',
      color: '#333',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    controls: {
      font: {
        list: {
          '': 'Default',
        },
      },
    },
    showTooltip: true,
    showTooltipDelay: 50,
    observer: {
      breakpoints: {
        mobile: 480,
        tablet: 768,
        desktop: 1024,
      },
    },
    extraButtons: Variables
      ? [
          {
            name: 'table',
            icon: 'table',
          },
          ,
          {
            name: 'Dynamic Variables',
            icon: ``,
            list: Variables,
            exec: function (editor, t, {control}) {
              editor.selection.insertHTML(control.name);
            },
          },
        ]
      : {
          name: 'table',
          icon: 'table',
        },

    customVariables,
    clipboard: {
      pastePlain: true,
    },
  };

  return (
    <div
      className={`${v2 ? 'custom-class' : ''} errorBorderOnEditor`}
      id={`${text}-commonEditor-joditEditor`}>
      {placeholder && <div className='title-text'>{placeholder}</div>}
      <JoditEditor
        config={config}
        ref={editor}
        value={value}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default CommonEditor;

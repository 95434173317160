import React, {useState, useEffect} from 'react';
import AdFeesComponent from '../../../Components/AdminComponents/AdFees';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import {useDispatch, useSelector} from 'react-redux';
import {deleteAdFeesById, getAllAdFees} from '../../../Store/Actions/adFees';
import {getUserDetails} from '../../../Store/Actions';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';

const AdFeesContainer = () => {
  const dispatch = useDispatch();
  const {allAdFees} = useSelector(({adFees}) => adFees);
  const [adFeeData, setAdFeeData] = useState();
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.adFees,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.adFees,
  );

  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pagecount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  useEffect(() => {
    if (allAdFees) {
      setAdFeeData(allAdFees?.data?.adFees);
      setPagination({
        ...pagination,
        page: allAdFees?.data?.currentPage,
        totalPages: allAdFees?.data?.totalPage,
        totalCount: allAdFees?.data?.totalCount,
      });
    } else {
      fetchAdFee();
    }
  }, [allAdFees?.data?.adFees]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchAdFee();
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('adFees', pageSize));
    dispatch(setPageNumber('adFees', current));
    setPagination({
      ...pagination,
      page: current,
      pagecount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };

  const fetchAdFee = async (searchValues) => {
    try {
      const sortField = sortParams.field || '';
      const sortOrder = sortParams.order || '';
      const searchQueryData = queryString(searchValues);
      dispatch(getAllAdFees(pagination, sortField, sortOrder, searchQueryData));
    } catch (error) {
      console.error(error);
    }
  };
  const deleteAdFee = async (id) => {
    try {
      dispatch(deleteAdFeesById(id));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <AdFeesComponent
        allAdFeesData={adFeeData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        deleteAdFee={(id) => {
          deleteAdFee(id);
        }}
        fetchAdFee={(searchValues) => {
          fetchAdFee(searchValues);
        }}
        handleTableChange={(pagination, filter, sorter) => {
          handleTableChange(pagination, filter, sorter);
        }}
        sortParams={sortParams}
      />
    </div>
  );
};

export default AdFeesContainer;

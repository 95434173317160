import axios from 'axios';
import {applicationConfig} from '../Util/config';

import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useJWTAuthActions} from './JWTAuthProvider';
import {getRefreshToken} from '../Store/Actions';

export const httpClient = axios.create({
  baseURL: applicationConfig.API_URL,
  headers: {
    Accept: '*/*',
  },
});

let isRefreshing = false;
let failedRequests = [];

/**
 * This function is a JavaScript Axios interceptor that handles refreshing authentication tokens
 * and retrying failed requests.
 * @returns The `children` prop is being returned.
 */
const AxiosInterceptor = ({children}) => {
  const navigate = useNavigate();
  const {logout} = useJWTAuthActions();
  useEffect(() => {
    const interceptor = httpClient.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error?.response?.status === 401 && !originalRequest._retry) {
          if (error?.response?.data?.errorMessage === 'JWT Expired') {
            if (!isRefreshing) {
              isRefreshing = true;
              try {
                const {token} = await getRefreshToken();
                setAuthToken(token);
                originalRequest.headers['Authorization'] = token;
                httpClient(originalRequest);
                return retryFailedRequests(token);
              } catch (error) {
                logout();
                failedRequests = [];
                return Promise.reject(error);
              } finally {
                isRefreshing = false;
              }
            } else {
              return new Promise((resolve) => {
                failedRequests.push((token) => {
                  originalRequest.headers['Authorization'] = token;
                  resolve(httpClient(originalRequest));
                });
              });
            }
          } else {
            // If status is 401 and it is not JWT Expired, redirect the user or admin to their panel without refreshing the token
            const userInfo = JSON.parse(localStorage.getItem('userInfo'));
            const userRole = userInfo ? userInfo.role : null;

            // Navigate user based on their role
            // If userRole is not null, navigate to updateProfile, else navigate to login
            if (userRole) {
              navigate('/');
            } else {
              navigate('/login');
            }
          }
        } else {
          return Promise.reject(error);
        }
      },
    );
  }, []);
  return children;
};

/**
 * The function retries failed requests by calling the callbacks with the provided token.
 * @param token - The token parameter is a variable that represents an authentication token or access
 * token. It is used to authorize and authenticate requests made to an API or server.
 */
function retryFailedRequests(token) {
  failedRequests.forEach((callback) => callback(token));
  failedRequests = [];
}

export const setAuthToken = (token) => {
  if (token) {
    httpClient.defaults.headers.common['Authorization'] = token;
    localStorage.setItem('token', token);
  } else {
    delete httpClient.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

export {AxiosInterceptor};

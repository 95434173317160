import {DatePicker, Select} from 'antd';
import React, {useRef, useState, useMemo} from 'react';
import './style.css';
import DateSvg from '../../../Assets/svgComponents/DateSvg';
import filter_by from '../../../Assets/filter-by.svg';
import DateDesortSvg from '../../../Assets/svgComponents/DateDesortSvg';
import {
  generateYearOptions,
  isNullUndefinedEmpty,
} from '../../../Util/commonUtility';
const {RangePicker} = DatePicker;

const CommonTitleWithFilters = ({
  title,
  handleChapterFilter,
  handleEventTypeFilter,
  newChaptersList,
  eventTypeList,
  eventTypeSelect = false,
  chapterSelect = false,
  dateSelect = false,
  yearSelect = false,
  usedForChapPage = false,
  usedForPastEvents = false,
  handleYearFilter,
  dateToggle,
  dateOrderHandler,
  selectedChapterValue,
  selectedEventTypeValue,
  selectedDateValue,
  selectedYearValue,
}) => {
  const selectRef = useRef(null);
  const yearOptions = useMemo(() => generateYearOptions(), []);
  const handleFocus = () => {
    selectRef.current && selectRef.current.blur();
  };
  return (
    <div
      className={`upcoming-events-heading-container ${
        usedForPastEvents && 'usedForPast'
      } ${
        usedForPastEvents &&
        'upcoming-events-heading-container-responsive-for-past-events'
      } ${
        usedForChapPage &&
        'upcoming-events-heading-container-responsive-for-chapter-page'
      }`}>
      <span className='upcoming-title'>{title} </span>
      <div
        className={`upcoming-events-heading-button-container ${
          usedForPastEvents &&
          'upcoming-events-heading-button-container-past-events'
        } ${
          usedForChapPage &&
          'upcoming-events-heading-button-container-chapter-page'
        } `}>
        <div className='filter-by-label'>
          <img src={filter_by} alt='filter-by-svg' />
          <span>Filter By</span>
        </div>
        {eventTypeSelect && (
          <Select
            id='event-type-select-1'
            showSearch
            prefixCls='event_type_select'
            defaultValue='Event Type'
            style={{width: '120px'}}
            onSelect={handleFocus}
            onChange={handleEventTypeFilter}
            value={
              isNullUndefinedEmpty(selectedEventTypeValue)
                ? selectedEventTypeValue
                : 'Event Type'
            }
            optionFilterProp='children'
            filterOption={(input, option) =>
              (option?.label ?? '').includes(input)
            }
            placeholder={'Select your Default chapter'}
            options={eventTypeList}
            ref={selectRef}></Select>
        )}
        {chapterSelect && (
          <Select
            id='chapters-type-select-2'
            showSearch
            prefixCls='event_type_select_chapter'
            defaultValue='Chapter'
            style={{width: '120px'}}
            onSelect={handleFocus}
            onChange={handleChapterFilter}
            value={selectedChapterValue || 'Chapter'}
            // optionFilterProp='children'
            filterOption={(input, option) =>
              (option?.label ?? '').includes(input)
            }
            placeholder={'Select your Default chapter'}
            options={newChaptersList}
            ref={selectRef}></Select>
        )}
        {dateSelect && (
          // <RangePicker
          //   prefixCls='date_picker'
          //   placeholder='Date'
          //   style={{width: '120px'}}
          //   onChange={filterBydate}
          //   suffixIcon={<DateSvg />}
          // />
          <button
            id='home-datepicker-type-button'
            className={`date_picker event-date-filter-button ${
              usedForChapPage && 'date_picker-chapter-page'
            }`}
            onClick={dateOrderHandler}>
            <span>Date</span>
            {!dateToggle ? <DateSvg /> : <DateDesortSvg />}
          </button>
        )}
        {yearSelect && (
          <Select
            id='home-type-year-select'
            className='event-type-select-year'
            showSearch
            prefixCls='event_type_select_year'
            defaultValue='Year'
            style={{width: '120px'}}
            onSelect={handleFocus}
            onChange={handleYearFilter}
            value={selectedYearValue || 'Year'}
            placeholder={'Select year to filter by'}
            options={yearOptions}
            ref={selectRef}></Select>
        )}
      </div>
    </div>
  );
};

export default CommonTitleWithFilters;

import React, {useEffect} from 'react';
import {Button, Typography, Space} from 'antd';
import './index.css';
import {
  formatCurrency,
  formatCurrencyByLocalChapter,
} from '../../../Util/commonUtility';
import {useDispatch, useSelector} from 'react-redux';
import {getAllChapters} from '../../../Store/Actions';

const {Title} = Typography;

const CartConfirmationAction = ({
  heading,
  price,
  onPay,
  isJobEditing,
  isAdEditing,
}) => {
  const localChapterData = useSelector(
    (state) => state.chapters.localChapterData,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!localChapterData) {
      dispatch(getAllChapters());
    }
  }, [localChapterData]);
  return (
    <div className='confirmation-cart-container'>
      <div className='confirmation-content'>
        <div className='confirmation-heading'>{heading}</div>
        <div className='confirmation-price'>
          {formatCurrencyByLocalChapter(price, localChapterData)}
        </div>
      </div>
      <div className='confirmation-buttons'>
        <Button
          type='primary'
          prefixCls='confirmation-payment'
          onClick={onPay}
          disabled={isJobEditing || isAdEditing}>
          Payment
        </Button>
        {/* <Button prefixCls='confirmation-cancel'>Cancel</Button> */}
      </div>
    </div>
  );
};

export default CartConfirmationAction;

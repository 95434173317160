import React from 'react';
import './style.css';
import {formatCurrencyByLocalChapter} from '../../../Util/commonUtility';

const CommonHeroText = ({
  heading,
  subheading,
  usedForMyEvents = false,
  userCredit,
  localChapterData,
}) => {
  return (
    <div className='common-hero-container-wrapper'>
      <div className='common-hero-text-container'>
        <span className='common-hero-text-heading'>{heading}</span>
        <span className='common-hero-text-subheading'>{subheading}</span>
      </div>

      {usedForMyEvents ? (
        <div className='common-hero-credit-container'>
          <span className='common-hero-credit-heading'>Available Credit: </span>
          <span className='common-hero-credit-amount'>
            {localChapterData
              ? formatCurrencyByLocalChapter(userCredit, localChapterData)
              : '$0'}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default CommonHeroText;

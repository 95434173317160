import React, {useState} from 'react';
import './style.css';
import {CloseCircleFilled, DoubleRightOutlined} from '@ant-design/icons';
import {Button, Card, Modal} from 'antd';
import {Shichang_Miao} from './../../../../Resource/images';
import view_more_svg from '../../../../Assets/view-more-right-arrow.svg';

const CommiteeCard = ({data, allowReadMore = true}) => {
  const [readMoreClicked, setReadMoreClick] = useState(false);

  const handleOk = () => {
    setReadMoreClick(false);
  };
  const handleCancel = () => {
    setReadMoreClick(false);
  };
  return (
    <React.Fragment>
      <div
        className={
          allowReadMore
            ? 'committee-card-container committee-card-container-pointer'
            : 'committee-card-container'
        }
        onClick={() => allowReadMore && setReadMoreClick(true)}>
        <div className='sub-committee-card-container'>
          <p>{data?.name_heading}</p>
          {data?.position && <label>{data?.position}</label>}
        </div>
        {/* {allowReadMore && (
          <div className='committee-read-more-icon-container'>
            <Button type='text' prefixCls='committee-read-more-icon'>
              Read more
              <img
                src={view_more_svg}
                alt='view_more_svg'
                className='view-more-svg used-for-committee'
              />
            </Button>
          </div>
        )} */}
      </div>
      <Modal
        centered
        prefixCls='committee-read-more-modal'
        width={800}
        title=''
        open={readMoreClicked}
        footer={false}
        closeIcon={<CloseCircleFilled className='committee-close-icon' />}
        onCancel={handleCancel}>
        <div className='committee-main-container'>
          {data?.profile && (
            <div className='committee-profile-container'>
              <img
                src={data?.profile}
                className='committee-profile-image'></img>
            </div>
          )}
          <div className='committee-content-container'>
            <div className='committee-member-name-container'>
              <p className='committee-member-name'>{data?.name_heading}</p>
            </div>
            <div className='committee-member-sub-heading-container'>
              {data?.sub_heading_1 && (
                <p className='committee-member-sub-heading'>
                  {data?.sub_heading_1}
                </p>
              )}
              {data?.sub_heading_2 && (
                <p className='committee-member-sub-heading'>
                  {data?.sub_heading_2}
                </p>
              )}
              {data?.sub_heading_3 && (
                <p className='committee-member-sub-heading'>
                  {data?.sub_heading_3}
                </p>
              )}
            </div>
            <div className='committee-member-description-container'>
              <p className='committee-member-description'>
                {data?.description}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default CommiteeCard;

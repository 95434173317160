import React, {useEffect, useState} from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
} from 'antd';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {
  NumberWithDecimalValidation,
  EventOptionTypes,
  SponsorshipType,
  queryString,
  disablePrevDatesHandler,
  NumberWithoutDecimalValidation,
  OnlyAlphabetInputValidator,
  NoEmptySpaceInput,
  OnlyAlphabetInputValidatorWithSpecialCharacters,
  PriceLimitationValidation,
  validateEventOptionNumberInput,
} from '../../../../Util/commonUtility';
import {ApplicationString} from '../../../../Localization/Locales/en';
import dayjs from 'dayjs';
import {Option} from 'antd/es/mentions';
import {getAllEventOptionGroupsByQueryString} from '../../../../Store/Actions/eventOptionGroups';
import {useDispatch} from 'react-redux';
import EventOptionDetails from './../Details/index';
import {CloseCircleOutlined, InfoCircleOutlined} from '@ant-design/icons';
import CommonEditor from '../../../CommonComponents/TextEditor';

const EventOptionsFormComponent = (props) => {
  const {id, eventId} = useParams();
  const [submit, setSubmit] = useState(false);
  const [submitLabel, setSubmitLabel] = useState(
    ApplicationString['common.button.submitForm'],
  );
  const [form] = Form.useForm();
  const [showSponsorPerks, setShowSponsorPerks] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [vendor, setVendor] = useState();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const {
    postEventOptions,
    EditData,
    updateEventOptions,
    fetchEventOptionsById,
    eventsList,
    eventOptionGroupsList,
    fetching,
    setFetching,
    setEventOptionGroupsList,
  } = props;
  const [editorInfoState, setEditorInfoState] = useState(`<p></p>`);

  // const handleEventOptionGroupSearch = (value) => {
  //   setEventOptionGroupsList([]);
  //   setFetching(true);
  //   const Data = {
  //     name: value,
  //   };
  //   const searchQueryData = queryString(Data);
  //   dispatch(
  //     getAllEventOptionGroupsByQueryString(
  //       {
  //         page: 1,
  //         pageCount: 100,
  //       },
  //       '',
  //       '',
  //       searchQueryData,
  //     ),
  //   );
  // };

  useEffect(() => {
    if (id) {
      fetchEventOptionsById(id);
      setSubmitLabel(ApplicationString['common.button.updateForm']);
    }
  }, []);
  useEffect(() => {
    if (id == EditData?.id) {
      if (EditData) {
        if (EditData?.event_option_type === EventOptionTypes.REGISTRATION) {
          setShowRegistration(true);
        }
        if (EditData?.event_option_type === EventOptionTypes.SPONSORSHIP) {
          setShowSponsorPerks(true);
        }
        setVendor(EditData?.is_vendor || false);
        setEditorInfoState(EditData?.eventSponsorshipPerk?.benefits);
        form.setFieldsValue({
          ...EditData,
          start_date: EditData?.start_date ? dayjs(EditData?.start_date) : null,
          is_vendor: EditData?.is_vendor,
          end_date: EditData?.end_date ? dayjs(EditData?.end_date) : null,
          table_space: EditData?.eventSponsorshipPerk?.table_space,
          raffle: EditData?.eventSponsorshipPerk?.raffle,
          free_lunch: EditData?.eventSponsorshipPerk?.free_lunch,
          workshop_attendees:
            EditData?.eventSponsorshipPerk?.workshop_attendees,
          attendees_price: EditData?.eventSponsorshipPerk?.attendees_price,
          attendees_note: EditData?.eventSponsorshipPerk?.attendees_note,
          slide_deck: EditData?.eventSponsorshipPerk?.slide_deck,
          presentation_note: EditData?.eventSponsorshipPerk?.presentation_note,
          flyer_distribution:
            EditData?.eventSponsorshipPerk?.flyer_distribution,
          dinner_with_speaker:
            EditData?.eventSponsorshipPerk?.dinner_with_speaker,
          event_option_group_id: {
            ...EditData.event_option_group_id,
            label: EditData?.event_option_group_id?.name,
            value: EditData?.event_option_group_id?.id,
          },
          benefits: EditData?.eventSponsorshipPerk?.benefits,
          sponsor_perk_content:
            EditData?.eventSponsorshipPerk?.sponsor_perk_content,
          sponsor_contact_content:
            EditData?.eventSponsorshipPerk?.sponsor_contact_content,
          lunch_price: EditData?.eventSponsorshipPerk?.lunch_price,
          dinner_with_speaker_price:
            EditData?.eventSponsorshipPerk?.dinner_with_speaker_price,
        });
      }
    }
  }, [EditData]);

  const handleChange = (values) => {
    if (values === 'sponsorship') {
      setShowSponsorPerks(true);
      setShowRegistration(false);
    } else if (values === 'registration') {
      setShowRegistration(true);
      setShowSponsorPerks(false);
    } else {
      setShowRegistration(false);
      setShowSponsorPerks(false);
    }
  };

  const onFinish = (values) => {
    const Data = {
      name: values?.name,
      currency_type: values?.currency_type,
      details: values.details,
      event_id: eventId ? Number(eventId) : Number(EditData?.event_id),
      event_option_group_id:
        values?.event_option_group_id?.value || values?.event_option_group_id,
      start_date: values.start_date,
      end_date: values.end_date,
      is_vendor: values?.is_vendor ? true : false,
      event_option_type: values.event_option_type,
      limit: values?.limit ? parseInt(values?.limit) : 0,
      qty: values?.qty ? parseInt(values?.qty) : 0,
      price: values?.price ? parseInt(values?.price) : 0,
      orders_count: values?.orders_count ? parseInt(values?.orders_count) : 0,
      sponsorship_type: values?.sponsorship_type,
      sponsorshipPerk: {
        // table_space: values.table_space ? values.table_space : '',
        // raffle: values.raffle ? values.raffle : '',
        free_lunch: values.free_lunch ? parseInt(values.free_lunch) : 0,
        workshop_attendees: values.workshop_attendees
          ? parseInt(values.workshop_attendees)
          : 0,
        attendees_price: values.attendees_price
          ? parseInt(values.attendees_price)
          : 0,
        benefits: values.benefits,
        // attendees_note: values.attendees_note ? values.attendees_note : '',
        // slide_deck: values.slide_deck ? values.slide_deck : '',
        // presentation_note: values.presentation_note
        //   ? values.presentation_note
        //   : '',
        // flyer_distribution: values.flyer_distribution
        //   ? values.flyer_distribution
        //   : '',

        sponsor_perk_content: values?.sponsor_perk_content
          ? values.sponsor_perk_content
          : '',
        sponsor_contact_content: values?.sponsor_contact_content
          ? values?.sponsor_contact_content
          : '',
        lunch_price: values?.lunch_price ? parseInt(values.lunch_price) : 0,
        dinner_with_speaker_price: values?.dinner_with_speaker_price
          ? parseInt(values.dinner_with_speaker_price)
          : 0,
        dinner_with_speaker: values?.dinner_with_speaker
          ? parseInt(values.dinner_with_speaker)
          : 0,
      },
    };

    if (id) {
      updateEventOptions(Data, EditData.id);
      // form.resetFields();
    } else {
      postEventOptions(Data);
      // form.resetFields();
    }
    setSubmit(true);
  };
  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };
  const onReset = () => {
    form.resetFields();
    setSubmit(false);
    setShowSponsorPerks(false);
  };
  const backToEventOptions = () => {
    if (eventId) {
      navigate(`/admin/eventOptions/${eventId}`);
    } else {
      navigate(`/admin/eventOptions/${EditData?.event_id}`);
    }
  };

  const validateCurrency = (inputString) => {
    // Basic pattern for currency symbols
    const currencyPattern = /^[A-Z]{3}$/;
    return currencyPattern.test(inputString);
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container option-details'>
        <div className='search-container' style={{gap: '10px'}}>
          <Button onClick={() => navigate('/admin/events')} id='back-to-events'>
            {ApplicationString['common.button.backToEvents']}
          </Button>
          {/* <Button
          type='primary'
          onClick={() => navigate('/admin/eventOptions/add')}
          id='EventOptionsGroupComponent-button-addJobs'
          className='common-button'>
          <div className='icon-container'>
            <img src={plus} alt='plus' className='icon-plus' />
            <span className='add-content'>
              {ApplicationString['eventOptionsComponent.button.addForm']}
            </span>
          </div>
        </Button> */}
        </div>
        <Row gutter={40} className='row-main-container'>
          <Col span={16}>
            <Form
              form={form}
              name='basic'
              onFinish={onFinish}
              layout='vertical'
              className='two-column-form'
              initialValues={''}
              onFinishFailed={onFinishFailed}
              autoComplete='off'>
              <div className='input-container'>
                <div className='form-column'>
                  <Form.Item
                    label='Name'
                    name='name'
                    rules={[
                      {
                        required: true,
                        message: 'Event option name should not be empty!',
                      },
                      {
                        validator: (_, value) =>
                          NoEmptySpaceInput(value, 'event option name'),
                      },
                    ]}>
                    <Input
                      prefixCls='common-input-user'
                      className='genral-form'
                      placeholder='Enter event option name'
                    />
                  </Form.Item>
                  <Form.Item
                    label='Price'
                    name='price'
                    rules={[
                      {required: true, message: 'Price should not be empty!'},
                      {
                        validator: (_, value) =>
                          NumberWithoutDecimalValidation(
                            value,
                            'Price',
                            9999999,
                          ),
                      },
                    ]}>
                    <Input
                      prefixCls='common-input-user'
                      className='genral-form'
                      Number
                      min={0}
                      controls={false}
                      placeholder='Enter event option price'
                    />
                  </Form.Item>
                </div>
                <div className='form-column'>
                  <Form.Item
                    label='Quantity'
                    name='qty'
                    rules={[
                      {
                        required: true,
                        message: 'Quantity should not be empty!',
                      },
                      {
                        validator: (_, value) =>
                          NumberWithoutDecimalValidation(value, 'Quantity'),
                      },
                    ]}>
                    <Input
                      prefixCls='common-input-user'
                      className='genral-form'
                      Number
                      min={0}
                      controls={false}
                      placeholder='Enter event option quantity'
                    />
                  </Form.Item>

                  <Form.Item
                    label='Limit'
                    name='limit'
                    rules={[
                      {
                        validator: (_, value) =>
                          NumberWithoutDecimalValidation(value, 'Limit'),
                      },
                    ]}>
                    <Input
                      prefixCls='common-input-user'
                      className='genral-form'
                      placeholder='Set limit for event option'
                    />
                  </Form.Item>
                  {/* <Form.Item
                    label='Start Date'
                    name='start_date'
                    rules={[
                      {
                        required: true,
                        message: 'Start date should not be empty!',
                      },
                    ]}>
                    <DatePicker
                      disabledDate={(current) =>
                        disablePrevDatesHandler(current, true)
                      }
                      placeholder='Select event option start date'
                      prefixCls='common-input-user'
                      className='genral-form'
                    />
                  </Form.Item>
                  <Form.Item
                    label='End Date'
                    name='end_date'
                    rules={[
                      {
                        required: true,
                        message: 'End date should not be empty!',
                      },
                      ({getFieldValue}) => ({
                        validator(_, value) {
                          const startDate = getFieldValue('start_date');
                          if (!startDate || !value) {
                            return Promise.resolve();
                          }
                          if (value && value.isAfter(startDate)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              'End Date must be after Start Date',
                            );
                          }
                        },
                      }),
                    ]}>
                    <DatePicker
                      disabledDate={(current) =>
                        disablePrevDatesHandler(current, true)
                      }
                      placeholder='Select event option end date'
                      prefixCls='common-input-user'
                      className='genral-form'
                    />
                  </Form.Item> */}
                </div>
              </div>
              <div className='input-container'>
                <div className='form-column'>
                  <Form.Item
                    label='Event Option Type'
                    name='event_option_type'
                    rules={[
                      {
                        required: true,
                        message: 'Event Option Type should not be empty!',
                      },
                    ]}>
                    <Select
                      showSearch
                      prefixCls='common-select-register'
                      className='space'
                      allowClear
                      onChange={handleChange}
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option?.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={'Select event option type'}
                      options={Object.values(EventOptionTypes).map((type) => ({
                        label: type.toUpperCase(),
                        value: type,
                      }))}
                    />
                  </Form.Item>
                </div>
              </div>
              {/* {showRegistration && (
                <Form.Item name='is_vendor' valuePropName='checked'>
                  <Checkbox>Show for Sponsorship Registrations Only </Checkbox>
                </Form.Item>
              )} */}
              {showSponsorPerks && (
                <div>
                  <div className='input-container'>
                    <div className='form-column'>
                      <Form.Item
                        label='Sponsorship Type'
                        name='sponsorship_type'
                        rules={[
                          {
                            required: true,
                            message: 'Sponsorship Type should not be empty!',
                          },
                        ]}
                        valuePropName='value'>
                        <Select
                          prefixCls='common-select-register'
                          placeholder='Select Sponsorship Type'>
                          {Object.entries(SponsorshipType).map(
                            ([key, value]) => (
                              <Option key={key} value={value}>
                                {key}
                              </Option>
                            ),
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                  <div className='input-container'>
                    <div className='form-column'>
                      <Form.Item
                        label='Meeting Presentation'
                        name='workshop_attendees'
                        rules={[
                          {
                            required: true,
                            message:
                              'Meeting Presentation should not be empty!',
                          },
                          {
                            validator: (_, value) =>
                              validateEventOptionNumberInput(
                                value,
                                'Meeting Presentation',
                                10,
                              ),
                          },
                        ]}>
                        <Input
                          prefixCls='common-input-user'
                          className='genral-form'
                          placeholder='Enter the number of Meeting Presentation'
                        />
                      </Form.Item>
                      <Form.Item
                        label='Lunch Limit'
                        name='free_lunch'
                        rules={[
                          {
                            required: true,
                            message: 'Lunch Limit should not be empty!',
                          },
                          {
                            validator: (_, value) =>
                              validateEventOptionNumberInput(
                                value,
                                'Free lunch',
                              ),
                          },
                        ]}>
                        <Input
                          prefixCls='common-input-user'
                          className='genral-form'
                          placeholder='Enter number of free lunch'
                        />
                      </Form.Item>
                      <Form.Item
                        label='Dinner with Speaker'
                        name='dinner_with_speaker'
                        rules={[
                          {
                            required: true,
                            message: 'Dinner with speaker should not be empty!',
                          },
                          {
                            validator: (_, value) =>
                              validateEventOptionNumberInput(
                                value,
                                'Dinner with speaker',
                                10,
                              ),
                          },
                        ]}>
                        <Input
                          prefixCls='common-input-user'
                          className='genral-form'
                          placeholder='Enter number of dinner with speaker'
                        />
                      </Form.Item>
                    </div>
                    <div className='form-column'>
                      <Form.Item
                        label='Attendees Price'
                        name='attendees_price'
                        disabled
                        rules={[
                          {
                            validator: (_, value) =>
                              NumberWithoutDecimalValidation(
                                value,
                                'Attendees price',
                              ),
                          },
                        ]}>
                        <Input
                          prefixCls='common-input-user'
                          className='genral-form'
                          placeholder='Enter attendees price'
                        />
                      </Form.Item>
                      <Form.Item
                        label='Lunch Price'
                        name='lunch_price'
                        rules={[
                          {
                            required: false,
                            message: 'Lunch price should not be empty!',
                          },
                          {
                            validator: (_, value) =>
                              NumberWithoutDecimalValidation(
                                value,
                                'Lunch price',
                              ),
                          },
                        ]}>
                        <Input
                          prefixCls='common-input-user'
                          className='genral-form'
                          placeholder='Enter lunch price'
                        />
                      </Form.Item>
                      <Form.Item
                        label='Dinner with Speaker Price'
                        name='dinner_with_speaker_price'
                        rules={[
                          {
                            required: false,
                            message:
                              'Dinner with speaker price should not be empty!',
                          },
                          {
                            validator: (_, value) =>
                              NumberWithoutDecimalValidation(
                                value,
                                'Dinner with speaker price',
                              ),
                          },
                        ]}>
                        <Input
                          prefixCls='common-input-user'
                          className='genral-form'
                          placeholder='Enter dinner with speaker price'
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='input-container'>
                    <div className='form-column'>
                      <Form.Item
                        label='Sponsor Perk Content'
                        name='sponsor_perk_content'
                        rules={[
                          {
                            validator: (_, value) =>
                              NoEmptySpaceInput(value, 'sponsor perk content'),
                          },
                        ]}>
                        <Input
                          prefixCls='common-input-user'
                          className='genral-form'
                          placeholder='Enter sponsor perk content'
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='input-container'>
                    <div className='form-column'>
                      <Form.Item
                        label='Sponsor Contact Content'
                        name='sponsor_contact_content'
                        rules={[
                          {
                            required: false,
                            message:
                              'Sponsor contact content should not be empty!',
                          },
                          {
                            validator: (_, value) =>
                              NoEmptySpaceInput(
                                value,
                                'sponsor contact content',
                              ),
                          },
                        ]}>
                        <Input
                          prefixCls='common-input-user'
                          className='genral-form'
                          placeholder='Enter sponsor contact content'
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className='input-container'>
                    <div className='form-column'>
                      <Form.Item
                        label='Sponsorship Perks'
                        name='benefits'
                        rules={[
                          {
                            required: true,
                            message: 'Sponsorship perks should not be empty!',
                          },
                        ]}>
                        <CommonEditor
                          value={editorInfoState}
                          onChange={setEditorInfoState}
                          text='sponsorship perks'
                          placeholderValue='sponsorship perks'
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              )}

              <Form.Item>
                <div className='button-container'>
                  <Button
                    id='adminEventOptions-backButton-button'
                    className='common-submit-login common-form-submit common-form-cancel'
                    onClick={() => {
                      backToEventOptions();
                    }}>
                    Back
                  </Button>
                  <div className='form-flex-button'>
                    <Button
                      id='adminEventOptions-submitButton-button'
                      type='primary'
                      htmlType='submit'
                      //disabled={submit ? true : false}
                      className='common-submit-login common-form-submit'>
                      {submitLabel}
                    </Button>
                    <Button
                      id='adminEventOptions-resetButton-button'
                      className='common-submit-login common-form-submit common-form-cancel'
                      htmlType='button'
                      onClick={onReset}>
                      Clear
                    </Button>
                  </div>
                </div>
              </Form.Item>
            </Form>
          </Col>
          <Col span={8}>
            <EventOptionDetails />
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default EventOptionsFormComponent;

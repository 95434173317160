import React, {useEffect, useState} from 'react';
import {Divider, Drawer, Menu, Collapse, Row, Col, Space} from 'antd';
import './ToggleSwitch.css';
import {useNavigate} from 'react-router-dom';
import closeIcon from './../../../Assets/close.svg';
import {UserOutlined} from '@ant-design/icons';
import {createSelectionMenuOptionsUtil} from '../../../Util/commonUtility';
import arrowDown from './../../../Assets/svgs/arrowDown.svg';
import Link from 'antd/es/typography/Link';
import {useDispatch, useSelector} from 'react-redux';
import {setMenuRoute} from '../../../Store/Actions';
const {Panel} = Collapse;

const ToggleSwitch = ({profileView, logout, chapterList, chapterLogo}) => {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const {setMenuRouteState} = useSelector(({chapters}) => chapters);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const currentPath = window.location.pathname;

  const visibleChapters = chapterList?.filter((chapter) => {
    return chapter?.is_visible_on_map === true;
  });

  const userLogout = () => {
    logout();
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setIsDrawerVisible(true);
    }
  };

  const navigate = useNavigate();
  const onMenuItemClick = (e) => {
    setIsChecked(false);
    setIsDrawerVisible(false);
    navigate(`/${e.key}`);
  };

  useEffect(() => {
    handleCloseDrawer();
  }, [setMenuRouteState]);

  const menus = [
    {
      id: 'header-aboutpbss-1',
      label: 'About PBSS',
      key: 'aboutUs',
    },
    {
      id: 'header-events-2',
      label: 'Events',
      key: 'events',
      children: [
        {
          label: 'Pick a Location',
          key: 'PickLocation',
          children: [
            ...createSelectionMenuOptionsUtil(
              visibleChapters,
              'normalized_name',
              'name',
            ),
            {label: 'PBSS International', key: ''},
          ],
        },
        {
          label: 'Event Types',
          key: 'EventTypes',
          children: [
            {label: 'Seminar Luncheons', key: 'eventType/seminarLuncheons'},
            {label: 'Workshops', key: 'eventType/workshops'},
            {label: 'Symposia', key: 'eventType/symposia'},
            {label: 'Dinner Seminars', key: 'eventType/dinnerSeminars'},
          ],
        },
      ],
    },
    {
      id: 'header-sponsorships-2',
      label: 'Sponsorships',
      key: 'sponsorshipInfo',
    },
    {
      id: 'header-jobs-4',
      label: 'Job Postings',
      key: 'jobs/approved',
    },
    {
      id: 'header-resources-5',
      label: 'Resources',
      key: 'resources',
      children: [
        {label: 'STEM outreach', key: 'stemOutreach'},
        {label: 'Other Partners', key: 'otherPartners'},
      ],
    },
    {
      id: 'header-contactus-6',
      label: 'Contact Us',
      key: 'contactUs',
    },
    {
      id: 'header-faq-7',
      label: 'FAQ',
      key: 'faq',
    },
    {
      id: 'header-myEventCart-8',
      label: 'My Cart',
      key: 'myCart',
    },
  ];

  const handleCloseDrawer = () => {
    setIsDrawerVisible(false);
    setIsChecked(false);
    dispatch(setMenuRoute(false));
  };

  return (
    <div className='toggle-switch'>
      <input
        id='checkbox'
        type='checkbox'
        checked={isChecked}
        onChange={handleToggle}
      />
      <label
        className={`toggle ${isChecked ? 'checked' : ''}`}
        htmlFor='checkbox'>
        <div id='bar1' className='bars'></div>
        <div id='bar2' className='bars'></div>
        <div id='bar3' className='bars'></div>
      </label>

      <Drawer
        className='mobile-menu'
        placement='right'
        closable={true}
        closeIcon={<img src={closeIcon} />}
        onClose={handleCloseDrawer}
        visible={isDrawerVisible}
        width={'fit-content'}
        extra={
          <Space>
            <img
              onClick={() => {
                handleCloseDrawer();
                navigate('/');
              }}
              className='drawer-header-logo'
              crossOrigin='anonymous'
              src={chapterLogo}
            />
          </Space>
        }>
        <Menu
          onClick={onMenuItemClick}
          className='drawer-menu'
          prefixCls={`pbss-menu`}
          mode='inline'
          items={menus}
        />

        <Row gutter={[48]} className='profile-auth-container'>
          {userInfo && userInfo.role ? (
            <Col span={24}>
              <Collapse
                prefixCls='account-collapse'
                expandIconPosition='end'
                accordion>
                <Panel
                  header={
                    <span>
                      <UserOutlined /> My Account
                    </span>
                  }
                  key='1'>
                  {profileView}
                </Panel>
              </Collapse>
            </Col>
          ) : null}
          <Col span={24}>
            <div className='auth-button-container'>
              {userInfo && userInfo.role ? (
                <button
                  className='common-button-header bg-remove extend-button-w extend-settings-button'
                  onClick={userLogout}>
                  Logout
                </button>
              ) : (
                <>
                  {currentPath !== '/register' && (
                    <button
                      onClick={() => {
                        handleCloseDrawer();
                        navigate('/register');
                      }}
                      id='join'
                      className='common-button-header bg-remove extend-button-w extend-settings-button'>
                      Join PBSS
                    </button>
                  )}
                  {currentPath !== '/login' && (
                    <button
                      onClick={() => {
                        handleCloseDrawer();
                        navigate('/login');
                      }}
                      id='login'
                      className='common-button-header extend-settings-button'>
                      Login
                    </button>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Drawer>
    </div>
  );
};

export default ToggleSwitch;

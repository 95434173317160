import React, {useEffect, useState} from 'react';
import SponsorsFormComponent from '../../../../Components/AdminComponents/Sponsors/SponsorsForm';
import {useDispatch, useSelector} from 'react-redux';
import {
  addSponsors,
  fetchSponsorsById,
  updateSponsorById,
} from '../../../../Store/Actions';
import {useNavigate} from 'react-router-dom';

const SponsorsFormContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {fetchSponsorData} = useSelector(({sponsors}) => sponsors);
  const [editData, setEditData] = useState();

  useEffect(() => {
    if (fetchSponsorData) {
      setEditData(fetchSponsorData?.data);
    }
  }, [fetchSponsorData]);

  const postSponsors = async (Data) => {
    dispatch(addSponsors(Data, navigate));
  };

  const updateSponsors = async (Data, id) => {
    dispatch(updateSponsorById(Data, id, navigate));
  };

  const fetchSponsorById = async (id) => {
    dispatch(fetchSponsorsById(id));
  };

  return (
    <div>
      <SponsorsFormComponent
        postSponsors={(Data) => {
          postSponsors(Data);
        }}
        updateSponsors={(id, Data) => {
          updateSponsors(id, Data);
        }}
        fetchSponsorById={(id) => {
          fetchSponsorById(id);
        }}
        EditData={editData}
      />
    </div>
  );
};

export default SponsorsFormContainer;

import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  createEventOptionGroups,
  editEventOptionGroups,
  getEventOptionGroupsById,
} from '../../../../Store/Actions/eventOptionGroups';
import EventOptionGroupsFormComponent from '../../../../Components/AdminComponents/EventOptionGroups/EventOptionGroupsForm';

const EventOptionGroupsFormContainer = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {getEventOptionGroupsData} = useSelector(
    ({eventOptionGroups}) => eventOptionGroups,
  );
  const [editData, setEditData] = useState();

  useEffect(() => {
    if (getEventOptionGroupsData) {
      setEditData(getEventOptionGroupsData?.data);
    }
  }, [getEventOptionGroupsData]);

  const postEventOptionGroups = async (Data) => {
    dispatch(createEventOptionGroups(Data, navigate));
  };

  const updateEventOptionGroups = async (Data, id) => {
    dispatch(editEventOptionGroups(Data, id, navigate));
  };
  const fetchEventOptionGroupsById = async (id) => {
    dispatch(getEventOptionGroupsById(id));
  };
  return (
    <div>
      <EventOptionGroupsFormComponent
        postEventOptionGroups={(Data) => {
          postEventOptionGroups(Data);
        }}
        updateEventOptionGroups={(id, Data) => {
          updateEventOptionGroups(id, Data);
        }}
        fetchEventOptionGroupsById={(id) => {
          fetchEventOptionGroupsById(id);
        }}
        EditData={editData}
      />
    </div>
  );
};

export default EventOptionGroupsFormContainer;

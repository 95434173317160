import React, {useEffect, useState} from 'react';
import {Space, Table, Popconfirm, Tooltip, Button, Popover} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FilterOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import './index.css';
import {useNavigate} from 'react-router-dom';
import {
  PageSizeArray,
  emailCodeColumnValues,
  PaginationPosition,
  emailCodesFilters,
} from '../../../Util/commonUtility';
import {htmlToDraft} from 'html-to-draftjs';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import {ApplicationString} from '../../../Localization/Locales/en';
import editIcon from '../../../Assets/editIcon.svg';
import deleteIcon from '../../../Assets/deleteIcon.svg';
import plus from '../../../Assets/Plus.svg';
import UpdateHistoryTableInfo from '../HistoryView';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {useSelector} from 'react-redux';
const EmailCodeComponent = (props) => {
  let navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const {
    allEmailCodesData,
    fetchEmailCodes,
    paging,
    handlePaginationChange,
    deleteEmailCodes,
    sortParams,
    handleTableChange,
    searchValues,
  } = props;
  useEffect(() => {
    searchValues && setExpanded(true);
  }, [searchValues]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const ps = useSelector(({pageSizes}) => pageSizes.pageSizes.emailCodes);
  const handleSearch = (values) => {
    fetchEmailCodes(values);
  };
  const convertToPlainText = (html) => {
    const temporaryElement = document.createElement('div');
    temporaryElement.innerHTML = html;
    return temporaryElement.textContent || temporaryElement.innerText || '';
  };

  const handleEditClick = async (id) => {
    navigate(`/admin/emailTemplates/update/${id}`);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      ellipsis: true,
      sorter: true,
      width: '2%',
    },
    {
      title: 'Template Details',
      dataIndex: 'details',
      key: 'details',
      sorter: true,
      width: '15%',
    },
    {
      title: 'Email Template',
      dataIndex: 'email_template',
      key: 'email_template',
      width: '10%',
      render: (text, record) => {
        const html = {__html: text};
        return (
          <Popover
            prefixCls='extend-popover-event-temaplate'
            content={
              <div
                className='pop-over-scroll'
                dangerouslySetInnerHTML={html}></div>
            }
            trigger='hover'
            title='Description'
            arrow={false}>
            <Button
              type='text'
              id={`adminEmailTemplates-ViewButton-${record.id}`}
              prefixCls='common-button-popover'>
              <EyeOutlined /> View Email Template Description
            </Button>
          </Popover>
        );
      },
    },

    {
      title: '',
      key: 'action',
      width: '4%',
      fixed: 'right',
      render: (_, record) => (
        <AdminActionsDropdown
          record={record}
          editBool={true}
          // deleteBool={true}
          // handleDeleteClick={deleteEmailCodes}
          handleEditClick={handleEditClick}
          text='Email Template'
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });

  const searchColumn = columns.filter((column) =>
    emailCodeColumnValues.includes(column.dataIndex),
  );

  return (
    <div className='main-container'>
      <div className='search-container' style={{gap: '10px'}}>
        <Button onClick={toggleExpand} id='AdFeeComponent-button-filterSearch'>
          {expanded ? <FilterOutlined /> : <FilterOutlined />}{' '}
          {ApplicationString['dynamicSearch.button.heading']}
        </Button>
        {/* <Button
          type='primary'
          onClick={() => navigate('/admin/emailTemplates/add')}
          id='EmailCodesComponent-button-addEmailCodes'
          className='common-button'>
          <div className='icon-container'>
            <img src={plus} alt='plus' className='icon-plus' />
            <span className='add-content'>
              {ApplicationString['emailCodesComponent.button.addForm']}
            </span>
          </div>
        </Button> */}
      </div>
      {expanded && (
        <DynamicSearchForm
          columns={emailCodesFilters}
          onSearch={handleSearch}
          savedFilteredValues={searchValues}
          title={'email_template'}
        />
      )}
      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          columns={columns}
          onChange={handleTableChange}
          dataSource={allEmailCodesData}
          key={allEmailCodesData?.map((item) => {
            item.id;
          })}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          scroll={{x: columns?.length * 80}}
          // sticky={true}
          pagination={{
            onChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            current: paging.page,
            total: paging.totalCount,
            pageSize: ps || paging.pageCount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default EmailCodeComponent;

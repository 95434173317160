import {
  GET_ALL_SPECIAL_PAYMENT_DETAILS,
  POST_SPECIAL_PAYMENT_DETAILS,
  POST_SPECIAL_PAYMENT_DETAILS_SUCCESS,
  POST_TOKEN_DATA,
  RESET_SPECIAL_PAYMENT,
} from '../../Constant/ActionTypes';

const initialState = {
  specialPaymentDetails: false,
  specialPaymentSuccess: false,
  specialPaymentDetailsData: false,
  clientKeys: false,
};

const SpecialPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_SPECIAL_PAYMENT_DETAILS:
      return {
        ...state,
        specialPaymentDetails: false,
        specialPaymentDetailsData: false,
      };
    case POST_SPECIAL_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        specialPaymentSuccess: true,
        specialPaymentDetailsData: false,
      };
    case RESET_SPECIAL_PAYMENT:
      return {
        ...state,
        specialPaymentDetails: false,
        specialPaymentSuccess: false,
      };
    case GET_ALL_SPECIAL_PAYMENT_DETAILS:
      return {
        specialPaymentDetailsData: action.payload,
      };
    case POST_TOKEN_DATA:
      return {
        ...state,
        clientKeys: action.payload,
        specialPaymentDetailsData: false,
      };
    default:
      return state;
  }
};

export default SpecialPaymentReducer;

import React from 'react';
import {Modal, Button} from 'antd';

const CommonUserModal = ({
  visible,
  onCancel,
  onConfirm,
  content,
  confirmBtnText,
  usedForAdminSide = false,
}) => {
  return (
    <Modal
      className='modal-updateProfile'
      maskClosable={true}
      width={400}
      open={visible}
      onCancel={onCancel}
      footer={[
        <React.Fragment key={1}>
          <div className='modal-updateProfile-footer'>
            <Button
              className={
                usedForAdminSide ? 'view-button' : 'modal-cancelButton-footer'
              }
              key='cancel'
              onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className={
                usedForAdminSide ? 'common-button' : 'modal-verifyButton-footer'
              }
              key='submit'
              onClick={onConfirm}
              type='primary'>
              {confirmBtnText}
            </Button>
          </div>
        </React.Fragment>,
      ]}
      okText='Verify'>
      <div className='modal-updateProfile-container'>
        <p>{content}</p>
      </div>
    </Modal>
  );
};

export default CommonUserModal;

import React, {useState} from 'react';
import CommonPageHeroText from './../../../../CommonComponents/CommonPageHeroText/index';
import SectionLayout from './../../../../CommonComponents/Layout/sectionLayout';
import CartConfirmationAction from './../../../../CommonComponents/CommonCartConfirmaton/index';
import CommonCartTable from '../../CommonCartTable';
import './index.css';
import {useDispatch} from 'react-redux';
import {postAdsPayment} from '../../../../../Store/Actions';
import {useNavigate, useParams} from 'react-router-dom';
import {validatePaymentPrice} from '../../../../../Util/commonUtility';
import NavigationComponent from '../../../../CommonComponents/CommonBackButton';

const NormalAdsCartComponent = ({adData, adFeesOptions}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const [isAdEditing, setISAdEditing] = useState();
  // const adsData = {
  //   vendorName: 'Viva Biotech',
  //   desc: 'Join Viva Reception if you have a need in MedChem, SBDD, FBDD, CryoEM, ASMS, SPR, HDX, CADD, Bioassay, CMC, Ab,',
  //   adsType: 'Regular',
  //   fees: 1000,
  // };
  const handlePaymentClick = () => {
    if (validatePaymentPrice(adData[0]?.ad_fee, dispatch)) return;
    dispatch(
      postAdsPayment(
        {
          adsId: parseInt(id),
        },
        navigate,
      ),
    );
  };
  return (
    <div className='common-cart-container'>
      {/* <CommonPageHeroText
        heading={'Ads '}
        subheading={'Get Details about event  price, schedule & more..'}
      /> */}
      <div>
        <SectionLayout>
          <div className='common-cart-table-conatiner'>
            <NavigationComponent name={'Go Back to Ads'} urlPath={`ads`} />
            <CommonCartTable
              adsData={adData}
              text='ad'
              adFeesOptions={adFeesOptions}
              setISAdEditing={setISAdEditing}
            />
          </div>
          {/* <div className='common-cart-coupon-container'>
            <PromotionalCodeInput
              subTitle='If you have promotional code  input here'
              buttonText='Redeem'
              onApplyClick={(promoCode) => {
                const Data = {
                  code: promoCode,
                };
                // applyCouponCode(Data);
              }}
            />
          </div> */}
          <div>
            <CartConfirmationAction
              heading='Total amount to be paid'
              price={adData?.length > 0 && adData[0]?.ad_fee}
              onPay={handlePaymentClick}
              isAdEditing={isAdEditing}
            />
          </div>
        </SectionLayout>
      </div>
    </div>
  );
};

export default NormalAdsCartComponent;

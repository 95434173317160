// ChapterNavigation.js

import React, {useEffect, useRef, useState} from 'react';
import PinSvg from './../../../Assets/svgs/pin.svg';
import {Select} from 'antd';
import {Option} from 'antd/es/mentions';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {ApplicationString} from '../../../Localization/Locales/en';

const ChapterNavigation = ({chaptersList, handleChapterClick}) => {
  const [shouldRender, setshouldRender] = useState(false);
  const navigationContainerRef = useRef(null);
  const chapterRefs = useRef([]);
  const [showArrowButton, setShowArrowButton] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const scrollLeft = () => {
    if (currentIndex > 0) {
      const scrollAmount =
        navigationContainerRef.current.scrollWidth / chaptersList.length;
      navigationContainerRef.current.scrollBy({
        top: 0,
        left: -scrollAmount,
        behavior: 'smooth',
      });
      setCurrentIndex(currentIndex - 1);
    }
  };

  const scrollRight = () => {
    if (currentIndex < chaptersList.length - 1) {
      const scrollAmount =
        navigationContainerRef.current.scrollWidth / chaptersList.length;
      navigationContainerRef.current.scrollBy({
        top: 0,
        left: scrollAmount,
        behavior: 'smooth',
      });
      setCurrentIndex(currentIndex + 1);
    }
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.outerWidth > 469) {
        setshouldRender(true);
      } else {
        setshouldRender(false);
      }
      if (window.outerWidth < 1440) {
        setShowArrowButton(true);
      } else {
        setShowArrowButton(false);
      }
    };
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    chapterRefs.current = chapterRefs.current.slice(0, chaptersList.length);
  }, [chaptersList]);

  return (
    <div className='regin-container'>
      <div className='regin-head-container'>
        <p className='regin-heading'>
          {ApplicationString['userChpaterNavigaitonComponent.heading']}
        </p>
        <label className='regin-label-text'>
          {ApplicationString['userChpaterNavigationComponent.subheading']}
        </label>
      </div>
      {shouldRender ? (
        <span className='chapter-navigation-wrapper'>
          {chaptersList && (chaptersList.length > 6 || showArrowButton) && (
            <button
              className='chapter-navigation-arrow-button'
              onClick={scrollLeft}>
              <LeftOutlined />
            </button>
          )}
          <div
            ref={navigationContainerRef}
            id='chapter-navigation-container'
            className='chapter-navigation-button'>
            {chaptersList &&
              chaptersList?.map((item) => {
                const shouldFitContent = chaptersList.length <= 1;
                return (
                  <button
                    id={`chapter-navigation-button_${item.id}`}
                    key={item.id}
                    onClick={() =>
                      handleChapterClick(item.id, item.normalized_name)
                    }
                    className={shouldFitContent ? 'fit-content-button' : ''}>
                    {item.name} <img src={PinSvg} />
                  </button>
                );
              })}
          </div>
          {chaptersList && (chaptersList.length > 6 || showArrowButton) && (
            <button
              className='chapter-navigation-arrow-button'
              onClick={scrollRight}>
              <RightOutlined />
            </button>
          )}
        </span>
      ) : (
        <Select
          prefixCls='chapters-select'
          placeholder='Select chapter'
          onChange={(value, key) => {
            handleChapterClick(key.key, key.label);
          }}>
          {chaptersList &&
            chaptersList.map((item) => (
              <Option
                key={item.id}
                value={item.name}
                label={item.normalized_name}>
                {item.name}
              </Option>
            ))}
        </Select>
      )}
    </div>
  );
};

export default ChapterNavigation;

import React from 'react';
import './style.css';
import {Divider} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setMenuRoute} from '../../../Store/Actions';

const ProfileInfo2 = () => {
  const dispatch = useDispatch();

  const handleMenuClose = () => {
    dispatch(setMenuRoute(true));
  };

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  switch (true) {
    case userInfo && userInfo.role === 'admin':
    case userInfo && userInfo.role === 'super_admin':
      return (
        <div className='main-profile-container'>
          <div className='profile-view-container'>
            <div className='profile-user-wapper ms-auto'>
              <span>{userInfo?.first_name?.slice(0, 1)}</span>
              <div className='profile-email-text'>
                <p>{userInfo?.first_name}</p>
                <label>{userInfo?.email}</label>
              </div>
            </div>
          </div>
          <Link id='home-navigateAdmin-selectButton' to='/admin/events'>
            <li>Switch to Admin Functions</li>
          </Link>

          {userInfo?.first_name ? (
            <>
              <Link to='/updateProfile'>
                <li onClick={handleMenuClose}>Update Profile</li>
              </Link>
              <Divider prefixCls='profile-divider' />
              <Link to='/jobs'>
                <li onClick={handleMenuClose}>Create a Job Posting</li>
              </Link>
              <Link to='/ads'>
                <li onClick={handleMenuClose}>Create an Ad</li>
              </Link>
              <Link to='/myEvents'>
                <li onClick={handleMenuClose}>{`My Event(s)`}</li>
              </Link>
            </>
          ) : null}
        </div>
      );

    case userInfo && userInfo.role === 'user':
      return (
        <div className='main-profile-container'>
          <div className='profile-view-container'>
            <div className='profile-user-wapper ms-auto'>
              <span>{userInfo?.first_name?.slice(0, 1)}</span>
              <div className='profile-email-text'>
                <p>{userInfo?.first_name}</p>
                <label>{userInfo?.email}</label>
              </div>
            </div>
          </div>
          <Link to='/updateProfile'>
            <li onClick={handleMenuClose}>Update Profile</li>
          </Link>
          <Divider prefixCls='profile-divider' />
          <Link to='/jobs'>
            <li onClick={handleMenuClose}>Create a Job Posting</li>
          </Link>
          <Link to='/ads'>
            <li onClick={handleMenuClose}>Create an Ad</li>
          </Link>
          <Link to='/myEvents'>
            <li onClick={handleMenuClose}>My Event(s)</li>
          </Link>
        </div>
      );
  }
};

export default ProfileInfo2;

import React, {useEffect, useState} from 'react';
import UserAdsComponent from '../../../Components/UserComponents/Ads';
import {useDispatch, useSelector} from 'react-redux';
import {
  createSelectionOptionsUtil,
  getLocalStorageUserInfo,
} from '../../../Util/commonUtility';
import {fetchAdsByUserId, getAllCompanies} from '../../../Store/Actions';
import AttendeesCartComponent from '../../../Components/UserComponents/AttendeesCart';
import {
  normalRegistrationByUserAndEventId,
  UpdateAttendee,
  applyCouponCodes,
  deleteAttendee,
  getAttendesByUserAndEventId,
  sponsorRegistrationByUserAndEventId,
  verifyCouponCodes,
  getAttendeesByTransactionId,
  updateFoodAndRegistrationOptions,
} from '../../../Store/Actions/eventRegister';
import {
  getAllEventOptions,
  getEventOptionsByEventId,
} from '../../../Store/Actions/eventOptions';
import {useNavigate, useParams} from 'react-router-dom';

const AttendeesCartContainer = () => {
  const {id, transactionId} = useParams();
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const {allAttendees} = useSelector(({eventRegister}) => eventRegister);
  const {allAttendeesAfterPayment} = useSelector(
    ({eventRegister}) => eventRegister,
  );
  const [allAttendeesData, setAllAttendeesData] = useState([]);
  const [allEventOptionsFoodData, setAllEventOptionsFoodData] = useState([]);
  const [allEventOptionsRegistrationData, setAllEventOptionsRegistrationData] =
    useState([]);
  const {getEventOptionByEventId} = useSelector(
    ({eventOptions}) => eventOptions,
  );
  const [eventOptionsFoodData, setEventOptionsFoodData] = useState([]);
  const [attendeesData, setAttendeesData] = useState([]);
  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [appliedCartCoupon, setAppliedCartCoupon] = useState();
  const [isAppliedCoupon, setIsAppliedCoupon] = useState(false);
  const [eventType, setEventType] = useState('');
  const [eventName, setEventName] = useState('');
  const [isSponsorAvailable, setIsSponsorAvailable] = useState(false);

  useEffect(() => {
    if (getEventOptionByEventId) {
      const foodOptionsWithNoFood = getEventOptionByEventId?.data?.food;
      const filteredRegData = getEventOptionByEventId?.data?.registration;
      setEventOptionsFoodData(
        createSelectionOptionsUtil(
          foodOptionsWithNoFood,
          'id',
          'name',
          'price',
        ),
      );
      setAllEventOptionsRegistrationData(
        createSelectionOptionsUtil(filteredRegData, 'id', 'name', 'price'),
      );
    } else {
      dispatch(getEventOptionsByEventId(id));
    }
  }, [getEventOptionByEventId]);

  useEffect(() => {
    if (eventOptionsFoodData) {
      setAllEventOptionsFoodData(() => [
        ...eventOptionsFoodData,
        {value: 'no-food', label: 'No Food'},
      ]);
    }
  }, [eventOptionsFoodData]);

  useEffect(() => {
    transactionId
      ? getAttendeeByTransactionId(transactionId)
      : getAttendeeByEventId(id);
  }, [transactionId, id]);

  useEffect(() => {
    if (transactionId) {
      const commonDataVar = allAttendeesAfterPayment?.data?.eventRegistrations;
      if (allAttendeesAfterPayment) {
        const Data = commonDataVar?.attendees;
        setAllAttendeesData(Data);
        setEventName(allAttendeesAfterPayment?.data?.event_name);
      } else {
        getAttendeeByTransactionId(transactionId);
      }
    } else {
      if (allAttendees) {
        setTotalCartPrice({
          total_price_per_cart: allAttendees?.data?.total_price,
          discount: allAttendees?.data?.discount,
          discounted_price: allAttendees?.data?.discounted_price,
          refund_credit: allAttendees?.data?.refund_credit,
          refund_credit_for_regular_attendees:
            allAttendees?.data?.refund_credit,
        });
        const Data = allAttendees?.data?.event_registrations?.attendees;
        setAllAttendeesData(Data);
        setAppliedCartCoupon(allAttendees?.data?.discount_code);
        setEventType(allAttendees?.data?.event_type_name);
        setEventName(allAttendees?.data?.event_name);
        setIsSponsorAvailable(allAttendees?.data?.sponsorship_available);
      } else {
        getAttendeeByEventId(id);
      }
    }
  }, [allAttendees, allAttendeesAfterPayment, transactionId, id]);

  useEffect(() => {
    if (isAppliedCoupon) {
      getAttendeeByEventId(id);
    }
  }, [isAppliedCoupon]);

  useEffect(() => {
    if (allAttendeesData) {
      const Data = Array.isArray(allAttendeesData)
        ? allAttendeesData.map((item) => {
            return {
              ...item,
              food_option: item?.event_registration_options?.food,
              registration_option:
                item?.event_registration_options?.registration,
            };
          })
        : [];
      setAttendeesData(Data);
    }
  }, [allAttendeesData]);

  const getAttendeeByEventId = (id) => {
    const attendeeType = 'normal';
    dispatch(getAttendesByUserAndEventId(id, attendeeType));
  };
  const getAttendeeByTransactionId = (transactionId) => {
    dispatch(getAttendeesByTransactionId(transactionId));
  };
  const applyCouponCode = async (Data) => {
    dispatch(applyCouponCodes(Data, setIsAppliedCoupon));
  };
  const editAttendeeById = (
    Data,
    id,
    setEditingKey,
    form,
    setIsAddAttendee,
  ) => {
    transactionId
      ? dispatch(
          updateFoodAndRegistrationOptions(Data, id, setEditingKey, form),
        )
      : dispatch(
          UpdateAttendee(Data, id, setEditingKey, form, setIsAddAttendee),
        );
  };
  const deleteAttendeeById = async (id) => {
    dispatch(deleteAttendee(id));
  };
  const paymentByUserRegistration = async (id) => {
    dispatch(
      normalRegistrationByUserAndEventId(
        id,
        navigate,
        totalCartPrice?.discounted_price === 0,
      ),
    );
  };
  // useEffect(() => {
  //   if (allCompanies) {
  //     setCompaniesData(
  //       createSelectionOptionsUtil(
  //         allCompanies?.data?.companies,
  //         'name',
  //         'name',
  //       ),
  //     );
  //   } else {
  //     dispatch(getAllCompanies());
  //   }
  // }, [allCompanies]);

  return (
    <div>
      <AttendeesCartComponent
        isSponsorAvailable={isSponsorAvailable}
        applyCouponCode={(Data) => {
          applyCouponCode(Data);
        }}
        attendeesData={attendeesData}
        allEventOptionsFoodData={allEventOptionsFoodData}
        allEventOptionsRegistrationData={allEventOptionsRegistrationData}
        totalCartPrice={totalCartPrice}
        editAttendeeById={(Data, id, setEditingKey, form, setIsAddAttendee) => {
          editAttendeeById(Data, id, setEditingKey, form, setIsAddAttendee);
        }}
        deleteAttendeeById={(id) => {
          deleteAttendeeById(id);
        }}
        paymentByUserRegistration={(id) => {
          paymentByUserRegistration(id);
        }}
        appliedCartCoupon={appliedCartCoupon}
        eventType={eventType}
        eventName={eventName}
        setAttendeesData={setAttendeesData}
      />
    </div>
  );
};

export default AttendeesCartContainer;

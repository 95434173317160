import React, {useEffect, useState} from 'react';
import {
  deleteAdById,
  editAdStatusById,
  getAllAds,
  getAllAdsWithoutPagination,
  resetAdsCsvDownloadState,
} from '../../../Store/Actions';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import {useDispatch, useSelector} from 'react-redux';
import AdsComponent from '../../../Components/AdminComponents/Ads';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';
import dayjs from 'dayjs';

const AdsContainer = () => {
  const dispatch = useDispatch();
  const {allAds, allAdsWithoutPagination} = useSelector(({ads}) => ads);
  const {allAccountAds} = useSelector(({ads}) => ads);
  const [listData, setListData] = useState();
  const [allAdsWithoutPaginationData, setAllAdsWithoutPaginationData] =
    useState();
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.ads,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.ads,
  );

  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pageCount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });
  const [totalAdsCount, setTotalAdsCount] = useState(10);
  const [adsFetchingWithoutPagination, setAdsFetchingWithoutPagination] =
    useState(false);
  const [totalAdFees, setTotalAdFees] = useState(0);

  const [totalAccountAdFees, setTotalAccountAdFees] = useState();
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  const searchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.ads,
  );

  useEffect(() => {
    if (allAds) {
      setListData(allAds?.data?.ads);
      setPagination({
        ...pagination,
        page: allAds?.data?.currentPage,
        totalPages: allAds?.data?.totalPage,
        totalCount: allAds?.data?.totalCount,
      });
      setTotalAdsCount(allAds?.data?.totalCount);
    } else {
      fetchAds();
    }
  }, [allAds?.data?.ads]);
  useEffect(() => {
    if (allAdsWithoutPagination) {
      setAllAdsWithoutPaginationData(allAdsWithoutPagination?.data?.ads);
      setTotalAdFees(allAdsWithoutPagination?.data?.totalAdFees);
    }
  }, [allAdsWithoutPagination?.data?.ads]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      const formattedSearchValues = {
        ...searchValues,
        date:
          searchValues?.date?.length > 0
            ? [
                dayjs(searchValues?.date[0]).format('YYYY-MM-DD'),
                dayjs(searchValues?.date[1]).format('YYYY-MM-DD'),
              ]
            : '',
      };
      fetchAds(formattedSearchValues);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  // useEffect(() => {
  //   if (allAds) {
  //     fetchAds();
  //   }
  // }, [
  //   pagination.page,
  //   pagination.pageCount,
  //   sortParams.field,
  //   sortParams.order,
  // ]);

  useEffect(() => {
    if (searchValues) {
      dispatch(setPageNumber('ads', 1));
      setPagination({
        ...pagination,
        page: 1,
      });
      setPaginationAndSortChanged(true);
    }
    dispatch(resetAdsCsvDownloadState());
    setAdsFetchingWithoutPagination(false);
  }, [searchValues]);

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
    dispatch(resetAdsCsvDownloadState());
    setAdsFetchingWithoutPagination(false);
  };
  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('ads', pageSize));
    dispatch(setPageNumber('ads', current));

    setPagination({
      ...pagination,
      page: current,
      pageCount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };
  const fetchAds = async (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(getAllAds(sortField, sortOrder, searchQueryData, pagination));
  };

  const DeleteAds = async (id) => {
    dispatch(deleteAdById(id));
  };

  const updateStatusById = async (adData, status) => {
    const id = adData.id;
    dispatch(editAdStatusById(id, status));
  };

  const fetchAdsWithoutPagination = async (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllAdsWithoutPagination(
        sortField,
        sortOrder,
        searchQueryData,
        totalAdsCount,
        setAdsFetchingWithoutPagination,
      ),
    );
  };

  return (
    <div>
      {/* {downloadCSV && (
        <CSVLink
          data={formattedAdsAccountData}
          header={headers}
          filename={`Ads_Report.csv`}></CSVLink>
      )} */}
      <AdsComponent
        allAdsData={listData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        DeleteAds={(id) => {
          DeleteAds(id);
        }}
        handleTableChange={handleTableChange}
        fetchAds={(searchValues) => {
          fetchAds(searchValues);
        }}
        updateStatusById={(adData, status) => {
          updateStatusById(adData, status);
        }}
        sortParams={sortParams}
        searchValues={searchValues}
        fetchAdsWithoutPagination={(searchValues) => {
          fetchAdsWithoutPagination(searchValues);
        }}
        adsFetchingWithoutPagination={adsFetchingWithoutPagination}
        totalAdFees={totalAdFees}
        allAdsWithoutPaginationData={allAdsWithoutPaginationData}
      />
    </div>
  );
};
export default AdsContainer;

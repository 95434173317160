import React from 'react';
import './PaymentFailed.css'; // Using the same CSS file
import {Button, Result} from 'antd';
import {useNavigate} from 'react-router-dom';

const PaymentFailure = () => {
  const navigate = useNavigate();
  return (
    <div className='send-email-success'>
      <Result
        status='error'
        title='Payment Failed'
        subTitle='Unfortunately, your payment could not be processed.
        Please try again or contact support if the issue persists.'
        extra={[
          <Button
            type='primary'
            key='goto_home'
            className='goto_signin_button'
            onClick={() => navigate('/')}>
            Back to home
          </Button>,
        ]}
      />
    </div>
  );
};

export default PaymentFailure;

import {
  DELETE_EVENTOPTIONGROUPS_BY_ID,
  GET_ALL_EVENTOPTIONGROUPS,
  GET_ALL_EVENTOPTIONGROUPS_BY_QUERY,
  GET_EVENTOPTIONGROUPS_BY_ID,
  POST_EVENTOPTIONGROUPS,
  UPDATE_EVENTOPTIONGROUPS_BY_ID,
} from '../../Constant/ActionTypes';

const initialState = {
  allEventOptionGroups: false,
  getEventOptionGroupsData: false,
  isCreateEventOptionGroups: false,
  isUpdateEventOptionGroups: false,
  allEventOptionGroupsQueryData: false,
  getEventOptionGroupsDataByUser: false,
};

const EventOptionGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EVENTOPTIONGROUPS:
      return {
        ...state,
        allEventOptionGroups: action.payload,
        isCreateEventOptionGroups: false,
        isUpdateEventOptionGroups: false,
        isUpdateEventOptionGroupsStatus: false,
      };
    case GET_ALL_EVENTOPTIONGROUPS_BY_QUERY:
      return {
        ...state,
        allEventOptionGroupsQueryData: action.payload,
        isCreateEventOptionGroups: false,
        isUpdateEventOptionGroups: false,
        isUpdateEventOptionGroupsStatus: false,
      };
    case GET_EVENTOPTIONGROUPS_BY_ID:
      return {
        ...state,
        getEventOptionGroupsData: action.payload,
      };
    case POST_EVENTOPTIONGROUPS:
      return {
        ...state,
        isCreateEventOptionGroups: true,
        allEventOptionGroups: false,
        allEventOptionGroupsQueryData: false,
      };
    case UPDATE_EVENTOPTIONGROUPS_BY_ID:
      return {
        ...state,
        isUpdateEventOptionGroups: true,
        allEventOptionGroups: false,
        allEventOptionGroupsQueryData: false,
      };
    case DELETE_EVENTOPTIONGROUPS_BY_ID:
      return {
        ...state,
        allEventOptionGroups: false,
        allEventOptionGroupsQueryData: false,
      };
    default:
      return state;
  }
};

export default EventOptionGroupsReducer;

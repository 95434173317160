import React, {useState} from 'react';
import {Collapse} from 'antd';
import './style.css';
import FAQDownArrowSvg from '../../../Assets/svgComponents/FAQDownArrowSvg';
import FAQUpArrowSvg from '../../../Assets/svgComponents/FAQUpArrowSvg';

const {Panel} = Collapse;

const CustomCollapse = ({data, titleHide}) => {
  const [activeKey, setActiveKey] = useState(null);

  const handleCollapseChange = (keys) => {
    setActiveKey(keys[0]);
  };

  if (!data) {
    data = [
      {
        title: ' How can I sponsor a PBSS event?',
        content:
          'You can sign up for either the major sponsorship or the vendor show of a seminar luncheon or a workshop by visiting the PBSS home page and clicking on the Register link in the event calendar.',
      },
      {
        title: 'Is there a membership fee to join?',
        content: 'No. Membership is free.',
      },
      {
        title: 'How do I print a receipt for a registered event?',
        content: `
          <p>Receipts are automatically sent to your registered email address when you successfully pay and submit registration for an event. If you cannot find the receipt in your inbox, please check your spam/junk folders and whitelist <a href="info@pbss.org" >info@pbss.org</a>
          </p>
          <ol class='list-padding' >
            <li>Alternatively, please go through the following steps to get a copy of your receipt:
            </li>
            <li>Log into your PBSS account.</li>
            <li>For an upcoming event, from the right-hand corner of the navigation bar, go to "My Account" and then "My Events." From the list of events, click the "Download Receipt" button next to the desired event.
            </li>
            <li>For a past event, go to “My Events” and then click “Attended” from the category dropdown. From the list of events, click the "Download Receipt" button next to the desired event.
            </li>
          </ol>
        `,
      },
      {
        title:
          'How much does it cost to attend an SF-Bay PBSS event and what do I get with my registration?',
        content: `
        <p>PBSS hosts both free and paid events.</p>
        <ol class='list-padding' >
          <li>
          Free events require registration for the event on pbss.org even though there are no associated costs. 
          </li>
          <li>For paid events, please click on each event on the Registration page to view costs. 
          </li>
        </ol>
        <p><u>For paid events, please click on each event on the Registration page to view costs.</u></p>
        <ol>
          <li>Lunch orders are not accepted on the event day
          </li>
          <li>
          Meeting Attendance:
            <ul class='list-padding'>
            <li>Regular Attendee: $295 for workshops and $195 for symposia (subject to change)
            </li></ul>
          </li>
          <li>Vendor Show Exhibition:
            <ul class='list-padding'><li>
	
            Participation in an in-person PBSS event as an exhibitor costs $695/event (subject to change)            </li>
            <li>
            Your registration fee includes:
              <ul class='list-padding'><li>Vendor show tabletop space where you may display your company materials through the event.</li>
              <li>2 subsidized meeting seats at $125 each.</li>
              <li>PDF Slide Deck of the presentations if the event is a workshop.</li>
              <li>Participation in a Raffle (you collect business cards from our attendees and participate in a drawing along with other vendors to giveaway any prize of your choice to the attendee).  
              </li>
              </ul>
            </li>
            </ul>
          </li>
          <li>Happy Hour Sponsorship:
           <ul class='list-padding'>
              <li>Participation in an in-person PBSS event as a Happy Hour Sponsor costs $975 (subject to change).</li>
              <li>Your registration fee includes:
                <ul class='list-padding'>
                  <li>Vendor show tabletop space where you may display your company materials through the event.
                  </li>
                  <li>PDF Slide Deck of the presentations if the event is a workshop.</li>
                  <li>Participation in a Raffle (you collect business cards from our attendees and participate in a drawing along with other vendors to giveaway any prize of your choice to the attendee).
                  </li>
                  <li>Your sponsorship is acknowledged in the meeting agenda.</li>
                  <li>A two-minute informal oral advert/blurb about your company and its offerings to our attendees before the panel discussion.
                  </li>
                </ul>
              </li>
           </ul>
          </li>
          <li>
          Lunch Sponsorship:
            <ul class='list-padding'>
              <li>Participation in an in-person PBSS event as a Lunch Sponsor costs $1,250 (subject to change). 
              </li>
              <li>Your registration fee includes:
                <ul class='list-padding'>
                  <li>Vendor show tabletop space where you may display your company materials through the event.
                  </li>
                  <li>PDF Slide Deck of the presentations if the event is a workshop.</li>
                  <li>Participation in a Raffle (you collect business cards from our attendees and participate in a drawing along with other vendors to giveaway any prize of your choice to the attendee).</li>
                  <li>Two free lunches for your representatives.
                  </li>
                  <li>Your sponsorship is acknowledged in the meeting agenda.</li>
                  <li>A two-minute informal oral advert/blurb about your company and its offerings to our attendees before the lunch break.</li>
                  <li>Flyer distribution to advertise your company’s offerings on the lunch tables before attendees come in for lunch.</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
          Major Sponsorship:
            <ul class='list-padding'>
              <li>Participation in an in-person PBSS event as a Major Sponsor costs $3,000 (subject to change). </li>
              <li>
              Your registration fee includes:
                <ul class='list-padding'>
                  <li>Exclusive vendor show tabletop space next to the workshop/symposium entrance where you may display your company materials through the event.</li>
                  <li>2 free workshop or symposium seats.</li>
                  <li>PDF Slide Deck of the presentations if the event is a workshop.</li>
                  <li>Participation in a Raffle (you collect business cards from our attendees and participate in a drawing along with other vendors to giveaway any prize of your choice to the attendee).</li>
                  <li>Two free lunches for your representatives.</li>
                  <li>A ten-minute oral podium slide presentation about your company and its offerings during the workshop/symposium.</li>
                  <li>Company flyer that advertises your company’s offerings will be emailed to the meeting attendees and will also be included in the workshop slide deck for all attendees. </li>
                  <li>Dinner invitation for 2 representatives to the post-meeting dinner for the organizers/speakers/sponsors (Depending on the hosting chapter).</li>
                  <li>You will receive the meeting attendees’ names and affiliations (their emails may be provided if they opted in during the registration process).</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
          Raffle Rules:
            <ul class='list-padding'>
              <li>The raffle typically takes place at the end of the last break/vendor show session and before the final presentation session.</li>
              <li>To minimize the raffle time, each participating vendor must pre-draw 3 winners’ names (one as the winner and the other two as backups), so that the winner’s name can be quickly announced.</li>
              <li>Each vendor rep has 30 seconds to announce their name, company name and offerings.</li>
              <li>Each vendor must give the prize(s) to one single winner.</li>
              <li>A person must be present to win.</li>
            </ul>
          </li>
        </ol>
      `,
      },
      {
        title: 'What are the lunch options for vendor representatives?',
        content: `
          <ol class='list-padding'>
            <li>The seminar luncheon vendor show:
              <ul class='list-padding'>
                <li>Lunches are provided free of charge. You can sign up for the lunch option when you sign up for the vendor show. Your other fellow reps attending the show must sign up for the lunch individually through the seminar luncheon registration process as regular attendees.</li>
              </ul>
            </li>
            <li>
            The workshop/forum luncheon vendor show:
              <ul class='list-padding'>
                <li>Lunches are optional for a fee.</li>
              </ul>
            </li>
          </ol>
        `,
      },
      {
        title: 'Should vendor reps pay for workshop attendance?',
        content: `<ul><li>For the major sponsor of a workshop, we allow two of the sponsor's representatives to attend the workshop free of charge. Please provide the names of the two attendees during the registration process.</li></ul>`,
      },
      {
        title: 'What benefits do I receive as a vendor? ',
        content: `
          <ol class='list-padding'>
            <li>	
Each vendor (and major sponsor) will get a 6-ft vendor show table. You can display anything appropriate on the table.</li>
            <li>For seminar luncheons and afternoon workshops, arrive by 11:00 AM at the venue to set up your display on a 6-ft table (first come first serve).</li>
            <li>For full-day workshops, arrive by 8:00 am to set up.</li>
            <li>If you have signed up for lunch, you need to check in with the PBSS check-in desk to get your lunch ticket.</li>
            <li>You are encouraged to bring a door prize to attract attendees (we limit one prize per vendor). If it is a seminar luncheon vendor show, the raffle drawing takes place at the end of the seminar, roughly ~1:15 PM. If it is a workshop seminar, the drawing takes place normally right before the final workshop session, ~4 PM.</li>
            <li>Vendor show time: before the event, during the breaks and during the Happy Hour.</li>
          </ol>
        `,
      },
      {
        title: 'What is PBSS policy on vendor speakers?',
        content: `We often receive interest from vendors in providing a speaker for one of our meetings. Unfortunately, in order to avoid any impression of commercial promotion or vendor favoritism, the PBSS policy generally prohibits the use of vendor speakers, except in rare cases where a vendor holds a unique technology that we are interested in hearing about. One compromise solution is that vendors can recommend a client scientist who is an expert on the vendor's technology.`,
      },
      {
        title: 'Can I ship materials in advance?',
        content: `
          <div>Please email us at info@pbss.org for the instructions as it depends on the location. Please note that some venues do not accept shipments or charge for retaining them.
          </div>
        `,
      },
      {
        title: 'What is PBSS policy on membership information sharing?',
        content: `
        In order to protect the privacy of our members, membership list is strictly used for PBSS business only and can not be shared with anyone or any party outside the PBSS management
        `,
      },
      {
        title: 'Will PBSS provide an Attendance Certificate?',
        content: `<p>We can provide an electronic copy (PDF) of the attendance certificate upon special request at an extra cost, typically $50. Please email us at <a>info@pbss.org</a> and indicate the date and title of the event. </p>`,
      },
      {
        title: 'What are the different types of events hosted by PBSS?',
        content: 'Seminar Luncheon, Workshops, Symposia, Dinner Seminars.',
      },
      {
        title: 'What is the cancellation and refund policy?',
        content: `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Refund Policy</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            margin: 20px;
        }
        h1 {
            text-align: center;
        }
        p {
            margin-bottom: 10px;
        }
        .submission {
            margin-left: 20px;
        }
       
    </style>
</head>
<body>    
    <ul>
        <li>
            <div class="section">
                <p class="refund-title">Refund Policy for General Attendees</p>
                <div class="submission">
                    <p><strong>Submission:</strong></p>
                    <ol>
                        <li>Refund requests must be submitted in writing.</li>
                        <li>Deadline: At least 3 business days before the event start date.</li>
                    </ol>
                    
                    <p><strong>Refund Amount:</strong></p>
                     <ol>
          <li>Standard refunds: Full amount minus processing fees.</li>
                        <li>Event cancellation by PBSS: Full refund without deductions.</li>
                    </ol>
                    
                    <p><strong>Request Process:</strong></p>
                    <ol>
                        <li>Go to the "My Event(s)" page.</li>
                        <li>Click the "Ask for Refund" button.</li>
                        <li>Select the names of attendees requiring refunds.</li>
                        <li>Complete the refund request form with the required information.</li>
                        <li>Submit the form or click "Cancel" to exit without requesting a refund.</li>
                    </ol>
                    
                    <p>Please review all information carefully before submitting your refund request.</p>
                    <ol>
                    <li>
                    Full refund minus $100 processing fee if requested at least 2 weeks before the event.
                    </li>
                    <li>
                    No refunds available within 2 weeks of the event.
                    </li>
                    </ol>
                </div>
            </div>
        </li>

        <li>
        <div class="section">
            <p class="refund-title">Refund Policy for Happy Hour and Lunch Sponsors</p>
            <div class="submission">
            <ol>
            <li>Full refund minus $300 processing fee if requested at least 2 weeks before the event.</li>
                <li>No refunds available within 2 weeks of the event.</li>
            </ol>
                
            </div>
        </div>
    </li>
        
        <li>
            <div class="section">
                <p class="refund-title">Refund policy for Major Sponsors</p>
                <div class="submission">
                <ol>
                      <li>Full refund minus $500 processing fee if requested at least 2 months before the event.</li>
                    <li>No refunds available within 2 months of the event.</li>
                </ol>
              
                </div>
            </div>
        </li>
        
       
    </ul>
    
    <div class="section">
        <p>All refund requests must be submitted online through the Event Registration page after logging into your account.</p>
        <p>As a volunteer-run organization, PBSS implements this policy to efficiently manage resources. We appreciate your understanding.</p>
        <p>Contact <a href="mailto:info@pbss.org">info@pbss.org</a> for further questions.</p>
    </div>
</body>
</html>
`,
      },
    ];
  }

  return (
    <React.Fragment>
      <div className={`${!titleHide && 'collapse-container-e23'}`}>
        {!titleHide && <p className='collapse-title'>FAQ</p>}
        <Collapse
          expandIconPosition='end'
          prefixCls='custom-collapse'
          onChange={handleCollapseChange}>
          {data.map((item, index) => (
            <Panel
              id={`aboutUs-FAQCollapse-${index}`}
              header={item.title}
              key={index}
              className={activeKey === index ? 'active-panel' : ''}>
              {/* {item.content} */}
              <div
                dangerouslySetInnerHTML={{__html: item.content}}
                className='collapse-content'
              />
            </Panel>
          ))}
        </Collapse>
      </div>
    </React.Fragment>
  );
};

export default CustomCollapse;

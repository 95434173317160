import React, {useEffect, useState} from 'react';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import './style.css';
import {ApplicationString} from '../../../Localization/Locales/en';

const ComponentTitle = ({
  textColor,
  title,
  description,
  hideButton,
  OnPrev,
  OnNext,
  currentOpeningResponsive,
  usedForChapPage,
  extraClass,
  usedForContactPage = false,
}) => {
  const [activeButton, setActiveButton] = useState('left');

  const handleLeftButtonClick = () => {
    setActiveButton('left');
    OnPrev ? OnPrev() : null;
  };

  const handleRightButtonClick = () => {
    setActiveButton('right');
    OnNext ? OnNext() : null;
  };

  return (
    <div
      className={`common-title-section1 ${extraClass} ${
        currentOpeningResponsive && `current-opning-responsive-for-title `
      } ${usedForChapPage && 'current-opening-chap-page-margin'}`}>
      <div
        className={`common-title-container ${
          usedForChapPage && 'common-title-container-chapPage'
        }`}>
        {title ? <p style={{color: `${textColor}`}}>{title}</p> : ''}
        {description ? <span>{description}</span> : ''}
        {usedForContactPage ? (
          <span>
            Alternately, you may also send us an email at
            <a href={`mailto:info@pbss.org`}> info@pbss.org</a>
          </span>
        ) : (
          ''
        )}
      </div>
      {hideButton ? (
        ''
      ) : (
        <div className='slider-action-container'>
          <button
            id={`${title.replace(/\s+/g, '')}-left-action-button-1`}
            className={`action-navigation ${
              activeButton === 'left' ? 'dark' : 'light'
            } ${
              currentOpeningResponsive &&
              'current-opening-responsive-for-buttons'
            }`}
            onClick={handleLeftButtonClick}>
            <LeftOutlined />
          </button>
          <button
            id={`${title.replace(/\s+/g, '')}-right-action-button-2`}
            className={`action-navigation ${
              activeButton === 'right' ? 'dark' : 'light'
            } ${
              currentOpeningResponsive &&
              'current-opening-responsive-for-buttons'
            }
            `}
            onClick={handleRightButtonClick}>
            <RightOutlined />
          </button>
        </div>
      )}
    </div>
  );
};

export default ComponentTitle;

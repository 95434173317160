import React, {useEffect, useState} from 'react';
import {getAllChapters} from '../../../Store/Actions';
import {useDispatch, useSelector} from 'react-redux';
import {formatMyEventsData} from '../../../Util/commonUtility';
import {LoadMoreSize} from './../../../Util/commonUtility';
import MyEventComponent from '../../../Components/UserComponents/MyEvent';

const MyEventContainer = () => {
  let dispatch = useDispatch();
  const {allMyEvents} = useSelector(({events}) => events);
  const localChapterData = useSelector(
    (state) => state?.chapters?.localChapterData,
  );
  const [loggedIn, setLoggedIn] = useState(false);
  const [chapterList, setChapterList] = useState([]);
  const [countData, setCountData] = useState(LoadMoreSize);
  const [totalEventCountChapter, setTotalEventCountChapter] = useState(0);
  const [userCredit, setUserCredit] = useState(0);
  const [eventData, setEventData] = useState([]);
  const [formattedEventData, setFormattedEventData] = useState([]);
  const [fetchNewData, setFetchNewData] = useState(false);

  // useEffect(() => {
  //   if (fetchUserData) {
  //     dispatch(setChapterById(fetchUserData?.data?.local_chapter_id));
  //   } else {
  //     const userInfo = getLocalStorageUserInfo();
  //     const userId = userInfo ? userInfo?.id : '';
  //     if (userId) {
  //       dispatch(fetchUsersById(userId));
  //     }
  //   }
  // }, [fetchUserData]);

  // useEffect(() => {
  //   if (allChapters) {
  //     setChapterList(allChapters?.data?.chapters);
  //   } else {
  //     dispatch(getAllChapters());
  //   }
  // }, [allChapters]);

  // useEffect(() => {
  //   const userInfo = localStorage.getItem('userInfo');
  //   if (userInfo) {
  //     setLoggedIn(true);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (getEventsDataByChapters) {
  //     const Data =
  //       getEventsDataByChapters?.data?.events.length > 0
  //         ? getEventsDataByChapters?.data?.events?.slice(0, countData)
  //         : getEventsDataByChapters?.data?.events;
  //     setGetAllEventDataByChapter(Data);
  //     setTotalEventCountChapter(getEventsDataByChapters?.data?.totalCount);
  //   }
  // }, [getEventsDataByChapters, countData]);

  // useEffect(() => {
  //   if (loggedIn && defaultChapterValue !== false) {
  //     dispatch(getEventsByChapters(defaultChapterValue));
  //   }
  // }, [loggedIn, dispatch, defaultChapterValue]);

  useEffect(() => {
    if (allMyEvents) {
      setEventData(allMyEvents);
    } else {
      setFetchNewData(true);
    }
  }, [allMyEvents]);

  useEffect(() => {
    if (!localChapterData) {
      dispatch(getAllChapters());
    }
  }, [localChapterData]);

  useEffect(() => {
    if (eventData) {
      setFormattedEventData(
        eventData?.data?.myEvents?.length > 0
          ? formatMyEventsData(eventData?.data?.myEvents)
          : null,
      );
    }
  }, [eventData]);

  // useEffect(() => {
  //   if (getAllEventDataByChapter) {
  //     const updatedEventData =
  //       getAllEventDataByChapter.length > 0
  //         ? getAllEventDataByChapter.map((item) => {
  //             return {
  //               key: item.id,
  //               id: item.id,
  //               sponsorshipAvailable: `${
  //                 Array.isArray(item.sponsors) ? item.sponsors.length : 0
  //               } Sponsorship Available`,
  //               speakers: Array.isArray(item.speakers)
  //                 ? item.speakers.map((speakers) => {
  //                     return (
  //                       <div key={speakers}>
  //                         {speakers.first_name + ' ' + speakers.last_name}
  //                       </div>
  //                     );
  //                   })
  //                 : '',
  //               date: moment(item.date).utcOffset(0).format('MMM DD'),
  //               eventName: item.name,
  //               registerText: 'Register Now',
  //               location: item.place,
  //               price: '295',
  //               category: 'PBSS International Boston - PBSS',
  //               eventType: item?.event_type_name,
  //               eventChapter: item?.chapter_name,
  //             };
  //           })
  //         : null;
  //     setEventData(updatedEventData);
  //   }
  // }, [getAllEventDataByChapter]);

  const handleLoadMore = () => {
    setCountData(countData * 2);
  };

  return (
    <MyEventComponent
      loggedIn={loggedIn}
      handleLoadMore={handleLoadMore}
      eventData={eventData}
      totalEventCountChapter={totalEventCountChapter}
      chaptersList={chapterList}
      formattedEventData={formattedEventData}
      setFormattedEventData={setFormattedEventData}
      userCredit={userCredit}
      localChapterData={localChapterData}
      setFetchNewData={setFetchNewData}
      fetchNewData={fetchNewData}
      setUserCredit={setUserCredit}
    />
  );
};

export default MyEventContainer;

import {
  DELETE_COMPANIES_BY_ID,
  GET_ALL_COMPANIES,
  GET_ALL_COMPANIES_BY_QUERY,
  GET_COMPANIES_BY_ID,
  POST_COMPANIES,
  UPDATE_COMPANIES_BY_ID,
} from '../../Constant/ActionTypes';

const initialState = {
  allCompanies: false,
  allCompaniesQueryData: false,
  getCompaniesData: false,
  isCreateCompany: false,
  isUpdateCompany: false,
};

const CompaniesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COMPANIES:
      return {
        ...state,
        allCompanies: action.payload,
        isCreateCompany: false,
        isUpdateCompany: false,
      };
    case GET_ALL_COMPANIES_BY_QUERY:
      return {
        ...state,
        allCompaniesQueryData: action.payload,
        isCreateCompany: false,
        isUpdateCompany: false,
      };
    case GET_COMPANIES_BY_ID:
      return {
        ...state,
        getCompaniesData: action.payload,
      };
    case POST_COMPANIES:
      return {
        ...state,
        isCreateCompany: true,
        allCompanies: false,
        allCompaniesQueryData: false,
      };
    case UPDATE_COMPANIES_BY_ID:
      return {
        ...state,
        isUpdateCompany: true,
        allCompanies: false,
        allCompaniesQueryData: false,
      };
    case DELETE_COMPANIES_BY_ID:
      return {
        ...state,
        allCompanies: false,
        allCompaniesQueryData: false,
      };
    default:
      return state;
  }
};

export default CompaniesReducer;

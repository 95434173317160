import {
  DELETE_JOBS_BY_ID,
  GET_ALL_JOBS,
  GET_ALL_JOBS_ACCOUNTS,
  GET_ALL_JOBS_APPROVED,
  GET_ALL_JOBS_BY_QUERY,
  GET_ALL_JOBS_WITHOUT_PAGINATION,
  GET_JOBS_BY_ID,
  GET_JOBS_BY_USERID,
  POST_JOBS,
  POST_JOBS_PAYMENT,
  POST_JOBS_PAYMENT_SUCCESS,
  RESET_JOBS_PAYMENT,
  UPDATE_JOBS_BY_ID,
  UPDATE_JOBS_STATUS,
} from '../../Constant/ActionTypes';

const initialState = {
  allJobs: false,
  getJobData: false,
  isCreateJob: false,
  isUpdateJob: false,
  isUpdateJobStatus: false,
  allJobsQueryData: false,
  allJobsWithoutPagination: false,
  getJobDataByUser: false,
  getApprovedJobs: false,
  jobsPayment: false,
  jobsPaymentSuccess: false,
  allAccountJobs: false,
};

const JobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_JOBS:
      return {
        ...state,
        allJobs: action.payload,
        isCreateJob: false,
        isUpdateJob: false,
        isUpdateJobStatus: false,
      };
    case GET_ALL_JOBS_ACCOUNTS:
      return {
        ...state,
        allAccountJobs: action.payload,
        isCreateAd: false,
        isUpdateAd: false,
        isUpdateAdsStatus: false,
      };
    case GET_ALL_JOBS_APPROVED:
      return {
        ...state,
        getApprovedJobs: action.payload,
        isCreateJob: false,
        isUpdateJob: false,
        isUpdateJobStatus: false,
      };
    case GET_ALL_JOBS_BY_QUERY:
      return {
        ...state,
        allJobsQueryData: action.payload,
        isCreateJob: false,
        isUpdateJob: false,
        isUpdateJobStatus: false,
      };

    case GET_ALL_JOBS_WITHOUT_PAGINATION:
      return {
        ...state,
        allJobsWithoutPagination: action.payload,
        isCreateJob: false,
        isUpdateJob: false,
        isUpdateJobStatus: false,
      };
    case UPDATE_JOBS_STATUS:
      return {
        ...state,
        isUpdateJobStatus: true,
        allJobs: false,
        allJobsQueryData: false,
        getApprovedJobs: false,
      };
    case GET_JOBS_BY_ID:
      return {
        ...state,
        getJobData: action.payload,
      };
    case GET_JOBS_BY_USERID:
      return {
        ...state,
        getJobDataByUser: action.payload,
      };
    case POST_JOBS:
      return {
        ...state,
        isCreateJob: true,
        allJobs: false,
        allJobsQueryData: false,
        getJobDataByUser: false,
        getApprovedJobs: false,
      };
    case UPDATE_JOBS_BY_ID:
      return {
        ...state,
        isUpdateJob: true,
        getJobData: false,
        allJobs: false,
        allJobsQueryData: false,
        getJobDataByUser: false,
      };
    case DELETE_JOBS_BY_ID:
      return {
        ...state,
        allJobs: false,
        allJobsQueryData: false,
        getApprovedJobs: false,
      };
    case POST_JOBS_PAYMENT:
      return {
        ...state,
        jobsPayment: action.payload,
        getJobDataByUser: false,
      };
    case POST_JOBS_PAYMENT_SUCCESS:
      return {
        ...state,
        jobsPaymentSuccess: true,
        getJobDataByUser: false,
      };
    case RESET_JOBS_PAYMENT:
      return {
        ...state,
        jobsPayment: false,
        jobsPaymentSuccess: false,
      };
    default:
      return state;
  }
};

export default JobsReducer;

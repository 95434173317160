import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  DefaultPaginationValues,
  createSelectionOptionsUtil,
  queryString,
} from '../../../Util/commonUtility';
import {
  cancelEventRegistrationsByEventIds,
  getAllRegistrationByQueryString,
  getRegistrationById,
  getRegistrationByIdForCSV,
  resetEventRegisterCsvDownloadState,
  updateFoodAndRegistrationOptions,
} from '../../../Store/Actions/eventRegister';
import EventRegistrationsComponent from '../../../Components/AdminComponents/EventRegistration';
import {useParams} from 'react-router-dom';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';
import {getEventOptionsByEventId} from '../../../Store/Actions/eventOptions';
const EventRegistrationContainer = () => {
  const dispatch = useDispatch();
  const {getEventOptionByEventId} = useSelector(
    ({eventOptions}) => eventOptions,
  );
  const {allRegistrationDetailsByEvent} = useSelector(
    ({eventRegister}) => eventRegister,
  );
  const {allRegistrationDetailsByEventForCSV} = useSelector(
    ({eventRegister}) => eventRegister,
  );

  const [allEventRegistrationDataByEvent, setAllEventRegistrationDataByEvent] =
    useState();
  const [
    allEventRegistrationDataByEventForCSV,
    setAllEventRegistrationDataByEventForCSV,
  ] = useState();
  const [eventRegistrationData, setEventRegistrationData] = useState();
  const [eventOptionsFoodData, setEventOptionsFoodData] = useState([]);
  const [allEventOptionsRegistrationData, setAllEventOptionsRegistrationData] =
    useState([]);
  const [querySearchValues, setQuerySearchValues] = useState();
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.eventRegistrations,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.eventRegistrations,
  );
  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pageCount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  const {id} = useParams();
  const reduxSearchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.event_registrations,
  );
  const reduxSearchValueID = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValueID,
  );
  const searchValues =
    parseInt(id) === reduxSearchValueID ? reduxSearchValues : null;

  const [csvDataLoading, setCSVDataLoading] = useState(false);
  const [totalEventRegistrationCount, setTotalEventRegistrationCount] =
    useState(10);

  useEffect(() => {
    if (id) {
      fetchEventRegistrations();
      dispatch(getEventOptionsByEventId(id));
    }
  }, [id]);

  useEffect(() => {
    if (allRegistrationDetailsByEvent) {
      setAllEventRegistrationDataByEvent(
        allRegistrationDetailsByEvent?.data?.eventRegistrations,
      );
      setPagination({
        ...pagination,
        page: allRegistrationDetailsByEvent?.data?.currentPage,
        totalPages: allRegistrationDetailsByEvent?.data?.totalPage,
        totalCount: allRegistrationDetailsByEvent?.data?.totalCount,
      });
      setTotalEventRegistrationCount(
        allRegistrationDetailsByEvent?.data?.totalCount,
      );
    } else {
      fetchEventRegistrations();
    }
  }, [allRegistrationDetailsByEvent]);

  useEffect(() => {
    if (allRegistrationDetailsByEventForCSV) {
      setAllEventRegistrationDataByEventForCSV(
        allRegistrationDetailsByEventForCSV?.data?.eventRegistrations,
      );
    }
  }, [
    allRegistrationDetailsByEventForCSV?.data?.eventRegistrations,
    totalEventRegistrationCount,
  ]);

  useEffect(() => {
    if (getEventOptionByEventId) {
      const filteredRegData =
        getEventOptionByEventId?.data?.registration?.filter(
          (reg) => reg?.is_vendor === false,
        );
      const foodOptions = getEventOptionByEventId?.data?.food;
      const createUtilData = foodOptions
        ? createSelectionOptionsUtil(foodOptions, 'id', 'name', 'price')
        : [];
      const foodOptionsWithNoFood = [
        ...createUtilData,
        {value: 'no-food', label: 'No Food'},
      ];
      setEventOptionsFoodData(foodOptionsWithNoFood);
      setAllEventOptionsRegistrationData(
        createSelectionOptionsUtil(filteredRegData, 'id', 'name', 'price'),
      );
    } else {
      dispatch(getEventOptionsByEventId(id));
    }
  }, [getEventOptionByEventId]);

  useEffect(() => {
    if (eventRegistrationData) {
      setPagination({
        ...pagination,
        page: eventRegistrationData?.data?.currentPage,
        totalPages: eventRegistrationData?.data?.totalPage,
        totalCount: eventRegistrationData?.data?.totalCount,
      });
    }
  }, [eventRegistrationData]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchEventRegistrations(searchValues);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  useEffect(() => {
    if (searchValues) {
      dispatch(setPageNumber('eventRegistrations', 1));
      setPagination({
        ...pagination,
        page: 1,
      });
      setPaginationAndSortChanged(true);
    }
    dispatch(resetEventRegisterCsvDownloadState());
    setCSVDataLoading(false);
  }, [searchValues]);

  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('eventRegistrations', pageSize));
    dispatch(setPageNumber('eventRegistrations', current));
    setPagination({
      ...pagination,
      page: current,
      pageCount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
    dispatch(resetEventRegisterCsvDownloadState());
    setCSVDataLoading(false);
  };

  const fetchEventRegistrations = (searchValues) => {
    setQuerySearchValues(searchValues);
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getRegistrationById(
        pagination,
        sortField,
        sortOrder,
        searchQueryData,
        id,
      ),
    );
  };
  const fetchEventRegistrationsForCSV = (searchValues) => {
    setQuerySearchValues(searchValues);
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getRegistrationByIdForCSV(
        sortField,
        sortOrder,
        searchQueryData,
        totalEventRegistrationCount,
        id,
        setCSVDataLoading,
      ),
    );
  };

  const cancelEventRegistrations = (eventIds, setSelectedRowKeys) => {
    dispatch(cancelEventRegistrationsByEventIds(eventIds, setSelectedRowKeys));
  };

  const UpdateRegistrationOptions = (data, id) => {
    dispatch(updateFoodAndRegistrationOptions(data, id));
  };

  return (
    <div>
      <EventRegistrationsComponent
        foodOptions={eventOptionsFoodData}
        registrationOptions={allEventOptionsRegistrationData}
        allEventRegistrationData={allEventRegistrationDataByEvent}
        allEventRegistrationDataForCSV={allEventRegistrationDataByEventForCSV}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        handleTableChange={handleTableChange}
        sortParams={sortParams}
        fetchEventRegistrations={(searchValues) => {
          fetchEventRegistrations(searchValues);
        }}
        fetchEventRegistrationsForCSV={(searchValues) => {
          fetchEventRegistrationsForCSV(searchValues);
        }}
        cancelEventRegistrations={(eventIds, setSelectedRowKeys) => {
          cancelEventRegistrations(eventIds, setSelectedRowKeys);
        }}
        UpdateRegistrationOptions={(data, id) => {
          UpdateRegistrationOptions(data, id);
        }}
        searchValues={searchValues}
        csvDataLoading={csvDataLoading}
      />
    </div>
  );
};

export default EventRegistrationContainer;

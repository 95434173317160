import React, {useState} from 'react';
import {Table, Select, Input, InputNumber, Form, Skeleton} from 'antd';
import {
  NoEmptySpaceInput,
  ReturnNAOnEmpty,
  formatCurrency,
  validateAdsDescriptionInput,
  validateMonthsInput,
} from '../../../../Util/commonUtility';
import AdminActionsDropdown from '../../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {updateAdById} from '../../../../Store/Actions';
import {useDispatch} from 'react-redux';
import './index.css';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  adFeesOptions,
  ...restProps
}) => {
  let inputElement;
  switch (dataIndex) {
    case 'ad_fee_id':
      inputElement = (
        <Form.Item
          name='ad_fee_id'
          rules={[
            {required: true, message: 'Ad fee type should not be empty'},
          ]}>
          <Select
            prefixCls='adType-select'
            placeholder={`Select ads type`}
            options={adFeesOptions}></Select>
        </Form.Item>
      );
      break;

    case 'ad_months':
      inputElement = (
        <Form.Item
          name='months'
          style={{margin: 0}}
          rules={[
            {required: true, message: ''},
            {
              validator: (_, value) => validateMonthsInput(value, 'months'),
            },
          ]}>
          <Input placeholder={`Enter months`} />
        </Form.Item>
      );
      break;
    case 'vendor':
      inputElement = (
        <Form.Item
          name='vendor'
          style={{margin: 0}}
          rules={[
            {required: true, message: 'Vendor name should not be empty!'},
            {
              validator: (_, value) => NoEmptySpaceInput(value, ' Vendor name'),
            },
          ]}>
          <Input placeholder={`Enter vendor name`} />
        </Form.Item>
      );
      break;

    case 'description':
      inputElement = (
        <Form.Item
          name={dataIndex}
          style={{margin: 0}}
          rules={[
            {
              validator: (_, value) =>
                validateAdsDescriptionInput(value, 'Description'),
            },
          ]}>
          <Input.TextArea placeholder='Add ads description' rows={3} />
        </Form.Item>
      );
      break;

    default:
      inputElement = (
        <Form.Item
          name={dataIndex}
          style={{margin: 0}}
          rules={[
            {
              validator: (_, value) => NoEmptySpaceInput(value, title),
            },
          ]}>
          <Input placeholder={`Enter your ${title}`} />
        </Form.Item>
      );
  }

  return (
    <td {...restProps}>{editing ? <div>{inputElement}</div> : children}</td>
  );
};

const CommonCartTable = ({
  adsData,
  text = '',
  adFeesOptions,
  setISAdEditing,
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const dispatch = useDispatch();

  const isEditing = (record) => record.id === editingKey;
  const columns = [
    {
      title: 'vendor name',
      dataIndex: 'vendor',
      editable: true,
      className: 'vendor-name',
      width: '10%',
      render: (text) => ReturnNAOnEmpty(text),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
      width: '20%',
      render: (textDesc, record) => {
        return <div className='cart-desc'>{ReturnNAOnEmpty(textDesc)}</div>;
      },
    },
    {
      title: `Ad type`,
      dataIndex: `${text}_fee_id`,
      editable: true,
      width: '10%',
      render: (_, record) => {
        const originalAdFee = adFeesOptions?.find(
          (obj) => obj?.details === record?.ad_fee_note,
        )?.fee;
        return (
          <div className='cart-type'>
            {record?.ad_fee_note + '-' + formatCurrency(originalAdFee)}
          </div>
        );
      },
    },
    {
      title: `Ad months`,
      dataIndex: `${text}_months`,
      editable: true,
      width: '10%',
      render: (_, record) => {
        return <div className='cart-type'>{adsData[0]?.months}</div>;
      },
    },
    {
      title: 'Fees',
      dataIndex: `${text}_fee`,
      width: '10%',
      render: (text, record) => {
        return <div className='cart-fees'>{formatCurrency(text)}</div>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      className: 'common-cart-action',
      width: '5%',
      render: (_, record) => (
        <AdminActionsDropdown
          record={record}
          editBool={true}
          text={text}
          handleEditClick={edit}
          usedForAttendeeTable={true}
          attendeeEditingKey={editingKey}
          handleSaveClick={save}
          handleCancelClick={cancel}
          usedForAdsAndJobsCart={true}
        />
      ),
    },
  ];

  const edit = (record) => {
    setISAdEditing(true);
    setEditingKey(record.id);
    const findAdFeesId = adFeesOptions?.find(
      (obj) => obj?.details === record?.ad_fee_note,
    )?.id;
    form.setFieldsValue({
      ...record,
      ad_fee_id: findAdFeesId,
      ad_months: adsData[0]?.months,
    });
  };

  const cancel = () => {
    setISAdEditing(false);
    form.resetFields();
    setEditingKey('');
  };

  const save = async (adFeesData) => {
    setISAdEditing(false);
    const row = await form.validateFields();
    const Data = {
      description: row?.description,
      vendor: row?.vendor,
      months: `${row?.months}`,
      attachment_url: adsData[0]?.attachment_url,
      email: adsData[0]?.email,
      logo_ids: `[${adsData[0]?.logo_ids[0]?.id || ''}]`,
      start_date: adsData[0]?.start_date,
    };
    dispatch(
      updateAdById(
        {...Data, ad_fee_id: `${row?.ad_fee_id}`},
        adFeesData.id,
        form,
        setEditingKey,
      ),
    );
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === `${text}Type` ? 'select' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      <Form form={form}>
        <Table
          rowKey={'id'}
          showExpandColumn={false}
          dataSource={adsData}
          scroll={{x: 1000}}
          components={{
            body: {
              cell: (props) => (
                <EditableCell adFeesOptions={adFeesOptions} {...props} />
              ),
            },
          }}
          columns={mergedColumns}
          pagination={false}
          prefixCls='common-cart-table'
        />
      </Form>
    </div>
  );
};

export default CommonCartTable;

import {Divider} from 'antd';
import React from 'react';
import './style.css';
import {useNavigate} from 'react-router-dom';
import {ApplicationString} from '../../../../Localization/Locales/en';

const MajorInfoCard = ({imgSrc, description, price}) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className='majorInfo-container'>
        {/* <img src={imgSrc} alt='majorInfo' className='majorInfo-image' /> */}
        <div className='majorInfo-details'>
          <p className='majorInfo-description'>{description}</p>
          <div className='majorInfo-price'>
            <p>
              {ApplicationString['userEventMajorSponsorCard.regularPriceText']}
            </p>
            <span>{price}</span>
          </div>
        </div>
        <Divider />
        <div className='info-body'>
          <p>{ApplicationString['userEventMajorSponsorCard.benefitsText']}</p>
          <div className='info-body-text'>
            {ApplicationString['userEventMajorSponsorCard.bodyText']}
          </div>
          <li>{ApplicationString['userEventMajorSponsorCard.li1']}</li>
          <li>{ApplicationString['userEventMajorSponsorCard.li2']}</li>
          <li>{ApplicationString['userEventMajorSponsorCard.li3']}</li>
          <div>
            {ApplicationString['userEventMajorSponsorCard.afterListText']}
          </div>
          <div>
            <p className='for-major-sponsor'>
              {
                ApplicationString[
                  'userEventMajorSponsorCard.instructions.heading'
                ]
              }
            </p>
            <li className='fz-16'>
              {ApplicationString['userEventMajorSponsorCard.instructions.l1']}
            </li>
            <li className='fz-16'>
              {ApplicationString['userEventMajorSponsorCard.instructions.l2']}
            </li>
          </div>
          <div className='sponsor-nav-container'>
            <button
              id='sponsorship-register-button'
              onClick={() => {
                navigate(`/register`);
              }}>
              {
                ApplicationString[
                  'userEventMajorSponsorCard.buttonText.signupforsponsorship'
                ]
              }
            </button>
            <button
              id='sponsorship-contactUs-button'
              onClick={() => {
                navigate(`/contactUs`);
              }}>
              {
                ApplicationString[
                  'userEventMajorSponsorCard.buttonText.contactourvendorliaison'
                ]
              }
            </button>
            <button
              onClick={() => {
                navigate(`/specialPayment`);
              }}>
              {
                ApplicationString[
                  'userEventMajorSponsorCard.buttonText.makeaspecialpayment'
                ]
              }
            </button>
          </div>
        </div>
      </div>
      <div className='static-container'>
        <span>
          {
            ApplicationString[
              'userSponsorPolicyComponent.membershipListSharing'
            ]
          }
        </span>
        <div className='static-container-card'>
          <div className='card-body list-sharing-text'>
            {
              ApplicationString[
                'userSponsorPolicyComponent.membershipListSharing.description'
              ]
            }
          </div>
        </div>
        <div className='vendor-show-card-container'>
          <span>
            {
              ApplicationString[
                'userSponsorPolicyComponent.vendorShowCard.title'
              ]
            }
          </span>
          <ul>
            <div>
              <li>
                {
                  ApplicationString[
                    'userSponsorPolicyComponent.vendorShowCard.description'
                  ]
                }
              </li>
            </div>
          </ul>
        </div>
        <div className='vendor-show-card-container'>
          <span>
            {
              ApplicationString[
                'userSponsorPolicyComponent.vendorShowCard.title2'
              ]
            }
          </span>
          <ul>
            <div>
              <li>
                {
                  ApplicationString[
                    'userSponsorPolicyComponent.vendorShowCard.li1'
                  ]
                }
              </li>
            </div>
            <div>
              <li>
                {
                  ApplicationString[
                    'userSponsorPolicyComponent.vendorShowCard.li2'
                  ]
                }
              </li>
            </div>
            <div>
              <li>
                {
                  ApplicationString[
                    'userSponsorPolicyComponent.vendorShowCard.li3'
                  ]
                }
              </li>
            </div>
            <div>
              <li>
                {
                  ApplicationString[
                    'userSponsorPolicyComponent.vendorShowCard.li4'
                  ]
                }
              </li>
            </div>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MajorInfoCard;

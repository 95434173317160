import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {getAllJobFees, getJobsById} from '../../../../Store/Actions';
import {createSelectionOptionsUtil} from '../../../../Util/commonUtility';
import JobsCartComponent from '../../../../Components/UserComponents/Jobs/JobsCart';
import {Col, Row} from 'antd';
import CommonPageHeroText from '../../../../Components/CommonComponents/CommonPageHeroText';

const JobsCartContainer = () => {
  const {id} = useParams();
  let dispatch = useDispatch();
  const {getJobData} = useSelector(({jobs}) => jobs);
  const [jobData, setJobData] = useState();
  const {allJobFees} = useSelector(({jobFees}) => jobFees);
  const [jobFeesOptions, setJobFeesOptions] = useState([]);

  useEffect(() => {
    if (getJobData && getJobData?.data?.id === parseInt(id)) {
      setJobData([
        {
          ...getJobData?.data,
        },
      ]);
    } else {
      fetchJobsById(parseInt(id));
    }
  }, [getJobData?.data]);

  const fetchJobsById = async (id) => {
    dispatch(getJobsById(id));
  };

  useEffect(() => {
    if (allJobFees) {
      setJobFeesOptions(
        createSelectionOptionsUtil(
          allJobFees?.data?.jobFees,
          'id',
          'details',
          'fee',
        ),
      );
    } else {
      dispatch(
        getAllJobFees(
          {
            page: 1,
            pagecount: 10,
          },
          '',
          '',
          '',
        ),
      );
    }
  }, [allJobFees]);
  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        <Col span={24}>
          <CommonPageHeroText
            heading={'Jobs Payment Method'}
            subheading={
              'Get Details about jobs payment price , schedule & more...'
            }
          />
        </Col>
        <Col span={24}>
          <JobsCartComponent
            jobData={jobData}
            jobFeesOptions={jobFeesOptions}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default JobsCartContainer;

import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL_CHAPTERS,
  SOMETHING_WENT_WRONG,
  ADD_CHAPTER_SUCCESS,
  SHOW_MESSAGE,
  UPDATE_CHAPTER_BY_ID,
  DELETE_CHAPTER_BY_ID,
  FETCH_CHAPTER_BY_ID,
  GET_ALL_CHAPTERS_BY_QUERY,
  SET_CHAPTER_BY_ID,
  SET_SIDE_BAR_STATE,
  SET_FORM_STATE_SUCESS,
  SET_EVENT_NAME_STATE,
  SET_MENU_ROUTE,
  POST_CHAPTER_SUCCESS,
  UPDATE_CHAPTER_SUCCESS,
  DELETE_CHAPTER_SUCCESS,
  SET_LOCAL_CHAPTER_DETAILS,
  GET_ALL_CHAPTERS_BY_QUERY_FOR_SEARCH,
} from '../../Constant/ActionTypes';

import Urls from '../../Constant/Urls';
import {httpClient} from '../../Api/client';
import {commonSortOrder, handleJwtExpireMsg} from '../../Util/commonUtility';

export const getAllChapters = () => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(Urls.CHAPTER_GET_ALL);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_CHAPTERS, payload: data?.data});
        let userInfo = localStorage.getItem('userInfo');
        if (userInfo) {
          userInfo = JSON.parse(userInfo);
        }
        const userLocalChapterDetails = data?.data?.data?.chapters?.find(
          (chapter) => chapter?.id == userInfo?.local_chapter_id,
        );
        dispatch({
          type: SET_LOCAL_CHAPTER_DETAILS,
          payload: userLocalChapterDetails,
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllChaptersByQueryStringForSearch = (
  sortField,
  sortOrder,
  queryString,
  pagination,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.CHAPTER_GET_ALL}?page=${pagination.page}&pageCount=${
          pagination.pageCount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${queryString}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: GET_ALL_CHAPTERS_BY_QUERY_FOR_SEARCH,
          payload: data?.data,
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const getAllChaptersByQueryString = (
  sortField,
  sortOrder,
  queryString,
  pagination,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.CHAPTER_GET_ALL}?page=${pagination.page}&pageCount=${
          pagination.pageCount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${queryString}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_CHAPTERS_BY_QUERY, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addChapter = (chapterData, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.post(Urls.CHAPTER_ADD, chapterData);
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: ADD_CHAPTER_SUCCESS, payload: response.data});
        navigate && navigate('/admin/chapters');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const fetchChapterById = (chapterId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.get(
        Urls.CHAPTER_FETCH_BY_ID.replace('#{chapterId}', chapterId),
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_CHAPTER_BY_ID, payload: response?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateChapterById = (updatedChapterData, chapterId, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.patch(
        Urls.CHAPTER_UPDATE_BY_ID.replace('#{chapterId}', chapterId),
        updatedChapterData,
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: UPDATE_CHAPTER_BY_ID, payload: response?.data});
        navigate && navigate('/admin/chapters');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteChapterById = (chapterId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.delete(
        Urls.CHAPTER_DELETE_BY_ID.replace('#{chapterId}', chapterId),
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: DELETE_CHAPTER_BY_ID, payload: chapterId});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const setChapterById = (chapterId) => {
  return async (dispatch) => {
    try {
      dispatch({type: SET_CHAPTER_BY_ID, payload: chapterId});
    } catch (error) {}
  };
};

export const setSidebarState = (state) => {
  return async (dispatch) => {
    try {
      dispatch({type: SET_SIDE_BAR_STATE, payload: state});
    } catch (error) {}
  };
};

export const setFormState = (state) => {
  return async (dispatch) => {
    try {
      dispatch({type: SET_FORM_STATE_SUCESS, payload: state});
    } catch (error) {}
  };
};

export const setEventNameState = (state) => {
  return async (dispatch) => {
    try {
      dispatch({type: SET_EVENT_NAME_STATE, payload: state});
    } catch (error) {}
  };
};

export const setMenuRoute = (state) => {
  return async (dispatch) => {
    try {
      dispatch({type: SET_MENU_ROUTE, payload: state});
    } catch (error) {}
  };
};

export const setPaymentRoute = (state) => {
  return async (dispatch) => {
    try {
      dispatch({type: SET_PAYMENT_ROUTE, payload: state});
    } catch (error) {}
  };
};

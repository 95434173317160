import {PlusOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Input, Select} from 'antd';
import React, {useRef, useMemo} from 'react';
import {ApplicationString} from '../../../Localization/Locales/en';
import './style.css';
import filter_by from '../../../Assets/filter-by.svg';
import {generateYearOptions} from '../../../Util/commonUtility';

const CommonAddNewListingWithFilter = ({
  setIsModalOpen,
  searchOnChange,
  onSearch,
  placeHolder,
  selectPlaceholder,
  registrtion_filter,
  List,
  handleChange,
  handleYearFilter,
  usedForMyEventsCart,
  selectedYearValue,
  categoryList,
  handleCategoryFilter,
  selectedCategoryValue,
  selectedStatusValue,
  addNew = true,
  usedForMyEvents = false,
  search,
  year,
  pageName,
  status = true,
}) => {
  const selectRef = useRef(null);
  const yearOptions = useMemo(() => generateYearOptions(), []);
  const handleFocus = () => {
    selectRef.current && selectRef.current.blur();
  };

  return (
    <React.Fragment>
      <div className='listingWithFilterContainer'>
        {addNew && (
          <div className='add-new-listing-button-container'>
            <Button
              type='primary'
              id='UserJobsComponent-filterButton-addJobs'
              prefixCls='add-new-listing-button'
              onClick={() => setIsModalOpen(true)}>
              <span className='add-new-listing-button-content'>
                {ApplicationString['userComponent.button.addListing']}{' '}
                {pageName}
              </span>
              <div>
                <PlusOutlined className='add-new-listing-icon' />
              </div>
            </Button>
          </div>
        )}
        <div
          className={`filter-search-container ${
            usedForMyEventsCart && 'filter-search-container-myEventsCart'
          } ${usedForMyEvents && 'flex-spaceBetween-w100'}`}>
          <div className='event-filter-type-container'>
            <div className='add-new-listing-filter-with-label-container'>
              <div className='filter-by-label' id='filterByLasbel'>
                <img src={filter_by} alt='filter-by-svg' />
                <span>Filter By</span>
              </div>
              {status && (
                <Select
                  id={`myProfile-selectEvent-${selectPlaceholder}`}
                  showSearch
                  prefixCls='event_type_select_chapter'
                  defaultValue={selectPlaceholder}
                  // style={{width: '120px'}}
                  onChange={handleChange}
                  onSelect={handleFocus}
                  value={selectedStatusValue || selectPlaceholder}
                  // optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option?.label ?? '').includes(input)
                  }
                  placeholder={'Select your default status'}
                  options={List}
                  ref={selectRef}></Select>
              )}
              {usedForMyEvents && (
                <Select
                  id='myProfile-selectEvent-category'
                  showSearch
                  prefixCls='event_type_select_chapter'
                  defaultValue={'History'}
                  onSelect={handleFocus}
                  onChange={handleCategoryFilter}
                  className='used-for-myevents-select'
                  value={selectedCategoryValue || 'History'}
                  // optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option?.label ?? '').includes(input)
                  }
                  placeholder={'Select your default location'}
                  options={categoryList}
                  ref={selectRef}></Select>
              )}
              {year && (
                <Select
                  id='myProfile-selectEvent-year'
                  showSearch
                  prefixCls='event_type_select_year'
                  defaultValue='Year'
                  onSelect={handleFocus}
                  onChange={handleYearFilter}
                  value={selectedYearValue || 'Year'}
                  placeholder={'Select year'}
                  options={yearOptions}
                  ref={selectRef}></Select>
              )}
            </div>
            {registrtion_filter && (
              <div className='registration-filter'>{registrtion_filter}</div>
            )}
          </div>

          {search && (
            <div
              id='userProfile-Search-input'
              className={`add-new-listing-search-container ${
                usedForMyEvents && 'mn-w-400px'
              }`}>
              <Input
                prefixCls='add-new-listing-search-input'
                placeholder={`${placeHolder}`}
                suffix={<SearchOutlined onClick={onSearch} />}
                onChange={searchOnChange}
              />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CommonAddNewListingWithFilter;

import React, {useEffect, useState} from 'react';
import AdsFormComponent from '../AdsForm';
import {useDispatch} from 'react-redux';
import CommonAddNewListingWithFilter from '../../../CommonComponents/Common_AddNewListing_with_Filter';
import {Alert, Button, Col, Row} from 'antd';
import CommonAdsListingCard from './CommonAdsListingCard';
import SectionLayout from '../../../CommonComponents/Layout/sectionLayout';
import {fetchAdsByUserId} from '../../../../Store/Actions';
import {
  AdStatus,
  AdsAndJobsOptions,
  AdsAndJobsOptionsForUser,
  DefaultListingViewMoreCount,
  getLocalStorageUserInfo,
} from '../../../../Util/commonUtility';
import DownArrowSvg from '../../../../Assets/svgComponents/DownArrowSvg';

const CommonAdsListing = ({
  allAdsData,
  setAllAdsData,
  page,
  setPage,
  adFeesOptions,
  zeroFeeData,
  fetchNewData,
  setFetchNewData,
}) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValues, setSearchValues] = useState(null);
  const [filterValue, setFilterValue] = useState('');
  const [selectedYearValue, setSelectedYearValue] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const userInfo = getLocalStorageUserInfo();
  const userId = userInfo?.id;

  const searchOnChange = (e) => {
    let search = e.target.value;
    if (search) {
      setSearchValues(e.target.value);
    } else {
      setSearchValues('');
    }
  };

  const handleYearFilter = (value) => {
    if (!value) {
      dispatch(
        fetchAdsByUserId(
          userId,
          searchValues,
          filterValue,
          setAllAdsData,
          page,
          setTotalItems,
          '',
        ),
      );
      setSelectedYearValue('');
    } else {
      dispatch(
        fetchAdsByUserId(
          userId,
          searchValues,
          filterValue,
          setAllAdsData,
          page,
          setTotalItems,
          value,
        ),
      );
      setSelectedYearValue(value);
    }
  };

  const onSearch = () => {
    dispatch(
      fetchAdsByUserId(
        userId,
        searchValues,
        filterValue,
        setAllAdsData,
        page,
        setTotalItems,
        selectedYearValue,
      ),
    );
  };

  useEffect(() => {
    if (fetchNewData) {
      dispatch(
        fetchAdsByUserId(
          userId,
          searchValues,
          filterValue,
          setAllAdsData,
          page,
          setTotalItems,
          selectedYearValue,
        ),
      );
      setFetchNewData(false);
    }
  }, [fetchNewData]);

  const handleChange = (value) => {
    if (!value) {
      dispatch(
        fetchAdsByUserId(
          userId,
          searchValues,
          '',
          setAllAdsData,
          page,
          setTotalItems,
          selectedYearValue,
        ),
      );
      setFilterValue('');
    } else {
      setFilterValue(value);
      dispatch(
        fetchAdsByUserId(
          userId,
          searchValues,
          value,
          setAllAdsData,
          page,
          setTotalItems,
          selectedYearValue,
        ),
      );
    }
  };

  const onViewMore = () => {
    const userInfo = getLocalStorageUserInfo();
    const userId = userInfo?.id;
    setPage((prev) => {
      const newPage =
        prev + DefaultListingViewMoreCount?.adsListingViewMoreCount;
      dispatch(
        fetchAdsByUserId(
          userId,
          searchValues,
          filterValue,
          setAllAdsData,
          newPage,
          setTotalItems,
          selectedYearValue,
        ),
      );
      return newPage;
    });
  };

  useEffect(() => {
    const userInfo = getLocalStorageUserInfo();
    const userId = userInfo ? userInfo?.id : '';
    if (searchValues || filterValue) {
      dispatch(
        fetchAdsByUserId(
          userId,
          searchValues,
          filterValue,
          setAllAdsData,
          page,
          setTotalItems,
          selectedYearValue,
        ),
      );
    } else {
      dispatch(
        fetchAdsByUserId(
          userId,
          searchValues,
          filterValue,
          setAllAdsData,
          page,
          setTotalItems,
          selectedYearValue,
        ),
      );
    }
  }, [searchValues]);

  return (
    <React.Fragment>
      <SectionLayout>
        <Row gutter={[0, 32]}>
          <Col span={24}>
            <CommonAddNewListingWithFilter
              search
              year
              selectedStatusValue={filterValue}
              selectedYearValue={selectedYearValue}
              setIsModalOpen={setIsModalOpen}
              searchOnChange={searchOnChange}
              onSearch={onSearch}
              handleYearFilter={handleYearFilter}
              List={AdsAndJobsOptionsForUser}
              handleChange={handleChange}
              selectPlaceholder='Status'
              placeHolder='Search for ads'
              pageName={'Ad'}
            />
          </Col>
          <Col span={24} className='common-jobs-listing-main-container'>
            {allAdsData?.length > 0 ? (
              allAdsData?.map((item, index) => (
                <CommonAdsListingCard
                  key={item?.id}
                  id={item?.id}
                  adsLogo={item?.logo_ids[0] || ''}
                  adsHeading={item?.vendor}
                  adsDescription={item?.description}
                  adsStatus={item?.status}
                  url={item?.attachment_url}
                  adsEmail={item?.email}
                  adsQty={item?.qty}
                  adsStart_date={item?.start_date}
                  adsEnd_date={item?.end_date}
                  ad_fee={item?.ad_fee}
                  ad_fee_note={item?.ad_fee_note}
                  months={item?.months}
                  adFeesOptions={adFeesOptions}
                />
              ))
            ) : (
              <Alert
                message='No Ads Available'
                className='no-jobs-alert'
                type='info'
                showIcon
              />
            )}
          </Col>
          {
            <AdsFormComponent
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              adFeesOptions={adFeesOptions}
              zeroFeeData={zeroFeeData}
            />
          }
          <Col span={24}>
            {totalItems > allAdsData?.length && (
              <div className='view-more-container'>
                <Button
                  className='view-more-button'
                  id='myProfileAds-viewMore-button'
                  onClick={onViewMore}>
                  <span className='view-more-text'>View More Ads</span>
                  <DownArrowSvg />
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </SectionLayout>
    </React.Fragment>
  );
};

export default CommonAdsListing;

import React, {useEffect, useState} from 'react';
import ContactUsComponent from '../../../Components/UserComponents/ContactUs';
import {createSelectionOptionsUtil} from '../../../Util/commonUtility';
import {getAllChapters} from '../../../Store/Actions';
import {useSelector, useDispatch} from 'react-redux';
import {postContactForm} from '../../../Store/Actions/contactUs';
import {useNavigate} from 'react-router-dom';

const ContactUsContainer = () => {
  const [chaptersOptions, setChaptersOptions] = useState([]);
  const {allChapters} = useSelector(({chapters}) => chapters);
  const dispatch = useDispatch();

  useEffect(() => {
    if (allChapters) {
      const visibleChapters = allChapters?.data?.chapters?.filter(
        (chapter) => chapter?.is_visible_on_map === true,
      );
      setChaptersOptions(
        createSelectionOptionsUtil(visibleChapters, 'name', 'name'),
      );
    } else {
      dispatch(getAllChapters());
    }
  }, [allChapters, dispatch]);

  const submitContactForm = async (data, form) => {
    try {
      await dispatch(postContactForm(data, form));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ContactUsComponent
      submitContactForm={(data, form) => submitContactForm(data, form)}
      chaptersOptions={chaptersOptions}
    />
  );
};

export default ContactUsContainer;

import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Input, Popconfirm, Select, Table, Tag} from 'antd';
import {CaretDownOutlined} from '@ant-design/icons';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import './style.css';
import freeIcon from '../../../Assets/free-icon.svg';
import SectionLayout from './../../CommonComponents/Layout/sectionLayout';
const EditableContext = React.createContext(null);
const registrationOptions = [
  {
    label: 'Regular Registration $295',
    value: 1,
  },
  {
    label: 'Workshop Registration $300',
    value: 2,
  },
  {
    label: 'Symposis Registration $200',
    value: 3,
  },
];
const foodOptions = [
  {
    label: 'Thai Food $80',
    value: 4,
  },
  {
    label: 'Mexican Food $120',
    value: 5,
  },
  {
    label: 'Fish $50',
    value: 6,
  },
];

const EditableCell = ({
  title,
  children,
  dataIndex,
  record,
  save,
  toggleEdit,
  ...restProps
}) => {
  let inputElement;
  switch (dataIndex) {
    case 'registration_option':
      inputElement = (
        <Form.Item
          name={dataIndex}
          style={{margin: 0}}
          rules={[
            {
              required: true,
              message: `Please enter the ${title}.`,
            },
          ]}>
          <Select
            placeholder='Registration option'
            options={registrationOptions}
            suffixIcon={<CaretDownOutlined />}
            disabled={isEditableKey !== ''}
          />
        </Form.Item>
      );
      break;
    case 'food_option':
      inputElement = (
        <Form.Item
          name={dataIndex}
          style={{margin: 0}}
          rules={[
            {
              required: true,
              message: `Please enter the ${title}.`,
            },
          ]}>
          <Select
            placeholder='Food option'
            options={foodOptions}
            suffixIcon={<CaretDownOutlined />}
            disabled={isEditableKey !== ''}
          />
        </Form.Item>
      );
      break;
    case 'action':
      inputElement = (
        <AdminActionsDropdown
          record={record}
          saveBool={true}
          editBool={true}
          handleEditClick={toggleEdit}
          handleSaveClick={save}
          sendRecordinEdit={true}
          text='Sponsor'
        />
      );
      break;
    default:
      inputElement = (
        <Form.Item name={dataIndex} style={{margin: 0}}>
          <Input placeholder={`${title}`} disabled={isEditableKey !== ''} />
        </Form.Item>
      );
  }
  return <td {...restProps}>{editable ? inputElement : children}</td>;
};

const EditableRow = ({index, ...props}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const SponsorPerkForm = () => {
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);
  const initialRowData = [
    {
      key: '1',
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      registration_option: '',
      food_option: '',
    },
    {
      key: '2',
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      registration_option: '',
      food_option: '',
    },
  ];

  const [dataSource, setDataSource] = useState(initialRowData);

  // const handleDelete = (record) => {
  //   const newData = dataSource.filter((item) => item.key !== record.key);
  //   const filteredData = [
  //     {
  //       key: '3',
  //       firstName: '',
  //       lastName: '',
  //       companyName: '',
  //       email: '',
  //       registration_option: '',
  //       food_option: '',
  //     },
  //     ...newData,
  //   ];
  //   setDataSource(filteredData);
  // };

  const toggleEdit = (record) => {
    setEditing(!editing);
    if (!editing) {
      form.setFieldsValue({
        ...record,
      });
    }
  };

  const save = async (record) => {
    try {
      const values = await form.validateFields();
      handleSave({...record, ...values});
      setEditing(false);
    } catch (errInfo) {
      console.error('Save failed:', errInfo);
    }
  };
  const defaultColumns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      editable: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      editable: true,
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      editable: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      editable: true,
    },
    {
      title: 'Registration Option',
      dataIndex: 'registration_option',
      editable: true,
    },
    {
      title: 'Food Option',
      dataIndex: 'food_option',
      editable: true,
    },
    {
      title: 'Pricing',
      dataIndex: 'price',
      render: (text) => {
        return (
          <div className='sponsor-pricing'>
            <div className='price-text'>$0.0</div>
            <Tag color='red' prefixCls='sponsor-table-tag'>
              <div className='free-container'>
                <span className='free-icon'>
                  <img src={freeIcon}></img>
                </span>
                <span>Free </span>
              </div>
            </Tag>
          </div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      editable: true,
    },
  ];

  const handleSave = (row) => {
    const newData = dataSource.map((item) =>
      item.key === row.key ? {...item, ...row} : item,
    );
    setDataSource(newData);
  };
  const components = {
    body: {
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        save,
        toggleEdit,
      }),
    };
  });

  return (
    <div className='sponsor-perk-container'>
      <SectionLayout>
        <Form form={form}>
          <div>
            <div className='sponsor-table-heading'>
              <span className='sponsor-table-heading-text'>
                Included Benefits
              </span>
            </div>
            <Table
              prefixCls='sponsor-perk-table'
              className='sponsor-table-container'
              components={components}
              rowClassName={() => 'editable-row'}
              bordered={false}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </div>
        </Form>
      </SectionLayout>
    </div>
  );
};

export default SponsorPerkForm;

import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  createEventOptions,
  editEventOptions,
  getEventOptionsById,
} from '../../../../Store/Actions/eventOptions';
import {createSelectionOptionsUtil} from '../../../../Util/commonUtility';
import EventOptionsFormComponent from '../../../../Components/AdminComponents/EventOptions/EventOptionsForm';
import {getAllEvents} from './../../../../Store/Actions/events';
import {getAllEventOptionGroupsByQueryString} from './../../../../Store/Actions/eventOptionGroups';

const EventOptionsFormContainer = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {allEventOptionGroupsQueryData} = useSelector(
    ({eventOptionGroups}) => eventOptionGroups,
  );
  const {allEvents} = useSelector(({events}) => events);
  const {getEventOptionsData} = useSelector(({eventOptions}) => eventOptions);
  const [editData, setEditData] = useState();
  const [eventsList, setEventsList] = useState([]);
  const [eventOptionGroupsList, setEventOptionGroupsList] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (getEventOptionsData) {
      setEditData(getEventOptionsData?.data);
    }
  }, [getEventOptionsData]);

  useEffect(() => {
    if (allEvents) {
      setEventsList(
        createSelectionOptionsUtil(allEvents?.data?.events, 'id', 'name'),
      );
    } else {
      dispatch(getAllEvents());
    }
  }, [allEvents]);

  useEffect(() => {
    if (allEventOptionGroupsQueryData) {
      setEventOptionGroupsList(
        createSelectionOptionsUtil(
          allEventOptionGroupsQueryData?.data?.eventOptionGroups,
          'id',
          'name',
        ),
      );
    } else {
      dispatch(
        getAllEventOptionGroupsByQueryString(
          {
            page: 1,
            pagecount: 100,
          },
          '',
          '',
          '',
        ),
      );
    }
  }, [allEventOptionGroupsQueryData?.data?.eventOptionGroups]);

  const postEventOptions = async (Data) => {
    dispatch(createEventOptions(Data, navigate));
  };

  const updateEventOptions = async (Data, id) => {
    dispatch(editEventOptions(Data, id, navigate));
  };
  const fetchEventOptionsById = async (id) => {
    dispatch(getEventOptionsById(id));
  };
  return (
    <div>
      <EventOptionsFormComponent
        postEventOptions={(Data) => {
          postEventOptions(Data);
        }}
        updateEventOptions={(id, Data) => {
          updateEventOptions(id, Data);
        }}
        fetchEventOptionsById={(id) => {
          fetchEventOptionsById(id);
        }}
        EditData={editData}
        eventsList={eventsList}
        eventOptionGroupsList={eventOptionGroupsList}
        setEventOptionGroupsList={setEventOptionGroupsList}
        fetching={fetching}
        setFetching={setFetching}
      />
    </div>
  );
};

export default EventOptionsFormContainer;

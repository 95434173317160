import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {hideMessage} from '../../../Store/Actions';
import NotificationService from '../../../Services/Common/notification';
import {Spin} from 'antd';

const AppInfoView = () => {
  const {loading, error, displayMessage} = useSelector(({common}) => common);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      NotificationService.error('Error', error);
      dispatch(hideMessage());
    }
  }, [error]);

  useEffect(() => {
    if (displayMessage) {
      NotificationService.success('Success', displayMessage);
      dispatch(hideMessage());
    }
  }, [displayMessage]);

  return <>{loading ? <Spin prefixCls='pbss-loder' /> : null}</>;
};

export default AppInfoView;

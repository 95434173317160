import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  //   UPDATING_CONTENT,
  // UPDATE_EVENT_TEMPLATE_SCHEDULER_STATUS_BY_ID,
  GET_ALL_EVENT_TEMPLATE_SCHEDULERS,
  ADD_EVENT_TEMPLATE_SCHEDULER_SUCCESS,
  DELETE_EVENT_TEMPLATE_SCHEDULER_BY_ID,
  FETCH_EVENT_TEMPLATE_SCHEDULER_BY_ID,
  FETCH_EVENT_TEMPLATE_SCHEDULERS_BY_USERID,
  GET_EVENT_TEMPLATE_SCHEDULER_BY_EVENT_ID,
  SET_EVENT_TEMPLATE_EDIT,
  SEND_DIRECT_EMAIL_BY_ID,
  SET_EVENT_START_DATE_EVENT_TEMPLATE_SCHEDULER,
} from '../../Constant/ActionTypes';

import Urls from '../../Constant/Urls';
import {httpClient} from '../../Api/client';
import {commonSortOrder, handleJwtExpireMsg} from '../../Util/commonUtility';
import {setEventNameState} from './chapters';

// Action creators

const fetchStart = () => ({type: FETCH_START});
const fetchSuccess = () => ({type: FETCH_SUCCESS});
const fetchError = (error) => ({type: FETCH_ERROR, payload: error});

const showMessage = (message) => ({type: SHOW_MESSAGE, payload: message});

// EventTemplateScheduler actions

export const getAllEventTemplateSchedulers =
  (sortField, sortOrder, queryString, pagination) => async (dispatch) => {
    dispatch(fetchStart());
    try {
      const response = await httpClient.get(
        `${Urls.EVENT_TEMPLATE_SCHEDULER_GET_ALL}?page=${
          pagination.page
        }&pageCount=${pagination.pageCount}&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${queryString}`,
      );

      dispatch(fetchSuccess());
      dispatch({
        type: GET_ALL_EVENT_TEMPLATE_SCHEDULERS,
        payload: response?.data,
      });
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch(fetchError(errorMessage));
    }
  };

export const addEventTemplateScheduler =
  (eventTemplateSchedulerData, navigate) => async (dispatch) => {
    dispatch(fetchStart());
    try {
      const response = await httpClient.post(
        Urls.EVENT_TEMPLATE_SCHEDULER_ADD,
        eventTemplateSchedulerData,
      );
      if (response?.status === HttpCode.Ok) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Event Template Scheduler Successfully Created'));
        dispatch({
          type: ADD_EVENT_TEMPLATE_SCHEDULER_SUCCESS,
          payload: response?.data,
        });
        navigate &&
          navigate(
            `/admin/eventEmailTemplatesScheduler/${response?.data?.data?.event_id}`,
          );
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch(fetchError(errorMessage));
    }
  };

export const getEventTemplateSchedulerByEventId =
  (eventTemplateSchedulerId) => async (dispatch) => {
    dispatch(fetchStart());
    try {
      const response = await httpClient.get(
        Urls.EVENT_TEMPLATE_SCHEDULER_FETCH_BY_EVENT_ID.replace(
          '#{eventEmailTemplateId}',
          eventTemplateSchedulerId,
        ),
      );
      if (response?.status === HttpCode.Ok) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_EVENT_TEMPLATE_SCHEDULER_BY_EVENT_ID,
          payload: response?.data,
        });
        dispatch(setEventNameState(response?.data?.data?.event_name));
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch(fetchError(errorMessage));
    }
  };

export const getEventTemplateSchedulerById =
  (eventTemplateSchedulerId) => async (dispatch) => {
    dispatch(fetchStart());
    try {
      const response = await httpClient.get(
        Urls.EVENT_TEMPLATE_SCHEDULER_FETCH_BY_ID.replace(
          '#{eventEmailTemplateId}',
          eventTemplateSchedulerId,
        ),
      );
      if (response?.status === HttpCode.Ok) {
        dispatch(fetchSuccess());
        dispatch({
          type: FETCH_EVENT_TEMPLATE_SCHEDULER_BY_ID,
          payload: response?.data,
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch(fetchError(errorMessage));
    }
  };

export const fetchEventTemplateSchedulersByUserId =
  (userId, searchVendor, filterStatus, setAllEventTemplateSchedulersData) =>
  async (dispatch) => {
    dispatch(fetchStart());
    try {
      const response = await httpClient.get(
        `${Urls.EVENT_TEMPLATE_SCHEDULERS_GET_BY_USERID.replace(
          '#{userId}',
          userId,
        )}?sortField=id&sortOrder=desc&vendor=${searchVendor || ''}&status=${
          filterStatus || ''
        }`,
      );

      if (response?.status === HttpCode.Ok) {
        dispatch(fetchSuccess());
        dispatch({
          type: FETCH_EVENT_TEMPLATE_SCHEDULERS_BY_USERID,
          payload: response?.data,
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch(fetchError(errorMessage));
    }
  };

export const updateEventTemplateSchedulerById =
  (updatedEventTemplateSchedulerData, eventTemplateSchedulerId, navigate) =>
  async (dispatch) => {
    dispatch(fetchStart());
    try {
      const response = await httpClient.patch(
        Urls.EVENT_TEMPLATE_SCHEDULER_UPDATE_BY_ID.replace(
          '#{eventTemplateSchedulerId}',
          eventTemplateSchedulerId,
        ),
        updatedEventTemplateSchedulerData,
      );

      if (response?.status === HttpCode.Ok) {
        dispatch(fetchSuccess());
        dispatch(showMessage(response?.data?.message));
        dispatch({
          type: FETCH_EVENT_TEMPLATE_SCHEDULER_BY_ID,
          payload: response?.data,
        });
        const eventId = updatedEventTemplateSchedulerData.get('event_id');
        navigate && navigate(`/admin/eventEmailTemplatesScheduler/${eventId}`);
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch(fetchError(errorMessage));
    }
  };

// export const editEventTemplateSchedulerStatusById = (id, status) => async (dispatch) => {
//   dispatch({ type: UPDATING_CONTENT });
//   try {
//     const response = await httpClient.patch(
//       `${Urls.EVENT_TEMPLATE_SCHEDULER_UPDATE_STATUS_BY_ID.replace('#{eventTemplateSchedulerId}', id).replace('#{eventTemplateSchedulerStatus}', status)}`
//     );

//     if (response.status === HttpCode.Ok) {
//       dispatch(fetchSuccess());
//       dispatch(showMessage('Event Template Scheduler Status Updated Successfully'));
//       dispatch({ type: UPDATE_EVENT_TEMPLATE_SCHEDULER_STATUS });
//     }
//   } catch (error) {
//     let errorMessage = error.response?.data?.errorMessage || 'Something went wrong';
//     dispatch(fetchError(errorMessage));
//   }
// };

export const deleteEventTemplateSchedulerById =
  (eventTemplateSchedulerId) => async (dispatch) => {
    dispatch(fetchStart());
    try {
      const response = await httpClient.delete(
        Urls.EVENT_TEMPLATE_SCHEDULER_DELETE_BY_ID.replace(
          '#{eventTemplateSchedulerId}',
          eventTemplateSchedulerId,
        ),
      );

      if (response?.status === HttpCode.Ok) {
        dispatch(fetchSuccess());
        dispatch(showMessage(response?.data?.message));
        dispatch({
          type: DELETE_EVENT_TEMPLATE_SCHEDULER_BY_ID,
          payload: eventTemplateSchedulerId,
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch(fetchError(errorMessage));
    }
  };

export const setEventTemplateEdit = (state) => {
  return async (dispatch) => {
    try {
      dispatch({type: SET_EVENT_TEMPLATE_EDIT, payload: state});
    } catch (error) {}
  };
};
export const setEventStartDateForEventTemplateScheduler = (state) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_EVENT_START_DATE_EVENT_TEMPLATE_SCHEDULER,
        payload: state,
      });
    } catch (error) {}
  };
};

export const sendDirectEmailByTemplateId =
  (eventTemplateSchedulerId) => async (dispatch) => {
    dispatch(fetchStart());
    try {
      const response = await httpClient.post(
        Urls.EVENT_TEMPLATE_SCHEDULER_SEND_DIRECT_EMAIL_BY_ID.replace(
          '#{eventTemplateSchedulerId}',
          eventTemplateSchedulerId,
        ),
      );

      if (response?.status === HttpCode.Ok) {
        dispatch(fetchSuccess());
        dispatch(showMessage(response?.data?.message));
        dispatch({
          type: SEND_DIRECT_EMAIL_BY_ID,
          payload: eventTemplateSchedulerId,
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch(fetchError(errorMessage));
    }
  };

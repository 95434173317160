import React from 'react';

const SectionLayout = (props) => {
  return (
    <React.Fragment>
      <div
        className={`main-section-conatiner common-container-section ${props.extendClass}`}>
        {props.children}
      </div>
    </React.Fragment>
  );
};
export default SectionLayout;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {getJobsById} from '../../../../Store/Actions';
import JobsViewDetailsComponent from '../../../../Components/UserComponents/Jobs/ViewDetails';

const JobsViewDetailsContainer = () => {
  const dispatch = useDispatch();
  const {getJobData} = useSelector(({jobs}) => jobs);
  const [jobsDataById, setJobsDataById] = useState([]);
  const {id} = useParams();

  useEffect(() => {
    dispatch(getJobsById(id));
  }, [id]);

  useEffect(() => {
    if (getJobData) {
      setJobsDataById(getJobData?.data);
    }
  }, [getJobData]);

  return (
    <div>
      <JobsViewDetailsComponent jobsDataById={jobsDataById} />
    </div>
  );
};

export default JobsViewDetailsContainer;

import {Col, Row} from 'antd';
import React, {useEffect} from 'react';
import {useParams} from 'react-router';
import CommonPaymentCheckoutForm from '../../../CommonComponents/CommonPaymentCheckoutForm';
import SectionLayout from '../../../CommonComponents/Layout/sectionLayout';
import NormalJobsCartComponent from './NormalJobCart';
import {Elements} from '@stripe/react-stripe-js';
import {useDispatch, useSelector} from 'react-redux';
import {
  postJobPaymentSuccess,
  resetJobPayment,
} from '../../../../Store/Actions';
import CommonPageHeroText from '../../../CommonComponents/CommonPageHeroText';
import {createStipePromise} from '../../../../Util/commonUtility';

const JobsCartComponent = ({jobData, jobFeesOptions}) => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {clientSecret, publishable_key} = useSelector(
    (state) => state?.jobs?.jobsPayment,
  );

  const stripePromise = createStipePromise(`${publishable_key}`);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  const dispatchSuccess = (id, intent) => {
    dispatch(postJobPaymentSuccess({jobsId: id, paymentIntentId: intent}));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    return () => {
      dispatch(resetJobPayment());
    };
  }, []);
  return (
    <React.Fragment>
      <Row gutter={[0, 28]} className='pbssMainContainerRow'>
        <Col span={24}>
          {clientSecret ? (
            <SectionLayout>
              <Elements options={options} stripe={stripePromise}>
                <div className='card-stripe-container'>
                  <CommonPaymentCheckoutForm
                    clientSecret={clientSecret}
                    price={jobData?.length > 0 && jobData[0]?.job_fee}
                    DataId={parseInt(id)}
                    goBackURL={`/jobs/cart/${parseInt(id)}`}
                    dispatchSuccess={(id, intent) => {
                      dispatchSuccess(id, intent);
                    }}
                    successUrl={'/paymentSuccess/jobs'}
                    resetPayment={resetJobPayment}
                  />
                </div>
              </Elements>
            </SectionLayout>
          ) : (
            <NormalJobsCartComponent
              jobData={jobData}
              jobFeesOptions={jobFeesOptions}
            />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default JobsCartComponent;

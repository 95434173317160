import React from 'react';
import CommonJobListing from '../CommonJobListing';
import SectionLayout from '../../../CommonComponents/Layout/sectionLayout';
import dayjs from 'dayjs';
import PageTitleCard from '../../../CommonComponents/PageTitleCard';
import PageHeading from '../../../CommonComponents/PageHeading';
import {all} from 'axios';
import CommonJobsListingCard from '../CommonJobListing/CommonJobListingCard';
import {Button, Col, Row} from 'antd';
import DownArrowSvg from '../../../../Assets/svgComponents/DownArrowSvg';
import CommonHeroText from '../../../CommonComponents/CommonHeroText';
import {ApplicationString} from '../../../../Localization/Locales/en';

const ApprovedJobListing = ({allApprovedJobsData}) => {
  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRowJobsListingPage'>
        <Col span={24}>
          <CommonHeroText
            heading={
              ApplicationString['userApprovedJobsListingComponent.heading']
            }
            subheading={
              ApplicationString['userApprovedJobsListingComponent.subtitle']
            }
          />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <span className='jobListingPageContentHeading'>
              {ApplicationString['userApprovedJobsListingComponent.subheading']}
            </span>
          </SectionLayout>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <div className='common-jobs-listing-main-container'>
              {allApprovedJobsData.map((item, index) => {
                return (
                  <CommonJobsListingCard
                    key={item?.id}
                    jobId={item?.id}
                    jobLocation={item?.location}
                    jobLastDate={item?.end_date}
                    jobEmployer={item?.employer}
                    jobTitle={item?.position}
                    jobDescription={item?.description}
                    jobStatus={item?.status}
                  />
                );
              })}
            </div>
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ApprovedJobListing;

import React from 'react';
import {Tooltip} from 'antd';

/**
 * Give props for text and flag for tooltip need to show or not.
 * @param {string} columnText - The text of column.
 * @param {boolean} isToolTip - Flag for showing tooltip.
 */
const CommonTableColumnTextView = ({
  columnText,
  isToolTip,
  usedForCurrency = false,
}) => {
  return columnText ? (
    isToolTip ? (
      <Tooltip prefixCls='common-pbss-tooltip' title={columnText}>
        <div
          className={`${usedForCurrency && 'currency-text'} fixed-size-text`}>
          {columnText}
        </div>
      </Tooltip>
    ) : (
      <div className={`${usedForCurrency && 'currency-text'} fixed-size-text`}>
        {columnText}
      </div>
    )
  ) : (
    ''
  );
};

export default CommonTableColumnTextView;

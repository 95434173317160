import React, {useEffect, useState} from 'react';
import {Space, Table, Popconfirm, Tooltip, Button} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {
  EventTypesColumnValues,
  PageSizeArray,
  PaginationPosition,
  eventTypesFilters,
} from '../../../Util/commonUtility';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import dayjs from 'dayjs';
import {ApplicationString} from '../../../Localization/Locales/en';
import plus from '../../../Assets/Plus.svg';
import UpdateHistoryTableInfo from '../HistoryView';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {useSelector} from 'react-redux';

const EventTypesComponent = (props) => {
  let navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const {
    allEventTypesData,
    fetchEventTypes,
    paging,
    handlePaginationChange,
    DeleteEventTypes,
    sortParams,
    handleTableChange,
    searchValues,
  } = props;
  const ps = useSelector(({pageSizes}) => pageSizes.pageSizes.eventTypes);

  const handleSearch = (values) => {
    fetchEventTypes(values);
  };

  useEffect(() => {
    searchValues && setExpanded(true);
  }, [searchValues]);

  const handleEditClick = async (id) => {
    navigate(`/admin/eventTypes/update/${id}`);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: '',
      width: 60,
    },
    {
      title: 'Order Of Event Type',
      dataIndex: 'order_number',
      key: 'order_number',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
    },
    {
      title: '',
      key: 'action',
      width: '6%',
      render: (_, record) => (
        <AdminActionsDropdown
          record={record}
          editBool={true}
          deleteBool={true}
          handleEditClick={handleEditClick}
          handleDeleteClick={DeleteEventTypes}
          text='Event Type'
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sorter = true;
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });

  const searchColumn = columns.filter((column) =>
    EventTypesColumnValues.includes(column.dataIndex),
  );

  return (
    <div className='main-container'>
      <div className='search-container' style={{gap: '10px'}}>
        <Button
          onClick={toggleExpand}
          id='EventTypesComponent-button-filterSearch'>
          {expanded ? <FilterOutlined /> : <FilterOutlined />}{' '}
          {ApplicationString['dynamicSearch.button.heading']}
        </Button>
        <Button
          type='primary'
          onClick={() => navigate('/admin/eventTypes/add')}
          id='EventTypesComponent-button-addEventTypes'
          className='common-button'>
          <div className='icon-container'>
            <img src={plus} alt='plus' className='icon-plus' />
            <span className='add-content'>
              {ApplicationString['eventTypesComponent.button.addForm']}
            </span>
          </div>
        </Button>
      </div>
      {expanded && (
        <DynamicSearchForm
          columns={eventTypesFilters}
          onSearch={handleSearch}
          savedFilteredValues={searchValues}
          title={'event_types'}
        />
      )}
      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          columns={columns}
          onChange={handleTableChange}
          dataSource={allEventTypesData}
          key={allEventTypesData?.map((item) => {
            item.id;
          })}
          // sticky={true}
          pagination={{
            onChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            current: paging.page,
            total: paging.totalCount,
            pageSize: ps || paging.pageCount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default EventTypesComponent;

import React, {useEffect, useState} from 'react';
import UserUpdateProfileComponent from '../../../Components/UserComponents/UserUpdateProfile';
import {
  fetchUsersById,
  fetchUsersByIdForUpdateProfile,
  getAllApprovedAds,
  getAllApprovedJobs,
  getAllChapters,
  getAllKeywords,
  getAllKeywordsByQuerySearch,
  resetKeywordSearchData,
  updateUserById,
} from '../../../Store/Actions';
import {createSelectionOptionsUtil} from '../../../Util/commonUtility';
import {useDispatch, useSelector} from 'react-redux';
import {getLocalStorageUserInfo} from './../../../Util/commonUtility';

const UserUpdateProfileContainer = () => {
  const dispatch = useDispatch();
  const {fetchUserDataForUpdateProfile} = useSelector(({users}) => users);
  const {allChapters} = useSelector(({chapters}) => chapters);
  const {allKeywordsQueryData} = useSelector(({keywords}) => keywords);
  const [userData, setUserData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [chapterList, setChapterList] = useState([]);
  const [keywordsOptions, SetKeywordsOptions] = useState([]);
  const userInfo = getLocalStorageUserInfo();
  const userId = userInfo?.id;

  useEffect(() => {
    dispatch(resetKeywordSearchData(false));
  }, []);

  useEffect(() => {
    if (fetchUserDataForUpdateProfile) {
      setUserData(fetchUserDataForUpdateProfile?.data);
    } else {
      dispatch(fetchUsersByIdForUpdateProfile(userId));
    }
  }, [fetchUserDataForUpdateProfile?.data]);

  useEffect(() => {
    if (allChapters) {
      const visibleChapters = allChapters?.data?.chapters?.filter((chapter) => {
        return chapter?.is_visible_on_map === true;
      });
      setChapterList(createSelectionOptionsUtil(visibleChapters, 'id', 'name'));
    } else {
      dispatch(getAllChapters());
    }
  }, [allChapters]);

  useEffect(() => {
    if (allKeywordsQueryData.forSearch) {
      SetKeywordsOptions(
        createSelectionOptionsUtil(
          allKeywordsQueryData.forSearch?.data?.keywords,
          'id',
          'name',
        ),
      );
      setFetching(false);
    } else {
      dispatch(
        getAllKeywordsByQuerySearch('', '', '', {
          page: 1,
          pageCount: 10,
        }),
      );
    }
  }, [allKeywordsQueryData.forSearch]);

  // useEffect(() => {
  //   if (allKeywords) {
  //     setKeywordsList(
  //       createSelectionOptionsUtil(allKeywords?.data?.keywords, 'name', 'name'),
  //     );
  //   } else {
  //     dispatch(getAllKeywords());
  //   }
  // }, [allKeywords]);

  const updateUser = async (Data, id, Navigate) => {
    dispatch(updateUserById(Data, id, Navigate));
  };

  return (
    <UserUpdateProfileComponent
      chaptersList={chapterList}
      userData={userData}
      updateUser={(id, Data, Navigate) => {
        updateUser(id, Data, Navigate);
      }}
      keywordsOptions={keywordsOptions}
      SetKeywordsOptions={SetKeywordsOptions}
      fetching={fetching}
      setFetching={setFetching}
    />
  );
};

export default UserUpdateProfileContainer;

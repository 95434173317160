import React from 'react';
import './style.css';
import SectionLayout from '../Layout/sectionLayout';

const ChapterPageheroText = ({
  heading,
  subheading,
  bgColor,
  pd,
  chapterHide,
}) => {
  return (
    <>
      <div
        className='hero-text-container'
        style={{background: `${bgColor}`, padding: `${pd}`}}>
        <span className='heading'>{heading}</span>
        <span className='subheading'>
          {`${subheading}`} {chapterHide && 'Chapter'}
        </span>
      </div>
    </>
  );
};

export default ChapterPageheroText;

import React, {useEffect} from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import PageHeading from '../../CommonComponents/PageHeading';
import {Button, Col, Form, Input, Row, Select} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {ApplicationString} from '../../../Localization/Locales/en';
import {
  NoEmptySpaceInput,
  OnlyAlphabetInputValidator,
  OnlyAlphabetInputValidatorWithSpecialCharacters,
  formValueChangeHandler,
  validateContactDetails,
  validateEmailInput,
  validatePhoneNumber,
} from '../../../Util/commonUtility';
import './style.css';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import ComponentTitle from '../../CommonComponents/CommonComponentTitle';
import ReCAPTCHA from 'react-google-recaptcha';
import {applicationConfig} from '../../../Util/config';
import CustomCollapse from '../../CommonComponents/CommonAccordian';

const ContactUsComponent = ({chaptersOptions, submitContactForm}) => {
  const [form] = Form.useForm();
  const eventSubjectOptions = [
    {
      label: 'General Info',
      value: 'General Info',
    },
    {
      label: 'Ad Placement',
      value: 'Ad Placement',
    },
    {
      label: 'Sponsorship',
      value: 'Sponsorship',
    },
    {
      label: 'Vendor Services',
      value: 'Vendor Services',
    },
    {
      label: 'Others',
      value: 'Others',
    },
  ];
  const onFinish = (values) => {
    delete values.captcha;
    delete values.email_confirm;
    submitContactForm(values, form);
  };

  const backTOChapter = () => {
    Navigate('/');
  };

  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <React.Fragment>
      <Row gutter={[0, 28]} className='pbssMainContainerRow'>
        <Col span={24}>
          <SponsorOptionPageHeroText
            title={ApplicationString['userContactUsComponent.heading']}
          />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <ComponentTitle
              usedForContactPage={true}
              extraClass={'ms-auto-mobile'}
              hideButton
              textColor={'black'}
              title={ApplicationString['userContactUsComponent.ThankyouText']}
              description={
                ApplicationString['userContactUsComponent.description']
              }
            />
          </SectionLayout>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <div className='contact-us-container'>
              <Form
                form={form}
                name='basic'
                layout='vertical'
                onFinish={onFinish}
                onValuesChange={(changedValues) =>
                  formValueChangeHandler(form, changedValues, 'contactUs')
                }
                initialValues={''}
                onFinishFailed={onFinishFailed}
                autoComplete='off'
                className='two-column-form'>
                <div className='input-container extend-contactUs-form'>
                  <div className='form-column'>
                    <Form.Item
                      // label='Name'
                      name='name'
                      rules={[
                        {
                          required: true,
                          message: 'Please fill out your name!',
                        },
                        {
                          validator: (_, value) =>
                            OnlyAlphabetInputValidatorWithSpecialCharacters(
                              value,
                              'Name',
                            ),
                        },
                      ]}>
                      <Input
                        prefixCls='user-common-input-user'
                        className='genral-form'
                        placeholder={
                          ApplicationString[
                            'userContactUsFormComponent.name.placeholder'
                          ]
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name='state'
                      rules={[
                        {
                          validator: (_, value) =>
                            OnlyAlphabetInputValidator(value, 'State'),
                        },
                      ]}>
                      {/* <Select
                        prefixCls='common-select-register'
                        showSearch
                        allowClear
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          (option?.label ?? '').includes(input)
                        }
                        className='space'
                        placeholder={'Select State / Proviance'}
                        options={[]}></Select> */}
                      <Input
                        prefixCls='user-common-input-user'
                        className='genral-form'
                        placeholder={'Enter state/province'}
                      />
                    </Form.Item>
                    <Form.Item
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: 'Please fill out your email!',
                        },
                        {
                          validator: (_, value) =>
                            validateEmailInput(value, 'Email'),
                        },
                      ]}>
                      <Input
                        prefixCls='user-common-input-user'
                        className='genral-form'
                        placeholder={
                          ApplicationString[
                            'userContactUsFormComponent.email.placeholder'
                          ]
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name='phoneNumber'
                      rules={[
                        {
                          validator: (_, value) =>
                            validatePhoneNumber(value, 'Phone number'),
                        }, // Custom validator
                      ]}>
                      <Input
                        prefixCls='user-common-input-user'
                        className='genral-form'
                        placeholder={
                          ApplicationString[
                            'userContactUsFormComponent.phoneNumber.placeholder'
                          ]
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className='form-column'>
                    <Form.Item
                      name='city'
                      rules={[
                        {
                          validator: (_, value) =>
                            OnlyAlphabetInputValidator(value, 'City'),
                        },
                      ]}>
                      {/* <Select
                        prefixCls='common-select-register'
                        showSearch
                        allowClear
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          (option?.label ?? '').includes(input)
                        }
                        className='space'
                        placeholder={
                          ApplicationString[
                            'userContactUsFormComponent.selectCity.placeholder'
                          ]
                        }
                        options={[]}></Select> */}
                      <Input
                        prefixCls='user-common-input-user'
                        className='genral-form'
                        placeholder={
                          ApplicationString[
                            'userContactUsFormComponent.selectCity.placeholder'
                          ]
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name='country'
                      rules={[
                        {
                          validator: (_, value) =>
                            OnlyAlphabetInputValidator(value, 'Country'),
                        },
                      ]}>
                      {/* <Select
                        prefixCls='common-select-register'
                        showSearch
                        allowClear
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          (option?.label ?? '').includes(input)
                        }
                        className='space'
                        placeholder={
                          ApplicationString[
                            'userContactUsFormComponent.selectCountry.placeholder'
                          ]
                        }
                        options={[]}></Select> */}
                      <Input
                        prefixCls='user-common-input-user'
                        className='genral-form'
                        placeholder={
                          ApplicationString[
                            'userContactUsFormComponent.selectCountry.placeholder'
                          ]
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name='email_confirm'
                      rules={[
                        {
                          required: true,
                          message: 'Please fill out your email!',
                        },
                        {
                          validator: (_, value) => {
                            validateEmailInput(value, 'Email');
                            if (
                              !value ||
                              form.getFieldValue('email') === value
                            ) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                new Error('Email IDs must match'),
                              );
                            }
                          },
                        },
                      ]}>
                      <Input
                        prefixCls='user-common-input-user'
                        className='genral-form'
                        placeholder={
                          ApplicationString[
                            'userContactUsFormComponent.emailConfirm.placeholder'
                          ]
                        }
                      />
                    </Form.Item>
                    <Form.Item name='affiliation'>
                      <Select
                        prefixCls='common-select-contact'
                        showSearch
                        allowClear
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          (option?.label ?? '').includes(input)
                        }
                        className='space'
                        placeholder={
                          ApplicationString[
                            'userContactUsFormComponent.selectAffiliation.placeholder'
                          ]
                        }
                        options={chaptersOptions}></Select>
                      {/* <Input
                        prefixCls='user-common-input-user'
                        className='genral-form'
                        placeholder={
                          ApplicationString[
                            'userContactUsFormComponent.selectAffiliation.placeholder'
                          ]
                        }
                      /> */}
                    </Form.Item>
                  </div>
                </div>
                <div className='input-container'>
                  <div className='form-column'>
                    <Form.Item
                      name='topic'
                      rules={[
                        {
                          required: true,
                          message: 'Please fill out inquiry title!',
                        },
                        {
                          validator: (_, value) =>
                            NoEmptySpaceInput(value, 'Inquiry Title'),
                        },
                      ]}>
                      <Input
                        prefixCls='user-common-input-user'
                        className='genral-form'
                        placeholder={
                          ApplicationString[
                            'userContactUsFormComponent.inquiry.placeholder'
                          ]
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className='form-column'>
                    <Form.Item name='emailSubject'>
                      <Select
                        prefixCls='common-select-contact'
                        showSearch
                        allowClear
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          (option?.label ?? '').includes(input)
                        }
                        className='space'
                        placeholder={
                          ApplicationString[
                            'userContactUsFormComponent.selectEmailSubject.placeholder'
                          ]
                        }
                        options={eventSubjectOptions}></Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='input-container'>
                  <div className='form-column'>
                    <Form.Item
                      name='comments'
                      rules={[
                        {
                          required: true,
                          message: 'Please fill out your details!',
                        },
                        {
                          validator: (_, value) =>
                            validateContactDetails(value, 'Details'),
                        },
                      ]}>
                      <TextArea
                        className='user-common-input-user genral-form extend-contactUs'
                        placeholder={
                          ApplicationString[
                            'userContactUsFormComponent.description.placeholder'
                          ]
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='input-container'>
                  <div className='form-column'>
                    <Form.Item>
                      <p className='re-captcha-label-login'>
                        {
                          ApplicationString[
                            'userContactUsComponent.captchaText'
                          ]
                        }
                      </p>
                      <Form.Item
                        name='captcha'
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: 'Please click on the CAPTCHA above',
                          },
                        ]}>
                        <ReCAPTCHA
                          sitekey={
                            applicationConfig.CAPTCHA_SITE_KEY
                          }></ReCAPTCHA>
                      </Form.Item>
                    </Form.Item>
                  </div>
                </div>
                <Form.Item>
                  <div className='button-container'>
                    <Button
                      id='contactUs-submitFeedback-button'
                      type='primary'
                      htmlType='submit'
                      className='user-common-submit-login common-form-submit contact-us-button'>
                      {
                        ApplicationString[
                          'dynamicSearch.button.submit-your-feedback'
                        ]
                      }
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </SectionLayout>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <CustomCollapse />
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ContactUsComponent;

import {Button, Col, Row, Table, Tag} from 'antd';
import React, {useState} from 'react';
import CommonHeroText from '../../CommonComponents/CommonHeroText';
import {ApplicationString} from '../../../Localization/Locales/en';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import {
  exportToCSV,
  formatCurrency,
  getStatusColor,
} from '../../../Util/commonUtility';
import './style.css';
import CommonAddNewListingWithFilter from '../../CommonComponents/Common_AddNewListing_with_Filter';
import {CreditCardOutlined, SelectOutlined} from '@ant-design/icons';
import DownArrowSvg from '../../../Assets/svgComponents/DownArrowSvg';

const PaymentHistoryComponent = ({getAllUserPaymentData, handleLoadMore}) => {
  const [activeFilter, setActiveFilter] = useState('all');

  const handleExport = () => {
    // Modify the data as needed for exporting
    const dataToExport = dataSource.map((record) => ({
      Amount: record.amount,
      Status: record.status,
      'Payment Method': record.paymentMethod,
      Description: record.description,
      Email: record.email,
      Date: record.date,
    }));
    exportToCSV(dataToExport, 'payment_history_data');
  };

  const columns = [
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => (
        <span className='common-text-payment attendees-table-cell'>
          {formatCurrency(record.amount)}
        </span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <span className='attendees-table-cell'>
          <Tag color={getStatusColor(record.status)}>{record.status}</Tag>
        </span>
      ),
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (text, record) => (
        <span className='common-text-payment attendees-table-cell'>
          <Tag className='tag-render'>
            <CreditCardOutlined />
            {' 400 *** 302'}{' '}
          </Tag>
        </span>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => (
        <span className='common-text-desc attendees-table-cell'>
          {record.description}
        </span>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => (
        <span className='common-text-desc attendees-table-cell'>
          {record.email}
        </span>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => (
        <span className='common-text-desc attendees-table-cell'>
          {record.date}
        </span>
      ),
    },
  ];

  const statusOptions = [
    {key: 'all', value: 'all', name: 'All'},
    {key: 'paid', value: 'paid', name: 'Paid'},
    {key: 'pending', value: 'pending', name: 'Pending'},
    {key: 'refund', value: 'refund', name: 'Refund'},
    {key: 'failed', value: 'failed', name: 'Failed'},
    {key: 'non-refund', value: 'non-refund', name: 'Non-refund'},
  ];

  return (
    <Row gutter={[0, 30]} className='pbssMainContainerRowJobsListingPage'>
      <Col span={24}>
        <CommonHeroText
          heading={ApplicationString['userPaymentHistoryComponent.heading']}
          subheading={
            ApplicationString['userPaymentHistoryComponent.subheading']
          }
        />
      </Col>
      <Col span={24}>
        <SectionLayout>
          <div className='filter-action-main'>
            <div className='filters-container'>
              {/* <button
                className={activeFilter === 'all' ? 'active-payment' : ''}
              >
                All Payments
              </button>

              <button
                className={activeFilter === 'refund' ? 'active-payment' : ''}
              >
                Refunds
              </button>

              <button
                className={activeFilter === 'failed' ? 'active-payment' : ''}
              >
                Failed Tx
              </button> */}
            </div>
            <div className='filter-action-container'>
              <CommonAddNewListingWithFilter
                addNew={false}
                usedForMyEvents={false}
                selectPlaceholder={'Status'}
                List={statusOptions}
                placeHolder='Search by event name'
              />
              <Button type='default' onClick={handleExport}>
                Export
                <SelectOutlined />
              </Button>
            </div>
          </div>
        </SectionLayout>
      </Col>
      <Col span={24}>
        <SectionLayout>
          <Table
            scroll={{x: getAllUserPaymentData?.length * 200}}
            className='attendees-table payment-history-table'
            pagination={{
              showTotal: (total, range) => `Total ${total} items`,
              position: ['bottomLeft'],
              // hideOnSinglePage: true, // This will hide the pagination when there's only one page
              // pageSize: 10, // Set the number of items per page
            }}
            dataSource={getAllUserPaymentData}
            columns={columns}
            footer={() =>
              getAllUserPaymentData?.length > 0 && (
                <div className='view-more-container'>
                  <Button
                    className='view-more-button view-more-extend'
                    onClick={handleLoadMore}>
                    <span className='view-more-text view-more-text-extend'>
                      View More Transaction
                    </span>
                  </Button>
                </div>
              )
            }
          />
        </SectionLayout>
      </Col>
    </Row>
  );
};

export default PaymentHistoryComponent;

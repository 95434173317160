import {EyeOutlined, FilterOutlined} from '@ant-design/icons';
import {Button, Popover, Table, Tag, Col, Row, Divider, Tooltip} from 'antd';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {CSVLink} from 'react-csv';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import plus from '../../../Assets/Plus.svg';
import {ApplicationString} from '../../../Localization/Locales/en';
import {
  AdsAndJobsOptions,
  PageSizeArray,
  PaginationPosition,
  formatCurrency,
  formatDate,
  jobsFilters,
  ReturnNAOnEmpty,
  AdsAndJobsOptionsForAdmin,
  addHttpsToUrl,
} from '../../../Util/commonUtility';
import useDebouncedCallback from '../../../hooks/useDebounce';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import StatusUpdateModal from '../../CommonComponents/CommonModal';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import CommonTableColumnTextView from './../../CommonComponents/CommonTableColumnTextView/index';
import CustomizedTable from '../../CommonComponents/CommonTable';
import {DescriptionItem} from './../../../Util/commonUtility';
import AdminViewDetailsDrawer from '../../CommonComponents/AdminViewDetailsDrawer';

const JobsComponent = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [recordData, setRecordData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);

  let navigate = useNavigate();
  const {
    allJobsData,
    handlePaginationChange,
    paging,
    sortParams,
    handleTableChange,
    fetchJobs,
    deleteJobs,
    updateStatusById,
    searchValues,
    fetchJobsWithoutPagination,
    jobsFetchingWithoutPagination,
    totalJobFees,
    allJobsWithoutPaginationData,
  } = props;

  const ps = useSelector(({pageSizes}) => pageSizes.pageSizes.jobs);

  useEffect(() => {
    searchValues && setExpanded(true);
  }, [searchValues]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleSearch = (values) => {
    fetchJobs(values);
  };

  const handleEditClick = async (id) => {
    navigate(`/admin/jobs/update/${id}`);
  };

  const handleUpdateStatusClick = async (record) => {
    setIsModalVisible(true);
    setShowUpdateStatus(record.status);
    setRecordData(record);
  };

  const handleChange = (value) => {
    setShowUpdateStatus(value);
  };
  const hideModal = () => {
    setIsModalVisible(false);
  };
  const handleUpdateJobStatus = () => {
    updateStatusById(recordData, showUpdateStatus);
    hideModal(false);
    setShowUpdateStatus(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  // Drawer functions ----------------------------------------------------
  const showDrawer = (record) => {
    setOpenDrawerDetails(record);
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const JobsDrawerContent = () => {
    const {
      employer,
      position,
      start_date,
      months,
      location,
      contact_info,
      description,
      requirement,
      job_fee,
      job_fee_note,
      attachment_url,
      status,
    } = openDrawerDetails || {};

    return (
      <>
        <Row>
          <Col span={24}>
            <DescriptionItem title='Employer Name' content={employer} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem title='Location' content={location} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Job Position'
              content={<CustomizedTable TableData={position} />}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem
              title='Contact Information'
              content={contact_info || 'N/A'}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='Job Status'
              content={
                {
                  in_review: 'In Review',
                  approved: 'Approved',
                  draft: 'Draft',
                  rejected: 'Rejected',
                  expired: 'Expired',
                }[status] || status
              }
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem
              title='Start Date'
              content={start_date ? formatDate(start_date) : 'N/A'}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem title='Months' content={months || 'N/A'} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={12}>
            <DescriptionItem
              title='Job Fees'
              content={job_fee ? formatCurrency(job_fee, 'USD') : 'N/A'}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='Job Fee Note'
              content={job_fee_note || 'N/A'}
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Job Description'
              content={<CustomizedTable TableData={description} />}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Job Requirement'
              content={<CustomizedTable TableData={requirement} />}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Job Attachment URL'
              content={
                attachment_url ? (
                  <a
                    href={addHttpsToUrl(attachment_url)}
                    target='_blank'
                    rel='noreferrer'>
                    {attachment_url}
                  </a>
                ) : (
                  'N/A'
                )
              }
            />
          </Col>
        </Row>
        <Divider />
      </>
    );
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '1%',
      sorter: true,
    },
    {
      title: 'Employer Name',
      dataIndex: 'employer',
      width: '3%',
      sorter: true,
      render: (text) => {
        return (
          <>
            <div className='fixed-size-text'>
              <CommonTableColumnTextView columnText={text} isToolTip={true} />
            </div>
          </>
        );
      },
    },
    {
      title: 'Position',
      dataIndex: 'position',
      width: '3%',
      sorter: true,
      render: (text) => {
        return (
          <>
            <div className='fixed-size-text'>
              <Popover
                prefixCls='extend-popover'
                content={<CustomizedTable TableData={text} />}
                trigger='hover'
                title='Position'
                arrow={false}>
                <Button type='text' prefixCls='common-button-popover'>
                  <EyeOutlined /> View Position
                </Button>
              </Popover>{' '}
            </div>
          </>
        );
      },
    },
    {
      title: 'Job Status',
      dataIndex: 'status',
      width: '2%',
      fixed: 'right',
      render: (status) => {
        let color;
        let labal;
        switch (status) {
          case 'approved':
            labal = 'Approved';
            color = 'green';
            break;
          case 'rejected':
            labal = 'Rejected';
            color = 'red';
            break;
          case 'in_review':
            labal = 'In Review';
            color = 'blue';
            break;
          case 'expired':
            labal = 'Expired';
            color = 'error';
            break;
          default:
            labal = 'Draft';
            color = 'purple';
            break;
        }
        return <span style={{color: `${color}`}}>{labal}</span>;
      },
    },
    {
      title: '',
      key: 'action',
      width: '1%',
      fixed: 'right',
      render: (_, record) => (
        <AdminActionsDropdown
          editBool={true}
          deleteBool={true}
          statusBool={true}
          viewBool={true}
          handleView={showDrawer}
          record={record}
          handleEditClick={handleEditClick}
          handleDeleteClick={deleteJobs}
          handleUpdateStatusClick={handleUpdateStatusClick}
          text='Job'
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });

  const formateAccountData = () => {
    let newAccountData = [];
    const preprocessHTML = (html) => {
      // Remove line breaks and replace commas with a placeholder
      return html.replace(/(\r\n|\n|\r)/gm, '').replace(/,/g, '&#44;');
    };
    if (allJobsWithoutPaginationData?.length > 0)
      for (const job of allJobsWithoutPaginationData) {
        let newJobs = {
          id: job.id,
          user_id: job?.user_id,
          employer: job.employer,
          position: preprocessHTML(job?.position),
          start_date: job.start_date
            ? dayjs(job?.start_date).format('YYYY/MM/DD')
            : 'NA',
          months: job?.months || 'NA',
          job_fee: formatCurrency(job.job_fee),
        };
        newAccountData.push(newJobs);
      }

    return newAccountData;
  };

  const headers = [
    {label: 'ID', key: 'id'},
    {label: 'UserID', key: 'user_id'},
    {label: 'Employer', key: 'employer'},
    {label: 'Position', key: 'position'},
    {label: 'Start_Date', key: 'start_date'},
    {label: 'Months', key: 'months'},
    {label: 'Job_Fee', key: 'job_fee'},
  ];

  const formattedJobsAccountData = formateAccountData();

  // Add total fees row
  const totalFeesRow = {
    id: '',
    employer: '',
    position: '',
    user_id: '',
    start_date: '',
    months: '',
    job_fee: formatCurrency(totalJobFees),
  };

  const dataWithTotalFees = [...formattedJobsAccountData, totalFeesRow];

  // debouncing the table & pagination changes to stop too many requests
  const debouncedHandlePaginationChange = useDebouncedCallback(
    handlePaginationChange,
    1000,
  );
  const debouncedTableChange = useDebouncedCallback(handleTableChange, 1000);

  return (
    <div className='main-container'>
      <AdminViewDetailsDrawer
        content={JobsDrawerContent}
        visible={open}
        text={'Job'}
        onCloseDrawer={onCloseDrawer}
        editNavigate={`/admin/jobs/update/${openDrawerDetails?.id}`}
      />
      <StatusUpdateModal
        data={recordData}
        visible={isModalVisible}
        onCancel={hideModal}
        showUpdateStatus={
          showUpdateStatus === 'expired' ? 'Expired' : showUpdateStatus
        }
        handleChange={handleChange}
        handleUpdateJobStatus={handleUpdateJobStatus}
        selectionOptions={AdsAndJobsOptionsForAdmin?.filter(
          (obj) => obj.label !== 'Expired',
        )}
      />
      <div className='search-container' style={{gap: '10px'}}>
        <Button onClick={toggleExpand} id='Jobs-button-filterSearch'>
          {expanded ? <FilterOutlined /> : <FilterOutlined />}
          {ApplicationString['dynamicSearch.button.heading']}
        </Button>
        {jobsFetchingWithoutPagination && !allJobsWithoutPaginationData && (
          <Button>Fetching jobs..</Button>
        )}
        {!jobsFetchingWithoutPagination &&
          !allJobsWithoutPaginationData?.length && (
            <Button
              onClick={() => {
                fetchJobsWithoutPagination(searchValues);
              }}>
              Load CSV Data
            </Button>
          )}
        {!jobsFetchingWithoutPagination &&
          allJobsWithoutPaginationData?.length > 0 && (
            <Button id='Jobs-button-downloadCsv'>
              <CSVLink
                data={dataWithTotalFees}
                header={headers}
                filename={'Jobs_Report.csv'}>
                Download CSV
              </CSVLink>
            </Button>
          )}
        <Button
          type='primary'
          onClick={() => navigate('/admin/jobs/add')}
          id='JobsComponent-button-addJobs'
          className='common-button'>
          <div className='icon-container'>
            <img src={plus} alt='plus' className='icon-plus' />
            <span className='add-content'>
              {ApplicationString['jobsComponent.button.addForm']}
            </span>
          </div>
        </Button>
      </div>
      {expanded && (
        <DynamicSearchForm
          columns={jobsFilters}
          onSearch={handleSearch}
          savedFilteredValues={searchValues}
          title={'jobs'}
        />
      )}
      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          columns={columns}
          dataSource={allJobsData}
          rowKey={'id'}
          onChange={debouncedTableChange}
          scroll={{x: columns.length * 180}}
          pagination={{
            onChange: debouncedHandlePaginationChange,
            current: paging.page,
            total: paging.totalCount,
            pageSize: ps || paging.pageCount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default JobsComponent;

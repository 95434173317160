import React, {useEffect, useState} from 'react';
import UserAdsComponent from '../../../Components/UserComponents/Ads';
import {useDispatch, useSelector} from 'react-redux';
import {
  DefaultListingPageCount,
  createSelectionOptionsUtil,
  getLocalStorageUserInfo,
} from '../../../Util/commonUtility';
import {fetchAdsByUserId, getAllAdFees} from '../../../Store/Actions';

const UserAdsContainer = () => {
  let dispatch = useDispatch();
  const {getAdsDataByUser} = useSelector(({ads}) => ads);
  const [allAdsData, setAllAdsData] = useState([]);
  const [page, setPage] = useState(
    DefaultListingPageCount?.adsListingPageCount,
  );
  const {allAdFees} = useSelector(({adFees}) => adFees);
  const [adFeesOptions, setAdFeesOptions] = useState([]);
  const [zeroFeeData, setZeroFeeData] = useState([]);
  const [fetchNewData, setFetchNewData] = useState(false);

  useEffect(() => {
    if (getAdsDataByUser) {
      setAllAdsData(getAdsDataByUser?.data?.ads);
    } else {
      const userInfo = getLocalStorageUserInfo();
      const userId = userInfo ? userInfo?.id : '';
      setFetchNewData(true);
    }
  }, [getAdsDataByUser]);

  useEffect(() => {
    if (allAdFees) {
      setAdFeesOptions(
        createSelectionOptionsUtil(
          allAdFees?.data?.adFees,
          'id',
          'details',
          'fee',
        ),
      );
      setZeroFeeData(allAdFees?.data?.adFees?.filter((obj) => obj?.fee === 0));
    } else {
      dispatch(
        getAllAdFees(
          {
            page: 1,
            pagecount: 10,
          },
          '',
          '',
          '',
        ),
      );
    }
  }, [allAdFees]);
  return (
    <div>
      <UserAdsComponent
        allAdsData={allAdsData}
        setAllAdsData={setAllAdsData}
        page={page}
        setPage={setPage}
        adFeesOptions={adFeesOptions}
        zeroFeeData={zeroFeeData}
        fetchNewData={fetchNewData}
        setFetchNewData={setFetchNewData}
      />
    </div>
  );
};

export default UserAdsContainer;

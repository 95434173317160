import React, {useEffect, useState} from 'react';
import {Alert, Col, Row} from 'antd';
import {useParams} from 'react-router-dom';
import SponsorListing from '../../CommonComponents/SponsorListing';
import SponsorPerksListing from '../../CommonComponents/SponsorListing/SponsorPerksListing';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import CustomCollapse from '../../CommonComponents/CommonAccordian';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import {ApplicationString} from '../../../Localization/Locales/en';
import NavigationComponent from '../../CommonComponents/CommonBackButton';
import './style.css';

const SponsorshipOptionComponent = ({
  allEventOptionsRegistrationData,
  getEventSponsorOptions,
  removeSponsorShipFromCart,
}) => {
  const {id} = useParams();
  const [sponsorshipOption, setSponsorOptionData] = useState([]);

  useEffect(() => {
    if (allEventOptionsRegistrationData) {
      const filterByQty = allEventOptionsRegistrationData?.filter(
        (data) => data?.qty - data?.used_qty > 0,
      );
      setSponsorOptionData(filterByQty);
    } else {
      getEventSponsorOptions(id);
    }
  }, [allEventOptionsRegistrationData]);

  const removeSponsorShipFromCartByEventId = () => {
    removeSponsorShipFromCart(id);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        <Col span={24}>
          <SponsorOptionPageHeroText
            title={ApplicationString['userSponsorshipOptionComponent.heading']}
            subTitle={
              ApplicationString['userSponsorshipOptionComponent.subheading']
            }
          />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <NavigationComponent
              name={'Go Back to Attendee Registration'}
              urlPath={`addAttendees/${id}`}
            />
          </SectionLayout>
        </Col>
        <Col span={24}>
          {sponsorshipOption?.length > 0 ? (
            <SponsorPerksListing
              sponsorPerksData={sponsorshipOption}
              removeSponsorShipFromCartByEventId={
                removeSponsorShipFromCartByEventId
              }
            />
          ) : (
            <SectionLayout>
              <Alert
                message={
                  ApplicationString['userSponsorshipOption.alertMessage']
                }
                className='no-event-sponsor-alert'
                type='info'
                showIcon
              />
            </SectionLayout>
          )}
        </Col>
        <Col span={24}>
          <SectionLayout>
            <CustomCollapse />
          </SectionLayout>
        </Col>
      </Row>
    </>
  );
};

export default SponsorshipOptionComponent;

import React, {useState} from 'react';
import {applicationConfig} from '../../../Util/config';
import './style.css';

const ChapterBannerComponent = ({chaptersList, pageHeadingComponent}) => {
  let chapterUrlBanner = pageHeadingComponent;
  const currentPath = window.location.pathname;
  const splitted = currentPath.split('/');
  const splittedChapter = splitted[splitted?.length - 1];

  const visibleChapters = chaptersList?.filter(
    (chapter) => chapter?.is_visible_on_map === true,
  );

  if (visibleChapters?.find((obj) => obj.normalized_name === splittedChapter)) {
    const filteredChapterUrl = visibleChapters?.find(
      (chapter) => chapter?.normalized_name === splittedChapter,
    );
    const bannerName = filteredChapterUrl?.banner_ids[0]?.name;
    const bannerUrl = `${applicationConfig.UPLOAD_URL}/${bannerName}`;
    chapterUrlBanner = bannerName ? bannerUrl : pageHeadingComponent;
  } else {
    chapterUrlBanner = pageHeadingComponent;
  }

  return (
    <React.Fragment>
      {!chapterUrlBanner?.props?.heading ? (
        <img
          className='header-chapter-banner'
          src={chapterUrlBanner}
          crossOrigin='anonymous'
          alt='banner'
          onClick={() => {
            navigate('/');
          }}
        />
      ) : (
        pageHeadingComponent
      )}
    </React.Fragment>
  );
};

export default ChapterBannerComponent;

import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_REFUND_BY_EVENTID,
  REFUND_UPDATE_SUCCESS,
  SHOW_MESSAGE,
  SOMETHING_WENT_WRONG,
  UPDATE_REFUND_STATUS,
} from '../../Constant/ActionTypes';
import {commonSortOrder, handleJwtExpireMsg} from '../../Util/commonUtility';
import Urls from '../../Constant/Urls';
import {httpClient} from '../../Api/client';
import {setEventNameState} from './chapters';

export const getRefundByEventId = (
  pagination,
  sortField,
  sortOrder,
  searchQueryData,
  id,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.GET_REFUND_BY_EVENTID.replace('#{eventId}', id)}?page=${
          pagination.page
        }&pageCount=${pagination.pageCount}&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${searchQueryData}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_REFUND_BY_EVENTID, payload: data?.data});
        dispatch(setEventNameState(data?.data?.data?.event_name));
        return data?.data;
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const editRefundStatus = (Data) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.post((Urls.GET_REFUND_BY_EVENTID, Data));
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: UPDATE_REFUND_STATUS, payload: data?.data});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

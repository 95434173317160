import React, {useState} from 'react';
import {Divider, Drawer, List, Tooltip, Button, Space} from 'antd';
import {EyeOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';

const AdminViewDetailsDrawer = ({
  visible,
  content,
  onCloseDrawer,
  text,
  editNavigate,
  isEditable = true,
}) => {
  let navigate = useNavigate();
  const handleEditClick = () => {
    if (editNavigate) {
      navigate(editNavigate);
    }
  };
  return (
    <div>
      <Drawer
        width={550}
        placement='right'
        closable={false}
        title={`${text} Details`}
        onClose={onCloseDrawer}
        open={visible}
        prefixCls='view-details-drawer'
        extra={
          <Space>
            <Button className='view-button-cancel' onClick={onCloseDrawer}>
              Cancel
            </Button>
            {isEditable && (
              <Button
                className='view-button-edit'
                onClick={handleEditClick}
                type='primary'>
                Edit {text}
              </Button>
            )}
          </Space>
        }>
        {content()}
      </Drawer>
    </div>
  );
};

export default AdminViewDetailsDrawer;

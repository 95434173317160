import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Tag,
  Tooltip,
  theme,
} from 'antd';
import './index.css';
import ReCAPTCHA from 'react-google-recaptcha';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {getAllKeywordsByQuerySearch, signUpUser} from '../../../Store/Actions';
import {applicationConfig} from '../../../Util/config';
import {
  maxTagPlaceholder,
  tagRender,
  validateEmailInput,
  validatePhoneNumber,
  queryString,
  OnlyAlphabetInputValidator,
  NoEmptySpaceInput,
  UserPasswordValidator,
  validateCompanyName,
  OnlyAlphabetInputValidatorWithSpecialCharacters,
} from '../../../Util/commonUtility';

const {Option} = Select;

const RegisterForm = ({
  chaptersList,
  fetching,
  setFetching,
  SetKeywordsOptions,
  keywordsOptions,
  chaptersListForAnnouncements,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [registerForm] = Form.useForm();
  const [selectedLocalChapter, setSelectedLocalChapter] = useState(null);
  const chapterDataArray = chaptersList?.map((chapter) => chapter?.value);
  const keywordDataArray = keywordsOptions?.map((keyword) => keyword?.id);
  const [chapterWithoutLocalForMail, setchapterWithoutLocalForMail] =
    useState();
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [checkboxState, setCheckboxState] = useState([]);
  const [checkboxKeywordsState, setCheckboxKeywordsState] = useState([]);
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const passwordValue = registerForm.getFieldValue('password');
  const onRegister = (values) => {
    const Data = {
      ...values,
      phone_number: values?.phone_number ? values?.phone_number : null,
      mail_preference: values?.mail_preference || [],
      area_of_interest: tags?.toString(),
      user_keywords: selectedKeywords,
    };
    dispatch(signUpUser(Data, navigate));
  };
  const onReset = () => {
    registerForm.resetFields();
    registerForm.setFieldsValue({
      user_keywords: [],
    });
    setSelectedKeywords([]);
    setTags([]);
  };
  const onRegisterFailed = (values) => {
    console.error(values, 'values');
  };

  // const handleSelectKeywordChange = (value) => {
  //   const selectAllData = keywordsOptions?.filter((data) => data?.value !== 'all');
  //   if (value.includes('all')) {
  //     registerForm.setFieldsValue({
  //       user_keywords: selectAllData,
  //     });
  //   }
  // };

  // const handleSelectMailChange = (value) => {
  //   if (value.includes('all')) {
  //     registerForm.setFieldsValue({
  //       mail_preference: chapterDataArray,
  //     });
  //     setchapterWithoutLocalForMail(chapterDataArray);
  //   } else {
  //     registerForm.setFieldsValue({
  //       mail_preference: value,
  //     });
  //     setchapterWithoutLocalForMail(value);
  //   }
  // };

  const handleSelectMailChange = (value) => {
    const selectAllData = chapterDataArray?.map((item) => item?.value);
    if (value.includes('all')) {
      setCheckboxState([...selectAllData, 'all']);
      registerForm.setFieldsValue({
        mail_preference: chapterDataArray,
      });
      setchapterWithoutLocalForMail(chapterDataArray);
    } else {
      setCheckboxState(value);
      setchapterWithoutLocalForMail(value);
    }
  };

  const handleMailPrefCheckboxChange = (value, checked) => {
    const selectedValues = chaptersList
      ?.filter((item) => item?.value !== 'all')
      ?.map((item) => item?.value);
    if (checked) {
      if (value == 'all') {
        setCheckboxState([...selectedValues, 'all']);
      } else {
        setCheckboxState([...checkboxState, value]);
      }
    } else {
      if (value == 'all') {
        setCheckboxState([]);
        registerForm.setFieldsValue({
          mail_preference: [],
        });
      } else {
        setCheckboxState(checkboxState.filter((item) => item !== value));
      }
    }
  };
  const handleKeywordCheckboxChange = (value, checked) => {
    if (checked) {
      setCheckboxKeywordsState([...checkboxKeywordsState, value]);
    } else {
      setCheckboxKeywordsState(
        checkboxKeywordsState.filter((item) => item !== value),
      );
    }
  };
  const handleLocalChapterChange = (value) => {
    setSelectedLocalChapter(value);
  };

  const handleKeywordChange = (value) => {
    setSelectedKeywords(value);
    setCheckboxKeywordsState(value);
  };

  const handleKeywordSearch = (value) => {
    SetKeywordsOptions([]);
    setFetching(true);
    const Data = {
      name: value,
    };
    const searchQueryData = queryString(Data);
    dispatch(
      getAllKeywordsByQuerySearch('', '', searchQueryData, {
        page: 1,
        pageCount: 10,
      }),
    );
  };

  const handleKeywordAllowClear = () => {
    dispatch(
      getAllKeywordsByQuerySearch('', '', '', {
        page: 1,
        pageCount: 10,
      }),
    );
  };

  const handleKeywordMouseLeave = () => {
    if (keywordsOptions?.length === 0) {
      dispatch(
        getAllKeywordsByQuerySearch('', '', '', {
          page: 1,
          pageCount: 10,
        }),
      );
    }
  };

  // const handleClose = () => {
  //   setTags([]);
  // };

  // const showInput = () => {
  //   setInputVisible(true);
  // };
  // const closeInput = () => {
  //   setInputVisible(false);
  // };
  // const handleInputChange = (e) => {
  //   setInputValue(e.target.value);
  // };

  // const handleInputConfirm = () => {
  //   if (inputValue) {
  //     const newTags = [...tags, inputValue];
  //     setTags(newTags);
  //   }
  //   setInputVisible(false);
  //   setInputValue('');
  // };

  return (
    <Form
      // {...formItemLayout}
      layout='vertical'
      form={registerForm}
      name='registerForm'
      onFinish={onRegister}
      onFinishFailed={onRegisterFailed}
      initialValues={''}
      className='register-form'
      scrollToFirstError>
      <Row gutter={14}>
        <Col xs={24} sm={12}>
          <Form.Item
            name='first_name'
            rules={[
              {required: true, message: 'Please fill out your first name!'},
              {
                validator: (_, value) =>
                  OnlyAlphabetInputValidatorWithSpecialCharacters(
                    value,
                    'First name',
                  ),
              },
            ]}>
            <div className='register-form-input-container'>
              <Input
                prefix={'*'}
                placeholder={'First name'}
                className='register-form-input'
              />
            </div>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name='last_name'
            rules={[
              {
                required: true,
                message: 'Please fill out your last name!',
              },
              {
                validator: (_, value) =>
                  OnlyAlphabetInputValidatorWithSpecialCharacters(
                    value,
                    'Last name',
                  ),
              },
            ]}>
            <div className='register-form-input-container'>
              <Input
                prefix={`*`}
                placeholder={'Last name'}
                className='register-form-input'
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col xs={24} sm={12}>
          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                message: 'Please fill out your email!',
              },
              {
                validator: (_, value) => validateEmailInput(value, 'Email'),
              },
            ]}>
            <div className='register-form-input-container'>
              <Input
                prefix={'*'}
                placeholder={'Email'}
                className='register-form-input'
              />
            </div>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name='confirm_email'
            dependencies={['email']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your email!',
              },
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (!value || getFieldValue('email') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Email that you entered do not match'),
                  );
                },
              }),
            ]}>
            <div className='register-form-input-container'>
              <Input
                prefix={'*'}
                placeholder={'Confirm Email'}
                className='register-form-input'
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col xs={24} sm={12}>
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: 'Please fill out password!',
              },
              {
                validator: UserPasswordValidator,
              },
            ]}
            hasFeedback>
            <div className='register-form-input-container'>
              <Input.Password
                prefix={<span className={`ant-input-prefix`}>*</span>}
                placeholder={'Password'}
                className='common-input-register'
              />
            </div>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name='confirm'
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The new password that you entered do not match'),
                  );
                },
              }),
            ]}>
            <div className='register-form-input-container'>
              <Input.Password
                prefix={'*'}
                placeholder={'Confirm password'}
                className='common-input-register'
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col xs={24} sm={12}>
          <Form.Item
            name='address'
            rules={[
              {
                required: false,
                message: 'Please fill out your address!',
              },
              {
                validator: (_, value) => NoEmptySpaceInput(value, 'Address'),
              },
            ]}>
            <div className='register-form-input-container'>
              <Input
                prefix={` `}
                placeholder={'Address'}
                className='register-form-input'
              />
            </div>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name='city'
            rules={[
              {
                required: true,
                message: 'Please fill out your city!',
              },
              {
                validator: (_, value) =>
                  OnlyAlphabetInputValidator(value, 'City'),
              },
            ]}>
            <div className='register-form-input-container'>
              <Input
                prefix={'*'}
                placeholder={'City'}
                className='register-form-input'
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col xs={24} sm={12}>
          <Form.Item
            name='state'
            rules={[
              {
                required: true,
                message: 'Please fill out your state!',
              },
              {
                validator: (_, value) =>
                  OnlyAlphabetInputValidator(value, 'State'),
              },
            ]}>
            <div className='register-form-input-container'>
              <Input
                prefix={'*'}
                placeholder={'State'}
                className='register-form-input'
              />
            </div>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name='postal_code'
            rules={[
              {
                required: true,
                message: 'Please fill out your Zip code!',
              },
              {
                validator: (_, value) => NoEmptySpaceInput(value, 'Zip code'),
              },
            ]}>
            <div className='register-form-input-container'>
              <Input
                prefix={'*'}
                placeholder={'Zip code'}
                className='register-form-input'
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col xs={24} sm={12}>
          <Form.Item
            name='country'
            rules={[
              {required: true, message: 'Please fill out your country!'},
              {
                validator: (_, value) =>
                  OnlyAlphabetInputValidator(value, 'Country'),
              },
            ]}>
            <div className='register-form-input-container'>
              <Input
                prefix={'*'}
                placeholder={'Country'}
                className='register-form-input'
              />
            </div>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name='company'
            rules={[
              {
                required: true,
                message: 'If you are in between jobs, please enter N/A!',
              },
              {
                validator: (_, value) =>
                  validateCompanyName(value, 'Company name'),
              },
            ]}>
            <div className='register-form-input-container'>
              <Input
                prefix={`*`}
                placeholder={'Company Name/Affiliation'}
                className='register-form-input'
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col xs={24} sm={24}>
          <Form.Item
            name='phone_number'
            rules={[
              {
                validator: (_, value) =>
                  validatePhoneNumber(value, 'Contact number'),
              },
            ]}>
            <div className='register-form-input-container'>
              <Input
                prefix={` `}
                placeholder={'Contact number'}
                className='register-form-input'
              />
            </div>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={24}>
          <Form.Item
            name='local_chapter_id'
            label={
              <div>
                <span className='ant-input-prefix'>* </span>Select Local Chapter
              </div>
            }
            rules={[{required: true, message: 'Please select local chapter!'}]}>
            <Select
              prefixCls='register-select-local-chapter'
              showSearch
              className='space register-form-selection local-select'
              // optionFilterProp='children'
              allowClear
              optionFilterProp='children'
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
              onChange={handleLocalChapterChange}
              placeholder={'Select local chapter'}
              options={chaptersList}></Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={24}>
          <Form.Item
            name='mail_preference'
            initialValue={chapterWithoutLocalForMail}
            label={
              <div className='register-select-label'>
                <span className='ant-input-prefix'></span>Select a PBSS
                Chapter(s) to receive its announcements
              </div>
            }>
            <Select
              id='basic-selection-chapters'
              prefixCls='register-select-input'
              className='space register-form-selection'
              mode='multiple'
              showSearch
              allowClear
              // optionFilterProp='children'
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
              placeholder={'Select chapter(s)'}
              onChange={handleSelectMailChange}
              maxTagCount={'responsive'}
              maxTagPlaceholder={maxTagPlaceholder}
              tagRender={tagRender}
              value={registerForm.getFieldValue('mail_preference')}
              options={
                (chaptersListForAnnouncements &&
                  chaptersListForAnnouncements?.map((item) => ({
                    label: (
                      <Checkbox
                        checked={checkboxState.includes(item.value)}
                        onChange={(e) =>
                          handleMailPrefCheckboxChange(
                            item.value,
                            e.target.checked,
                          )
                        }>
                        {item.label}
                      </Checkbox>
                    ),
                    value: item.value,
                  }))) ||
                []
              }></Select>
            {/* <TagGroup
              onClearAll={handleClearAll}
              onSelectAll={handleSelectAll}
              usedForChap={true}
            /> */}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={24}>
          <Form.Item
            label={
              <div className='register-select-label'>
                <span className='ant-input-prefix'>* </span>Select Area(s) of
                Interest
              </div>
            }
            name='user_keywords'
            rules={[
              {
                required: true,
                message: 'Please select atleast one area of interest!',
              },
            ]}>
            <Select
              prefixCls='register-select-input'
              className='space register-form-selection'
              mode='multiple'
              showSearch
              allowClear
              // optionFilterProp='children'
              filterOption={(input, option) =>
                option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
              onChange={handleKeywordChange}
              onSearch={handleKeywordSearch}
              notFoundContent={
                fetching ? (
                  <Spin size='small' />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
              }
              maxTagCount={'responsive'}
              onClear={handleKeywordAllowClear}
              onMouseLeave={handleKeywordMouseLeave}
              maxTagPlaceholder={maxTagPlaceholder}
              tagRender={tagRender}
              placeholder={'Select area(s) of interest'}
              options={keywordsOptions}></Select>
            {/* <div className='keyword-container'>
              <div className='keyword-label-container'>
                <div className='keyword-label'>Select Areas of Interest</div>
                <div className='keyword-buttons-container'>
                  <TagGroup
                    onClearAll={handleKeywordClearAll}
                    onSelectAll={handleKeywordSelectAll}
                  />
                </div>
              </div>

              <div className='keyword-render-container'>
                {keywordsOptions?.map((item, index) => {
                  return (
                    <div key={index} className='keyword-render'>
                      <div className='keyword-name'>{item?.label}</div>
                      <div>
                        <Checkbox
                          prefixCls='keyword-checkbox'
                          valuePropName='checked'
                          checked={
                            selectedKeywords.length > 0 &&
                            selectedKeywords.includes(item.id)
                          }
                          onChange={(e) =>
                            handleKeywordChange(e, item.id)
                          }></Checkbox>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div> */}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label={
              <div className='register-select-label'>Add Other Interests</div>
            }
            name='area_of_interest'
            rules={[
              {
                required: false,
                message: 'Please add other interests!',
              },
              {
                validator: (_, value) => {
                  const regex = /\s{4,}/g;
                  // added extra validation cause it was causing second error message from antd
                  if (value && value?.length <= 0) {
                    return Promise.reject(new Error(''));
                  }
                  if (value && value.trim() === '') {
                    return Promise.reject(
                      new Error('Area of interest cannot include space!'),
                    );
                  } else if (value && regex.test(value)) {
                    return Promise.reject(
                      new Error('Too many consecutive spaces!'),
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}>
            <div className='register-form-input-container'>
              <Input
                prefix={` `}
                placeholder={'Enter area of interest, separated by commas'}
                className='register-form-input'
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Row
          gutter={{
            xs: 4,
            sm: 4,
            md: 8,
            lg: 20,
          }}>
          <Col xs={24} sm={24} md={24} lg={10}>
            <Form.Item name='is_vendor' className='vendor-check'>
              <Checkbox
                prefixCls='keyword-checkbox'
                className='gap'
                valuePropName='checked'>
                <span className='register-select-label'>
                  I am a Vendor Representative
                </span>
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={16}>
            <Form.Item
              className='vendor-check'
              label='Complete Captcha'
              name='captcha'
              rules={[
                {
                  required: true,
                  message: 'Please click on the CAPTCHA above',
                },
              ]}>
              <ReCAPTCHA
                sitekey={applicationConfig.CAPTCHA_SITE_KEY}></ReCAPTCHA>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      {/* <Form.Item
        name='agreement'
        valuePropName='checked'
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error('Should accept agreement')),
          },
        ]}>
        <Checkbox>
          I have read the <a href=''>agreement</a>
        </Checkbox>
      </Form.Item> */}
      <Divider />
      <Row gutter={40} className='register-buttons'>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Form.Item>
            <Button
              prefixCls='register-submit'
              id='register-register-button'
              type='primary'
              className='register-button'
              htmlType='submit'>
              Register
            </Button>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Form.Item>
            <Button
              prefixCls='register-cancel'
              id='register-cancel-button'
              className='register-button'
              onClick={onReset}>
              Cancel
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default RegisterForm;

import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL_ADS,
  SOMETHING_WENT_WRONG,
  ADD_AD_SUCCESS,
  SHOW_MESSAGE,
  DELETE_AD_BY_ID,
  FETCH_AD_BY_ID,
  UPDATING_CONTENT,
  UPDATE_AD_STATUS_BY_ID,
  FETCH_ADS_BY_USERID,
  ADD_AD,
  GET_ALL_ADS_APPROVED,
  UPDATE_AD_BY_ID,
  GET_ALL_ACCOUNTS,
  POST_ADS_PAYMENT,
  POST_ADS_PAYMENT_SUCCESS,
  RESET_PAYMENT,
  RESET_ADS_PAYMENT,
  GET_ALL_ADS_WITHOUT_PAGINATION,
  UPDATE_ADS_SUCCESS,
  POST_ADS_SUCCESS,
  UPDATE_ADS_STATUS_SUCCESS,
  DELETE_ADS_SUCCESS,
} from '../../Constant/ActionTypes';

import Urls from '../../Constant/Urls';
import {httpClient} from '../../Api/client';
import {
  DefaultListingViewMoreCount,
  commonSortOrder,
  handleJwtExpireMsg,
} from '../../Util/commonUtility';

export const getAllAds = (sortField, sortOrder, queryString, pagination) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.AD_GET_ALL}?page=${pagination.page}&pageCount=${
          pagination.pageCount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${queryString}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_ADS, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllAdsWithoutPagination = (
  sortField,
  sortOrder,
  queryString,
  totalAdsCount,
  setAdsFetchingWithoutPagination,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    setAdsFetchingWithoutPagination && setAdsFetchingWithoutPagination(true);
    try {
      let data = await httpClient.get(
        `${
          Urls.AD_GET_ALL
        }?page=1&pageCount=${totalAdsCount}&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${queryString}`,
      );
      if (data.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_ADS_WITHOUT_PAGINATION, payload: data.data});
        setAdsFetchingWithoutPagination &&
          setAdsFetchingWithoutPagination(false);
      }
    } catch (error) {
      let errorMessage = '';
      setAdsFetchingWithoutPagination && setAdsFetchingWithoutPagination(false);
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const resetAdsCsvDownloadState = () => {
  return {
    type: GET_ALL_ADS_WITHOUT_PAGINATION,
    payload: [],
  };
};

export const getAllApprovedAds = () => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(Urls.ADS_GET_ALL_APPROVED);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_ADS_APPROVED, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllAccountData = (date) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.ADS_GET_ALL_ACCOUNTS}?date=${date}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_ACCOUNTS, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addAds = (
  adData,
  form,
  Navigate,
  setIsModalOpen,
  setValidFileList,
  isZeroFee,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.post(Urls.AD_ADD, adData);
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: ADD_AD, payload: response.data});
        form && form.resetFields();
        setValidFileList && setValidFileList([]);
        if (setIsModalOpen) {
          setIsModalOpen(false);
          if (isZeroFee) {
            Navigate(`/paymentSuccess/ads`);
          } else {
            Navigate(`cart/${response?.data?.data.id}`);
          }
        } else {
          Navigate('/admin/ads');
        }
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAdsById = (adId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.get(
        Urls.AD_FETCH_BY_ID.replace('#{adsId}', adId),
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_AD_BY_ID, payload: response?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const fetchAdsByUserId = (
  userId,
  searchVendor,
  filterStatus,
  setAllAdsData,
  page = DefaultListingViewMoreCount?.adsListingViewMoreCount,
  setTotalItems,
  year,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.ADS_GET_BY_USERID.replace(
          '#{userId}',
          userId,
        )}?sortField=id&sortOrder=desc&vendor=${
          searchVendor ? searchVendor : ''
        }&status=${
          filterStatus ? filterStatus : ''
        }&page=1&pageCount=${page}&year=${year}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_ADS_BY_USERID, payload: data?.data});
        setAllAdsData(data?.data);
        setTotalItems && setTotalItems(data?.data?.data?.totalCount);
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const updateAdById = (
  updatedAdData,
  adId,
  form,
  setEditingKey,
  navigate,
  setIsModalOpen,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.patch(
        Urls.AD_UPDATE_BY_ID.replace('#{adsId}', adId),
        updatedAdData,
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: UPDATE_AD_BY_ID, payload: response?.data});
        form && form.resetFields();
        setIsModalOpen && setIsModalOpen(false);
        navigate && navigate('/admin/ads');
        setEditingKey && setEditingKey('');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const updateAdByIdAdmin = (updatedAdData, adId, form, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.patch(
        Urls.AD_UPDATE_BY_ID.replace('#{adsId}', adId),
        updatedAdData,
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: UPDATE_AD_BY_ID, payload: response?.data});
        form && form.resetFields();
        navigate && navigate('/admin/ads');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const editAdStatusById = (id, status) => {
  return async (dispatch) => {
    dispatch({type: UPDATING_CONTENT});
    try {
      let data = await httpClient.patch(
        `${Urls.AD_UPDATE_STATUS_BY_ID.replace('#{adsId}', id).replace(
          '#{adsStatus}',
          status,
        )}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: data?.data?.message,
        });
        dispatch({type: UPDATE_AD_STATUS_BY_ID});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const deleteAdById = (adId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.delete(
        Urls.AD_DELETE_BY_ID.replace('#{adsId}', adId),
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: DELETE_AD_BY_ID, payload: adId});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const postAdsPayment = (data, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let response = await httpClient.post(Urls.ADS_POST_PAYMENT, data);
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: POST_ADS_PAYMENT,
          payload: response?.data?.data,
        });
        if (response?.data?.data === null) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: response?.data?.message,
          });
          navigate && navigate('/paymentSuccess/ads');
        }
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const postAdsPaymentSuccess = (data) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let response = await httpClient.post(Urls.ADS_POST_PAYMENT_SUCCESS, data);
      if (response?.status === HttpCode.Ok) {
        dispatch({type: POST_ADS_PAYMENT_SUCCESS});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: response?.data?.message});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const resetAdPayment = () => {
  return (dispatch) => {
    dispatch({type: RESET_ADS_PAYMENT});
  };
};

import React from 'react';
import './style.css';
import {GoogleOutlined, InstagramOutlined} from '@ant-design/icons';
import {applicationConfig} from '../../../Util/config';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFacebook, faInstagram } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='content'>
        <p>
          {/* {applicationConfig.footerText} {new Date().getFullYear()} */}
          {applicationConfig.footerText}
        </p>
        {/* <div className='privacy-links'>
          <a id='footer-privacy-policy-navigation' href='mailto:info@myleservices.com'>Privacy Policy</a>
          <a id='footer-terms-of-service-navigation' href='mailto:info@myleservices.com'>Terms of Service</a>
          <a id='footer-cookies-settings-navigation' href='mailto:info@myleservices.com'>Cookies Settings</a>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;

import React, {useDebugValue, useState} from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import PageHeading from '../../CommonComponents/PageHeading';
import {
  Table,
  Space,
  Button,
  Form,
  Popconfirm,
  Tag,
  Skeleton,
  Col,
  Row,
  message,
} from 'antd';
import {
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  RightOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import EditableCell from '../../CommonComponents/EditableCell';
import './style.css';
import ConfirmationAction from '../../CommonComponents/PaymentConfirmationAction';
import {useNavigate, useParams} from 'react-router-dom';
import CommonAlert from '../../CommonComponents/CommonAlert';
import {useEffect} from 'react';
import {
  filterFoodOptionColumnsForAttendee,
  formValueChangeHandler,
  formatCurrency,
} from '../../../Util/commonUtility';
import AddAttendeeeComponent from '../../CommonComponents/AttendeeAddComponent';
import CustomCollapse from '../../CommonComponents/CommonAccordian';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import NavigationComponent from '../../CommonComponents/CommonBackButton';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import {ApplicationString} from '../../../Localization/Locales/en';
import {useThrottle} from '../../../hooks/useThrottle';
import {useDispatch} from 'react-redux';
import {FETCH_ERROR} from '../../../Constant/ActionTypes';
import CommonUserModal from './../../CommonComponents/CommonUserModal/index';

const AddAttendeeComponent = ({
  allEventOptionsFoodData,
  allEventOptionsRegistrationData,
  AddAttendee,
  eventType,
  getAttendeeByEventId,
  attendeesData,
  setAttendeesData,
  deleteAttendeeById,
  editAttendeeById,
  totalCartPrice,
  isSponsorAvailable,
  eventName,
  allAttendeesData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isAttendeeModalOpen, setIsAttendeeModalOpen] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState();
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  const [isAddAttendee, setIsAddAttendee] = useState(false);
  const [disableAttendeeClicks, setDisableAttendeeClicks] = useState(false);
  const [disablePaymentClicks, setDisablePaymentClicks] = useState(false);
  const [disableEditClicks, setDisableEditClicks] = useState(false);
  const [mealOptions, setMealOptions] = useState(true);
  const isEditing = (record) => record.id === editingKey;
  const [loading, setLoading] = useState(false);

  const edit = (record) => {
    const EditData = {
      ...record,
      attendee_email_confirm: record?.attendee_email
        ? record?.attendee_email
        : '',
      food_option:
        record?.food_option === ''
          ? []
          : record.event_registration_options?.food?.event_option_id ||
            'no-food',
      registration_option:
        record.event_registration_options?.registration?.event_option_id,
    };
    if (!isAddAttendee) {
      form.setFieldsValue({
        ...EditData,
      });
    }
    setEditingKey(record.id);
  };

  const {id} = useParams();

  const cancel = () => {
    if (isAddAttendee) {
      attendeesData.pop();
      setAttendeesData([...attendeesData]);
    }
    form.resetFields();
    setIsAddAttendee(false);
    setEditingKey('');
    setDisableAttendeeClicks(false);
    setDisablePaymentClicks(false);
    setDisableEditClicks(false);
  };
  const save = async (attendeeData) => {
    try {
      const row = await form.validateFields();
      const Data = {
        ...row,
        event_id: id && parseInt(id),
        food_option:
          row.food_option === 'no-food' ||
          eventType?.toLowerCase() === 'webcast'
            ? {}
            : {
                type: 'food',
                event_option_id: row.food_option,
                qty: 1,
              },
        registration_option: {
          type: 'registration',
          event_option_id: row.registration_option,
          qty: 1,
        },
      };

      if (isAddAttendee) {
        AddAttendee(
          Data,
          setEditingKey,
          form,
          setIsAddAttendee,
          setDisableAttendeeClicks,
          setDisablePaymentClicks,
          setDisableEditClicks,
        );
      } else {
        const EditData = {
          ...row,
          food_option:
            row.food_option === 'no-food' ||
            eventType?.toLowerCase() === 'webcast'
              ? {}
              : {
                  type: 'food',
                  event_option_id: row?.food_option,
                  qty: 1,
                  id: attendeeData?.event_registration_options?.food?.id,
                },
          registration_option: {
            type: 'registration',
            event_option_id: row?.registration_option,
            qty: 1,
            id: attendeeData?.event_registration_options?.registration?.id,
          },
        };
        editAttendeeById(
          EditData,
          attendeeData.id,
          setEditingKey,
          form,
          setDisableAttendeeClicks,
          setDisablePaymentClicks,
          setDisableEditClicks,
        );
      }
    } catch (errInfo) {
      console.error('Validation Failed:', errInfo);
    }
  };
  // const generateUniqueID = () => {
  //   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  //   let uniqueID = '';

  //   for (let i = 0; i < 8; i++) {
  //     const randomIndex = Math.floor(Math.random() * characters.length);
  //     uniqueID += characters.charAt(randomIndex);
  //   }

  //   return uniqueID;
  // };

  // const onExpand = (expanded, record) => {
  //   setOnExpandId(record.id);
  //   let keys = [];
  //   keys.push(record.key);
  //   setExpandedRowKeys(keys);
  // };

  // useEffect(() => {
  //   console.log(editingKey, 8);
  //   editingKey !== '1'
  //     ? setDisableAttendeeClicks(true)
  //     : setDisableAttendeeClicks(false);
  // }, [editingKey]);

  // useEffect(() => {
  //   const newRow = {
  //     id: '1',
  //     name: '',
  //     email: '',
  //     eventid: '',
  //     price: '',
  //     registration_option: '',
  //     food_option: '',
  //   };
  //   if (attendeesData && attendeesData?.length === 0) {
  //     setIsAddAttendee(true);
  //     edit(newRow);
  //     setAttendeesData([...attendeesData, newRow]);
  //   }
  // }, [attendeesData]);

  const addRow = () => {
    const newAttendee = {
      id: '',
      name: '',
      email: '',
      eventId: '',
      price: '',
      registration_option: '',
      food_option: '',
    };
    const existingAttendee = attendeesData.find((obj) =>
      Object.keys(newAttendee).every((key) => obj[key] === newAttendee[key]),
    );
    if (existingAttendee) {
      setDisableAttendeeClicks(true);
      return;
    } else {
      setIsAddAttendee(true);
      edit(newAttendee);
      setAttendeesData([...attendeesData, newAttendee]);
      setDisableEditClicks(true);
    }
  };

  const collapseRow = (key) => {
    const index = expandedRowKeys.indexOf(key);
    if (index > -1) {
      const newExpandedRowKeys = [
        ...expandedRowKeys.slice(0, index),
        ...expandedRowKeys.slice(index + 1),
      ];
      setExpandedRowKeys(newExpandedRowKeys);
    }
  };

  const handleConfirm = useThrottle(() => {
    if (allAttendeesData?.length <= 0 || disablePaymentClicks) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Kindly take action and save the attendee details first.',
      });
      return;
    }
    setIsAttendeeModalOpen(true);
  }, 2000);

  const handleCancel = () => {
    navigate(`/eventDetails/${id}`);
  };

  const onMealSelect = () => {
    setMealOptions(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (attendeesData && attendeesData?.length > 0)
      setDisableAttendeeClicks(false);
  }, [attendeesData]);

  useEffect(() => {
    const newRow = {
      id: '1',
      name: '',
      email: '',
      eventid: '',
      price: '',
      registration_option: '',
      food_option: '',
    };
    if (attendeesData && attendeesData?.length === 0) {
      setDisableAttendeeClicks(true);
      const loading = !attendeesData || attendeesData.length === 0;
      setLoading(loading);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setIsAddAttendee(true);
      edit(newRow);
      setAttendeesData([...attendeesData, newRow]);
    }
  }, [attendeesData]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      editable: true,
      width: '15%',
      render: (text, record) => {
        return (
          <Tag prefixCls='regularAttendeesTag'>
            {record.attendee_first_name} {record.attendee_last_name}
          </Tag>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'attendee_email',
      key: 'email',
      width: '18%',
      editable: true,
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },

    {
      title: 'Option',
      dataIndex: 'option',
      key: 'registration_and_food_option',
      width: '20%',
      editable: true,
      render: (_, record) => {
        const registrationName = record.registration_option?.name;
        const registrationPrice = record.registration_option?.price;
        const foodName = record.food_option?.name || 'No Food';
        const foodPrice = record.food_option?.price;
        return (
          <div className='option-tag-container'>
            <Tag prefixCls='regularAttendeesTag'>
              Registration: {registrationName} -{' '}
              {formatCurrency(registrationPrice, 'USD')}
            </Tag>
            {eventType !== 'Webcast' && (
              <Tag prefixCls='regularAttendeesTag'>
                Food: {foodName}{' '}
                {foodPrice ? '- ' + formatCurrency(foodPrice, 'USD') : ''}
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: 'Company/Affiliation',
      dataIndex: 'attendee_company',
      key: 'company',
      width: '15%',
      editable: true,
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },
    {
      title: 'Price',
      dataIndex: 'total_cost',
      width: '5%',
      render: (text) => {
        return (
          <h4 className='regularAttendees-price'>{formatCurrency(text)}</h4>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '6%',
      render: (_, record) => {
        const isEditingRecord = record.id === editingKey;
        return (
          <AdminActionsDropdown
            record={record}
            usedForAttendeeTable={true}
            editBool={true}
            deleteBool={true}
            attendeeEditingKey={editingKey}
            handleCancelClick={cancel}
            handleSaveClick={save}
            handleEditClick={edit}
            handleDeleteClick={() => deleteAttendeeById(record.id)}
            isAddAttendee={isAddAttendee}
            disableEditClicks={disableEditClicks}
            setDisableEditClicks={setDisableEditClicks}
            setDisableAttendeeClicks={setDisableAttendeeClicks}
            setDisablePaymentClicks={setDisablePaymentClicks}
          />
        );
      },
    },
  ];

  const mergedColumns = filterFoodOptionColumnsForAttendee(
    columns,
    eventType,
    isEditing,
  );
  const onConfirm = () => {
    navigate(`/attendeesCart/${id}`);
  };

  const handleConfirmModalCancel = () => {
    setIsAttendeeModalOpen(false);
  };

  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        <div className='stickyEventName'>
          <CommonUserModal
            visible={isAttendeeModalOpen}
            onCancel={handleConfirmModalCancel}
            onConfirm={onConfirm}
            confirmBtnText={'Agree & Continue'}
            content={
              <React.Fragment>
                <div className='payment-modal'>
                  The attendee details can be edited before 14 days of the event
                  start date. In case you wish to alter or modify the details in
                  the 14 days before the event start date, please reach out to
                  the admin at <a href='mailto:info@pbss.org'>info@pbss.org</a>.
                  Remember to reach out to admin 4 days before the event.
                </div>
              </React.Fragment>
            }
          />
          <SponsorOptionPageHeroText
            title={eventName || 'Attendee'}
            subTitle={'Attendee Registration'}
            extra={
              isSponsorAvailable ? (
                <Button
                  onClick={() => {
                    navigate(`/sponsorshipOption/${id}`);
                  }}
                  className='reg-navigation-btn'>
                  {
                    ApplicationString[
                      'userAddAttendeeComponent.registerAs.sponsorText'
                    ]
                  }
                </Button>
              ) : (
                false
              )
            }
          />
        </div>
        <Col span={24}>
          <SectionLayout>
            <NavigationComponent
              name={'Go Back to Event Details'}
              urlPath={`eventDetails/${id}`}
            />
          </SectionLayout>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <Form
              form={form}
              onValuesChange={(changedValues) =>
                formValueChangeHandler(form, changedValues)
              }>
              <Skeleton
                loading={loading}
                prefixCls='table-skeleton'
                active
                paragraph={{rows: attendeesData?.length}}>
                <div className='attendees-label'>
                  {
                    ApplicationString[
                      'userAddAttendeeComponent.regularAttendee.label'
                    ]
                  }
                </div>
                <Table
                  prefixCls='attendees-table-container'
                  showExpandColumn={false}
                  components={{
                    body: {
                      cell: (props) => (
                        <EditableCell
                          allEventOptionsFoodData={allEventOptionsFoodData}
                          allEventOptionsRegistrationData={
                            allEventOptionsRegistrationData
                          }
                          eventType={eventType}
                          attendeesData={attendeesData}
                          {...props}
                        />
                      ),
                    },
                  }}
                  pagination={false}
                  scroll={{x: columns?.length * 150}}
                  dataSource={attendeesData}
                  columns={mergedColumns}
                  // expandable={{
                  //   onExpand: (expanded, record) => {
                  //     return onExpand(expanded, record);
                  //   },
                  //   expandedRowKeys: expandedRowKeys,
                  //   expandedRowRender: (record, index, indent, expanded) => {
                  //     return (
                  //       <>
                  //         {expanded && (
                  //           <FoodOptionsExpandable
                  //             record={record}
                  //             updateRecord={updateRecord}
                  //             collapseRow={() => collapseRow(record.key)}
                  //           />
                  //         )}
                  //       </>
                  //     );
                  //   },
                  // }}
                  // footer={() => (
                  //   <Space className='add-attendee-container'>
                  //     <Button
                  //       id='add-attendee'
                  //       className={editingKey !== '' ? '' : 'add-attendee'}
                  //       type='default'
                  //       disabled={editingKey !== ''}
                  //       onClick={addRow}>
                  //       Add New Attendees <PlusOutlined />
                  //     </Button>
                  //   </Space>
                  // )}
                />
              </Skeleton>
            </Form>
          </SectionLayout>
        </Col>

        <Col span={24}>
          <SectionLayout extendClass='addAttendeeWrapperEventUserComponent'>
            <AddAttendeeeComponent
              disable={disableAttendeeClicks}
              addRow={addRow}
              setDisablePaymentClicks={setDisablePaymentClicks}
            />
          </SectionLayout>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <ConfirmationAction
              heading='Total amount to be paid'
              data={attendeesData}
              submitName={'Proceed to pay'}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
              totalCartPrice={totalCartPrice}
              usedForAddAttendees={true}
            />
          </SectionLayout>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <CustomCollapse />
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AddAttendeeComponent;

import React, {useEffect} from 'react';
import {
  Modal,
  Select,
  Button,
  Divider,
  Radio,
  Tooltip,
  Input,
  Popconfirm,
  Alert,
} from 'antd';
import {
  JobStatus,
  formatCurrencyByLocalChapter,
  maxTagPlaceholder,
} from '../../../Util/commonUtility';
import './style.css';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {useSelector, useDispatch} from 'react-redux';
import {getAllChapters} from './../../../Store/Actions/chapters';
function StatusUpdateModal({
  visible,
  onCancel,
  showUpdateStatus,
  handleChange,
  handleUpdateJobStatus,
  submitText,
  statusHeading,
  selectionOptions,
  tagRender,
  mode,
  setRefundToAccount,
  handleRadioChange,
  refundBoolForAdmin = false,
  isColorSchemeUser,
  data,
  isModalCentred,
  handleRefundAdminInputChange,
  percentageRefund,
  selectedRowTotalRefundAmount = 0,
  usedForMyEvents = false,
  selectedRowCreditAmount,
  selectedRowPaidAmount,
  selectedRowTotalDiscount,
}) {
  let dispatch = useDispatch();
  const localChapterData = useSelector(
    (state) => state.chapters.localChapterData,
  );
  useEffect(() => {
    if (!localChapterData) {
      dispatch(getAllChapters());
    }
  }, [localChapterData]);
  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      centered={isModalCentred}
      title={statusHeading ? statusHeading : `Update Status`}
      footer={
        <div className='update-footer'>
          <Button key='cancel' onClick={onCancel}>
            Cancel
          </Button>

          {refundBoolForAdmin ? (
            <Popconfirm
              placement='topRight'
              title='Refund Selected Registration'
              prefixCls='status-modal-popconfirm'
              description='Are you sure you want to refund the selected attendees?'
              onConfirm={handleUpdateJobStatus}
              okType='primary'
              icon={<QuestionCircleOutlined style={{color: 'warning'}} />}>
              <Button
                key='update'
                type='primary'
                className={`${'common-button'}`}>
                {submitText ? submitText : 'Update'}
              </Button>
            </Popconfirm>
          ) : (
            <Button
              key='update'
              type='primary'
              onClick={handleUpdateJobStatus}
              className={
                isColorSchemeUser ? 'common-button-refund' : 'common-button'
              }>
              {submitText ? submitText : 'Update'}
            </Button>
          )}
        </div>
      }>
      <Divider />

      <div className='refund-modal-fields-wrapper'>
        {refundBoolForAdmin ? (
          <>
            <span>
              Please enter the percentage of the amount to be refunded.
            </span>
            <Input
              prefixCls='refund-admin-input'
              value={percentageRefund}
              placeholder='Refund'
              suffix='%'
              onChange={handleRefundAdminInputChange}
            />
            <div>
              <span>
                Paid Amount :
                {formatCurrencyByLocalChapter(
                  selectedRowPaidAmount,
                  localChapterData,
                )}
              </span>
              <br />
              <span>
                Used Credit :
                {formatCurrencyByLocalChapter(
                  selectedRowCreditAmount,
                  localChapterData,
                )}
              </span>
              <br />
              <span>
                Coupon Discount :
                {formatCurrencyByLocalChapter(
                  selectedRowTotalDiscount,
                  localChapterData,
                )}
              </span>
              <br />
              <hr />
              <span>
                <b>
                  Total Amount to be Refunded :
                  {formatCurrencyByLocalChapter(
                    selectedRowTotalRefundAmount,
                    localChapterData,
                  )}
                </b>
              </span>
              <br />
              <br />
              <Alert
                message={'Coupon Discount payment will not be refunded.'}
                type='info'
                showIcon
              />
            </div>
          </>
        ) : (
          <Select
            prefixCls='common-select-register'
            maxTagCount={'responsive'}
            maxTagPlaceholder={maxTagPlaceholder}
            className='update-status-selection'
            placeholder='Select option'
            value={showUpdateStatus}
            defaultValue={showUpdateStatus}
            onChange={handleChange}
            options={selectionOptions}
            mode={mode}
            tagRender={tagRender}
          />
        )}
        {refundBoolForAdmin && (
          <div className='refund-modal-radio-wrapper'>
            <p>
              Direct or Credit{' '}
              <Tooltip
                title={`For direct payments, the funds will be returned to the original payment method. For credit payments, the funds will be added to the user's credit balance.`}>
                <QuestionCircleOutlined />
              </Tooltip>
            </p>
            <Radio.Group
              prefixCls='refund-radio-group'
              onChange={handleRadioChange}
              defaultValue='direct'
              buttonStyle='solid'>
              <Radio.Button value='direct'>Direct</Radio.Button>
              <Radio.Button value='credit'>Credit</Radio.Button>
            </Radio.Group>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default StatusUpdateModal;

import {
  getLocalStorageUserInfo,
  truncateTextToWords,
} from '../../../Util/commonUtility';
import {fetchAdsByUserId, getAllApprovedAds} from '../../../Store/Actions';
import view_more_svg from '../../../Assets/view-more-right-arrow.svg';
import {ApplicationString} from '../../../Localization/Locales/en';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {Col, Row, Skeleton, Spin} from 'antd';
import './style.css';

const CommonWantToSeeAdsListing = ({
  usedForChapPage,
  containerHeight,
  usedForRegister,
  isVisible = true,
}) => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const adsData = useSelector(({ads}) => ads?.getApprovedAds?.data?.ads);
  const [shouldRender, setshouldRender] = useState(false);
  const [allAdsData, setAllAdsData] = useState([]);
  const userInfo = localStorage.getItem('userInfo');
  const [isHovered, setIsHovered] = useState(true);
  const isAdsOverflowing = allAdsData?.length > 6;

  useEffect(() => {
    if (adsData) {
      setAllAdsData(adsData);
    } else {
      dispatch(getAllApprovedAds());
    }
  }, [adsData]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth > 1000) {
        setshouldRender(true);
      } else {
        setshouldRender(false);
      }
    };
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const submitAdHandler = () => {
    if (userInfo) {
      navigate('/ads');
    } else {
      navigate('/login');
    }
  };

  const handleMouseOver = () => {
    setIsHovered(false);
  };

  const handleMouseOut = () => {
    setIsHovered(true);
  };

  return (
    // allAdsData.length > 0 &&
    <div
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      className={`${
        usedForRegister
          ? 'want-to-see-your-ads-container for-register'
          : 'want-to-see-your-ads-container'
      } ${
        usedForChapPage && 'want-to-see-your-ads-container-margin-for-chapPage'
      }`}>
      <div
        className={`${
          usedForRegister
            ? ' want-to-see-your-ads-listing-container register'
            : ' want-to-see-your-ads-listing-container'
        }`}
        style={{
          height: isAdsOverflowing ? containerHeight : 'auto',
          maxHeight: containerHeight,
          marginBottom: isAdsOverflowing && isVisible ? '32px' : '0px',
        }}>
        {isAdsOverflowing && shouldRender && isHovered && isVisible ? (
          <div className='marquee--inner'>
            <span>
              {allAdsData?.map((item, i) => {
                const attachmentUrl = item?.attachment_url || '';
                return (
                  <div key={i} className={`want-to-see-your-ads-listing-card `}>
                    <span>
                      <a
                        id={`ads-link-${i}`}
                        href={attachmentUrl}
                        target='_blank'
                        rel='noopener noreferrer'>
                        {item?.vendor || ''}
                      </a>{' '}
                      {truncateTextToWords(item?.description || '', 20)}
                    </span>
                  </div>
                );
              })}
            </span>
            <span>
              {allAdsData?.map((item, i) => {
                const attachmentUrl = item?.attachment_url || '';
                return (
                  <div key={i} className={`want-to-see-your-ads-listing-card `}>
                    <span>
                      <a
                        id={`ads-link-${i}`}
                        href={attachmentUrl}
                        target='_blank'
                        rel='noopener noreferrer'>
                        {item?.vendor || ''}
                      </a>{' '}
                      {truncateTextToWords(item?.description || '', 20)}
                    </span>
                  </div>
                );
              })}
            </span>
          </div>
        ) : (
          allAdsData?.map((item, i) => {
            const attachmentUrl = item?.attachment_url || '';
            return (
              <div
                key={i}
                className={`want-to-see-your-ads-listing-card scrolling-card`}>
                <span>
                  <a
                    id={`ads-link-${i}`}
                    href={attachmentUrl}
                    target='_blank'
                    rel='noopener noreferrer'>
                    {item?.vendor || ''}
                  </a>{' '}
                  {truncateTextToWords(item?.description || '', 20)}
                </span>
              </div>
            );
          })
        )}
      </div>
      <Row
        className='want-to-see-your-ads-buttons-container'
        style={{
          position: isAdsOverflowing && isVisible ? 'absolute' : 'relative',
        }}>
        <button
          id='submit-ads-button'
          className='view-more-svg-button'
          onClick={submitAdHandler}>
          {ApplicationString['adsApprovedListComponent.button']}
          <img
            src={view_more_svg}
            alt='view_more_svg'
            className='view-more-svg'
          />
        </button>
      </Row>
    </div>
  );
};

export default CommonWantToSeeAdsListing;

import {
  GET_ALL_SPONSORS,
  ADD_SPONSOR_SUCCESS,
  UPDATE_SPONSOR_BY_ID,
  DELETE_SPONSOR_BY_ID,
  FETCH_SPONSOR_BY_ID,
  GET_ALL_SPONSORS_BY_QUERY,
} from '../../Constant/ActionTypes';

const initialState = {
  allSponsors: false,
  isCreateSponsor: false,
  isUpdateSponsor: false,
  fetchSponsorData: false,
  allSponsorsQueryData: false,
};

const SponsorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SPONSORS:
      return {
        ...state,
        allSponsors: action.payload,
        isCreateSponsor: false,
        isUpdateSponsor: false,
      };
    case GET_ALL_SPONSORS_BY_QUERY:
      return {
        ...state,
        allSponsorsQueryData: action.payload,
        isCreateSponsor: false,
        isUpdateSponsor: false,
      };
    case FETCH_SPONSOR_BY_ID:
      return {
        ...state,
        fetchSponsorData: action.payload,
      };
    case ADD_SPONSOR_SUCCESS:
      return {
        ...state,
        isCreateSponsor: true,
        allSponsors: false,
        allSponsorsQueryData: false,
      };
    case UPDATE_SPONSOR_BY_ID:
      return {
        ...state,
        isUpdateSponsor: true,
        allSponsors: false,
        allSponsorsQueryData: false,
      };
    case DELETE_SPONSOR_BY_ID:
      return {
        ...state,
        allSponsors: false,
        allSponsorsQueryData: false,
      };
    default:
      return state;
  }
};

export default SponsorsReducer;

import {useDispatch, useSelector} from 'react-redux';
import SignUpComponent from '../../../../Components/AdminComponents/Auth/SignUp';
import {getAllChapters, getAllKeywords} from '../../../../Store/Actions';
import {useEffect, useState} from 'react';
import {createSelectionOptionsUtil} from '../../../../Util/commonUtility';

const SignUpContainer = () => {
  const dispatch = useDispatch();
  const {allChapters} = useSelector(({chapters}) => chapters);
  const {allKeywords} = useSelector(({keywords}) => keywords);
  const [chapterList, setChapterList] = useState([]);
  const [keywordsList, setKeywordsList] = useState([]);

  useEffect(() => {
    if (allChapters) {
      const visibleChapters = allChapters?.data?.chapters?.filter((chapter) => {
        return chapter?.is_visible_on_map === true;
      });
      setChapterList(createSelectionOptionsUtil(visibleChapters, 'id', 'name'));
    } else {
      dispatch(getAllChapters());
    }
  }, [allChapters]);

  useEffect(() => {
    if (allKeywords) {
      setKeywordsList(
        createSelectionOptionsUtil(allKeywords?.data?.keyword, 'name', 'name'),
      );
    } else {
      dispatch(getAllKeywords());
    }
  }, [allKeywords]);

  return (
    <SignUpComponent chaptersList={chapterList} keywordsList={keywordsList} />
  );
};
export default SignUpContainer;

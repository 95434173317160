import React, {useState} from 'react';
import './index.css';
import RegisterForm from '../../../CommonComponents/RegisterForm';
import {Divider, Row} from 'antd';
import FormTitle from '../../../CommonComponents/FormHeading';
import SectionLayout from '../../../CommonComponents/Layout/sectionLayout';
const SignUpComponent = ({chaptersList, keywordsList}) => {
  return (
    <React.Fragment>
      <div className='register-max-conatiner'>
        <div className='Register-form-conatiner'>
          <SectionLayout>
            <FormTitle
              title='Admin Registration'
              description='Please log in to Admin account'
              additionalLabel='Login required For Admin Panel'
            />
            <Divider />
            <RegisterForm
              chaptersList={chaptersList}
              keywordsList={keywordsList}
            />
          </SectionLayout>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignUpComponent;

import {Alert, Button, Col, Result, Row} from 'antd';
import React from 'react';
import SectionLayout from '../Layout/sectionLayout';
import {SmileOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import SponsorOptionPageHeroText from '../SponsorOptionPageHeroText/index';
const StayTunedComponent = () => {
  let navigate = useNavigate();
  return (
    <Result
      prefixCls='common-stay-tuned-container'
      icon={<SmileOutlined />}
      title='Stay tuned for more information!'
      extra={
        <Button
          type='primary'
          onClick={() => navigate('/')}
          prefixCls='common-stay-tuned-button'>
          Back Home
        </Button>
      }
    />
  );
};

export default StayTunedComponent;

import React, {useEffect, useState} from 'react';
import {Space, Table, Popconfirm, Tooltip, Button, Card, Tag} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import './index.css';
import {useNavigate} from 'react-router-dom';
import {
  ChapterColumnValues,
  PageSizeArray,
  PaginationPosition,
  ReturnNAOnEmpty,
  chapterFilters,
} from '../../../Util/commonUtility';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import {ApplicationString} from '../../../Localization/Locales/en';
import editIcon from '../../../Assets/editIcon.svg';
import deleteIcon from '../../../Assets/deleteIcon.svg';
import plus from '../../../Assets/Plus.svg';
import UpdateHistoryTableInfo from '../HistoryView';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {useSelector} from 'react-redux';
import CommonTableColumnTextView from '../../CommonComponents/CommonTableColumnTextView';

const ChaptersComponent = (props) => {
  let navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const {
    allChaptersData,
    searchValues,
    fetchChapters,
    paging,
    handlePaginationChange,
    DeleteChapters,
    sortParams,
    handleTableChange,
  } = props;

  const ps = useSelector(({pageSizes}) => pageSizes.pageSizes.chapters);
  const handleSearch = (values) => {
    fetchChapters(values);
  };

  const handleEditClick = async (id) => {
    navigate(`/admin/chapters/update/${id}`);
  };

  useEffect(() => {
    searchValues && setExpanded(true);
  }, [searchValues]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: '',
      width: '5%',
    },
    {
      title: 'Order Of Chapter',
      dataIndex: 'order_number',
      key: 'order_number',
      width: '10%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'User',
      dataIndex: 'nvp_user',
      key: 'user',
    },
    {
      title: 'Email',
      dataIndex: 'pp_email',
      key: 'email',
    },
    {
      title: 'Time Zone',
      dataIndex: 'time_zone',
      key: 'time_zone',
      render: (text) => {
        return (
          <CommonTableColumnTextView
            columnText={ReturnNAOnEmpty(text)}
            isToolTip={false}
          />
        );
      },
    },
    {
      title: 'Visible On Map',
      dataIndex: 'is_visible_on_map',
      key: 'is_visible_on_map',
      render: (text) => {
        return (
          <div className='refund-text'>
            <CommonTableColumnTextView
              columnText={text ? 'Yes' : 'No'}
              isToolTip={false}
            />
          </div>
        );
      },
    },
    {
      title: 'Payment gateway',
      dataIndex: 'payment_gateway',
    },
    {
      title: '',
      key: 'action',
      width: '6%',
      fixed: 'right',
      render: (_, record) => (
        <AdminActionsDropdown
          record={record}
          editBool={true}
          deleteBool={true}
          handleDeleteClick={DeleteChapters}
          handleEditClick={handleEditClick}
          text='Chapter'
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sorter = true;
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });

  const searchColumn = columns.filter((column) =>
    ChapterColumnValues.includes(column.dataIndex),
  );
  return (
    <div className='main-container'>
      <div className='search-container' style={{gap: '10px'}}>
        <Button onClick={toggleExpand} id='AdFeeComponent-button-filterSearch'>
          {expanded ? <FilterOutlined /> : <FilterOutlined />}{' '}
          {ApplicationString['dynamicSearch.button.heading']}
        </Button>
        <Button
          type='primary'
          onClick={() => navigate('/admin/chapters/add')}
          id='AdFeeComponent-button-addChapters'
          className='common-button'>
          <div className='icon-container'>
            <img src={plus} alt='plus' className='icon-plus' />
            <span className='add-content'>
              {ApplicationString['chaptersComponent.button.addForm']}
            </span>
          </div>
        </Button>
      </div>

      {expanded && (
        <DynamicSearchForm
          columns={chapterFilters}
          onSearch={handleSearch}
          savedFilteredValues={searchValues}
          title={'chapters'}
        />
      )}

      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          onChange={handleTableChange}
          columns={columns}
          dataSource={allChaptersData}
          // sticky={true}
          scroll={{x: allChaptersData?.length * 150}}
          key={allChaptersData?.map((item) => {
            item.id;
          })}
          pagination={{
            onChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            current: paging.page,
            total: paging.totalCount,
            pageSize: ps || paging.pageCount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default ChaptersComponent;

import React, {useEffect, useState} from 'react';
import {
  Button,
  Input,
  Space,
  Table,
  Tag,
  Divider,
  Col,
  Row,
  Tooltip,
} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {
  PageSizeArray,
  PaginationPosition,
  eventRefundsFilters,
  formatCurrency,
  formatCurrencyByLocalChapter,
  formatDate,
  maxTagPlaceholder,
  tagRender,
} from '../../../Util/commonUtility';
import {EyeOutlined, FilterOutlined} from '@ant-design/icons';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import {ApplicationString} from '../../../Localization/Locales/en';
import {CSVLink} from 'react-csv';
import CommonTableColumnTextView from '../../CommonComponents/CommonTableColumnTextView';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {useNavigate} from 'react-router-dom';
import {editRefundStatusById} from '../../../Store/Actions/events';
import {FETCH_ERROR} from '../../../Constant/ActionTypes';
import {getAllChapters} from '../../../Store/Actions';
import {DescriptionItem} from './../../../Util/commonUtility';
import AdminViewDetailsDrawer from './../../CommonComponents/AdminViewDetailsDrawer/index';
import StatusUpdateModal from '../../CommonComponents/CommonModal';
import {resetMemorizedInitiatedRefundAttendees} from '../../../Store/Actions/eventRegister';

const EventRefundComponent = (props) => {
  let dispatch = useDispatch();
  const {
    allEventRefundData,
    paging,
    sortParams,
    handleTableChange,
    handlePaginationChange,
    fetchEventRefunds,
    searchValues,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const [selectedRefundAttendees, setSelectedRefundAttendees] = useState();
  const [percentageRefund, setPercentageRefund] = useState('');
  const [selectionType, setSelectionType] = useState('checkbox');
  const [showApproveReject, setShowApproveReject] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowTotalCost, setSelectedRowTotalCost] = useState();
  const [selectedRowTotalRefundAmount, setSelectedRowTotalRefundAmount] =
    useState(0);
  const [selectedRowPaidAmount, setSelectedRowPaidAmount] = useState(0);
  const [selectedRowCreditAmount, setSelectedRowCreditAmount] = useState(0);
  const [selectedRowTotalDiscount, setSelectedRowTotalDiscount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [refundToAccount, setRefundToAccount] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);
  const [showTotalCost, setShowTotalCost] = useState(0);
  const [showTotalDiscount, setShowTotalDiscount] = useState(0);

  const navigate = useNavigate();

  const localChapterData = useSelector(
    (state) => state.chapters.localChapterData,
  );
  const selectedInitiatedRefundIds = useSelector(
    (state) => state.eventRegister.selectedInitiatedRefundIds,
  );
  useEffect(() => {
    if (!localChapterData) {
      dispatch(getAllChapters());
    }
  }, [localChapterData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    searchValues && setExpanded(true);
  }, [searchValues]);

  useEffect(() => {
    if (selectedInitiatedRefundIds) {
      setSelectedRowKeys(
        selectedInitiatedRefundIds?.map((row) => Number(row?.id)),
      );
      const totalCreditArray = selectedInitiatedRefundIds.map((item) =>
        Number(item?.credit_amount),
      );
      const totalPaidArray = selectedInitiatedRefundIds.map((item) =>
        Number(item?.paid_amount),
      );
      const totalCostArray = selectedInitiatedRefundIds.map((item) =>
        Number(item?.total_cost),
      );
      const totalDiscountArray = selectedInitiatedRefundIds.map((item) =>
        Number(item?.discount_amount),
      );
      function getSum(total, num) {
        return total + num;
      }
      const totalCost = totalCostArray.reduce(getSum, 0);
      const totalDiscountAmount = totalDiscountArray.reduce(getSum, 0);
      const totalCreditAmount = totalCreditArray.reduce(getSum, 0);
      const totalPaidAmount = totalPaidArray.reduce(getSum, 0);
      setSelectedRowCreditAmount(totalCreditAmount);
      setSelectedRowPaidAmount(totalPaidAmount);
      setSelectedRowTotalCost(totalCost - totalDiscountAmount);
      dispatch(resetMemorizedInitiatedRefundAttendees());
    }
  }, [selectedInitiatedRefundIds]);

  const handleApproveRefunds = () => {
    if (percentageRefund == 0) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Refund percentage cannot be zero',
      });
      return;
    }
    if (percentageRefund === '') {
      dispatch({type: FETCH_ERROR, payload: 'Please enter refund percentage'});
    } else if (selectedRefundAttendees.length <= 0) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Please select attendees to approve refund',
      });
    }
    const Data = {
      eventRegistrationIds: selectedRefundAttendees,
      status: 0,
      refund_percentage: Number(percentageRefund),
      refund_to_account: refundToAccount,
    };
    dispatch(
      editRefundStatusById(Data, setSelectedRowKeys, setShowApproveReject),
    );
    setSelectedRefundAttendees([]);
    setPercentageRefund('');
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value >= 0 && value <= 100) {
      setPercentageRefund(value);
      const totalCost = Number(selectedRowTotalCost);
      const refundAmount = (totalCost * value) / 100;
      setSelectedRowTotalRefundAmount(refundAmount);
    }
  };

  // Drawer functions ----------------------------------------------------
  const showDrawer = (record) => {
    setOpenDrawerDetails(record);
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const EventDrawerContent = () => {
    const {
      attendee_first_name,
      attendee_last_name,
      user_id,
      event_id,
      attendee_email,
      attendee_company,
      is_sponsor,
      is_reps,
      registration_type,
      event_registration_options,
      total_cost,
      discount_amount,
      discount_code,
      status,
      created_at,
      credit_amount,
    } = openDrawerDetails || {};

    return (
      <>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Attendee Status'
              content={
                {
                  3: 'Paid',
                  4: 'Payment Confirmed',
                  6: 'Fee Wavier',
                  '-1': 'To be Deleted',
                  0: 'Payment Refunded',
                  1: 'To be Paid',
                  2: 'Refund Pending',
                  5: 'No Charge Event',
                }[status] || status
              }
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem title='User Id' content={user_id} />
          </Col>
          <Col span={12}>
            <DescriptionItem title='Event Id' content={event_id} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem title='First Name' content={attendee_first_name} />
          </Col>
          <Col span={12}>
            <DescriptionItem title='Last Name' content={attendee_last_name} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem title='Attendee Email' content={attendee_email} />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='Attendee Company'
              content={attendee_company}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem
              title='Sponsorship'
              content={is_sponsor ? 'Yes' : 'No'}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='Representative'
              content={is_reps ? 'Yes' : 'No'}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Registration Type'
              content={registration_type}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Registration Option'
              content={
                event_registration_options?.sponsorship?.name
                  ? event_registration_options?.sponsorship?.name
                  : event_registration_options?.registration?.name +
                    ' ' +
                    '-' +
                    ' ' +
                    formatCurrency(
                      event_registration_options?.registration?.price,
                      'USD',
                    )
              }
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Food Option'
              content={
                event_registration_options?.food?.name
                  ? event_registration_options?.food?.name +
                    ' ' +
                    '-' +
                    ' ' +
                    formatCurrency(
                      event_registration_options?.food?.price,
                      'USD',
                    )
                  : 'No Food'
              }
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem
              title='Total Cost'
              content={formatCurrency(total_cost ? total_cost : 0)}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='Used Credit'
              content={formatCurrency(credit_amount ? credit_amount : 0)}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem
              title='Discounted Cost'
              content={formatCurrency(discount_amount ? discount_amount : 0)}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='Total Cost After Discount'
              content={formatCurrency(total_cost - discount_amount || 0)}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem
              title='Coupon Code Applied'
              content={discount_code || 'N/A'}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Registered Date'
              content={formatDate(created_at)}
            />
          </Col>
        </Row>
        <Divider />
      </>
    );
  };

  const handleRadioChange = (e) => {
    if (e.target.value === 'direct') {
      setRefundToAccount(false);
    } else if (e.target.value === 'credit') {
      setRefundToAccount(true);
    }
  };

  const handleRefundVisible = () => {
    setIsModalVisible(true);
  };
  const hideModal = () => {
    setIsModalVisible(false);
    setPercentageRefund('');
    setRefundToAccount(false);
    setSelectedRowTotalRefundAmount(0);
  };

  const footer = () => {
    return (
      <div>
        {showApproveReject && (
          <>
            <div className='refund-admin-container'>
              <StatusUpdateModal
                statusHeading='Select Mode and Percentage For Refund'
                visible={isModalVisible}
                onCancel={hideModal}
                submitText='Refund'
                mode={'tags'}
                maxTagCount={'responsive'}
                maxTagPlaceholder={maxTagPlaceholder}
                tagRender={tagRender}
                handleRadioChange={handleRadioChange}
                refundBoolForAdmin={true}
                handleRefundAdminInputChange={handleInputChange}
                percentageRefund={percentageRefund}
                setRefundToAccount={setRefundToAccount}
                handleUpdateJobStatus={handleApproveRefunds}
                selectedRowTotalRefundAmount={selectedRowTotalRefundAmount}
                selectedRowCreditAmount={selectedRowCreditAmount}
                selectedRowPaidAmount={selectedRowPaidAmount}
                selectedRowTotalDiscount={selectedRowTotalDiscount}
              />
              <Button
                type='primary'
                onClick={handleRefundVisible}
                id='EventRefundComponent-button-backToEvent'
                className='common-submit-login common-form-submit'>
                Approve
              </Button>
            </div>
          </>
        )}
      </div>
    );
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const handleSearch = (values) => {
    fetchEventRefunds(values);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowIds, selectedKeys) => {
      const filterSelectedKeys = selectedKeys?.filter(
        (row) => row?.status !== 'Payment Refunded',
      );
      const totalCostArray = filterSelectedKeys.map((item) =>
        Number(item?.total_cost),
      );
      const totalCreditArray = filterSelectedKeys.map((item) =>
        Number(item?.credit_amount),
      );
      const totalPaidArray = filterSelectedKeys.map((item) =>
        Number(item?.paid_amount),
      );
      const totalDiscountArray = filterSelectedKeys.map((item) =>
        Number(item?.discount_amount),
      );
      function getSum(total, num) {
        return total + num;
      }
      const totalCost = totalCostArray.reduce(getSum, 0);
      const totalDiscountAmount = totalDiscountArray.reduce(getSum, 0);
      const totalCreditAmount = totalCreditArray.reduce(getSum, 0);
      const totalPaidAmount = totalPaidArray.reduce(getSum, 0);
      setSelectedRowCreditAmount(totalCreditAmount);
      setSelectedRowPaidAmount(totalPaidAmount);
      setSelectedRowTotalDiscount(totalDiscountAmount);
      setSelectedRowTotalCost(totalCost - totalDiscountAmount);

      setSelectedRowKeys(filterSelectedKeys?.map((row) => row.id));
    },
    getCheckboxProps: (record) => {
      return {
        disabled: record.status == 'Payment Refunded',
      };
    },
  };

  useEffect(() => {
    if (selectedRowKeys?.length > 0) {
      setShowApproveReject(true);
      setSelectedRefundAttendees(selectedRowKeys);
    } else {
      setShowApproveReject(false);
      setSelectedRefundAttendees([]);
    }
  }, [selectedRowKeys]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '4%',
    },
    {
      title: 'First Name',
      dataIndex: 'attendee_first_name',
      sorter: true,
      width: '10%',
      render: (text) => {
        return <span>{text || 'N/A'}</span>;
      },
    },
    {
      title: 'Last Name',
      dataIndex: 'attendee_last_name',
      sorter: true,
      width: '10%',
      render: (text) => {
        return <span>{text || 'N/A'}</span>;
      },
    },
    {
      title: 'Registration Option',
      dataIndex: 'registration_option',
      width: '15%',
      render: (text, record) => {
        const isOptionsSponsor =
          record.event_registration_options?.sponsorship?.name;
        return (
          <span>
            {/* {checkIsEditable(record)} */}
            {record?.is_reps || record?.is_sponsor
              ? 'N/A'
              : isOptionsSponsor
              ? isOptionsSponsor
              : record.event_registration_options?.registration?.name +
                ' ' +
                '-' +
                ' ' +
                formatCurrency(
                  record?.event_registration_options?.registration?.price,
                  'USD',
                )}
          </span>
        );
      },
    },
    {
      title: 'Food Option',
      dataIndex: 'food',
      width: '15%',
      ellipsis: true,
      render: (text) => {
        return <spam>{text?.name ?? 'No Food'}</spam>;
      },
    },
    {
      title: 'Total Cost',
      dataIndex: 'total_cost',
      width: '10%',
      ellipsis: true,
      sorter: true,
      render: (text) => {
        return (
          <span>
            {formatCurrencyByLocalChapter(text ? text : 0, localChapterData)}
          </span>
        );
      },
    },
    {
      title: 'Refund Amount',
      dataIndex: 'refunded_amount',
      width: '10%',
      ellipsis: true,
      sorter: true,
      render: (text) => {
        return (
          <span>{formatCurrencyByLocalChapter(text, localChapterData)}</span>
        );
      },
    },
    {
      title: 'Refund Mode',
      dataIndex: 'refund_to_account',
      width: '10%',
      ellipsis: true,
      sorter: true,
      render: (text, record) => {
        let refundMode;
        //Added specific condition for true and false because initially show NA when refund is approved the show Direct or Credit
        if (record?.status === 'Refund Pending') {
          refundMode = 'N/A';
        } else {
          refundMode = text ? 'Credit' : 'Direct';
        }
        return <span>{refundMode}</span>;
      },
    },

    {
      title: 'Status',
      dataIndex: 'status',
      fixed: 'right',
      width: '9%',
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      width: '3%',
      render: (_, record) => (
        <AdminActionsDropdown
          text='Event Refund'
          record={record}
          viewBool={true}
          handleView={showDrawer}
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });

  return (
    <div className='main-container'>
      <AdminViewDetailsDrawer
        content={EventDrawerContent}
        visible={open}
        text={'Event Refund'}
        onCloseDrawer={onCloseDrawer}
        isEditable={false}
      />
      <div className='search-container' style={{gap: '10px'}}>
        <Button
          onClick={() => navigate('/admin/events')}
          id='EventRefundComponent-button-backToEvent'>
          Back To Events
        </Button>
        <Button onClick={toggleExpand} id='EventRefund-button-filterSearch'>
          {expanded ? <FilterOutlined /> : <FilterOutlined />}{' '}
          {ApplicationString['dynamicSearch.button.heading']}
        </Button>
      </div>
      {expanded && (
        <DynamicSearchForm
          columns={eventRefundsFilters}
          onSearch={handleSearch}
          savedFilteredValues={searchValues}
          title={'event_refunds'}
        />
      )}
      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          columns={columns}
          dataSource={allEventRefundData}
          rowKey={'id'}
          onChange={handleTableChange}
          sticky={true}
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          footer={footer}
          pagination={{
            onChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            current: paging.page,
            total: paging.totalCount,
            pageSize: paging.pageCount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default EventRefundComponent;

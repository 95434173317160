import {
  GET_ALL_EVENT_TEMPLATE_SCHEDULERS,
  ADD_EVENT_TEMPLATE_SCHEDULER_SUCCESS,
  UPDATE_EVENT_TEMPLATE_SCHEDULER_BY_ID,
  DELETE_EVENT_TEMPLATE_SCHEDULER_BY_ID,
  FETCH_EVENT_TEMPLATE_SCHEDULER_BY_ID,
  FETCH_EVENT_TEMPLATE_SCHEDULERS_BY_USERID,
  GET_EVENT_TEMPLATE_SCHEDULER_BY_EVENT_ID,
  SET_EVENT_TEMPLATE_EDIT,
  SEND_DIRECT_EMAIL_BY_ID,
  SET_EVENT_NAME_STATE,
  SET_EVENT_START_DATE_EVENT_TEMPLATE_SCHEDULER,
} from '../../Constant/ActionTypes';

const initialState = {
  allEventTemplateSchedulers: false,
  isCreateEventTemplateScheduler: false,
  isUpdateEventTemplateScheduler: false,
  fetchEditData: false,
  getEventTemplateSchedulersDataByUser: false,
  getEventTemplateSchedulersDataByEventId: false,
  isEventTemplateEdit: false,
  eventStartDateForEventTemp: false,
};

const EventTemplateSchedulerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EVENT_TEMPLATE_SCHEDULERS:
      return {
        ...state,
        allEventTemplateSchedulers: action.payload,
        isCreateEventTemplateScheduler: false,
        isUpdateEventTemplateScheduler: false,
      };
    case FETCH_EVENT_TEMPLATE_SCHEDULER_BY_ID:
      return {
        ...state,
        fetchEditData: action.payload,
      };
    case FETCH_EVENT_TEMPLATE_SCHEDULERS_BY_USERID:
      return {
        ...state,
        getEventTemplateSchedulersDataByUser: action.payload,
      };
    case GET_EVENT_TEMPLATE_SCHEDULER_BY_EVENT_ID:
      return {
        ...state,
        getEventTemplateSchedulersDataByEventId: action.payload,
      };
    case ADD_EVENT_TEMPLATE_SCHEDULER_SUCCESS:
      return {
        ...state,
        isCreateEventTemplateScheduler: true,
        allEventTemplateSchedulers: false,
        getEventTemplateSchedulersDataByUser: false,
        getEventTemplateSchedulersDataByEventId: false,
      };
    case UPDATE_EVENT_TEMPLATE_SCHEDULER_BY_ID:
      return {
        ...state,
        isUpdateEventTemplateScheduler: true,
        getEventTemplateSchedulersDataByUser: false,
        allEventTemplateSchedulers: false,
        getEventTemplateSchedulersDataByEventId: false,
      };
    case DELETE_EVENT_TEMPLATE_SCHEDULER_BY_ID:
      return {
        ...state,
        allEventTemplateSchedulers: false,
        getEventTemplateSchedulersDataByUser: false,
        getEventTemplateSchedulersDataByEventId: false,
      };
    case SET_EVENT_TEMPLATE_EDIT:
      return {
        ...state,
        isEventTemplateEdit: action.payload,
      };
    case SET_EVENT_START_DATE_EVENT_TEMPLATE_SCHEDULER:
      return {
        ...state,
        eventStartDateForEventTemp: action.payload,
      };
    case SEND_DIRECT_EMAIL_BY_ID:
      return {
        ...state,
        allEventTemplateSchedulers: false,
        getEventTemplateSchedulersDataByUser: false,
        getEventTemplateSchedulersDataByEventId: false,
      };
    default:
      return state;
  }
};

export default EventTemplateSchedulerReducer;

import {Button, Empty, Form, Input, Select, Spin} from 'antd';
import React, {useEffect, useState} from 'react';
import {
  NoEmptySpaceInput,
  validateEventOptionNumberInput,
  validatePaymentPrice,
} from '../../../../Util/commonUtility';
import CommonEditor from '../../../CommonComponents/TextEditor';
import {ApplicationString} from '../../../../Localization/Locales/en';
import './style.css';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';

const AdminSpecialPaymentFormComponent = ({
  userEmailOptions,
  setUserEmailOptions,
  userFetching,
  handleUserSearch,
  handleUserAllowClear,
  handleUserAllowClearForMouseLeave,
  editorState,
  postSpecialPayment,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const html = {__html: editorState};

  useEffect(() => {
    if (editorState) {
      form.setFieldsValue({
        body: editorState,
      });
    }
  }, [editorState]);

  const onFinish = (values) => {
    if (validatePaymentPrice(values.amount, dispatch)) return;
    postSpecialPayment({
      user_id: values.userId,
      subject: values.subject,
      amount: values.amount,
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='generateSpecialPaymentForm'
          layout='vertical'
          onFinish={onFinish}
          className='two-column-form'
          autoComplete='off'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                name='userId'
                label='Select User'
                rules={[
                  {
                    required: true,
                    message: 'User should not be empty!!',
                  },
                ]}>
                <Select
                  prefixCls='common-select-register'
                  mode='single'
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  onSearch={handleUserSearch}
                  onClear={handleUserAllowClear}
                  onMouseLeave={handleUserAllowClearForMouseLeave}
                  notFoundContent={
                    userFetching ? (
                      <Spin size='small' />
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  placeholder={'Select users'}
                  options={userEmailOptions}></Select>
              </Form.Item>
              <Form.Item
                label='Subject'
                name='subject'
                rules={[
                  {
                    required: true,
                    message: 'Subject should not be empty!!',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Employer'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter subject for the email'
                />
              </Form.Item>
              <Form.Item
                label='Amount'
                name='amount'
                rules={[
                  {
                    required: true,
                    message: 'Amount should not be empty!!',
                  },
                  {
                    validator: (_, value) =>
                      validateEventOptionNumberInput(value, 'Amount', 999999),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter amount for the special payment'
                />
              </Form.Item>
              <Form.Item name='body' label='Email template'>
                <div
                  dangerouslySetInnerHTML={html}
                  className='adminspecialPayment-template-div'></div>
              </Form.Item>
              <div className='form-flex-button  adminSpecialPayment-button-container'>
                <Button
                  id='adminEmailToMember-submitButton-button'
                  type='primary'
                  htmlType='submit'
                  // //disabled={submit ? true : false}
                  className='common-submit-login common-form-submit'>
                  {ApplicationString['adminSpecialPayment.button.submit']}
                </Button>
                <Button
                  className='common-submit-login common-form-submit common-form-cancel'
                  htmlType='button'
                  id='adminEmailToMember-resetButton-reset'
                  onClick={onReset}>
                  {ApplicationString['adminSpecialPayment.button.reset']}
                </Button>{' '}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AdminSpecialPaymentFormComponent;

import React, {useEffect, useState} from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import {useDispatch, useSelector} from 'react-redux';
import EmailCodeComponent from '../../../Components/AdminComponents/EmailCodes';
import {deleteEmailCodeById, getAllEmailCodes} from '../../../Store/Actions';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';

const EmailCodeContainer = () => {
  const dispatch = useDispatch();
  const {allEmailCodes} = useSelector(({emailCodes}) => emailCodes);
  const [listData, setListData] = useState();
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.emailCodes,
  );
  const memorizedPage = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.emailCodes,
  );
  const [pagination, setPagination] = useState({
    page: memorizedPage || DefaultPaginationValues.PAGE,
    pageCount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  const searchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.email_template,
  );

  useEffect(() => {
    if (allEmailCodes.forMaster) {
      setListData(allEmailCodes.forMaster?.data?.emailCodes);
      setPagination({
        ...pagination,
        page: allEmailCodes.forMaster?.data?.currentPage,
        totalPages: allEmailCodes.forMaster?.data?.totalPage,
        totalCount: allEmailCodes.forMaster?.data?.totalCount,
      });
    } else {
      fetchEmailCodes();
    }
  }, [allEmailCodes.forMaster?.data?.emailCodes]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchEmailCodes(searchValues);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  // useEffect(() => {
  //   if (allEmailCodes.forMaster) {
  //     fetchEmailCodes();
  //   }
  // }, [
  //   pagination.page,
  //   pagination.pageCount,
  //   sortParams.field,
  //   sortParams.order,
  // ]);

  useEffect(() => {
    if (searchValues) {
      dispatch(setPageNumber('emailCodes', 1));
      setPagination({
        ...pagination,
        page: 1,
      });
      setPaginationAndSortChanged(true);
    }
  }, [searchValues]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };
  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('emailCodes', pageSize));
    dispatch(setPageNumber('emailCodes', current));

    setPagination({
      ...pagination,
      page: current,
      pageCount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };
  const fetchEmailCodes = async (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllEmailCodes(sortField, sortOrder, searchQueryData, pagination),
    );
  };

  const deleteEmailCodes = async (id) => {
    dispatch(deleteEmailCodeById(id));
  };

  return (
    <div>
      <EmailCodeComponent
        allEmailCodesData={listData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        deleteEmailCodes={(id) => {
          deleteEmailCodes(id);
        }}
        handleTableChange={handleTableChange}
        fetchEmailCodes={(searchValues) => {
          fetchEmailCodes(searchValues);
        }}
        sortParams={sortParams}
        searchValues={searchValues}
      />
    </div>
  );
};
export default EmailCodeContainer;

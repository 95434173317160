import React from 'react';
import EmailCodeContainer from '../../../Containers/AdminContainer/EmailCodes';
import EmailToMemberContainer from '../../../Containers/EmailToMemberContainer';

const EmailToMembersScreen = () => {
  return (
    <div>
      <EmailToMemberContainer />
    </div>
  );
};

export default EmailToMembersScreen;

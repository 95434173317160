import React from 'react';
import './PaymentSuccess.css'; // Import the CSS file
import {Button, Result} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const {action, registrationType} = useParams();
  // Determine the button label and navigation path based on the action parameter
  const getButtonConfig = (action, registrationType) => {
    switch (action) {
      case 'ads':
        return {label: 'Go to Ads', path: '/ads'};
      case 'jobs':
        return {label: 'Go to Jobs', path: '/jobs'};
      default:
        return {
          label: 'Go to My Events',
          path:
            registrationType === 'sponsorship'
              ? '/myEvents/sponsorship'
              : '/myEvents/regular',
        };
    }
  };

  const {label, path} = getButtonConfig(action, registrationType);

  return (
    <div className='send-email-success'>
      <Result
        status='success'
        title='Payment Successful!'
        subTitle=' Your payment has been processed successfully. Thank you for your purchase.
        You will receive a confirmation email shortly.'
        extra={[
          <Button
            type='primary'
            key='goto_signin'
            className='goto_signin_button'
            onClick={() => navigate('/')}>
            Back to home
          </Button>,
          <Button
            key={`${label}`}
            onClick={() => navigate(path)}
            prefixCls='reset_back_button'>
            {label}
          </Button>,
        ]}
      />
    </div>
  );
};

export default PaymentSuccess;

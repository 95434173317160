import React, {useEffect, useState} from 'react';
import {
  Space,
  Table,
  Popconfirm,
  Row,
  Button,
  Tooltip,
  Tag,
  Divider,
  Col,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FilterOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import {useNavigate} from 'react-router-dom';
import {
  CouponCodeApplyToFoodRadioOptions,
  CouponCodeRaioOptions,
  DescriptionItem,
  PageSizeArray,
  PaginationPosition,
  ReturnNAOnEmpty,
  formatDate,
} from '../../../Util/commonUtility';
import {ApplicationString} from '../../../Localization/Locales/en';
import editIcon from '../../../Assets/editIcon.svg';
import deleteIcon from '../../../Assets/deleteIcon.svg';
import plus from '../../../Assets/Plus.svg';
import UpdateHistoryTableInfo from '../HistoryView';
import moment from 'moment';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {useSelector} from 'react-redux';
import useDebouncedCallback from '../../../hooks/useDebounce';
import CommonTableColumnTextView from './../../CommonComponents/CommonTableColumnTextView/index';
import AdminViewDetailsDrawer from '../../CommonComponents/AdminViewDetailsDrawer';

const CouponCodesComponent = (props) => {
  const [expanded, setExpanded] = useState(false);
  let navigate = useNavigate();
  const {
    allCouponCodesData,
    handlePaginationChange,
    paging,
    sortParams,
    handleTableChange,
    deleteCouponCodes,
    fetchCouponCodes,
    searchValues,
    eventsList,
    setFetching,
    fetching,
    setEventsList,
  } = props;
  const ps = useSelector(({pageSizes}) => pageSizes.pageSizes.couponCodes);
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const [open, setOpen] = useState(false);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);
  const couponCodesFilters = [
    {
      label: 'Event Id',
      name: 'event_id',
      type: 'event_id',
      options: eventsList,
      initialValue: [],
    },
    {label: 'Coupon code', name: 'code', initialValue: ''},
    {label: 'Discount', name: 'discount', initialValue: ''},
    {label: 'Quantity', name: 'qty', initialValue: ''},
    {
      label: 'Apply to food',
      name: 'apply_to_food',
      type: 'radio',
      initialValue: '',
    },
    {
      label: 'Apply to whole cart',
      name: 'apply_to_whole_cart',
      type: 'radio',
      initialValue: '',
    },
    {
      label: 'Discount In Percentage',
      name: 'percentage_discount',
      type: 'radio',
      initialValue: '',
    },
  ];

  useEffect(() => {
    searchValues && setExpanded(true);
  }, [searchValues]);

  const handleSearch = (values) => {
    fetchCouponCodes(values);
  };

  const handleEditClick = async (id) => {
    navigate(`/admin/couponCodes/update/${id}`);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const showDrawer = (record) => {
    setOpenDrawerDetails(record);
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const CouponCodesDrawerContent = () => {
    const {
      event_id,
      code,
      discount,
      qty,
      apply_to_food,
      apply_to_whole_cart,
      percentage_discount,
      start_date,
      end_date,
    } = openDrawerDetails || {};

    return (
      <>
        <Row>
          <Col span={24}>
            <DescriptionItem title='Event Id' content={event_id || 'N/A'} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem title='Coupon Code' content={code} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem title='Discount' content={discount} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem title='Quantity' content={qty} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Apply to food'
              content={apply_to_food ? 'Yes' : 'No'}
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Apply to Whole Cart'
              content={apply_to_whole_cart ? 'Yes' : 'No'}
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Discount in Percentage'
              content={percentage_discount ? 'Yes' : 'No'}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Start Date'
              content={formatDate(start_date)}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem title='End Date' content={formatDate(end_date)} />
          </Col>
        </Row>
        <Divider />
      </>
    );
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '1.5%',
    },
    {
      title: 'Event Id',
      dataIndex: 'event_id',
      width: '2%',
      render: (text) => ReturnNAOnEmpty(text),
    },
    {
      title: 'Coupon Code',
      dataIndex: 'code',
      width: '4%',
    },

    {
      title: 'Discount',
      dataIndex: 'discount',
      width: '1.5%',
      render: (text, record) => {
        return (
          <span
            color={'success'}
            style={{fontSize: '15px', marginBottom: '5px'}}
            key={text}>
            {record.percentage_discount ? `${text}%` : `${text}`}
          </span>
        );
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      width: '1.5%',
    },
    {
      title: 'Used Quantity',
      dataIndex: 'used_qty',
      width: '1.5%',
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      width: '1%',
      render: (_, record) => (
        <AdminActionsDropdown
          record={record}
          editBool={true}
          viewBool={true}
          handleView={showDrawer}
          deleteBool={true}
          handleEditClick={handleEditClick}
          handleDeleteClick={deleteCouponCodes}
          text='Coupon Code'
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action' && column.key !== 'view_details') {
      column.sorter = true;
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });

  // debouncing the table & pagination changes to stop too many requests
  const debouncedHandlePaginationChange = useDebouncedCallback(
    handlePaginationChange,
    1000,
  );
  const debouncedTableChange = useDebouncedCallback(handleTableChange, 1000);

  return (
    <div className='main-container'>
      <AdminViewDetailsDrawer
        content={CouponCodesDrawerContent}
        visible={open}
        text={'Coupon Codes'}
        onCloseDrawer={onCloseDrawer}
        editNavigate={`/admin/couponCodes/update/${openDrawerDetails?.id}`}
      />
      <div className='search-container' style={{gap: '10px'}}>
        <Button
          onClick={toggleExpand}
          id='CouponCodesComponent-button-filterSearch'>
          {expanded ? <FilterOutlined /> : <FilterOutlined />}{' '}
          {ApplicationString['dynamicSearch.button.heading']}
        </Button>
        <Button
          type='primary'
          onClick={() => navigate('/admin/couponCodes/add')}
          id='CouponCodesComponent-button-addCouponCodes'
          className='common-button'>
          <div className='icon-container'>
            <img src={plus} alt='plus' className='icon-plus' />
            <span className='add-content'>
              {ApplicationString['couponCodesComponent.button.addForm']}
            </span>
          </div>
        </Button>
      </div>
      {expanded && (
        <DynamicSearchForm
          columns={couponCodesFilters}
          onSearch={handleSearch}
          options={CouponCodeRaioOptions}
          savedFilteredValues={searchValues}
          title={'coupon_codes'}
          eventsList={eventsList}
          setEventsList={setEventsList}
          fetching={fetching}
          setFetching={setFetching}
        />
      )}
      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          columns={columns}
          dataSource={allCouponCodesData}
          rowKey={'id'}
          scroll={{x: columns?.length * 140}}
          // sticky={true}
          onChange={debouncedTableChange}
          pagination={{
            onChange: debouncedHandlePaginationChange,
            current: paging.page,
            total: paging.totalCount,
            pageSize: ps || paging.pagecount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default CouponCodesComponent;

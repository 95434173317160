import {useRef, useCallback, useEffect} from 'react';

export const useThrottle = (callback, delay) => {
  const timeoutRef = useRef();
  const shouldWait = useRef(false);

  return (...args) => {
    if (!shouldWait.current) {
      callback(...args);
      shouldWait.current = true;
      timeoutRef.current = setTimeout(() => {
        shouldWait.current = false;
      }, delay);
    }
  };
};

import React, {useEffect, useState} from 'react';
import AddChapterComponent from '../../../../Components/AdminComponents/Chapters/AddChapter';
import {useDispatch, useSelector} from 'react-redux';
import {
  addChapter,
  fetchChapterById,
  updateChapterById,
} from '../../../../Store/Actions';
import {useNavigate} from 'react-router-dom';

const AddChapterContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {fetchChapterData} = useSelector(({chapters}) => chapters);
  const [editData, setEditData] = useState();

  useEffect(() => {
    if (fetchChapterData) {
      setEditData(fetchChapterData?.data);
    } else setEditData();
  }, [fetchChapterData]);

  const postChapter = async (Data) => {
    dispatch(addChapter(Data, navigate));
  };

  const updateChapter = async (Data, id) => {
    dispatch(updateChapterById(Data, id, navigate));
  };

  const fetchChapter = async (id) => {
    dispatch(fetchChapterById(id));
  };

  return (
    <div>
      <AddChapterComponent
        postChapter={(Data) => {
          postChapter(Data);
        }}
        updateChapter={(id, Data) => {
          updateChapter(id, Data);
        }}
        fetchChapter={(id) => {
          fetchChapter(id);
        }}
        EditData={editData}
      />
    </div>
  );
};

export default AddChapterContainer;

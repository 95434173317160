import React from 'react';
import EventTypeStaticMainComponent from '../../../Components/UserComponents/EventTypesStaticComponents/EventTypeStaticMainComponent';
import {useParams} from 'react-router-dom';

const EventTypesStaticContainer = () => {
  const {eventTypeName} = useParams();
  return <EventTypeStaticMainComponent eventTypeName={eventTypeName} />;
};

export default EventTypesStaticContainer;

import {
  GET_ALL_SPONSOR_ATTENDEES,
  ADD_SPONSOR_ATTENDEE_SUCCESS,
  UPDATE_SPONSOR_ATTENDEE_BY_ID,
  DELETE_SPONSOR_ATTENDEE_BY_ID,
} from '../../Constant/ActionTypes';

const initialState = {
  allSponsorAttendees: false,
  isCreateSponsorAttendee: false,
  isUpdateSponsorAttendee: false,
};

const SponsorAttendeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SPONSOR_ATTENDEES:
      return {
        ...state,
        allSponsorAttendees: action.payload,
        isCreateSponsorAttendee: false,
        isUpdateSponsorAttendee: false,
      };
    case ADD_SPONSOR_ATTENDEE_SUCCESS:
      return {
        ...state,
        isCreateSponsorAttendee: true,
        allSponsorAttendees: false,
      };
    case UPDATE_SPONSOR_ATTENDEE_BY_ID:
      return {
        ...state,
        isUpdateSponsorAttendee: true,
        allSponsorAttendees: false,
      };
    case DELETE_SPONSOR_ATTENDEE_BY_ID:
      return {
        ...state,
        allSponsorAttendees: false,
      };
    default:
      return state;
  }
};

export default SponsorAttendeesReducer;

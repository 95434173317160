import React from 'react';
import {Breadcrumb, Card} from 'antd';
import {HomeOutlined, UserOutlined} from '@ant-design/icons';

const DashboardContainer = (props) => {
  return (
    <div>
      <Breadcrumb
        items={[
          {
            href: '',
            title: <HomeOutlined />,
          },
          {
            href: '',
            title: (
              <>
                <UserOutlined />
                <span>Application List</span>
              </>
            ),
          },
          {
            title: 'Application',
          },
        ]}
      />
      <Card>DashboardContainer</Card>
    </div>
  );
};

export default DashboardContainer;

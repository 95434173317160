import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL_JOBS,
  SOMETHING_WENT_WRONG,
  UPDATE_JOBS_STATUS,
  DELETE_JOBS_BY_ID,
  DELETE_JOBS_SUCCESS,
  UPDATE_JOBS_STATUS_SUCCESS,
  SHOW_MESSAGE,
  POST_JOBS_SUCCESS,
  POST_JOBS,
  UPDATE_JOBS_SUCCESS,
  UPDATE_JOBS_BY_ID,
  GET_JOBS_BY_ID,
  UPDATING_CONTENT,
  GET_ALL_JOBS_BY_QUERY,
  GET_JOBS_BY_USERID,
  GET_ALL_JOBS_APPROVED,
  GET_ALL_JOBS_ACCOUNTS,
  POST_JOBS_PAYMENT_SUCCESS,
  POST_JOBFEES_SUCCESS,
  POST_JOBS_PAYMENT,
  RESET_JOBS_PAYMENT,
  GET_ALL_JOBS_WITHOUT_PAGINATION,
} from '../../Constant/ActionTypes';
import {
  DefaultListingViewMoreCount,
  commonSortOrder,
  handleJwtExpireMsg,
} from '../../Util/commonUtility';
import Urls from '../../Constant/Urls';
import {httpClient} from '../../Api/client';
import {showMessage} from './Common';

export const getAllJobs = () => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(Urls.JOBS_GET_ALL);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_JOBS, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllApprovedJobs = () => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(Urls.JOBS_GET_ALL_APPROVED);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_JOBS_APPROVED, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const getAllJobsAccountData = (date) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.JOBS_GET_ALL_ACCOUNTS}?date=${date}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_JOBS_ACCOUNTS, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const getAllJobsByQueryString = (
  pagination,
  sortField,
  sortOrder,
  searchQueryData,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.JOBS_GET_ALL}?page=${pagination.page}&pageCount=${
          pagination.pageCount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${searchQueryData}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_JOBS_BY_QUERY, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllJobsWithoutPagination = (
  sortField,
  sortOrder,
  searchQueryData,
  totalJobsCount,
  setJobsFetchingWithoutPagination,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    setJobsFetchingWithoutPagination && setJobsFetchingWithoutPagination(true);
    try {
      let data = await httpClient.get(
        `${
          Urls.JOBS_GET_ALL
        }?page=1&pageCount=${totalJobsCount}&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${searchQueryData}`,
      );
      if (data.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_JOBS_WITHOUT_PAGINATION, payload: data.data});
        setJobsFetchingWithoutPagination &&
          setJobsFetchingWithoutPagination(false);
      }
    } catch (error) {
      let errorMessage = '';
      setJobsFetchingWithoutPagination &&
        setJobsFetchingWithoutPagination(false);
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const resetJobCsvDownloadState = () => {
  return {
    type: GET_ALL_JOBS_WITHOUT_PAGINATION,
    payload: [],
  };
};

export const editStatusById = (id, status) => {
  return async (dispatch) => {
    dispatch({type: UPDATING_CONTENT});
    try {
      let data = await httpClient.patch(
        `${Urls.JOBS_UPDATE_STATUS_BY_ID.replace('#{jobsId}', id).replace(
          '#{jobStatus}',
          status,
        )}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: UPDATE_JOBS_STATUS});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteJobsById = (id) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.delete(
        `${Urls.JOBS_DELETE_BY_ID.replace('#{jobsId}', id)}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: DELETE_JOBS_BY_ID});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const createJobs = (
  Data,
  form,
  Navigate,
  setIsModalOpen,
  setValidFileList,
  isZeroFee,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.post(Urls.JOBS_ADD, Data);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: POST_JOBS});
        form && form.resetFields();
        setValidFileList && setValidFileList([]);
        if (setIsModalOpen) {
          setIsModalOpen(false);
          if (isZeroFee) {
            Navigate(`/paymentSuccess/jobs`);
          } else {
            Navigate(`cart/${data?.data?.data?.id}`);
          }
        } else {
          Navigate('/admin/jobs');
        }
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const editJobs = (
  Data,
  id,
  form,
  setEditingKey,
  navigate,
  setIsModalOpen,
) => {
  return async (dispatch) => {
    dispatch({type: UPDATING_CONTENT});
    try {
      let data = await httpClient.patch(
        `${Urls.JOBS_UPDATE_BY_ID.replace('#{jobsId}', id)}`,
        Data,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: UPDATE_JOBS_BY_ID, payload: data?.data});
        form && form.resetFields();
        setIsModalOpen && setIsModalOpen(false);
        navigate && navigate('/admin/jobs');
        setIsModalOpen && setIsModalOpen(false);
        setEditingKey && setEditingKey('');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
// export const editJobsAdmin = (Data, id, form, navigate) => {
//   return async (dispatch) => {
//     dispatch({type: UPDATING_CONTENT});
//     try {
//       let data = await httpClient.patch(
//         `${Urls.JOBS_UPDATE_BY_ID.replace('#{jobsId}', id)}`,
//         Data,
//       );
//       let successMessage = '';
//       if (data.status === HttpCode.Ok) {
//         successMessage = UPDATE_JOBS_SUCCESS;
//         dispatch({type: FETCH_SUCCESS});
//         dispatch({type: SHOW_MESSAGE, payload: successMessage});
//         dispatch({type: UPDATE_JOBS_BY_ID, payload: data?.data});
//         form && form.resetFields();
//         navigate && navigate('/admin/jobs');
//       }
//     } catch (error) {
//       let errorMessage = '';
//       switch (error?.response?.status) {
//         case HttpCode.NotFound:
//           errorMessage = error?.response?.data?.errorMessage;
//           break;
//         case HttpCode.BadRequest:
//           errorMessage = error?.response?.data?.errorMessage;
//           break;
//         default:
//           errorMessage = SOMETHING_WENT_WRONG;
//           break;
//       }
//       dispatch({type: FETCH_ERROR, payload: errorMessage});
//     }
//   };
// };

export const getJobsById = (id) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.JOBS_GET_BY_ID.replace('#{jobsId}', id)}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_JOBS_BY_ID, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getJobsByUserId = (
  userId,
  searchPosition,
  filterValue,
  setAllJobsData,
  page = DefaultListingViewMoreCount?.jobsListingViewMoreCount,
  setTotalItems,
  year,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.JOBS_GET_BY_USERID.replace(
          '#{userId}',
          userId,
        )}?sortField=id&sortOrder=desc&position=${
          searchPosition ? searchPosition : ''
        }&status=${
          filterValue ? filterValue : ''
        }&page=1&pageCount=${page}&year=${year}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_JOBS_BY_USERID, payload: data?.data});
        setAllJobsData(data?.data);
        setTotalItems && setTotalItems(data?.data?.data?.totalCount);
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const postJobPayment = (data, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let response = await httpClient.post(Urls.JOBS_POST_PAYMENT, data);
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: POST_JOBS_PAYMENT,
          payload: response?.data?.data,
        });
        if (response?.data?.data === null) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: response?.data?.message,
          });
          navigate && navigate('/paymentSuccess/jobs');
        }
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const postJobPaymentSuccess = (data) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let response = await httpClient.post(
        Urls.JOBS_POST_PAYMENT_SUCCESS,
        data,
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: POST_JOBS_PAYMENT_SUCCESS,
          payload: response?.data?.message,
        });
        dispatch(showMessage(response?.data?.message));
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const resetJobPayment = () => {
  return async (dispatch) => {
    dispatch({type: RESET_JOBS_PAYMENT});
  };
};

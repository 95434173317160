import React, {useState, useEffect} from 'react';
import JobsFormComponent from '../../../../Components/AdminComponents/Jobs/JobsForm';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  createJobs,
  editJobs,
  getJobsById,
} from '../../../../Store/Actions/jobs';
import {getAllJobFees} from '../../../../Store/Actions';
import {createSelectionOptionsUtil} from '../../../../Util/commonUtility';

const JobsFormContainer = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();
  const {getJobData} = useSelector(({jobs}) => jobs);
  const {allJobFees} = useSelector(({jobFees}) => jobFees);
  const [editData, setEditData] = useState();
  const [jobFeesOptions, setJobFeesOptions] = useState([]);

  useEffect(() => {
    if (getJobData) {
      setEditData(getJobData?.data);
    }
  }, [getJobData]);

  const postJobs = async (Data, form) => {
    dispatch(createJobs(Data, form, navigate, null));
  };

  const updateJobs = async (Data, id, form) => {
    dispatch(editJobs(Data, id, form, null, navigate));
  };
  const fetchJobsById = async (id) => {
    dispatch(getJobsById(id));
  };

  useEffect(() => {
    if (allJobFees) {
      setJobFeesOptions(
        createSelectionOptionsUtil(
          allJobFees?.data?.jobFees,
          'id',
          'details',
          'fee',
        ),
      );
    } else {
      dispatch(
        getAllJobFees(
          {
            page: 1,
            pagecount: 100,
          },
          '',
          '',
          '',
        ),
      );
    }
  }, [allJobFees]);
  return (
    <div>
      <JobsFormComponent
        postJobs={(Data, form) => {
          postJobs(Data);
        }}
        updateJobs={(id, Data, form) => {
          updateJobs(id, Data, form);
        }}
        fetchJobsById={(id) => {
          fetchJobsById(id);
        }}
        EditData={id ? editData : false}
        jobFeesOptions={jobFeesOptions}
      />
    </div>
  );
};

export default JobsFormContainer;

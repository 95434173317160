import React, {useEffect} from 'react';
import Event2Listing from '../../CommonComponents/EventListing2.0';
import EventListingChaptersInternational from '../../CommonComponents/EventListing/EventListingChaptersInternational';
import PageHeading from '../../CommonComponents/PageHeading';
import PageTitleCard from '../../CommonComponents/PageTitleCard';
import PbssInMap from '../../CommonComponents/PbssInCommonMap';
import pageImage from '../../../Assets/pbssIn.png';
import MyEventListing from '../../CommonComponents/MyEventListing';
import MyEventListingV2 from '../../CommonComponents/MyEventListing/index-v2';
import CommonHeroText from '../../CommonComponents/CommonHeroText';
import {Col, Row} from 'antd';
import {ApplicationString} from '../../../Localization/Locales/en';

const MyEventComponent = ({
  formattedEventData,
  setFormattedEventData,
  userCredit,
  localChapterData,
  fetchNewData,
  setFetchNewData,
  setUserCredit,
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <React.Fragment>
      {/* <PageTitleCard
        imageUrl={pageImage}
        pageTitle='Welcome to My event'
        description=' working in diverse organizations such as the biotechnology and pharmaceutical industries.'
        buttonText='Learn More'
      />
      <PageHeading
        border={false}
        title={'PBSS - My Events'}
        description={'Pharmaceutical & Bioscience Society '}
      /> */}
      {/* <MyEventListing
        isHeading={false}
        handleRefundStatus={handleRefundStatus}
        // allApprovedJobsData={allApprovedJobsData}
        // allApprovedAdsData={allApprovedAdsData}
        // handleNext={handleNext}
        // handlePrev={handlePrev}
        // handleNextAds={handleNextAds}
        // handlePrevAds={handlePrevAds}
      /> */}
      <Row gutter={[0, 30]} className='pbssMainContainerRowJobsListingPage'>
        <Col span={24}>
          <CommonHeroText
            usedForMyEvents={true}
            userCredit={userCredit}
            localChapterData={localChapterData}
            heading={ApplicationString['userMyEventsComponent.heading']}
            subheading={ApplicationString['userMyEventsComponent.subheading']}
          />
        </Col>
        <Col span={24}>
          <MyEventListingV2
            formattedEventData={formattedEventData}
            setFormattedEventData={setFormattedEventData}
            fetchNewData={fetchNewData}
            setFetchNewData={setFetchNewData}
            setUserCredit={setUserCredit}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MyEventComponent;

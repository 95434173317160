import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  SOMETHING_WENT_WRONG,
  UPDATE_STEM,
  GET_STEM,
  UPDATING_CONTENT,
} from '../../Constant/ActionTypes';
import {HttpCode} from '../../Constant/HttpCode';
import Urls from '../../Constant/Urls';
import {httpClient} from './../../Api/client';

export const editStem = (Data, id) => {
  return async (dispatch) => {
    dispatch({type: UPDATING_CONTENT});
    try {
      let data = await httpClient.patch(
        `${Urls.STEM_UPDATE_BY_ID.replace('#{stemId}', id)}`,
        Data,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: UPDATE_STEM});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const getStemById = (showMessage = false) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(Urls.STEM_GET);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        showMessage &&
          dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: GET_STEM, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

import React from 'react';
import './style.css';

const CommonInfoCard = ({title, imgSrc, isVendor, content}) => {
  return (
    <React.Fragment>
      <div className='commonInfo-details'>
        {/* <p className='card-heading'>{title}</p> */}
        {isVendor ? <button>Become a Vendor</button> : ''}
      </div>
      <div className='commonInfo-container'>
        {/* <img src={imgSrc} alt='commonInfo' className='commonInfo-image' /> */}
        {content}
      </div>
    </React.Fragment>
  );
};

export default CommonInfoCard;

import React, {useState, useEffect} from 'react';
import {ApplicationString} from '../../../Localization/Locales/en';
import {
  Button,
  Input,
  Select,
  Card,
  Alert,
  Dropdown,
  Form,
  Row,
  Col,
} from 'antd';

import './index.css';
import {SearchOutlined, FilterOutlined, PlusOutlined} from '@ant-design/icons';
import CommonJobListing from './CommonJobListing';
import dayjs from 'dayjs';
import JobsFormComponent from './JobsForm';
import {useDispatch} from 'react-redux';
import {getJobsByUserId} from '../../../Store/Actions';
import {
  AdsAndJobsOptions,
  getLocalStorageUserInfo,
  JobStatus,
} from '../../../Util/commonUtility';
import SectionLayout from './../../CommonComponents/Layout/sectionLayout';
import StatusUpdateModal from '../../CommonComponents/CommonModal';
import sponsorImg from '../../../Assets/Jobs.png';
import PageTitleCard from '../../CommonComponents/PageTitleCard';
import PageHeading from '../../CommonComponents/PageHeading';
import CommonHeroText from '../../CommonComponents/CommonHeroText';
const UserJobsComponent = (props) => {
  const dispatch = useDispatch();
  const {
    allJobsData,
    setAllJobsData,
    page,
    setPage,
    jobFeesOptions,
    zeroFeeData,
    fetchNewData,
    setFetchNewData,
  } = props;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRowJobsListingPage'>
        <Col span={24}>
          <CommonHeroText
            heading={ApplicationString['userJobsComponentPage.heading']}
            subheading={''}
          />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <span className='jobListingPageContentHeading'>
              {ApplicationString['userJobsComponent.contentHeading']}
            </span>
          </SectionLayout>
        </Col>
        <Col span={24}>
          <CommonJobListing
            allJobsData={allJobsData}
            setAllJobsData={setAllJobsData}
            page={page}
            setPage={setPage}
            zeroFeeData={zeroFeeData}
            jobFeesOptions={jobFeesOptions}
            fetchNewData={fetchNewData}
            setFetchNewData={setFetchNewData}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserJobsComponent;

import React from 'react';
import './style.css';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import {Link} from 'react-router-dom';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';

const SponsorInfoDetailsComponent = () => {
  return (
    <>
      <SponsorOptionPageHeroText title={'Sponsorship Info'} />
      <SectionLayout>
        <div className='sponsorInfoDetails-cards-wrapper'>
          <div className='sponsorInfoDetails-card'>
            <h1>Vendor Show Exhibition:</h1>
            <p>Participation in an in-person PBSS event as an exhibitor.</p>
            <p>Your registration fee includes:</p>
            <ol>
              <li>
                Vendor show tabletop space where you may display your company
                materials through the event.
              </li>
              <li>2 attendees for the workshop at a subsidized price.</li>
              <li>
                PDF Slide Deck of the presentations if the event is a workshop.
              </li>
              <li>
                Participation in a Raffle (you collect business cards from our
                attendees and participate in a drawing along with other vendors
                to giveaway any prize of your choice to the attendee).
              </li>
            </ol>
            <p>
              For further details please contact{' '}
              <a href={`mailto:info@pbss.org`}> info@pbss.org</a>
            </p>
          </div>
          <div className='sponsorInfoDetails-card'>
            <h1>Happy Hour Sponsorship:</h1>
            <p>
              Participation in an in-person PBSS event as a Happy Hour Sponsor.
            </p>
            <p>Your registration includes:</p>
            <ol>
              <li>
                Vendors show tabletop space where you may display your company
                materials through the event.
              </li>
              <li>
                PDF Slide Deck of the presentations if the event is a workshop.
              </li>
              <li>2 attendees for the workshop at a subsidized price.</li>
              <li>
                Participation in a Raffle (you collect business cards from our
                attendees and participate in a drawing along with other vendors
                to give away any prize of your choice to the attendee).
              </li>
              <li>Your sponsorship is acknowledged in the meeting agenda.</li>
              <li>
                A two-minute informal oral advert/blurb about your company and
                its offerings to our attendees during the Happy Hour.
              </li>
            </ol>
            <p>
              For further details please contact{' '}
              <a href={`mailto:info@pbss.org`}> info@pbss.org</a>
            </p>
          </div>
          <div className='sponsorInfoDetails-card'>
            <h1>Lunch Sponsorship:</h1>
            <p>Participation in an in-person PBSS event as a Lunch Sponsor.</p>
            <p>Your registration fee includes:</p>
            <ol>
              <li>
                Vendor show tabletop space where you may display your company
                materials throughout the event.
              </li>
              <li>
                PDF Slide Deck of the presentations if the event is a workshop.
              </li>
              <li>2 attendees for the workshop at a subsidized price.</li>
              <li>
                Participation in a Raffle (you collect business cards from our
                attendees and participate in a drawing along with other vendors
                to give away any prize of your choice to the attendee).
              </li>
              <li>Two free lunches for your representatives.</li>
              <li>Your sponsorship is acknowledged in the meeting agenda.</li>
              <li>
                A two-minute informal oral advert/blurb about your company and
                its offerings to our attendees during lunch.
              </li>
              <li>
                Flyer distribution to advertise your company’s offerings on the
                lunch tables before attendees come in for lunch.
              </li>
            </ol>
            <p>
              For further details please contact{' '}
              <a href={`mailto:info@pbss.org`}> info@pbss.org</a>
            </p>
          </div>
          <div className='sponsorInfoDetails-card'>
            <h1>Major Sponsorship:</h1>
            <p>Participation in an in-person PBSS event as a Major Sponsor.</p>
            <p>Your registration fee includes:</p>
            <ol>
              <li>
                Exclusive vendor show tabletop space next to the
                workshop/symposium entrance where you may display your company
                materials through the event.
              </li>
              <li>2 free workshop or symposium seats.</li>
              <li>
                PDF Slide Deck of the presentations if the event is a workshop.
              </li>
              <li>
                Participation in a Raffle (you collect business cards from our
                attendees and participate in a drawing along with other vendors
                to give away any prize of your choice to the attendee).
              </li>
              <li>Two free lunches for your representatives.</li>
              <li>
                A ten-minute oral podium slide presentation about your company
                and its offerings during the workshop/symposium.
              </li>
              <li>
                Company flyer that advertises your company’s offerings will be
                emailed to the meeting attendees and will also be included in
                the workshop slide deck for all attendees.
              </li>
              <li>
                Dinner invitation for 2 representatives to the post-meeting
                dinner for the organizers/speakers/sponsors.
              </li>
              <li>
                You will receive the meeting attendees’ names and affiliations
                (their emails may be provided if they opted in during the
                registration process).
              </li>
            </ol>
            <p>
              For further details please contact{' '}
              <a href={`mailto:info@pbss.org`}> info@pbss.org</a>
            </p>
          </div>
          <div className='sponsorInfoDetails-card'>
            <h1>Raffle Rules:</h1>
            <li>
              The raffle typically takes place at the end of the last
              break/vendor show session and before the final presentation
              session.
            </li>
            <li>
              To minimize the raffle time, each participating vendor must
              pre-draw 3 winners’ names (one as the winner and the other two as
              backups), so that the winner’s name can be quickly announced.
            </li>
            <li>
              Each vendor rep has 30 seconds to announce their name, company
              name and offerings.
            </li>
            <li>Each vendor must give the prize(s) to one single winner.</li>
            <li>A person must be present to win.</li>
          </div>
          <div>
            Please refer to the FAQs page for additional information or contact
            us <Link to='/contactUs'>here</Link>.
          </div>
        </div>
      </SectionLayout>
    </>
  );
};

export default SponsorInfoDetailsComponent;

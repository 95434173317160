import React from 'react';
import './style.css';
import {useNavigate} from 'react-router-dom';

const SponsorOptionPageHeroText = ({title, subTitle, extra}) => {
  return (
    <React.Fragment>
      <div className='hero-main-container'>
        <div className='hero-sub-container'>
          <h1 className='hero-heading'>{title}</h1>
          <p className='hero-subheading'>{subTitle}</p>
        </div>
        {extra ? <div className='extra-content'>{extra}</div> : ''}
      </div>
    </React.Fragment>
  );
};

export default SponsorOptionPageHeroText;

import React, {useEffect, useState} from 'react';
import PageHeading from '../../CommonComponents/PageHeading';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import {Button, Progress, Select, Space, Form, Upload, message} from 'antd';
import {FileOutlined, UploadOutlined} from '@ant-design/icons';
import './style.css';
import CustomCollapse from '../../CommonComponents/CommonAccordian';
import {useNavigate, useParams} from 'react-router-dom';
import {
  createSelectionOptionsUtil,
  getLocalStorageUserInfo,
} from '../../../Util/commonUtility';
import {ApplicationString} from '../../../Localization/Locales/en';

const AssetsUploadComponent = ({
  setSelectOptionsValue,
  sponsorData,
  optionInitialValue,
  allEventOptionsRegistrationData,
}) => {
  const userData = getLocalStorageUserInfo();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [eventOption, setOption] = useState();
  const [open, setOpen] = useState(false);
  const sponsorOption = createSelectionOptionsUtil(
    allEventOptionsRegistrationData,
    'id',
    'name',
    'price',
  );

  useEffect(() => {
    if (sponsorData?.id) {
      setOpen(false);
    }
  }, [eventOption]);

  useEffect(() => {
    if (optionInitialValue) {
      setOption(optionInitialValue);
    }
  }, [optionInitialValue]);

  const onSponsorSelect = (e) => {
    const selectedOption = allEventOptionsRegistrationData.find(
      (option) => option.id === e,
    );
    const updatedData = {
      ...selectedOption,
      attendee_name: userData?.first_name,
      attendee_email: userData?.email,
      sponsor_options: selectedOption?.name,
      event_option_id: selectedOption?.id,
      event_registration_options: {
        sponsorship: {
          name: selectedOption?.name,
          event_sponsorship_perk: selectedOption?.event_sponsorship_perk,
        },
      },
      total_price_per_attendee: selectedOption?.price,
      value: e,
    };
    setSelectOptionsValue(updatedData);
    setOption(updatedData);
  };

  // const submit = () => {
  //   navigate('/eventSponsor');
  // };

  const uploadPropsLogos = {
    name: 'fileLogos',
    accept: 'image/*, application/pdf',
    multiple: true,
  };
  const uploadPropsBanners = {
    name: 'fileBanners',
    accept: 'image/*, application/pdf',
    multiple: true,
  };

  const beforeUploadLogos = (file) => {
    const reader = new FileReader();
    const fileSize = file.size / 1024 / 1024;

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        // if (width !== 250 && height !== 250) {
        // message.error('Image must be 250px x 250px!');
        return Upload.LIST_IGNORE;
        // }
      };
      if (fileSize > 3) {
        message.error('File size must not exceed 3MB!');
        return Upload.LIST_IGNORE;
      }
      return true;
    };

    reader.readAsDataURL(file);
  };
  const beforeUploadBanner = (file) => {
    const reader = new FileReader();
    const fileSize = file.size / 1024 / 1024;

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        // if (width <= 500 && height <= 500) {
        //   message.error('Image must be 1080px x 1080px!');
        //   return Upload.LIST_IGNORE;
        // }
      };
      // if (fileSize > 3) {
      //   message.error('File size must not exceed 2MB!');
      //   return Upload.LIST_IGNORE;
      // }
      return true;
    };

    reader.readAsDataURL(file);
  };

  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const onFinish = (values) => {
    const updatedData = {
      ...eventOption,
      logos: values.logo,
      banners: values.flyer,
    };
    setSelectOptionsValue(updatedData);
    setOpen(false);
  };

  const conicColors = {'100%': '#207176'};

  return (
    <React.Fragment>
      {/* <SectionLayout extendClass='padding-none'>
        <Form
          form={form}
          initialValues={{event_registration_options: optionInitialValue}}>
          <Form.Item name='event_registration_options'>
            <div className='options-container'>
              <Select
                disabled={optionInitialValue ?? false}
                prefixCls={
                  optionInitialValue
                    ? 'sponsor-vendor-disabled'
                    : 'sponsor-vendor-on'
                }
                className={`sponsor-selection`}
                value={eventOption}
                options={sponsorOption}
                placeholder='Select option'
                style={{width: 250}}
                onChange={(opt) => onSponsorSelect(opt)}
              />
              {eventOption?.sponsorship_type === 3 ? (
                <Button
                  type='default'
                  disabled={optionInitialValue ?? false}
                  onClick={() => {
                    setOpen(!open);
                  }}>
                  Upload Assets <UploadOutlined />
                </Button>
              ) : (
                ''
              )}
            </div>
          </Form.Item>
        </Form>
      </SectionLayout> */}
      {open ? (
        <Form
          form={form}
          initialValues={''}
          name='eventForm'
          onFinish={onFinish}>
          <SectionLayout>
            <Space prefixCls='upload-space' wrap direction='horizontal'>
              <div className='upload-conatiner'>
                <Form.Item name='logo' className='logo-form-item w-100'>
                  <Upload.Dragger
                    {...uploadPropsLogos}
                    beforeUpload={beforeUploadLogos}
                    customRequest={dummyRequest}>
                    <div className='upload-dag-conatiner'>
                      <label>Upload Flyer</label>
                      <p className='ant-upload-drag-icon'>
                        <FileOutlined style={{color: '#207176'}} />
                      </p>
                      {/* <Progress
                        percent={100}
                        strokeColor={conicColors}
                        size='small'
                      /> */}
                      <p className='upload-text'>
                        {
                          ApplicationString[
                            'userAssetsUploadComponent.uploadConditionText'
                          ]
                        }
                      </p>
                    </div>
                  </Upload.Dragger>
                </Form.Item>
                <Form.Item name='flyer' className='logo-form-item-a w-100'>
                  <Upload.Dragger
                    className='upload-assets'
                    {...uploadPropsBanners}
                    beforeUpload={beforeUploadBanner}
                    customRequest={dummyRequest}>
                    <div className='upload-dag-conatiner'>
                      <Button>
                        <UploadOutlined />{' '}
                        {
                          ApplicationString[
                            'userAssetsUploadComponent.uploadLogoText'
                          ]
                        }{' '}
                      </Button>

                      <p>
                        {
                          ApplicationString[
                            'userAssetsUploadComponent.1080px1080px'
                          ]
                        }
                      </p>
                    </div>
                  </Upload.Dragger>
                </Form.Item>
              </div>
            </Space>
            <div className='action-assets-submit'>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  className='common-submit-login common-form-submit w-100'>
                  Continue
                </Button>
              </Form.Item>
            </div>
          </SectionLayout>
        </Form>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default AssetsUploadComponent;

import React, {useEffect, useState} from 'react';
import {Table, Button, Tag, Popover, Divider, Row, Col, Tooltip} from 'antd';
import {EyeOutlined} from '@ant-design/icons';
import {useNavigate, useParams} from 'react-router-dom';
import {
  PaginationPosition,
  ReturnNAOnEmpty,
  formatCurrency,
  getSponsorshipTypeName,
} from '../../../Util/commonUtility';
import {ApplicationString} from '../../../Localization/Locales/en';
import plus from '../../../Assets/Plus.svg';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {DescriptionItem} from './../../../Util/commonUtility';
import CustomizedTable from './../../CommonComponents/CommonTable/index';
import AdminViewDetailsDrawer from '../../CommonComponents/AdminViewDetailsDrawer';
const EventOptionsComponent = (props) => {
  const {id} = useParams();
  const [expanded, setExpanded] = useState(false);
  let navigate = useNavigate();
  const {
    allEventOptionsData,
    handlePaginationChange,
    paging,
    sortParams,
    handleTableChange,
    fetchEventOptions,
    deleteEventOptions,
  } = props;
  const [eventNameHeading, setEventNameHeading] = useState('');
  const [open, setOpen] = useState(false);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const handleSearch = (values) => {
    fetchEventOptions(values);
  };

  const handleEditClick = async (id) => {
    navigate(`/admin/eventOptions/update/${id}`);
  };

  useEffect(() => {
    if (allEventOptionsData) {
      setEventNameHeading(allEventOptionsData[0]?.event_name);
    }
  }, [allEventOptionsData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  // Drawer functions ----------------------------------------------------
  const showDrawer = (record) => {
    setOpenDrawerDetails(record);
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const EventOptionsDrawerContent = () => {
    const {
      name: eventOptionName,
      event_option_group_name: eventOptionGroupName,
      event_option_type: optionType,
      sponsorship_type,
      price,
      currency_type,
      details,
      limit,
      qty,
      used_qty,
      eventSponsorshipPerk,
      is_vendor,
    } = openDrawerDetails || {};

    return (
      <>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Event Option Name'
              content={eventOptionName}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Event Option Group Name'
              content={eventOptionGroupName}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem title='Event Option Type' content={optionType} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Price'
              content={formatCurrency(price, 'USD')}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem
              title='Currency Type'
              content={currency_type || 'N/A'}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem title='Details' content={details || 'N/A'} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem title='Limit' content={limit} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem title='Quantity' content={qty || 'N/A'} />
          </Col>
          <Col span={12}>
            <DescriptionItem title='Used Quantity' content={used_qty || 0} />
          </Col>
        </Row>
        <Divider />
        {optionType === 'sponsorship' && (
          <>
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title='Sponsorship Type'
                  content={
                    sponsorship_type
                      ? getSponsorshipTypeName(Number(sponsorship_type))
                      : 'N/A'
                  }
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title='Attendee Price'
                  content={formatCurrency(
                    eventSponsorshipPerk?.attendees_price,
                    'USD',
                  )}
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title='Sponsorship Perks'
                  content={
                    eventSponsorshipPerk?.benefits ? (
                      <CustomizedTable
                        TableData={eventSponsorshipPerk?.benefits}
                      />
                    ) : (
                      'N/A'
                    )
                  }
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title='Lunch Limit'
                  content={eventSponsorshipPerk?.free_lunch || 'N/A'}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title='Meeting Presentation'
                  content={eventSponsorshipPerk?.workshop_attendees || 'N/A'}
                />
              </Col>
            </Row>

            <Divider />
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title='Dinner with Speaker'
                  content={eventSponsorshipPerk?.dinner_with_speaker || 'N/A'}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title='Dinner with Speaker Price'
                  content={
                    formatCurrency(
                      eventSponsorshipPerk?.dinner_with_speaker_price,
                      'USD',
                    ) || 'N/A'
                  }
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title='Sponsor Contact Content'
                  content={
                    eventSponsorshipPerk?.sponsor_contact_content || 'N/A'
                  }
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title='Sponsor Perk Content'
                  content={eventSponsorshipPerk?.sponsor_perk_content || 'N/A'}
                />
              </Col>
            </Row>
          </>
        )}

        {optionType === 'registration' && (
          <>
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title='Vendor'
                  content={is_vendor ? 'Yes' : 'No'}
                />{' '}
              </Col>
            </Row>
            <Divider />
          </>
        )}
      </>
    );
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '2%',
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '4%',
      sorter: true,
      render: (name) => {
        return <div>{name}</div>;
      },
    },
    {
      title: 'Event Option Type',
      dataIndex: 'event_option_type',
      sorter: true,
      width: '3%',
      render: (text) => {
        return <span>{text.toUpperCase()}</span>;
      },
    },
    {
      title: 'Sponsorship Type',
      dataIndex: 'sponsorship_type',
      key: 'sponsorship_type',
      ellipsis: true,
      width: '3%',
      render: (text) => {
        return (
          <span>
            {text ? getSponsorshipTypeName(Number(text)).toUpperCase() : 'NA'}
          </span>
        );
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      width: '3%',
      sorter: true,
      render: (text) => {
        return formatCurrency(text, 'USD');
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      width: '3%',
      sorter: true,
    },
    {
      title: 'Used Quantity',
      dataIndex: 'used_qty',
      width: '3%',
      sorter: true,
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      width: '1%',
      render: (_, record) => (
        <AdminActionsDropdown
          record={record}
          editBool={true}
          viewBool={true}
          handleView={showDrawer}
          deleteBool={true}
          handleEditClick={handleEditClick}
          handleDeleteClick={deleteEventOptions}
          text='Event Option'
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });
  return (
    <div className='main-container'>
      <AdminViewDetailsDrawer
        content={EventOptionsDrawerContent}
        visible={open}
        text={'Event Option'}
        onCloseDrawer={onCloseDrawer}
        editNavigate={`/admin/eventOptions/update/${openDrawerDetails?.id}`}
      />
      <div className='search-container' style={{gap: '10px'}}>
        <Button onClick={() => navigate('/admin/events')} id='back-to-events'>
          {ApplicationString['common.button.backToEvents']}
        </Button>
        <Button
          type='primary'
          onClick={() => navigate(`/admin/eventOptions/add/${id}`)}
          id='EventOptionsGroupComponent-button-addJobs'
          className='common-button'>
          <div className='icon-container'>
            <img src={plus} alt='plus' className='icon-plus' />
            <span className='add-content'>
              {ApplicationString['eventOptionsComponent.button.addForm']}
            </span>
          </div>
        </Button>
      </div>
      {/* {expanded && (
        <DynamicSearchForm columns={columns} onSearch={handleSearch} />
      )} */}

      <div className='listing-container'>
        <Table
          className='admin-table'
          columns={columns}
          dataSource={allEventOptionsData}
          rowKey={'id'}
          scroll={{x: columns?.length * 140}}
          onChange={handleTableChange}
          sticky={true}
          pagination={{
            // onChange: (page, pageSize) => {
            //   handlePaginationChange(page, pageSize);
            // },
            // current: paging.page,
            // total: paging.totalcount,
            // pageSize: paging.pagecount,
            // pageSizeOptions: PageSizeArray,
            // showSizeChanger: true,
            // onShowSizeChange: (page, pageSize) => {
            //   handlePaginationChange(page, pageSize);
            // },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default EventOptionsComponent;

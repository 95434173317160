import React from 'react';
import SpecialPaymentContainer from '../../../Containers/UserContainer/SpecialPayment';

const SpecialPaymentScreen = () => {
  return (
    <React.Fragment>
      <SpecialPaymentContainer />
    </React.Fragment>
  );
};

export default SpecialPaymentScreen;

import React, {useEffect, useState} from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import KeywordComponent from '../../../Components/AdminComponents/Keywords';
import {
  deleteKeywordById,
  getAllKeywords,
  getAllKeywordsByQuery,
  getUserDetails,
  updateKeywordById,
} from '../../../Store/Actions';
import {useDispatch, useSelector} from 'react-redux';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';
import {useNavigate} from 'react-router-dom';
const KeywordContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {allKeywordsQueryData} = useSelector(({keywords}) => keywords);
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.keywords,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.keywords,
  );

  const [listData, setListData] = useState();
  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pageCount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  const searchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.keywords,
  );

  useEffect(() => {
    if (allKeywordsQueryData.forMaster) {
      setListData(allKeywordsQueryData.forMaster?.data?.keywords);
      setPagination({
        ...pagination,
        page: allKeywordsQueryData.forMaster?.data?.currentPage,
        totalPages: allKeywordsQueryData.forMaster?.data?.totalPage,
        totalCount: allKeywordsQueryData.forMaster?.data?.totalCount,
      });
    } else {
      fetchKeyword();
    }
  }, [allKeywordsQueryData.forMaster?.data?.keywords]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchKeyword(searchValues);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  // useEffect(() => {
  //   if (allKeywordsQueryData.forMaster) {
  //     fetchKeyword();
  //   }
  // }, [
  //   pagination.page,
  //   pagination.pageCount,
  //   sortParams.field,
  //   sortParams.order,
  // ]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  useEffect(() => {
    if (searchValues) {
      dispatch(setPageNumber('keywords', 1));
      setPagination({
        ...pagination,
        page: 1,
      });
      setPaginationAndSortChanged(true);
    }
  }, [searchValues]);

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };
  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('keywords', pageSize));
    dispatch(setPageNumber('keywords', current));

    setPagination({
      ...pagination,
      page: current,
      pageCount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };
  const fetchKeyword = async (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllKeywordsByQuery(sortField, sortOrder, searchQueryData, pagination),
    );
  };

  const deleteKeyword = async (id) => {
    dispatch(deleteKeywordById(id));
  };

  const updateKeyword = async (Data, id) => {
    dispatch(updateKeywordById(Data, id, navigate));
  };

  return (
    <div>
      <KeywordComponent
        allKeywordData={listData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        deleteKeyword={(id) => {
          deleteKeyword(id);
        }}
        handleTableChange={handleTableChange}
        fetchKeyword={(searchValues) => {
          fetchKeyword(searchValues);
        }}
        updateKeyword={(id, Data) => {
          updateKeyword(id, Data);
        }}
        sortParams={sortParams}
        searchValues={searchValues}
      />
    </div>
  );
};
export default KeywordContainer;

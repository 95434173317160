import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './Store/store';
import {AxiosInterceptor, setAuthToken} from './Api/client';
import JWTAuthAuthProvider from './Api/JWTAuthProvider';
const root = ReactDOM.createRoot(document.getElementById('root'));

const token = localStorage.getItem('token');
if (token) {
  setAuthToken(token);
}
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <JWTAuthAuthProvider>
          <AxiosInterceptor>
            <App />
          </AxiosInterceptor>
        </JWTAuthAuthProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

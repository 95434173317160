import {
  ADD_KEYWORD_SUCCESS,
  DELETE_KEYWORD_BY_ID,
  FETCH_KEYWORD_BY_ID,
  GET_ALL_KEYWORDS,
  UPDATE_KEYWORD_BY_ID,
  GET_ALL_KEYWORDS_BY_QUERY,
  GET_ALL_KEYWORDS_BY_QUERY_SEARCH,
} from '../../Constant/ActionTypes';

const initialState = {
  allKeywords: false,
  allKeywordsQueryData: {
    forSearch: false,
    forMaster: false,
  },
  isCreateKeyword: false,
  isUpdateKeyword: false,
  fetchKeywordData: false,
};

const KeywordReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_KEYWORDS:
      return {
        ...state,
        allKeywords: action.payload,
        isCreateKeyword: false,
        isUpdateKeyword: false,
      };
    case GET_ALL_KEYWORDS_BY_QUERY:
      return {
        ...state,
        allKeywordsQueryData: {
          ...state.allKeywordsQueryData,
          forMaster: action.payload,
        },
        isCreateKeyword: false,
        isUpdateKeyword: false,
      };
    case GET_ALL_KEYWORDS_BY_QUERY_SEARCH:
      return {
        ...state,
        allKeywordsQueryData: {
          ...state.allKeywordsQueryData,
          forSearch: action.payload,
        },
        isCreateKeyword: false,
        isUpdateKeyword: false,
      };
    case FETCH_KEYWORD_BY_ID:
      return {
        ...state,
        fetchKeywordData: action.payload,
      };
    case ADD_KEYWORD_SUCCESS:
      return {
        ...state,
        isCreateKeyword: true,
        allKeywordsQueryData: false,
        allKeywords: false,
      };
    case UPDATE_KEYWORD_BY_ID:
      return {
        ...state,
        isUpdateKeyword: true,
        allKeywordsQueryData: false,
        allKeywords: false,
      };
    case DELETE_KEYWORD_BY_ID:
      return {
        ...state,
        allKeywords: false,
        allKeywordsQueryData: false,
      };
    default:
      return state;
  }
};

export default KeywordReducer;

import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  createCouponCodes,
  editCouponCodes,
  getCouponCodesById,
} from '../../../../Store/Actions';
import {createSelectionOptionsUtil} from '../../../../Util/commonUtility';
import CouponCodesFormComponent from '../../../../Components/AdminComponents/CouponCodes/CouponCodesForm';
import {
  getAllEvents,
  getAllEventsByQueryString,
  getAllEventsByQueryStringSearch,
} from '../../../../Store/Actions/events';
const CouponCodesFormContainer = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {allEventsQueryData} = useSelector(({events}) => events);
  const {getCouponCodesData} = useSelector(({couponCodes}) => couponCodes);
  const [editData, setEditData] = useState();
  const [eventsList, setEventsList] = useState([]);
  const [fetching, setFetching] = useState(false);
  const {id} = useParams();

  useEffect(() => {
    if (allEventsQueryData.forSearch) {
      setEventsList(
        createSelectionOptionsUtil(
          allEventsQueryData.forSearch?.data?.events,
          'id',
          'name',
        ),
      );
      setFetching(false);
    } else {
      dispatch(
        getAllEventsByQueryStringSearch(
          {
            page: 1,
            pagecount: 10,
          },
          '',
          '',
          '',
        ),
      );
    }
  }, [allEventsQueryData.forSearch]);

  useEffect(() => {
    if (getCouponCodesData) {
      setEditData(getCouponCodesData?.data);
    }
  }, [getCouponCodesData]);

  const postCouponCodes = async (Data) => {
    dispatch(createCouponCodes(Data, navigate));
  };

  const updateCouponCodes = async (Data, id) => {
    dispatch(editCouponCodes(Data, id, navigate));
  };
  const fetchCouponCodesById = async (id) => {
    dispatch(getCouponCodesById(id));
  };
  return (
    <div>
      <CouponCodesFormComponent
        postCouponCodes={(Data) => {
          postCouponCodes(Data);
        }}
        updateCouponCodes={(Data, id) => {
          updateCouponCodes(Data, id);
        }}
        fetchCouponCodesById={(id) => {
          fetchCouponCodesById(id);
        }}
        EditData={editData}
        eventsList={eventsList}
        setEventsList={setEventsList}
        fetching={fetching}
        setFetching={setFetching}
      />
    </div>
  );
};

export default CouponCodesFormContainer;

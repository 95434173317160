import React from 'react';
import './index.css';
import {DatePicker, Space, Modal, Button, Divider, Form} from 'antd';
import {CSVLink} from 'react-csv';
const {RangePicker} = DatePicker;

function CommonAccountModal({
  visible,
  onCancel,
  text,
  submitText,
  formattedData,
  headers,
  handleCsvSubmit,
  downloadCsvBool,
}) {
  const [form] = Form.useForm();

  return (
    <Space direction='vertical' size={12}>
      <Modal
        open={visible}
        onCancel={
          () => onCancel(form)
          // form.resetFields();
        }
        title={`Download ${text} CSV`}
        footer={false}>
        <Divider />
        <Form
          form={form}
          name={`${text}-csv`}
          initialValues={''}
          layout='vertical'
          className='two-column-form'
          autoComplete='off'
          onFinish={handleCsvSubmit}>
          <Form.Item
            label={'Select Date Range For Downloading CSV'}
            name={'date'}
            rules={[
              {
                required: true,
                message: 'Please select Date Range',
              },
            ]}>
            <RangePicker showTime className='common-input-user genral-form' />
          </Form.Item>

          <div className='update-footer'>
            <Button
              id={`${text}-cancelAction-button`}
              key='cancel'
              onClick={() => onCancel(form)}>
              Cancel
            </Button>
            <Button
              id={`${text}-submitAction-button`}
              key='update'
              type='primary'
              className='common-button'
              htmlType='submit'>
              {downloadCsvBool ? (
                <>
                  <CSVLink
                    data={formattedData}
                    headers={headers}
                    filename={`${text}_Report.csv`}>
                    {submitText ? submitText : 'Submit'}
                  </CSVLink>
                  {form.resetFields()}
                </>
              ) : submitText ? (
                submitText
              ) : (
                'Submit'
              )}
            </Button>
          </div>
        </Form>
      </Modal>
    </Space>
  );
}

export default CommonAccountModal;

import React from 'react';
import {ApplicationString} from '../../../Localization/Locales/en';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import {Col, Row} from 'antd';
import PastEventsListing from '../../CommonComponents/PastEventsListing';
import Event2Listing from '../../CommonComponents/EventListing2.0';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';

const SeminarLuncheonsComponent = () => {
  return (
    <Row gutter={[0, 26]}>
      <Col span={24}>
        <div className='eventTypesStaticPageCard'>
          <SponsorOptionPageHeroText
            title={
              <span className='eventTypesStaticPageCardHeading'>
                {ApplicationString['userSeminarLuncheonsComponent.heading']}
              </span>
            }
          />
          <SectionLayout>
            <p>{ApplicationString['userSeminarLuncheonsComponent.p1']}</p>
            <br />
            <p>{ApplicationString['userSeminarLuncheonsComponent.p2']}</p>
            <br />
            <p>
              For information on the upcoming seminar luncheons,{' '}
              <a
                href={`http://www.pbss.org/aspx/eventsall.aspx?eType=0`}
                target='_blank'
                rel='noopener noreferrer'>
                click here
              </a>
            </p>
          </SectionLayout>
        </div>
      </Col>
    </Row>
  );
};

export default SeminarLuncheonsComponent;

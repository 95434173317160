import React from 'react';
import './updateHistory.css';
import {Divider, Dropdown, Menu} from 'antd';
import moment from 'moment';
import {HistoryOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

const UpdateHistoryTableInfo = ({userInfo, hideBool = false, text}) => {
  const {userDetails} = useSelector(({users}) => users);

  const createUser = userInfo.create_by_id;
  const created = moment(userInfo.created_at).format('DD MMM, hh:mm A');
  const updateUser = userInfo.edit_by_id;
  const update = moment(userInfo.updated_at).format('DD MMM, hh:mm A');

  const editedData = userDetails?.data?.filter(
    (item) => item.id === createUser,
  );
  const updatedData = userDetails?.data?.filter(
    (item) => item.id === updateUser,
  );

  return (
    <Dropdown
      overlay={
        <Menu className='main-container-historypopup'>
          <div className='history-Info-container'>
            <div className='inner-info-user'>
              <div>
                <p className='user-info-heading'>Created</p>
                {createUser && editedData && editedData[0]?.first_name && (
                  <div className='user-list-info createUser'>
                    {editedData[0]?.first_name}
                  </div>
                )}
                <span className='user-list-info create'>{`🕓 ${created}`}</span>
              </div>
              <Divider className='label-divider' />
              <div>
                <p className='user-info-heading updateHeading'>Updated</p>
                {updateUser && updatedData && updatedData[0]?.first_name && (
                  <div className='user-list-info createUser'>
                    {updatedData[0]?.first_name}
                  </div>
                )}
                <span className='user-list-info create'>{`🕓 ${update}`}</span>
              </div>
            </div>
          </div>
        </Menu>
      }>
      <div className='historyUpdateButton'>
        <div className='icon-container'>
          <HistoryOutlined /> {`${hideBool ? `${text} History` : ''}`}
        </div>
        <span>
          <div className='Button-show-history'></div>
        </span>
      </div>
    </Dropdown>
  );
};

export default UpdateHistoryTableInfo;

import React, {useEffect, useState} from 'react';
import {Space, Table, Popconfirm, Button, Tooltip} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {PageSizeArray, PaginationPosition} from '../../../Util/commonUtility';
import {ApplicationString} from '../../../Localization/Locales/en';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import editIcon from '../../../Assets/editIcon.svg';
import deleteIcon from '../../../Assets/deleteIcon.svg';
import plus from '../../../Assets//Plus.svg';
import UpdateHistoryTableInfo from '../HistoryView';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {useSelector} from 'react-redux';

const AdFeesComponent = (props) => {
  const [expanded, setExpanded] = useState(false);
  const {
    allAdFeesData,
    paging,
    handlePaginationChange,
    deleteAdFee,
    fetchAdFee,
    sortParams,
    handleTableChange,
  } = props;
  const ps = useSelector(({pageSizes}) => pageSizes.pageSizes.adFees);
  let navigate = useNavigate();
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const handleSearch = (values) => {
    fetchAdFee(values);
  };

  const handleEditClick = async (id) => {
    navigate(`/admin/adfees/update/${id}`);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 60,
    },
    {
      title: 'Fee',
      dataIndex: 'fee',
    },
    {
      title: 'Details',
      dataIndex: 'details',
    },
    {
      title: '',
      key: 'action',
      width: '6%',
      render: (_, record) => (
        <AdminActionsDropdown
          record={record}
          editBool={true}
          deleteBool={true}
          handleEditClick={handleEditClick}
          handleDeleteClick={deleteAdFee}
          text='Ad Fee'
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sorter = true;
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });
  return (
    <div className='main-container'>
      <div className='search-container' style={{gap: '10px'}}>
        {/* <Button onClick={toggleExpand} id='AdFeeComponent-button-filterSearch'>
          {expanded ? <FilterOutlined /> : <FilterOutlined />}{' '}
          {ApplicationString['dynamicSearch.button.heading']}
        </Button> */}
        <Button
          type='primary'
          onClick={() => navigate('/admin/adfees/add')}
          id='AdFeeComponent-button-addAdFees'
          className='common-button'>
          <div className='icon-container'>
            <img src={plus} alt='plus' className='icon-plus' />
            <span className='add-content'>
              {ApplicationString['adFeesComponent.button.addForm']}
            </span>
          </div>
        </Button>
      </div>
      {/* {expanded && (
        <DynamicSearchForm columns={columns} onSearch={handleSearch} />
      )} */}
      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          columns={columns}
          dataSource={allAdFeesData}
          rowKey={'id'}
          scroll={{x: 786}}
          onChange={handleTableChange}
          // sticky={true}
          pagination={{
            onChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            current: paging.page,
            total: paging.totalCount,
            pageSize: ps || paging.pagecount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default AdFeesComponent;

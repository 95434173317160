import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SOMETHING_WENT_WRONG,
  SHOW_MESSAGE,
  UPDATING_CONTENT,
  GET_ALL_EVENTOPTIONGROUPS,
  GET_ALL_EVENTOPTIONGROUPS_BY_QUERY,
  DELETE_EVENTOPTIONGROUPS_SUCCESS,
  DELETE_EVENTOPTIONGROUPS_BY_ID,
  POST_EVENTOPTIONGROUPS_SUCCESS,
  POST_EVENTOPTIONGROUPS,
  UPDATE_EVENTOPTIONGROUPS_SUCCESS,
  UPDATE_EVENTOPTIONGROUPS_BY_ID,
  GET_EVENTOPTIONGROUPS_BY_ID,
} from '../../Constant/ActionTypes';
import {commonSortOrder, handleJwtExpireMsg} from '../../Util/commonUtility';
import Urls from '../../Constant/Urls';
import {httpClient} from '../../Api/client';

export const getAllEventOptionGroups = () => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(Urls.EVENTOPTIONGROUPS_GET_ALL);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_EVENTOPTIONGROUPS, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllEventOptionGroupsByQueryString = (
  pagination,
  sortField,
  sortOrder,
  searchQueryData,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.EVENTOPTIONGROUPS_GET_ALL}?page=${pagination.page}&pageCount=${
          pagination.pagecount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${searchQueryData}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: GET_ALL_EVENTOPTIONGROUPS_BY_QUERY,
          payload: data?.data,
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteEventOptionGroupsById = (id) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.delete(
        `${Urls.EVENTOPTIONGROUPS_DELETE_BY_ID.replace(
          '#{eventOptionGroupsId}',
          id,
        )}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: DELETE_EVENTOPTIONGROUPS_BY_ID});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const createEventOptionGroups = (Data, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.post(Urls.EVENTOPTIONGROUPS_ADD, Data);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: POST_EVENTOPTIONGROUPS});
        navigate('/admin/eventOptionGroups');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const editEventOptionGroups = (Data, id, navigate) => {
  return async (dispatch) => {
    dispatch({type: UPDATING_CONTENT});
    try {
      let data = await httpClient.patch(
        `${Urls.EVENTOPTIONGROUPS_UPDATE_BY_ID.replace(
          '#{eventOptionGroupsId}',
          id,
        )}`,
        Data,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: UPDATE_EVENTOPTIONGROUPS_BY_ID});
        navigate('/admin/eventOptionGroups');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getEventOptionGroupsById = (id) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.EVENTOPTIONGROUPS_GET_BY_ID.replace(
          '#{eventOptionGroupsId}',
          id,
        )}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_EVENTOPTIONGROUPS_BY_ID, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

import React, {useEffect, useState} from 'react';
import {
  Button,
  Checkbox,
  Col,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Spin,
} from 'antd';
import './add.css';
import {useNavigate} from 'react-router-dom';
import {InfoCircleOutlined} from '@ant-design/icons';
import {
  commonSelectAll,
  validatePhoneNumber,
  maxTagPlaceholder,
  tagRender,
  maxTagPlaceholderKeyword,
  NoEmptySpaceInput,
  OnlyAlphabetInputValidator,
  validateEmailInput,
  queryString,
  validateCompanyName,
  OnlyAlphabetInputValidatorWithSpecialCharacters,
} from '../../../Util/commonUtility';
import CommonPageHeroText from './../../CommonComponents/CommonPageHeroText/index';
import {getAllKeywordsByQuerySearch} from '../../../Store/Actions';
import {useDispatch} from 'react-redux';

const UserUpdateProfileComponent = (props) => {
  const [form] = Form.useForm();
  let dispatch = useDispatch();
  let Navigate = useNavigate();
  const {
    userData,
    keywordsList,
    chaptersList,
    updateUser,
    fetching,
    setFetching,
    SetKeywordsOptions,
    keywordsOptions,
  } = props;
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const keywordDataArray = keywordsList?.map((keyword) => keyword?.id);
  const [tags, setTags] = useState([]);
  const [checkboxState, setCheckboxState] = useState([]);
  const [checkboxKeywordsState, setCheckboxKeywordsState] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        ...userData,
        is_vendor_reps: userData?.is_vendor_reps
          ? userData?.is_vendor_reps
          : false,
        confirm_email: userData?.email,
        local_chapter_id: {
          ...userData?.local_chapter_id,
          label: userData?.local_chapter_id?.name,
          value: userData?.local_chapter_id?.id,
        },
        mail_preference: userData?.mail_preference?.map((item, index) => {
          return {
            ...item,
            label: item?.name,
            value: item?.id,
          };
        }),
        user_keywords: userData?.keywords?.map((item, index) => {
          return {
            ...item,
            label: item?.name,
            value: item?.id,
          };
        }),
      });
      setTags(
        userData?.area_of_interest !== '' &&
          userData?.area_of_interest?.split(','),
      );
      setSelectedKeywords(
        userData?.keywords?.map((item) => {
          return item?.id;
        }),
      );
    }
  }, [userData]);

  const onReset = () => {
    Navigate('/');
  };

  const handleKeywordChange = (value) => {
    setSelectedKeywords(value);
    setCheckboxKeywordsState(value);
  };

  const handleKeywordSearch = (value) => {
    SetKeywordsOptions([]);
    setFetching(true);
    const Data = {
      name: value,
    };
    const searchQueryData = queryString(Data);
    dispatch(
      getAllKeywordsByQuerySearch('', '', searchQueryData, {
        page: 1,
        pageCount: 10,
      }),
    );
  };

  const handleKeywordAllowClear = () => {
    dispatch(
      getAllKeywordsByQuerySearch('', '', '', {
        page: 1,
        pageCount: 10,
      }),
    );
  };

  const handleKeywordMouseLeave = () => {
    if (keywordsOptions?.length === 0) {
      dispatch(
        getAllKeywordsByQuerySearch('', '', '', {
          page: 1,
          pageCount: 10,
        }),
      );
    }
  };

  const onFinish = (values) => {
    const MailPrefArray = values.mail_preference?.map((chapter) => {
      return chapter?.value || chapter;
    });
    const Data = {
      ...values,
      phone_number: values?.phone_number ? values?.phone_number : null,
      mail_preference: MailPrefArray,
      user_keywords: selectedKeywords || [],
      local_chapter_id:
        values?.local_chapter_id?.value || values?.local_chapter_id,
    };
    updateUser(Data, userData?.id, Navigate);
  };

  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };
  // const handleSelectKeywordChange = (value) => {
  //   const selectAllData = keywordsList?.filter((data) => data?.value !== 'all');
  //   if (value.includes('all')) {
  //     form.setFieldsValue({
  //       area_of_interest: selectAllData,
  //     });
  //   }
  // };
  const handleSelectMailChange = (value) => {
    const selectAllData = chaptersList
      ?.filter((item) => item?.value !== 'all')
      ?.map((item) => item?.value);
    if (value.includes('all')) {
      setCheckboxState([...selectAllData, 'all']);
      form.setFieldsValue({
        mail_preference: selectAllData,
      });
    } else {
      setCheckboxState(value);
    }
  };

  const handleMailPrefCheckboxChange = (value, checked) => {
    const selectedValues = chaptersList
      ?.filter((item) => item?.value !== 'all')
      ?.map((item) => item?.value);
    if (checked) {
      if (value == 'all') {
        setCheckboxState([...selectedValues, 'all']);
      } else {
        setCheckboxState([...checkboxState, value]);
      }
    } else {
      if (value == 'all') {
        setCheckboxState([]);
        form.setFieldsValue({
          mail_preference: [],
        });
      } else {
        setCheckboxState(checkboxState.filter((item) => item !== value));
      }
    }
  };
  const handleKeywordCheckboxChange = (value, checked) => {
    if (checked) {
      setCheckboxKeywordsState([...checkboxKeywordsState, value]);
    } else {
      setCheckboxKeywordsState(
        checkboxKeywordsState.filter((item) => item !== value),
      );
    }
  };

  const backTOusers = () => {
    Navigate('/');
  };

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  function TagGroup({onClearAll, onSelectAll, usedForChap}) {
    return (
      <div className='tag-group-container'>
        <span onClick={onSelectAll} style={{marginLeft: 8}}>
          <Button
            prefixCls={`tag-render ${usedForChap && 'chapter-button'} select`}>
            <span className='tag-render-text'>Select all</span>
          </Button>
        </span>
        <span onClick={onClearAll} style={{marginLeft: 8}}>
          <Button
            prefixCls={`tag-render ${usedForChap && 'chapter-button'} clear`}>
            <span className='tag-render-text'>Clear</span>
          </Button>
        </span>
      </div>
    );
  }

  return (
    <React.Fragment>
      <CommonPageHeroText
        heading={'Update Profile'}
        subheading={'Pharmaceutical & Bioscience Society '}
      />
      <div className='main-form-container-update-profile'>
        <Form
          form={form}
          name='basic'
          layout='vertical'
          onFinish={onFinish}
          className='two-column-form-update-profile'
          onFinishFailed={onFinishFailed}
          initialValues={''}
          autoComplete='off'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='First Name'
                name='first_name'
                rules={[
                  {
                    required: true,
                    message: 'First name should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidatorWithSpecialCharacters(
                        value,
                        'First name',
                      ),
                  },
                ]}>
                <Input
                  prefixCls='update-profile-common-input'
                  className='genral-form'
                  placeholder='Enter your first name'
                />
              </Form.Item>

              <Form.Item
                label='Title'
                initialValue={null}
                name='title'
                rules={[
                  {
                    validator: (_, value) => NoEmptySpaceInput(value, 'Title'),
                  },
                ]}>
                <Input
                  prefixCls='update-profile-common-input'
                  className='genral-form'
                  placeholder='Enter your title'
                />
              </Form.Item>
              <Form.Item
                label='Address'
                name='address'
                rules={[
                  {
                    required: false,
                    message: 'Address should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Address'),
                  },
                ]}>
                <Input
                  prefixCls='update-profile-common-input'
                  className='genral-form'
                  placeholder='Enter your address'
                />
              </Form.Item>
              <Form.Item
                label='State'
                name='state'
                rules={[
                  {
                    required: true,
                    message: 'State should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidator(value, 'State'),
                  },
                ]}>
                <Input
                  prefixCls='update-profile-common-input'
                  className='genral-form'
                  placeholder='Enter your state'
                />
              </Form.Item>
              <Form.Item
                label='Country'
                name='country'
                rules={[
                  {required: true, message: 'Country should not be empty!'},
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidator(value, 'Country'),
                  },
                ]}>
                <Input
                  prefixCls='update-profile-common-input'
                  className='genral-form'
                  placeholder='Enter your country name'
                />
              </Form.Item>
              <Form.Item
                name='local_chapter_id'
                label='Select Your Local Default Chapter'
                rules={[
                  {
                    required: true,
                    message: 'Local chapter should not be empty!',
                  },
                ]}>
                <Select
                  prefixCls='common-select-register'
                  showSearch
                  className='space'
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  placeholder={'Select your default chapter'}
                  options={chaptersList}></Select>
              </Form.Item>
            </div>
            <div className='form-column'>
              <Form.Item
                label='Last Name'
                name='last_name'
                rules={[
                  {
                    required: true,
                    message: 'Last name should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidatorWithSpecialCharacters(
                        value,
                        'Last name',
                      ),
                  },
                ]}>
                <Input
                  prefixCls='update-profile-common-input'
                  className='genral-form'
                  placeholder='Enter your last name'
                />
              </Form.Item>
              <Form.Item
                label='Company Name/Affiliation'
                name='company'
                rules={[
                  {
                    required: true,
                    message: 'If you are in between jobs, please enter N/A!',
                  },
                  {
                    validator: (_, value) =>
                      validateCompanyName(value, 'Company name'),
                  },
                ]}>
                <Input
                  prefixCls='update-profile-common-input'
                  className='genral-form'
                  placeholder='Enter your company name or academic affiliation.'
                />
              </Form.Item>
              <Form.Item
                label='City'
                name='city'
                rules={[
                  {
                    required: true,
                    message: 'City should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidator(value, 'City'),
                  },
                ]}>
                <Input
                  prefixCls='update-profile-common-input'
                  className='genral-form'
                  placeholder='Enter your city name'
                />
              </Form.Item>
              <Form.Item
                label='Postal Code'
                name='postal_code'
                rules={[
                  {
                    required: true,
                    message: 'Postal code should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Postal code'),
                  },
                ]}>
                <Input
                  prefixCls='update-profile-common-input'
                  className='genral-form'
                  placeholder='Enter your postal code'
                />
              </Form.Item>
              <Form.Item
                label='Phone Number'
                name='phone_number'
                rules={[
                  {
                    validator: (_, value) =>
                      validatePhoneNumber(value, 'Phone number'),
                  },
                ]}>
                <Input
                  prefixCls='update-profile-common-input'
                  className='genral-form'
                  placeholder='Enter your phone number'
                />
              </Form.Item>
              <Form.Item
                label='Notes'
                name='notes'
                rules={[
                  {
                    validator: (_, value) => NoEmptySpaceInput(value, 'Notes'),
                  },
                ]}>
                <Input
                  prefixCls='update-profile-common-input'
                  className='genral-form'
                  placeholder='Enter your notes'
                />
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label={
                  <div className='detailLabelAndTextMessageContainer'>
                    <span className='detailsLabel'>Email</span>
                    <span className='detailsTextMessage'>
                      <InfoCircleOutlined /> Contact us to change your email on
                      the account,
                      <a href='mailto:info@pbss.org'> info@pbss.org </a>
                    </span>
                  </div>
                }
                name='email'
                initialValue={null}
                rules={[
                  {
                    required: true,
                    message: 'Email should not be empty',
                  },
                  {
                    validator: (_, value) => validateEmailInput(value, 'Email'),
                  },
                ]}>
                <Input
                  prefixCls='update-profile-common-input'
                  className='genral-form'
                  placeholder='Enter your email'
                  disabled={true}
                />
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                name='mail_preference'
                label='Select a PBSS Chapter(s) to receive its announcements'>
                <Select
                  id='basic-selection-chapters'
                  prefixCls='common-select-register'
                  mode='multiple'
                  showSearch
                  allowClear
                  maxTagCount={'responsive'}
                  maxTagPlaceholder={maxTagPlaceholder}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  placeholder={'Select chapter(s)'}
                  onChange={handleSelectMailChange}
                  tagRender={tagRender}
                  defaultValue={
                    chaptersList?.filter((data) => data?.value !== 'all')?.value
                  }
                  options={
                    (chaptersList &&
                      commonSelectAll(chaptersList)?.map((item) => ({
                        label: (
                          <Checkbox
                            checked={checkboxState.includes(item.value)}
                            onChange={(e) =>
                              handleMailPrefCheckboxChange(
                                item.value,
                                e.target.checked,
                              )
                            }>
                            {item.label}
                          </Checkbox>
                        ),
                        value: item.value,
                      }))) ||
                    []
                  }></Select>
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Select Area(s) of Interest'
                name='user_keywords'>
                <Select
                  prefixCls='common-select-register'
                  mode='multiple'
                  showSearch
                  maxTagCount={'responsive'}
                  maxTagPlaceholder={maxTagPlaceholderKeyword}
                  allowClear
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.label
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  }
                  onChange={handleKeywordChange}
                  onClear={handleKeywordAllowClear}
                  onMouseLeave={handleKeywordMouseLeave}
                  onSearch={handleKeywordSearch}
                  notFoundContent={
                    fetching ? (
                      <Spin size='small' />
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  tagRender={tagRender}
                  placeholder={'Select area(s) of interest'}
                  options={keywordsOptions}></Select>

                {/* <div className='keyword-container-admin'>
                  <div className='keyword-label-container '>
                    <div className='keyword-label'>
                      Select Areas of Interest
                    </div>
                    <div>
                      <TagGroup
                        onClearAll={handleKeywordClearAll}
                        onSelectAll={handleKeywordSelectAll}
                      />
                    </div>
                  </div>

                  <div className='keyword-render-container'>
                    {keywordsList?.map((item, index) => {
                      return (
                        <div key={index} className='keyword-render admin'>
                          <div className='keyword-name'>{item?.label}</div>
                          <div>
                            <Checkbox
                              prefixCls='keyword-checkbox'
                              valuePropName='checked'
                              checked={
                                Array.isArray(selectedKeywords) &&
                                selectedKeywords.includes(item.id)
                              }
                              onChange={(e) =>
                                handleKeywordChange(e, item.id)
                              }></Checkbox>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div> */}
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='You can add other interest in following textbox , use comma
                    for separation of multiple options'
                name='area_of_interest'
                rules={[
                  {
                    required: false,
                    message: 'Area of interest should not be empty!',
                  },
                  {
                    validator: (_, value) => {
                      const regex = /\s{4,}/g;
                      // added extra validation cause it was causing second error message from antd
                      if (value && value?.length <= 0) {
                        return Promise.reject(new Error(''));
                      }
                      if (value && value.trim() === '') {
                        return Promise.reject(
                          new Error('Area of interest cannot include space!'),
                        );
                      } else if (value && regex.test(value)) {
                        return Promise.reject(
                          new Error('Too many consecutive spaces!'),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}>
                {/* <div className='keyword-other-container'>
                  <div className='keyword-other-label'>
                    You can add other interest in following textbox , use comma
                    for separation of multiple options
                  </div>
                  <div
                    className='keyword-other-render-admin'
                    id='adminUpdateProfile-interest-input'>
                    {Array.isArray(tags)
                      ? tags?.map((tag, index) => {
                          const isLongTag = tag.length > 20;
                          const tagElem = (
                            <Tag key={tag} className='tag-render-selected'>
                              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                            </Tag>
                          );
                          return isLongTag ? (
                            <Tooltip
                              className='common-pbss-tooltip'
                              title={tag}
                              key={tag}>
                              {tagElem}
                            </Tooltip>
                          ) : (
                            tagElem
                          );
                        })
                      : ''}
                    {inputVisible && (
                      <Input
                        type='text'
                        size='small'
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                        prefixCls='keyword-other-input'
                      />
                    )}
                    {inputVisible && (
                      <Tag
                        onClick={handleInputConfirm}
                        className='tag-render save-input add'>
                        <span className='tag-render-text close'>
                          Save <SaveOutlined />
                        </span>
                      </Tag>
                    )}
                    {inputVisible && (
                      <Tag
                        id='adminUpdateProfile-closeInput-button'
                        onClick={closeInput}
                        className='tag-render close-input add'>
                        <span className='tag-render-text close'>
                          Close <CloseOutlined className='input-close-icon' />
                        </span>
                      </Tag>
                    )}
                    <span className='keyword-other-buttons'>
                      {!inputVisible && (
                        <Tag
                          id='adminUpdateProfile-addInput-button'
                          onClick={showInput}
                          className='keyword-other-add add'>
                          Add
                          <PlusOutlined prefixCls='keyword-other-icon-add' />
                        </Tag>
                      )}
                      {!inputVisible && (
                        <span onClick={handleClose} style={{marginLeft: 8}}>
                          <Button
                            id='adminUpdateProfile-clearInput-button'
                            prefixCls={`tag-render clear add`}>
                            <span className='tag-render-text close'>
                              Clear <CloseOutlined />
                            </span>
                          </Button>
                        </span>
                      )}
                    </span>
                  </div>
                </div> */}
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter area of interest, separated by commas'
                />
              </Form.Item>
            </div>
          </div>
          <Row gutter={10} className='register-buttons'>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item>
                <Button
                  prefixCls='register-submit'
                  id='register-register-button'
                  type='primary'
                  className='register-button'
                  htmlType='submit'>
                  Update Profile
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item>
                <Button
                  onClick={onReset}
                  prefixCls='register-cancel'
                  id='register-cancel-button'
                  className='register-button'>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default UserUpdateProfileComponent;

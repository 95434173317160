import React, {useEffect, useState} from 'react';
import {
  Space,
  Table,
  Popconfirm,
  Tooltip,
  Button,
  Switch,
  Tag,
  Row,
  Divider,
  Col,
} from 'antd';
import {EyeOutlined, FilterOutlined} from '@ant-design/icons';
import {ApplicationString} from '../../../Localization/Locales/en';
import {useNavigate} from 'react-router-dom';
import {
  PageSizeArray,
  PaginationPosition,
  UserStatusOptions,
  UserRoleEnums,
  userFilters,
  ReturnNAOnEmpty,
  formatCurrencyByLocalChapter,
} from '../../../Util/commonUtility';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import {CSVLink} from 'react-csv';
import plus from '../../../Assets/Plus.svg';
import StatusUpdateModal from '../../CommonComponents/CommonModal';
import {useDispatch, useSelector} from 'react-redux';
import AdminActionsDropdown from './../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import useDebouncedCallback from '../../../hooks/useDebounce';
import {DescriptionItem} from './../../../Util/commonUtility';
import CustomizedTable from './../../CommonComponents/CommonTable/index';
import AdminViewDetailsDrawer from '../../CommonComponents/AdminViewDetailsDrawer';
import {render} from '@testing-library/react';
import CommonTableColumnTextView from './../../CommonComponents/CommonTableColumnTextView/index';
import {getAllChapters} from '../../../Store/Actions';
import dayjs from 'dayjs';

const UserComponent = (props) => {
  let navigate = useNavigate();
  const {
    allUsersData,
    paging,
    chaptersOptions,
    handleTableChange,
    fetchUsers,
    fetchUsersWithoutPagination,
    handlePaginationChange,
    deleteUser,
    sortParams,
    allUsersDataWithoutPagination,
    updateStatusById,
    searchValues,
    csvDataLoading,
  } = props;
  const ps = useSelector(({pageSizes}) => pageSizes.pageSizes.users);
  const [expanded, setExpanded] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [recordData, setRecordData] = useState();
  const [open, setOpen] = useState(false);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const loggedUserRole = userInfo?.role;
  const localUserId = userInfo?.id;
  const adminRoleData = allUsersData?.filter(
    (obj) => obj.role === UserRoleEnums.ADMIN,
  );
  const userRoleData = allUsersData?.filter(
    (obj) => obj.role === UserRoleEnums.USER,
  );
  const filteredAdminAndUserData = allUsersData?.filter(
    (obj) => obj.role !== UserRoleEnums.SUPER_ADMIN,
  );
  let canEdit = false;
  let canDelete = false;
  let canUpdateStatus = false;

  useEffect(() => {
    searchValues && setExpanded(true);
  }, [searchValues]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleEditClick = async (id) => {
    navigate(`/admin/memberAttendees/update/${id}`);
  };
  const handleSearch = (values) => {
    fetchUsers(values);
  };
  const handleUpdateStatusClick = async (record) => {
    setIsModalVisible(true);
    const statusMap = {
      1: 'active',
      0: 'in-active',
    };
    const status = statusMap[record.userstatus] || record.userstatus;
    setShowUpdateStatus(status);
    setRecordData(record);
  };

  const handleChange = (value) => {
    setShowUpdateStatus(value);
  };

  const handleUpdateJobStatus = () => {
    updateStatusById(recordData, showUpdateStatus);
    hideModal(false);
    setShowUpdateStatus(false);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  // Drawer functions ----------------------------------------------------
  const showDrawer = (record) => {
    setOpenDrawerDetails(record);
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const localChapterData = useSelector(
    (state) => state.chapters.localChapterData,
  );
  useEffect(() => {
    if (!localChapterData) {
      dispatch(getAllChapters());
    }
  }, [localChapterData]);

  const MemberAttendeesDrawerContent = () => {
    const {
      first_name,
      last_name,
      company,
      email,
      phone_number,
      city,
      state,
      country,
      address,
      postal_code,
      fax_number,
      local_chapter_id,
      userstatus,
      mail_preference,
      refund_credit,
      notes,
      keywords,
      role,
      title,
      area_of_interest,
      last_login,
      is_vendor_reps,
    } = openDrawerDetails || {};

    const renderList = (items) => {
      const listItems = items?.map((item, index) => (
        <span key={item}>
          {`${item?.name}${index === items.length - 1 ? '' : ', '}`}
        </span>
      ));
      return items?.length > 0 ? listItems : 'N/A';
    };

    return (
      <>
        <Row>
          <Col span={12}>
            <DescriptionItem title='First Name' content={first_name} />
          </Col>
          <Col span={12}>
            <DescriptionItem title='Last Name' content={last_name} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Total Refund Credit'
              content={formatCurrencyByLocalChapter(
                refund_credit,
                localChapterData,
              )}
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={12}>
            <DescriptionItem title='Email' content={email} />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='Company Name/Affiliation
'
              content={company}
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Local Chapter'
              content={local_chapter_id?.name}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Selected Chapter Preference For PBSS Announcement'
              content={renderList(mail_preference)}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Selected Area of Interest'
              content={renderList(keywords)}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Other Area of Interest'
              content={area_of_interest || 'N/A'}
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={12}>
            <DescriptionItem
              title='Phone Number'
              content={phone_number || 'N/A'}
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem title='Address' content={address} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={12}>
            <DescriptionItem title='City' content={city} />
          </Col>
          <Col span={12}>
            <DescriptionItem title='State' content={state} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={12}>
            <DescriptionItem title='Country' content={country} />
          </Col>
          <Col span={12}>
            <DescriptionItem title='Postal Code' content={postal_code} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={12}>
            <DescriptionItem title='Notes' content={notes || 'N/A'} />
          </Col>
          <Col span={12}>
            <DescriptionItem title='Title' content={title || 'N/A'} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={12}>
            <DescriptionItem title='Role' content={role} />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='User Status'
              content={
                {
                  1: 'Active',
                  0: 'In-Active',
                }[userstatus] || userstatus
              }
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Vendor Representative'
              content={is_vendor_reps ? 'Yes' : 'No'}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='User Last Login'
              content={dayjs(last_login).format('YYYY-MM-DD hh:mm A')}
            />
          </Col>
        </Row>
        <Divider />
      </>
    );
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '2%',
      sorter: true,
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: '4%',
      sorter: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: '4%',
      sorter: true,
    },
    {
      title: 'Company Name',
      dataIndex: 'company',
      key: 'company',
      width: '4%',
      sorter: true,
      render: (text, record) => {
        return <div>{ReturnNAOnEmpty(text)}</div>;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '6%',
      sorter: true,
    },

    {
      title: 'Status',
      dataIndex: 'userstatus',
      key: 'status',
      width: '3%',
      render: (text, record) => {
        const statusMap = {
          1: 'Active',
          0: 'In-Active',
        };
        return <span>{statusMap[text]}</span>;
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      fixed: 'right',
      key: 'role',
      width: '2%',
      render: (text, record) => {
        return <span>{text}</span>;
      },
    },

    {
      title: 'Refund Credit',
      dataIndex: 'refund_credit',
      width: '3%',
      fixed: 'right',
      render: (text, record) => {
        return (
          <CommonTableColumnTextView
            columnText={formatCurrencyByLocalChapter(text, localChapterData)}
            isToolTip={false}
            usedForCurrency={true}
          />
        );
      },
    },
    {
      title: '',
      key: 'action',
      width: '2%',
      fixed: 'right',
      render: (_, record) => {
        if (
          loggedUserRole === UserRoleEnums?.SUPER_ADMIN &&
          record?.id === localUserId
        ) {
          canEdit = true;
        } else if (
          loggedUserRole === UserRoleEnums?.SUPER_ADMIN &&
          filteredAdminAndUserData?.find((obj) => obj?.id === record?.id)
        ) {
          canDelete = true;
          canEdit = true;
          canUpdateStatus = true;
        } else if (
          loggedUserRole === UserRoleEnums?.ADMIN &&
          record?.id === localUserId
        ) {
          canEdit = true;
        } else if (
          loggedUserRole === UserRoleEnums?.ADMIN &&
          userRoleData?.find((obj) => obj?.id === record?.id)
        ) {
          canDelete = true;
          canEdit = true;
          canUpdateStatus = true;
        } else {
          canDelete = false;
          canEdit = false;
          canUpdateStatus = false;
        }
        return (
          <AdminActionsDropdown
            record={record}
            editBool={canEdit}
            deleteBool={canDelete}
            statusBool={canUpdateStatus}
            viewBool={true}
            handleView={showDrawer}
            handleDeleteClick={deleteUser}
            handleEditClick={handleEditClick}
            handleUpdateStatusClick={handleUpdateStatusClick}
            text='Member Attendee'
          />
        );
      },
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });

  const formateUsersData = () => {
    let newAllUsersData = [];
    allUsersDataWithoutPagination?.map((item) => {
      let newUser = {
        id: item.id,
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        title: item.title,
        company: item?.company,
        address: item?.address,
        city: item?.city,
        state: item?.state,
        postal_code: item?.postal_code,
        country: item.country,
        phone_number: item?.phone_number,
        fax_number: item?.fax_number || 'NA',
        notes: item?.notes || 'NA',
      };
      newAllUsersData.push(newUser);
    });
    newAllUsersData.push({
      id: '',
      first_name: '',
      last_name: '',
      email: '',
      title: '',
      company: '',
      address: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
      phone_number: '',
      fax_number: '',
      notes: '',
    });
    return newAllUsersData;
  };
  const formattedUserData = formateUsersData();
  const headers = [
    {label: 'Id', key: 'id'},
    {label: 'First_name', key: 'first_name'},
    {label: 'Last_name', key: 'last_name'},
    {label: 'Email', key: 'email'},
    {label: 'Title', key: 'title'},
    {label: 'Company', key: 'company'},
    {label: 'Address', key: 'address'},
    {label: 'City', key: 'city'},
    {label: 'State', key: 'state'},
    {label: 'Postal Code', key: 'postal_code'},
    {label: 'Country', key: 'country'},
    {label: 'Phone Number', key: 'phone_number'},
    {label: 'Fax Number', key: 'fax_number'},
    {label: 'Notes', key: 'notes'},
  ];

  // debouncing the table & pagination changes to stop too many requests
  const debouncedHandlePaginationChange = useDebouncedCallback(
    handlePaginationChange,
    1000,
  );
  const debouncedTableChange = useDebouncedCallback(handleTableChange, 1000);

  return (
    <div className='main-container'>
      {' '}
      <AdminViewDetailsDrawer
        content={MemberAttendeesDrawerContent}
        visible={open}
        text={'Member Attendees'}
        onCloseDrawer={onCloseDrawer}
        isEditable={
          (loggedUserRole === UserRoleEnums?.SUPER_ADMIN &&
            openDrawerDetails?.id === localUserId) ||
          (loggedUserRole === UserRoleEnums?.SUPER_ADMIN &&
            filteredAdminAndUserData?.find(
              (obj) => obj?.id === openDrawerDetails?.id,
            )) ||
          (loggedUserRole === UserRoleEnums?.ADMIN &&
            openDrawerDetails?.id === localUserId) ||
          (loggedUserRole === UserRoleEnums?.ADMIN &&
            userRoleData?.find((obj) => obj?.id === openDrawerDetails?.id))
            ? true
            : false
        }
        editNavigate={`/admin/memberAttendees/update/${openDrawerDetails?.id}`}
      />
      <StatusUpdateModal
        data={recordData}
        visible={isModalVisible}
        onCancel={hideModal}
        showUpdateStatus={showUpdateStatus}
        handleChange={handleChange}
        handleUpdateJobStatus={handleUpdateJobStatus}
        selectionOptions={UserStatusOptions}
      />
      <div className='search-container' style={{gap: '10px'}}>
        <Button onClick={toggleExpand} id='UsersComponent-button-filterSearch'>
          {expanded ? <FilterOutlined /> : <FilterOutlined />}
          {ApplicationString['dynamicSearch.button.heading']}
        </Button>
        {csvDataLoading && !allUsersDataWithoutPagination && (
          <Button>Fetching users..</Button>
        )}
        {!csvDataLoading && !allUsersDataWithoutPagination?.length && (
          <Button
            csvDataLoading
            onClick={() => {
              fetchUsersWithoutPagination(searchValues);
            }}>
            Load CSV Data
          </Button>
        )}
        {!csvDataLoading && allUsersDataWithoutPagination?.length > 0 && (
          <Button id='Users-button-downloadCsv'>
            <CSVLink
              data={formattedUserData}
              header={headers}
              filename={'User_Report.csv'}>
              Download CSV
            </CSVLink>
          </Button>
        )}
        <Button
          type='primary'
          onClick={() => navigate('/admin/memberAttendees/add')}
          id='UsersComponent-button-addUsers'
          className='common-button'>
          <div className='icon-container'>
            <img src={plus} alt='plus' className='icon-plus' />
            <span className='add-content'>
              {ApplicationString['usersComponent.button.addForm']}
            </span>
          </div>
        </Button>
      </div>
      {expanded && (
        <DynamicSearchForm
          columns={userFilters}
          chaptersOptions={chaptersOptions}
          onSearch={handleSearch}
          savedFilteredValues={searchValues}
          title={'users'}
        />
      )}
      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          onChange={debouncedTableChange}
          columns={columns}
          dataSource={allUsersData}
          key={allUsersData?.map((item) => {
            item.id;
          })}
          // sticky={true}
          scroll={{x: columns?.length * 140}}
          pagination={{
            onChange: debouncedHandlePaginationChange,
            current: paging.page,
            total: paging.totalCount,
            pageSize: ps || paging.pageCount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default UserComponent;

import React, {useState, useEffect} from 'react';
import AdsCartComponent from '../../../../Components/UserComponents/Ads/AdsCart';
import {useParams} from 'react-router-dom';
import {getAdsById, getAllAdFees} from '../../../../Store/Actions';
import {useSelector, useDispatch} from 'react-redux';
import {createSelectionOptionsUtil} from '../../../../Util/commonUtility';
import {Col, Row} from 'antd';
import CommonPageHeroText from './../../../../Components/CommonComponents/CommonPageHeroText/index';

const AdsCartContainer = () => {
  const {id} = useParams();
  let dispatch = useDispatch();
  const {fetchEditData} = useSelector(({ads}) => ads);
  const [adData, setAdData] = useState();
  const {allAdFees} = useSelector(({adFees}) => adFees);
  const [adFeesOptions, setAdFeesOptions] = useState([]);

  useEffect(() => {
    if (fetchEditData && fetchEditData?.data?.id === parseInt(id)) {
      setAdData([
        {
          ...fetchEditData?.data,
        },
      ]);
    } else {
      fetchAdsById(parseInt(id));
    }
  }, [fetchEditData.data]);

  const fetchAdsById = async (id) => {
    dispatch(getAdsById(id));
  };

  useEffect(() => {
    if (allAdFees) {
      setAdFeesOptions(
        createSelectionOptionsUtil(
          allAdFees?.data?.adFees,
          'id',
          'details',
          'fee',
        ),
      );
    } else {
      dispatch(
        getAllAdFees(
          {
            page: 1,
            pagecount: 10,
          },
          '',
          '',
          '',
        ),
      );
    }
  }, [allAdFees]);

  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        <Col span={24}>
          <Col span={24}>
            <CommonPageHeroText
              heading={'Ads Payment Method'}
              subheading={
                'Get Details about ads payment price , schedule & more...'
              }
            />
          </Col>
        </Col>
        <Col span={24}>
          <AdsCartComponent adData={adData} adFeesOptions={adFeesOptions} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AdsCartContainer;

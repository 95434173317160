import React from 'react';
import SectionLayout from '../../../CommonComponents/Layout/sectionLayout';
import dayjs from 'dayjs';
import PageTitleCard from '../../../CommonComponents/PageTitleCard';
import PageHeading from '../../../CommonComponents/PageHeading';
import CommonAdsListing from './../CommonAdsListing/index';
import CommonAdsListingCard from '../CommonAdsListing/CommonAdsListingCard';
import {App, Button, Col, Row} from 'antd';
import DownArrowSvg from '../../../../Assets/svgComponents/DownArrowSvg';
import CommonHeroText from '../../../CommonComponents/CommonHeroText';
import {ApplicationString} from '../../../../Localization/Locales/en';

const ApprovedAdsListing = ({allApprovedAdsData}) => {
  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRowJobsListingPage'>
        <Col span={24}>
          <CommonHeroText
            heading={
              ApplicationString['userApprovedAdsListingComponent.heading']
            }
            subheading={''}
          />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <span className='jobListingPageContentHeading'>
              {ApplicationString['userApprovedAdsListingComponent.subheading']}
            </span>
          </SectionLayout>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <div className='common-jobs-listing-main-container'>
              {allApprovedAdsData.map((item, index) => {
                return (
                  <CommonAdsListingCard
                    key={item?.id}
                    id={item?.id}
                    adsHeading={item?.vendor}
                    adsDescription={item?.description}
                    adsStatus={item?.status}
                    adsLogo={item?.logo_ids[0]}
                    url={item?.attachment_url}
                  />
                );
              })}
            </div>
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ApprovedAdsListing;

import React, {useEffect, useState} from 'react';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Upload,
  Tooltip,
  Spin,
  Radio,
  message,
  Empty,
} from 'antd';
import {useLocation, useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import {ApplicationString} from '../../../../Localization/Locales/en';
import './style.css';
import CommonEditor from '../../../CommonComponents/TextEditor';
import {
  DeleteOutlined,
  InfoCircleFilled,
  UploadOutlined,
} from '@ant-design/icons';
import {applicationConfig} from '../../../../Util/config';
import {
  IsScheduleAvailable,
  NoEmptySpaceInput,
  UploadFileMaxSize,
  emailTemplatesTypeCode,
  getEmailDetails,
  queryString,
} from '../../../../Util/commonUtility';
import {
  getAllEmailCodes,
  getAllEmailCodesSearch,
} from '../../../../Store/Actions';
import {useDispatch} from 'react-redux';
import moment from 'moment';
import {FETCH_ERROR} from '../../../../Constant/ActionTypes';

const EventTempFormComponent = (props) => {
  const {
    postEventTemplateScheduler,
    emailCodeList,
    EditData,
    updateEventTemplateScheduler,
    fetching,
    setFetching,
    setEmailCodeList,
    eventTemplateSchedulerData,
    fetchEventTemplateSchedulerById,
    emailTemplateCode,
    isEventTemplateEdit,
    eventStartDateForEventTemp,
  } = props;

  const {id, action} = useParams();
  const [submitLabel, setSubmitLabel] = useState('Submit');
  const [form] = Form.useForm();
  const [editorCodeState, setEditorCodeState] = useState(`<p></p>`);
  const [selectdOption, setSelectdOption] = useState({});
  const Navigate = useNavigate();
  let dispatch = useDispatch();
  const location = useLocation();
  const currentURL = location.pathname;
  const [isAddEmail, setIsAddEmail] = useState(currentURL.includes('add'));
  const [logoIds, setLogoIds] = useState([]);
  const [allLogoIds, setAllLogoIds] = useState([]);
  const [dynamicVariables, setDynamicVariables] = useState();
  const [isScheduleAvailable, setIsScheduleAvailable] = useState(false);
  const [validFileList, setValidFileList] = useState([]);
  const [isEmailTemplate, setIsEmailTemplate] = useState(isEventTemplateEdit);

  // const uploadProps = {
  //   name: 'fileLogos',
  //   accept: 'image/*, application/pdf',
  //   multiple: false,
  //   maxCount: 1,
  //   listType: 'text',
  // };

  // const { Panel } = Collapse;

  // const handleEmailTemplateSearch = (value) => {
  //   setFetching(true);
  //   const Data = {
  //     details: value,
  //   };
  //   const searchQueryData = queryString(Data);
  //   dispatch(
  //     getAllEmailCodesSearch('', '', searchQueryData, {
  //       page: 1,
  //       pageCount: 10,
  //     }),
  //   );
  // };
  // const handleEmailTemplatForMouseLeave = (value) => {
  //   if (emailCodeList?.length === 0) {
  //     dispatch(
  //       getAllEmailCodesSearch('', '', '', {
  //         page: 1,
  //         pageCount: 100,
  //       }),
  //     );
  //   }
  // };
  // const handleEmailTemplateAllowClear = (value) => {
  //   dispatch(
  //     getAllEmailCodesSearch('', '', '', {
  //       page: 1,
  //       pageCount: 100,
  //     }),
  //   );
  // };

  const getTemplateIdByType = (emailTemplateType) => {
    const template = emailCodeList?.find(
      (t) => t.email_template_type === emailTemplateType,
    );
    return template ? template.id : null;
  };

  useEffect(() => {
    if (action === 'updateTemplate') {
      setIsEmailTemplate(true);
    } else if (action === 'updateTemplateSchedule') {
      setIsScheduleAvailable(true);
    }
  });

  useEffect(() => {
    setIsAddEmail(currentURL.includes('add'));
    if (!isAddEmail) {
      fetchEventTemplateSchedulerById(id);
      setSubmitLabel('Update');
    }
  }, []);

  const filesFormats = [
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // 'application/msword',
    'application/pdf',
    'image/png',
    'image/jpeg',
    'image/jpg',
  ];

  const uploadProps = {
    name: 'file',
    multiple: true,
    maxCount: 10,
    // listType: 'picture',
    beforeUpload: (file) => {
      const isRightFormat = filesFormats.includes(file.type);
      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'You can only upload pdf, png, jpg, jpeg files',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSize;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSize}MB!`,
        });
        return false;
      }

      setValidFileList((prev) => {
        if (prev.length >= 10) {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Maximum 10 files can be uploaded',
          });
          return prev;
        }
        return [...prev, file];
      });
      return isRightFormat && isLt2M;
    },
  };

  const removeFileHandler = (file) => {
    setValidFileList((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
  };

  useEffect(() => {
    if (!isAddEmail) {
      const selectedEmailCode = EditData?.email_template_body;
      setEditorCodeState(selectedEmailCode);
      form.setFieldsValue({
        ...EditData,
        code_id: getTemplateIdByType(EditData?.email_template_type),
        schedule_date: EditData?.schedule_date
          ? dayjs(EditData?.schedule_date)
          : null,
      });

      EditData?.attachment_ids?.length > 0
        ? setValidFileList((prev) =>
            EditData?.attachment_ids?.map((eFile) => {
              return {
                uid: eFile.id,
                name: eFile.name,
                status: 'done',
                url: `${applicationConfig.UPLOAD_URL}/${eFile.name}`,
              };
            }),
          )
        : setValidFileList((prev) => []);
    }
    if (!isAddEmail) {
      setIsScheduleAvailable(true);
    }
    if (isEmailTemplate && !EditData?.schedule_date) {
      setIsScheduleAvailable(false);
    }
  }, [EditData]);

  const handalSelect = (value, option) => {
    const emailSubject = option?.details;
    form.setFieldValue('subject', emailSubject);
    setSelectdOption(option);
    const selectedEmailCode = option?.email_template;
    setEditorCodeState(selectedEmailCode);
    const dynamicVariable = emailTemplatesTypeCode.find(
      (template) => template?.value == option?.email_template_type,
    )?.dynamicValues;
    setDynamicVariables((prevValue) => dynamicVariable);
    form.setFieldsValue({
      email_template_body: selectedEmailCode,
      email_template_type: option.email_template_type, // Update the form field directly
    });
  };

  const onFinish = (values) => {
    const scheduleData = {
      ...values,
      // id: EditData?.id,
      // email_attachments:EditData?.email_attachments,
      // attachment_ids: logoIds,
      event_id: isAddEmail ? id : EditData?.event_id,
      email_template_body: editorCodeState,
    };
    const formData = new FormData();
    Object.entries(scheduleData).forEach(([key, value, index]) => {
      if (!isScheduleAvailable && key === 'schedule_date') return;

      if (key === 'email_attachments') {
        // check if we are editing or not
        if (!isAddEmail) {
          const attachmentIds = [];
          if (EditData?.attachment_ids?.length > 0) {
            // if we have attachments then send them as ids and new ones as attachments

            validFileList.forEach((validFile) => {
              EditData?.attachment_ids?.some(
                (file) => file?.id === validFile?.uid,
              )
                ? attachmentIds.push(validFile?.uid)
                : formData.append(
                    'email_attachments',
                    validFile?.originFileObj || validFile,
                  );
            });
            formData.append('attachment_ids', `[${attachmentIds}]`);
          } else {
            values?.email_attachments?.forEach((file) =>
              formData.append(`email_attachments`, file?.originFileObj || file),
            );
          }
        } else {
          // not editing so just append all files
          setValidFileList((prev) => []);
          values?.email_attachments?.forEach((file) =>
            formData.append(`email_attachments`, file?.originFileObj || file),
          );
        }
      } else if (isScheduleAvailable && key === 'schedule_date') {
        const localDate = new Date(value);
        formData.append(key, value.toISOString() ?? '');
      } else {
        formData.append(key, value);
      }
    });
    if (!isAddEmail) {
      updateEventTemplateScheduler(formData, EditData?.id);
      // onReset();
    } else {
      postEventTemplateScheduler(formData);
      // onReset();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  const backToEventSchedulers = () => {
    Navigate(
      `/admin/eventEmailTemplatesScheduler/${
        isAddEmail ? id : EditData?.event_id
      }`,
    );
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  const beforeUploadFlyer = (file) => {
    const reader = new FileReader();
    const fileSize = file.size / 1024;
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        // if (width !== 1080 && height !== 1080) {
        //   message.error('Image must be 1080px x 1080px!');
        //   return Upload.LIST_IGNORE;
        // }
      };
      if (fileSize > 2) {
        message.error('File size must not exceed 2MB!');
        return Upload.LIST_IGNORE;
      }
      return true;
    };

    reader.readAsDataURL(file);
  };

  const handleScheduleChange = (values) => {
    if (values.target.value) {
      setIsScheduleAvailable(true);
    } else {
      setIsScheduleAvailable(false);
    }
  };

  const handleDeleteLogo = (logoId) => {
    const updatedLogoIds = allLogoIds?.filter((logo) => logo.id !== logoId);
    const idsArray = updatedLogoIds.map((item) => item.id);
    setLogoIds(JSON.stringify(idsArray));
    setAllLogoIds(updatedLogoIds);
  };

  const setDynamicVariable = (value, option) => {
    setDynamicVariables((prevValue) => option?.dynamicValues);
  };

  const disableDatehandler = (current) => {
    let currentDate = moment().format('YYYY-MM-DD');
    let customDate = moment(eventStartDateForEventTemp).format('YYYY-MM-DD');

    // Directly map the dates from eventTemplateSchedulerData and format them
    let disabledDates = eventTemplateSchedulerData?.map((item) =>
      moment.utc(item.schedule_date).format('YYYY-MM-DD'),
    );

    if (!isAddEmail) {
      const currentDateNotDisabled = moment
        .utc(EditData.schedule_date)
        .format('YYYY-MM-DD');
      disabledDates = disabledDates.filter(
        (date) => date !== currentDateNotDisabled,
      );
    }
    return (
      current < moment(currentDate).endOf('day') ||
      current.isAfter(customDate) ||
      disabledDates?.includes(current.format('YYYY-MM-DD'))
    );
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          onFinish={onFinish}
          layout='vertical'
          className='two-column-form'
          autoComplete='off'>
          <div className='input-container'>
            <div className='form-column'>
              {/* <Form.Item hidden name='id'></Form.Item> */}
              {/* <Form.Item
                name='event_id'
                // label='Select Your Event'
                initialValue={isAddEmail ? id : EditData?.event_id}
                // rules={[{required: true, message: 'Event  should not be empty!'}]}
              >
                {/* <Select
                  prefixCls='common-select-register'
                  showSearch
                  className='space'
                  optionFilterProp='children'
                 filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  placeholder={'Select your Event'}
                  options={eventsList}></Select> */}
              {/* </Form.Item> */}
              <Form.Item
                label='Email Template'
                name='code_id'
                hidden={!isEmailTemplate && !isAddEmail}
                rules={[
                  {
                    required: isAddEmail,
                    message: 'Email template should not be empty!',
                  },
                ]}>
                <Select
                  // disabled={!isAddEmail}
                  prefixCls='common-select-register'
                  className='space'
                  placeholder='Select email template'
                  showSearch
                  allowClear
                  onChange={(value, option) => handalSelect(value, option)}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={emailCodeList}
                />
              </Form.Item>
              {selectdOption?.email_template_type && (
                <div className='info-container'>
                  <InfoCircleFilled />
                  <p className='content-info'>
                    {
                      getEmailDetails(selectdOption.email_template_type)
                        .description
                    }
                  </p>
                </div>
              )}

              <Form.Item
                label='Subject'
                name='subject'
                rules={[
                  {required: true, message: 'Subject should not be empty!'},
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Subject'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter subject for the email'
                />
              </Form.Item>
              <Form.Item
                label='Do you want to schedule this email?'
                name='is_scheduled'
                valuePropName='value'
                hidden={!isAddEmail}>
                <Radio.Group
                  prefixCls='common-radio-group'
                  optionType='button'
                  onChange={handleScheduleChange}
                  buttonStyle='solid'>
                  {Object.entries(IsScheduleAvailable).map(([key, value]) => (
                    <Radio key={key} value={value}>
                      {key}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label='Schedule Date'
                name='schedule_date'
                hidden={isAddEmail ? !isScheduleAvailable : isEmailTemplate}
                rules={[
                  {
                    required: isScheduleAvailable,
                    message: 'Schedule date should not be empty!',
                  },
                ]}>
                <DatePicker
                  disabledDate={disableDatehandler}
                  placeholder='Select schedule date for sending emails'
                  prefixCls='common-input-user'
                  className='genral-form'
                />
              </Form.Item>
              <Form.Item
                label='Email Template Type'
                name='email_template_type'
                hidden='true'
                rules={[
                  {
                    required: true,
                    message: 'Email template type should not be empty!',
                  },
                ]}>
                <Select
                  prefixCls='common-select-register'
                  disabled={!isAddEmail}
                  className='space'
                  placeholder='Select email template type'
                  // showSearch
                  allowClear
                  onChange={(value, option) =>
                    setDynamicVariable(value, option)
                  }
                  options={emailTemplateCode}
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Email template body should not be empty!',
                  },
                ]}
                name='email_template_body'
                hidden={!isEmailTemplate && !isAddEmail}>
                <CommonEditor
                  // disabled={EditData?.id??false}
                  Variables={dynamicVariables}
                  value={editorCodeState}
                  onChange={(value) => setEditorCodeState(value)}
                  text={'email_template_body'}
                  placeholderValue={'email template description'}
                />
              </Form.Item>
              <div className='input-container'>
                <div className='form-column'>
                  <div className='upload-admin'>
                    <Form.Item
                      name='email_attachments'
                      label=''
                      // valuePropName='fileList'
                      getValueFromEvent={normFile}
                      hidden={!isEmailTemplate && !isAddEmail}>
                      <Upload
                        id='adminEventTesting-uploadButton-button'
                        prefixCls='upload-list-name-fix-class-admin'
                        className='common-input-user genral-form height-auto pd-y-20'
                        {...uploadProps}
                        accept='.pdf,.png,.jpg,.jpeg'
                        customRequest={dummyRequest}
                        onRemove={removeFileHandler}
                        fileList={validFileList}
                        showUploadList={true}>
                        <div>
                          <UploadOutlined className='upload-logo-admin' />
                          <div>
                            <span className='upload-text-logos-admin'>
                              Upload Attachment
                            </span>
                          </div>
                          <div className='upload-type-admin'>
                            <span className='upload-desc-admin'>
                              Accepts only pdf/image files (png, jpg, jpeg, pdf)
                            </span>
                            <br />
                            <span className='upload-desc-admin'>
                              Maximum number of files allowed is 10.
                            </span>
                            <br />
                            <span className='upload-desc-admin'>
                              {`Maximum file size is ${UploadFileMaxSize}MB.`}
                            </span>
                          </div>
                        </div>
                      </Upload>
                    </Form.Item>
                  </div>
                </div>
              </div>
              {/* {allLogoIds?.length ? (
                <Form.Item
                  label='Uploaded Attechments'
                  hidden={!isEventTemplateEdit && !isAddEmail}>
                  <div className='logo-list-container'>
                    {allLogoIds?.map((logo, index) => (
                      <div
                        key={index}
                        className='logo-container extend-logo-container'>
                        <Image
                          crossOrigin='anonymous'
                          preview={true}
                          className='sponsor-icon-email-attach'
                          src={
                            logo.name
                              ? `${applicationConfig.UPLOAD_URL}/${logo.name}`
                              : 'https://icons8.com/icon/12053/document'
                          }
                        />
                        <Tooltip prefixCls = 'common-pbss-tooltip' title='Click to delete this logo'>
                          <Button
                            // type='button'
                            className='delete-logo-button'
                            onClick={() => handleDeleteLogo(logo.id)}>
                            <DeleteOutlined />
                          </Button>
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                </Form.Item>
              ) : (
                ''
              )} */}
            </div>
          </div>
          <Form.Item>
            <div className='button-container'>
              <Button
                id='back'
                className='common-submit-login common-form-submit common-form-cancel'
                onClick={backToEventSchedulers}>
                {ApplicationString['common.button.backForm']}
              </Button>
              <div className='form-flex-button'>
                <Button
                  type='primary'
                  htmlType='submit'
                  id='submit'
                  className='common-submit-login common-form-submit'>
                  {submitLabel}
                </Button>
                <Button
                  className='common-submit-login common-form-submit common-form-cancel'
                  htmlType='button'
                  id='clear'
                  onClick={onReset}>
                  {ApplicationString['dynamicSearch.button.reset']}
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EventTempFormComponent;

import {
  GET_REFUND_BY_EVENTID,
  UPDATE_REFUND_STATUS,
} from '../../Constant/ActionTypes';

const initialState = {
  allRefundDetails: false,
  isUpdateRefundStatus: false,
};

const EventRefundReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REFUND_BY_EVENTID:
      return {
        ...state,
        allRefundDetails: action.payload,
      };
    case UPDATE_REFUND_STATUS:
      return {
        ...state,
        isUpdateRefundStatus: true,
        allRefundDetails: false,
      };

    default:
      return state;
  }
};

export default EventRefundReducer;

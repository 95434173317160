import {
  GET_ALL_EVENTTYPES,
  ADD_EVENTTYPES_SUCCESS,
  UPDATE_EVENTTYPES_BY_ID,
  DELETE_EVENTTYPES_BY_ID,
  FETCH_EVENTTYPES_BY_ID,
  GET_ALL_EVENTTYPES_BY_QUERY,
  GET_ALL_EVENTTYPES_BY_QUERY_SEARCH,
} from '../../Constant/ActionTypes';

const initialState = {
  allEventTypes: false,
  isCreateEventTypes: false,
  isUpdateEventTypes: false,
  fetchEventTypesData: false,
  allEventTypesQueryData: {
    forSearch: false,
    forMaster: false,
  },
};

const EventTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EVENTTYPES_BY_QUERY:
      return {
        ...state,
        allEventTypesQueryData: {
          ...state.allEventTypesQueryData,
          forMaster: action.payload,
        },
        isCreateEventTypes: false,
        isUpdateEventTypes: false,
      };
    case GET_ALL_EVENTTYPES_BY_QUERY_SEARCH:
      return {
        ...state,
        allEventTypesQueryData: {
          ...state.allEventTypesQueryData,
          forSearch: action.payload,
        },
        isCreateEventTypes: false,
        isUpdateEventTypes: false,
      };
    case GET_ALL_EVENTTYPES:
      return {
        ...state,
        allEventTypes: action.payload,
        isCreateEventTypes: false,
        isUpdateEventTypes: false,
      };
    case FETCH_EVENTTYPES_BY_ID:
      return {
        ...state,
        fetchEventTypesData: action.payload,
      };
    case ADD_EVENTTYPES_SUCCESS:
      return {
        ...state,
        isCreateEventTypes: true,
        allEventTypes: false,
        allEventTypesQueryData: false,
      };
    case UPDATE_EVENTTYPES_BY_ID:
      return {
        ...state,
        isUpdateEventTypes: true,
        allEventTypes: false,
        allEventTypesQueryData: false,
      };
    case DELETE_EVENTTYPES_BY_ID:
      return {
        ...state,
        allEventTypes: false,
        allEventTypesQueryData: false,
      };
    default:
      return state;
  }
};

export default EventTypesReducer;

import React, {useEffect, useState} from 'react';
import {
  Button,
  Card,
  Checkbox,
  Spin,
  Form,
  Input,
  Select,
  Tag,
  Tooltip,
  Radio,
  Empty,
} from 'antd';
import './add.css';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {
  CaretDownOutlined,
  SaveOutlined,
  CloseOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  UserRoleOptions,
  commonSelectAll,
  validatePhoneNumber,
  queryString,
  OnlineEvents,
  tagRender,
  maxTagPlaceholder,
  maxTagPlaceholderKeyword,
  OnlyAlphabetInputValidator,
  NoEmptySpaceInput,
  UserPasswordValidator,
  validateEmailInput,
  UserRoleEnums,
  validateCompanyName,
  OnlyAlphabetInputValidatorWithSpecialCharacters,
} from '../../../../Util/commonUtility';
import {useDispatch} from 'react-redux';
import {
  getAllChaptersByQueryStringSearch,
  getAllKeywordsByQuerySearch,
} from '../../../../Store/Actions';

const UserFormComponent = (props) => {
  const [submit, setSubmit] = useState(false);
  const [submitLabel, setSubmitLabel] = useState('Submit');
  const [form] = Form.useForm();
  let Navigate = useNavigate();
  let dispatch = useDispatch();
  const {
    postUser,
    editData,
    updateUser,
    fetchUserById,
    keywordsList,
    chaptersList,
    chapterOptions,
    fetching,
    setFetching,
    SetKeywordsOptions,
    keywordsOptions,
  } = props;
  const [selectedRole, setSelectedRole] = useState('Admin');
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const keywordDataArray = keywordsList?.map((keyword) => keyword?.id);
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const loggedUserId = userInfo?.id;
  const [checkboxState, setCheckboxState] = useState([]);

  useEffect(() => {
    if (id) {
      fetchUserById(id);
      setSubmitLabel('Update');
    }
  }, []);

  useEffect(() => {
    if (id) {
      editData &&
        form.setFieldsValue({
          ...editData,
          is_vendor_reps: editData?.is_vendor_reps
            ? editData?.is_vendor_reps
            : false,
          local_chapter_id: {
            ...editData?.local_chapter_id,
            label: editData?.local_chapter_id?.name,
            value: editData?.local_chapter_id?.id,
          },
          mail_preference: editData?.mail_preference?.map((item, index) => {
            return {
              ...item,
              label: item?.name,
              value: item?.id,
            };
          }),
          confirm_email: editData?.email,
          user_keywords: editData?.keywords?.map((item, index) => {
            return {
              ...item,
              label: item?.name,
              value: item?.id,
            };
          }),
        });
      setTags(
        editData?.area_of_interest !== '' &&
          editData?.area_of_interest?.split(','),
      );
      setSelectedKeywords(
        editData?.keywords?.map((item) => {
          return item?.id;
        }),
      );
    }
  }, [editData]);

  const handleSelectChange = (value) => {
    setSelectedRole(value);
  };

  const handleKeywordAllowClear = () => {
    dispatch(
      getAllKeywordsByQuerySearch('', '', '', {
        page: 1,
        pageCount: 10,
      }),
    );
  };

  const handleKeywordMouseLeave = () => {
    if (keywordsOptions?.length === 0) {
      dispatch(
        getAllKeywordsByQuerySearch('', '', '', {
          page: 1,
          pageCount: 10,
        }),
      );
    }
  };
  const handleKeywordChange = (value) => {
    setSelectedKeywords(value);
  };

  const handleKeywordSearch = (value) => {
    SetKeywordsOptions([]);
    setFetching(true);
    const Data = {
      name: value,
    };
    const searchQueryData = queryString(Data);
    dispatch(
      getAllKeywordsByQuerySearch('', '', searchQueryData, {
        page: 1,
        pageCount: 10,
      }),
    );
  };

  const handleClose = () => {
    setTags([]);
  };
  const showInput = () => {
    setInputVisible(true);
  };
  const closeInput = () => {
    setInputVisible(false);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue) {
      const newTags = [...tags, inputValue];
      setTags(newTags);
    }
    setInputVisible(false);
    setInputValue('');
  };
  const onFinish = (values) => {
    const mailPrefData = values?.mail_preference?.map((mail) => {
      return mail?.value ?? mail;
    });
    const Data = {
      ...values,
      phone_number: values?.phone_number ? values?.phone_number : null,
      is_vendor_reps: values?.is_vendor_reps ?? false,
      mail_preference: mailPrefData || [],
      user_keywords: selectedKeywords,
      local_chapter_id:
        values?.local_chapter_id?.value || values?.local_chapter_id,
    };
    if (id) {
      updateUser(Data, editData.id);
      // form.resetFields();
      // Navigate('/admin/memberAttendees');
      setSubmit(true);
    } else {
      postUser(Data);
      // form.resetFields();
      // Navigate('/admin/memberAttendees');
    }
  };
  // const handleSelectKeywordChange = (value) => {
  //   const selectAllData = keywordsList?.filter((data) => data?.value !== 'all');
  //   if (value.includes('all')) {
  //     form.setFieldsValue({
  //       user_keywords: selectAllData,
  //     });
  //   }
  // };
  const handleSelectMailChange = (value) => {
    const selectAllData = chaptersList
      ?.filter((item) => item?.value !== 'all')
      ?.map((item) => item?.value);
    if (value.includes('all')) {
      setCheckboxState([...selectAllData, 'all']);
      form.setFieldsValue({
        mail_preference: selectAllData,
      });
    } else {
      setCheckboxState(value);
    }
  };

  const handleMailPrefCheckboxChange = (value, checked) => {
    const selectedValues = chaptersList
      ?.filter((item) => item?.value !== 'all')
      ?.map((item) => item?.value);
    if (checked) {
      if (value == 'all') {
        setCheckboxState([...selectedValues, 'all']);
      } else {
        setCheckboxState([...checkboxState, value]);
      }
    } else {
      if (value == 'all') {
        setCheckboxState([]);
        form.setFieldsValue({
          mail_preference: [],
        });
      } else {
        setCheckboxState(checkboxState.filter((item) => item !== value));
      }
    }
  };

  function TagGroup({onClearAll, onSelectAll, usedForChap}) {
    return (
      <div className='tag-group-container'>
        <span onClick={onSelectAll} style={{marginLeft: 8}}>
          <Button
            prefixCls={`tag-render ${usedForChap && 'chapter-button'} select`}>
            <span className='tag-render-text'>Select all</span>
          </Button>
        </span>
        <span onClick={onClearAll} style={{marginLeft: 8}}>
          <Button
            prefixCls={`tag-render ${usedForChap && 'chapter-button'} clear`}>
            <span className='tag-render-text'>Clear</span>
          </Button>
        </span>
      </div>
    );
  }

  const {id} = useParams();
  let userOptionsRenderBasedOnRole;
  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };
  const onReset = () => {
    form.resetFields();
    form.setFieldsValue({
      user_keywords: [],
    });
    setSelectedKeywords([]);
    setTags([]);
  };
  const backTOusers = () => {
    Navigate('/admin/memberAttendees');
  };
  if (loggedUserId !== Number(id)) {
    if (userInfo?.role === UserRoleEnums.ADMIN) {
      userOptionsRenderBasedOnRole = UserRoleOptions?.filter(
        (item) => item.value === 'user',
      );
    } else if (userInfo?.role === UserRoleEnums.SUPER_ADMIN) {
      userOptionsRenderBasedOnRole = UserRoleOptions?.filter(
        (item) => item.value !== 'super_admin',
      );
    } else {
      userOptionsRenderBasedOnRole = UserRoleOptions;
    }
  } else {
    userOptionsRenderBasedOnRole = UserRoleOptions?.filter(
      (item) => item.value === editData?.role,
    );
  }

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          layout='vertical'
          onFinish={onFinish}
          className='two-column-form'
          onFinishFailed={onFinishFailed}
          initialValues={''}
          autoComplete='off'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='First Name'
                name='first_name'
                rules={[
                  {
                    required: true,
                    message: 'First name should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidatorWithSpecialCharacters(
                        value,
                        'First name',
                      ),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter member attendee first name'
                />
              </Form.Item>
              <Form.Item
                label='Email'
                name='email'
                rules={[
                  {
                    required: true,
                    message: 'Email should not be empty',
                  },
                  {
                    validator: (_, value) => validateEmailInput(value, 'Email'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter member attendee email'
                />
              </Form.Item>
              {!id ? (
                <Form.Item
                  label='Password'
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: 'Password should not be empty!',
                    },
                    {
                      validator: UserPasswordValidator,
                    },
                  ]}
                  hasFeedback>
                  <div>
                    <Input.Password
                      placeholder={'Password'}
                      prefixCls='common-input-user'
                      className='genral-form'
                    />
                  </div>
                </Form.Item>
              ) : (
                ''
              )}
              <Form.Item
                label='Title'
                name='title'
                rules={[
                  {
                    validator: (_, value) => NoEmptySpaceInput(value, 'Title'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter title for the member attendee'
                />
              </Form.Item>
              <Form.Item
                label='Address'
                name='address'
                rules={[
                  {
                    required: false,
                    message: 'Address should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Address'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter member attendee address'
                />
              </Form.Item>
              <Form.Item
                label='State'
                name='state'
                rules={[
                  {
                    required: true,
                    message: 'State should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidator(value, 'State'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter member attendee state name'
                />
              </Form.Item>
              <Form.Item
                label='Country'
                name='country'
                rules={[
                  {required: true, message: 'Country should not be empty!'},
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidator(value, 'Country'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter member attendee country name'
                />
              </Form.Item>

              <Form.Item
                name='local_chapter_id'
                label='Select Your Default Local Chapter'
                rules={[
                  {
                    required: true,
                    message: 'Local chapter should not be empty!',
                  },
                ]}>
                <Select
                  prefixCls='common-select-register'
                  showSearch
                  className='space'
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  // onSearch={handleChapterSearch}
                  // notFoundContent={fetching ? <Spin size='small' /> : null}
                  placeholder={'Select member attendee default chapter'}
                  options={chapterOptions}></Select>
              </Form.Item>
              <Form.Item
                name='role'
                label='Select User Role'
                rules={[
                  {
                    required: true,
                    message: 'Member attendee role should not be empty!',
                  },
                ]}>
                <Select
                  id='user-role'
                  prefixCls='common-select-register'
                  showSearch
                  optionFilterProp='children'
                  placeholder={'Select the role of this user'}
                  options={userOptionsRenderBasedOnRole}
                  className='space'
                  value={selectedRole} // Use 'value' to make the Select component controlled
                  onChange={handleSelectChange}></Select>
              </Form.Item>
            </div>

            <div className='form-column'>
              <Form.Item
                label='Last Name'
                name='last_name'
                rules={[
                  {
                    required: true,
                    message: 'Last name should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidatorWithSpecialCharacters(
                        value,
                        'Last name',
                      ),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter member attendee last name'
                />
              </Form.Item>
              <Form.Item
                label='Confirm email'
                name='confirm_email'
                dependencies={['email']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your Email',
                  },
                  ({getFieldValue}) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('email') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'The new email that you entered do not match',
                        ),
                      );
                    },
                  }),
                ]}>
                <Input
                  placeholder={'Confirm member attendee email'}
                  prefixCls='common-input-user'
                  className='genral-form'
                />
              </Form.Item>
              {!id ? (
                <Form.Item
                  label='Confirm password'
                  name='confirm'
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password',
                    },
                    ({getFieldValue}) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'The new password that you entered do not match',
                          ),
                        );
                      },
                    }),
                  ]}>
                  <Input.Password
                    type='password'
                    placeholder={'Confirm password'}
                    prefixCls='common-input-user'
                    className='genral-form'
                  />
                </Form.Item>
              ) : (
                ''
              )}
              <Form.Item
                label='Company Name/Affiliation'
                name='company'
                rules={[
                  {
                    required: true,
                    message: 'If you are in between jobs, please enter N/A!',
                  },
                  {
                    validator: (_, value) =>
                      validateCompanyName(value, 'Company name'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter member attendee company name or academic affiliation.'
                />
              </Form.Item>
              <Form.Item
                label='City'
                name='city'
                rules={[
                  {
                    required: true,
                    message: 'City should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      OnlyAlphabetInputValidator(value, 'City'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter member attendee city name'
                />
              </Form.Item>
              <Form.Item
                label='Postal Code'
                name='postal_code'
                rules={[
                  {
                    required: true,
                    message: 'Postal code should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Postal code'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter member attendee postal code'
                />
              </Form.Item>
              <Form.Item
                label='Phone Number'
                initialValue={null}
                name='phone_number'
                rules={[
                  {
                    validator: (_, value) =>
                      validatePhoneNumber(value, 'Phone number'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter member attendee phone number'
                />
              </Form.Item>
              <Form.Item
                label='Notes'
                name='notes'
                rules={[
                  {
                    validator: (_, value) => NoEmptySpaceInput(value, 'Notes'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter notes'
                />
              </Form.Item>
              <Form.Item
                name='mail_preference'
                label='Select a PBSS Chapter(s) to receive its announcements'
                // rules={[
                //   {required: true, message: 'Default Chapter  should not be empty!'},
                // ]}
              >
                <Select
                  id='basic-selection-chapters'
                  prefixCls='common-select-register'
                  mode='multiple'
                  showSearch
                  allowClear
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  placeholder={'Select chapters'}
                  onChange={handleSelectMailChange}
                  maxTagCount={'responsive'}
                  maxTagPlaceholder={maxTagPlaceholder}
                  tagRender={tagRender}
                  defaultValue={
                    chaptersList?.filter((data) => data?.value !== 'all')?.value
                  }
                  options={
                    (chaptersList &&
                      commonSelectAll(chaptersList)?.map((item) => ({
                        label: (
                          <Checkbox
                            checked={checkboxState.includes(item.value)}
                            onChange={(e) =>
                              handleMailPrefCheckboxChange(
                                item.value,
                                e.target.checked,
                              )
                            }>
                            {item.label}
                          </Checkbox>
                        ),
                        value: item.value,
                      }))) ||
                    []
                  }></Select>
              </Form.Item>
            </div>
          </div>

          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Select Area(s) of Interest'
                name='user_keywords'>
                <Select
                  prefixCls='common-select-register'
                  mode='multiple'
                  maxTagCount={'responsive'}
                  maxTagPlaceholder={maxTagPlaceholderKeyword}
                  showSearch
                  allowClear
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  onChange={handleKeywordChange}
                  onClear={handleKeywordAllowClear}
                  onMouseLeave={handleKeywordMouseLeave}
                  onSearch={handleKeywordSearch}
                  notFoundContent={
                    fetching ? (
                      <Spin size='small' />
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  tagRender={tagRender}
                  placeholder={'Select area of interest'}
                  options={keywordsOptions}></Select>
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='You can add other interest in following textbox , use comma
                    for separation of multiple options'
                name='area_of_interest'
                rules={[
                  {
                    required: false,
                    message: 'Area of interest should not be empty!',
                  },
                  {
                    validator: (_, value) => {
                      const regex = /\s{4,}/g;
                      // added extra validation cause it was causing second error message from antd
                      if (value && value?.length <= 0) {
                        return Promise.reject(new Error(''));
                      }
                      if (value && value.trim() === '') {
                        return Promise.reject(
                          new Error('Area of interest cannot include space!'),
                        );
                      } else if (value && regex.test(value)) {
                        return Promise.reject(
                          new Error('Too many consecutive spaces!'),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter area of interest, separated by commas for member attendee'
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item
            label='Vendor Representative'
            name='is_vendor_reps'
            valuePropName='value'>
            <Radio.Group
              prefixCls='common-radio-group'
              optionType='button'
              buttonStyle='solid'>
              {Object.entries(OnlineEvents).map(([key, value]) => (
                <Radio key={key} value={value}>
                  {key}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <div className='button-container'>
              <Button
                className='common-submit-login common-form-submit common-form-cancel'
                onClick={() => {
                  backTOusers();
                }}>
                Cancel
              </Button>
              <div className='form-flex-button form-flex-button-right'>
                <Button
                  type='primary'
                  htmlType='submit'
                  // //disabled={submit ? true : false}
                  className='common-submit-login common-form-submit'>
                  {submitLabel}
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UserFormComponent;

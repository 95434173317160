import {Col, Row} from 'antd';
import React from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import StayTunedComponent from '../../CommonComponents/StayTunedComponent';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';

const OtherPartnersComponent = () => {
  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        <Col span={24}>
          <SponsorOptionPageHeroText title={'Other Partners'} />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <StayTunedComponent />
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default OtherPartnersComponent;

import React, {useEffect, useState} from 'react';
import {
  DefaultPaginationValues,
  createSelectionOptionsUtil,
  queryString,
} from '../../../Util/commonUtility';
import UsersComponent from '../../../Components/AdminComponents/Users';
import {useDispatch, useSelector} from 'react-redux';
import {
  deleteUserById,
  getAllUsersWithoutPagination,
  getAllUsersByQuery,
  editUserStatusById,
  resetUserCsvDownloadState,
  getAllChapters,
  getAllChaptersByQueryString,
  getAllChaptersByQueryStringForSearch,
} from '../../../Store/Actions';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';

const UserContainer = () => {
  const dispatch = useDispatch();
  const {allUsersByQuery} = useSelector(({users}) => users);
  const {allUsersWithoutPagination} = useSelector(({users}) => users);
  const {allChaptersQueryData} = useSelector(({chapters}) => chapters);
  const [listData, setListData] = useState();
  const [listDataWithoutPagination, setListDataWithoutPagination] = useState();
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.users,
  );
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.users,
  );
  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pageCount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [csvDataLoading, setCSVDataLoading] = useState(false);
  const [totalUserCount, setTotalUserCount] = useState(10);
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);
  const searchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.users,
  );
  const [chaptersOptions, setChaptersOptions] = useState([]);

  useEffect(() => {
    if (allUsersByQuery.forMaster) {
      setListData(allUsersByQuery.forMaster?.data?.users);
      setPagination({
        ...pagination,
        page: allUsersByQuery.forMaster?.data?.currentPage,
        totalPages: allUsersByQuery.forMaster?.data?.totalPage,
        totalCount: allUsersByQuery.forMaster?.data?.totalCount,
      });
      setTotalUserCount(allUsersByQuery.forMaster?.data?.totalCount);
    } else {
      fetchUsers();
    }
  }, [allUsersByQuery.forMaster?.data?.users]);

  useEffect(() => {
    const visibleChapters =
      allChaptersQueryData?.forSearch?.data?.chapters?.filter(
        (chapter) => chapter?.is_visible_on_map === true,
      );
    if (allChaptersQueryData?.forSearch) {
      setChaptersOptions(
        createSelectionOptionsUtil(visibleChapters, 'id', 'name'),
      );
    } else {
      dispatch(
        getAllChaptersByQueryStringForSearch('', '', '', {
          page: 1,
          pageCount: 100,
        }),
      );
    }
  }, [allChaptersQueryData?.forSearch?.data?.chapters]);

  useEffect(() => {
    if (allUsersWithoutPagination) {
      setListDataWithoutPagination(allUsersWithoutPagination?.data?.users);
    }
  }, [allUsersWithoutPagination?.data?.users, totalUserCount]);

  useEffect(() => {
    if (searchValues) {
      dispatch(setPageNumber('users', 1));
      setPagination({
        ...pagination,
        page: 1,
      });
      setPaginationAndSortChanged(true);
    }
    dispatch(resetUserCsvDownloadState());
    setCSVDataLoading(false);
  }, [searchValues]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchUsers(searchValues);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged, searchValues]);

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
    dispatch(resetUserCsvDownloadState());
    setCSVDataLoading(false);
  };
  const handlePaginationChange = (current, pageSize) => {
    setPaginationAndSortChanged(true);
    dispatch(setPageSize('users', pageSize));
    dispatch(setPageNumber('users', current));
    setPagination({
      ...pagination,
      page: current,
      pageCount: pageSize,
    });
  };
  const fetchUsers = async (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllUsersByQuery(sortField, sortOrder, searchQueryData, pagination),
    );
  };

  const deleteUser = async (id) => {
    dispatch(deleteUserById(id));
  };

  const updateStatusById = async (UserData, status) => {
    const id = UserData?.id;
    dispatch(editUserStatusById(id, status));
  };

  const fetchUsersWithoutPagination = async (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllUsersWithoutPagination(
        sortField,
        sortOrder,
        searchQueryData,
        totalUserCount,
        setCSVDataLoading,
      ),
    );
  };

  return (
    <div>
      <UsersComponent
        allUsersData={listData}
        allUsersDataWithoutPagination={listDataWithoutPagination}
        paging={pagination}
        csvDataLoading={csvDataLoading}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        fetchUsers={(searchValues) => {
          fetchUsers(searchValues);
        }}
        fetchUsersWithoutPagination={(searchValues) => {
          fetchUsersWithoutPagination(searchValues);
        }}
        handleTableChange={handleTableChange}
        deleteUser={(id) => {
          deleteUser(id);
        }}
        sortParams={sortParams}
        updateStatusById={(UserData, status) => {
          updateStatusById(UserData, status);
        }}
        searchValues={searchValues}
        chaptersOptions={chaptersOptions}
      />
    </div>
  );
};
export default UserContainer;

import Form from 'antd/es/form/Form';
import Input from 'antd/es/input/Input';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {
  updatePassword,
  verifyResetPasswordToken,
} from './../../../../../../Store/Actions/auth';
import './index.css';
import {Col, Row, Button} from 'antd';
import {UserPasswordValidator} from './../../../../../../Util/commonUtility';
const UpdatePasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updatePassForm] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams(
    document.location.search,
  );
  const token = searchParams.get('token');

  useEffect(() => {
    dispatch(verifyResetPasswordToken(token, navigate));
  }, []);

  const onUpdatePass = (values) => {
    const Data = {
      password: values.password,
    };
    dispatch(updatePassword(Data, token, navigate, updatePassForm));
  };
  return (
    <div className='update-pass-form'>
      <Form
        name='update-pass'
        initialValues={''}
        onFinish={onUpdatePass}
        form={updatePassForm}>
        <Row gutter={6}>
          <Col span={24}>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password',
                },
                {
                  validator: UserPasswordValidator,
                },
              ]}
              hasFeedback>
              <div className='updatePassword-form-input-container'>
                <Input.Password
                  placeholder={'New password'}
                  prefixCls='common-input-user-login'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={24}>
            <Form.Item
              name='confirm'
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'The new password that you entered do not match!',
                      ),
                    );
                  },
                }),
              ]}>
              <div className='updatePassword-form-input-container'>
                <Input.Password
                  placeholder={'Retype password'}
                  prefixCls='common-input-user-login'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <Button
                id='submit-update-pass-page-button'
                type='primary'
                htmlType='submit'
                prefixCls='submit-update-pass-page'>
                Reset My Password
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UpdatePasswordForm;

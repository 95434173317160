import React, {useEffect} from 'react';
import CommonHeroText from '../../CommonComponents/CommonHeroText';
import {Col, Row} from 'antd';
import {ApplicationString} from '../../../Localization/Locales/en';
import MyEventListingToBePaid from '../../CommonComponents/MyEventListing/MyEventCard/MyEventCardToBePaid';

const MyEventsCartComponent = ({
  formattedEventData,
  handleRefundStatus,
  userCredit,
  localChapterData,
  setUserCredit,
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRowJobsListingPage'>
        <Col span={24}>
          <CommonHeroText
            usedForMyEvents={true}
            userCredit={userCredit}
            localChapterData={localChapterData}
            heading={ApplicationString['userMyEventsCartComponent.heading']}
            subheading={
              ApplicationString['userMyEventsCartComponent.subheading']
            }
          />
        </Col>
        <Col span={24}>
          <MyEventListingToBePaid
            handleRefundStatus={handleRefundStatus}
            formattedEventData={formattedEventData}
            setUserCredit={setUserCredit}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MyEventsCartComponent;

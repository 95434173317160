import React, {useEffect, useState} from 'react';
import AdsFormComponent from '../../../../Components/AdminComponents/Ads/AdsForm';
import {useDispatch, useSelector} from 'react-redux';
import {
  addAds,
  getAdsById,
  getAllAdFees,
  updateAdById,
} from '../../../../Store/Actions';
import {useNavigate, useParams} from 'react-router-dom';
import {createSelectionOptionsUtil} from '../../../../Util/commonUtility';
const AdsFormContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const {fetchEditData} = useSelector(({ads}) => ads);
  const [editData, setEditData] = useState();
  const {allAdFees} = useSelector(({adFees}) => adFees);
  const [adFeesOptions, setAdFeesOptions] = useState([]);

  useEffect(() => {
    if (fetchEditData) {
      setEditData(fetchEditData?.data);
    }
  }, [fetchEditData.data]);

  const postAds = async (Data, form) => {
    dispatch(addAds(Data, form, navigate, null));
  };

  const updateAds = async (Data, id, form) => {
    dispatch(updateAdById(Data, id, form, null, navigate));
  };

  const fetchAdsById = async (id) => {
    dispatch(getAdsById(id));
  };

  useEffect(() => {
    if (allAdFees) {
      setAdFeesOptions(
        createSelectionOptionsUtil(
          allAdFees?.data?.adFees,
          'id',
          'details',
          'fee',
        ),
      );
    } else {
      dispatch(
        getAllAdFees(
          {
            page: 1,
            pagecount: 100,
          },
          '',
          '',
          '',
        ),
      );
    }
  }, [allAdFees]);

  return (
    <div>
      <AdsFormComponent
        postAds={(Data, form) => {
          postAds(Data, form);
        }}
        updateAds={(id, Data, form) => {
          updateAds(id, Data, form);
        }}
        fetchAdsById={(id) => {
          fetchAdsById(id);
        }}
        EditData={id ? editData : false}
        adFeesOptions={adFeesOptions}
      />
    </div>
  );
};

export default AdsFormContainer;

import React, {useEffect, useState} from 'react';
import AppInfoView from '../../CommonComponents/AppInfoView/appInfoView';
import CommonHeroText from '../../CommonComponents/CommonHeroText';
import {Button, Col, Form, Input, Result, Row} from 'antd';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import './index.css';
import {useSearchParams} from 'react-router-dom';
import {unsubscribeUser, validateUnsubscribe} from '../../../Store/Actions';
import {useDispatch} from 'react-redux';

const UnsubscribeComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams(
    document.location.search,
  );
  const [unsubscribeSuccess, setUnsubscribeSuccess] = useState();

  let dispatch = useDispatch();
  const token = searchParams.get('tn');
  const handleContactEmail = () => {
    window.location.href = 'mailto:info@pbss.org';
  };

  useEffect(() => {
    if (token) {
      const Data = {
        unsubscribe_token: token,
      };
      dispatch(validateUnsubscribe(Data));
    }
  }, [token]);

  const handleOnFinish = () => {
    const Data = {
      unsubscribe_token: token,
    };
    dispatch(unsubscribeUser(Data, setUnsubscribeSuccess));
  };
  // const alreadyUnsubscribedContent = () => {
  //   return (
  //     <div>
  //       <p>You are already unsubscribed to the Pbss communications.</p>
  //       <p>
  //         We appreciate you taking the time to manage your subscription
  //         settings. If you have any further questions or concerns, please feel
  //         free to reach out to our customer support team at
  //         <span> </span>
  //         <span className='unsubscribe-contact' onClick={handleContactEmail}>
  //           info@pbss.com
  //         </span>
  //         .
  //       </p>
  //       <p>
  //         We appreciate your support and hope to have the opportunity to serve
  //         you again in the future. Thank you for being a part of our community.
  //       </p>
  //     </div>
  //   );
  // };

  const unsubscribtionSuccessfullContent = () => {
    return (
      <div>
        <p>
          We wanted to confirm that your request to unsubscribe from PBSS
          communications has been successfully processed. Your preferences have
          been updated, and you will no longer receive any information or
          updates from us.
        </p>
        <p>
          We appreciate you taking the time to manage your subscription
          settings. If you have any further questions or concerns, please feel
          free to reach out to our customer support team at
          <span> </span>
          <span className='unsubscribe-contact' onClick={handleContactEmail}>
            info@pbss.com
          </span>
          .
        </p>
        <p>
          We appreciate your support and hope to have the opportunity to serve
          you again in the future. Thank you for being a part of our community.
        </p>
      </div>
    );
  };

  return (
    <div>
      <CommonHeroText
        heading={'Unsubscribe'}
        subheading='No need to worry. Simply click on the unsubscribe button to stop receiving communications from PBSS.'
      />
      <Row justify='center' align='middle' style={{minHeight: '60vh'}}>
        <Col span={24}>
          <SectionLayout>
            {unsubscribeSuccess ? (
              <div className='send-email-success'>
                <Result
                  status='success'
                  title={'Successfully unsubscribed to www.pbss.org!'}
                  subTitle={unsubscribtionSuccessfullContent()}
                />
              </div>
            ) : (
              <div className='unsubscribe-pass-container'>
                <div className='unsubscribe-pass-sub-container'>
                  <div className='unsubscribe-text-container'>
                    <p className='unsubscribe-page-heading'>
                      Unsubscribe From Pbss Communications.
                    </p>
                    <p className='sub-page-heading'>
                      {`Click the below button to unsubscribe from pbss.org`}
                    </p>
                  </div>
                  <Form onFinish={handleOnFinish}>
                    <Form.Item
                      className='text-center'
                      prefixCls='unsubscribe-button-form'>
                      <Button
                        type='primary'
                        htmlType='submit'
                        prefixCls='submit-login-page'
                        className='login-button unsubscribe-extend-login-button'>
                        Unsubscribe
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            )}
          </SectionLayout>
        </Col>
      </Row>
    </div>
  );
};

export default UnsubscribeComponent;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  DefaultPaginationValues,
  createSelectionForAdminOptionsUtil,
  createSelectionOptionsUtil,
  queryString,
} from '../../../Util/commonUtility';
import {
  deleteEventsById,
  duplicateEventsById,
  editEventStatusById,
  getAllEventsByQueryString,
} from './../../../Store/Actions/events';
import EventsComponent from './../../../Components/AdminComponents/Events/index';
import {useNavigate} from 'react-router-dom';
import {
  getAllChapters,
  getAllChaptersByQueryString,
  getAllChaptersByQueryStringForSearch,
  getAllEventTypes,
  getAllEventTypesByQuery,
  getAllEventTypesByQuerySearch,
  getAllSpeakers,
  getAllSpeakersByQuerySearch,
} from '../../../Store/Actions';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';
import dayjs from 'dayjs';

const EventsContainer = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const {allEventsQueryData} = useSelector(({events}) => events);
  const {allEventTypesQueryData} = useSelector(({eventTypes}) => eventTypes);
  const {allChaptersQueryData} = useSelector(({chapters}) => chapters);
  const {allSpeakersQueryData} = useSelector(({speakers}) => speakers);
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.events,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.events,
  );

  const [eventsData, setEventsData] = useState();
  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pageCount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'event_start_date_time',
    order: 'descend',
  });
  const [chaptersOptions, setChaptersOptions] = useState([]);
  const [speakerOptions, setSpeakerOptions] = useState([]);
  const [eventTypesOptions, SetEventTypesOption] = useState([]);
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);
  const searchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.events,
  );
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    if (allSpeakersQueryData.forSearch) {
      setSpeakerOptions(
        createSelectionForAdminOptionsUtil(
          allSpeakersQueryData.forSearch?.data?.speakers,
          'id',
          'first_name',
          'last_name',
        ),
      );
      setFetching(false);
    } else {
      dispatch(
        getAllSpeakersByQuerySearch(
          {
            page: 1,
            pagecount: 10,
          },
          '',
          '',
          '',
        ),
      );
    }
  }, [allSpeakersQueryData]);

  useEffect(() => {
    if (searchValues) {
      dispatch(setPageNumber('events', 1));
      setPagination({
        ...pagination,
        page: 1,
      });
      setPaginationAndSortChanged(true);
    }
  }, [searchValues]);

  useEffect(() => {
    if (allEventTypesQueryData.forSearch) {
      const eventTypesOptions = createSelectionOptionsUtil(
        allEventTypesQueryData.forSearch?.data?.eventTypes,
        'id',
        'name',
      );
      SetEventTypesOption(eventTypesOptions);
      setFetching(false);
    } else {
      dispatch(
        getAllEventTypesByQuerySearch('', '', '', {
          page: 1,
          pageCount: 100,
        }),
      );
    }
  }, [allEventTypesQueryData.forSearch?.data?.eventTypes]);

  useEffect(() => {
    if (allEventsQueryData.forMaster) {
      setEventsData(allEventsQueryData.forMaster?.data?.events);
      setPagination({
        ...pagination,
        page: allEventsQueryData.forMaster?.data?.currentPage,
        totalPages: allEventsQueryData.forMaster?.data?.totalPage,
        totalCount: allEventsQueryData.forMaster?.data?.totalCount,
      });
    } else {
      fetchEvents();
    }
  }, [allEventsQueryData.forMaster?.data?.events]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      const formattedSearchValues = {
        ...searchValues,
        date:
          searchValues?.date?.length > 0
            ? [
                dayjs(searchValues?.date[0]).format('YYYY-MM-DD'),
                dayjs(searchValues?.date[1]).format('YYYY-MM-DD'),
              ]
            : '',
      };
      fetchEvents(formattedSearchValues);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged, searchValues]);

  useEffect(() => {
    if (allChaptersQueryData?.forSearch) {
      const visibleChapters =
        allChaptersQueryData.forSearch?.data?.chapters?.filter(
          (chapter) => chapter?.is_visible_on_map === true,
        );
      setChaptersOptions(
        createSelectionOptionsUtil(visibleChapters, 'id', 'name'),
      );
    } else {
      dispatch(
        getAllChaptersByQueryStringForSearch('', '', '', {
          page: 1,
          pageCount: 100,
        }),
      );
    }
  }, [allChaptersQueryData?.forSearch?.data?.chapters]);

  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('events', pageSize));
    dispatch(setPageNumber('events', current));
    setPagination({
      ...pagination,
      page: current,
      pageCount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };

  const updateStatusById = async (eventData, status) => {
    const id = eventData.id;
    dispatch(editEventStatusById(id, status));
  };
  const fetchEvents = (filterValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(filterValues);
    dispatch(
      getAllEventsByQueryString(
        pagination,
        sortField,
        sortOrder,
        searchQueryData,
      ),
    );
  };

  const deleteEvents = (id) => {
    dispatch(deleteEventsById(id));
  };
  const duplicateEvents = async (id) => {
    dispatch(duplicateEventsById(id, navigate));
  };
  return (
    <div className='headmain-form-container'>
      <EventsComponent
        speakerOptions={speakerOptions}
        setSpeakerOptions={setSpeakerOptions}
        fetching={fetching}
        setFetching={setFetching}
        allEventsData={eventsData}
        paging={pagination}
        eventTypesOptions={eventTypesOptions}
        SetEventTypesOption={SetEventTypesOption}
        chaptersOptions={chaptersOptions}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        handleTableChange={handleTableChange}
        sortParams={sortParams}
        fetchEvents={(searchValues) => {
          fetchEvents(searchValues);
        }}
        deleteEvents={(id) => {
          deleteEvents(id);
        }}
        duplicateEvents={(id) => {
          duplicateEvents(id);
        }}
        updateStatusById={(eventData, status) => {
          updateStatusById(eventData, status);
        }}
        searchValues={searchValues}
      />
    </div>
  );
};

export default EventsContainer;

import React from 'react';
import EmailToMemberComponent from '../../Components/AdminComponents/EmailToMemberComponent';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {useState} from 'react';
import {
  createSelectionForAdminOptionsUtil,
  createSelectionOptionsUtilForEmailCode,
  createSelectionOptionsUtilForEmailToMembers,
} from '../../Util/commonUtility';
import {
  getAllChaptersByQueryString,
  getAllChaptersByQueryStringForSearch,
  getAllEmailCodesSearch,
  resetEmailCodesSearchState,
} from '../../Store/Actions';
import {sendEmailToMembers} from '../../Store/Actions/emailToMembers';
import {getAllUsersByQuerySearch} from './../../Store/Actions/users';

const EmailToMemberContainer = () => {
  const dispatch = useDispatch();
  const userData = useSelector(({users}) => users);
  const {allUsersByQuery} = useSelector(({users}) => users);
  // const chapterData = useSelector(
  //   ({chapters}) => chapters?.allChapters?.data?.chapters,
  // );
  const {allChaptersQueryData} = useSelector(({chapters}) => chapters);

  const {allEmailCodes} = useSelector(({emailCodes}) => emailCodes);
  const [userEmailOptions, setUserEmailOptions] = useState([]);
  const [chapterOptions, setChapterOptions] = useState([]);
  const [emailCodeList, setEmailCodeList] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    dispatch(resetEmailCodesSearchState(false));
  }, []);

  // useEffect(() => {
  //   if (chapterData) {
  //     const visibleChapters = chapterData?.filter((chapter) => {
  //       return chapter?.is_visible_on_map === true;
  //     });
  //     setChapterOptions(
  //       createSelectionOptionsUtil(visibleChapters, 'id', 'name'),
  //     );
  //   } else {
  //     dispatch(getAllChapters());
  //   }
  // }, [chapterData]);

  useEffect(() => {
    if (allChaptersQueryData?.forSearch) {
      const visibleChapters =
        allChaptersQueryData.forSearch?.data?.chapters?.filter(
          (chapter) => chapter?.is_visible_on_map === true,
        );
      const utilsChapter = createSelectionForAdminOptionsUtil(
        visibleChapters,
        'id',
        'name',
      );
      const vendorRepsChapter = [
        ...utilsChapter,
        {value: 'vendor_reps', label: 'Vendor Reps'},
      ];
      setChapterOptions(vendorRepsChapter);
      setFetching(false);
    } else {
      dispatch(
        getAllChaptersByQueryStringForSearch('', '', '', {
          page: 1,
          pageCount: 100,
        }),
      );
    }
  }, [allChaptersQueryData?.forSearch?.data?.chapters]);

  useEffect(() => {
    if (allUsersByQuery.forSearch) {
      setUserEmailOptions(
        createSelectionOptionsUtilForEmailToMembers(
          allUsersByQuery.forSearch?.data?.users,
          'email',
          'first_name',
          'last_name',
        ),
      );
      setFetching(false);
    } else {
      dispatch(
        getAllUsersByQuerySearch('', '', '', {
          page: 1,
          pageCount: 10,
        }),
      );
    }
  }, [allUsersByQuery.forSearch]);

  useEffect(() => {
    if (allEmailCodes.forSearch) {
      // const filteredEmailCodes =
      //   allEmailCodes.forSearch?.data?.emailCodes?.filter(
      //     (emailCode) => emailCode?.email_template_type === 'EverydayMail',
      //   );
      setFetching(false);
      setEmailCodeList(
        createSelectionOptionsUtilForEmailCode(
          allEmailCodes.forSearch?.data?.emailCodes,
          'id',
          'details',
        ),
      );
    } else {
      dispatch(
        getAllEmailCodesSearch('', '', '', {
          page: 1,
          pageCount: 60,
        }),
      );
    }
  }, [allEmailCodes.forSearch?.data?.emailCodes]);

  const sendEmailToMembersProp = async (values, form, setValidFileList) => {
    dispatch(sendEmailToMembers(values, form, setValidFileList));
  };

  return (
    <div>
      <EmailToMemberComponent
        userEmailOptions={userEmailOptions}
        setUserEmailOptions={setUserEmailOptions}
        chapterOptions={chapterOptions}
        emailCodeList={emailCodeList}
        setEmailCodeList={setEmailCodeList}
        sendEmailToMembersProp={sendEmailToMembersProp}
        fetching={fetching}
        setFetching={setFetching}
        setChapterOptions={setChapterOptions}
      />
    </div>
  );
};

export default EmailToMemberContainer;

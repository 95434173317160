import React, {useEffect, useState} from 'react';
import {Table, Button, Popover, Tag, Tooltip, Col, Row, Divider} from 'antd';
import {EyeOutlined, FilterOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {
  AdsColumnValues,
  PageSizeArray,
  PaginationPosition,
  AdsAndJobsOptions,
  adsFilters,
  formatCurrency,
  formatDate,
  ReturnNAOnEmpty,
  AdsAndJobsOptionsForAdmin,
} from '../../../Util/commonUtility';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import plus from '../../../Assets/Plus.svg';
import {ApplicationString} from '../../../Localization/Locales/en';
import StatusUpdateModal from '../../CommonComponents/CommonModal';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {useSelector} from 'react-redux';
import CommonAccountModal from './../../CommonComponents/CommonAccountCSV/index';
import dayjs from 'dayjs';
import useDebouncedCallback from '../../../hooks/useDebounce';
import CommonTableColumnTextView from '../../CommonComponents/CommonTableColumnTextView';
import {CSVLink} from 'react-csv';
import {DescriptionItem, addHttpsToUrl} from './../../../Util/commonUtility';
import AdminViewDetailsDrawer from '../../CommonComponents/AdminViewDetailsDrawer';
import CustomizedTable from '../../CommonComponents/CommonTable';

const AdsComponent = (props) => {
  let navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [recordData, setRecordData] = useState();
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const {
    allAdsData,
    fetchAds,
    paging,
    handlePaginationChange,
    DeleteAds,
    sortParams,
    handleTableChange,
    updateStatusById,
    searchValues,
    fetchAdsWithoutPagination,
    adsFetchingWithoutPagination,
    totalAdFees,
    allAdsWithoutPaginationData,
  } = props;

  const ps = useSelector(({pageSizes}) => pageSizes.pageSizes.ads);
  const [open, setOpen] = useState(false);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);

  useEffect(() => {
    searchValues && setExpanded(true);
  }, [searchValues]);

  const handleSearch = (values) => {
    fetchAds(values);
  };

  const handleEditClick = async (id) => {
    navigate(`/admin/ads/update/${id}`);
  };
  const handleChange = (value) => {
    setShowUpdateStatus(value);
  };
  const handleUpdateStatusClick = async (record) => {
    setShowUpdateStatus(record.status);
    setRecordData(record);
  };

  const handleUpdateStatus = () => {
    updateStatusById(recordData, showUpdateStatus);
    setShowUpdateStatus(false);
  };

  const hideModal = () => {
    setShowUpdateStatus(false);
  };

  const hideAccountModal = (form) => {
    setShowAccount(false);
    form.resetFields();
  };
  const handleShowAccountModal = () => {
    setShowAccount(true);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  // Drawer functions ----------------------------------------------------
  const showDrawer = (record) => {
    setOpenDrawerDetails(record);
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const AdsDrawerContent = () => {
    const {
      vendor,
      email,
      start_date,
      months,
      description,
      ad_fee,
      ad_fee_note,
      attachment_url,
      status,
    } = openDrawerDetails || {};

    return (
      <>
        <Row>
          <Col span={24}>
            <DescriptionItem title='Vendor Name' content={vendor} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem title='Submitted By' content={email || 'N/A'} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Ad Status'
              content={
                {
                  in_review: 'In Review',
                  approved: 'Approved',
                  draft: 'Draft',
                  rejected: 'Rejected',
                  expired: 'Expired',
                }[status] || status
              }
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Start Date'
              content={start_date ? formatDate(start_date) : 'N/A'}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem title='Months' content={months || 'N/A'} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Ad Fees'
              content={ad_fee ? formatCurrency(ad_fee, 'USD') : 'N/A'}
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='ad Fee Note'
              content={ad_fee_note || 'N/A'}
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Ad Description'
              content={description || 'N/A'}
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem
              title='Ad Attachment URL'
              content={
                attachment_url ? (
                  <a
                    href={addHttpsToUrl(attachment_url)}
                    target='_blank'
                    rel='noreferrer'>
                    {attachment_url}
                  </a>
                ) : (
                  'N/A'
                )
              }
            />
          </Col>
        </Row>
        <Divider />
      </>
    );
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '1.5%',
      sorter: true,
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendor',
      width: '3%',
      render: (text) => ReturnNAOnEmpty(text),
    },
    {
      title: 'Submitted By',
      dataIndex: 'email',
      key: 'email',
      width: '3%',
      sorter: true,
      render: (text) => ReturnNAOnEmpty(text),
    },

    {
      title: 'Ad Status',
      dataIndex: 'status',
      width: '1%',
      fixed: 'right',
      render: (status) => {
        let color;
        let labal;
        switch (status) {
          case 'approved':
            labal = 'Approved';
            color = 'green';
            break;
          case 'rejected':
            labal = 'Rejected';
            color = 'red';
            break;
          case 'in_review':
            labal = 'In Review';
            color = 'blue';
            break;
          case 'expired':
            labal = 'Expired';
            color = 'error';
            break;
          default:
            labal = 'Draft';
            color = 'purple';
            break;
        }
        return <span style={{color: `${color}`}}>{labal}</span>;
      },
    },
    {
      title: '',
      key: 'action',
      width: '1%',
      fixed: 'right',
      render: (_, record) => (
        <AdminActionsDropdown
          record={record}
          editBool={true}
          deleteBool={true}
          statusBool={true}
          viewBool={true}
          handleView={showDrawer}
          handleEditClick={handleEditClick}
          handleDeleteClick={DeleteAds}
          handleUpdateStatusClick={handleUpdateStatusClick}
          text='Ad'
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });

  const formateAccountData = () => {
    let newAccountData = [];
    const preprocessHTML = (html) => {
      // Remove line breaks and replace commas with a placeholder
      return html.replace(/(\r\n|\n|\r)/gm, '').replace(/,/g, '&#44;');
    };
    allAdsWithoutPaginationData?.map((item) => {
      let newAds = {
        id: item.id,
        user_id: item?.user_id,
        vendor: item.vendor,
        description: preprocessHTML(item.description),
        start_date: item.start_date
          ? dayjs(item?.start_date).format('YYYY/MM/DD')
          : 'NA',
        months: item?.months || 'NA',
        ad_fee: formatCurrency(item.ad_fee),
        // ad_fee_note: item.ad_fee_note,
      };
      newAccountData.push(newAds);
    });

    return newAccountData;
  };

  const headers = [
    {label: 'ID', key: 'id'},
    {label: 'UserID', key: 'user_id'},
    {label: 'Vendor', key: 'vendor'},
    {label: 'Description', key: 'description'},
    {label: 'Start_Date', key: 'start_date'},
    {label: 'Months', key: 'months'},
    {label: 'Ad_Fee', key: 'ad_fee'},
  ];

  const formattedAdsAccountData = formateAccountData();

  // Add total fees row
  const totalFeesRow = {
    id: '',
    vendor: '',
    description: '',
    user_id: '',
    start_date: '',
    months: '',
    ad_fee: formatCurrency(totalAdFees),
  };

  const dataWithTotalFees = [...formattedAdsAccountData, totalFeesRow];

  // debouncing the table & pagination changes to stop too many requests
  const debouncedHandlePaginationChange = useDebouncedCallback(
    handlePaginationChange,
    1000,
  );
  const debouncedTableChange = useDebouncedCallback(handleTableChange, 1000);

  return (
    <div className='main-container'>
      <AdminViewDetailsDrawer
        content={AdsDrawerContent}
        visible={open}
        text={'Ad'}
        onCloseDrawer={onCloseDrawer}
        editNavigate={`/admin/ads/update/${openDrawerDetails?.id}`}
      />
      <StatusUpdateModal
        data={recordData}
        visible={showUpdateStatus}
        onCancel={hideModal}
        showUpdateStatus={
          showUpdateStatus === 'expired' ? 'Expired' : showUpdateStatus
        }
        handleChange={handleChange}
        handleUpdateJobStatus={handleUpdateStatus}
        selectionOptions={AdsAndJobsOptionsForAdmin?.filter(
          (obj) => obj.label !== 'Expired',
        )}
      />
      <div className='search-container' style={{gap: '10px'}}>
        <Button onClick={toggleExpand} id='AdFeeComponent-button-filterSearch'>
          {expanded ? <FilterOutlined /> : <FilterOutlined />}
          {ApplicationString['dynamicSearch.button.heading']}
        </Button>
        {adsFetchingWithoutPagination && !allAdsWithoutPaginationData && (
          <Button>Fetching Ads..</Button>
        )}
        {!adsFetchingWithoutPagination &&
          !allAdsWithoutPaginationData?.length && (
            <Button
              onClick={() => {
                fetchAdsWithoutPagination(searchValues);
              }}>
              Load CSV Data
            </Button>
          )}
        {!adsFetchingWithoutPagination &&
          allAdsWithoutPaginationData?.length > 0 && (
            <Button id='Ads-button-downloadCsv'>
              <CSVLink
                data={dataWithTotalFees}
                header={headers}
                filename={'Ads_Report.csv'}>
                Download CSV
              </CSVLink>
            </Button>
          )}
        <Button
          type='primary'
          onClick={() => navigate('/admin/ads/add')}
          id='AdsComponent-button-addAds'
          className='common-button'>
          <div className='icon-container'>
            <img src={plus} alt='plus' className='icon-plus' />
            <span className='add-content'>
              {ApplicationString['adsComponent.button.addForm']}
            </span>
          </div>
        </Button>
      </div>
      {expanded && (
        <DynamicSearchForm
          columns={adsFilters}
          onSearch={handleSearch}
          savedFilteredValues={searchValues}
          title={'ads'}
        />
      )}
      {/* <Row className='row-container'>
        {showUpdateStatus && (
          <div>
            <Select
              style={{
                width: 120,
                margin: '0px 10px',
              }}
              value={showUpdateStatus}
              defaultValue={showUpdateStatus}
              onChange={handleChange}
              options={Object.values(AdStatus).map((status) => ({
                label: status.toUpperCase(),
                value: status,
              }))}
            />
            <Button
              type='primary'
              onClick={() => {
                handleUpdateStatus();
              }}
              id='AdsComponent-button-updateStatus'>
              Update{' '}
            </Button>
          </div>
        )}
      </Row> */}
      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          columns={columns}
          onChange={debouncedTableChange}
          dataSource={allAdsData}
          // sticky={true}
          scroll={{x: columns?.length * 170}}
          key={allAdsData?.map((item) => {
            item.id;
          })}
          pagination={{
            onChange: debouncedHandlePaginationChange,
            current: paging.page,
            total: paging.totalCount,
            pageSize: ps || paging.pageCount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default AdsComponent;

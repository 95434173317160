import {
  DELETE_COUPONCODES_BY_ID,
  GET_ALL_COUPONCODES,
  GET_COUPONCODES_BY_ID,
  POST_COUPONCODES,
  UPDATE_COUPONCODES_BY_ID,
  GET_ALL_COUPONCODES_WITH_QUERY,
  VERIFY_COUPONCODES,
} from '../../Constant/ActionTypes';

const initialState = {
  allCouponCodes: false,
  allCouponCodesWithQuery: false,
  getCouponCodesData: false,
  isCreateCouponCodes: false,
  isUpdateCouponCodes: false,
};

const CouponCodesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COUPONCODES:
      return {
        ...state,
        allCouponCodes: action.payload,
        isCreateCouponCodes: false,
        isUpdateCouponCodes: false,
      };
    case GET_ALL_COUPONCODES_WITH_QUERY:
      return {
        ...state,
        allCouponCodesWithQuery: action.payload,
        allCouponCodes: false,
        isCreateCouponCodes: false,
        isUpdateCouponCodes: false,
      };
    case GET_COUPONCODES_BY_ID:
      return {
        ...state,
        getCouponCodesData: action.payload,
      };
    case POST_COUPONCODES:
      return {
        ...state,
        isCreateCouponCodes: true,
        allCouponCodes: false,
        allCouponCodesWithQuery: false,
      };
    case UPDATE_COUPONCODES_BY_ID:
      return {
        ...state,
        isUpdateCouponCodes: true,
        allCouponCodes: false,
        allCouponCodesWithQuery: false,
      };
    case DELETE_COUPONCODES_BY_ID:
      return {
        ...state,
        allCouponCodes: false,
        allCouponCodesWithQuery: false,
      };

    default:
      return state;
  }
};

export default CouponCodesReducer;

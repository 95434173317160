import React, {useState, useEffect} from 'react';
import JobFeesFormComponent from '../../../../Components/AdminComponents/JobFees/JobFeesForm';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  createJobFees,
  editJobFees,
  getJobFeesById,
} from '../../../../Store/Actions';
const JobFeesFormContainer = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {getJobFeesData} = useSelector(({jobFees}) => jobFees);
  const [editData, setEditData] = useState();

  useEffect(() => {
    if (getJobFeesData) {
      setEditData(getJobFeesData?.data);
    }
  }, [getJobFeesData]);

  const postJobFees = async (Data) => {
    dispatch(createJobFees(Data, navigate));
  };

  const updateJobFees = async (Data, id) => {
    dispatch(editJobFees(Data, id, navigate));
  };

  const fetchJobFeesById = async (id) => {
    dispatch(getJobFeesById(id));
  };
  return (
    <div>
      <JobFeesFormComponent
        postJobFees={(Data) => {
          postJobFees(Data);
        }}
        updateJobFees={(id, Data) => {
          updateJobFees(id, Data);
        }}
        fetchJobFeesById={(id) => {
          fetchJobFeesById(id);
        }}
        EditData={editData}
      />
    </div>
  );
};

export default JobFeesFormContainer;

import React from 'react';
import './style.css';
import {useNavigate} from 'react-router-dom';
import {RollbackOutlined} from '@ant-design/icons';

const NavigationComponent = ({name, urlPath}) => {
  const navigation = useNavigate();

  const handleBack = () => {
    navigation(`/${urlPath}`);
  };
  return (
    <React.Fragment>
      <div className='btn-main-container'>
        <button onClick={handleBack}>
          {' '}
          <span className='btn-icon-box'>
            <RollbackOutlined />
          </span>{' '}
          {name}{' '}
        </button>
      </div>
    </React.Fragment>
  );
};

export default NavigationComponent;

import {useEffect, useState} from 'react';
import {Route, useNavigate} from 'react-router-dom';

const IsAuthorized = ({isLoggedIn, allowedRoles, path, element}) => {
  const [isValidRole, setIsValidRole] = useState(false);
  const navigate = useNavigate();
  const checkRole = () => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      const user = JSON.parse(userInfo);
      if (user.role && allowedRoles?.length > 0) {
        if (allowedRoles.includes(user.role)) setIsValidRole(true);
        else {
          // navigate('/notAuthorized');
          navigate('/updateProfile');
        }
      }
    }
  };
  useEffect(() => {
    checkRole();
  }, [isValidRole]);
  return <>{element}</>;
};

export default IsAuthorized;

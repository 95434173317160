import React from 'react';
import './style.css';
import {Divider} from 'antd';
import {Link, useNavigate} from 'react-router-dom';

const ProfileInfo = ({logout}) => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const userLogout = () => {
    logout();
  };
  const currentPath = window.location.pathname;

  switch (true) {
    case userInfo && userInfo.role === 'admin':
    case userInfo && userInfo.role === 'super_admin':
      return (
        <div className='main-profile-container'>
          <div className='profile-view-container'>
            <label>ACCOUNT</label>
            <div className='profile-user-wapper'>
              <span>{userInfo?.first_name?.slice(0, 1)}</span>
              <div className='profile-email-text'>
                <p>{userInfo?.first_name}</p>
                <label>{userInfo?.email}</label>
              </div>
            </div>
          </div>
          <Link to='/admin/events'>
            <li>Switch to Admin Functions</li>
          </Link>
          {userInfo?.first_name ? (
            <>
              <Link to='/updateProfile'>
                <li>Update Profile</li>
              </Link>
              <Divider prefixCls='profile-divider' />
              <Link to='/jobs'>
                <li>Create a Job Posting</li>
              </Link>
              <Link to='/ads'>
                <li>Create an Ad</li>
              </Link>
              <Link to='/myEvents'>
                <li>{`My Event(s)`}</li>
              </Link>
              <Divider prefixCls='profile-divider' />
            </>
          ) : null}
          <button
            className='common-button-header bg-remove extend-button-w extend-settings-button'
            onClick={userLogout}>
            Logout
          </button>
        </div>
      );

    case userInfo && userInfo.role === 'user':
      return (
        <div className='main-profile-container'>
          <div className='profile-view-container'>
            <label>ACCOUNT</label>
            <div className='profile-user-wapper'>
              <span>{userInfo?.first_name?.slice(0, 1)}</span>
              <div className='profile-email-text'>
                <p>{userInfo?.first_name}</p>
                <label>{userInfo?.email}</label>
              </div>
            </div>
          </div>
          <Link to='/updateProfile'>
            <li>Update Profile</li>
          </Link>
          <Divider prefixCls='profile-divider' />
          <Link to='/jobs'>
            <li>Create a Job Posting</li>
          </Link>
          <Link to='/ads'>
            <li>Create an Ad</li>
          </Link>
          <Link to='/myEvents'>
            <li>My Event(s)</li>
          </Link>
          <Divider prefixCls='profile-divider' />
          <button
            className='common-button-header bg-remove extend-button-w extend-settings-button'
            onClick={userLogout}>
            Logout
          </button>
        </div>
      );

    case currentPath === '/login':
      return (
        <div className='main-profile-container'>
          <Link to='/register'>
            <button className='common-button-header bg-remove extend-button-w extend-settings-button'>
              Join Pbss
            </button>
          </Link>
        </div>
      );

    case currentPath === '/register':
      return (
        <div className='main-profile-container'>
          <Link to='/login'>
            <button className='common-button-header extend-settings-button'>
              Login
            </button>
          </Link>
        </div>
      );

    default:
      return (
        <div className='main-profile-container'>
          {currentPath !== '/login' && (
            <Link to='/login'>
              <button className='common-button-header extend-settings-button'>
                Login
              </button>
            </Link>
          )}
          {currentPath !== '/register' && (
            <Link to='/register'>
              <button className='common-button-header bg-remove extend-button-w extend-settings-button'>
                Join Pbss
              </button>
            </Link>
          )}
        </div>
      );
  }
};

export default ProfileInfo;

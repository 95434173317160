import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import EventDetailsComponent from '../../../../Components/UserComponents/PbssInternational/EventDetails';
import {useNavigate, useParams} from 'react-router-dom';
import moment from 'moment';
import {getEventsById} from '../../../../Store/Actions/events';
import {
  getAllApprovedAds,
  getAllApprovedJobs,
  getAllSponsors,
} from '../../../../Store/Actions';
import {
  EventRegistrationStatus,
  EventStatusEnum,
} from '../../../../Util/commonUtility';
const EventDetailsContainer = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const {getEventsData} = useSelector(({events}) => events);
  const [eventsData, setEventsData] = useState([]);
  const {fetchUserData} = useSelector(({users}) => users);
  const {getApprovedJobs} = useSelector(({jobs}) => jobs);
  const {getApprovedAds} = useSelector(({ads}) => ads);
  const [allApprovedJobsData, setAllApprovedJobsData] = useState([]);
  const [allApprovedAdsData, setAllApprovedAdsData] = useState([]);
  const [sponsorsFilteredData, setSponsorsFilteredData] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(2);
  const [startIndexAds, setStartIndexAds] = useState(0);
  const [endIndexAds, setEndIndexAds] = useState(3);
  const [endIndexSponsor, setEndIndexSponsor] = useState(0);
  const [startIndexSponsor, setStartIndexSponsor] = useState(3);
  const [totalJobs, setTotalJobs] = useState(0);
  const [totalAds, setTotalAds] = useState(0);
  const {id} = useParams();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const userRole = userInfo ? userInfo.role : null;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [id]);

  const handleNext = () => {
    if (endIndex + 1 < totalJobs) {
      setStartIndex(endIndex + 1);
      setEndIndex(endIndex + 3);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 3);
      setEndIndex(endIndex - 3);
    }
  };
  const handleNextAds = () => {
    if (endIndexAds + 1 < totalAds) {
      setStartIndexAds(endIndexAds + 1);
      setEndIndexAds(endIndexAds + 4);
    }
  };

  const handlePrevAds = () => {
    if (startIndexAds > 0) {
      setStartIndexAds(startIndexAds - 4);
      setEndIndexAds(endIndexAds - 4);
    }
  };

  const handleNextMajorSponsor = () => {
    if (endIndexSponsor + 1 < totalAds) {
      setStartIndexSponsor(endIndexSponsor + 1);
      setEndIndexSponsor(endIndexSponsor + 4);
    }
  };

  const handlePrevMajorSponsor = () => {
    if (startIndexSponsor > 0) {
      setStartIndexSponsor(startIndexSponsor - 4);
      setEndIndexSponsor(endIndexSponsor - 4);
    }
  };

  useEffect(() => {
    dispatch(getEventsById(id));
  }, [id]);

  useEffect(() => {
    if (getEventsData && getEventsData?.data?.id === parseInt(id)) {
      {
        const updatedEventData = {
          ...getEventsData?.data,
          key: getEventsData?.data?.key,
          id: getEventsData?.data?.id,
          flyer_url: getEventsData?.data?.flyer_url,
          flyer_text: getEventsData?.data?.flyer_text,
          flyer_images: getEventsData?.data?.flyer_image_ids,
          sponsorshipAvailable: `${
            Array.isArray(getEventsData?.data?.sponsors)
              ? getEventsData?.data?.sponsors.length
              : 0
          } Sponsorship Available`,
          // speakers: Array.isArray(getEventsData?.data?.speakers)
          //   ? getEventsData?.data?.speakers.map((speaker) => {
          //       return {
          //         name: `${speaker?.first_name} ${
          //           speaker?.last_name ? speaker.last_name : ''
          //         }`.trim(),
          //         title: speaker?.title || 'N/A',
          //         company: speaker?.company_name || 'N/A',
          //         details: speaker?.details || 'N/A',
          //       };
          //     })
          //   : '',
          speaker_name: getEventsData?.data?.speaker_name,
          registrationPrice: getEventsData?.data?.registration_price,
          registerText: 'Register Now',
        };
        const updatedSponsorsData = getEventsData?.data?.sponsors?.filter(
          (obj) =>
            parseInt(obj?.sponsorship_type) === EventRegistrationStatus.PAID,
        );
        setSponsorsFilteredData(updatedSponsorsData);
        setEventsData(updatedEventData);
      }
    }
  }, [getEventsData]);

  useEffect(() => {
    if (getApprovedJobs) {
      const Data = getApprovedJobs?.data?.jobs?.slice(startIndex, endIndex + 1);
      setAllApprovedJobsData(Data);
      setTotalJobs(getApprovedJobs?.data?.totalCount);
    } else {
      dispatch(getAllApprovedJobs());
    }
  }, [getApprovedJobs, startIndex, endIndex]);

  useEffect(() => {
    if (getApprovedAds) {
      const Data = getApprovedAds?.data?.ads?.slice(
        startIndexAds,
        endIndexAds + 1,
      );
      setAllApprovedAdsData(Data);
      setTotalAds(getApprovedAds?.data?.totalCount);
    } else {
      dispatch(getAllApprovedAds());
    }
  }, [getApprovedAds, startIndexAds, endIndexAds]);

  return (
    <div>
      <EventDetailsComponent
        eventsData={eventsData}
        allApprovedJobsData={allApprovedJobsData}
        allApprovedAdsData={allApprovedAdsData}
        sponsorsFilteredData={sponsorsFilteredData}
        handleNextMajorSponsor={handleNextMajorSponsor}
        handlePrevMajorSponsor={handlePrevMajorSponsor}
        handleNext={handleNext}
        handlePrev={handlePrev}
        handleNextAds={handleNextAds}
        handlePrevAds={handlePrevAds}
      />
    </div>
  );
};

export default EventDetailsContainer;

import React, {useEffect, useState} from 'react';
import {getAllApprovedAds, getAllApprovedJobs} from '../../../../Store/Actions';
import {useDispatch, useSelector} from 'react-redux';
import ApprovedAdsListing from '../../../../Components/UserComponents/Ads/ApprovedAdsListing';

const ApprovedAdsContainer = () => {
  const {getApprovedAds} = useSelector(({ads}) => ads);
  const [allApprovedAdsData, setAllApprovedAdsData] = useState([]);
  //   const [totalJobs, setTotalJobs] = useState(0);
  let dispatch = useDispatch();

  useEffect(() => {
    if (getApprovedAds) {
      const Data = getApprovedAds?.data?.ads;
      setAllApprovedAdsData(Data);
    } else {
      dispatch(getAllApprovedAds());
    }
  }, [getApprovedAds]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <div>
      <ApprovedAdsListing allApprovedAdsData={allApprovedAdsData} />
    </div>
  );
};

export default ApprovedAdsContainer;

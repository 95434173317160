import {SET_SEARCH_VALUE} from '../../Constant/ActionTypes';

const initialState = {
  searchValues: {},
};

const dynamicSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_VALUE: {
      return {
        ...state,
        searchValues: {
          ...state.searchValues,
          [action.payload.searchPageName]: action.payload.searchvalue,
        },
        searchValueID: action.payload.ID,
      };
    }

    default:
      return state;
  }
};

export default dynamicSearchReducer;

import React, {useEffect, useState} from 'react';
import EventCard from '../../../CommonComponents/EventCard';
import SectionLayout from '../../../CommonComponents/Layout/sectionLayout';
import RecentOpeningsJobs from '../../../CommonComponents/RecentOpenings/jobs';
import SponsorListing from '../../../CommonComponents/SponsorListing';
import WorkShopAgenda from '../workShopAgenda';
import './style.css';
import MajorSponsorListing from '../../../CommonComponents/MajorSponsorListing';
import {Col, Row, Image} from 'antd';
import PastEventsListing from '../../../CommonComponents/PastEventsListing';

import {getAllPastEvents} from '../../../../Store/Actions/events';
import {useDispatch, useSelector} from 'react-redux';
import {
  DefaultPaginationValuesForPastEvents,
  EventStatus,
  EventStatusEnum,
  SponsorshipTypeMapping,
  sponsorDataMarquee,
} from '../../../../Util/commonUtility';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {applicationConfig} from '../../../../Util/config';
import ComponentTitle from '../../../CommonComponents/CommonComponentTitle';

const EventDetailsComponent = (props) => {
  const {
    eventsData,
    allApprovedJobsData,
    allApprovedAdsData,
    sponsorsFilteredData,
    handleNext,
    handlePrev,
    handleNextMajorSponsor,
    handlePrevMajorSponsor,
    handleNextAds,
    handlePrevAds,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pastEventData, setPastEventData] = useState();
  const [getAllPastEventData, setGetAllPastEventData] = useState();
  const {allPastEvents} = useSelector(({events}) => events);
  const [selectedYearValue, setSelectedYearValue] = React.useState('');
  const [sponsorData, setSponsorData] = useState('');
  const [majorSponsorData, setMajorSponosr] = useState('');
  const [totalPastEventsPages, setTotalPastEventsPages] = useState();

  const [pagination, setPagination] = React.useState({
    page: DefaultPaginationValuesForPastEvents.PAGE,
    pagecount: DefaultPaginationValuesForPastEvents.PAGE_SIZE,
    totalPages: DefaultPaginationValuesForPastEvents.TOTAL_PAGES,
  });

  const handlePaginationChange = (current, pageSize) => {
    setPagination({
      ...pagination,
      page: current,
      pagecount: pageSize,
    });
  };

  const handleYearFilter = (value) => {
    dispatch(getAllPastEvents(pagination, value));
    setSelectedYearValue(value);
  };

  const filtredMajorAndSponsorData = () => {
    const majorSponsorData = eventsData?.sponsors?.filter(
      (item) => item.sponsorship_type === '3',
    );
    const sponsorData = eventsData?.sponsors?.filter(
      (item) => item.sponsorship_type === '2' || item.sponsorship_type === '1',
    );
    setMajorSponosr(majorSponsorData);
    setSponsorData(sponsorData);
  };

  useEffect(() => {
    if (eventsData) {
      filtredMajorAndSponsorData();
    }
  }, [allPastEvents, eventsData]);

  useEffect(() => {
    if (allPastEvents) {
      dispatch(getAllPastEvents(pagination, selectedYearValue));
    }
  }, [pagination.page, pagination?.pagecount]);

  useEffect(() => {
    if (allPastEvents) {
      const Data = allPastEvents?.data?.events;
      setGetAllPastEventData(Data);
      setPagination({
        ...pagination,
        page: allPastEvents?.data?.currentPage,
        totalPages: allPastEvents?.data?.totalPage,
        totalCount: allPastEvents?.data?.totalCount,
      });
      setTotalPastEventsPages(allPastEvents?.data?.totalPage);
    } else {
      dispatch(getAllPastEvents(pagination, selectedYearValue));
    }
  }, [allPastEvents?.data?.events]);

  useEffect(() => {
    if (getAllPastEventData) {
      const updatedPastEventData = getAllPastEventData.map((item) => {
        return {
          key: item.id,
          name: item.name,
          event_date: moment(item.event_start_date_time)
            .utcOffset(0)
            .format('DD MMM YYYY'),
          event_type: item.event_type_name,
          chapter_name: item?.local_chapter_normalized_name
            ? item?.local_chapter_normalized_name
            : item.local_chapter_name,
        };
      });
      setPastEventData(updatedPastEventData);
    }
  }, [getAllPastEventData]);

  let speakersNameRender = '';
  if (eventsData?.speakers?.length > 0) {
    if (eventsData?.speakers?.length === 1) {
      speakersNameRender = eventsData.speakers[0].name;
    } else {
      const speakerNames = eventsData.speakers.map(
        (speaker) => `${speaker.name} [${speaker.company}]`,
      );
      speakersNameRender = speakerNames.join(', ');
    }
  }
  const showBio =
    eventsData?.event_bio || eventsData?.event_type_name === 'Webcast';

  const getVendorAndSponsorData = (events) => {
    const sponsors = [];
    const exhibitors = [];

    events?.forEach((event) => {
      const sponsorshipType = parseInt(event.sponsorship_type, 10);
      const sponsorshipName = SponsorshipTypeMapping[sponsorshipType];

      if (sponsorshipType === 0) {
        exhibitors.push(event.company_name);
      } else {
        sponsors.push(`${event.company_name} (${sponsorshipName})`);
      }
    });

    if (sponsors.length === 0 && exhibitors.length === 0) {
      return false;
    }

    return {
      sponsors,
      exhibitors,
    };
  };

  const {sponsors, exhibitors} = getVendorAndSponsorData(eventsData?.sponsors);

  return (
    <Row gutter={[0, 30]} className='pbssMainContainerRowEventDetails'>
      <Col span={24}>
        <SectionLayout>
          <EventCard
            sponsors={sponsors}
            exhibitors={exhibitors}
            isExpand={false}
            key={1} // Replace with a unique key for each card
            EventId={eventsData?.id} // Replace with a unique event ID
            sponsorshipAvailable={true}
            eventName={eventsData?.name}
            isRegistrationOpen={
              eventsData?.status == EventStatus.ACTIVE ? true : false
            }
            speakers={eventsData?.speaker_name}
            organizers={eventsData?.organizers?.join(', ')}
            date={eventsData?.date}
            dateObject={eventsData?.formattedDateTime} // Replace with the actual date
            location={eventsData?.place}
            details={eventsData?.details}
            registerText={eventsData?.registerText}
            price={eventsData?.registrationPrice} // Replace with the actual price
            eventTypeName={eventsData?.event_type_name}
            moreDetails={true}
          />
        </SectionLayout>
      </Col>
      {eventsData?.event_bio !== '<p></p>' &&
        eventsData?.event_bio !== '<p><br></p>' &&
        showBio && (
          <Col span={24}>
            <WorkShopAgenda
              eventType={eventsData?.event_type_name}
              TableData={eventsData?.event_bio}
              usedForBio={true}
            />
          </Col>
        )}
      {eventsData?.event_info !== '<p></p>' &&
        eventsData?.event_info !== '<p><br></p>' &&
        eventsData?.event_info && (
          <Col span={24}>
            <WorkShopAgenda TableData={eventsData?.event_info} />
          </Col>
        )}
      {eventsData?.speaker_bio !== '<p></p>' &&
        eventsData?.speaker_bio !== '<p><br></p>' &&
        eventsData?.speaker_bio && (
          <Col span={24}>
            <WorkShopAgenda
              TableData={eventsData?.speaker_bio}
              usedForSpeakerBio={true}
            />
          </Col>
        )}
      {eventsData?.flyer_text !== 'N/A' && (
        <Col span={24}>
          <SectionLayout>
            <span>{eventsData?.flyer_text}</span>
          </SectionLayout>
        </Col>
      )}
      {eventsData?.flyer_url && (
        <Col span={24}>
          <SectionLayout>
            <a
              href={`${applicationConfig.UPLOAD_URL}/${eventsData?.flyer_url?.name}`}
              className='common-submit-login flyer-event-details'
              target='_blank'
              rel='noreferrer'>
              Download Flyer
            </a>
          </SectionLayout>
        </Col>
      )}
      {eventsData?.flyer_images?.length > 0 && (
        <Col span={24}>
          <SectionLayout>
            <ComponentTitle
              hideButton
              title={'Event Flyer Images'}
              textColor={'black'}
            />
            <Image.PreviewGroup>
              {eventsData?.flyer_images?.map((image) => (
                <Image
                  prefixCls='event-flyer-image'
                  crossOrigin='anonymous'
                  key={image?.id}
                  width={'50%'}
                  height={'400px'}
                  src={`${applicationConfig.UPLOAD_URL}/${image?.name}`}
                />
              ))}
            </Image.PreviewGroup>
          </SectionLayout>
        </Col>
      )}
      <Col span={24}>
        {/* <SpeakerListing speakersData={eventsData?.speakers} /> */}
      </Col>
      {majorSponsorData?.length > 0 && (
        <Col span={24}>
          <MajorSponsorListing
            handleNext={handleNextMajorSponsor}
            handlePrev={handlePrevMajorSponsor}
            sponsorData={majorSponsorData}
            addScroll={true}
          />
        </Col>
      )}
      <Col span={24}>
        <PastEventsListing
          handlePaginationChange={(page, pageSize) => {
            handlePaginationChange(page, pageSize);
          }}
          pastEventData={pastEventData}
          pagination={pagination}
          selectedYearValue={selectedYearValue}
          handleYearFilter={handleYearFilter}
          totalPastEventsPages={totalPastEventsPages}
          usedForBio={true}
        />
      </Col>
      <Col span={24}>
        <SponsorListing sponsorData={sponsorDataMarquee} addScroll={true} />
      </Col>
      <Col span={24}>
        <RecentOpeningsJobs
          title={'Recent Job Openings'}
          buttonName={'View Details'}
          data={allApprovedJobsData}
          flex={true}
          handleNext={handleNext}
          handlePrev={handlePrev}
          home={true}
        />
      </Col>
    </Row>
  );
};

export default EventDetailsComponent;

// import React from 'react';

// const DateDesortSvg = () => {
//   return (
//     <>
//       <svg
//         width={15}
//         height={15}
//         viewBox='0 0 15 15'
//         fill='none'
//         xmlns='http://www.w3.org/2000/svg'>
//         <path
//           d='M3.33351 7.4999C3.89542 7.4999 4.4007 7.82961 4.63021 8.33449L4.67509 8.4453L6.61828 13.8862C6.77303 14.3196 6.5472 14.7965 6.11379 14.9512C5.71133 15.095 5.27137 14.9105 5.08667 14.5364L5.04872 14.4467L4.65099 13.3331H2.01603L1.61832 14.4467C1.46353 14.8801 0.98669 15.106 0.553271 14.9512C0.15081 14.8075 -0.072694 14.3861 0.02137 13.9796L0.0487775 13.8862L1.99194 8.4453C2.19443 7.87839 2.73147 7.4999 3.33351 7.4999ZM10.8334 0.833322C11.2936 0.833322 11.6667 1.20642 11.6667 1.66664V12.0115L12.6011 11.0771C12.9266 10.7517 13.4542 10.7517 13.7797 11.0771C14.1051 11.4025 14.1051 11.9302 13.7797 12.2556L11.4226 14.6126C11.0972 14.938 10.5696 14.938 10.2441 14.6126L7.88718 12.2556C7.56177 11.9302 7.56177 11.4025 7.88718 11.0771C8.21259 10.7517 8.74025 10.7517 9.06566 11.0771L10.0001 12.0115V1.66664C10.0001 1.20642 10.3731 0.833322 10.8334 0.833322ZM3.33351 9.6442L2.61127 11.6665H4.05576L3.33351 9.6442ZM5.63229 0C6.41628 0 6.82457 0.90996 6.34505 1.49366L6.28045 1.56483L2.84536 4.99993H5.83346C6.2937 4.99993 6.66678 5.37303 6.66678 5.83325C6.66678 6.26062 6.34509 6.61283 5.93064 6.66097L5.83346 6.66657H1.03473C0.250741 6.66657 -0.157532 5.75662 0.321959 5.17292L0.386556 5.10175L3.82166 1.66664H0.83355C0.373315 1.66664 0.000228164 1.29355 0.000228164 0.833322C0.000228164 0.405968 0.32192 0.0537437 0.736366 0.00560634L0.83355 0H5.63229Z'
//           fill='#2E56A4'
//         />
//       </svg>
//     </>
//   );
// };

// export default DateDesortSvg;
import * as React from 'react';
const DateDesortSvg = () => (
  <svg
    viewBox='0 0 256 256'
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}>
    <path fill='none' d='M0 0h256v256H0z' />
    <path
      fill='none'
      stroke='#2f57a4'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={16}
      d='m144 168 40 40 40-40M184 112v96'
      className='stroke-000000'
    />
    <path
      stroke='#2f57a4'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={16}
      d='M48 128h72M48 64h136M48 192h56'
      fill='#2f57a4'
      className='fill-000000 stroke-000000'
    />
  </svg>
);
export default DateDesortSvg;

import {
  DELETE_ADFEES_BY_ID,
  GET_ADFEES_BY_ID,
  GET_ALL_ADFEES,
  POST_ADFEES,
  UPDATE_ADFEES_BY_ID,
} from '../../Constant/ActionTypes';

const initialState = {
  allAdFees: false,
  getAdFeesData: false,
  isCreateAdFees: false,
  isUpdateAdFees: false,
};

const AdFeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ADFEES:
      return {
        ...state,
        allAdFees: action.payload,
        isCreateAdFees: false,
        isUpdateAdFees: false,
      };
    case UPDATE_ADFEES_BY_ID:
      return {
        ...state,
        allAdFees: false,
        isUpdateAdFees: true,
      };
    case GET_ADFEES_BY_ID:
      return {
        ...state,
        getAdFeesData: action.payload,
      };
    case POST_ADFEES:
      return {
        ...state,
        allAdFees: false,
        isCreateAdFees: true,
      };
    case DELETE_ADFEES_BY_ID:
      return {
        ...state,
        allAdFees: false,
      };
    default:
      return state;
  }
};

export default AdFeesReducer;

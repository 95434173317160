import {
  DELETE_EVENTOPTIONS_BY_ID,
  GET_ADMIN_ALL_EVENTOPTIONS_BY_EVENT_ID,
  GET_ALL_EVENTOPTIONS,
  GET_ALL_EVENTOPTIONS_BY_QUERY,
  GET_EVENT_OPTIONS_BY_EVENT_ID,
  GET_EVENTOPTIONS_BY_ID,
  POST_EVENTOPTIONS,
  UPDATE_EVENTOPTIONS_BY_ID,
} from '../../Constant/ActionTypes';

const initialState = {
  allEventOptions: false,
  getEventOptionsData: false,
  isCreateEventOptions: false,
  isUpdateEventOptions: false,
  allEventOptionsQueryData: false,
  getEventOptionsDataByUser: false,
  getEventOptionByEventId: false,
  getAdminEventOptionsData: false,
};

const EventOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EVENTOPTIONS:
      return {
        ...state,
        allEventOptions: action.payload,
        isCreateEventOptions: false,
        isUpdateEventOptions: false,
        isUpdateEventOptionsStatus: false,
      };
    case GET_ALL_EVENTOPTIONS_BY_QUERY:
      return {
        ...state,
        allEventOptionsQueryData: action.payload,
        isCreateEventOptions: false,
        isUpdateEventOptions: false,
        isUpdateEventOptionsStatus: false,
      };
    case GET_EVENT_OPTIONS_BY_EVENT_ID:
      return {
        ...state,
        getEventOptionByEventId: action?.payload,
        isCreateEventOptions: false,
        isUpdateEventOptions: false,
        isUpdateEventOptionsStatus: false,
      };
    case GET_EVENTOPTIONS_BY_ID:
      return {
        ...state,
        getEventOptionsData: action.payload,
      };
    case POST_EVENTOPTIONS:
      return {
        ...state,
        isCreateEventOptions: true,
        allEventOptions: false,
        allEventOptionsQueryData: false,
        getAdminEventOptionsData: false,
      };
    case UPDATE_EVENTOPTIONS_BY_ID:
      return {
        ...state,
        isUpdateEventOptions: true,
        allEventOptions: false,
        allEventOptionsQueryData: false,
        getEventOptionByEventId: false,
        getAdminEventOptionsData: false,
      };
    case DELETE_EVENTOPTIONS_BY_ID:
      return {
        ...state,
        allEventOptions: false,
        allEventOptionsQueryData: false,
        getEventOptionByEventId: false,
        getAdminEventOptionsData: false,
      };
    case GET_ADMIN_ALL_EVENTOPTIONS_BY_EVENT_ID:
      return {
        ...state,
        getAdminEventOptionsData: action.payload,
      };
    default:
      return state;
  }
};

export default EventOptionsReducer;

import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  createCompanies,
  editCompanies,
  getCompaniesById,
} from '../../../../Store/Actions';
import CompaniesFormComponent from '../../../../Components/AdminComponents/Companies/CompaniesForm';

const CompaniesFormContainer = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {getCompaniesData} = useSelector(({companies}) => companies);
  const [editData, setEditData] = useState();

  useEffect(() => {
    if (getCompaniesData) {
      setEditData(getCompaniesData?.data);
    }
  }, [getCompaniesData]);

  const postCompanies = async (Data) => {
    dispatch(createCompanies(Data, navigate));
  };

  const updateCompanies = async (Data, id) => {
    dispatch(editCompanies(Data, id, navigate));
  };
  const fetchCompaniesById = async (id) => {
    dispatch(getCompaniesById(id));
  };
  return (
    <div>
      <CompaniesFormComponent
        postCompanies={(Data) => {
          postCompanies(Data);
        }}
        updateCompanies={(id, Data) => {
          updateCompanies(id, Data);
        }}
        fetchCompaniesById={(id) => {
          fetchCompaniesById(id);
        }}
        EditData={editData}
      />
    </div>
  );
};

export default CompaniesFormContainer;

import React, {useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import {RouteConfig} from './AppRouteConfig';
import ProtectedRoute from '../Components/CommonComponents/ProtectedRoute/protectedRoute';
const AppRoute = (props) => {
  return (
    <>
      <Routes>
        {RouteConfig.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <ProtectedRoute
                  element={route.element}
                  allowedLayout={route.allowedLayout}
                  allowedRoles={route.allowedRoles}
                  isProtectedRoute={route.isProtectedRoute}
                />
              }
              allowedRoles={route.allowedRoles}
            />
          );
        })}
      </Routes>
    </>
  );
};

export default AppRoute;

import React, {useEffect, useState, useRef} from 'react';
import './style.css';
import {ArrowRightOutlined} from '@ant-design/icons';
import SectionLayout from '../Layout/sectionLayout';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import jsVectorMap from 'jsvectormap';
import 'jsvectormap/dist/maps/world.js';
import 'jsvectormap/dist/css/jsvectormap.css';
import mapimg from '../../../Assets/map@2x.png';
import view_more_svg from '../../../Assets/view-more-right-arrow.svg';
import mapMarker from '../../../Assets/svgs/pin.svg';
import mapStarMarker from '../../../Assets/svgs/star.svg';
import {fetchAdsByUserId} from '../../../Store/Actions';
import {setChapterById} from '../../../Store/Actions';
import {Col, Row} from 'antd';
import CommonWantToSeeAdsListing from '../CommonWantToSeeAdsListing';
import {getLocalStorageUserInfo} from '../../../Util/commonUtility';
import pinSvg from '../../../Assets/svgs/pin.svg';

const AnyReactComponent = ({text}) => <div>{text}</div>;

const PbssInMap = ({chaptersList, mapSize}) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [shouldRender, setshouldRender] = useState(false);
  const [mFont, setMfont] = useState('10px');

  const handleChapterClick = (chapterId, chapterName) => {
    dispatch(setChapterById(chapterId));
    navigate(`/chapter/${chapterName}`);
  };

  const calculateAndSetFontSize = (windowWidth) => {
    let calculatedFontSize;
    if (windowWidth >= 300 && windowWidth < 600) {
      calculatedFontSize = 11;
    } else if (windowWidth >= 600 && windowWidth < 768) {
      calculatedFontSize = 14;
    } else if (windowWidth >= 768 && windowWidth < 1440) {
      calculatedFontSize = 16;
    } else {
      calculatedFontSize = 16;
    }
    const integerFontSize = parseInt(10);
    return integerFontSize;
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.outerWidth > 469) {
        setshouldRender(true);
      } else {
        setshouldRender(false);
      }
    };
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    let markers = [];
    if (chaptersList?.length > 0) {
      for (let chapter of chaptersList) {
        markers.push({
          name: chapter?.name,
          coords: [chapter.latitude, chapter.longitude],
          id: chapter?.id,
          normalized_name: chapter?.normalized_name,
        });
      }
      const map = new jsVectorMap({
        // world_merc, us_mill_en, us_merc_en,
        // us_lcc_en, us_aea_en, spain
        // russia, canada, iraq
        onLoaded: function (map) {
          window.addEventListener('resize', () => {
            map.updateSize();
            const dynamicFontSize = calculateAndSetFontSize(window.innerWidth);
            setMfont(dynamicFontSize + 'px');
          });
        },
        onMarkerClick: function (event, markerIndex) {
          document.querySelectorAll('.jvm-tooltip').forEach((element) => {
            element.parentNode.removeChild(element);
          });
          let chapterDetails = markers[markerIndex];
          if (chapterDetails) {
            handleChapterClick(
              chapterDetails.id,
              chapterDetails.normalized_name,
            );
          }
        },
        onRegionTooltipShow: function (event, tooltip, code) {
          tooltip.hide();
        },
        map: 'world',
        selector: '#map',
        backgroundColor: 'tranparent',
        draggable: false,
        zoomButtons: false,
        zoomOnScroll: false,
        zoomOnScrollSpeed: 3,
        zoomMax: 12,
        zoomMin: 1,
        zoomAnimate: false,
        zoomStep: 1.5,
        bindTouchEvents: false,
        // Line options
        lineStyle: {
          stroke: '#808080',
          strokeWidth: 1,
          strokeLinecap: 'round',
        },
        focusOn: {}, // focus on regions on page load
        /**
         * Markers options
         */
        markers: markers, // Set of markers to add to the map during initialization
        // labels: {
        //   markers: {
        //     render: function (chapter) {
        //       return chapter?.name;
        //     },
        //   },
        // },
        markersSelectable: true,
        markersSelectableOne: false,
        markerStyle: {
          initial: {
            r: 3,
            fill: '#374151',
            fillOpacity: 1,
            stroke: '#FFF',
            strokeWidth: 5,
            strokeOpacity: 0.5,
            image: pinSvg,
          },
          hover: {
            fill: '#2e56a4',
            cursor: 'pointer',
          },
          // selected: {
          //   fill: 'blue',
          // },
          // selectedHover: {},
        },
        markerLabelStyle: {
          initial: {
            fontFamily: "'Inter', sans-serif",
            fontSize: calculateAndSetFontSize(window.innerWidth) + 'px',
            fontWeight: 700,
            fill: '#000000',
          },
          // You can control the hover and selected state for labels as well.
          hover: {
            fill: '#2e56a4',
          },
          // selected: {
          //   fill: 'blue',
          // },
        },
        /**
         * Region styles
         */
        // labels: {
        //   // add a label for a specific region
        //   regions: {
        //     render(code) {
        //       return ['EG', 'KZ', 'CN'].indexOf(code) > -1
        //         ? 'Hello ' + code
        //         : '';
        //     },
        //   },
        // },
        //  regionsSelectable: true,
        // regionsSelectableOne: false,
        regionStyle: {
          // Region style
          initial: {
            // fill: 'rgb(190, 200, 227) !important',
            // fillOpacity: 1,
            // stroke: 'rgb(190, 200, 227) !important',
            // strokeWidth: 2px !important,
            // strokeOpacity: 1,
          },
          hover: {
            fillOpacity: 0.7,
            cursor: 'default',
          },
          // selected: {
          //   fill: '#000',
          // },
          // selectedHover: {},
        },
        // Tooltip configuration
        showTooltip: true,

        // Region label style
        // regionLabelStyle: {
        //   initial: {
        //     fontFamily: 'Verdana',
        //     fontSize: '12',
        //     fontWeight: 'bold',
        //     cursor: 'default',
        //     fill: '#35373e',
        //   },
        //   hover: {
        //     cursor: 'pointer',
        //   },
        // },
        // series: {
        //   markers: [
        //     // You can add one or more objects to create series for markers.
        //   ],
        //   regions: [
        //     // You can add one or more objects to create series for regions.
        //   ],
        // },
        // map visualization is used to analyze and display the geographically related data and present it in the form of maps.
        visualizeData: {
          scale: ['#eeeeee', '#999999'],
          // values: {
          //   EG: 29,
          //   US: 100,
          //   CA: 190,
          //   BR: 75,
          //   // ...
          // },
        },
      });
    }
  }, [chaptersList, shouldRender]);

  const defaultProps = {
    center: {lat: 59.95, lng: 30.33},
    zoom: 11,
  };

  return (
    <React.Fragment>
      <SectionLayout>
        <Row className='pbss-map-row-container' gutter={[16, 48]}>
          <Col span={24}>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={16}
                className='map-container-column'>
                <div className='map-conatiner'>
                  <div className='d3-map'>
                    <div
                      style={{
                        width: '100%',
                        height: shouldRender ? mapSize : '250px',
                      }}
                      id='map'
                      className='map-js-container'></div>
                  </div>
                </div>
              </Col>
              <Col className='col-ads-listing' xs={24} sm={24} md={24} lg={8}>
                <CommonWantToSeeAdsListing containerHeight={mapSize} />
              </Col>
            </Row>
          </Col>
        </Row>
      </SectionLayout>
    </React.Fragment>
  );
};
export default PbssInMap;

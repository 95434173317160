import React, {useEffect, useState} from 'react';
import UserJobsComponent from '../../../Components/UserComponents/Jobs';
import {getAllJobFees, getJobsByUserId} from '../../../Store/Actions';
import {useDispatch, useSelector} from 'react-redux';
import {
  createSelectionOptionsUtil,
  DefaultListingPageCount,
  getLocalStorageUserInfo,
} from '../../../Util/commonUtility';
const UserJobsContainer = () => {
  let dispatch = useDispatch();
  const {getJobDataByUser} = useSelector(({jobs}) => jobs);
  const [allJobsData, setAllJobsData] = useState([]);
  const {allJobFees} = useSelector(({jobFees}) => jobFees);
  const [jobFeesOptions, setJobFeesOptions] = useState([]);
  const [page, setPage] = useState(
    DefaultListingPageCount?.jobsListingPageCount,
  );
  const [zeroFeeData, setZeroFeeData] = useState([]);
  const [fetchNewData, setFetchNewData] = useState(false);

  useEffect(() => {
    if (getJobDataByUser) {
      setAllJobsData(getJobDataByUser?.data?.jobs);
    } else {
      const userInfo = getLocalStorageUserInfo();
      const userId = userInfo ? userInfo?.id : '';
      setFetchNewData(true);
    }
  }, [getJobDataByUser]);

  useEffect(() => {
    if (allJobFees) {
      setJobFeesOptions(
        createSelectionOptionsUtil(
          allJobFees?.data?.jobFees,
          'id',
          'details',
          'fee',
        ),
      );
      setZeroFeeData(
        allJobFees?.data?.jobFees?.filter((obj) => obj?.fee === 0),
      );
    } else {
      dispatch(
        getAllJobFees(
          {
            page: 1,
            pagecount: 10,
          },
          '',
          '',
          '',
        ),
      );
    }
  }, [allJobFees]);

  return (
    <div>
      <UserJobsComponent
        allJobsData={allJobsData}
        setAllJobsData={setAllJobsData}
        page={page}
        setPage={setPage}
        jobFeesOptions={jobFeesOptions}
        zeroFeeData={zeroFeeData}
        fetchNewData={fetchNewData}
        setFetchNewData={setFetchNewData}
      />
    </div>
  );
};

export default UserJobsContainer;

import * as React from 'react';
const ExpandSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={21}
    height={21}
    viewBox='0 0 21 21'
    fill='none'>
    <g clipPath='url(#clip0_891_1576)'>
      <path
        d='M3.9375 0C1.77905 0 0 1.77905 0 3.9375V17.0625C0 19.2209 1.77905 21 3.9375 21H17.0625C19.2209 21 21 19.2209 21 17.0625V3.9375C21 1.77905 19.2209 0 17.0625 0H3.9375ZM3.9375 2.625H17.0625C17.811 2.625 18.375 3.18897 18.375 3.9375V17.0625C18.375 17.811 17.811 18.375 17.0625 18.375H3.9375C3.18897 18.375 2.625 17.811 2.625 17.0625V3.9375C2.625 3.18897 3.18897 2.625 3.9375 2.625Z'
        fill='white'
      />
      <path d='M5.25 9.1875H15.75V11.8125H5.25V9.1875Z' fill='white' />
      <path d='M11.8125 5.25V15.75H9.1875V5.25H11.8125Z' fill='white' />
    </g>
    <defs>
      <clipPath id='clip0_891_1576'>
        <rect width={21} height={21} fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export default ExpandSvg;

import Home from '../Screens/UserScreen/Home';
import Dashboard from './../Screens/AdminScreen/Dashboard';
import Notfound from '../Screens/AdminScreen/ErrorPages/notFound';
import NotAuthorized from '../Screens/AdminScreen/ErrorPages/notAuthrized';
import SignInScreen from '../Screens/AdminScreen/Auth/SignIn';
import SignUpScreen from './../Screens/AdminScreen/Auth/SignUp';
import JobFeeScreen from '../Screens/AdminScreen/JobFees';
import JobFeesFormScreen from '../Screens/AdminScreen/JobFees/JobFeesForm';
import AdFeesScreen from '../Screens/AdminScreen/AdFees';
import AdFeesFormScreen from '../Screens/AdminScreen/AdFees/AdFeesForm';
import ChapterScreen from '../Screens/AdminScreen/Chapters';
import AddChapterScreen from '../Screens/AdminScreen/Chapters/AddChapter';
import AdsScreen from '../Screens/AdminScreen/Ads';
import AdsFormScreen from '../Screens/AdminScreen/Ads/AdsForm';
import UserScreen from '../Screens/AdminScreen/Users';
import UserFormScreen from '../Screens/AdminScreen/Users/UserForm';
import JobsScreen from '../Screens/AdminScreen/Jobs';
import JobsFormScreen from '../Screens/AdminScreen/Jobs/JobsForm';
import SponsorScreen from '../Screens/AdminScreen/Sponsors';
import SponsorsFormScreen from '../Screens/AdminScreen/Sponsors/SponsorsForm';
import KeywordScreen from '../Screens/AdminScreen/Keywords';
import KeywordFormScreen from '../Screens/AdminScreen/Keywords/KeywordsForm';
import EventTypesScreen from '../Screens/AdminScreen/EventTypes';
import EventTypesFormScreen from '../Screens/AdminScreen/EventTypes/EventTypesForm';
import SpeakersScreen from '../Screens/AdminScreen/Speakers';
import SpeakersFormScreen from '../Screens/AdminScreen/Speakers/SpeakersForm';
import CompaniesScreen from '../Screens/AdminScreen/Companies';
import CompaniesFormScreen from '../Screens/AdminScreen/Companies/CompaniesForm';
import CouponCodesScreen from '../Screens/AdminScreen/CouponCodes';
import CouponCodesFormScreen from '../Screens/AdminScreen/CouponCodes/CouponCodesForm';
import EmailCodeScreen from '../Screens/AdminScreen/EmailCode';
import EmailCodeFormScreen from '../Screens/AdminScreen/EmailCode/EmailCodeForm';
import UserJobsScreen from './../Screens/UserScreen/Jobs/index';
import UserAdsScreen from './../Screens/UserScreen/Ads/index';
import JobsViewDetailsScreen from '../Screens/UserScreen/Jobs/ViewDetails';
import EventOptionGroupsScreen from '../Screens/AdminScreen/EventOptionGroups';
import EventOptionGroupsFormScreen from './../Screens/AdminScreen/EventOptionGroups/EventOptionGroupsForm/index';
import EventOptionsScreen from '../Screens/AdminScreen/EventOptions';
import EventOptionsFormScreen from './../Screens/AdminScreen/EventOptions/EventOptionsForm/index';
import EventsFormScreen from './../Screens/AdminScreen/Events/EventsForm/index';
import EventsScreen from './../Screens/AdminScreen/Events/index';
import ResetSendEmailScreen from '../Screens/AdminScreen/Auth/ResetPassword/sendEmail';
import ResetUpdatePassScreen from './../Screens/AdminScreen/Auth/ResetPassword/updatePassword';
import PbssInScreen from '../Screens/UserScreen/PbssInternational';
import EventDetailsScreen from '../Screens/UserScreen/PbssInternational/EventDetails';
import PbssChapter from '../Screens/UserScreen/PbssChapter';
import PbssChapterScreen from '../Screens/UserScreen/PbssChapter';
import LoginScreen from '../Screens/UserScreen/Login';
import RegisterScreen from '../Screens/UserScreen/Register';
import SponsorshipInfoScreen from '../Screens/UserScreen/SponsorshipInfo';
import VendorShowScreen from '../Screens/UserScreen/VendorInfo';
import SponsorPolicyScreen from '../Screens/UserScreen/SponsorPolicy';
import AboutUsScreen from '../Screens/UserScreen/AboutUs';
import EventTemplateSchedulerScreen from '../Screens/AdminScreen/EventTemplateScheduler';
import EventTempFormScreen from '../Screens/AdminScreen/EventTemplateScheduler/EventTempForm';
import AddAttendeeScreen from '../Screens/UserScreen/AddAttendees';
import AttendeesCartScreen from '../Screens/UserScreen/AttendeesCart';
import AssetsUploadScreen from '../Screens/UserScreen/AssetsUpload';
import EventSponsorScreen from '../Screens/UserScreen/EventSponsor';
import EventSponsorCartScreen from '../Screens/UserScreen/EventSponsorCart';
import ApprovedJobsScreen from '../Screens/UserScreen/Jobs/ApprovedJobs';
import ApprovedAdsScreen from './../Screens/UserScreen/Ads/ApprovedAds/index';
import ContactUsScreen from '../Screens/UserScreen/ContactUS';
import EventRegistrationScreen from '../Screens/AdminScreen/EventsRegistration';
import MyEventScreen from '../Screens/UserScreen/MyEvent';
import UserUpdateProfileScreen from '../Screens/UserScreen/UserUpdateProfile';
import EmailToMembersScreen from '../Screens/AdminScreen/EmailToMember';
import EventRefundScreen from './../Screens/AdminScreen/EventRefund/index';
import SponsorshipOptionScreen from '../Screens/UserScreen/SponsorshipOption';
import SponsorPerkForm from './../Components/UserComponents/SponsorPerkForm/index';
import AdsCartScreen from '../Screens/UserScreen/Ads/AdsCart';
import PaymentHistoryScreen from '../Screens/UserScreen/PaymentHistory';
import PaymentCheckoutScreen from '../Screens/UserScreen/PaymentCheckoutForm';
import SpecialPaymentScreen from '../Screens/UserScreen/SpecialPaymentScreen';
import JobsCartScreen from './../Screens/UserScreen/Jobs/JobsCart/index';
import UnsubscribeScreen from '../Screens/UserScreen/Unsubscribe';
import FaqScreen from '../Screens/UserScreen/Faq';
import StemOutReachScreen from '../Screens/UserScreen/StemOutreach';
import OtherPartnersScreen from '../Screens/UserScreen/OtherPartners';
import EventTypesStaticScreen from '../Screens/UserScreen/EventTypesStaticScreen/EventTypesStaticScreen';
import SponsorInfoDetailsScreen from '../Screens/UserScreen/SponsorInfoDetails';
import AdminSpecialPaymentScreen from '../Screens/AdminScreen/AdminSpecialPaymentScreen';
import AdminSpecialPaymentFormScreen from '../Screens/AdminScreen/AdminSpecialPaymentScreen/AdminSpecialPaymentFormScreen';
import MyEventCartScreen from './../Screens/MyEventsCart/index';
import StemScreen from '../Screens/AdminScreen/Stem';
import PaymentSuccessScreen from '../Screens/UserScreen/PaymentSuccess';
import PaymentFailedScreen from '../Screens/UserScreen/PaymentFailed';
export const RouteConfig = [
  {
    path: 'admin/dashboard',
    element: <Dashboard />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/signin',
    element: <SignInScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/resetPassword',
    element: <ResetSendEmailScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/updatePassword',
    element: <ResetUpdatePassScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/signup',
    element: <SignUpScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '*',
    element: <Notfound />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/notAuthorized',
    element: <NotAuthorized />,
    isProtectedRoute: false,
    allowedRoles: [],
  },
  {
    path: '/updateProfile',
    element: <UserUpdateProfileScreen />,
    allowedLayout: 'main',
    isProtectedRoute: true,
    allowedRoles: [],
  },
  {
    path: '/admin/jobfees',
    element: <JobFeeScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/jobfees/add',
    element: <JobFeesFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/jobfees/update/:id',
    element: <JobFeesFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/adfees',
    element: <AdFeesScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/adfees/add',
    element: <AdFeesFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/adfees/update/:id',
    element: <AdFeesFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/chapters',
    element: <ChapterScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/chapters/add',
    element: <AddChapterScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/chapters/update/:id',
    element: <AddChapterScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/ads',
    element: <AdsScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/ads/add',
    element: <AdsFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/ads/update/:id',
    element: <AdsFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/memberAttendees',
    element: <UserScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/memberAttendees/add',
    element: <UserFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/emailToMembers',
    element: <EmailToMembersScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/specialPayment',
    element: <AdminSpecialPaymentScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/specialPayment/add',
    element: <AdminSpecialPaymentFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/memberAttendees/update/:id',
    element: <UserFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/jobs',
    element: <JobsScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/jobs/add',
    element: <JobsFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/jobs/update/:id',
    element: <JobsFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/sponsors',
    element: <SponsorScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/sponsors/add',
    element: <SponsorsFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/sponsors/update/:id',
    element: <SponsorsFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    // path: '/admin/keywords',
    path: '/admin/interests',
    element: <KeywordScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    // path: '/admin/keywords/add',
    path: '/admin/interests/add',
    element: <KeywordFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    // path: '/admin/keywords/update/:id',
    path: '/admin/interests/update/:id',
    element: <KeywordFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/eventTypes',
    element: <EventTypesScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/eventTypes/add',
    element: <EventTypesFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/eventTypes/update/:id',
    element: <EventTypesFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  // {
  //   path: '/admin/speakers',
  //   element: <SpeakersScreen />,
  //   isProtectedRoute: true,
  //   allowedLayout: 'admin',
  //   allowedRoles: ['super_admin', 'admin'],
  // },
  // {
  //   path: '/admin/speakers/add',
  //   element: <SpeakersFormScreen />,
  //   isProtectedRoute: true,
  //   allowedLayout: 'admin',
  //   allowedRoles: ['super_admin', 'admin'],
  // },
  {
    path: '/admin/speakers/update/:id',
    element: <SpeakersFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/companies',
    element: <CompaniesScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/companies/add',
    element: <CompaniesFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/companies/update/:id',
    element: <CompaniesFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/couponCodes',
    element: <CouponCodesScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/couponCodes/add',
    element: <CouponCodesFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/couponCodes/update/:id',
    element: <CouponCodesFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/emailTemplates',
    element: <EmailCodeScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/emailTemplates/add',
    element: <EmailCodeFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/emailTemplates/update/:id',
    element: <EmailCodeFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/eventOptionGroups',
    element: <EventOptionGroupsScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/eventOptionGroups/add',
    element: <EventOptionGroupsFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/eventOptionGroups/update/:id',
    element: <EventOptionGroupsFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/eventOptions/:id',
    element: <EventOptionsScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/eventOptions/add/:eventId',
    element: <EventOptionsFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/eventOptions/update/:id',
    element: <EventOptionsFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/events',
    element: <EventsScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/events/add',
    element: <EventsFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/events/update/:id',
    element: <EventsFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/eventRegistration/:id',
    element: <EventRegistrationScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/eventRefund/:id',
    element: <EventRefundScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/eventEmailTemplatesScheduler',
    element: <EventTemplateSchedulerScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/eventEmailTemplatesScheduler/:id',
    element: <EventTemplateSchedulerScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['admin'],
  },
  {
    path: '/admin/eventEmailTemplatesScheduler/add/:id',
    element: <EventTempFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/admin/eventEmailTemplatesScheduler/update/:id/:eventID/:action',
    element: <EventTempFormScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },
  {
    path: '/jobs',
    element: <UserJobsScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/ads',
    element: <UserAdsScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/ads/cart/:id',
    element: <AdsCartScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/jobs/cart/:id',
    element: <JobsCartScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/jobs/:id',
    element: <JobsViewDetailsScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/jobs/approved',
    element: <ApprovedJobsScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/ads/approved',
    element: <ApprovedAdsScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/',
    element: <PbssInScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/eventDetails/:id',
    element: <EventDetailsScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/chapter/:chapterName',
    element: <PbssChapterScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/login',
    element: <LoginScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/register',
    element: <RegisterScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/myEvents/:registrationType',
    element: <MyEventScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/myEvents',
    element: <MyEventScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },

  {
    path: '/myCart',
    element: <MyEventCartScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },

  {
    path: '/sponsorPerkForm',
    element: <SponsorPerkForm />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/sponsorAssetsUpload',
    element: <AssetsUploadScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/addAttendees/:id',
    element: <AddAttendeeScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/attendeesCart/:id',
    element: <AttendeesCartScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/sponsorshipOption/:id',
    element: <SponsorshipOptionScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/eventSponsor/:id/:optionId',
    element: <EventSponsorScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/eventSponsorCart/:id/:optionId',
    element: <EventSponsorCartScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/attendeesCart/:id/:transactionId',
    element: <AttendeesCartScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/eventSponsorCart/:id/:optionId/:transactionId',
    element: <EventSponsorCartScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/eventMajorSponsor',
    element: <SponsorshipInfoScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/eventVendorShow',
    element: <VendorShowScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/eventSponsorPolicy',
    element: <SponsorPolicyScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/aboutUs',
    element: <AboutUsScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/contactUs',
    element: <ContactUsScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/faq',
    element: <FaqScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/stemOutreach',
    element: <StemOutReachScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/otherPartners',
    element: <OtherPartnersScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/payment/:attendeeType/:id/:optionId',
    element: <PaymentCheckoutScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/payment/:attendeeType/:id',
    element: <PaymentCheckoutScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/specialPayment',
    element: <SpecialPaymentScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/paymentHistory',
    element: <PaymentHistoryScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: ['admin', 'user', 'super_admin'],
  },
  {
    path: '/unsubscribe',
    element: <UnsubscribeScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: 'eventType/:eventTypeName',
    element: <EventTypesStaticScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/sponsorshipInfo',
    element: <SponsorInfoDetailsScreen />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/admin/stem',
    element: <StemScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['super_admin', 'admin'],
  },

  {
    path: '/paymentSuccess/:action/:registrationType',
    element: <PaymentSuccessScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/paymentSuccess/:action',
    element: <PaymentSuccessScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: [],
  },
  {
    path: '/paymentFailed/:action',
    element: <PaymentFailedScreen />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: [],
  },
];

import React, {useEffect, useState} from 'react';
import {deleteUnPaidSponsorshipFromCart} from '../../../Store/Actions';
import {useDispatch, useSelector} from 'react-redux';
import SponsorshipOptionComponent from '../../../Components/UserComponents/SponsorshipOption';
import {getEventOptionsByEventId} from '../../../Store/Actions/eventOptions';
import {useParams} from 'react-router-dom';

const SponsorshipOptionContainer = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const [allEventOptionsRegistrationData, setAllEventOptionsRegistrationData] =
    useState([]);
  const {getEventOptionByEventId} = useSelector(
    ({eventOptions}) => eventOptions,
  );

  useEffect(() => {
    if (
      getEventOptionByEventId?.data?.sponsorship?.length > 0
        ? parseInt(id) ===
          getEventOptionByEventId?.data?.sponsorship[0]?.event_id
        : getEventOptionByEventId
    ) {
      const filteredSponsorData = getEventOptionByEventId?.data?.sponsorship;

      const qtyFilterData = filteredSponsorData?.filter(
        (data) => data?.qty !== 0,
      );
      const alreadyRegisteredFilterData = filteredSponsorData?.filter(
        (data) => data?.addedToCart,
      );

      setAllEventOptionsRegistrationData(filteredSponsorData);
    } else {
      dispatch(getEventOptionsByEventId(id));
    }
  }, [getEventOptionByEventId]);

  const getEventSponsorOptions = (id) => {
    dispatch(getEventOptionsByEventId(id));
  };
  const removeSponsorShipFromCart = (eventId) => {
    dispatch(deleteUnPaidSponsorshipFromCart(eventId));
  };

  return (
    <SponsorshipOptionComponent
      allEventOptionsRegistrationData={allEventOptionsRegistrationData}
      getEventSponsorOptions={(id) => {
        getEventSponsorOptions(id);
      }}
      removeSponsorShipFromCart={(eventId) => {
        removeSponsorShipFromCart(eventId);
      }}
    />
  );
};

export default SponsorshipOptionContainer;

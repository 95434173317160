import React, {useEffect, useState} from 'react';
import {Route, useNavigate} from 'react-router-dom';
import IsAuthorized from './roleCheck';
import MainLayout from '../Layout/mainLayout';
import AdminLayout from '../Layout/adminLayout';
import {useJWTAuthActions} from '../../../Api/JWTAuthProvider';

const ProtectedRoute = ({
  element,
  allowedLayout,
  isProtectedRoute,
  allowedRoles,
}) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const {logout} = useJWTAuthActions();
  const checkAuthToken = () => {
    const authToken = localStorage.getItem('token');
    if ((!authToken || authToken === 'undefined') && isProtectedRoute) {
      logout();
      setIsLoggedIn(false);
      // if (allowedLayout === 'main') {
      //   return navigate('/login');
      // }
      // if (allowedLayout === 'admin') {
      //   return navigate('/signin');
      // }
      return navigate('/login');
    }
    setIsLoggedIn(true);
  };
  const checkLayoutType = (element) => {
    switch (allowedLayout) {
      case 'main':
        return <MainLayout>{element}</MainLayout>;
      case 'admin':
        return <AdminLayout>{element}</AdminLayout>;
      default:
        return element;
    }
  };
  useEffect(() => {
    checkAuthToken();
  }, [isLoggedIn]);

  return isProtectedRoute ? (
    <IsAuthorized
      isLoggedIn={isLoggedIn}
      allowedRoles={allowedRoles}
      element={checkLayoutType(element)}
    />
  ) : (
    <>{checkLayoutType(element)}</>
  );
};
export default ProtectedRoute;

import React, {useState, useEffect} from 'react';
import {Table, Space, Button, Tooltip, Popconfirm, Tag, Popover} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  FormOutlined,
  FilterOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import {useNavigate, useParams} from 'react-router-dom';
import {
  AdsColumnValues,
  PageSizeArray,
  AdStatus,
  PaginationPosition,
  formatDate,
  EventStatus,
  CronJobStatus,
  formatDataForScheculer,
  getEmailDetails,
} from '../../../Util/commonUtility';
import moment from 'moment';
import {
  deleteEventTemplateSchedulerById,
  editEventTemplateSchedulerStatusById,
  getAllEventTemplateSchedulers,
  getUserDetails,
} from '../../../Store/Actions';
import plus from '../../../Assets/Plus.svg';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import {ApplicationString} from '../../../Localization/Locales/en';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {useSelector} from 'react-redux';

const EventTemplateSchedulerComponent = (props) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [eventTemplateData, setEventTemplateData] = useState([]);
  const editTemplateParams = 'updateTemplate';
  const editTeplateSchedulerParams = 'updateTemplateSchedule';
  const toggleExpand = () => {
    // setExpanded(!expanded);
    navigate('/admin/events');
  };
  const {
    allEventTemplateSchedulers,
    fetchEventTemplateSchedulers,
    eventTemplateSchedulerData,
    paging,
    handlePaginationChange,
    fetchEventTempaltesByEventId,
    DeleteEventTemplateScheduler,
    sortParams,
    handleTableChange,
    updateStatusById,
    handleEditTemplate,
    sendDirectEmailByTemplateId,
  } = props;
  const ps = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.eventTemplateSchedulers,
  );
  const handleSearch = (values) => {
    fetchAds(values);
  };

  const handleEditClick = async (id) => {
    navigate(`/admin/eventEmailTemplatesScheduler/update/${id}`);
  };

  useEffect(() => {
    const perId = Number(id);
    if (perId) {
      fetchEventTempaltesByEventId(perId);
    }
    // else {
    //   setEventTemplateData(allEventTemplateSchedulers);
    // }
  }, [id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 60,
    },
    // {
    //   title: 'Event ID',
    //   dataIndex: 'event_id',
    //   key: 'eventId',
    //   width: 100,
    // },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      width: '15%',
    },
    {
      title: 'Email Code Template',
      dataIndex: 'email_template_body',
      key: 'email_template_body',
      render: (text) => {
        const html = {__html: text};
        return (
          <Popover
            prefixCls='extend-popover-event-temaplate'
            content={
              <div
                className='pop-over-scroll'
                dangerouslySetInnerHTML={html}></div>
            }
            trigger='hover'
            title='Description'
            arrow={false}>
            <Button type='text' prefixCls='common-button-popover'>
              <EyeOutlined /> View Email Code Template
            </Button>
          </Popover>
        );
      },
    },
    {
      title: 'User Group',
      dataIndex: 'email_template_type',
      key: 'email_template_body',
      width: '20%',
      render: (text) => {
        const emailDetails = getEmailDetails(text);
        return <div>{emailDetails.description}</div>;
      },
    },
    {
      title: 'Schedule Date',
      dataIndex: 'scheduleDate',
      key: 'scheduleDate',
      width: '10%',
      render: (_, record) => {
        let scheduleDate;
        if (record?.schedule_date)
          scheduleDate = formatDataForScheculer(record?.schedule_date);
        else scheduleDate = 'Email not scheduled';
        return (
          <Tag color={'success'} className='date-common-tag'>
            {`${scheduleDate}`}
          </Tag>
        );
      },
    },
    {
      title: 'Schedule Status',
      dataIndex: 'cron_job_status',
      width: '10%',
      fixed: 'right',
      render: (cron_job_status) => {
        const updatedStatus = +cron_job_status;
        let color;
        let label;
        switch (updatedStatus) {
          case CronJobStatus.RUN_SUCCESSFULLY:
            color = 'green';
            label = 'Run successfully';
            break;
          case CronJobStatus.FAILED:
            color = 'red';
            label = 'Failed to run schedule';
            break;
          default:
            color = 'purple';
            label = 'Schedule not initiated yet';
        }
        return (
          <Tag color={color} style={{fontSize: '15px', marginBottom: '5px'}}>
            {label}
          </Tag>
        );
      },
    },
    {
      title: '',
      key: 'action',
      width: '4%',
      fixed: 'right',
      render: (_, record) => (
        <AdminActionsDropdown
          record={record}
          editBool={false}
          deleteBool={true}
          sendDirectEmail={true}
          // handleEditClick={handleEditClick}
          handleDeleteClick={DeleteEventTemplateScheduler}
          handleSendDirectEmailClick={sendDirectEmailByTemplateId}
          text='Event Template Scheduler'
          handleEditTemplate={(stateValue, id) => {
            handleEditTemplate(stateValue);
            navigate(
              `/admin/eventEmailTemplatesScheduler/update/${id}/${record?.event_id}/${editTemplateParams}`,
            );
          }}
          handleEditSchedule={(stateValue, id) => {
            handleEditTemplate(stateValue);
            navigate(
              `/admin/eventEmailTemplatesScheduler/update/${id}/${record?.event_id}/${editTeplateSchedulerParams}`,
            );
          }}
        />
      ),
    },
  ];

  // Apply sorting to columns
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sorter = true;
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });

  return (
    <div className='main-container'>
      <div className='search-container' style={{gap: '10px'}}>
        <Button
          onClick={toggleExpand}
          id='EventTemplateSchedulerComponent-button-filterSearch'>
          {/* {expanded ? <FilterOutlined /> : <FilterOutlined />}{' '}
          {ApplicationString['dynamicSearch.button.heading']} */}
          Back To Events
        </Button>
        <Button
          type='primary'
          onClick={() =>
            navigate(`/admin/eventEmailTemplatesScheduler/add/${id}`)
          }
          id='EventTemplateSchedulerComponent-button-addEventTemplateScheduler'
          className='common-button'>
          <div className='icon-container'>
            <img src={plus} alt='plus' className='icon-plus' />
            <span className='add-content'>
              {ApplicationString['eventTemplateScheduler.button.addForm']}
            </span>
          </div>
        </Button>
      </div>
      {expanded && (
        <DynamicSearchForm columns={columns} onSearch={handleSearch} />
      )}
      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          columns={columns}
          onChange={handleTableChange}
          dataSource={eventTemplateSchedulerData}
          pagination={{
            onChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            current: paging.page,
            total: paging.totalCount,
            pageSize: ps || paging.pageCount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default EventTemplateSchedulerComponent;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import {
  deleteEventOptionsById,
  getAllEventOptionsByEventId,
  getAllEventOptionsByQueryString,
} from './../../../Store/Actions/eventOptions';
import EventOptionsComponent from '../../../Components/AdminComponents/EventOptions';
import {useParams} from 'react-router-dom';

const EventOptionsContainer = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {allEventOptionsQueryData, getAdminEventOptionsData} = useSelector(
    ({eventOptions}) => eventOptions,
  );

  const [eventOptionsData, setEventOptionsData] = useState();
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.eventOptions,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.eventOptions,
  );
  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pagecount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'event_option_type',
    order: 'descend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  // useEffect(() => {
  //   if (allEventOptionsQueryData) {
  //     setEventOptionsData(allEventOptionsQueryData?.data?.eventOptions);
  //     setPagination({
  //       ...pagination,
  //       page: allEventOptionsQueryData?.data?.currentPage,
  //       totalPages: allEventOptionsQueryData?.data?.totalPage,
  //       totalCount: allEventOptionsQueryData?.data?.totalCount,
  //     });
  //   } else {
  //     fetchEventOptions();
  //   }
  // }, [allEventOptionsQueryData]);

  useEffect(() => {
    if (getAdminEventOptionsData) {
      setEventOptionsData(getAdminEventOptionsData?.data?.eventOptions);
      setPagination({
        ...pagination,
        // page: allEventOptionsQueryData?.data?.currentPage,
        // totalPages: allEventOptionsQueryData?.data?.totalPage,
        totalCount: getAdminEventOptionsData?.data?.totalCount,
      });
    } else {
      fetchEventOptionsByEventId(id);
    }
  }, [getAdminEventOptionsData]);

  useEffect(() => {
    fetchEventOptionsByEventId(id);
  }, [id]);

  // useEffect(() => {
  //   if (paginationAndSortChanged) {
  //     fetchEventOptions();
  //     setPaginationAndSortChanged(false);
  //   }
  // }, [paginationAndSortChanged]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchEventOptionsByEventId(id);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  const handlePaginationChange = (current, pageSize) => {
    setPagination({
      ...pagination,
      page: current,
      pagecount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };

  // const fetchEventOptions = (searchValues) => {
  //   const sortField = sortParams.field || '';
  //   const sortOrder = sortParams.order || '';
  //   const searchQueryData = queryString(searchValues);
  //   dispatch(
  //     getAllEventOptionsByQueryString(
  //       pagination,
  //       sortField,
  //       sortOrder,
  //       searchQueryData,
  //     ),
  //   );
  // };

  const fetchEventOptionsByEventId = (id) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    dispatch(getAllEventOptionsByEventId(id, sortField, sortOrder));
  };

  const deleteEventOptions = (id) => {
    dispatch(deleteEventOptionsById(id));
  };

  return (
    <div>
      <EventOptionsComponent
        allEventOptionsData={eventOptionsData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        handleTableChange={handleTableChange}
        sortParams={sortParams}
        // fetchEventOptions={(searchValues) => {
        //   fetchEventOptions(searchValues);
        // }}
        deleteEventOptions={(id) => {
          deleteEventOptions(id);
        }}
      />
    </div>
  );
};

export default EventOptionsContainer;

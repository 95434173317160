import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SOMETHING_WENT_WRONG,
  GET_ALL_EMAILCODES,
  ADD_EMAILCODE_SUCCESS,
  FETCH_EMAILCODE_BY_ID,
  UPDATE_EMAILCODE_BY_ID,
  DELETE_EMAILCODE_BY_ID,
  SHOW_MESSAGE,
  GET_ALL_EMAILCODES_SEARCH,
  SET_EMAIL_TEMPLATE_TYPE,
  POST_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_SUCCESS,
} from '../../Constant/ActionTypes';

import Urls from '../../Constant/Urls';
import {httpClient} from '../../Api/client';
import {commonSortOrder, handleJwtExpireMsg} from '../../Util/commonUtility';

//EMAILCODE
export const getAllEmailCodes = (
  sortField,
  sortOrder,
  queryString,
  pagination,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.EMAILCODE_GET_ALL}?page=${pagination.page}&pageCount=${
          pagination.pageCount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${queryString}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_EMAILCODES, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const getAllEmailCodesSearch = (
  sortField,
  sortOrder,
  queryString,
  pagination,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.EMAILCODE_GET_ALL}?page=${pagination.page}&pageCount=${
          pagination.pageCount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${queryString}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_EMAILCODES_SEARCH, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllsEmailCodes = () => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(`${Urls.EMAILCODE_GET_ALL}`);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_EMAILCODES, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addEmailCode = (emailCodeData, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.post(Urls.EMAILCODE_ADD, emailCodeData);
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: ADD_EMAILCODE_SUCCESS, payload: response?.data});
        navigate && navigate('/admin/emailTemplates');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const fetchEmailCodeById = (emailCodeId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.get(
        Urls.EMAILCODE_FETCH_BY_ID.replace('#{emailCodeId}', emailCodeId),
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_EMAILCODE_BY_ID, payload: response?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateEmailCodeById = (
  updatedEmailCodeData,
  emailCodeId,
  navigate,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.patch(
        Urls.EMAILCODE_UPDATE_BY_ID.replace('#{emailCodeId}', emailCodeId),
        updatedEmailCodeData,
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: UPDATE_EMAILCODE_BY_ID, payload: response.data});
        navigate && navigate('/admin/emailTemplates');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteEmailCodeById = (emailCodeId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.delete(
        Urls.EMAILCODE_DELETE_BY_ID.replace('#{emailCodeId}', emailCodeId),
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: DELETE_EMAILCODE_BY_ID, payload: emailCodeId});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const resetEmailCodesSearchState = (state) => {
  return async (dispatch) => {
    try {
      dispatch({type: GET_ALL_EMAILCODES_SEARCH, payload: state});
    } catch (error) {}
  };
};
export const storeEmailtemplateType = (state) => {
  return async (dispatch) => {
    try {
      dispatch({type: SET_EMAIL_TEMPLATE_TYPE, payload: state});
    } catch (error) {}
  };
};

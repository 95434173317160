import React from 'react';
// import './style.css'
import CustomizedTable from '../../../CommonComponents/CommonTable';
import ComponentTitle from '../../../CommonComponents/CommonComponentTitle';
import SectionLayout from '../../../CommonComponents/Layout/sectionLayout';
import {ApplicationString} from '../../../../Localization/Locales/en';

const WorkShopAgenda = ({
  eventType,
  TableData,
  usedForBio = false,
  usedForSpeakerBio = false,
}) => {
  let title;
  if (usedForBio) {
    title = ApplicationString['userWorkShopAgendaComponent.heading.Bio'];
  } else if (usedForSpeakerBio) {
    title = ApplicationString['userWorkShopAgendaComponent.heading.Speaker'];
  } else {
    title = ApplicationString['userWorkShopAgendaComponent.heading.Info'];
  }

  return (
    <SectionLayout>
      <React.Fragment>
        <ComponentTitle hideButton textColor={'black'} title={title} />
        <CustomizedTable
          eventType={eventType}
          usedForBio={usedForBio}
          TableData={TableData}
        />
      </React.Fragment>
    </SectionLayout>
  );
};

export default WorkShopAgenda;

import React, {useEffect} from 'react';
import {Card, Button, Carousel, Image, Row, Col, App} from 'antd';
import './index.css';
import {CalendarOutlined} from '@ant-design/icons';
import moment from 'moment/moment';
import demoJobs from '../../../../Assets/demo_jobs.png';
import {applicationConfig} from '../../../../Util/config';
import SectionLayout from '../../../CommonComponents/Layout/sectionLayout';
import PageHeading from './../../../CommonComponents/PageHeading/index';
import PageTitleCard from './../../../CommonComponents/PageTitleCard/index';
import {formatDate} from '../../../../Util/commonUtility';
// import jobsimg from '../../../../Assets/Jobs.png';
import {ApplicationString} from '../../../../Localization/Locales/en';
import SponsorOptionPageHeroText from '../../../CommonComponents/SponsorOptionPageHeroText';
import {addHttpsToUrl} from './../../../../Util/commonUtility';
const JobsViewDetailsComponent = (props) => {
  const {jobsDataById} = props;
  const markupDesc = {__html: jobsDataById?.description};
  const markupReq = {__html: jobsDataById?.requirement};
  const files = jobsDataById.banner_ids;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <React.Fragment>
      <SponsorOptionPageHeroText
        title={ApplicationString['userJobViewDetailsComponent.newHeading']}
        subTitle={
          ApplicationString['userJobViewDetailsComponent.newSubHeading']
        }
      />
      <SectionLayout>
        <div className='jobs-details-container'>
          {/* <div className='details-content-container'> */}
          {/* <Row>
              <Col>
                <div className='details-position'>
                  <span className='details-position-heading'>
                    {
                      ApplicationString[
                        'userJobViewDetailsComponent.jobPositionText'
                      ]
                    }
                  </span>
                  <span className='details-position-text'>
                    {jobsDataById.position}
                  </span>
                </div>
              </Col>
            </Row> */}
          {/* <Row>
              <div className='details-date-container'>
                <div className='details-date'>
                  <span className='details-calender-icon'>
                    <CalendarOutlined className='calender-icon' />
                  </span>
                  <span className='details-date-text'>
                    {formatDate(jobsDataById?.end_date) +
                      ' ' +
                      '| Pacific Time'}
                  </span>
                </div>
                <div className='details-location'>
                  <Button className='details-location-container'>
                    <span className='details-location-text'>
                      {jobsDataById.location}
                    </span>
                  </Button>
                </div>
              </div>
            </Row> */}
          {/* <div className='details-carousel-container'>
              <Carousel>
                {files && files.length > 0 ? (
                  files.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className='details-banner-image-container'>
                        <Image
                          crossOrigin='anonymous'
                          src={`${applicationConfig.UPLOAD_URL}/${item.name}`}
                          alt='banner'
                          className='details-banner-image'
                        />
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img
                      crossOrigin='anonymous'
                      src={demoJobs}
                      alt='banner'
                      className='details-banner-image'
                    />
                  </div>
                )}
              </Carousel>
              <div className='details-employer'>
                <span className='details-employer-text'>
                  Employer : {jobsDataById?.employer}
                </span>
              </div>
            </div> */}
          {/* </div> */}

          <Row className='details-jobs-content'>
            {/* <Col> */}
            {/* <div className='details-description'>

                <div
                  className='details-description-text'
                  dangerouslySetInnerHTML={markupDesc}></div>
              </div>
            </Col> */}
            <Col span={24} className='job-details-upper-box-column'>
              <div className='job-small-details-wrapper'>
                <div className='employer-position-applyNow-wrapper'>
                  <div className='employer-position-wrapper'>
                    <span className='employer-name'>
                      {jobsDataById?.employer}
                    </span>
                    <span className='job-position'>
                      {`(${jobsDataById?.position?.toUpperCase()})`}
                    </span>
                  </div>
                  {/* ----------- apply now button ---------------------- */}
                  {jobsDataById?.attachment_url && (
                    <div className='details-button-container'>
                      <a
                        href={addHttpsToUrl(jobsDataById?.attachment_url)}
                        target='_blank'
                        rel='noreferrer'>
                        <Button type='primary' className='details-button'>
                          <span className='details-button-text'>
                            {
                              ApplicationString[
                                'userJobViewDetailsComponent.button.applyNow'
                              ]
                            }
                          </span>
                        </Button>
                      </a>
                    </div>
                  )}
                </div>
                <div className='job-view-details-location-date-wrapper'>
                  {
                    <span className='common-job-listing-locationText'>
                      {jobsDataById?.location
                        ? jobsDataById?.location
                        : 'Location N/A'}
                    </span>
                  }
                  {
                    <span className='common-job-listing-dateText'>
                      <span className='dateText-text'>Last Date |</span>
                      {`   `}
                      <span className='dateText-date'>
                        <CalendarOutlined />
                        {` `}
                        {`${
                          jobsDataById?.end_date
                            ? moment(jobsDataById?.end_date)
                                .utcOffset(0)
                                .format('MMM DD YYYY')
                            : 'N/A'
                        }`}
                      </span>
                    </span>
                  }
                </div>
              </div>
              <div className='job-view-details-description'>
                <div
                  className='job-view-details-description-text'
                  dangerouslySetInnerHTML={markupDesc}></div>
              </div>
              {jobsDataById?.attachment_url && (
                <div className='details-button-container-mobile'>
                  <a
                    href={jobsDataById?.attachment_url}
                    target='_blank'
                    rel='noreferrer'>
                    <Button type='primary' className='details-button'>
                      <span className='details-button-text'>
                        {
                          ApplicationString[
                            'userJobViewDetailsComponent.button.applyNow'
                          ]
                        }
                      </span>
                    </Button>
                  </a>
                </div>
              )}
            </Col>
          </Row>
          <Row className='job-qualification-content-row'>
            <Col span={24}>
              {/* ----------- Qualification & Skill Required ------------ */}
              <div className='details-requirement'>
                <span className='details-requirement-heading'>
                  {
                    ApplicationString[
                      'userJobViewDetailsComponent.qualify&skillReq'
                    ]
                  }
                </span>
                <div
                  className='job-view-details-requirement-text'
                  dangerouslySetInnerHTML={markupReq}></div>
              </div>
            </Col>
          </Row>
          {/* <Row className='details-jobs-content apply'>
            <Col>
              
              <div className='details-button-container'>
                <a
                  href={jobsDataById?.attachment_url}
                  target='_blank'
                  rel='noreferrer'>
                  <Button type='primary' className='details-button'>
                    <span className='details-button-text'>
                      {
                        ApplicationString[
                          'userJobViewDetailsComponent.button.applyNow'
                        ]
                      }
                    </span>
                  </Button>
                </a>
              </div>
            </Col>
          </Row> */}
        </div>
      </SectionLayout>
    </React.Fragment>
  );
};
export default JobsViewDetailsComponent;

import React, {useState} from 'react';
import './style.css';
import {ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons';
import {useNavigate, useParams} from 'react-router-dom';
import {
  formatCurrency,
  formatDate,
  formatTimeZoneString,
  getLocalStorageUserInfo,
} from '../../../Util/commonUtility';
import ClockTimeSVG, {
  ClockTimeSVG2,
} from '../../../Assets/svgComponents/ClockTimeSVG';

const EventCard = ({
  sponsors,
  exhibitors,
  sponsorshipAvailable,
  eventName,
  isRegistrationOpen,
  date,
  location,
  description,
  registerText,
  price,
  speakers,
  moreDetails,
  isExpand,
  eventTypeName,
  EventId,
  organizers,
  dateObject,
  details,
}) => {
  const [expanded, setExpanded] = useState(isExpand);
  const Navigate = useNavigate();
  const {id} = useParams();
  const toggleDescription = () => {
    setExpanded(!expanded);
  };
  const OnRegisterHandler = () => {
    const userInfo = getLocalStorageUserInfo();
    localStorage.setItem('isGuestUserRegister', EventId);
    userInfo ? Navigate(`/addAttendees/${EventId}`) : Navigate(`/login`);
  };

  return (
    <React.Fragment>
      <div className='event-main-container'>
        <div className='event-sub-container'>
          <div className='event-top-line'>
            <span>
              <div className='type'>{eventTypeName}</div>
              <div className='date'>
                <p>
                  {dateObject?.event_start_date}
                  {' |'}
                </p>
                <label>
                  <ClockTimeSVG2 />
                  {dateObject?.event_start_time} - {dateObject?.event_end_time}{' '}
                  - {formatTimeZoneString(dateObject?.time_zone)}
                </label>
              </div>
            </span>
            {isRegistrationOpen && (
              <div
                id={`eventDetails-registerButton_${EventId}`}
                className='button'>
                <button onClick={OnRegisterHandler}>{registerText}</button>
              </div>
            )}
          </div>
          <div className='event-name-text'>
            <p>{eventName}</p>
          </div>
          <div className='event-details-container-card'>
            {details ? (
              <div className='details-li'>
                <p>Details:</p> <label>{details}</label>
              </div>
            ) : null}
            {speakers ? (
              <div className='details-li'>
                <p>Speakers:</p> <label>{speakers}</label>
              </div>
            ) : null}
            <div className='details-li'>
              <p>Organizers:</p> <label>{organizers}</label>
            </div>
            <div className='details-li'>
              <p>Registration Closes On:</p>
              <label className='mb-date-text'>
                {dateObject?.reg_end_date} - {dateObject?.reg_end_time} -{' '}
                {formatTimeZoneString(dateObject?.time_zone)}
              </label>
            </div>
            <div className='mb-detail-list'>
              {price && (
                <div className='details-li'>
                  <p>Registration Fee:</p>
                  <label className='mb-price-text'>{price}</label>
                </div>
              )}
              <div className='details-li location-li-extend'>
                <p>Location:</p>{' '}
                <label className='mb-location-text'>{location}</label>
              </div>
            </div>
            {sponsors?.length > 0 && (
              <div className='details-li'>
                <p>Sponsors:</p>
                <label>{sponsors.join(', ')}</label>
              </div>
            )}
            {exhibitors?.length > 0 && (
              <div className='details-li'>
                <p>Exhibitor (Vendor):</p>
                <label>{exhibitors.join(', ')}</label>
              </div>
            )}
            {/* <div className='details-li'>
              <p>Major Sponsor:</p>{' '}
              <label>
                Alex Zhavoronkov, Insilico Medicine; Abraham Heifets, Atomwise;
                Stephen MacKinnon, Recursion; Charles Fisher,
              </label>
            </div> */}
          </div>
          {isRegistrationOpen && (
            <div className='mb-button'>
              <button onClick={OnRegisterHandler}>{registerText}</button>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default EventCard;

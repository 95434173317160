import React, {useState, useEffect} from 'react';
import SpeakersFormComponent from '../../../../Components/AdminComponents/Speakers/SpeakersForm';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  createSpeakers,
  editSpeakers,
  getSpeakersById,
} from '../../../../Store/Actions';

const SpeakersFormContainer = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {getSpeakersData} = useSelector(({speakers}) => speakers);
  const [editData, setEditData] = useState();

  useEffect(() => {
    if (getSpeakersData) {
      setEditData(getSpeakersData?.data);
    }
  }, [getSpeakersData]);

  const postSpeakers = async (Data, form) => {
    dispatch(createSpeakers(Data, form, null, navigate));
  };

  const updateSpeakers = async (Data, id) => {
    dispatch(editSpeakers(Data, id, navigate));
  };
  const fetchSpeakersById = async (id) => {
    dispatch(getSpeakersById(id));
  };
  return (
    <div>
      <SpeakersFormComponent
        postSpeakers={(Data, form) => {
          postSpeakers(Data, form);
        }}
        updateSpeakers={(id, Data) => {
          updateSpeakers(id, Data);
        }}
        fetchSpeakersById={(id) => {
          fetchSpeakersById(id);
        }}
        EditData={editData}
      />
    </div>
  );
};

export default SpeakersFormContainer;

/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import './index.css';
const EventOptionDetails = () => {
  return (
    <React.Fragment>
      <div className='option-details-container'>
        <h4>
          Guidance for properly completing the information required on this
          form:
        </h4>

        <ul>
          <li className='option-name-list'>
            <span className='option-name-bold'>Name</span> should be in the
            format: Event option type-subtype. Examples: Food-Fish,
            Sponsorship-Vendor Show, Registration.
          </li>
          <li className='option-name-list'>
            <span className='option-name-bold'>Qty</span> is the total available
            seats for the option. e.g. If you plan to have 20 vendors for the
            event, then Qty for "Vendor Show" options should set to 20.
          </li>
          <li className='option-name-list'>
            <span className='option-name-bold'>Limit</span> is the number of the
            option a user can order. e.g. If you allow only one free food (Fish)
            per user, then Limits for the "Fish" option should be set to 1.
          </li>
          <li className='option-name-list'>
            <span className='option-name-bold'>Workshop Attendants</span> Enter
            the maximum attendees allowed at the
            <span className='option-name-bold'> “Attendees Price”</span> you
            list in the box below.
          </li>
          <li className='option-name-list'>
            For the event option
            <span className='option-name-bold'> “Sponsorship”</span>, indicate
            all the perks specific for that sponsorship type in the designated
            text box.
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default EventOptionDetails;

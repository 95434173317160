import {Alert, Col, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import {Elements, useElements, useStripe} from '@stripe/react-stripe-js';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import SpecialPaymentNormalForm from './SpecialPaymentNormalForm';
import {useDispatch, useSelector} from 'react-redux';
import CommonPaymentCheckoutForm from './../../CommonComponents/CommonPaymentCheckoutForm/index';
import {
  postSpecialPaymentDetailsSuccess,
  postSpecialPaymentToken,
  resetSpecialPayment,
} from '../../../Store/Actions/specialPayment';
import CommonPageHeroText from '../../CommonComponents/CommonPageHeroText';
import {createStipePromise} from '../../../Util/commonUtility';
import {ApplicationString} from '../../../Localization/Locales/en';
import {useParams, useSearchParams} from 'react-router-dom';

const SpecialPaymentComponent = () => {
  const [price, setPrice] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [publishable_key, setPublishableKey] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [paymentLinkStatus, setPaymentLinkStatus] = useState(false);
  const [currencyDetails, setCurrencyDetails] = useState(null);
  const dispatch = useDispatch();
  const keyDetails = useSelector((state) => state?.specialPayment?.clientKeys);
  useEffect(() => {
    if (keyDetails) {
      setClientSecret(keyDetails.clientSecret);
      setPublishableKey(keyDetails.publishable_key);
      setPrice(keyDetails.amount);
      setCurrencyDetails({
        conversion_rate: keyDetails?.conversion_rate,
        local_currency: keyDetails?.local_currency,
      });
      setPaymentLinkStatus(keyDetails.status === 'Paid' ? false : true);
    }
  }, [keyDetails]);

  useEffect(() => {
    if (publishable_key) {
      const stripePromise = createStipePromise(publishable_key);
      setStripePromise(stripePromise);
    }
  }, [publishable_key]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };
  const [searchParams, setSearchParams] = useSearchParams(
    document.location.search,
  );

  useEffect(() => {
    const token = searchParams.get('tn');
    dispatch(postSpecialPaymentToken(token));
  }, [searchParams]);

  useEffect(() => {
    return () => {
      dispatch(resetSpecialPayment());
    };
  }, []);

  const dispatchSuccess = (intent) => {
    dispatch(postSpecialPaymentDetailsSuccess(intent));
  };

  return (
    <React.Fragment>
      <Row gutter={[0, 28]} className='pbssMainContainerRow'>
        <Col span={24}>
          <CommonPageHeroText
            heading={'Special Payment Method'}
            subheading={
              'Get Details about special payment price , schedule & more...'
            }
          />
        </Col>
        <Col span={24}>
          <SectionLayout>
            {clientSecret && paymentLinkStatus ? (
              <Elements options={options} stripe={stripePromise}>
                <CommonPaymentCheckoutForm
                  clientSecret={clientSecret}
                  price={price}
                  currencyDetails={currencyDetails}
                  DataId={false}
                  resetPayment={resetSpecialPayment}
                  goBackURL={`/`}
                  dispatchSuccess={(intent) => {
                    dispatchSuccess(intent);
                  }}
                  successUrl={'/'}
                />
              </Elements>
            ) : (
              <Alert
                message={ApplicationString['specialPayment.alertMessage']}
                className='no-event-sponsor-alert'
                type='info'
                showIcon
              />
            )}
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SpecialPaymentComponent;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {editStem, getStemById} from './../../../Store/Actions/stem';
import StemAdminComponent from '../../../Components/AdminComponents/Stem';

const StemContainer = () => {
  const dispatch = useDispatch();
  const [editData, setEditData] = useState();
  const {allStems} = useSelector(({stem}) => stem);

  useEffect(() => {
    if (allStems) {
      setEditData(allStems?.data?.stem[0]);
    }
  }, [allStems]);

  useEffect(() => {
    fetchStemById();
  }, []);

  const updateStem = (Data, id) => {
    dispatch(editStem(Data, id));
  };

  const fetchStemById = () => {
    const showMessage = true;
    dispatch(getStemById(showMessage));
  };
  return (
    <StemAdminComponent
      updateStem={(id, Data) => {
        updateStem(id, Data);
      }}
      EditData={editData}
    />
  );
};

export default StemContainer;

import React, {useEffect, useState} from 'react';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import SponsorsComponent from '../../../Components/AdminComponents/Sponsors';
import {useDispatch, useSelector} from 'react-redux';
import {deleteSponsorById, getAllSponsorsByQuery} from '../../../Store/Actions';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';
const SponsorsContainer = () => {
  const dispatch = useDispatch();
  const {allSponsorsQueryData} = useSelector(({sponsors}) => sponsors);
  const [listData, setListData] = useState();
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.sponsors,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.sponsors,
  );
  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pageCount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);
  const searchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.sponsors,
  );

  useEffect(() => {
    if (allSponsorsQueryData) {
      setListData(allSponsorsQueryData?.data?.sponsors);
      setPagination({
        ...pagination,
        page: allSponsorsQueryData?.data?.currentPage,
        totalPages: allSponsorsQueryData?.data?.totalPage,
        totalCount: allSponsorsQueryData?.data?.totalCount,
      });
    } else {
      fetchSponsor();
    }
  }, [allSponsorsQueryData?.data?.sponsors]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchSponsor(searchValues);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  // useEffect(() => {
  //   if (allSponsorsQueryData) {
  //     fetchSponsor();
  //   }
  // }, [
  //   pagination.page,
  //   pagination.pageCount,
  //   sortParams.field,
  //   sortParams.order,
  // ]);

  useEffect(() => {
    if (searchValues) {
      dispatch(setPageNumber('sponsors', 1));
      setPagination({
        ...pagination,
        page: 1,
      });
      setPaginationAndSortChanged(true);
    }
  }, [searchValues]);

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };
  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('sponsors', pageSize));
    dispatch(setPageNumber('sponsors', current));

    setPagination({
      ...pagination,
      page: current,
      pageCount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };
  const fetchSponsor = async (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllSponsorsByQuery(sortField, sortOrder, searchQueryData, pagination),
    );
  };

  const deleteSponsor = async (id) => {
    dispatch(deleteSponsorById(id));
  };

  return (
    <div>
      <SponsorsComponent
        allSponsorData={listData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        deleteSponsor={(id) => {
          deleteSponsor(id);
        }}
        handleTableChange={handleTableChange}
        fetchSponsor={(searchValues) => {
          fetchSponsor(searchValues);
        }}
        sortParams={sortParams}
        searchValues={searchValues}
      />
    </div>
  );
};
export default SponsorsContainer;

import React, {useEffect, useState} from 'react';
import {Space, Table, Popconfirm, Tooltip, Button, Switch, Tag} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {
  KeywordColumnValues,
  PageSizeArray,
  PaginationPosition,
  keyWordRadioOptions,
  keywordFilters,
} from '../../../Util/commonUtility';
import DynamicSearchForm from '../../CommonComponents/DynamicSearch';
import editIcon from '../../../Assets/editIcon.svg';
import deleteIcon from '../../../Assets/deleteIcon.svg';
import plus from '../../../Assets/Plus.svg';
import {ApplicationString} from '../../../Localization/Locales/en';
import UpdateHistoryTableInfo from '../HistoryView';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {useSelector} from 'react-redux';
import useDebouncedCallback from '../../../hooks/useDebounce';
const KeywordComponent = (props) => {
  let navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const initialState = {};

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const {
    allKeywordData,
    fetchKeyword,
    paging,
    handlePaginationChange,
    deleteKeyword,
    sortParams,
    updateKeyword,
    handleTableChange,
    searchValues,
  } = props;
  const ps = useSelector(({pageSizes}) => pageSizes.pageSizes.keywords);
  const [switchState, setSwitchState] = useState(initialState);

  useEffect(() => {
    searchValues && setExpanded(true);
  }, [searchValues]);

  const handleSwitchChange = (record, status) => {
    updateKeyword({...record, status: status ? 1 : 0}, record.id);
  };

  const handleSearch = (values) => {
    fetchKeyword(values);
  };

  const handleEditClick = async (id) => {
    navigate(`/admin/interests/update/${id}`);
  };

  const isSwitchOn = (recordId) => {
    return recordId === 1;
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      ellipsis: true,
      width: '2.5%',
      sorter: true,
    },
    {
      title: 'Interest Name',
      dataIndex: 'name',
      key: 'company_name',
      width: '20%',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      render: (text) => {
        return (
          <span
            style={{
              color: isSwitchOn(text) ? 'green' : 'red',
            }}
            key={text}>
            {isSwitchOn(text) ? 'Active' : 'Disabled'}
          </span>
        );
      },
      // render: (_, record) => (
      //   <div className='switch-container'>
      //     <p
      //       className={`active-text ${
      //         isSwitchOn(record.status) ? 'active' : ''
      //       }`}>
      //       Active
      //     </p>
      //     <Switch
      //       size='small'
      //       checked={isSwitchOn(record.status)}
      //       onChange={(e) => handleSwitchChange(record, e)}
      //     />
      //     <p
      //       className={`disabled-text ${
      //         !isSwitchOn(record.status) ? 'active-dis' : ''
      //       }`}>
      //       Disabled
      //     </p>
      //   </div>
      // ),
    },
    {
      title: '',
      key: 'action',
      width: '3%',
      render: (_, record) => (
        <AdminActionsDropdown
          record={record}
          editBool={true}
          deleteBool={true}
          handleDeleteClick={deleteKeyword}
          handleEditClick={handleEditClick}
          text='Interest'
        />
      ),
    },
  ];
  columns.forEach((column) => {
    if (column.key !== 'action') {
      column.sortOrder =
        sortParams.field === column.dataIndex && sortParams.order;
    }
  });

  const searchColumn = columns.filter((column) =>
    KeywordColumnValues.includes(column.dataIndex),
  );

  // debouncing the table & pagination changes to stop too many requests
  const debouncedHandlePaginationChange = useDebouncedCallback(
    handlePaginationChange,
    1000,
  );
  const debouncedTableChange = useDebouncedCallback(handleTableChange, 1000);

  return (
    <div className='main-container'>
      <div className='search-container' style={{gap: '10px'}}>
        <Button onClick={toggleExpand} id='AdFeeComponent-button-filterSearch'>
          {expanded ? <FilterOutlined /> : <FilterOutlined />}{' '}
          {ApplicationString['dynamicSearch.button.heading']}
        </Button>
        <Button
          type='primary'
          onClick={() => navigate('/admin/interests/add')}
          id='KeywordsComponent-button-addKeywords'
          className='common-button'>
          <div className='icon-container'>
            <img src={plus} alt='plus' className='icon-plus' />
            <span className='add-content'>
              {ApplicationString['keywordsComponent.button.addForm']}
            </span>
          </div>
        </Button>
      </div>
      {expanded && (
        <DynamicSearchForm
          columns={keywordFilters}
          onSearch={handleSearch}
          options={keyWordRadioOptions}
          savedFilteredValues={searchValues}
          title={'keywords'}
        />
      )}
      <div className='listing-container'>
        <Table
          bordered
          className='admin-table'
          columns={columns}
          onChange={debouncedTableChange}
          dataSource={allKeywordData}
          key={allKeywordData?.map((item) => {
            item.id;
          })}
          // sticky={true}
          pagination={{
            onChange: debouncedHandlePaginationChange,
            current: paging.page,
            total: paging.totalCount,
            pageSize: ps || paging.pageCount,
            pageSizeOptions: PageSizeArray,
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showTotal: (total, range) => `Total ${total} items`,
            position: [PaginationPosition],
          }}
        />
      </div>
    </div>
  );
};

export default KeywordComponent;

import React, {useEffect} from 'react';
import {Button, Typography, Space, Divider} from 'antd';
import './style.css';
import {
  formatCurrency,
  formatCurrencyByLocalChapter,
} from '../../../Util/commonUtility';
import {useDispatch, useSelector} from 'react-redux';
import {getAllChapters} from '../../../Store/Actions';

const {Title} = Typography;

const ConfirmationAction = ({
  heading,
  onConfirm,
  onCancel,
  data,
  submitName,
  totalCartPrice,
  usedForAddAttendees,
  usedForSponsor = false,
  disable = false,
}) => {
  const localChapterData = useSelector(
    (state) => state.chapters.localChapterData,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!localChapterData) {
      dispatch(getAllChapters());
    }
  }, [localChapterData]);

  return (
    <div className='confirmation-container action-con'>
      <div className='price-container'>
        <div>
          {totalCartPrice?.discount || totalCartPrice?.refund_credit ? (
            <div>
              <div className='discount-price-container'>
                <label className='price-label'>Subtotal:</label>
                <p className='price-txt'>
                  {formatCurrencyByLocalChapter(
                    totalCartPrice?.total_price_per_cart,
                    localChapterData,
                  )}
                </p>
              </div>
              {totalCartPrice?.discount ? (
                <div className='discount-price-container'>
                  <label className='price-label'>Discount:</label>
                  <p className='price-txt line-break'>
                    {formatCurrencyByLocalChapter(
                      totalCartPrice?.discount,
                      localChapterData,
                    )}
                  </p>
                </div>
              ) : null}
              {totalCartPrice?.refund_credit ? (
                <div className='discount-price-container'>
                  <label className='price-label'>Use credit:</label>
                  <p className='price-txt line-break'>
                    {formatCurrencyByLocalChapter(
                      usedForSponsor
                        ? totalCartPrice?.refund_credit
                        : totalCartPrice?.refund_credit_for_regular_attendees,
                      localChapterData,
                    )}
                  </p>
                </div>
              ) : null}
              <Divider prefixCls='price-cart-divider' />
              <div className='discount-price-container'>
                <label className='price-label total-text-cart total-blue'>
                  TOTAL
                </label>
                <p className='price-txt final-price'>
                  {formatCurrencyByLocalChapter(
                    totalCartPrice?.discounted_price,
                    localChapterData,
                  )}
                </p>
              </div>
            </div>
          ) : (
            <div
              className={`${
                usedForAddAttendees && 'attendees-text'
              }  price-txt`}>
              <p>
                {formatCurrencyByLocalChapter(
                  totalCartPrice?.total_price_per_cart,
                  localChapterData,
                )}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className='btn-main-container'>
        {/* <Space wrap> */}
        <Button
          className='confirmation-action-button'
          prefixCls='proceedToPayBtn'
          type='primary'
          onClick={() => onConfirm()}
          disabled={disable}
          // disabled={!data || data?.length === 0}
        >
          {submitName}
        </Button>
        {/* <Button
            className='common-submit-login common-form-submit common-form-cancel'
            type='primary'
            ghost
            danger
            onClick={onCancel}>
            Back
          </Button> */}
        {/* </Space> */}
      </div>
    </div>
  );
};

export default ConfirmationAction;

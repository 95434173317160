import React from 'react';
import {Space, Button, notification} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import attendeesImg from './../../../Assets/svgs/attendees.svg'; // Replace with the correct path
import './style.css';

const AddAttendeeeComponent = ({
  addRow,
  disable = false,
  setDisablePaymentClicks,
  buttonText = null,
}) => {
  return (
    <React.Fragment>
      <Button
        id='add-attendee'
        className={'add-attendee'}
        disabled={disable}
        onClick={() => {
          setDisablePaymentClicks && setDisablePaymentClicks(true);
          addRow();
        }}>
        {buttonText ? buttonText : 'Add Additional Attendee'} <PlusOutlined />
      </Button>
    </React.Fragment>
  );
};

export default AddAttendeeeComponent;

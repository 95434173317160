import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AssetsUploadComponent from '../../../Components/UserComponents/AssetsUpload';

const AssetsUploadContainer = () => {
  let dispatch = useDispatch();

  useEffect(() => {}, []);

  return (
    <div>
      <AssetsUploadComponent />
    </div>
  );
};

export default AssetsUploadContainer;

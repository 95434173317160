import React from 'react';
import JobsCartContainer from '../../../../Containers/UserContainer/Jobs/JobsCart';

const JobsCartScreen = () => {
  return (
    <React.Fragment>
      <JobsCartContainer />
    </React.Fragment>
  );
};

export default JobsCartScreen;

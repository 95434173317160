import React, {useEffect, useState} from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import PageHeading from '../../CommonComponents/PageHeading';
import {Table, Select, Form, Tag, Skeleton, Row, Col} from 'antd';

import EditableCell from '../../CommonComponents/EditableCell';
import './style.css';
import ConfirmationAction from '../../CommonComponents/PaymentConfirmationAction';
import {useNavigate, useParams} from 'react-router-dom';

import {
  filterFoodOptionColumnsForAttendee,
  formValueChangeHandler,
  formatCurrency,
  getLocalStorageUserInfo,
  validatePaymentPrice,
} from '../../../Util/commonUtility';

import EventsCommonCartTable from '../../CommonComponents/EventCartCommonTable';
import {useDispatch} from 'react-redux';
import PromotionalCodeInputCart from './../../CommonComponents/CouponCodeInputCart/index';
import SponsorPerksCard from '../../CommonComponents/SponsorPerksComponent';
import CustomCollapse from '../../CommonComponents/CommonAccordian';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import NavigationComponent from '../../CommonComponents/CommonBackButton';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import {ApplicationString} from '../../../Localization/Locales/en';
import SponsorDetailCartForm from './SponsorDetailsCartForm';

const {Option} = Select;

const EventSponsorCartComponent = ({
  eventName,
  setListAttendeesData,
  totalCartPrice,
  eventRegisterData,
  allSponsorAttendeesData,
  sponsorData,
  deleteSponsorAttendee,
  paymentByUserRegistration,
  allEventOptionsFoodData,
  updateSponsorAttendee,
  updateSponsor,
  applyCouponCode,
  appliedCartCoupon,
  SponsorAttendeeOptionById,
  sponsorPerksData,
  attendeesDataWithPerks,
  eventType,
  setAttendeesData,
  setListAttendeesDataWithPerks,
}) => {
  const {id, optionId, transactionId} = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  // const [expandedRowKeys, setExpandedRowKeys] = useState();
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  const [isAddAttendee, setIsAddAttendee] = useState(false);
  // const [expandId, setOnExpandId] = useState(false);
  const [mealOptions, setMealOptions] = useState(true);
  // const userData = getLocalStorageUserInfo();
  const isEditing = (record) => record.id === editingKey;
  const [sponsorOption, setSelectOptionsValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [eventSponsorshipOption, setEventSponsorshipOption] = useState();
  const [couponVerifiedAttendee, setCouponVerifiedAttendee] = useState(false);
  const [couponVerifiedCart, setCouponVerifiedCart] = useState(false);
  const [eventSponsorPerksData, setEventSponsorPerksData] = useState();
  const localUserData = getLocalStorageUserInfo();

  useEffect(() => {
    if (sponsorPerksData) {
      setEventSponsorPerksData(sponsorPerksData);
    } else {
      SponsorAttendeeOptionById(optionId);
    }
  }, [sponsorPerksData, optionId]);

  const edit = (record) => {
    const EditData = {
      ...record,
      attendee_email_confirm: record?.attendee_email
        ? record?.attendee_email
        : '',
      food_option: record.event_registration_options?.food
        ? record.event_registration_options?.food?.event_option_id
        : 'no-food',
      registration_option:
        record.event_registration_options?.registration?.event_option_id,
      coupon_code: record?.discount_code,
    };
    form.setFieldsValue({
      ...EditData,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    if (isAddAttendee) {
      allSponsorAttendeesData.pop();
      setListAttendeesData([...allSponsorAttendeesData]);
    }
    setIsAddAttendee(false);
    setEditingKey('');
  };

  const save = async (data) => {
    const row = await form.validateFields();
    const sponsorData = {
      ...row,
      // is_reps: totalRepresentatives > 2 ? false : true,
      event_option_perk_id: eventSponsorshipOption?.event_sponsorship_perk?.id,
      sponsorship_option_id: eventSponsorshipOption?.event_option_id,
      event_id: Number(id),
      food_option:
        row.food_option === 'no-food' || eventType?.toLowerCase() === 'webcast'
          ? {}
          : {
              type: 'food',
              event_option_id: row?.food_option,
              qty: 1,
              id: data?.food_option?.id,
            },
      registration_option: {
        type: 'registration',
        event_option_id: row?.registration_option,
        qty: 1,
        id: data?.registration_option?.id,
      },
    };
    if (isAddAttendee) {
      postAttendeesById(sponsorData, setEditingKey, form);
      setIsAddAttendee(false);
    } else {
      updateSponsorAttendee(sponsorData, data?.id, setEditingKey, form);
    }
  };

  const handleDelete = (id) => {
    deleteSponsorAttendee(id);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      editable: true,
      width: '15%',
      render: (text, record) => {
        return (
          <Tag prefixCls='regularAttendeesTag'>
            {record.attendee_first_name} {record.attendee_last_name}
          </Tag>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'attendee_email',
      editable: true,
      width: '18%',
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },
    {
      title: 'Option',
      dataIndex: 'option',
      key: 'registration_and_food_option',
      editable: true,
      width: '15%',
      render: (_, record) => {
        const registrationName =
          record?.event_registration_options?.registration?.name;
        const price = record?.event_registration_options?.registration?.price;
        const foodName =
          record?.event_registration_options?.food?.name || 'No Food';
        const foodPrice = record?.event_registration_options?.food?.price;
        return (
          <div className='option-tag-container'>
            <Tag prefixCls='regularAttendeesTag'>
              Registration: {registrationName} - {formatCurrency(price, 'USD')}
            </Tag>
            {eventType !== 'Webcast' && (
              <Tag prefixCls='regularAttendeesTag'>
                Food: {foodName}{' '}
                {foodPrice ? '- ' + formatCurrency(foodPrice, 'USD') : ''}
              </Tag>
            )}
          </div>
        );
      },
    },

    {
      title: 'Company/Affiliation',
      dataIndex: 'attendee_company',
      key: 'company',
      width: '15%',
      editable: true,
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },
    {
      title: 'Price',
      dataIndex: 'total_price_per_attendee',
      width: '5%',
      render: (text) => {
        return (
          <h4 className='regularAttendees-price'>{formatCurrency(text)}</h4>
        );
      },
    },

    {
      title: ' ',
      dataIndex: 'action',
      width: '6%',
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <AdminActionsDropdown
            record={record}
            usedForAttendeeTable={true}
            editBool={true}
            attendeeEditingKey={editingKey}
            handleCancelClick={cancel}
            handleSaveClick={save}
            deleteBool={false}
            handleEditClick={edit}
            handleDeleteClick={() => handleDelete(record.id)}
          />
        );
      },
    },
  ];

  const handleConfirm = (data) => {
    if (validatePaymentPrice(totalCartPrice?.discounted_price, dispatch))
      return;
    paymentByUserRegistration(id, optionId);
  };

  const handleCancel = () => {
    navigate(`/eventSponsor/${id}`);
  };

  const onMealSelect = () => {
    setMealOptions(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (allSponsorAttendeesData?.length === 0) {
      const loading =
        !allSponsorAttendeesData || allSponsorAttendeesData.length === 0;
      setLoading(loading);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [allSponsorAttendeesData]);

  useEffect(() => {
    const eventOptionData =
      sponsorData?.event_registration_options?.sponsorship;
    if (eventOptionData) {
      setEventSponsorshipOption(eventOptionData);
    }
  }, [sponsorData]);

  const mergedColumns = filterFoodOptionColumnsForAttendee(
    columns,
    eventType,
    isEditing,
  );

  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        <Col span={24}>
          {transactionId ? (
            <SponsorOptionPageHeroText
              title={
                ApplicationString['userAttendeeCartComponent.afterPayment']
              }
              subTitle={
                eventName ||
                ApplicationString[
                  'userAttendeeCartComponent.subHeading.afterPayment'
                ]
              }
              hideDivider={true}
            />
          ) : (
            <SponsorOptionPageHeroText
              title={
                ApplicationString['userAttendeeCartComponent.confirm&PayText']
              }
              subTitle={eventName}
              extra={false}
            />
          )}
        </Col>
        <Col span={24}>
          <SectionLayout>
            {!transactionId && (
              <NavigationComponent
                name={
                  ApplicationString[
                    'userEventSponsorCartComponent.backToSponsorship'
                  ]
                }
                urlPath={`eventSponsor/${id}/${optionId}`}
              />
            )}
          </SectionLayout>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <SponsorPerksCard
              hideButton
              multiple={false}
              title={eventSponsorPerksData?.name}
              sponsorshipType={eventSponsorPerksData?.sponsorship_type}
              spot={
                eventSponsorPerksData?.qty - eventSponsorPerksData?.used_qty >=
                0
                  ? eventSponsorPerksData?.qty - eventSponsorPerksData?.used_qty
                  : 0
              }
              totalSpot={
                eventSponsorPerksData?.qty >= 0 ? eventSponsorPerksData?.qty : 0
              }
              price={eventSponsorPerksData?.price}
              perks={eventSponsorPerksData?.eventSponsorshipPerk}
              id={eventSponsorPerksData?.id}
              eventId={eventSponsorPerksData?.event_id}
            />
          </SectionLayout>
        </Col>
        {!transactionId && (
          <Col span={24}>
            <SectionLayout>
              <SponsorDetailCartForm
                alertInformation={
                  eventSponsorPerksData?.eventSponsorshipPerk
                    ?.sponsor_contact_content
                }
                eventSponsorData={sponsorData}
                localUserData={localUserData}
                updateSponsor={updateSponsor}
              />
            </SectionLayout>
          </Col>
        )}
        <Col span={24}>
          <SectionLayout>
            <EventsCommonCartTable
              alertInformation={
                eventSponsorPerksData?.eventSponsorshipPerk
                  ?.sponsor_perk_content
              }
              sponsorOption={sponsorOption}
              allEventOptionsFoodData={allEventOptionsFoodData}
              attendeesDataWithPerks={attendeesDataWithPerks}
              sponsorData={sponsorData}
              eventType={eventType}
              onEdit={(id, Data, setEditingKey, form) => {
                updateSponsorAttendee(id, Data, setEditingKey, form);
              }}
              sponsorPerksData={eventSponsorPerksData}
              setListAttendeesDataWithPerks={setListAttendeesDataWithPerks}
            />
          </SectionLayout>
        </Col>
        {/* <Col span={24}>
          <SectionLayout>
            <Form
              form={form}
              onValuesChange={(changedValues) =>
                formValueChangeHandler(form, changedValues)
              }>
              <Skeleton
                loading={loading}
                prefixCls='table-skeleton'
                active
                paragraph={{rows: allSponsorAttendeesData?.length}}>
                <div className='attendees-label'>Attendees</div>
                <Table
                  pagination={false}
                  prefixCls='attendees-table-container'
                  showExpandColumn={false}
                  components={{
                    body: {
                      cell: (props) => (
                        <EditableCell
                          attendeeTypeOptions={attendeeTypeOptions}
                          allEventOptionsRegistrationData={allRegistrationData}
                          allEventOptionsFoodData={allEventOptionsFoodData}
                          couponVerifiedAttendee={couponVerifiedAttendee}
                          setCouponVerifiedAttendee={setCouponVerifiedAttendee}
                          applyCouponCode={applyCouponCode}
                          attendeesData={allSponsorAttendeesData}
                          id={id}
                          eventType={eventType}
                          {...props}
                        />
                      ),
                    },
                  }}
                  scroll={{x: columns?.length * 150}}
                  dataSource={allSponsorAttendeesData}
                  columns={mergedColumns}
                />
              </Skeleton>
            </Form>
          </SectionLayout>
        </Col> */}
        {!transactionId && (
          <>
            <Col span={24}>
              <SectionLayout>
                <PromotionalCodeInputCart
                  title={
                    ApplicationString[
                      'userAttendeeCartComponent.couponCodeTitle'
                    ]
                  }
                  buttonText={
                    ApplicationString[
                      'userAttendeeCartComponent.couponCodeButtonText'
                    ]
                  }
                  couponVerifiedCart={couponVerifiedCart}
                  onRedeemClick={(Data) => {
                    applyCouponCode({
                      ...Data,
                      eventId: parseInt(id),
                    });
                  }}
                  appliedCartCoupon={appliedCartCoupon}
                />
              </SectionLayout>
            </Col>
            <Col span={24}>
              <SectionLayout>
                <ConfirmationAction
                  heading={
                    ApplicationString[
                      'userAttendeeCartComponent.totalAmountToBePaid'
                    ]
                  }
                  data={eventRegisterData}
                  submitName={
                    ApplicationString[
                      'userAttendeeCartComponent.confirm&PayText'
                    ]
                  }
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                  totalCartPrice={totalCartPrice}
                  usedForSponsor={true}
                />
              </SectionLayout>
            </Col>
          </>
        )}
        <Col span={24}>
          <SectionLayout>
            <CustomCollapse />
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default EventSponsorCartComponent;

import React, {useEffect} from 'react';
import './style.css';
import {Divider} from 'antd';

const TableHeading = ({title, subText}) => {
  return (
    <React.Fragment>
      <div className='heading-title'>
        {title}
        {subText ? (
          <p className='sub-heading-admin-table'>{`${subText}`}</p>
        ) : (
          ''
        )}
      </div>
    </React.Fragment>
  );
};

export default TableHeading;

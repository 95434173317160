import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import SpecialPaymentComponent from '../../../Components/UserComponents/SpecialPayment';

const SpecialPaymentContainer = () => {
  return (
    <React.Fragment>
      <SpecialPaymentComponent />
    </React.Fragment>
  );
};

export default SpecialPaymentContainer;

import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SOMETHING_WENT_WRONG,
  ADD_ATTENDEE,
  SHOW_MESSAGE,
  DELETE_SUCCESS,
  UPDATE_ATTENDEES_BY_ID,
  VERIFY_COUPONCODES,
  APPLY_COUPONCODES,
  UPDATE_SUCCESS,
  PAYMENT_EVENT_REGISTRATION_BY_EVENT_ID,
  GET_ALL_REGISTRATION,
  UPDATE_REGISTRATION_OPTOINS_BY_ID,
  UPDATE_SPONOSOR_BY_ID,
  ERROR_REGISTRATION,
  EVENT_REGISTRATION_SUCCESS,
  APPLIED_COUPONCODES_SUCCESS,
  ATTENDEE_ADDED_SUCCESS,
  VERIFY_COUPONCODES_SUCCESS,
  SPONSOR_UPDATE_SUCCESS,
  EVENT_REGISTRATION_UPDATE_SUCCESS,
  EVENT_REGISTRATION_CANCELED_SUCCESS,
  POST_EVENT_PAYMENT_SUCCESS,
  RESET_EVENT_PAYMENT,
  PAYMENT_EVENT_REGISTRATION_CLIENT_KEYS,
  CANCEL_GROUP_OF_REGISTRATION_BY_EVENT_ID,
  MEMORIZED_REFUND_ATTENDEES,
  POST_EVENT_PAYMENT_SUCCESS_FOR_NORMAL,
  POST_EVENT_PAYMENT_SUCCESS_FOR_SPONSOR,
  GET_ALL_REGISTRATION_CSV_DOWNLOAD,
  RESET_MEMORIZED_REFUND_ATTENDEES,
  SET_COUPONCODES_ERROR_MESSAGE,
  GET_ALL_ATTENDEES_BY_TRANSACTION_ID,
} from '../../Constant/ActionTypes';
import {commonSortOrder, handleJwtExpireMsg} from '../../Util/commonUtility';
import Urls from '../../Constant/Urls';
import {httpClient} from '../../Api/client';
import {
  GET_ALL_ATTENDEES_BY_USER_EVENTID,
  DELETE_ATTENDEES_BY_ID,
} from './../../Constant/ActionTypes';
import {setEventNameState, setPaymentRoute} from './chapters';

export const postAttendee = (
  Data,
  setEditingKey,
  form,
  setIsAddAttendee,
  setDisableAttendeeClicks,
  setDisablePaymentClicks,
  setDisableEditClicks,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.post(Urls.ADD_ATTENDEE, Data);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: ADD_ATTENDEE});
        setEditingKey && setEditingKey('');
        form && form.resetFields();
        setIsAddAttendee && setIsAddAttendee(false);
        setDisableAttendeeClicks && setDisableAttendeeClicks(false);
        setDisablePaymentClicks && setDisablePaymentClicks(false);
        setDisableEditClicks && setDisableEditClicks(false);
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const verifyCouponCodes = (code, eventId, setCouponVerified) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        Urls.COUPONCODES_VERIFY.replace('#{couponCode}', code).replace(
          '#{eventId}',
          eventId,
        ),
      );

      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: VERIFY_COUPONCODES});
        setCouponVerified && setCouponVerified(true);
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const applyCouponCodes = (Data, setIsAppliedCoupon) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.post(Urls.COUPONCODES_APPLY, Data);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: data?.data?.message,
        });
        dispatch({type: APPLY_COUPONCODES});
        setIsAppliedCoupon && setIsAppliedCoupon(true);
      }
    } catch (error) {
      let errorMessage = '';
      switch (error.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: SET_COUPONCODES_ERROR_MESSAGE, payload: errorMessage});
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAttendesByUserAndEventId = (id, attendeeType) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        Urls.GET_ALL_ATTENDEE.replace('#{eventId}', id).replace(
          '#{attendeeType}',
          attendeeType,
        ),
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: GET_ALL_ATTENDEES_BY_USER_EVENTID,
          payload: data?.data,
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAttendeesByTransactionId = (transactionId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        Urls.GET_ALL_ATTENDEE_TRANSACTION_ID.replace(
          '#{transactionId}',
          transactionId,
        ),
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: GET_ALL_ATTENDEES_BY_TRANSACTION_ID,
          payload: data?.data,
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

// Sponsor Registration
export const sponsorRegistrationByUserAndEventId = (
  id,
  navigate,
  priceBasedNav = false,
  optionId,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.patch(
        Urls.PAYMENT_UPDATE_BY_EVENT_ID.replace('#{eventId}', id),
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: PAYMENT_EVENT_REGISTRATION_BY_EVENT_ID,
          payload: data?.data,
        });
        dispatch(setPaymentRoute(`eventSponsor`));
        dispatch({
          type: PAYMENT_EVENT_REGISTRATION_CLIENT_KEYS,
          payload: data?.data?.data,
        });
        priceBasedNav
          ? navigate(`/paymentSuccess/myEvents/sponsorship`)
          : navigate(`/payment/sponsor/${id}/${optionId}`);
        priceBasedNav &&
          dispatch({
            type: SHOW_MESSAGE,
            payload: data?.data?.message,
          });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

// Normal Registration
export const normalRegistrationByUserAndEventId = (
  id,
  navigate,
  priceBasedNav = false,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.patch(
        Urls.PAYMENT_UPDATE_NORMAL_REGISTRATION_BY_EVENT_ID.replace(
          '#{eventId}',
          id,
        ),
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: PAYMENT_EVENT_REGISTRATION_BY_EVENT_ID,
          payload: data?.data,
        });
        dispatch(setPaymentRoute(`addAttendees`));
        dispatch({
          type: PAYMENT_EVENT_REGISTRATION_CLIENT_KEYS,
          payload: data?.data?.data,
        });
        priceBasedNav
          ? navigate(`/paymentSuccess/myEvents/regular`)
          : navigate(`/payment/normal/${id}`);

        priceBasedNav &&
          dispatch({
            type: SHOW_MESSAGE,
            payload: data?.data?.message,
          });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteAttendee = (id) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.delete(
        Urls.DELETE_ATTENDEE.replace('#{eventRegistrationId}', id),
      );

      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: DELETE_ATTENDEES_BY_ID});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const UpdateAttendee = (
  Data,
  id,
  setEditingKey,
  form,
  setDisableAttendeeClicks,
  setDisablePaymentClicks,
  setDisableEditClicks,
  setIsAddAttendee,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.patch(
        Urls.UPDATE_ATTENDEE.replace('#{eventRegistrationId}', id),
        Data,
      );
      if (data?.status === HttpCode.Ok) {
        setEditingKey && setEditingKey('');
        form && form.resetFields();
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: data?.data?.message,
        });
        dispatch({type: UPDATE_ATTENDEES_BY_ID});
        setDisableAttendeeClicks && setDisableAttendeeClicks(false);
        setDisablePaymentClicks && setDisablePaymentClicks(false);
        setDisableEditClicks && setDisableEditClicks(false);
        setIsAddAttendee && setIsAddAttendee(false);
      }
    } catch (error) {
      let errorMessage = '';
      switch (error.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const UpdateSponsorById = (Data, id, setEditDetails) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.patch(
        Urls.SPONSOR_UPDATE.replace('#{sponsorId}', id),
        Data,
      );
      if (data?.status === HttpCode.Ok) {
        setEditDetails && setEditDetails(false);
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: UPDATE_SPONOSOR_BY_ID,
        });
        dispatch({
          type: SHOW_MESSAGE,
          payload: data?.data?.message,
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllRegistrationByQueryString = (
  pagination,
  sortField,
  sortOrder,
  searchQueryData,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.GET_ALL_REGISTRATION}?page=${pagination.page}&pageCount=${
          pagination.pageCount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${searchQueryData}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_REGISTRATION, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const resetEventRegisterCsvDownloadState = () => {
  return {
    type: GET_ALL_REGISTRATION_CSV_DOWNLOAD,
    payload: [],
  };
};
export const getRegistrationByIdForCSV = (
  sortField,
  sortOrder,
  searchQueryData,
  totalEventRegistrationCount,
  id,
  setCSVDataLoading,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    setCSVDataLoading && setCSVDataLoading(true);
    try {
      let data = await httpClient.get(
        `${
          Urls.GET_REGISTRATIONS_BY_EVENTID
        }/${id}?page=1&pageCount=${totalEventRegistrationCount}&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${searchQueryData}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch(setEventNameState(data?.data?.data?.event_name));
        dispatch({
          type: GET_ALL_REGISTRATION_CSV_DOWNLOAD,
          payload: data?.data,
        });
        setCSVDataLoading && setCSVDataLoading(false);
      }
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        return data?.data;
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const getRegistrationById = (
  pagination,
  sortField,
  sortOrder,
  searchQueryData,
  id,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.GET_REGISTRATIONS_BY_EVENTID}/${id}?page=${
          pagination.page
        }&pageCount=${pagination.pageCount}&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${searchQueryData}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch(setEventNameState(data?.data?.data?.event_name));
        dispatch({type: GET_ALL_REGISTRATION, payload: data?.data});
      }
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        return data?.data;
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateFoodAndRegistrationOptions = (
  optionsData,
  id,
  setEditingKey,
  form,
  setDisableAttendeeClicks,
  setDisablePaymentClicks,
  setDisableEditClicks,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.patch(
        `${Urls.UPDATE_REGISTRATIONS_OPTIONS_BY_EVENTID}/${id}`,
        optionsData,
      );
      if (data?.status === HttpCode.Ok) {
        setEditingKey && setEditingKey('');
        form && form.resetFields();
        setDisableAttendeeClicks && setDisableAttendeeClicks(false);
        setDisablePaymentClicks && setDisablePaymentClicks(false);
        setDisableEditClicks && setDisableEditClicks(false);
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: data?.data?.message,
        });
        dispatch({type: UPDATE_REGISTRATION_OPTOINS_BY_ID});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const cancelEventRegistrationsByEventIds = (
  eventIds,
  setSelectedRowKeys,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.patch(
        Urls.CANCEL_GROUP_OF_EVENT_REGISTRATIONS_BY_EVENTID,
        eventIds,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: data?.data?.message,
        });
        dispatch({type: CANCEL_GROUP_OF_REGISTRATION_BY_EVENT_ID});
        setSelectedRowKeys && setSelectedRowKeys([]);
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
//for normal attendees
export const postEventPaymentSuccess = (data, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let response = await httpClient.patch(
        `${Urls.EVENT_POST_PAYMENT_SUCCESS}/${data?.type}`,
        data,
      );
      if (response.status === HttpCode.Ok) {
        dispatch({type: POST_EVENT_PAYMENT_SUCCESS});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: response?.data?.message});
        localStorage.removeItem('clientSecret');
        navigate &&
          navigate(
            `/paymentSuccess/myEvents/${
              data?.type === 'sponsor' ? 'sponsorship' : 'regular'
            }`,
          );
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const resetEventPayment = () => {
  return (dispatch) => {
    dispatch({type: RESET_EVENT_PAYMENT});
  };
};
export const memorizedInitiatedRefundAttendees = (
  selectedInitiateRefundIds,
) => {
  return (dispatch) => {
    dispatch({
      type: MEMORIZED_REFUND_ATTENDEES,
      payload: selectedInitiateRefundIds,
    });
  };
};
export const resetMemorizedInitiatedRefundAttendees = () => {
  return (dispatch) => {
    dispatch({type: RESET_MEMORIZED_REFUND_ATTENDEES});
  };
};

import React, {useEffect, useState} from 'react';
import {Button, Form} from 'antd';
import CommonEditor from '../../CommonComponents/TextEditor';
import {ApplicationString} from '../../../Localization/Locales/en';

const StemAdminComponent = (props) => {
  const [form] = Form.useForm();
  const {EditData, updateStem} = props;
  const [editorState, setEditorState] = useState(`<p></p>`);

  useEffect(() => {
    EditData &&
      form.setFieldsValue({
        ...EditData,
      });
  }, [EditData]);

  const onFinish = (values) => {
    updateStem(values, EditData.id);
  };
  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };
  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          onFinish={onFinish}
          layout='vertical'
          className='two-column-form'
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          initialValues={''}>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Stem Editor'
                name='stem'
                rules={[
                  {required: true, message: 'Stem editor should not be empty!'},
                ]}>
                <CommonEditor
                  value={editorState}
                  onChange={setEditorState}
                  text={'stem_editor'}
                  placeholderValue={'stem editor'}
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <div className='button-container'>
              <Button
                className='common-submit-login common-form-submit common-form-cancel'
                htmlType='button'
                id='adminStem-resetButton-reset'
                onClick={onReset}>
                {ApplicationString['dynamicSearch.button.reset']}
              </Button>
              <Button
                id='adminStem-submitButton-button'
                type='primary'
                htmlType='submit'
                className='common-submit-login common-form-submit'>
                {ApplicationString['dynamicSearch.button.update']}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>{' '}
    </div>
  );
};

export default StemAdminComponent;

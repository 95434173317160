import React, {useEffect} from 'react';
import CustomCollapse from '../../CommonComponents/CommonAccordian';
import PageTitleCard from '../../CommonComponents/PageTitleCard';
import PageHeading from '../../CommonComponents/PageHeading';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import MajorInfoCard from './InfoCard';
import sponsorImg from '../../../Assets/sponsorImg.png';
import {Col, Row} from 'antd';
import ChapterPageheroText from '../../CommonComponents/ChapterPageHeroText';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import {ApplicationString} from '../../../Localization/Locales/en';

const SponsorshipInfoComponent = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <React.Fragment>
      <Row gutter={[0, 28]} className='pbssMainContainerRow'>
        <Col span={24}>
          <SponsorOptionPageHeroText
            title={ApplicationString['userEventMajorSponsorPage.heading']}
            subTitle={ApplicationString['userEventMajorSponsorPage.subheading']}
          />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <MajorInfoCard
              imgSrc={
                'https://images.unsplash.com/photo-1561489396-888724a1543d?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
              }
              description={
                ApplicationString['userEventMajorSponsorPage.description']
              }
              price={'$2,000'}
            />
          </SectionLayout>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <CustomCollapse />
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SponsorshipInfoComponent;

import {SET_PAGE_NUMBER, SET_PAGE_SIZE} from '../../Constant/ActionTypes';

const initialState = {
  pageSizes: {},
  pageNumbers: {},
};

const pageSizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_SIZE: {
      const {pageName, pageSize} = action.payload;
      return {
        ...state,
        pageSizes: {
          ...state.pageSizes,
          [pageName]: pageSize,
        },
      };
    }
    case SET_PAGE_NUMBER: {
      const {pageName, pageNumber} = action.payload;
      return {
        ...state,
        pageNumbers: {
          ...state.pageNumbers,
          [pageName]: pageNumber,
        },
      };
    }
    default:
      return state;
  }
};

export default pageSizeReducer;

import {useEffect} from 'react';

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      //Check if it's a right-click (event.button === 2)
      if (event.type === 'contextmenu' || event.button === 2) {
        return;
      }

      //Check if the click was inside the element.
      //If it was, the function returns early and does nothing.
      const el = ref?.current;
      if (!el || el.contains(event?.target)) {
        return;
      }
      handler(event);
    };
    const keyListener = (event) => {
      if (event.key === 'Escape') {
        handler(event);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    document.addEventListener('contextmenu', listener);
    document.addEventListener('keydown', keyListener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
      document.addEventListener('contextmenu', listener);
      document.removeEventListener('keydown', keyListener);
    };
  }, [ref, handler]);
};

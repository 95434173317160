import {Divider} from 'antd';
import React, {useEffect} from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import './style.css';
import LoginForm from '../../CommonComponents/LoginForm';
import RecentOpeningsJobs from '../../CommonComponents/RecentOpenings/jobs';
import CommonPageHeroText from '../../CommonComponents/CommonPageHeroText';
import {ApplicationString} from '../../../Localization/Locales/en';

const LoginComponent = ({allApprovedJobsData}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <React.Fragment>
      {/* <PageTitleCard
        imageUrl={loginImage}
        pageTitle='Welcome to Pbss'
        description=' working in diverse organizations such as the biotechnology and pharmaceutical industries.'
        buttonText='Learn More'
      /> */}
      <div className='login-form-container'>
        <CommonPageHeroText
          heading={ApplicationString['userLoginComponent.heading']}
          subheading={ApplicationString['userLoginComponent.subheading']}
        />
        <SectionLayout>
          <div className='login-container'>
            <div className='form-container extend-login-form'>
              <LoginForm />
              <Divider prefixCls='login-divider' type='vertical' />
              <div className='main-opening-container main-opening-con-login'>
                <RecentOpeningsJobs
                  title={'Recent Job Openings'}
                  data={allApprovedJobsData}
                  buttonName={'View Details'}
                  viewDesc={true}
                  usedForChapPage={true}
                  login={true}
                />
                {/* <a href='/jobs/approved' className='more-approved-listing'>
                <Button className='more-approved-button'>More Jobs</Button>
              </a> */}
                {/* <RecentOpeningsAds
                title={'Ads'}
                data={allApprovedAdsData}
                buttonName={'Visit Now'}
                viewDesc={true}
              /> */}
                {/* <a href='/ads/approved' className='more-approved-listing'>
                <Button className='more-approved-button'>More Ads</Button>
              </a> */}
              </div>
            </div>
          </div>
        </SectionLayout>
      </div>
    </React.Fragment>
  );
};

export default LoginComponent;

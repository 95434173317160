import React, {createContext, useContext, useEffect, useState} from 'react';

import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {FETCH_ERROR, FETCH_START, LOGOUT} from '../Constant/ActionTypes';
import {setAuthToken} from './client';

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

/**
 * @returns  It is returning a context provider component that
 * wraps the children components. The provider component is providing the logout function through the
 * JWTAuthActionsContext.
 */
const JWTAuthAuthProvider = ({children}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = async () => {
    dispatch({type: FETCH_START});
    try {
      localStorage.clear();
      setAuthToken();
      dispatch({type: LOGOUT});
      navigate('/login');
    } catch (error) {
      dispatch({type: FETCH_ERROR, payload: error});
    }
  };

  return (
    <JWTAuthContext.Provider>
      <JWTAuthActionsContext.Provider
        value={{
          logout,
        }}>
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

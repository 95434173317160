import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  getEventOptionsByEventId,
  getEventOptionsById,
} from '../../../Store/Actions/eventOptions';
import {
  createSelectionOptionsUtil,
  createSelectionOptionsUtilForPerks,
} from '../../../Util/commonUtility';
import {
  UpdateAttendee,
  UpdateSponsorById,
  applyCouponCodes,
  deleteAttendee,
  getAttendeesByTransactionId,
  getAttendesByUserAndEventId,
  sponsorRegistrationByUserAndEventId,
  updateFoodAndRegistrationOptions,
  verifyCouponCodes,
} from '../../../Store/Actions/eventRegister';
import {useNavigate, useParams} from 'react-router-dom';
import EventSponsorCartComponent from '../../../Components/UserComponents/EventSponsorCart';

const EventSponsorCartContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {allAttendees} = useSelector(({eventRegister}) => eventRegister);
  const {allAttendeesAfterPayment} = useSelector(
    ({eventRegister}) => eventRegister,
  );
  const {getEventOptionByEventId, getEventOptionsData} = useSelector(
    ({eventOptions}) => eventOptions,
  );

  const [eventOptionsFoodData, setEventOptionsFoodData] = useState([]);
  const [listSponsorAttendeesData, setListAttendeesData] = useState();
  const [allEventOptionsFoodData, setAllEventOptionsFoodData] = useState([]);
  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [allEventOptionsRegistrationData, setAllEventOptionsRegistrationData] =
    useState([]);
  const [sponsorData, setSponsorData] = useState([]);
  const [attendeeTypeOptions, setAttendeeTypeOptions] = useState([]);
  const [eventForDataLength, setEventForDataLength] = useState([]);
  const [appliedCartCoupon, setAppliedCartCoupon] = useState();
  const [freeLunchLimit, setFreeLunchLimit] = useState();
  const [freeLunchPrice, setFreeLunchPrice] = useState();
  const [appliedAttendeeCoupon, setAppliedAttendeeCoupon] = useState();
  const [isAppliedCoupon, setIsAppliedCoupon] = useState(false);
  const [allRegistrationData, setAllRegistrationData] = useState([]);
  const [attendeesData, setAttendeesData] = useState([]);
  const [eventOptionData, setEventOptionData] = useState([]);
  const [listAttendeesDataWithPerks, setListAttendeesDataWithPerks] = useState(
    [],
  );
  const [eventName, setEventName] = useState('');
  const [eventType, setEventType] = useState('');

  const {id, transactionId, optionId} = useParams();

  const getAttendeeByEventId = (id) => {
    const attendeeType = 'sponsor';
    dispatch(getAttendesByUserAndEventId(id, attendeeType));
  };

  useEffect(() => {
    if (getEventOptionByEventId) {
      const foodOptionsWithNoFood = getEventOptionByEventId?.data?.food || [];
      const perksUtilData = createSelectionOptionsUtilForPerks(
        foodOptionsWithNoFood,
        freeLunchLimit,
        freeLunchPrice,
        'id',
        'name',
        'price',
      );
      const regularUtilData = createSelectionOptionsUtil(
        foodOptionsWithNoFood,
        'id',
        'name',
        'price',
      );
      setEventOptionsFoodData([...perksUtilData, ...regularUtilData]);
    } else {
      dispatch(getEventOptionsByEventId(id));
    }
  }, [getEventOptionByEventId, , freeLunchLimit, freeLunchPrice]);

  useEffect(() => {
    if (eventOptionsFoodData) {
      setAllEventOptionsFoodData([
        ...eventOptionsFoodData,
        {value: 'no-food', label: 'No Food'},
      ]);
    }
  }, [eventOptionsFoodData]);

  useEffect(() => {
    transactionId
      ? fetchSponsorAttendeesListByTransactionId(transactionId)
      : fetchSponsorAttendeesList(id);
  }, [transactionId, id]);

  useEffect(() => {
    if (transactionId) {
      const commonDataVar = allAttendeesAfterPayment?.data?.eventRegistrations;
      if (allAttendeesAfterPayment) {
        setEventName(allAttendeesAfterPayment?.data?.event_name);
        setEventType(allAttendeesAfterPayment?.data?.event_type_name);
        setListAttendeesDataWithPerks(commonDataVar?.attendeesWithPerks);
        setListAttendeesData(commonDataVar?.attendees);
        setSponsorData(commonDataVar?.sponsor);
        setEventForDataLength(commonDataVar);
      } else {
        fetchSponsorAttendeesListByTransactionId(transactionId);
      }
    } else {
      if (allAttendees) {
        const commonDataVar = allAttendees?.data?.event_registrations;
        setTotalCartPrice({
          total_price_per_cart: allAttendees?.data?.total_price,
          discount: allAttendees?.data?.discount,
          discounted_price: allAttendees?.data?.discounted_price,
          refund_credit: allAttendees?.data?.refund_credit,
        });
        setEventName(allAttendees?.data?.event_name);
        setEventType(allAttendees?.data?.event_type_name);
        setListAttendeesDataWithPerks(commonDataVar?.attendeesWithPerks);
        setListAttendeesData(commonDataVar?.attendees);
        setSponsorData(commonDataVar?.sponsor);
        setEventForDataLength(commonDataVar);
        setAppliedCartCoupon(allAttendees?.data?.discount_code);
      } else {
        fetchSponsorAttendeesList(id);
      }
    }
  }, [allAttendees, allAttendeesAfterPayment, transactionId, id]);

  useEffect(() => {
    if (
      getEventOptionsData
        ? parseInt(optionId) === getEventOptionsData?.data?.id
        : getEventOptionsData
    ) {
      setEventOptionData(getEventOptionsData?.data);

      setFreeLunchLimit(
        getEventOptionsData?.data?.eventSponsorshipPerk?.free_lunch,
      );
      setFreeLunchPrice(
        getEventOptionsData?.data?.eventSponsorshipPerk?.lunch_price,
      );
    } else {
      SponsorAttendeeOptionById(optionId);
    }
  }, [getEventOptionsData]);

  useEffect(() => {
    if (isAppliedCoupon) {
      getAttendeeByEventId(id);
    }
  }, [isAppliedCoupon]);

  useEffect(() => {
    if (listSponsorAttendeesData) {
      const Data = Array.isArray(listSponsorAttendeesData)
        ? listSponsorAttendeesData?.map((item) => {
            return {
              ...item,
              food_option: item?.event_registration_options?.food,
              registration_option:
                item?.event_registration_options?.registration,
            };
          })
        : [];
      setAttendeesData(Data);
    }
  }, [listSponsorAttendeesData, id]);

  const updateSponsorAttendee = async (Data, id, setEditingKey, form) => {
    transactionId
      ? dispatch(
          updateFoodAndRegistrationOptions(Data, id, setEditingKey, form),
        )
      : dispatch(UpdateAttendee(Data, id, setEditingKey, form));
  };

  const applyCouponCode = async (Data) => {
    dispatch(applyCouponCodes(Data, setIsAppliedCoupon));
  };
  const updateSponsor = async (Data, id) => {
    dispatch(UpdateSponsorById(Data, id));
  };
  const fetchSponsorAttendeesList = async (id) => {
    const attendeeType = 'sponsor';
    dispatch(getAttendesByUserAndEventId(id, attendeeType));
  };
  const fetchSponsorAttendeesListByTransactionId = async (transactionId) => {
    dispatch(getAttendeesByTransactionId(transactionId));
  };
  const deleteSponsorAttendee = async (id) => {
    dispatch(deleteAttendee(id));
  };

  const paymentByUserRegistration = async (id, optionId) => {
    dispatch(
      sponsorRegistrationByUserAndEventId(
        id,
        navigate,
        totalCartPrice?.discounted_price === 0,
        optionId,
      ),
    );
  };

  const SponsorAttendeeOptionById = async (id) => {
    dispatch(getEventOptionsById(id));
  };

  return (
    <div>
      <EventSponsorCartComponent
        eventName={eventName}
        attendeesDataWithPerks={listAttendeesDataWithPerks}
        sponsorPerksData={eventOptionData}
        setListAttendeesData={setListAttendeesData}
        allRegistrationData={allRegistrationData}
        totalCartPrice={totalCartPrice}
        sponsorData={sponsorData}
        allEventOptionsFoodData={allEventOptionsFoodData}
        attendeeTypeOptions={attendeeTypeOptions}
        allEventOptionsRegistrationData={allEventOptionsRegistrationData}
        deleteSponsorAttendee={(id) => {
          deleteSponsorAttendee(id);
        }}
        SponsorAttendeeOptionById={(id) => {
          SponsorAttendeeOptionById(id);
        }}
        allSponsorAttendeesData={attendeesData}
        applyCouponCode={(Data) => {
          applyCouponCode(Data);
        }}
        updateSponsorAttendee={(id, Data, setEditingKey, form) => {
          updateSponsorAttendee(id, Data, setEditingKey, form);
        }}
        paymentByUserRegistration={(id, optionId) => {
          paymentByUserRegistration(id, optionId);
        }}
        updateSponsor={(Data, id) => {
          updateSponsor(Data, id);
        }}
        eventRegisterData={eventForDataLength}
        appliedCartCoupon={appliedCartCoupon}
        eventType={eventType}
        setAttendeesData={setAttendeesData}
        setListAttendeesDataWithPerks={setListAttendeesDataWithPerks}
      />
    </div>
  );
};

export default EventSponsorCartContainer;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  addEmailCode,
  addEmailCodes,
  fetchAdById,
  fetchEmailCodeById,
  updateAdById,
  updateEmailCodeById,
} from '../../../../Store/Actions';
import EmailCodeFormComponent from '../../../../Components/AdminComponents/EmailCodes/EmailCodeForm';
import {useNavigate} from 'react-router-dom';

const EmailCodeFormContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {fetchEditData} = useSelector(({emailCodes}) => emailCodes);
  const [editData, setEditData] = useState();

  useEffect(() => {
    if (fetchEditData) {
      setEditData(fetchEditData?.data);
    }
  }, [fetchEditData.data]);

  const postEmailCodes = async (Data) => {
    dispatch(addEmailCode(Data, navigate));
  };

  const updateEmailCodes = async (Data, id) => {
    dispatch(updateEmailCodeById(Data, id, navigate));
  };

  const fetchEmailCodesById = async (id) => {
    dispatch(fetchEmailCodeById(id));
  };

  return (
    <div>
      <EmailCodeFormComponent
        postEmailCodes={(Data) => {
          postEmailCodes(Data);
        }}
        updateEmailCodes={(id, Data) => {
          updateEmailCodes(id, Data);
        }}
        fetchEmailCodesById={(id) => {
          fetchEmailCodesById(id);
        }}
        editData={editData}
      />
    </div>
  );
};

export default EmailCodeFormContainer;

import React, {useState, useEffect} from 'react';
import {ApplicationString} from '../../../Localization/Locales/en';
import {Button, Input, Select, Card, Alert, Dropdown, Row, Col} from 'antd';
import {SearchOutlined, FilterOutlined, PlusOutlined} from '@ant-design/icons';
import CommonAdsListing from './CommonAdsListing';
import dayjs from 'dayjs';
import './index.css';
import {useDispatch} from 'react-redux';
import {
  AdsAndJobsOptions,
  getLocalStorageUserInfo,
  JobStatus,
} from '../../../Util/commonUtility';
import AdsFormComponent from './AdsForm';
import {fetchAdsByUserId} from '../../../Store/Actions';
import SectionLayout from './../../CommonComponents/Layout/sectionLayout';
import StatusUpdateModal from '../../CommonComponents/CommonModal';
import ads from '../../../Assets/ads.svg';
import PageTitleCard from '../../CommonComponents/PageTitleCard';
import PageHeading from './../../CommonComponents/PageHeading/index';
import CommonHeroText from '../../CommonComponents/CommonHeroText';

const UserAdsComponent = (props) => {
  const {
    allAdsData,
    setAllAdsData,
    page,
    setPage,
    adFeesOptions,
    zeroFeeData,
    fetchNewData,
    setFetchNewData,
  } = props;

  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRowJobsListingPage'>
        <Col span={24}>
          <CommonHeroText
            heading={ApplicationString['userAdsComponent.heading']}
            subheading={''}
          />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <span className='jobListingPageContentHeading'>
              {ApplicationString['userAdsComponent.contentHeading']}
            </span>
          </SectionLayout>
        </Col>
        <Col span={24}>
          <CommonAdsListing
            allAdsData={allAdsData}
            setAllAdsData={setAllAdsData}
            page={page}
            setPage={setPage}
            adFeesOptions={adFeesOptions}
            zeroFeeData={zeroFeeData}
            fetchNewData={fetchNewData}
            setFetchNewData={setFetchNewData}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserAdsComponent;

import React from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import {Col, Row} from 'antd';
import CustomCollapse from '../../CommonComponents/CommonAccordian';

const FaqComponent = () => {
  return (
    <React.Fragment>
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        <Col span={24}>
          <SponsorOptionPageHeroText title={'Frequently Asked Questions'} />
        </Col>
        <Col span={24}>
          <SectionLayout>
            <CustomCollapse titleHide />
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FaqComponent;

import React from 'react';
import './style.css';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {Divider} from 'antd';

const VendorShowSponsor = () => {
  return (
    <React.Fragment>
      {/* <div className='common-image-text'>
        <p>{ApplicationString['userVendorInfoCardComponent.p1']}</p>
        <p>{ApplicationString['userVendorInfoCardComponent.p2']}</p>
      </div> */}
      <div className='static-container'>
        {/* <span>{ApplicationString['userVendorInfoCardComponent.title']}</span> */}
        {/* <div className='static-container-card'>
          <div className='card-body'>
            <label>
              {ApplicationString['userVendorInfoCardComponent.body1.label']}
            </label>
            <p>{ApplicationString['userVendorInfoCardComponent.body1.p']}</p>
          </div>
          <div className='card-body'>
            <label>
              {ApplicationString['userVendorInfoCardComponent.body2.label']}
            </label>
            <p>{ApplicationString['userVendorInfoCardComponent.body2.p']}</p>
          </div>
        </div> */}
        <div className='vendor-show-card-container'>
          <span>
            {
              ApplicationString[
                'userVendorInfoCardComponent.vendorShowCard1.title'
              ]
            }
          </span>
          <div>
            <li>
              {
                ApplicationString[
                  'userVendorInfoCardComponent.vendorShowCard1.li1'
                ]
              }
            </li>
            <li>
              {
                ApplicationString[
                  'userVendorInfoCardComponent.vendorShowCard1.li2'
                ]
              }
            </li>
            <li>
              {
                ApplicationString[
                  'userVendorInfoCardComponent.vendorShowCard1.li3'
                ]
              }
            </li>
            <li>
              {
                ApplicationString[
                  'userVendorInfoCardComponent.vendorShowCard1.li4'
                ]
              }
            </li>
          </div>
        </div>

        <div className='vendor-show-card-container'>
          <span>
            {
              ApplicationString[
                'userVendorInfoCardComponent.vendorShowCard2.title'
              ]
            }
          </span>
          <div className='time-card-container'>
            <ul>
              <li>
                <div className='time-flex-card'>
                  <p>
                    {
                      ApplicationString[
                        'userVendorInfoCardComponent.vendorShowCard2.time-p1'
                      ]
                    }
                  </p>
                  <label>
                    {
                      ApplicationString[
                        'userVendorInfoCardComponent.vendorShowCard2.time-label1'
                      ]
                    }
                  </label>
                </div>
              </li>
              <li>
                <div className='time-flex-card'>
                  <p>
                    {' '}
                    {
                      ApplicationString[
                        'userVendorInfoCardComponent.vendorShowCard2.time-p2'
                      ]
                    }{' '}
                  </p>
                  <label>
                    {' '}
                    {
                      ApplicationString[
                        'userVendorInfoCardComponent.vendorShowCard2.time-label2'
                      ]
                    }
                  </label>
                </div>
              </li>
              <div className='vendor-show-btext-container'>
                <p>
                  {
                    ApplicationString[
                      'userVendorInfoCardComponent.vendorShowbtext1-p'
                    ]
                  }
                </p>
                <label>
                  {
                    ApplicationString[
                      'userVendorInfoCardComponent.vendorShowbtext1-label'
                    ]
                  }
                </label>
              </div>
              <li>
                <div className='time-flex-card'>
                  <p>
                    {
                      ApplicationString[
                        'userVendorInfoCardComponent.vendorShowCard2.time-p3'
                      ]
                    }
                  </p>
                  <label>
                    {
                      ApplicationString[
                        'userVendorInfoCardComponent.vendorShowCard2.time-label3'
                      ]
                    }
                  </label>
                </div>
              </li>
            </ul>
          </div>

          <div className='vendor-show-btext-container'>
            <span>
              {
                ApplicationString[
                  'userVendorInfoCardComponent.vendorShowbtext2-span'
                ]
              }
            </span>
            <p>
              {
                ApplicationString[
                  'userVendorInfoCardComponent.vendorShowbtext2-p'
                ]
              }
            </p>
          </div>
        </div>
      </div>
      <div className='static-container'>
        <span>
          {
            ApplicationString[
              'userSponsorPolicyComponent.membershipListSharing'
            ]
          }
        </span>
        <div className='static-container-card'>
          <div className='card-body list-sharing-text'>
            {
              ApplicationString[
                'userSponsorPolicyComponent.membershipListSharing.description'
              ]
            }
          </div>
        </div>
        <div className='vendor-show-card-container'>
          <span>
            {
              ApplicationString[
                'userSponsorPolicyComponent.vendorShowCard.title'
              ]
            }
          </span>
          <ul>
            <div>
              <li>
                {
                  ApplicationString[
                    'userSponsorPolicyComponent.vendorShowCard.description'
                  ]
                }
              </li>
            </div>
          </ul>
        </div>
        <div className='vendor-show-card-container'>
          <span>
            {
              ApplicationString[
                'userSponsorPolicyComponent.vendorShowCard.title2'
              ]
            }
          </span>
          <ul>
            <div>
              <li>
                {
                  ApplicationString[
                    'userSponsorPolicyComponent.vendorShowCard.li1'
                  ]
                }
              </li>
            </div>
            <div>
              <li>
                {
                  ApplicationString[
                    'userSponsorPolicyComponent.vendorShowCard.li2'
                  ]
                }
              </li>
            </div>
            <div>
              <li>
                {
                  ApplicationString[
                    'userSponsorPolicyComponent.vendorShowCard.li3'
                  ]
                }
              </li>
            </div>
            <div>
              <li>
                {
                  ApplicationString[
                    'userSponsorPolicyComponent.vendorShowCard.li4'
                  ]
                }
              </li>
            </div>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VendorShowSponsor;

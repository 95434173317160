import React, {useEffect, useState} from 'react';
import {
  Modal,
  Form,
  Input,
  Upload,
  Image,
  Button,
  Space,
  Tooltip,
  message,
} from 'antd';
import {
  DeleteOutlined,
  FileOutlined,
  UploadOutlined,
  EditOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import './style.css';
import {applicationConfig} from '../../../Util/config';
import {
  EventRegistrationStatus,
  NoEmptySpaceInput,
  OnlyAlphabetInputValidator,
  UploadFileMaxSize,
  formatCurrency,
  validateEmailInput,
} from '../../../Util/commonUtility';
import {useParams} from 'react-router-dom';

const SponsorModal = ({
  isModalOpen,
  onFinish,
  localUserData,
  dummyRequest,
  validLogoFileList,
  validBannerFileList,
  beforeUploadLogos,
  beforeUploadBanner,
  removeBannerFileHandler,
  removeLogoFileHandler,
  eventSponsorData,
  handleClose,
  eventSponsorPerksData,
}) => {
  const [form] = Form.useForm();
  const [allLogoIds, setAllLogoIds] = useState([]);
  const [editDetails, setEditDetails] = useState(true);
  const {id, optionId} = useParams();

  useEffect(() => {
    if (Object.keys(eventSponsorData).length > 0) {
      setEditDetails(true);
      form.setFieldsValue({
        ...eventSponsorData,
        id: eventSponsorData?.id,
      });
    } else {
      setEditDetails(false);
    }
  }, [eventSponsorData, eventSponsorPerksData, editDetails]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  // const handleDeleteLogo = (logoId) => {
  //   // Implement the logic to delete the logo
  // };

  // const handleEdit = () => {
  //   setEditDetails(false);
  // };

  const handleCancel = () => {
    if (Object.keys(eventSponsorData).length > 0) {
      setEditDetails(true);
    } else {
      handleClose();
    }
  };

  return (
    <Modal
      title={`Asset Details`}
      closable={eventSponsorData ? true : false}
      open={isModalOpen}
      centered
      onCancel={handleClose}
      className='sponsor-upload-assets'
      footer={[
        <div key={0} className='sponsor-submit-container'>
          <Space>
            <Button
              key='edit'
              type='default'
              onClick={handleClose}
              className='user-common-submit-login common-form-submit'>
              Back
            </Button>
            <Button
              key='continue'
              type='primary'
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    form.resetFields(); // Reset the form fields
                    onFinish(values);
                    setEditDetails(true);
                  })
                  .catch((info) => {
                    console.error('Validate Failed:', info);
                  });
              }}
              htmlType='submit'
              className='user-common-submit-login common-form-submit w-100'>
              Submit
            </Button>
          </Space>
        </div>,
      ]}>
      <Form
        form={form}
        initialValues={{
          attendee_first_name: localUserData?.first_name,
          attendee_email: localUserData?.email,
        }}
        name='eventForm'
        onFinish={onFinish}>
        <Form.Item
          hidden
          prefixCls='sponsor-form-item'
          name='attendee_first_name'
          className='logo-form-item hide-sponsor w-100'
          // rules={[
          //   {
          //     required: true,
          //     message: 'Please enter your first name!',
          //   },
          // ]}
        >
          <Input />
        </Form.Item>
        <Form.Item hidden name='id'>
          <Input />
        </Form.Item>
        <Form.Item
          hidden
          prefixCls='sponsor-form-item'
          name='attendee_last_name'
          className='logo-form-item hide-sponsor w-100'>
          <Input />
        </Form.Item>
        <Form.Item
          hidden
          prefixCls='sponsor-form-item'
          name='attendee_email'
          className='logo-form-item hide-sponsor w-100'
          // rules={[
          //   {
          //     required: true,
          //     message: 'Please enter your email!',
          //   },
          //   {
          //     type: 'email',
          //     message: 'Please enter a valid email address!',
          //   },
          // ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          hidden
          prefixCls='sponsor-form-item'
          name='attendee_company'
          className='logo-form-item hide-sponsor w-100'
          // rules={[
          //   {
          //     required: true,
          //     message: 'Please enter your company name!',
          //   },
          // ]}
        >
          <Input />
        </Form.Item>
        {eventSponsorPerksData?.sponsorship_type ===
        EventRegistrationStatus.PAID ? (
          <div className='upload-conatiner'>
            <Form.Item
              name='logo'
              className='logo-form-item form-item-md w-100'
              getValueFromEvent={normFile}>
              <Upload.Dragger
                prefixCls='upload-list-name-fix-class'
                {...beforeUploadBanner}
                fileList={validBannerFileList}
                onRemove={removeBannerFileHandler}
                customRequest={dummyRequest}
                showUploadList={true}>
                <div className='upload-dag-conatiner'>
                  <label>Upload Flyer</label>
                  <p className='ant-upload-drag-icon'>
                    {/* <FileOutlined className='icon-style' /> */}
                  </p>
                  <p className='upload-text'>
                    Accepts only pdf/image files (png, jpg, jpeg, pdf)
                    <br />
                    {`Maximum file size is ${UploadFileMaxSize}MB.`}
                  </p>
                </div>
              </Upload.Dragger>
            </Form.Item>
            <Form.Item
              name='flyer'
              className='logo-form-item-a form-item-md w-100'>
              <Upload.Dragger
                prefixCls='upload-list-name-fix-class'
                className='upload-assets'
                {...beforeUploadLogos}
                accept='image/*'
                customRequest={dummyRequest}
                onRemove={removeLogoFileHandler}
                fileList={validLogoFileList}
                showUploadList={true}>
                <div className='upload-dag-conatiner'>
                  <label>Upload Company/Sponsor Logo</label>
                  <p className='upload-text'>
                    Accepts only image files (png, jpg, jpeg).
                    <br />
                    {`Maximum file size is ${UploadFileMaxSize}MB.`}
                  </p>
                </div>
              </Upload.Dragger>
            </Form.Item>
          </div>
        ) : (
          ''
        )}
        {/* {validLogoFileList?.length > 0 ? (
            <Form.Item label='Uploaded Logos'>
              <div className='logo-list-container'>
                {validLogoFileList?.map((logo, index) => (
                  <div
                    key={index}
                    className='logo-container extend-logo-container'>
                    <Image
                      crossOrigin='anonymous'
                      preview={true}
                      className='sponsor-icon-email-attach'
                      src={
                        logo.name
                          ? `${applicationConfig.UPLOAD_URL}/${logo?.name}`
                          : placeholder
                      }
                    />
                    <Tooltip
                      className='common-pbss-tooltip'
                      title='Click to delete this logo'>
                      <Button
                        // type='button'
                        className='delete-logo-button'
                        onClick={() => handleDeleteLogo(logo?.uid)}>
                        <DeleteOutlined />
                      </Button>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </Form.Item>
          ) : (
            ''
          )} */}
        {/* {validBannerFileList?.length > 0 ? (
            <Form.Item label='Uploaded Banners'>
              <div className='logo-list-container'>
                {validBannerFileList?.map((logo, index) => (
                  <div
                    key={index}
                    className='logo-container extend-logo-container'>
                    <Image
                      crossOrigin='anonymous'
                      preview={true}
                      className='sponsor-icon-email-attach'
                      src={
                        logo?.name
                          ? `${applicationConfig.UPLOAD_URL}/${logo?.name}`
                          : placeholder
                      }
                    />
                    <Tooltip
                      className='common-pbss-tooltip'
                      title='Click to delete this logo'>
                      <Button
                        // type='button'
                        className='delete-logo-button'
                        onClick={() => handleDeleteBanner(logo?.uid)}>
                        <DeleteOutlined />
                      </Button>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </Form.Item>
          ) : (
            ''
          )}{' '} */}
      </Form>
    </Modal>
  );
};

export default SponsorModal;

import React, {useEffect, useState} from 'react';
import {Alert, Button, Form, Input, Skeleton, Space, Table, Tag} from 'antd';
import AdminActionsDropdown from '../../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import EditableCell from '../../../CommonComponents/EditableCell';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {formValueChangeHandler} from '../../../../Util/commonUtility';

const SponsorDetailForm = ({
  onFinish,
  localUserData,
  eventSponsorData,
  setDisableAttendeeClicks,
  alertInformation,
}) => {
  const isEditing = (record) => record.id === editingKey;
  const [dataSource, setDataSource] = useState([]);
  const [sponsorCreatedSuccessFully, setSponsorCreatedSuccessFully] =
    useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(eventSponsorData).length > 0) {
      setDataSource([
        {
          id: eventSponsorData.id,
          attendee_first_name: eventSponsorData.attendee_first_name,
          attendee_last_name: eventSponsorData.attendee_last_name,
          attendee_email: eventSponsorData.attendee_email,
          attendee_company: eventSponsorData.attendee_company,
        },
      ]);
    } else {
      if (sponsorCreatedSuccessFully) {
        form.setFieldsValue({
          attendee_email_confirm: '',
          attendee_first_name: '',
          attendee_last_name: '',
          attendee_email: '',
          attendee_company: '',
        });
      }
      setEditingKey('1');
      setDataSource([
        {
          id: '1',
          attendee_first_name: '',
          attendee_last_name: '',
          attendee_email: '',
          attendee_company: '',
        },
      ]);
    }
  }, [eventSponsorData, localUserData]);

  useEffect(() => {
    if (eventSponsorData?.length === 0) {
      const loading = !eventSponsorData || eventSponsorData.length === 0;
      setLoading(loading);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [eventSponsorData]);

  const handleSave = async (key) => {
    const row = await form.validateFields();
    const sponsorData = {
      ...row,
      id: key.id,
    };
    onFinish(sponsorData, setSponsorCreatedSuccessFully);
    setEditingKey('');
    setDisableAttendeeClicks(false);
  };

  const edit = (record) => {
    setDisableAttendeeClicks(true);
    form.setFieldsValue({
      attendee_email_confirm: record?.attendee_email
        ? record?.attendee_email
        : '',
      attendee_first_name: record.attendee_first_name,
      attendee_last_name: record.attendee_last_name,
      attendee_email: record.attendee_email,
      attendee_company: record.attendee_company,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const columns = [
    {
      title: 'Contact Person First Name',
      dataIndex: 'attendee_first_name',
      width: '6%',
      editable: true,
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },
    {
      title: 'Contact Person Last Name',
      dataIndex: 'attendee_last_name',
      width: '6%',
      editable: true,
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },
    {
      title: 'Contact Person Email',
      dataIndex: 'attendee_email',
      width: '8%',
      editable: true,
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },
    {
      title: 'Company Affiliation',
      dataIndex: 'attendee_company',
      width: '6%',
      editable: true,
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },
    {
      title: ' ',
      dataIndex: 'action',
      width: '4%',
      render: (_, record) => {
        const hasEventSponsors =
          Object.keys(eventSponsorData).length > 0 ? true : false;
        return (
          <AdminActionsDropdown
            record={record}
            usedForAttendeeTable={true}
            editBool={true}
            attendeeEditingKey={editingKey}
            handleCancelClick={cancel}
            handleSaveClick={handleSave}
            deleteBool={false}
            handleEditClick={edit}
            cancelBool={hasEventSponsors}
            ToolTipTitle={'Sponsor'}
            //   handleDeleteClick={() => handleDelete(record.id)}
          />
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form
      form={form}
      onValuesChange={(changedValues) =>
        formValueChangeHandler(form, changedValues)
      }>
      <Skeleton
        loading={loading}
        prefixCls='table-skeleton'
        active
        paragraph={{rows: eventSponsorData?.length}}>
        <div className='attendees-content-container'>
          <div className='attendees-label'>
            {ApplicationString['userAddAttendeeComponent.sponsorDetail.label']}
          </div>
          {alertInformation && (
            <div className='attendees-description-container'>
              <Alert
                prefixCls='attendees-description-alert'
                message={alertInformation}
                type='info'
                showIcon
              />
            </div>
          )}
        </div>

        <Table
          prefixCls='attendees-table-container'
          components={{
            body: {
              cell: (props) => (
                <EditableCell {...props} usedForSponsorDetails />
              ),
            },
          }}
          scroll={{x: columns?.length * 150}}
          rowClassName={() => 'editable-row'}
          dataSource={dataSource}
          columns={mergedColumns}
          pagination={false}
        />
      </Skeleton>
    </Form>
  );
};

export default SponsorDetailForm;

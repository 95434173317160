import React, {useEffect, useState} from 'react';
import SectionLayout from '../Layout/sectionLayout';
import {Alert, Button, Card, Col, Row} from 'antd';
import CommonAddNewListingWithFilter from '../Common_AddNewListing_with_Filter';
import MyEventCard from './MyEventCard';
import {useDispatch, useSelector} from 'react-redux';
import {
  DefaultListingViewMoreCount,
  EventRegistrationType,
  LoadMoreSize,
  createSelectionOptionsUtil,
  getLocalStorageUserInfo,
  myEventsCategoryOptions,
  statusEnums,
  statusOptions,
} from '../../../Util/commonUtility';
import moment from 'moment';
import {
  getAllMyEvents,
  getEventsByChapters,
} from '../../../Store/Actions/events';
import {getAllChapters} from '../../../Store/Actions';
import DownArrowSvg from '../../../Assets/svgComponents/DownArrowSvg';
import useDebounceCallBack from '../../../hooks/useDebounce';
import EventTypeFilter from '../EventTypeFilter';

const MyEventListingV2 = ({
  formattedEventData,
  setFormattedEventData,
  fetchNewData,
  setFetchNewData,
  setUserCredit,
}) => {
  let dispatch = useDispatch();
  const {allMyEvents} = useSelector(({events}) => events);
  const {getEventsDataByChapters} = useSelector(({events}) => events);
  const {defaultChapterValue} = useSelector(({chapters}) => chapters);
  const [getAllEventData, setGetAllEventData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [eventDataChapters, setEventDataChapters] = useState([]);
  const [getAllEventDataByChapter, setGetAllEventDataByChapter] = useState();
  const {allChapters} = useSelector(({chapters}) => chapters);
  const [chaptersList, setChaptersList] = useState([]);
  const [newChaptersList, setNewChapterList] = useState([]);
  const [countData, setCountData] = useState(LoadMoreSize);
  const [totalEventCount, setTotalEventCount] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [selectedYearValue, setSelectedYearValue] = useState('');
  const [selectedCategoryValue, setSelectedCategoryValue] = useState('');
  const [filterValues, setFilterValues] = useState(statusEnums.PAID);
  const [searchValues, setSearchValues] = useState('');
  const userInfo = getLocalStorageUserInfo();
  const userId = userInfo?.id;
  const [eventType, setEventType] = useState(EventRegistrationType.regular);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const filterEvents = (events, type) => {
    return events?.filter((event) => {
      if (type === EventRegistrationType.regular) {
        return event?.attendees?.some((attendee) => attendee?.status !== 1);
      } else if (type === EventRegistrationType.sponsorship) {
        return event?.sponsors?.some((sponsor) => sponsor?.status !== 1);
      } else if (type === 'both') {
        return (
          event?.attendees?.some((attendee) => attendee?.status !== 1) &&
          event?.sponsors?.some((sponsor) => sponsor?.status !== 1)
        );
      }
      return false;
    });
  };

  useEffect(() => {
    if (formattedEventData) {
      const filtered = filterEvents(formattedEventData, eventType);
      setFilteredEvents(filtered);
    } else {
      setFilteredEvents(null);
    }
  }, [eventType, formattedEventData]);

  //   if (allChapters) {
  //     setChaptersList(
  //       createSelectionOptionsUtil(allChapters?.data?.chapters, 'id', 'name'),
  //     );
  //   } else {
  //     dispatch(getAllChapters());
  //   }
  // }, [allChapters, dispatch]);

  // useEffect(() => {
  //   if (chaptersList) {
  //     const newChapterList = [{label: 'All Events', value: 0}, ...chaptersList];
  //     setNewChapterList(newChapterList);
  //   }
  // }, [chaptersList]);

  // useEffect(() => {
  //   if (allMyEvents) {
  //     console.log(allMyEvents);
  //     const Data = allMyEvents?.data;
  //     setGetAllEventData(Data);
  //     setTotalEventCount(allMyEvents?.data?.totalCount);
  //   } else {
  //     console.log('hello');
  //     dispatch(getAllMyEvents());
  //   }
  // }, [allMyEvents, countData]);

  // useEffect(() => {
  // if (getAllEventData) {
  // const updatedEventData = getAllEventData?.map((item, index) => {
  //   return {
  //     ...item,
  //     key: item.id,
  //     sponsorshipAvailable: `${
  //       Array.isArray(item.sponsors) ? item.sponsors.length : 0
  //     } Sponsorship Available`,
  //     speakers: Array.isArray(item.speakers)
  //       ? item.speakers.map((speakers) => {
  //           return speakers.first_name + ' ' + speakers.last_name + ',';
  //         })
  //       : '',
  //     date: moment(item.date).utcOffset(0).format('MMM DD'),
  //     eventName: item.name,
  //     registerText: 'Register Now',
  //     price: '295',
  //     category: 'PBSS International Boston - PBSS',
  //     eventType: item.event_type_name,
  //     eventChapter: item?.chapter_name,
  //   };
  // });
  // setEventData(updatedEventData);
  // console.log(getAllEventData, 'data');
  // }
  // }, [getAllEventData]);

  // useEffect(() => {
  //   if (getEventsDataByChapters) {
  //     const Data = getEventsDataByChapters?.data?.events?.slice(0, countData);
  //     setGetAllEventDataByChapter(Data);
  //   } else {
  //     dispatch(getEventsByChapters(defaultChapterValue));
  //   }
  // }, [getEventsDataByChapters, countData]);
  // useEffect(() => {
  //   const userInfo = localStorage.getItem('userInfo');
  //   if (userInfo) {
  //     setLoggedIn(true);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (getEventsDataByChapters) {
  //     const Data = getEventsDataByChapters?.data?.events?.slice(0, countData);
  //     setGetAllEventDataByChapter(Data);
  //   }
  // }, [getEventsDataByChapters, countData]);

  // useEffect(() => {
  //   if (loggedIn && defaultChapterValue !== false) {
  //     dispatch(getEventsByChapters(defaultChapterValue));
  //   }
  // }, [loggedIn, dispatch, defaultChapterValue]);
  // useEffect(() => {
  //   if (getAllEventDataByChapter) {
  //     const updatedEventData =
  //       getAllEventDataByChapter.length > 0
  //         ? getAllEventDataByChapter.map((item) => {
  //             return {
  //               key: item.id,
  //               sponsorshipAvailable: `${
  //                 Array.isArray(item.sponsors) ? item.sponsors.length : 0
  //               } Sponsorship Available`,
  //               speakers: Array.isArray(item.speakers)
  //                 ? item.speakers.map((speakers) => {
  //                     return (
  //                       <div key={speakers}>
  //                         {speakers.first_name + ' ' + speakers.last_name}
  //                       </div>
  //                     );
  //                   })
  //                 : '',
  //               date: moment(item.date).utcOffset(0).format('MMM DD'),
  //               eventName: item.name,
  //               registerText: 'Complete Payment',
  //               location: item.place,
  //               price: '295',
  //               category: 'PBSS International Boston - PBSS',
  //               eventType: item?.event_type_name,
  //               eventChapter: item?.chapter_name,
  //             };
  //           })
  //         : null;
  //     setEventDataChapters(updatedEventData);
  //   }
  // }, [getAllEventDataByChapter]);
  // const [selectedCategory, setSelectedCategory] = useState('All Events');
  // const [activeButton, setActiveButton] = useState(null);
  // const filteredEvents =
  //   selectedCategory === 'All Events' ? eventData : eventDataChapters;

  // const handleFilter = (value) => {
  //   if (value === 0) {
  //     dispatch(getAllMyEvents());
  //   } else {
  //     dispatch(getEventsByChapters(value));
  //   }
  //   setSelectedCategory(value);
  // };
  // const filterByAction = (buttonName) => {
  //   if (activeButton === buttonName) {
  //     setActiveButton(null);
  //     dispatch(getAllMyEvents());
  //   } else {
  //     setActiveButton(buttonName);
  //     dispatch(getAllMyEvents(buttonName));
  //   }
  // };
  // const handleLoadMore = () => {
  //   dispatch(getAllMyEvents());
  //   setCountData(countData * 2);
  // };
  // const filterBydate = (selectedDate) => {
  //   const NewDate = selectedDate
  //     ? moment(new Date(selectedDate)).format('YYYY-MM-DD')
  //     : '';
  //   dispatch(getAllMyEvents(NewDate));
  // };

  // const handleUpdateStatus = (statusValue) => {
  //   setFilterValues(statusValue);
  //   filterByAction(filterValues);
  //   setShowUpdateStatus(false);
  // };

  // const hideModal = () => {
  //   setShowUpdateStatus(false);
  // };

  // Search Logic ------------------------------------------------
  // onSearch, searchOnChange and useEffect with [searchValues]

  // 1. On Search:
  const onSearch = () => {
    dispatch(
      getAllMyEvents(
        selectedCategoryValue,
        filterValues,
        selectedYearValue,
        searchValues,
        setFormattedEventData,
        setUserCredit,
      ),
    );
  };

  useEffect(() => {
    if (fetchNewData) {
      dispatch(
        getAllMyEvents(
          selectedCategoryValue,
          filterValues,
          selectedYearValue,
          searchValues,
          setFormattedEventData,
          setUserCredit,
        ),
      );
      setFetchNewData(false);
    }
  }, [fetchNewData]);

  //2. Search On Change:
  const searchOnChange = useDebounceCallBack((e) => {
    let search = e.target.value;
    if (search) {
      setSearchValues(e.target.value);
    } else {
      setSearchValues('');
    }
  }, 1500);

  //3. UseEffect on searchvalue change
  useEffect(() => {
    if (searchValues || filterValues) {
      dispatch(
        getAllMyEvents(
          selectedCategoryValue,
          filterValues,
          selectedYearValue,
          searchValues,
          setFormattedEventData,
          setUserCredit,
        ),
      );
    } else {
      dispatch(
        getAllMyEvents(
          selectedCategoryValue,
          filterValues,
          selectedYearValue,
          searchValues,
          setFormattedEventData,
          setUserCredit,
        ),
      );
    }
  }, [searchValues]);

  // Search Logic Finished ---------------------------------------

  // Year Filter Logic -------------------------------------
  const handleYearFilter = (yearValue) => {
    if (!yearValue) {
      dispatch(
        getAllMyEvents(
          selectedCategoryValue,
          filterValues,
          '',
          searchValues,
          setFormattedEventData,
        ),
      );
      setSelectedYearValue('');
    } else {
      dispatch(
        getAllMyEvents(
          selectedCategoryValue,
          filterValues,
          yearValue,
          searchValues,
          setFormattedEventData,
        ),
      );
      setSelectedYearValue(yearValue);
    }
  };
  // Year Filter Logic Finished -------------------------------

  // Status Filter Logic ----------------------------------------------------
  const handleChange = (value) => {
    if (!value && value !== 0 && value !== -1) {
      dispatch(
        getAllMyEvents(
          selectedCategoryValue,
          '',
          selectedYearValue,
          searchValues,
          setFormattedEventData,
        ),
      );
      setFilterValues('');
    } else {
      dispatch(
        getAllMyEvents(
          selectedCategoryValue,
          value,
          selectedYearValue,
          searchValues,
          setFormattedEventData,
        ),
      );
      setFilterValues(value);
    }
  };
  // Status Filter Logic Finished -------------------------------------------

  // Category Filter Logic ---------------------------------------------
  // Category Includes Attendee, Upcoming, this week and this month options
  const handleCategoryFilter = (categoryValue) => {
    if (!categoryValue) {
      dispatch(
        getAllMyEvents(
          '',
          filterValues,
          selectedYearValue,
          searchValues,
          setFormattedEventData,
        ),
      );
      setSelectedCategoryValue('');
    } else {
      dispatch(
        getAllMyEvents(
          categoryValue,
          filterValues,
          selectedYearValue,
          searchValues,
          setFormattedEventData,
        ),
      );
      setSelectedCategoryValue(categoryValue);
    }
  };
  // Category Filter Logic Finished -----------------------------------------

  return (
    <React.Fragment>
      <SectionLayout>
        <Row gutter={[0, 32]}>
          <Col span={24}>
            <CommonAddNewListingWithFilter
              selectedYearValue={selectedYearValue}
              selectedStatusValue={statusEnums.PAID}
              selectedCategoryValue={selectedCategoryValue}
              addNew={false}
              usedForMyEvents={true}
              year
              search
              pageName={'My Event'}
              status={false}
              registrtion_filter={
                <EventTypeFilter setEventType={setEventType} />
              }
              // myEventsFilterByAction={filterByAction}
              // myEventsActiveButton={activeButton}
              selectPlaceholder={'Status'}
              List={statusOptions}
              categoryList={myEventsCategoryOptions}
              searchOnChange={searchOnChange}
              onSearch={onSearch}
              handleChange={handleChange}
              handleYearFilter={handleYearFilter}
              handleCategoryFilter={handleCategoryFilter}
              placeHolder='Search by event name'
            />
          </Col>
          <Col span={24}>
            <div className='event2-listing-cards'>
              {filteredEvents?.length > 0 ? (
                filteredEvents?.map((event, index) => {
                  const toBePaidSponsor =
                    event?.sponsors?.length > 0
                      ? event?.sponsors?.filter(
                          (sponsor) => sponsor?.status === 1,
                        )
                      : [];
                  return (
                    <MyEventCard
                      paid
                      key={event?.id}
                      eventRegistrationType={eventType}
                      eventId={event?.id}
                      attendees={[
                        ...(event?.attendees || []),
                        // ...(event?.sponsors || []),
                      ]}
                      chapter={event?.chapter}
                      date={event?.date}
                      eventName={event?.eventName}
                      eventType={event?.eventType}
                      eventInfo={event?.eventInfo}
                      sponsorshipAvailable={event?.sponsorshipAvailable}
                      registration_status={event?.registration_status}
                      status={event?.status}
                      receiptData={event}
                      usedForMyEvents={true}
                      sponsors={event?.sponsors}
                      eventStartDate={event?.event_start_date}
                      toBePaidSponsor={toBePaidSponsor}
                    />
                  );
                })
              ) : (
                <Alert
                  message='No Events Available'
                  className='no-jobs-alert'
                  type='info'
                  showIcon
                />
              )}
              {/* {filteredEvents?.map((event, index) => (
                <MyEventCard
                  attendees={event?.attendees}
                  registration_status={'Paid'}
                  receiptData={event}
                  isExpand={true}
                  key={event?.key}
                  id={event?.key}
                  sponsorshipAvailable={event?.sponsorshipAvailable}
                  eventName={event?.eventName}
                  date={event?.date}
                  speakers={event?.speakers}
                  registerText={event?.registerText}
                  price={event?.price}
                  moreDetails={false}
                  descriptionLabel={'Speaker'}
                  eventType={event?.eventType}
                  chapter={
                    event?.local_chapter_normalized_name
                      ? event?.local_chapter_normalized_name
                      : event.local_chapter_name
                  }
                  handleRefundStatus={handleRefundStatus}
                  eventInfo={event?.event_info}
                />
              ))} */}
            </div>
          </Col>
        </Row>
      </SectionLayout>
    </React.Fragment>
  );
};

export default MyEventListingV2;

import React, {useEffect, useState} from 'react';
import KeywordFormComponent from '../../../../Components/AdminComponents/Keywords/KeywordsForm';
import {useDispatch, useSelector} from 'react-redux';
import {
  addKeyword,
  fetchKeywordById,
  updateKeywordById,
} from '../../../../Store/Actions';
import {useNavigate} from 'react-router-dom';

const KeywordFormContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {fetchKeywordData} = useSelector(({keywords}) => keywords);
  const [editData, setEditData] = useState();

  useEffect(() => {
    if (fetchKeywordData) {
      setEditData(fetchKeywordData?.data);
    }
  }, [fetchKeywordData]);

  const postKeyword = async (Data) => {
    dispatch(addKeyword(Data, navigate));
  };

  const updateKeyword = async (Data, id) => {
    dispatch(updateKeywordById(Data, id, navigate));
  };

  const fetchKeyword = async (id) => {
    dispatch(fetchKeywordById(id));
  };
  return (
    <div>
      <KeywordFormComponent
        postKeyword={(Data) => {
          postKeyword(Data);
        }}
        updateKeyword={(id, Data) => {
          updateKeyword(id, Data);
        }}
        fetchKeyword={(id) => {
          fetchKeyword(id);
        }}
        EditData={editData}
      />
    </div>
  );
};

export default KeywordFormContainer;

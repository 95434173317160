import React from 'react';
import CommiteeCard from './CommiteeCard';
import './style.css';
import {ApplicationString} from '../../../Localization/Locales/en';
import {
  CommitteeDataForPBSSORGAdvisors,
  CommitteeDataForPBSSORGOperationTeam,
  CommitteeDataFromBostonPBSSORG,
  CommitteeDataFromKoreaPBSSORG,
  CommitteeDataFromPBSSORG,
  CommitteeDataFromSanDiegoPBSSORG,
  CommitteeDataFromSfBayPBSSORG,
  CommitteeDataFromVancouverPBSSORG,
} from './committe';

const StandingCommiteeListing = () => {
  return (
    <React.Fragment>
      <div className='Commitee-listing-main'>
        <div className='Commitee-listing-heading'>
          <p>{ApplicationString['userAboutUsComponent.standingCommittee']}</p>
          <span className='sub-heading-standing-committee'>
            {
              ApplicationString[
                'userAboutUsComponent.standingCommittee.subText'
              ]
            }
          </span>
        </div>

        <div className='Commitee-listing-container'>
          {CommitteeDataFromPBSSORG.map((CommiteeItem) => {
            return <CommiteeCard key={CommiteeItem.name} data={CommiteeItem} />;
          })}
        </div>

        {/* sf-bay listing */}
        <div className='Commitee-listing-sub-heading'>
          <p>
            {ApplicationString['userAboutUsComponent.standingCommittee.sf-bay']}
          </p>
          <span className='sub-heading-standing-committee'>
            {
              ApplicationString[
                'userAboutUsComponent.standingCommittee.subText'
              ]
            }
          </span>
        </div>
        <div className='Commitee-listing-container'>
          {CommitteeDataFromSfBayPBSSORG.map((CommiteeItem) => {
            return <CommiteeCard key={CommiteeItem.name} data={CommiteeItem} />;
          })}
        </div>

        {/* san-diego listing */}
        <div className='Commitee-listing-sub-heading'>
          <p>
            {
              ApplicationString[
                'userAboutUsComponent.standingCommittee.san-diego'
              ]
            }
          </p>
          <span className='sub-heading-standing-committee'>
            {
              ApplicationString[
                'userAboutUsComponent.standingCommittee.subText'
              ]
            }
          </span>
        </div>
        <div className='Commitee-listing-container'>
          {CommitteeDataFromSanDiegoPBSSORG.map((CommiteeItem) => {
            return <CommiteeCard key={CommiteeItem.name} data={CommiteeItem} />;
          })}
        </div>

        {/* boston listing */}
        <div className='Commitee-listing-sub-heading'>
          <p>
            {ApplicationString['userAboutUsComponent.standingCommittee.boston']}
          </p>
          <span className='sub-heading-standing-committee'>
            {
              ApplicationString[
                'userAboutUsComponent.standingCommittee.subText'
              ]
            }
          </span>
        </div>
        <div className='Commitee-listing-container'>
          {CommitteeDataFromBostonPBSSORG.map((CommiteeItem) => {
            return <CommiteeCard key={CommiteeItem.name} data={CommiteeItem} />;
          })}
        </div>

        {/* vancouver listing */}
        <div className='Commitee-listing-sub-heading'>
          <p>
            {
              ApplicationString[
                'userAboutUsComponent.standingCommittee.vancouver'
              ]
            }
          </p>
          <span className='sub-heading-standing-committee'>
            {
              ApplicationString[
                'userAboutUsComponent.standingCommittee.subText'
              ]
            }
          </span>
        </div>
        <div className='Commitee-listing-container'>
          {CommitteeDataFromVancouverPBSSORG.map((CommiteeItem) => {
            return <CommiteeCard key={CommiteeItem.name} data={CommiteeItem} />;
          })}
        </div>

        {/* korea listing */}
        <div className='Commitee-listing-sub-heading'>
          <p>
            {ApplicationString['userAboutUsComponent.standingCommittee.korea']}
          </p>
          {/* <span className='sub-heading-standing-committee'>
            {
              ApplicationString[
                'userAboutUsComponent.standingCommittee.subText'
              ]
            }
          </span> */}
        </div>
        <div className='Commitee-listing-container'>
          {CommitteeDataFromKoreaPBSSORG.map((CommiteeItem) => {
            return (
              <CommiteeCard
                key={CommiteeItem.name}
                data={CommiteeItem}
                allowReadMore={false}
              />
            );
          })}
        </div>

        {/* distinguished advisor listing */}
        <div className='Commitee-listing-sub-heading'>
          <p>
            {
              ApplicationString[
                'userAboutUsComponent.standingCommittee.advisors'
              ]
            }
          </p>
          <span className='sub-heading-standing-committee'>
            {
              ApplicationString[
                'userAboutUsComponent.standingCommittee.subText'
              ]
            }
          </span>
        </div>
        <div className='Commitee-listing-container-advisors'>
          {CommitteeDataForPBSSORGAdvisors.map((CommiteeItem) => {
            return <CommiteeCard key={CommiteeItem.name} data={CommiteeItem} />;
          })}
        </div>

        {/* Operation Team listing */}
        <div className='Commitee-listing-sub-heading'>
          <p>
            {
              ApplicationString[
                'userAboutUsComponent.standingCommittee.operationTeam'
              ]
            }
          </p>
          <span className='sub-heading-standing-committee'>
            {
              ApplicationString[
                'userAboutUsComponent.standingCommittee.subText'
              ]
            }
          </span>
        </div>
        <div className='Commitee-listing-container-advisors'>
          {CommitteeDataForPBSSORGOperationTeam.map((CommiteeItem) => {
            return <CommiteeCard key={CommiteeItem.name} data={CommiteeItem} />;
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default StandingCommiteeListing;

import React from 'react';
import './style.css';

const CommonPageHeroText = ({heading, subheading}) => {
  return (
    <div className='pbssMainContainerRow'>
      <div className='hero-text-container-login'>
        <span className='heading-login'>{heading}</span>
        <span className='subheading-login'>{`${subheading}`}</span>
      </div>
    </div>
  );
};

export default CommonPageHeroText;

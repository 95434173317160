import React, {useState} from 'react';
import './style.css';
import {ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {
  formatCurrency,
  formatDate,
  formatTimeZoneString,
} from '../../../../Util/commonUtility';
import {Col, Row} from 'antd';
import ClockTimeSVG from '../../../../Assets/svgComponents/ClockTimeSVG';

const ChapterEventCard = ({
  eventType,
  eventDetails,
  dateObj,
  location,
  setClickedEventID,
  sponsorshipAvailable,
  eventName,
  date,
  chapter,
  speakers,
  registerText,
  price,
  descriptionLabel,
  moreDetails,
  isExpand,
  setIsRegistrationModalClosed,
  usedForChapPage = false,
  id,
  status,
}) => {
  const [expanded, setExpanded] = useState(isExpand);
  const Navigate = useNavigate();

  const toggleDescription = () => {
    setExpanded(!expanded);
  };

  return (
    <React.Fragment>
      <div className='event-new-card-container'>
        <div
          className='upcoming-event-card'
          id={`event-new-card-container-${id}`}
          onClick={() => {
            Navigate(`/eventDetails/${id}`);
          }}>
          <div className='upcoming-event-card-content-wrapper'>
            <div
              className={`cardLeftGroup ${
                usedForChapPage && 'cardLeftGroupForChapterpage'
              }`}>
              <div className='timeLocationDetails'>
                <div className='locationDateDetails'>
                  <span className='locationText'>{chapter}</span>
                  <div className='extraDetails-desktop'>
                    <span id={`home-event-extra-details-button-${id}`}>
                      {eventType}
                    </span>
                  </div>
                  <span className='dateText'>{dateObj?.event_start_date} </span>
                </div>
                <div className='timeExtraDetails'>
                  <span className='timeText'>
                    <ClockTimeSVG />
                    {`${dateObj?.event_start_time} - ${
                      dateObj?.event_end_time
                    } -
                    ${formatTimeZoneString(dateObj?.time_zone)}`}
                  </span>
                  <div className='extraDetails-mobile'>
                    <span>{eventType}</span>
                  </div>
                </div>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  Navigate(`/eventDetails/${id}`);
                }}
                className='upcoming-event-card-title hyperlink'>
                {eventName}
              </div>
            </div>
            <div
              className={`cardRightGroup borderLeft ${
                usedForChapPage && 'cardRightGroupChapter'
              }`}>
              <button
                id={`home-register-to-event-button-${id}`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (Number(status) === 2) {
                    setIsRegistrationModalClosed(true);
                    setClickedEventID(id);
                  } else {
                    Navigate(`/addAttendees/${id}`);
                  }
                }}
                className={`registerButton ${
                  usedForChapPage && 'registerButtonUsedForChapPage'
                } `}>
                {' '}
                {registerText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChapterEventCard;

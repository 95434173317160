import React from 'react';
import {
  // formatDate,
  getStatusInfo,
  truncateHtmlToWords,
} from '../../../../../Util/commonUtility';
import './style.css';
import {CalendarOutlined} from '@ant-design/icons';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {Badge, Button} from 'antd';
import JobsFormComponent from '../../JobsForm';
// import draftSvg from '../../../../../Assets/draft.svg';
// import inReviewSvg from '../../../../../Assets/in_review-v2.svg';
// import rejectedSvg from '../../../../../Assets/rejected-v2.svg';
// import approvedSvg from '../../../../../Assets/published-v2.svg';
// import expiredSvg from '../../../../../Assets/expired-v2.svg';
import {ApplicationString} from '../../../../../Localization/Locales/en';
import view_more_svg from '../../../../../Assets/view-more-right-arrow.svg';

const CommonJobsListingCard = (props) => {
  const {
    jobLocation,
    jobLastDate,
    jobEmployer,
    jobTitle,
    jobDescription,
    jobId,
    jobStatus,
    jobFeesOptions,
    jobFees,
  } = props;
  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const viewDetailsJob = () => {
    navigate(`/jobs/${jobId}`);
  };

  // const getColorStatus = (jobStatus) => {
  //   let svg;
  //   let className;
  //   let normalizeName;
  //   switch (jobStatus) {
  //     case 'draft':
  //       svg = draftSvg;
  //       className = 'ads-listing-card-status-text draft';
  //       normalizeName = 'Draft';
  //       break;
  //     case 'in_review':
  //       svg = inReviewSvg;
  //       className =
  //         'ads-listing-card-status-text ads-listing-card-status-in_review';
  //       normalizeName = 'Under Review';
  //       break;
  //     case 'rejected':
  //       svg = rejectedSvg;
  //       className =
  //         'ads-listing-card-status-text ads-listing-card-status-rejected';
  //       normalizeName = 'Rejected';
  //       break;
  //     case 'approved':
  //       svg = approvedSvg;
  //       className =
  //         'ads-listing-card-status-text ads-listing-card-status-approved';
  //       normalizeName = 'Published';
  //       break;
  //     case 'expired':
  //       svg = expiredSvg;
  //       className =
  //         'ads-listing-card-status-text ads-listing-card-status-expired';
  //       normalizeName = 'Expired';
  //   }
  //   return {svg, className, normalizeName};
  // };

  return (
    <React.Fragment>
      <Badge.Ribbon
        className='common-badge-ribbon'
        color={getStatusInfo(jobStatus).color}
        text={getStatusInfo(jobStatus).normalizeName}>
        <div className='common-job-listing-card-container'>
          <div className='common-job-listing-card'>
            <div className='common-job-listing-card-content-wrapper'>
              <div className='common-job-listing-locationDateDetails'>
                <div>
                  {' '}
                  <span className='common-job-listing-locationText'>
                    {jobLocation ? jobLocation : 'N/A'}
                  </span>
                </div>

                <div>
                  <span className='common-job-listing-dateText'>
                    <span className='dateText-text'>Last Date |</span>
                    {`   `}
                    <span className='dateText-date'>
                      <CalendarOutlined />
                      {` `}
                      {jobLastDate
                        ? `${moment(jobLastDate)
                            .utcOffset(0)
                            .format('DD MMM YYYY')}`
                        : 'N/A'}
                    </span>
                  </span>
                </div>
                {/* <div className='common-ads-listing-card-status-container'>
                <div className='common-ads-listing-card-status'>
                  <img
                    src={getColorStatus(jobStatus)?.svg}
                    alt='ads-status-svg'
                    className='ads-listing-card-statusImg'
                  />
                  <span className={getColorStatus(jobStatus)?.className}>
                    {getColorStatus(jobStatus)?.normalizeName}
                  </span>
                </div>
              </div> */}
              </div>
              <div className='common-job-left-right-group-wrapper'>
                <div className='common-job-listing-cardLeftGroup '>
                  <div
                    className='common-job-listing-card-titleEmployerDetails hyperlink'
                    onClick={viewDetailsJob}>
                    <span className='titleEmployerDetails-employer'>
                      {jobEmployer}
                    </span>
                    <span className='titleEmployerDetails-title'>{`(${jobTitle})`}</span>
                  </div>
                  <div className='common-job-listing-card-description'>
                    {truncateHtmlToWords(jobDescription, 25)}
                  </div>
                  <div className='common-ads-listing-card-button-contianer'></div>
                  {
                    <JobsFormComponent
                      isModalOpen={editModalOpen}
                      setIsModalOpen={setEditModalOpen}
                      editData={props}
                      jobFeesOptions={jobFeesOptions}
                    />
                  }
                </div>
                <div className='common-job-listing-cardRightGroup'>
                  <div className='common-job-listing-card-action-container'>
                    {jobStatus === 'draft' && jobFees > 0 && (
                      <Button
                        className='common-ads-listing-card-button'
                        onClick={() => navigate(`cart/${jobId}`)}>
                        <span className='link-no-style'>Pay</span>
                      </Button>
                    )}
                    {jobStatus === 'draft' && (
                      <Button
                        className='common-ads-listing-card-button'
                        onClick={() => setEditModalOpen(!editModalOpen)}>
                        Edit Job
                      </Button>
                    )}
                  </div>

                  <Button
                    className='view-more-svg-button'
                    id={`common-job-listing-viewDetailsButton_${jobId}`}
                    onClick={viewDetailsJob}>
                    {
                      ApplicationString[
                        'userApprovedJobsListingComponent.button.viewDetails'
                      ]
                    }
                    <img
                      src={view_more_svg}
                      alt='view_more_svg'
                      className='view-more-svg'
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Badge.Ribbon>
    </React.Fragment>
  );
};

export default CommonJobsListingCard;

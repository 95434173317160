const Urls = {
  //AUTH
  ACCOUNT_LOGIN: 'auth/login',
  ACCOUNT_REGISTER: 'auth/register',
  ACCOUNT_RESET_SEND_MAIL: 'auth/resetPassword',
  ACCOUNT_RESET_UPDATE_PASSWORD: 'auth/updatePassword',
  ACCOUNT_VERIFY_RESET_TOKEN: 'auth/validateToken',
  ACCOUNT_REFRESH_TOKEN: 'auth/refreshToken',
  ACCOUNT_UNSUBSCRIBE_VERIFY_TOKEN: 'auth/validateUnsubscribe',
  ACCOUNT_UNSUBSCRIBE_USER: 'auth/unsubscribe',

  SPONSOR_ATTENDEES_GET_ALL: `eventRegistration/showByUser/#{attendeeId}`,
  SPONSOR_ATTENDEES_ADD: `eventRegistration/sponsorship/asset`,
  SPONSOR_UPDATE: `eventRegistration/sponsorship/asset/#{sponsorId}`,
  SPONSOR_ATTENDEES_DELETE: `eventRegistration/sponsorship/asset/#{attendeeId}`,
  SPONSOR_REMOVE_FROM_CART: `eventRegistration/sponsorship/cart/#{eventId}`,
  SPONSOR_ATTENDEES_UPDATE: `eventRegistration/#{attendeeId}`,

  //USERS
  USER_GET_ALL_USERS: 'users',
  USER_ADD: 'users',
  USER_DELETE_USER: 'users/#{userId}',
  USER_UPDATE_USER_PROFILE: 'users/#{userId}',
  USER_FETCH_BY_ID: 'users/#{userId}',
  USER_UPDATE_BY_ID: 'users',
  USER_UPDATE_BY_ADMIN_ID: 'users/#{userId}',
  USER_DELETE_BY_ID: 'users/#{userId}',
  USER_DETAILS_BY_ID: 'users/details',
  USER_UPDATE_STATUS_BY_ID: 'users/#{userId}/#{userStatus}',

  //CHAPTERS
  CHAPTER_GET_ALL: 'chapters',
  CHAPTER_ADD: 'chapters',
  CHAPTER_FETCH_BY_ID: 'chapters/#{chapterId}',
  CHAPTER_UPDATE_BY_ID: 'chapters/#{chapterId}',
  CHAPTER_DELETE_BY_ID: 'chapters/#{chapterId}',

  //KEYWORDS
  KEYWORD_GET_ALL: 'keywords',
  KEYWORD_ADD: 'keywords',
  KEYWORD_FETCH_BY_ID: 'keywords/#{keywordId}',
  KEYWORD_UPDATE_BY_ID: 'keywords/#{keywordId}',
  KEYWORD_DELETE_BY_ID: 'keywords/#{keywordId}',

  //ADS
  AD_GET_ALL: 'campaigns',
  AD_ADD: 'campaigns',
  ADS_GET_ALL_APPROVED: 'campaigns/approved',
  ADS_GET_ALL_ACCOUNTS: 'campaigns/account',
  AD_FETCH_BY_ID: 'campaigns/#{adsId}',
  AD_UPDATE_BY_ID: 'campaigns/#{adsId}',
  AD_UPDATE_STATUS_BY_ID: 'campaigns/#{adsId}/#{adsStatus}',
  AD_DELETE_BY_ID: 'campaigns/#{adsId}',
  ADS_GET_BY_USERID: 'campaigns/userId/#{userId}',
  ADS_POST_PAYMENT: 'campaigns/adPayment',
  ADS_POST_PAYMENT_SUCCESS: 'campaigns/adPaymentConfirm',

  //EVENT TEMPLATES SCHEDULER

  EVENT_TEMPLATE_SCHEDULER_GET_ALL: 'eventEmailTemplate',
  EVENT_TEMPLATE_SCHEDULER_ADD: 'eventEmailTemplate',
  EVENT_TEMPLATE_SCHEDULER_FETCH_BY_EVENT_ID:
    'eventEmailTemplate/event/#{eventEmailTemplateId}',
  EVENT_TEMPLATE_SCHEDULER_FETCH_BY_ID:
    'eventEmailTemplate/#{eventEmailTemplateId}',
  EVENT_TEMPLATE_SCHEDULER_UPDATE_BY_ID:
    'eventEmailTemplate/#{eventTemplateSchedulerId}',
  // EVENT_TEMPLATE_SCHEDULER_UPDATE_STATUS_BY_ID: 'eventtemplatescheduler/#{eventTemplateSchedulerId}/#{eventTemplateSchedulerStatus}',
  EVENT_TEMPLATE_SCHEDULER_DELETE_BY_ID:
    'eventEmailTemplate/#{eventTemplateSchedulerId}',
  EVENT_TEMPLATE_SCHEDULERS_GET_BY_USERID:
    'eventEmailTemplate/userId/#{userId}',
  EVENT_TEMPLATE_SCHEDULER_SEND_DIRECT_EMAIL_BY_ID:
    'eventEmailTemplate/sendDirectEmail/#{eventTemplateSchedulerId}',

  //EMAILCODE
  EMAILCODE_GET_ALL: 'emailCodes',
  EMAILCODE_ADD: 'emailCodes',
  EMAILCODE_FETCH_BY_ID: 'emailCodes/#{emailCodeId}',
  EMAILCODE_UPDATE_BY_ID: 'emailCodes/#{emailCodeId}',
  EMAILCODE_DELETE_BY_ID: 'emailCodes/#{emailCodeId}',

  //EVENT TYPES
  EVENTTYPES_GET_ALL: 'eventTypes',
  EVENTTYPES_ADD: 'eventTypes',
  EVENTTYPES_FETCH_BY_ID: 'eventTypes/#{eventTypesId}',
  EVENTTYPES_UPDATE_BY_ID: 'eventTypes/#{eventTypesId}',
  EVENTTYPES_DELETE_BY_ID: 'eventTypes/#{eventTypesId}',

  //SPONSORS

  SPONSOR_GET_ALL: 'sponsors',
  SPONSOR_ADD: 'sponsors',
  SPONSOR_FETCH_BY_ID: 'sponsors/#{sponsorsId}',
  SPONSOR_UPDATE_BY_ID: 'sponsors/#{sponsorsId}',
  SPONSOR_DELETE_BY_ID: 'sponsors/#{sponsorsId}',

  //JOBFEES
  JOBFEES_GET_ALL: 'jobFees',
  JOBFEES_ADD: 'jobFees',
  JOBFEES_GET_BY_ID: 'jobFees/#{jobFeesId}',
  JOBFEES_UPDATE_BY_ID: 'jobFees/#{jobFeesId}',
  JOBFEES_DELETE_BY_ID: 'jobFees/#{jobFeesId}',

  //ADFEES
  ADFEES_GET_ALL: 'adFees',
  ADFEES_ADD: 'adFees',
  ADFEES_GET_BY_ID: 'adFees/#{adFeesId}',
  ADFEES_UPDATE_BY_ID: 'adFees/#{adFeesId}',
  ADFEES_DELETE_BY_ID: 'adFees/#{adFeesId}',

  //JOBS
  JOBS_GET_ALL: 'jobs',
  JOBS_GET_ALL_APPROVED: 'jobs/approved',
  JOBS_ADD: 'jobs',
  JOBS_GET_BY_ID: 'jobs/#{jobsId}',
  JOBS_UPDATE_BY_ID: 'jobs/#{jobsId}',
  JOBS_DELETE_BY_ID: 'jobs/#{jobsId}',
  JOBS_UPDATE_STATUS_BY_ID: 'jobs/#{jobsId}/#{jobStatus}',
  JOBS_GET_BY_USERID: 'jobs/userId/#{userId}',
  JOBS_GET_ALL_ACCOUNTS: 'jobs/account',
  JOBS_POST_PAYMENT: 'jobs/jobPayment',
  JOBS_POST_PAYMENT_SUCCESS: '/jobs/jobPaymentConfirm',

  //SPEAKERS
  SPEAKERS_GET_ALL: 'speakers',
  SPEAKERS_ADD: 'speakers',
  SPEAKERS_GET_BY_ID: 'speakers/#{speakersId}',
  SPEAKERS_UPDATE_BY_ID: 'speakers/#{speakersId}',
  SPEAKERS_DELETE_BY_ID: 'speakers/#{speakersId}',

  //COMPANIES
  COMPANIES_GET_ALL: 'companies',
  COMPANIES_ADD: 'companies',
  COMPANIES_GET_BY_ID: 'companies/#{companiesId}',
  COMPANIES_UPDATE_BY_ID: 'companies/#{companiesId}',
  COMPANIES_DELETE_BY_ID: 'companies/#{companiesId}',

  //COUPONCODES
  COUPONCODES_GET_ALL: 'couponCodes',
  COUPONCODES_ADD: 'couponCodes',
  COUPONCODES_GET_BY_ID: 'couponCodes/#{couponCodesId}',
  COUPONCODES_UPDATE_BY_ID: 'couponCodes/#{couponCodesId}',
  COUPONCODES_DELETE_BY_ID: 'couponCodes/#{couponCodesId}',

  //EVENTOPTIONGROUPS
  EVENTOPTIONGROUPS_GET_ALL: 'eventOptionGroups',
  EVENTOPTIONGROUPS_ADD: 'eventOptionGroups',
  EVENTOPTIONGROUPS_GET_BY_ID: 'eventOptionGroups/#{eventOptionGroupsId}',
  EVENTOPTIONGROUPS_UPDATE_BY_ID: 'eventOptionGroups/#{eventOptionGroupsId}',
  EVENTOPTIONGROUPS_DELETE_BY_ID: 'eventOptionGroups/#{eventOptionGroupsId}',

  //EVENTOPTIONS
  EVENTOPTIONS_GET_ALL: 'eventOptions',
  EVENTOPTIONS_ADD: 'eventOptions',
  EVENTOPTIONS_GET_BY_ID: 'eventOptions/#{eventOptionsId}',
  EVENTOPTIONS_UPDATE_BY_ID: 'eventOptions/#{eventOptionsId}',
  EVENTOPTIONS_DELETE_BY_ID: 'eventOptions/#{eventOptionsId}',
  EVENTOPTIONS_GET_BY_EVENTID: 'eventOptions/event/#{eventId}',
  EVENTOPTIONS_ADMIN_GET_BY_EVENTID: 'eventOptions/list/#{eventId}',

  //EVENTS
  EVENTS_GET_ALL: 'events',
  PAST_EVENTS_GET_ALL: 'events/pastEvents',
  EVENTS_USER_GET_ALL: 'events/chapter',
  EVENTS_GET_BY_CHAPTERS: 'events/chapter',
  EVENTS_ADD: 'events',
  EVENTS_GET_BY_ID: 'events/#{eventsId}',
  EVENTS_UPDATE_BY_ID: 'events/#{eventsId}',
  EVENTS_DELETE_BY_ID: 'events/#{eventsId}',
  EVENTS_DUPLICATE_BY_ID: 'events/duplicate/#{eventsId}',
  EVENTS_UPDATE_STATUS_BY_ID: 'events/#{eventsId}/#{eventsStatus}',
  EVENTS_UPDATE_REFUND_STATUS_BY_ID: 'eventRegistration/refund',

  //EVENT REGISTERED
  GET_ALL_REGISTRATION: 'eventRegistration',
  GET_REGISTRATIONS_BY_EVENTID: 'eventRegistration/event',
  UPDATE_REGISTRATIONS_OPTIONS_BY_EVENTID: 'eventRegistration/editOption',
  CANCEL_GROUP_OF_EVENT_REGISTRATIONS_BY_EVENTID:
    'eventRegistration/cancelEventRegistration',
  ADD_ATTENDEE: 'eventRegistration',
  GET_ALL_ATTENDEE: 'eventRegistration/showByUser/#{eventId}/#{attendeeType}',
  GET_ALL_ATTENDEE_TRANSACTION_ID: 'eventRegistration/#{transactionId}',
  DELETE_ATTENDEE: 'eventRegistration/#{eventRegistrationId}',
  UPDATE_ATTENDEE: 'eventRegistration/#{eventRegistrationId}',
  COUPONCODES_VERIFY: 'eventRegistration/verifyCoupon/#{couponCode}/#{eventId}',
  COUPONCODES_APPLY: 'eventRegistration/applyCoupon',
  PAYMENT_UPDATE_BY_EVENT_ID: `eventRegistration/payment/#{eventId}/sponsor`,
  PAYMENT_UPDATE_NORMAL_REGISTRATION_BY_EVENT_ID: `eventRegistration/payment/#{eventId}/normal`,
  EVENTS_GET_ALL_MYEVENTS: 'eventRegistration/myEvents',
  EVENT_POST_PAYMENT_SUCCESS: 'eventRegistration/paymentConfirmation',
  //EVENT REFUND
  GET_REFUND_BY_EVENTID: 'eventRegistration/refund/event/#{eventId}',
  UPDATE_REFUND_STATUS: 'eventRefund/#{eventStatus}',

  // EMAIL TO MEMBERS
  EMAIL_TO_MEMBERS: 'emails',

  //TEMPLATE PHOTOS
  TEMPLATE_PHOTOS_GET: 'templatePhotos',
  TEMPLATE_PHOTOS_POST: 'templatePhotos',

  // SPECIAL PAYMENT
  SPECIAL_PAYMENT: '/specialPayment',
  SPECIAL_PAYMENT_SECRET: '/specialPayment/secret',
  SPECIAL_PAYMENT_SUCCESS: 'specialPayment/confirm',

  //CONTACT US FORM
  CONTACT_US: '/emails/contact',

  //STEM
  STEM_GET: 'stem',
  STEM_UPDATE_BY_ID: 'stem/#{stemId}',
};
export default Urls;

import {Divider, Row} from 'antd';
import AppInfoView from '../../../CommonComponents/AppInfoView/appInfoView';
import LoginForm from '../../../CommonComponents/LoginForm';
import SectionLayout from '../../../CommonComponents/Layout/sectionLayout';
import FormTitle from '../../../CommonComponents/FormHeading';
const SignInComponent = () => {
  return (
    <>
      <Row justify='center' align='middle' style={{minHeight: '80vh'}}>
        <SectionLayout>
          <FormTitle
            title='Admin Login'
            description='Please log in to Admin account'
            additionalLabel='Login required For Admin Panel'
          />
          <Divider />
          <LoginForm />
        </SectionLayout>
      </Row>
    </>
  );
};

export default SignInComponent;

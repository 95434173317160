import {CaretDownOutlined} from '@ant-design/icons';
import {Checkbox, Form, Input, Select} from 'antd';
import {Option} from 'antd/es/mentions';
import {
  OnlyAlphabetInputValidatorWithSpecialCharacters,
  uniqueSponsorPerksValueEnum,
  validateCompanyName,
} from '../../../Util/commonUtility';

const SponsorEditableCell = ({
  editing,
  dataIndex,
  title,
  eventType,
  record,
  index,
  children,
  allEventOptionsFoodData,
  attendeesData = [],
  handleAttendeePresentationChange,
  checkedAttendeePresentation,
  handleAttendeeDinnerChange,
  checkedAttendeeDinner,
  SponsorPerks,
  sponsorOption,
  transactionId,
  ...restProps
}) => {
  let inputElement;
  const AttendeePresentationLimit = SponsorPerks?.workshop_attendees;
  const AttendeeDinnerLimit = SponsorPerks?.dinner_with_speaker;
  const allAttendeePresentationData = attendeesData?.filter(
    (obj) => obj.attending_presentation,
  );
  const allAttendeeDinnerData = attendeesData?.filter(
    (obj) => obj.attending_dinner,
  );
  const disablePresentationCheckboxValue =
    allAttendeePresentationData?.length >= AttendeePresentationLimit;
  const disablePresentationCheckbox = record?.attending_presentation
    ? false
    : disablePresentationCheckboxValue;
  const disableDinnerCheckboxValue =
    allAttendeeDinnerData?.length >= AttendeeDinnerLimit;
  const disableDinnerCheckbox = record?.attending_dinner
    ? false
    : disableDinnerCheckboxValue;

  const lunchLimit = SponsorPerks?.free_lunch;

  const disableLunchOptions = (foodOption) => {
    let disabledValue;
    const totalRemainingQuantity = foodOption?.qty - foodOption?.used_qty;
    const checkIsSponsorOption =
      typeof foodOption?.value === 'string' &&
      foodOption?.value?.includes(uniqueSponsorPerksValueEnum);

    const allAttendeeFreeFilterData = attendeesData?.filter(
      (obj) =>
        obj?.event_registration_options?.food?.price ===
        foodOption?.discounted_price,
    );
    const allAttendeeFilterData = attendeesData?.filter(
      (obj) =>
        obj?.event_registration_options?.food?.event_option_id ===
        foodOption.id,
    );
    if (checkIsSponsorOption) {
      disabledValue =
        totalRemainingQuantity === 0 ||
        allAttendeeFreeFilterData?.length >= lunchLimit ||
        allAttendeeFilterData?.length >= totalRemainingQuantity;
    } else {
      disabledValue =
        totalRemainingQuantity === 0 ||
        allAttendeeFilterData?.length >= totalRemainingQuantity;
    }
    return disabledValue;
  };

  const uniqueEmailValidator = (value, record) => {
    if (record?.attendee_email !== value && attendeesData?.length > 0) {
      const sameEmail = attendeesData?.find(
        (obj) => obj?.attendee_email === value,
      );
      if (sameEmail) {
        return Promise.reject(
          new Error(`${value} already exists as attendee email address!`),
        );
      }
    }
    return Promise.resolve();
  };

  switch (dataIndex) {
    case 'name':
      inputElement = (
        <div className='field-separation'>
          <Form.Item
            name='attendee_first_name'
            className='no-margin'
            rules={[
              {
                required: true,
                message: 'Please fill out first name!',
              },
              {
                validator: (_, value) =>
                  OnlyAlphabetInputValidatorWithSpecialCharacters(
                    value,
                    'First name',
                  ),
              },
            ]}>
            <Input placeholder='Enter attendee first name' />
          </Form.Item>
          <Form.Item
            name='attendee_last_name'
            className='no-margin'
            rules={[
              {
                required: true,
                message: 'Please fill out last name!',
              },
              {
                validator: (_, value) =>
                  OnlyAlphabetInputValidatorWithSpecialCharacters(
                    value,
                    'Last name',
                  ),
              },
            ]}>
            <Input placeholder='Enter attendee last name' />
          </Form.Item>
        </div>
      );
      break;
    case 'attendee_email':
      inputElement = (
        <div className='field-separation'>
          <Form.Item
            name={dataIndex}
            className='no-margin'
            rules={[
              {
                required: true,
                message: `Please fill out ${title?.toLowerCase()}!`,
              },
              {
                type: 'email',
                message: 'Please enter a valid email address',
              },
              {
                validator: (_, value) => uniqueEmailValidator(value, record),
              },
            ]}>
            <Input
              onCopy={(e) => e.preventDefault()} // Disable copy
              placeholder={`${`Enter attendee ${title?.toLowerCase()}`}`}
            />
          </Form.Item>
          <Form.Item
            name={`${dataIndex}_confirm`}
            className='no-margin'
            rules={[
              {
                required: true,
                message: `Please confirm attendee ${title?.toLowerCase()}!`,
              },
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (
                    !value ||
                    getFieldValue(dataIndex)?.toLowerCase() ===
                      value?.toLowerCase()
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Email IDs must match'));
                },
              }),
            ]}>
            <Input
              placeholder={`${`Confirm attendee ${title?.toLowerCase()}`}`}
            />
          </Form.Item>
        </div>
      );
      break;
    case 'attendee_company':
      inputElement = (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `Please fill out ${title?.toLowerCase()}!`,
            },
            {
              validator: (_, value) => validateCompanyName(value, title),
            },
          ]}
          style={{margin: 0}}>
          <Input placeholder={`Enter attendee ${title?.toLowerCase()}`} />
        </Form.Item>
      );
      break;
    case 'option':
      inputElement = (
        <div className='field-separation'>
          {
            (inputElement = eventType !== 'Webcast' && (
              <Form.Item
                name='food_option'
                className='no-margin'
                rules={[
                  {
                    required: true,
                    message: `Please select attendee lunch option!`,
                  },
                ]}>
                <Select
                  showSearch
                  allowClear
                  disabled={
                    transactionId ? (record.food_option ? false : true) : false
                  }
                  placeholder='Select attendee lunch option'
                  className='editable-options'
                  suffixIcon={<CaretDownOutlined />}>
                  {allEventOptionsFoodData?.map((foodOption) => (
                    <Option
                      key={foodOption.label}
                      value={foodOption.value}
                      disabled={disableLunchOptions(foodOption)}>
                      {foodOption.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ))
          }
        </div>
      );
      break;
    case 'attending_presentation':
      inputElement = (
        <Form.Item name={dataIndex} style={{margin: 0}}>
          <div className='checkbox-editableCell'>
            <Checkbox
              onChange={handleAttendeePresentationChange}
              checked={checkedAttendeePresentation}
              disabled={disablePresentationCheckbox}></Checkbox>
          </div>
        </Form.Item>
      );
      break;
    case 'attending_dinner':
      inputElement = (
        <Form.Item name={dataIndex} style={{margin: 0}}>
          <div className='checkbox-editableCell'>
            <Checkbox
              onChange={handleAttendeeDinnerChange}
              checked={checkedAttendeeDinner}
              disabled={disableDinnerCheckbox}></Checkbox>
          </div>
        </Form.Item>
      );
      break;
    default:
      inputElement = (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `Please fill out ${title?.toLowerCase()}!`,
            },
            {
              validator: (_, value) =>
                OnlyAlphabetInputValidatorWithSpecialCharacters(value, title),
            },
          ]}
          style={{margin: 0}}>
          <Input placeholder={`${`Enter attendee ${title?.toLowerCase()}`}`} />
        </Form.Item>
      );
  }

  return (
    <td {...restProps}>{editing ? <div>{inputElement}</div> : children}</td>
  );
};
export default SponsorEditableCell;

import React, {useEffect} from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Row,
  Space,
} from 'antd';
import './updatePassword.css';
import {logo} from '../../../../../Resource/images';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {
  updatePassword,
  verifyResetPasswordToken,
} from '../../../../../Store/Actions';
import AppInfoView from '../../../../CommonComponents/AppInfoView/appInfoView';
import {useSearchParams} from 'react-router-dom';
import {UserPasswordValidator} from '../../../../../Util/commonUtility';
import SectionLayout from './../../../../CommonComponents/Layout/sectionLayout';
import CommonPageHeroText from './../../../../CommonComponents/CommonPageHeroText/index';
import {ApplicationString} from './../../../../../Localization/Locales/en';
import UpdatePasswordForm from './UpdatePassForm/index';
const ResetUpdatePassComponent = () => {
  return (
    <React.Fragment>
      <CommonPageHeroText
        heading={ApplicationString['userResetPasswordComponent.heading']}
        subheading={ApplicationString['userResetPasswordComponent.subheading']}
      />
      <SectionLayout>
        <UpdatePasswordForm />
      </SectionLayout>
    </React.Fragment>
  );
};

export default ResetUpdatePassComponent;

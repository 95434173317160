import React, {useEffect, useState} from 'react';
import MyEventsCartComponent from '../../../Components/UserComponents/MyEventCart';
import {useDispatch, useSelector} from 'react-redux';
import {
  DefaultListingPageCount,
  LoadMoreSize,
  ToBePaidStatusFilterValue,
  formatMyEventsData,
} from '../../../Util/commonUtility';
import {getAllChapters} from '../../../Store/Actions';
import {getAllMyEventsCart} from '../../../Store/Actions/events';

const MyEventCartContainer = () => {
  const dispatch = useDispatch();
  const {allMyEventsCard} = useSelector(({events}) => events);
  const [eventCartData, setEventCartData] = useState([]);
  const [countData, setCountData] = useState(LoadMoreSize);
  const [formattedEventData, setFormattedEventData] = useState([]);
  const [userCredit, setUserCredit] = useState(0);
  const localChapterData = useSelector(
    (state) => state?.chapters?.localChapterData,
  );

  useEffect(() => {
    if (allMyEventsCard) {
      setEventCartData(allMyEventsCard);
    } else {
      dispatch(getAllMyEventsCart(ToBePaidStatusFilterValue, setUserCredit));
    }
  }, [allMyEventsCard]);

  useEffect(() => {
    if (!localChapterData) {
      dispatch(getAllChapters());
    }
  }, [localChapterData]);

  useEffect(() => {
    if (eventCartData) {
      setFormattedEventData(
        eventCartData?.data?.myEvents?.length > 0
          ? formatMyEventsData(eventCartData?.data?.myEvents)
          : null,
      );
    }
  }, [eventCartData]);

  const handleLoadMore = () => {
    setCountData(countData * 2);
  };

  return (
    <MyEventsCartComponent
      handleLoadMore={handleLoadMore}
      eventData={eventCartData}
      formattedEventData={formattedEventData}
      userCredit={userCredit}
      localChapterData={localChapterData}
      setUserCredit={setUserCredit}
    />
  );
};

export default MyEventCartContainer;

import {notification} from 'antd';

const NotificationService = {
  success(message, description) {
    notification.success({
      message,
      description,
      duration: 3,
    });
  },

  error(message, description) {
    notification.error({
      message,
      description,
      duration: 3,
    });
  },

  info(message, description) {
    notification.info({
      message,
      description,
    });
  },

  warning(message, description) {
    notification.warning({
      message,
      description,
    });
  },
};

export default NotificationService;

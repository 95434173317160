import {ApplicationString} from '../../../../Localization/Locales/en';
import './style.css';
import React from 'react';
const VendorPolicy = () => {
  return (
    <React.Fragment>
      {/* <div className='common-image-text'>
        <p>{ApplicationString['userSponsorPolicyComponent.p1']}</p>
        <p>{ApplicationString['userSponsorPolicyComponent.p2']}</p>
      </div> */}
      <div className='static-container'>
        <span>
          {
            ApplicationString[
              'userSponsorPolicyComponent.membershipListSharing'
            ]
          }
        </span>
        <div className='static-container-card'>
          <div className='card-body list-sharing-text'>
            {
              ApplicationString[
                'userSponsorPolicyComponent.membershipListSharing.description'
              ]
            }
          </div>
        </div>
        <div className='vendor-show-card-container'>
          <span>
            {
              ApplicationString[
                'userSponsorPolicyComponent.vendorShowCard.title'
              ]
            }
          </span>
          <ul>
            <div>
              <li>
                {
                  ApplicationString[
                    'userSponsorPolicyComponent.vendorShowCard.description'
                  ]
                }
              </li>
            </div>
          </ul>
        </div>
        <div className='vendor-show-card-container'>
          <span>
            {
              ApplicationString[
                'userSponsorPolicyComponent.vendorShowCard.title2'
              ]
            }
          </span>
          <ul>
            <div>
              <li>
                {
                  ApplicationString[
                    'userSponsorPolicyComponent.vendorShowCard.li1'
                  ]
                }
              </li>
            </div>
            <div>
              <li>
                {
                  ApplicationString[
                    'userSponsorPolicyComponent.vendorShowCard.li2'
                  ]
                }
              </li>
            </div>
            <div>
              <li>
                {
                  ApplicationString[
                    'userSponsorPolicyComponent.vendorShowCard.li3'
                  ]
                }
              </li>
            </div>
            <div>
              <li>
                {
                  ApplicationString[
                    'userSponsorPolicyComponent.vendorShowCard.li4'
                  ]
                }
              </li>
            </div>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VendorPolicy;

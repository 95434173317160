//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const FETCH_END = 'FETCH_END';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const UPDATING_CONTENT = 'updating_content';
export const LOGOUT = 'logout';
export const SOMETHING_WENT_WRONG = 'Something went wrong, Please try again!';
export const RETRY_LOGIN = 'Please login again!';

export const JWT_EXPIRED = 'JWT Expired';

export const INVALID_REFRESH_TOKEN_PROVIDE = 'Invalid refresh token provided!';
export const AUTH_TOKEN_NOT_PROVIDED = 'Authorization header not provided';
export const INVALID = 'Invalid username or password';
export const ERROR_REGISTRATION = 'error_registration';
export const SET_LOCAL_CHAPTER_DETAILS = 'set_local_chapter_details';
//USER
export const GET_ALL_USERS = 'get_all_users';
export const GET_ALL_USERS_WITHOUT_PAGINATION =
  'get_all_users_without_pagination';
export const ADD_USERS = 'post_users';
export const GET_ALL_USERS_BY_QUERY = 'get_all_users_by_query';
export const GET_ALL_USERS_BY_QUERY_SEARCH = 'get_all_users_by_query_search';
export const FETCH_USER_BY_ID = 'fetch_user_id';
export const FETCH_USER_BY_ID_FOR_UPDATE_PROFILE =
  'fetch_user_id_for_update_profile';
export const UPDATE_USER_BY_ID = 'update_user_id';
export const UPDATE_USERS_STATUS = 'update_users_status';
export const DELETE_USER_BY_ID = 'delete_user_id';
export const GET_USER_DETAILS = 'get_user_details';

// Your existing action types
export const ADD_SPONSOR_ATTENDEE_SUCCESS = 'add_attendees';
export const GET_ALL_SPONSOR_ATTENDEES = 'get_all_attendees';
export const DELETE_SPONSOR_ATTENDEE_BY_ID = 'delete_sponsor_attendee_by_id';
export const UPDATE_SPONSOR_ATTENDEE_BY_ID = 'update_sponsor_attendee';

//CHAPTER
export const GET_ALL_CHAPTERS = 'get_all_chapters';
export const SET_CHAPTER_BY_ID = 'set_chapter_id';
export const ADD_CHAPTER_SUCCESS = 'add_chapters';
export const FETCH_CHAPTER_BY_ID = 'fetch_chapter_id';
export const UPDATE_CHAPTER_BY_ID = 'update_chapter_id';
export const DELETE_CHAPTER_BY_ID = 'delete_chapter_id';
export const GET_ALL_CHAPTERS_BY_QUERY = 'get_all_chapters_by_query';
export const GET_ALL_CHAPTERS_BY_QUERY_FOR_SEARCH =
  'get_all_chapters_by_query_for_search';

//EMAILCODE
export const GET_ALL_EMAILCODES = 'get_all_emailcodes';
export const GET_ALL_EMAILCODES_SEARCH = 'get_all_emailcodes_search';
export const ADD_EMAILCODE_SUCCESS = 'add_emailcodes';
export const FETCH_EMAILCODE_BY_ID = 'fetch_emailcode_id';
export const UPDATE_EMAILCODE_BY_ID = 'update_emailcode_id';
export const DELETE_EMAILCODE_BY_ID = 'delete_emailcode_id';
export const GET_ALL_EMAILCODES_BY_QUERY = 'get_all_emailcodes_by_query';
export const SET_EMAIL_TEMPLATE_TYPE = 'set_email_template_type';

//KEYWORD
export const GET_ALL_KEYWORDS = 'get_all_keywords';
export const ADD_KEYWORD_SUCCESS = 'add_keywords';
export const FETCH_KEYWORD_BY_ID = 'fetch_keyword_id';
export const UPDATE_KEYWORD_BY_ID = 'update_keyword_id';
export const DELETE_KEYWORD_BY_ID = 'delete_keyword_id';
export const GET_ALL_KEYWORDS_BY_QUERY = 'get_all_keyword_by_query';
export const GET_ALL_KEYWORDS_BY_QUERY_SEARCH =
  'get_all_keyword_by_query_search';

//ADS
export const GET_ALL_ADS = 'get_all_ads';
export const GET_ALL_ADS_WITHOUT_PAGINATION = 'get_all_ads_without_pagination';
export const GET_ALL_ADS_APPROVED = 'get_all_ads_approved';
export const GET_ALL_ACCOUNTS = 'get_all_accounts';
export const FETCH_ADS_BY_USERID = 'fetch_ads_by_user_id';
export const ADD_AD = 'add_ads';
export const UPDATE_AD_BY_ID = 'update_ad_id';
export const UPDATE_AD_STATUS_BY_ID = 'update_ad_status_id';
export const DELETE_AD_BY_ID = 'delete_ad_id';
export const FETCH_AD_BY_ID = 'fetch_ad_id';
export const POST_ADS_PAYMENT = 'post_ads_payment';
export const POST_ADS_PAYMENT_SUCCESS = 'post_ads_payment_success';
export const RESET_ADS_PAYMENT = 'reset_payment';

//EVENT TEMPLATES SCHEDULER
export const GET_ALL_EVENT_TEMPLATE_SCHEDULERS =
  'get_all_event_template_schedulers';
export const SET_EVENT_START_DATE_EVENT_TEMPLATE_SCHEDULER =
  'set_event_start_date_event_template_scheduler';
export const SET_EVENT_TEMPLATE_EDIT = 'set_event_template_edit';
export const SEND_DIRECT_EMAIL_BY_ID = 'send_direct_email_by_id';
export const ADD_EVENT_TEMPLATE_SCHEDULER_SUCCESS =
  'add_event_template_scheduler_success';
export const DELETE_EVENT_TEMPLATE_SCHEDULER_BY_ID =
  'delete_event_template_scheduler_by_id';
export const FETCH_EVENT_TEMPLATE_SCHEDULER_BY_ID =
  'fetch_event_template_scheduler_by_id';
export const FETCH_EVENT_TEMPLATE_SCHEDULERS_BY_USERID =
  'fetch_event_template_schedulers_by_userid';
export const UPDATE_EVENT_TEMPLATE_SCHEDULER_BY_ID =
  'update_event_template_schedulers_id';
export const GET_EVENT_TEMPLATE_SCHEDULER_BY_EVENT_ID =
  'get_event_template_scheduler_by_event_id';

//SPONSORS
export const GET_ALL_SPONSORS = 'get_all_sponsors';
export const GET_ALL_SPONSORS_BY_QUERY = 'get_all_sponsors_by_query';
export const ADD_SPONSOR_SUCCESS = 'add_sponsors';
export const UPDATE_SPONSOR_BY_ID = 'update_sponsor_id';
export const DELETE_SPONSOR_BY_ID = 'delete_sponsor_id';
export const FETCH_SPONSOR_BY_ID = 'fetch_sponsor_id';

//EVENT_TYPES
export const GET_ALL_EVENTTYPES = 'get_all_eventTypes';
export const GET_ALL_EVENTTYPES_BY_QUERY = 'get_all_eventTypes_by_query';
export const GET_ALL_EVENTTYPES_BY_QUERY_SEARCH =
  'get_all_eventTypes_by_query_search';
export const ADD_EVENTTYPES_SUCCESS = 'add_eventTypes';
export const UPDATE_EVENTTYPES_BY_ID = 'update_eventTypes_id';
export const DELETE_EVENTTYPES_BY_ID = 'delete_eventTypes_id';
export const FETCH_EVENTTYPES_BY_ID = 'fetch_eventTypes_id';

//JOB
export const GET_ALL_JOBS = 'get_all_jobs';
export const GET_ALL_JOBS_APPROVED = 'get_all_jobs_approved';
export const GET_JOBS_BY_ID = 'get_jobs_by_id';
export const GET_JOBS_BY_USERID = 'get_jobs_by_user_id';
export const POST_JOBS = 'post_jobs';
export const UPDATE_JOBS_STATUS = 'update_job_status';
export const UPDATE_JOBS_BY_ID = 'update_jobs_by_id';
export const DELETE_JOBS_BY_ID = 'delete_jobs_by_id';
export const GET_ALL_JOBS_BY_QUERY = 'get_all_jobs_by_query';

export const GET_ALL_JOBS_WITHOUT_PAGINATION =
  'get_all_jobs_without_pagination';
export const GET_ALL_JOBS_ACCOUNTS = 'get_all_jobs_accounts';
export const POST_JOBS_PAYMENT = 'post_jobs_payment';
export const POST_JOBS_PAYMENT_SUCCESS = 'post_jobs_payment_success';
export const RESET_JOBS_PAYMENT = 'reset_jobs_payment';

//JOBFEES
export const GET_ALL_JOBFEES = 'get_all_jobFees';
export const GET_JOBFEES_BY_ID = 'get_jobFees_by_id';
export const POST_JOBFEES = 'post_jobFees';
export const UPDATE_JOBFEES_STATUS = 'update_jobFees_status';
export const UPDATE_JOBFEES_BY_ID = 'update_jobFees_by_id';
export const DELETE_JOBFEES_BY_ID = 'delete_jobFees_by_id';

//ADFEES
export const GET_ALL_ADFEES = 'get_all_adFees';
export const GET_ADFEES_BY_ID = 'get_adFees_by_id';
export const POST_ADFEES = 'post_adFees';
export const UPDATE_ADFEES_STATUS = 'update_adFees_status';
export const UPDATE_ADFEES_BY_ID = 'update_adFees_by_id';
export const DELETE_ADFEES_BY_ID = 'delete_adFees_by_id';

//SPEAKERS
export const GET_ALL_SPEAKERS = 'get_all_speakers';
export const GET_ALL_SPEAKERS_BY_QUERY = 'get_all_speakers_by_query';
export const GET_ALL_SPEAKERS_BY_QUERY_SEARCH =
  'get_all_speakers_by_query_search';
export const GET_SPEAKERS_BY_ID = 'get_speakers_by_id';
export const POST_SPEAKERS = 'post_speakers';
export const UPDATE_SPEAKERS_STATUS = 'update_speakers_status';
export const UPDATE_SPEAKERS_BY_ID = 'update_speakers_by_id';
export const DELETE_SPEAKERS_BY_ID = 'delete_speakers_by_id';

//COMPANIES
export const GET_ALL_COMPANIES = 'get_all_companies';
export const GET_ALL_COMPANIES_BY_QUERY = 'get_all_companies_by_query';
export const GET_COMPANIES_BY_ID = 'get_companies_by_id';
export const POST_COMPANIES = 'post_companies';
export const UPDATE_COMPANIES_BY_ID = 'update_companies_by_id';
export const DELETE_COMPANIES_BY_ID = 'delete_companies_by_id';

//COUPON_CODES
export const GET_ALL_COUPONCODES = 'get_all_couponCodes';
export const GET_ALL_COUPONCODES_WITH_QUERY = 'get_all_couponCodes_with_query';
export const GET_ALL_COUPONCODES_WITH_QUERY_SEARCH =
  'get_all_couponCodes_with_query_search';
export const GET_COUPONCODES_BY_ID = 'get_couponCodes_by_id';
export const POST_COUPONCODES = 'post_couponCodes';
export const UPDATE_COUPONCODES_BY_ID = 'update_couponCodes_by_id';
export const DELETE_COUPONCODES_BY_ID = 'delete_couponCodes_by_id';

//EVENTOPTIONGROUPS
export const GET_ALL_EVENTOPTIONGROUPS = 'get_all_eventOptionGroups';
export const GET_EVENTOPTIONGROUPS_BY_ID = 'get_eventOptionGroupsby_id';
export const POST_EVENTOPTIONGROUPS = 'post_eventOptionGroups';
export const UPDATE_EVENTOPTIONGROUPS_BY_ID = 'update_eventOptionGroups_by_id';
export const DELETE_EVENTOPTIONGROUPS_BY_ID = 'delete_eventOptionGroups_by_id';
export const GET_ALL_EVENTOPTIONGROUPS_BY_QUERY =
  'get_all_eventOptionGroups_by_query';
export const GET_ALL_EVENTOPTIONGROUPS_BY_QUERY_SEARCH =
  'get_all_eventOptionGroups_by_query_search';
export const GET_ADMIN_ALL_EVENTOPTIONS_BY_EVENT_ID =
  'get_admin_all_eventOptionGroups_by_event_id';

//EVENTOPTIONS
export const GET_ALL_EVENTOPTIONS = 'get_all_eventOptions';
export const GET_EVENTOPTIONS_BY_ID = 'get_eventOptions_by_id';
export const POST_EVENTOPTIONS = 'post_eventOptions';
export const UPDATE_EVENTOPTIONS_BY_ID = 'update_eventOptions_by_id';
export const DELETE_EVENTOPTIONS_BY_ID = 'delete_eventOptions_by_id';
export const GET_ALL_EVENTOPTIONS_BY_QUERY = 'get_all_eventOptions_by_query';
export const GET_EVENT_OPTIONS_BY_EVENT_ID = 'GET_EVENT_OPTIONS_BY_EVENT_ID';

//EVENTS
export const GET_ALL_EVENTS = 'get_all_events';
export const GET_ALL_PAST_EVENTS = 'get_all_past_events';
export const GET_ALL_USER_EVENTS = 'get_all_user_events';
export const GET_EVENTS_BY_CHAPTERS = 'get_all_events_by_chapters';
export const GET_EVENTS_BY_ID = 'get_events_by_id';
export const POST_EVENTS = 'post_events';
export const UPDATE_EVENTS_BY_ID = 'update_events_by_id';
export const DUPLICATE_EVENTS_BY_ID = 'duplicate_events_by_id';
export const DELETE_EVENTS_BY_ID = 'delete_events_by_id';
export const GET_ALL_EVENTS_BY_QUERY = 'get_all_events_by_query';
export const GET_ALL_EVENTS_BY_QUERY_SEARCH = 'get_all_events_by_query_search';
export const UPDATE_EVENT_STATUS = 'update_event_status';
export const UPDATE_EVENT_REFUND_STATUS = 'update_event_refund_status';

//EVENT REGISTRATION
export const ADD_ATTENDEE = 'add_attendee';
export const GET_ALL_ATTENDEES_BY_USER_EVENTID = 'get_all_attendee';
export const GET_ALL_ATTENDEES_BY_TRANSACTION_ID =
  'get_all_attendee_by_transaction_id';
export const DELETE_ATTENDEES_BY_ID = 'delete_attendees_by_id';
export const UPDATE_ATTENDEES_BY_ID = 'update_attendees_by_id';
export const UPDATE_SPONOSOR_BY_ID = 'update_sponosor_by_id';
export const VERIFY_COUPONCODES = 'verify_couponCodes';
export const APPLY_COUPONCODES = 'apply_couponCodes';
export const SET_COUPONCODES_ERROR_MESSAGE = 'set_couponCodes_error_message';
export const GET_ALL_REGISTRATION = 'get_all_registration';
export const GET_ALL_REGISTRATION_CSV_DOWNLOAD =
  'get_all_registration_csv_download';
export const PAYMENT_EVENT_REGISTRATION_BY_EVENT_ID =
  'update_payment_by_event_id';
export const RESET_EVENT_EDIT_DATA = 'reset_event_edit_data';
export const GET_ALL_MY_EVENTS = 'get_all_my_events';
export const GET_ALL_MY_EVENTS_CART = 'get_all_my_events_cart';
export const POST_EVENT_PAYMENT_SUCCESS = 'post_event_payment_success';
export const RESET_EVENT_PAYMENT = 'reset_event_payment';
export const MEMORIZED_REFUND_ATTENDEES = 'memorized_refund_attendees';
export const RESET_MEMORIZED_REFUND_ATTENDEES =
  'reset_memorized_refund_attendees';
export const PAYMENT_EVENT_REGISTRATION_CLIENT_KEYS =
  'payment_event_registration_client_keys';

export const UPDATE_REGISTRATION_OPTOINS_BY_ID = 'update_registration_options';
export const CANCEL_GROUP_OF_REGISTRATION_BY_EVENT_ID =
  'cancel_group_of_registration';

//EVENT REFUND
export const GET_REFUND_BY_EVENTID = 'get_refund_by_event_id';
export const UPDATE_REFUND_STATUS = 'update_refund_status';

//SIDE BAR STATE MANAGE
export const SET_SIDE_BAR_STATE = 'Set Side bar state';
//Admin Table Page Size
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
//Admin Table Dynamic Search
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
//Admin Table Page Number
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
// EMAIL TO MEMBERS
export const SEND_EMAIL_TO_MEMBERS = 'SEND_EMAIL_TO_MEMBERS';

//FORM STATE
export const SET_FORM_STATE_SUCESS = 'FORM_STATE_CHANGE';

//EVENT NAME STATE
export const SET_EVENT_NAME_STATE = 'EVENT_NAME_STATE_CHANGE';
//ROUTE MENU STATE
export const SET_MENU_ROUTE = 'MENU_ROUTE_STATE';
//TEMPLATE PHOTOS
export const GET_TEMPLATE_PHOTOS = 'get_template_photos';
export const POST_TEMPLATE_PHOTOS = 'post_template_photos';

// SPECIAL PAYMENT
export const POST_SPECIAL_PAYMENT_DETAILS = 'POST_SPECIAL_PAYMENT_DETAILS';
export const POST_SPECIAL_PAYMENT_DETAILS_SUCCESS =
  'POST_SPECIAL_PAYMENT_DETAILS_SUCCESS';
export const RESET_SPECIAL_PAYMENT = 'RESET_SPECIAL_PAYMENT';
export const GET_ALL_SPECIAL_PAYMENT_DETAILS =
  'GET_ALL_SPECIAL_PAYMENT_DETAILS';
export const POST_TOKEN_DATA = 'POST_TOKEN_DATA';

// APIS SUCCESS MESSAGES

//SUCCESS EVENTS
export const DELETE_EVENTS_SUCCESS = 'Event Deleted Successfully';
export const POST_EVENTS_SUCCESS = 'Event Created Successfully';
export const UPDATE_EVENTS_SUCCESS = 'Event Updated Successfully';
export const DUPLICATE_EVENTS_SUCCESS = 'Event Duplicated Successfully';
export const UPDATE_EVENTS_STATUS_SUCCESS = 'Event Status Updated Successfully';
export const UPDATE_EVENTS_STATUS_REFUND_SUCCESS =
  'Refund Requested Successfully';
export const APPROVE_EVENTS_STATUS_REFUND_SUCCESS =
  'Refund Approved Successfully';

//SUCCESS EVENTOPTIONGROUPS
export const DELETE_EVENTOPTIONGROUPS_SUCCESS =
  'Event Option Group Deleted Successfully';
export const POST_EVENTOPTIONGROUPS_SUCCESS =
  'Event Option Group Created Successfully';
export const UPDATE_EVENTOPTIONGROUPS_SUCCESS =
  'Event Option Group Updated Successfully';

// EVENT REFUND SUCCESS
export const REFUND_UPDATE_SUCCESS = 'Status Updated Successfully';

//EVENT REGISTRATION SUCCESS
export const DELETE_SUCCESS = 'Attendee Deleted Successfully';
export const UPDATE_SUCCESS = 'Attendee Updated Successfully';
export const EVENT_REGISTRATION_SUCCESS =
  'Success! Your event registration is confirmed';
export const ATTENDEE_ADDED_SUCCESS = 'Attendee Added Successfully';
export const SPONSOR_DELETE_SUCCESS = 'Sponsor Deleted Successfully';
export const SPONSOR_ADD_SUCCESS = 'Sponsor Created Successfully';
export const SPONSOR_UPDATE_SUCCESS = 'Sponsor Updated Successfully';
export const EVENT_REGISTRATION_UPDATE_SUCCESS =
  'Event Registration Updated Successfully';
export const EVENT_REGISTRATION_CANCELED_SUCCESS =
  'Event Registration Canceled Successfully';

//AUTH FORGOT PASS
export const SEND_MAil_SUCCESS = 'send_mail_success';
//AUTH FORGOT PASS SUCCESS
export const RESET_PASSWORD_SUCCESS = 'Password Updated Successfully';

//SUCCESS EVENTOPTIONS
export const DELETE_EVENTOPTION_SUCCESS = 'Event Option Deleted Successfully';
export const POST_EVENTOPTION_SUCCESS = 'Event Option Created Successfully';
export const UPDATE_EVENTOPTION_SUCCESS = 'Event Option Updated Successfully';

//SUCCESS COUPON_CODES
export const DELETE_COUPONCODES_SUCCESS = 'Coupon Code Deleted Successfully';
export const POST_COUPONCODES_SUCCESS = 'Coupon Code Created Successfully';
export const UPDATE_COUPONCODES_SUCCESS = 'Coupon Code Updated Successfully';
export const APPLIED_COUPONCODES_SUCCESS =
  'Your Coupon Code is Valid and Applied';
export const VERIFY_COUPONCODES_SUCCESS = 'Valid Coupon Code';
//SUCCESS SPEAKERS
export const DELETE_SPEAKERS_SUCCESS = 'Speakers Deleted Successfully';
export const POST_SPEAKERS_SUCCESS = 'Speakers Created Successfully';
export const UPDATE_SPEAKERS_SUCCESS = 'Speakers Updated Successfully';

//SUCCESS COMPANIES
export const DELETE_COMPANIES_SUCCESS = 'Company Deleted Successfully';
export const POST_COMPANIES_SUCCESS = 'Company Created Successfully';
export const UPDATE_COMPANIES_SUCCESS = 'Company Updated Successfully';

//SUCCESS ADFEES
export const DELETE_ADFEES_SUCCESS = 'AdFees Deleted Successfully';
export const POST_ADFEES_SUCCESS = 'AdFees Created Successfully';
export const UPDATE_ADFEES_SUCCESS = 'AdFees Updated Successfully';

//SUCCESS JOBS
export const DELETE_JOBS_SUCCESS = 'Job Deleted Successfully';
export const POST_JOBS_SUCCESS = 'Job Created Successfully';
export const UPDATE_JOBS_SUCCESS = 'Job Updated Successfully';
export const UPDATE_JOBS_STATUS_SUCCESS = 'Job Status Updated Successfully';

//SUCCESS ADS
export const DELETE_ADS_SUCCESS = 'Ad Deleted Successfully';
export const POST_ADS_SUCCESS = 'Ad Created Successfully';
export const UPDATE_ADS_SUCCESS = 'Ad Updated Successfully';
export const UPDATE_ADS_STATUS_SUCCESS = 'Ad Status Updated Successfully';

//SUCCESS JOBFEES
export const DELETE_JOBFEES_SUCCESS = 'JobFees Deleted Successfully';
export const POST_JOBFEES_SUCCESS = 'JobFees Created Successfully';
export const UPDATE_JOBFEES_SUCCESS = 'JobFees Updated Successfully';

//SUCCESS VALIDATE_UNSUBSCRIBE
export const VALIDATE_UNSUBSCRIBE_SUCCESS =
  'Already Unsubscribed To Pbss Communications';

//SUCCESS CHAPTER
export const DELETE_CHAPTER_SUCCESS = 'Chapter Deleted Successfully';
export const POST_CHAPTER_SUCCESS = 'Chapter Created Successfully';
export const UPDATE_CHAPTER_SUCCESS = 'Chapter Updated Successfully';

//SUCCESS EMAIL TEMPLATE
export const DELETE_TEMPLATE_SUCCESS = 'Email Template Deleted Successfully';
export const POST_TEMPLATE_SUCCESS = 'Email Template Created Successfully';
export const UPDATE_TEMPLATE_SUCCESS = 'Email Template Updated Successfully';

//SUCCESS EVENT TYPE TEMPLATE
export const DELETE_EVENT_TYPE_SUCCESS = 'Event Type Deleted Successfully';
export const POST_EVENT_TYPE_SUCCESS = 'Event Type Created Successfully';
export const UPDATE_EVENT_TYPE_SUCCESS = 'Event Type Updated Successfully';

//SUCCESS KEYWORD TEMPLATE
export const DELETE_INTEREST_SUCCESS = 'Interest Deleted Successfully';
export const POST_INTEREST_SUCCESS = 'Interest Created Successfully';
export const UPDATE_INTEREST_SUCCESS = 'Interest Updated Successfully';

//SUCCESS USER
export const DELETE_USER_SUCCESS = 'Member Attendee Deleted Successfully';
export const POST_USER_SUCCESS = 'Member Attendee Created Successfully';
export const UPDATE_USER_SUCCESS = 'Member Attendee Updated Successfully';
export const UPDATE_USER_STATUS_SUCCESS =
  'Member Attendee Status Updated Successfully';
export const UPDATE_USER_PROFILE_SUCCESS = 'Profile Updated Successfully';

//STEM
export const UPDATE_STEM = 'update_stem';
export const GET_STEM = 'update_stem';

import React, {useEffect, useState} from 'react';
import SectionLayout from '../../../CommonComponents/Layout/sectionLayout';
import CommonJobsListingCard from './CommonJobListingCard';
import CommonAddNewListingWithFilter from '../../../CommonComponents/Common_AddNewListing_with_Filter';
import {Alert, Button, Col, Row} from 'antd';
import './index.css';
import JobsFormComponent from '../JobsForm';
import {
  AdsAndJobsOptions,
  AdsAndJobsOptionsForUser,
  DefaultListingViewMoreCount,
  getLocalStorageUserInfo,
} from '../../../../Util/commonUtility';
import {useDispatch} from 'react-redux';
import {getJobsByUserId} from '../../../../Store/Actions';
import DownArrowSvg from '../../../../Assets/svgComponents/DownArrowSvg';
import {ApplicationString} from '../../../../Localization/Locales/en';

const CommonJobListing = ({
  allJobsData,
  setAllJobsData,
  page,
  setPage,
  jobFeesOptions,
  zeroFeeData,
  fetchNewData,
  setFetchNewData,
}) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValues, setSearchValues] = useState(null);
  const [filterValue, setFilterValue] = useState('');
  const [selectedYearValue, setSelectedYearValue] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const userInfo = getLocalStorageUserInfo();
  const userId = userInfo ? userInfo?.id : '';

  const searchOnChange = (e) => {
    let search = e.target.value;
    if (search) {
      setSearchValues(e.target.value);
    } else {
      setSearchValues('');
    }
  };

  const formatedJobsLocationData = allJobsData?.map((item) => ({
    label: item.location,
    value: item.location,
  }));

  useEffect(() => {
    if (fetchNewData) {
      dispatch(
        getJobsByUserId(
          userId,
          searchValues,
          filterValue,
          setAllJobsData,
          page,
          setTotalItems,
          selectedYearValue,
        ),
      );
      setFetchNewData(false);
    }
  }, [fetchNewData]);

  const onSearch = () => {
    const userInfo = getLocalStorageUserInfo();
    const userId = userInfo?.id;
    dispatch(
      getJobsByUserId(
        userId,
        searchValues,
        filterValue,
        setAllJobsData,
        page,
        setTotalItems,
        selectedYearValue,
      ),
    );
  };

  const handleChange = (value) => {
    if (!value) {
      dispatch(
        getJobsByUserId(
          userId,
          searchValues,
          '',
          setAllJobsData,
          page,
          setTotalItems,
          selectedYearValue,
        ),
      );
      setFilterValue('');
    } else {
      setFilterValue(value);
      dispatch(
        getJobsByUserId(
          userId,
          searchValues,
          value,
          setAllJobsData,
          page,
          setTotalItems,
          selectedYearValue,
        ),
      );
    }
  };

  const handleYearFilter = (value) => {
    if (!value) {
      dispatch(
        getJobsByUserId(
          userId,
          searchValues,
          filterValue,
          setAllJobsData,
          page,
          setTotalItems,
          '',
        ),
      );
      setSelectedYearValue('');
    } else {
      dispatch(
        getJobsByUserId(
          userId,
          searchValues,
          filterValue,
          setAllJobsData,
          page,
          setTotalItems,
          value,
        ),
      );
      setSelectedYearValue(value);
    }
  };

  const onViewMore = () => {
    const userInfo = getLocalStorageUserInfo();
    const userId = userInfo?.id;
    setPage((prev) => {
      const newPage =
        prev + DefaultListingViewMoreCount?.jobsListingViewMoreCount;
      dispatch(
        getJobsByUserId(
          userId,
          searchValues,
          filterValue,
          setAllJobsData,
          newPage,
          setTotalItems,
          selectedYearValue,
        ),
      );
      return newPage;
    });
  };

  useEffect(() => {
    if (searchValues || filterValue) {
      dispatch(
        getJobsByUserId(
          userId,
          searchValues,
          filterValue,
          setAllJobsData,
          page,
          setTotalItems,
          selectedYearValue,
        ),
      );
    } else {
      dispatch(
        getJobsByUserId(
          userId,
          searchValues,
          filterValue,
          setAllJobsData,
          page,
          setTotalItems,
          selectedYearValue,
        ),
      );
    }
  }, [searchValues]);

  return (
    <React.Fragment>
      <SectionLayout>
        <Row gutter={[0, 32]}>
          <Col span={24}>
            <CommonAddNewListingWithFilter
              year
              search
              selectedYearValue={selectedYearValue}
              selectedStatusValue={filterValue}
              setIsModalOpen={setIsModalOpen}
              searchOnChange={searchOnChange}
              onSearch={onSearch}
              handleYearFilter={handleYearFilter}
              handleChange={handleChange}
              List={AdsAndJobsOptionsForUser}
              pageName={'Job'}
              selectPlaceholder={
                ApplicationString['userJobsComponent.statusPlaceHolder']
              }
              placeHolder={
                ApplicationString['userJobsComponent.searchForJobsText']
              }
            />
          </Col>
          <Col span={24} className='common-jobs-listing-main-container'>
            {allJobsData?.length > 0 ? (
              allJobsData?.map((item, index) => {
                return (
                  <CommonJobsListingCard
                    key={item?.id}
                    jobId={item?.id}
                    jobTitle={item?.position}
                    jobEmployer={item?.employer}
                    jobLocation={item?.location}
                    jobContactInfo={item?.contact_info}
                    jobUrl={item?.attachment_url}
                    jobDescription={item?.description}
                    jobRequirements={item?.requirement}
                    jobLogo={item?.logo_ids[0] || ''}
                    jobStartDate={item?.start_date}
                    jobLastDate={item?.end_date}
                    jobStatus={item?.status}
                    jobFeesOptions={jobFeesOptions}
                    jobFees={item?.job_fee}
                    job_fee_note={item?.job_fee_note}
                    months={item?.months}
                  />
                );
              })
            ) : (
              <Alert
                message='No Jobs Available'
                className='no-jobs-alert'
                type='info'
                showIcon
              />
            )}
          </Col>
          {
            <JobsFormComponent
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              jobFeesOptions={jobFeesOptions}
              zeroFeeData={zeroFeeData}
            />
          }
          <Col span={24}>
            {totalItems > allJobsData?.length && (
              <div className='view-more-container'>
                <Button
                  className='view-more-button'
                  id='userProfileJobs-viewMore-button'
                  onClick={onViewMore}>
                  <span className='view-more-text'>
                    {ApplicationString['userJobsComponent.button.viewMore']}
                  </span>
                  <DownArrowSvg />
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </SectionLayout>
    </React.Fragment>
  );
};

export default CommonJobListing;

import {SET_SEARCH_VALUE} from '../../Constant/ActionTypes';

export const setSearchValue = (searchPageName, searchvalue, ID) => {
  return {
    type: SET_SEARCH_VALUE,
    payload: {
      searchPageName,
      searchvalue,
      ID,
    },
  };
};

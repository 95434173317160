import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  addEventTemplateScheduler,
  fetchEmailCodeById,
  getAllEmailCodes,
  getAllEmailCodesSearch,
  getAllsEmailCodes,
  getEventTemplateSchedulerById,
  resetEmailCodesSearchState,
  updateEventTemplateSchedulerById,
  getEventTemplateSchedulerByEventId,
} from '../../../../Store/Actions';
import EventTempFormComponent from '../../../../Components/AdminComponents/EventTemplateScheduler/EventTempForm';
import {
  NotificationTypes,
  createSelectionOptionsUtil,
  createSelectionOptionsUtilForEmailCode,
  emailTemplatesTypeCode,
  isEventEmailTemplate,
} from '../../../../Util/commonUtility';
import {getAllEvents} from '../../../../Store/Actions/events';
import {useNavigate, useParams} from 'react-router-dom';

const EventTempFormContainer = () => {
  const {id, eventID} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {getEventTemplateSchedulersDataByEventId} = useSelector(
    ({eventTemplateSchedulers}) => eventTemplateSchedulers,
  );
  const {fetchEditData, isEventTemplateEdit, eventStartDateForEventTemp} =
    useSelector(({eventTemplateSchedulers}) => eventTemplateSchedulers);

  const eventsListData = useSelector(
    ({events}) => events?.allEventsQueryData?.data?.events,
  );

  const [editData, setEditData] = useState([]);
  const [emailCodeList, setEmailCodeList] = useState([]);
  const {allEmailCodes} = useSelector(({emailCodes}) => emailCodes);
  const [eventsList, setEventsList] = useState([]);
  const {allEvents} = useSelector(({events}) => events);
  const [fetching, setFetching] = useState(false);
  const [eventTemplateSchedulerData, setEventTemplateSchedulerData] =
    useState();
  const [emailTemplateCode, setEmailTemplateCode] = useState(
    emailTemplatesTypeCode?.filter((typeCode) => typeCode.isEventEmailTemplate),
  );
  useEffect(() => {
    dispatch(resetEmailCodesSearchState(false));
  }, []);

  useEffect(() => {
    if (fetchEditData) {
      setEditData(fetchEditData?.data);
    }
  }, [fetchEditData]);

  useEffect(() => {
    if (allEmailCodes.forSearch) {
      const filteredEmailCodes =
        allEmailCodes.forSearch?.data?.emailCodes?.filter((emailCode) =>
          isEventEmailTemplate(emailCode.email_template_type),
        );
      setEmailCodeList(
        createSelectionOptionsUtilForEmailCode(
          filteredEmailCodes,
          'id',
          'details',
        ),
      );
      setFetching(false);
    } else {
      dispatch(
        getAllEmailCodesSearch('', '', '', {
          page: 1,
          pageCount: 60,
        }),
      );
    }
  }, [allEmailCodes.forSearch]);

  useEffect(() => {
    if (allEvents) {
      setEventsList(
        createSelectionOptionsUtil(allEvents?.data?.events, 'id', 'name'),
      );
    } else {
      dispatch(getAllEvents());
    }
  }, [allEvents]);

  useEffect(() => {
    if (getEventTemplateSchedulersDataByEventId) {
      setEventTemplateSchedulerData(
        getEventTemplateSchedulersDataByEventId?.data?.eventEmailTemplates,
      );
    } else {
      fetchEventTempaltesByEventId(eventID ? eventID : id);
    }
  }, [getEventTemplateSchedulersDataByEventId]);

  const fetchEventTempaltesByEventId = (eventId) => {
    dispatch(getEventTemplateSchedulerByEventId(eventId));
  };

  const postEventTemplateScheduler = async (data) => {
    dispatch(addEventTemplateScheduler(data, navigate));
  };

  const updateEventTemplateScheduler = async (data, id) => {
    dispatch(updateEventTemplateSchedulerById(data, id, navigate));
  };

  const fetchEventTemplateSchedulerById = async (id) => {
    dispatch(getEventTemplateSchedulerById(id));
  };

  return (
    <div>
      <EventTempFormComponent
        eventTemplateSchedulerData={eventTemplateSchedulerData}
        postEventTemplateScheduler={(data) => {
          postEventTemplateScheduler(data);
        }}
        updateEventTemplateScheduler={(id, data) => {
          updateEventTemplateScheduler(id, data);
        }}
        fetchEventTemplateSchedulerById={(id) => {
          fetchEventTemplateSchedulerById(id);
        }}
        isEventTemplateEdit={isEventTemplateEdit}
        emailCodeList={emailCodeList}
        eventsList={eventsList}
        EditData={editData}
        fetching={fetching}
        setFetching={setFetching}
        setEmailCodeList={setEmailCodeList}
        emailTemplateCode={emailTemplateCode}
        eventStartDateForEventTemp={eventStartDateForEventTemp}
      />
    </div>
  );
};

export default EventTempFormContainer;

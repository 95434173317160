import React, {useEffect, useState} from 'react';
import {
  Form,
  Input,
  Button,
  Space,
  Card,
  InputNumber,
  DatePicker,
  Select,
  Radio,
  Checkbox,
  Spin,
  Empty,
} from 'antd';
import {SearchOutlined, ClearOutlined} from '@ant-design/icons';
import {ApplicationString} from '../../../Localization/Locales/en';
import './style.css';
import {
  AdsAndJobsOptions,
  UserRoleOptions,
  UserStatusOptions,
  eventTypesOptions,
  isEmptyObject,
  keyWordRadioOptions,
  queryString,
  registrationFilterOptions,
} from '../../../Util/commonUtility';
import {Option} from 'antd/es/mentions';
import {Group} from 'antd/es/avatar';
import moment from 'moment';
import {setSearchValue} from './../../../Store/Actions/dynamicSearch';
import {useDispatch, useSelector} from 'react-redux';
import {getAllEventsByQueryStringSearch} from '../../../Store/Actions/events';
import {
  getAllEventTypesByQuerySearch,
  getAllSpeakersByQuerySearch,
} from '../../../Store/Actions';
import {useParams} from 'react-router-dom';

const DynamicSearchForm = ({
  columns,
  onSearch,
  eventTypesOptions,
  chaptersOptions,
  speakerOptions,
  savedFilteredValues,
  title,
  eventIdOptions,
  eventsList,
  setFetching,
  fetching,
  setEventsList,
  setSpeakerOptions,
  SetEventTypesOption,
  options,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const {searchValues} = useSelector(({dynamicSearch}) => dynamicSearch);

  const {id} = useParams();
  useEffect(() => {
    if (savedFilteredValues) {
      form.setFieldsValue(savedFilteredValues);
    }
  }, [savedFilteredValues]);

  const onFinish = (values) => {
    dispatch(
      setSearchValue(
        title,
        {
          ...values,
          status: values?.status || values?.status === 0 ? values?.status : [],
          event_id: values?.event_id ? values?.event_id : '',
          local_chapter_id: values?.local_chapter_id
            ? values?.local_chapter_id
            : [],
          event_type_id: values?.event_type_id ? values?.event_type_id : [],
        },
        parseInt(id),
      ),
    );
    const startDate = values?.date ? new Date(values?.date[0]) : '';
    const formattedStartDate = moment(startDate)?.format('YYYY-MM-DD');
    const endDate = values?.date ? new Date(values?.date[1]) : '';
    const formattedendDate = moment(endDate)?.format('YYYY-MM-DD');
    const dateRange = [formattedStartDate, formattedendDate];
    const resultValues = {
      ...values,
      status: values?.status || values?.status === 0 ? values?.status : [],
      date: values?.date ? dateRange : '',
      event_id: values?.event_id ? values?.event_id : '',
      local_chapter_id: values?.local_chapter_id
        ? values?.local_chapter_id
        : [],
      event_type_id: values?.event_type_id ? values?.event_type_id : [],
    };
    if (!isEmptyObject(resultValues)) {
      onSearch(resultValues);
    }
  };

  const handleReset = () => {
    form.setFieldsValue({
      event_id: [],
      status: [],
      local_chapter_id: [],
      event_type_id: [],
    });
    onSearch(form.getFieldsValue());
    if (!isEmptyObject(form.getFieldValue())) {
      form.resetFields();
      onSearch(form.getFieldsValue());
      dispatch(setSearchValue(title, false));
    }
  };

  const handleEventSearch = (value) => {
    setEventsList([]);
    setFetching(true);
    const Data = {
      name: value,
    };
    const searchQueryData = queryString(Data);
    dispatch(
      getAllEventsByQueryStringSearch(
        {
          page: 1,
          pagecount: 10,
        },
        '',
        '',
        searchQueryData,
      ),
    );
  };

  const handleEventAllowClear = () => {
    dispatch(
      getAllEventsByQueryStringSearch({
        page: 1,
        pagecount: 10,
      }),
    );
  };

  //speaker custom search
  const handleSpeakerSearch = (value) => {
    setSpeakerOptions([]);
    setFetching(true);
    const Data = {
      first_name: value,
    };
    const searchQueryData = queryString(Data);
    dispatch(
      getAllSpeakersByQuerySearch(
        {
          page: 1,
          pagecount: 10,
        },
        '',
        '',
        searchQueryData,
      ),
    );
  };
  const handleSpeakerAllowClear = () => {
    dispatch(
      getAllSpeakersByQuerySearch({
        page: 1,
        pagecount: 10,
      }),
    );
  };

  const handleSpeakerMouseLeave = () => {
    if (speakerOptions?.length === 0) {
      dispatch(
        getAllSpeakersByQuerySearch({
          page: 1,
          pagecount: 10,
        }),
      );
    }
  };

  const handleEventTypeSearch = (value) => {
    SetEventTypesOption([]);
    setFetching(true);
    const Data = {
      name: value,
    };
    const searchQueryData = queryString(Data);
    dispatch(
      getAllEventTypesByQuerySearch('', '', searchQueryData, {
        page: 1,
        pageCount: 10,
      }),
    );
  };
  const handleEventTypeAllowClear = () => {
    dispatch(
      getAllEventTypesByQuerySearch('', '', '', {
        page: 1,
        pageCount: 10,
      }),
    );
  };
  const handleEventTypeMouseLeave = () => {
    if (eventTypesOptions?.length === 0) {
      dispatch(
        getAllEventTypesByQuerySearch('', '', '', {
          page: 1,
          pageCount: 10,
        }),
      );
    }
  };

  const getFormItemByType = (type, title, options) => {
    switch (type) {
      case 'eventType':
        return (
          <Select
            id={`${title}-eventSelect`}
            prefixCls='filter-filterSearch-select'
            placeholder={`Select ${title}`}
            style={{width: '200px'}}
            options={[]}></Select>
        );
      case 'event_id':
        return (
          <Select
            prefixCls='filter-filterSearch-select'
            showSearch
            allowClear
            optionFilterProp='children'
            filterOption={(input, option) =>
              option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSearch={handleEventSearch}
            notFoundContent={
              fetching ? (
                <Spin size='small' />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }
            onClear={handleEventAllowClear}
            placeholder={'Search by available events'}
            options={eventsList}></Select>
        );
      case 'speakers':
        return (
          <Select
            prefixCls='filter-filterSearch-select'
            showSearch
            allowClear
            optionFilterProp='children'
            filterOption={(input, option) =>
              option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSearch={handleSpeakerSearch}
            onMouseLeave={handleSpeakerMouseLeave}
            onClear={handleSpeakerAllowClear}
            notFoundContent={
              fetching ? (
                <Spin size='small' />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }
            placeholder={'Search by speakers'}
            options={speakerOptions}></Select>
        );
      case 'event_type_id':
        return (
          <Select
            prefixCls='filter-filterSearch-select'
            showSearch
            allowClear
            optionFilterProp='children'
            filterOption={(input, option) =>
              option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSearch={handleEventTypeSearch}
            onMouseLeave={handleEventTypeMouseLeave}
            onClear={handleEventTypeAllowClear}
            notFoundContent={
              fetching ? (
                <Spin size='small' />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }
            placeholder={'Search by event type'}
            options={eventTypesOptions}></Select>
        );
      case 'number':
        return (
          <Input
            id={`${title}-filterSearch-inputNumber`}
            type={type}
            className='search-input-filter'
            placeholder={`Search by ${title}`}
          />
        );
      case 'dropdown':
        return (
          <Select
            id={`${title}-filterSearch-dropdown`}
            defaultOpen={null}
            value={[]}
            allowClear
            prefixCls='filter-search-select'
            placeholder={`Search by ${title}`}
            style={{width: '200px'}}
            options={options}></Select>
        );
      case 'checkbox':
        return (
          <Checkbox
            id={`${title}-filterSearch-checkbox`}
            prefixCls='dynamic-checkbox'></Checkbox>
        );
      case 'radio':
        return (
          <Radio.Group
            id={`${title}-filterSearch-radioButtons`}
            prefixCls='common-radio-group'
            options={options}
            optionType='button'
            buttonStyle='solid'
          />
        );
      case 'dateRange':
        return (
          <DatePicker.RangePicker
            id={`${title}-filterSearch-datePicker`}
            style={{width: '100%'}}
            placement='topLeft'
            prefixCls='date-range'
            className='search-input-filter'
            placeholder={['Search by start date', 'end date']}
          />
        );
      default:
        return (
          <Input
            id={`${title}-filterSearch-input`}
            defaultValue={''}
            className='search-input-filter'
            placeholder={`Search by ${title}`}
          />
        );
    }
  };

  const getFormItems = () => {
    return columns.map((column) => (
      <Form.Item
        valuePropName={column?.valueProps}
        initialValue={column?.initialValue}
        key={column.key}
        name={column.name}
        label={column.label}>
        {(() => {
          switch (column?.name) {
            case 'local_chapter_id':
              return getFormItemByType(
                column?.type,
                column?.label?.toLowerCase(),
                chaptersOptions,
              );
            case 'speakers':
              return getFormItemByType(
                column?.type,
                column?.label?.toLowerCase(),
                speakerOptions,
              );
            case 'event_id':
              return getFormItemByType(
                column?.type,
                column?.label?.toLowerCase(),
                eventIdOptions,
              );
            default:
              return getFormItemByType(
                column?.type,
                column?.label?.toLowerCase(),
                column?.options ? column?.options : options,
              );
          }
        })()}
      </Form.Item>
    ));
  };

  return (
    <Card className='search-fi'>
      <Form
        form={form}
        onFinish={onFinish}
        layout='vertical'
        style={{marginBottom: '16px'}}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '(auto-fill, minmax(230px, 1fr)) !important',
            gap: '16px',
          }}>
          <Space wrap>{getFormItems()}</Space>
        </div>

        <Space style={{float: 'right'}} direction='vertical'>
          <Space>
            <Button
              id='admin-filterSearch-submitButton'
              type='primary'
              htmlType='submit'
              icon={<SearchOutlined />}
              className='common-button'>
              {ApplicationString['dynamicSearch.button.search']}{' '}
            </Button>
            <Button
              id='admin-filterSearch-resetButton'
              htmlType='button'
              onClick={handleReset}
              icon={<ClearOutlined />}
              className='reset-button'>
              {ApplicationString['dynamicSearch.button.reset']}
            </Button>
          </Space>
        </Space>
      </Form>
    </Card>
  );
};

export default DynamicSearchForm;

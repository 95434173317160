import React from 'react';
import SeminarLuncheonsComponent from './SeminarLuncheonsComponent';
import SymposiaComponent from './SymposiaComponent';
import WorkshopsComponent from './WorkshopsComponent';
import DinnerSeminarsComponent from './DinnerSeminarsComponent';

const EventTypeStaticMainComponent = ({eventTypeName}) => {
  const showEventTypeComponent = () => {
    if (eventTypeName === 'seminarLuncheons') {
      return <SeminarLuncheonsComponent />;
    } else if (eventTypeName === 'symposia') {
      return <SymposiaComponent />;
    } else if (eventTypeName === 'workshops') {
      return <WorkshopsComponent />;
    } else if (eventTypeName === 'dinnerSeminars') {
      return <DinnerSeminarsComponent />;
    } else {
      return <h1>Event Type Not Found</h1>;
    }
  };
  return <React.Fragment>{showEventTypeComponent()}</React.Fragment>;
};

export default EventTypeStaticMainComponent;

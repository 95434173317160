import {InfoCircleFilled} from '@ant-design/icons';
import React from 'react';
import './style.css';
const CustomizedTable = ({eventType, usedForBio, TableData}) => {
  const html = {__html: TableData};
  return (
    <div>
      {eventType === 'Webcast' && usedForBio && (
        <h3 className='desc-info'>
          <InfoCircleFilled /> The Zoom link will be sent one day before the
          event once we verify that you are outside SF-Bay Area
        </h3>
      )}
      <div dangerouslySetInnerHTML={html}></div>
    </div>
  );
};

export default CustomizedTable;

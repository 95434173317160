import React from 'react';
import './style.css';
import {ArrowRightOutlined} from '@ant-design/icons';
import ComponentTitle from '../CommonComponentTitle';
import SectionLayout from './../../CommonComponents/Layout/sectionLayout';
import {Link, useNavigate} from 'react-router-dom';
import {truncateHtmlToWords} from '../../../Util/commonUtility';

const RemaingDays = (StartDate, EndDate) => {
  const SDate = new Date(StartDate).getDate();
  const EDate = new Date(EndDate).getDate();

  const Remaining = EDate - SDate;
  return `${Remaining} Days Left For this job to close`;
};
const RecentOpeningsJobs = (props) => {
  const navigate = useNavigate();
  const viewDetailsHandler = (jobsId) => {
    navigate(`/jobs/${jobsId}`);
  };

  const content = (
    <div
      className={`opening-container ${props.login && 'used-for-login'} ${
        props?.usedForChapPage && 'opening-container-used-for-chapPage'
      }`}>
      {props.flex ? (
        <ComponentTitle
          title={props.title}
          OnNext={props.handleNext}
          OnPrev={props.handlePrev}
          textColor={'black'}
          currentOpeningResponsive={true}
          usedForChapPage={props.usedForChapPage}
        />
      ) : (
        <p>{props.title}</p>
      )}
      <div
        className={`openings-card-container ${props.login && 'login'} ${
          props.home && 'home'
        } ${
          props.usedForChapPage && 'opening-card-container-for-chapterpage'
        }`}>
        {/* <div className='opening-card-wapper'>
      <div className='card-title-wapper'>
        <div className='card-head-w'>
          <p>Want to see your job ad here?</p>
          <label className='wapper-desc'>
            Please fill up details for Book you ad placement here.
            limited spots book now.
          </label>
        </div>
      </div>
      <button>
        <a href='/jobs'>Submit Details</a>
      </button>
    </div> */}
        {/* {props?.data?.map((Item, index) => {
      return (
        <div key={index} className='opening-card-wapper'>
          <div className='card-title-wapper'>
            <div className='card-head-w'>
              <p>
                {props.flex
                  ? Item?.position?.slice(0, 30) + '. . .'
                  : Item.position}
              </p>
              <label className='wapper-desc'>{Item.employer}</label>
            </div>
            {Item.vacancy && <span>{Item.vacancy}</span>}
          </div>
          <div>
            <span>{RemaingDays(Item.start_date, Item.end_date)}</span>
          </div> */}
        {/* <Popover
          content={<div dangerouslySetInnerHTML={html}></div>}
          title='Description'
          trigger='hover'
          className='html-popover'>
          <button className='view-desc'>View Description</button>
        </Popover> */}
        {/* <a href={`/jobs/${Item?.id}`}>
            {props.buttonName}
            <ArrowRightOutlined className='details-icon' />
          </a>
        </div>
      );
    })}*/}
        {props?.data?.map((item, index) => {
          const descriptionItem = truncateHtmlToWords(
            item.description,
            props.login ? 20 : 32,
          );
          const positionItem = truncateHtmlToWords(
            item.position,
            props.login ? 4 : 32,
          );
          return (
            <>
              <div
                className={`opening-card-new-wrapper ${
                  props.usedForChapPage && 'opening-card-new-wrpapper-chapPage'
                }`}
                key={`${item.position}-${index}`}
                id={`home-openingCard-detailsButton_${item.id}`}
                onClick={() => viewDetailsHandler(item.id)}>
                <div className='opening-card-main'>
                  <div
                    className={`opening-card-title ${
                      props.usedForChapPage && 'opening-card-title-chapPage'
                    }`}>
                    {positionItem}
                  </div>
                  <div className='opening-card-company'>
                    <span
                      className={`opening-card-company-location ${
                        props.usedForChapPage &&
                        'opening-card-location-chapPage'
                      }`}>
                      {`${item.location}`}
                      <span style={{color: '#868686'}}>,</span>
                    </span>
                    <span
                      className={`opening-card-company-employer ${
                        props.usedForChapPage &&
                        'opening-card-employer-chapPage'
                      }`}>{` ${item.employer}`}</span>
                  </div>
                  <div
                    className={`opening-card-description ${
                      props.usedForChapPage &&
                      'opening-card-description-chapPage'
                    } `}
                    dangerouslySetInnerHTML={{__html: descriptionItem}}
                  />
                </div>
                <div className='opening-card-button-container'>
                  <button className='view-details-button'>View Details</button>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
  return (
    <React.Fragment>
      {props.usedForChapPage ? (
        content
      ) : (
        <SectionLayout>{content}</SectionLayout>
      )}
    </React.Fragment>
  );
};

export default RecentOpeningsJobs;

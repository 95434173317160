import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  addEventTypes,
  fetchEventTypeById,
  updateEventTypeById,
} from '../../../../Store/Actions';
import EventTypesFormComponent from '../../../../Components/AdminComponents/EventTypes/EventTypesForm';
import {useNavigate} from 'react-router-dom';

const EventTypesFormContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {fetchEventTypesData} = useSelector(({eventTypes}) => eventTypes);
  const [editData, setEditData] = useState();

  useEffect(() => {
    if (fetchEventTypesData) {
      setEditData(fetchEventTypesData?.data);
    }
  }, [fetchEventTypesData?.data]);

  const postEventTypes = async (Data) => {
    dispatch(addEventTypes(Data, navigate));
  };

  const updateEventTypes = async (Data, id) => {
    dispatch(updateEventTypeById(Data, id, navigate));
  };

  const fetchEventTypes = async (id) => {
    dispatch(fetchEventTypeById(id));
  };

  return (
    <div>
      <EventTypesFormComponent
        postEventTypes={(Data) => {
          postEventTypes(Data);
        }}
        updateEventTypes={(id, Data) => {
          updateEventTypes(id, Data);
        }}
        fetchEventTypes={(id) => {
          fetchEventTypes(id);
        }}
        EditData={editData}
      />
    </div>
  );
};

export default EventTypesFormContainer;

import {SET_PAGE_NUMBER, SET_PAGE_SIZE} from '../../Constant/ActionTypes';

export const setPageSize = (pageName, pageSize) => {
  return {
    type: SET_PAGE_SIZE,
    payload: {
      pageName,
      pageSize,
    },
  };
};

export const setPageNumber = (pageName, pageNumber) => {
  return {
    type: SET_PAGE_NUMBER,
    payload: {
      pageName,
      pageNumber,
    },
  };
};

import React, {useEffect, useState} from 'react';
import {
  deleteEventTypesById,
  getAllEventTypesByQuery,
  getUserDetails,
} from '../../../Store/Actions';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import {useDispatch, useSelector} from 'react-redux';
import EventTypesComponent from '../../../Components/AdminComponents/EventTypes';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';

const EventTypesContainer = () => {
  const dispatch = useDispatch();
  const {allEventTypesQueryData} = useSelector(({eventTypes}) => eventTypes);
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.eventTypes,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.eventTypes,
  );
  const [listData, setListData] = useState();
  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pageCount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'order_number',
    order: 'ascend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  const searchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.event_types,
  );

  useEffect(() => {
    if (allEventTypesQueryData.forMaster) {
      setListData(allEventTypesQueryData?.forMaster?.data?.eventTypes);
      setPagination({
        ...pagination,
        page: allEventTypesQueryData?.forMaster?.data?.currentPage,
        totalPages: allEventTypesQueryData?.forMaster?.data?.totalPage,
        totalCount: allEventTypesQueryData?.forMaster?.data?.totalCount,
      });
    } else {
      fetchEventTypes();
    }
  }, [allEventTypesQueryData?.forMaster?.data?.eventTypes]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchEventTypes(searchValues);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  // useEffect(() => {
  //   if (allEventTypesQueryData) {
  //     fetchEventTypes();
  //   }
  // }, [
  //   pagination.page,
  //   pagination.pageCount,
  //   sortParams.field,
  //   sortParams.order,
  // ]);

  useEffect(() => {
    if (searchValues) {
      dispatch(setPageNumber('eventTypes', 1));
      setPagination({
        ...pagination,
        page: 1,
      });
      setPaginationAndSortChanged(true);
    }
  }, [searchValues]);

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };
  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('eventTypes', pageSize));
    dispatch(setPageNumber('eventTypes', current));
    setPagination({
      ...pagination,
      page: current,
      pageCount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };
  const fetchEventTypes = (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllEventTypesByQuery(
        sortField,
        sortOrder,
        searchQueryData,
        pagination,
      ),
    );
  };

  const DeleteEventTypes = (id) => {
    dispatch(deleteEventTypesById(id));
  };

  return (
    <div>
      <EventTypesComponent
        allEventTypesData={listData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        DeleteEventTypes={(id) => {
          DeleteEventTypes(id);
        }}
        handleTableChange={handleTableChange}
        fetchEventTypes={(searchValues) => {
          fetchEventTypes(searchValues);
        }}
        sortParams={sortParams}
        searchValues={searchValues}
      />
    </div>
  );
};
export default EventTypesContainer;

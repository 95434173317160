import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import CompaniesComponent from '../../../Components/AdminComponents/Companies';
import {
  deleteCompaniesById,
  getAllCompaniesByQuery,
  getUserDetails,
} from '../../../Store/Actions';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';

const CompaniesContainer = () => {
  const dispatch = useDispatch();
  const {allCompaniesQueryData} = useSelector(({companies}) => companies);
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.companies,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.companies,
  );

  const [companiesData, setCompaniesData] = useState();
  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pagecount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  const searchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.companies,
  );

  useEffect(() => {
    if (allCompaniesQueryData) {
      setCompaniesData(allCompaniesQueryData?.data?.companies);
      setPagination({
        ...pagination,
        page: allCompaniesQueryData?.data?.currentPage,
        totalPages: allCompaniesQueryData?.data?.totalPage,
        totalCount: allCompaniesQueryData?.data?.totalCount,
      });
    } else {
      fetchCompanies();
    }
  }, [allCompaniesQueryData]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchCompanies(searchValues);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  useEffect(() => {
    if (searchValues) {
      dispatch(setPageNumber('companies', 1));
      setPagination({
        ...pagination,
        page: 1,
      });
      setPaginationAndSortChanged(true);
    }
  }, [searchValues]);

  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('companies', pageSize));
    dispatch(setPageNumber('companies', current));
    setPagination({
      ...pagination,
      page: current,
      pagecount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };

  const fetchCompanies = (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';

    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllCompaniesByQuery(pagination, sortField, sortOrder, searchQueryData),
    );
  };
  const deleteCompanies = (id) => {
    dispatch(deleteCompaniesById(id));
  };

  return (
    <div>
      <CompaniesComponent
        allCompaniesData={companiesData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        handleTableChange={handleTableChange}
        sortParams={sortParams}
        fetchCompanies={(searchValues) => {
          fetchCompanies(searchValues);
        }}
        deleteCompanies={(id) => {
          deleteCompanies(id);
        }}
        searchValues={searchValues}
      />
    </div>
  );
};

export default CompaniesContainer;

import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Input, Row} from 'antd';
import MyEventCard from './../index';
import SectionLayout from './../../../Layout/sectionLayout';
import {
  DefaultListingViewMoreCount,
  EventRegistrationStatus,
  EventRegistrationType,
  ToBePaidStatusFilterValue,
  myEventsCategoryOptions,
} from './../../../../../Util/commonUtility';
import {getAllMyEventsCart} from '../../../../../Store/Actions/events';
import DownArrowSvg from '../../../../../Assets/svgComponents/DownArrowSvg';
import useDebouncedCallback from '../../../../../hooks/useDebounce';
import {useDispatch} from 'react-redux';
import CommonAddNewListingWithFilter from '../../../Common_AddNewListing_with_Filter';
import EventTypeFilter from '../../../EventTypeFilter';

const MyEventListingToBePaid = ({formattedEventData, setUserCredit}) => {
  const dispatch = useDispatch();
  const [searchValues, setSearchValues] = useState('');
  const [selectedYearValue, setSelectedYearValue] = useState('');
  const [eventType, setEventType] = useState(EventRegistrationType.regular);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const filterEvents = (events, type) => {
    return events?.filter((event) => {
      if (type === EventRegistrationType.regular) {
        return event?.attendees?.some(
          (attendee) => attendee?.status === EventRegistrationStatus.TO_BE_PAID,
        );
      } else if (type === EventRegistrationType.sponsorship) {
        return event?.sponsors?.some(
          (sponsor) => sponsor?.status === EventRegistrationStatus.TO_BE_PAID,
        );
      } else if (type === 'both') {
        return (
          event?.attendees?.some(
            (attendee) =>
              attendee?.status === EventRegistrationStatus.TO_BE_PAID,
          ) &&
          event?.sponsors?.some(
            (sponsor) => sponsor?.status === EventRegistrationStatus.TO_BE_PAID,
          )
        );
      }
      return false;
    });
  };

  useEffect(() => {
    if (formattedEventData) {
      const filtered = filterEvents(formattedEventData, eventType);
      setFilteredEvents(filtered);
    } else {
      setFilteredEvents(null);
    }
  }, [eventType, formattedEventData]);

  const onSearch = () => {
    dispatch(
      getAllMyEventsCart(
        ToBePaidStatusFilterValue,
        setUserCredit,
        searchValues,
      ),
    );
  };

  //2. Search On Change:
  const searchOnChange = useDebouncedCallback((e) => {
    let search = e.target.value;
    if (search) {
      setSearchValues(e.target.value);
    } else {
      setSearchValues('');
    }
  }, 1500);

  useEffect(() => {
    dispatch(
      getAllMyEventsCart(
        ToBePaidStatusFilterValue,
        setUserCredit,
        searchValues,
      ),
    );
  }, [searchValues]);

  // const onViewMore = () => {
  //   setPage((prev) => {
  //     const newPage =
  //       prev + DefaultListingViewMoreCount?.eventsListingViewMoreCount;
  //     dispatch(
  //       getAllMyEventsCart(
  //         ToBePaidStatusFilterValue,
  //         newPage,
  //         setTotalItems,
  //         setUserCredit,
  //         searchValues,
  //         yearValue,
  //       ),
  //     );
  //     return newPage;
  //   });
  // };

  const handleYearFilter = (yearValue) => {
    if (!yearValue) {
      dispatch(
        getAllMyEventsCart(
          ToBePaidStatusFilterValue,
          setUserCredit,
          searchValues,
        ),
      );
      setSelectedYearValue('');
    } else {
      dispatch(
        getAllMyEventsCart(
          ToBePaidStatusFilterValue,
          setUserCredit,
          searchValues,
          yearValue,
        ),
      );
      setSelectedYearValue(yearValue);
    }
  };

  return (
    <React.Fragment>
      <SectionLayout>
        <Row gutter={[0, 32]}>
          <Col span={24}>
            <CommonAddNewListingWithFilter
              selectedYearValue={selectedYearValue}
              addNew={false}
              usedForMyEvents={false}
              usedForMyEventsCart={true}
              year
              search
              status={false}
              registrtion_filter={
                <EventTypeFilter setEventType={setEventType} />
              }
              categoryList={myEventsCategoryOptions}
              searchOnChange={searchOnChange}
              onSearch={onSearch}
              handleYearFilter={handleYearFilter}
              placeHolder='Search by event name'
            />
          </Col>
          <Col span={24}>
            <div className='event2-listing-cards'>
              {filteredEvents?.length > 0 ? (
                filteredEvents?.map((event, index) => {
                  const toBePaidSponsor =
                    event?.sponsors?.length > 0
                      ? event?.sponsors?.filter(
                          (sponsor) =>
                            sponsor?.status ===
                            EventRegistrationStatus.TO_BE_PAID,
                        )
                      : [];
                  return (
                    <MyEventCard
                      paid={false}
                      key={event?.id}
                      eventRegistrationType={eventType}
                      eventId={event?.id}
                      attendees={[...(event?.attendees || [])]}
                      chapter={event?.chapter}
                      date={event?.date}
                      eventName={event?.eventName}
                      eventType={event?.eventType}
                      eventInfo={event?.eventInfo}
                      sponsorshipAvailable={event?.sponsorshipAvailable}
                      registration_status={event?.registration_status}
                      status={event?.status}
                      receiptData={event}
                      usedForMyEvents={true}
                      sponsors={event?.sponsors}
                      refundLastDate={event?.refund_last_date}
                      toBePaidSponsor={toBePaidSponsor}
                    />
                  );
                })
              ) : (
                <Alert
                  message='No Events Available'
                  className='no-jobs-alert'
                  type='info'
                  showIcon
                />
              )}
            </div>
          </Col>
          {/* <Col span={24}>
            {totalItems > formattedEventData?.length && (
              <div className='view-more-container'>
                <Button
                  className='view-more-button'
                  id='myProfileMyEvents-viewMore-button'
                  onClick={onViewMore}>
                  <span className='view-more-text'>View More Events</span>
                  <DownArrowSvg />
                </Button>
              </div>
            )}
          </Col> */}
        </Row>
      </SectionLayout>
    </React.Fragment>
  );
};

export default MyEventListingToBePaid;

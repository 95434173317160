import React, {useEffect, useState} from 'react';
import {Button, Card, DatePicker, Form, Input, Select} from 'antd';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {
  NoEmptySpaceInput,
  OnlyAlphabetInputValidator,
  validateCompanyName,
  validateEmailInput,
  validatePhoneNumber,
} from '../../../../Util/commonUtility';
import CommonEditor from '../../../CommonComponents/TextEditor';
const SponsorsFormComponent = (props) => {
  const [submit, setSubmit] = useState(false);
  const [editorCommentState, setEditorCommentState] = useState(`<p></p>`);

  const [submitLabel, setSubmitLabel] = useState(
    ApplicationString['common.button.submitForm'],
  );
  const [form] = Form.useForm();
  let Navigate = useNavigate();
  const {postSponsors, EditData, updateSponsors, fetchSponsorById} = props;
  const {id} = useParams();

  useEffect(() => {
    if (id) {
      fetchSponsorById(id);
      setSubmitLabel(ApplicationString['common.button.updateForm']);
    }
  }, []);

  useEffect(() => {
    if (id) {
      EditData &&
        form.setFieldsValue({
          ...EditData,
        });
      setEditorCommentState(EditData?.comments);
    }
  }, [EditData]);

  const onFinish = (values) => {
    const Data = {
      ...values,
      company_name: values?.company_name ? values?.company_name : undefined,
      comments: editorCommentState,
    };
    if (id) {
      updateSponsors(Data, EditData.id);
      // form.resetFields();
      // Navigate('/admin/sponsors');
    } else {
      postSponsors(Data);
      // form.resetFields();
      // Navigate('/admin/sponsors');
    }
    setSubmit(true);
  };

  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };
  const onReset = () => {
    form.resetFields();
    setEditorCommentState(`<p></p>`);
  };
  const backToSponsor = () => {
    Navigate('/admin/sponsors');
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          onFinish={onFinish}
          layout='vertical'
          className='two-column-form'
          onFinishFailed={onFinishFailed}
          initialValues={''}
          autoComplete='off'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Company Name'
                name='company_name'
                rules={[
                  {
                    validator: (_, value) =>
                      validateCompanyName(value, 'Company name'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter company name'
                />
              </Form.Item>

              <Form.Item
                label='Title'
                name='title'
                rules={[
                  {
                    validator: (_, value) => NoEmptySpaceInput(value, 'Title'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter sponsor title'
                />
              </Form.Item>
            </div>
            <div className='form-column'>
              <Form.Item
                label='Email'
                name='email'
                rules={[
                  {
                    required: true,
                    message: 'Email should not be empty',
                  },
                  {
                    validator: (_, value) => validateEmailInput(value, 'Email'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter sponsor email'
                />
              </Form.Item>

              <Form.Item
                label='Phone Number'
                name='phone_number'
                rules={[
                  {
                    validator: (_, value) =>
                      validatePhoneNumber(value, 'Phone number'),
                  }, // Custom validator
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter sponsor phone number'
                />
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Name'
                name='name'
                rules={[
                  {
                    validator: (_, value) =>
                      validateCompanyName(value, 'Sponsor name'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter sponsor name'
                />
              </Form.Item>
              <Form.Item
                label='Extension'
                name='extension'
                rules={[
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Extension'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter extension'
                />
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item label='Comments' name='comments'>
                <CommonEditor
                  value={editorCommentState}
                  onChange={setEditorCommentState}
                  text={'comments'}
                  placeholderValue={'comments'}
                />
              </Form.Item>
            </div>
          </div>

          <div className='button-container'>
            <Button
              className='common-submit-login common-form-submit common-form-cancel'
              id='sponsorComponent-button-back'
              onClick={() => {
                backToSponsor();
              }}>
              {ApplicationString['common.button.backForm']}
            </Button>
            <div className='form-flex-button'>
              <Button
                type='primary'
                htmlType='submit'
                id='submitComponent-button-submit'
                //disabled={submit ? true : false}
                className='common-submit-login common-form-submit'>
                {submitLabel}
              </Button>
              <Button
                className='common-submit-login common-form-submit common-form-cancel'
                htmlType='button'
                id='sponsorComponent-button-reset'
                onClick={onReset}>
                {ApplicationString['dynamicSearch.button.reset']}
              </Button>{' '}
            </div>
          </div>
        </Form>
      </div>{' '}
    </div>
  );
};

export default SponsorsFormComponent;

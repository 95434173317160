import React from 'react';
import './style.css';
import {Button, Col, Divider, Form, Input, Row} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {applicationConfig} from '../../../Util/config';
import ReCAPTCHA from 'react-google-recaptcha';
import {signIn} from '../../../Store/Actions';
import {useThrottle} from '../../../hooks/useThrottle';
import {
  NoEmptySpaceInput,
  UserPasswordValidator,
  validateEmailInput,
} from './../../../Util/commonUtility';

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginForm] = Form.useForm();
  const onLogIn = (values) => {
    dispatch(signIn(values, navigate));
  };
  const debouncedOnLogIn = useThrottle(onLogIn, 3000);
  return (
    <React.Fragment>
      <div className='login-form'>
        <Form
          name='login'
          // onFinish={onLogIn}
          onFinish={debouncedOnLogIn}
          initialValues={''}
          form={loginForm}>
          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                message: 'Please fill out email address!',
              },
              {
                validator: (_, value) => validateEmailInput(value, 'Email'),
              },
            ]}>
            <Input
              prefixCls='common-input-user-login'
              placeholder='Email address'
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: 'Please fill out password!',
              },
            ]}
            hasFeedback>
            <Input.Password
              prefixCls='common-input-user-login'
              placeholder='Password'
            />
          </Form.Item>

          <div
            className='password-options-login'
            id='login-forgotPassword-link'>
            <div>
              <div className='signup-link-login'>Not a member? </div>
              <div className='signup-link-description'>
                <Link id='login-freeAccount-link' to={'../register'}>
                  Click here to create a free account.
                </Link>
              </div>
            </div>
            <Link to={'../resetPassword'}>Forgot my password</Link>
          </div>
          <Form.Item prefixCls='re-captcha-login'>
            <p className='re-captcha-label-login'>Complete CAPTCHA</p>
            <Form.Item
              name='captcha'
              noStyle
              rules={[
                {
                  required: true,
                  message: 'Please click on the CAPTCHA above',
                },
              ]}>
              <ReCAPTCHA
                sitekey={applicationConfig.CAPTCHA_SITE_KEY}></ReCAPTCHA>
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Button
              id='login-submit-button'
              type='primary'
              htmlType='submit'
              prefixCls='submit-login-page'
              className='login-button'>
              Login
            </Button>
          </Form.Item>
        </Form>
        <div className='captcha'>{/* Add your captcha component here */}</div>
      </div>
    </React.Fragment>
  );
};

export default LoginForm;

import React, {useEffect, useState} from 'react';
import AdminSpecialPaymentComponent from '../../../Components/AdminComponents/AdminSpecialPaymentComponent';
import {useDispatch, useSelector} from 'react-redux';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';
import {getAllSpecialPaymentDetails} from '../../../Store/Actions/specialPayment';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';

const AdminSpecialPaymentContainer = () => {
  const dispatch = useDispatch();

  // search value data
  const searchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.special_payment,
  );

  // special payment data
  const {specialPaymentDetailsData} = useSelector(
    ({specialPayment}) => specialPayment,
  );
  const [listData, setListData] = useState();

  // pagination data
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.specialPayment,
  );
  const memorizedPage = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.specialPayment,
  );
  const [pagination, setPagination] = useState({
    page: memorizedPage || DefaultPaginationValues.PAGE,
    pageCount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  // fetch special payment data
  useEffect(() => {
    if (specialPaymentDetailsData) {
      setListData(specialPaymentDetailsData);
      setPagination({
        ...pagination,
        page: specialPaymentDetailsData?.currentPage,
        totalPages: specialPaymentDetailsData?.totalPage,
        totalCount: specialPaymentDetailsData?.totalCount,
      });
    } else {
      dispatch(getAllSpecialPaymentDetails(pagination));
    }
  }, [specialPaymentDetailsData]);

  // useEffect for pagination and sorting
  useEffect(() => {
    if (paginationAndSortChanged) {
      const sortField = sortParams.field || '';
      const sortOrder = sortParams.order || '';
      const searchQueryData = queryString(searchValues);
      dispatch(
        getAllSpecialPaymentDetails(
          pagination,
          sortOrder,
          sortField,
          searchQueryData,
        ),
      );
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  // handle pagination
  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };
  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('specialPayment', pageSize));
    dispatch(setPageNumber('specialPayment', current));
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    setPagination({
      ...pagination,
      page: current,
      pageCount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };

  // search value useEffect
  useEffect(
    (paginationAndSortChanged) => {
      dispatch(setPageNumber('specialPayment', 1));
      setPagination({
        ...pagination,
        page: 1,
      });
      setPaginationAndSortChanged(true);
    },
    [searchValues],
  );

  // filter handle search
  const handleSearch = (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllSpecialPaymentDetails(
        pagination,
        sortOrder,
        sortField,
        searchQueryData,
      ),
    );
  };

  return (
    <AdminSpecialPaymentComponent
      allSpecialPaymentDetails={listData?.specialPayments}
      handlePaginationChange={handlePaginationChange}
      handleTableChange={handleTableChange}
      paging={pagination}
      sortParams={sortParams}
      handleSearch={handleSearch}
      searchValues={searchValues}
    />
  );
};

export default AdminSpecialPaymentContainer;

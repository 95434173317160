import React from 'react';

const ClockTimeSVG = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={12}
      height={12}
      viewBox='0 0 10 10'
      fill='none'>
      <path
        d='M4.78918 2.42269V4.77173L5.48789 5.94625M8.98143 4.77173C8.98143 7.10696 7.10452 9 4.78918 9C2.47386 9 0.596924 7.10696 0.596924 4.77173C0.596924 2.43652 2.47386 0.543457 4.78918 0.543457C7.10452 0.543457 8.98143 2.43652 8.98143 4.77173Z'
        stroke='#2F57A4'
        strokeWidth={0.939616}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ClockTimeSVG;

export const ClockTimeSVG2 = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={16}
      viewBox='0 0 10 10'
      fill='none'>
      <path
        d='M4.78918 2.42269V4.77173L5.48789 5.94625M8.98143 4.77173C8.98143 7.10696 7.10452 9 4.78918 9C2.47386 9 0.596924 7.10696 0.596924 4.77173C0.596924 2.43652 2.47386 0.543457 4.78918 0.543457C7.10452 0.543457 8.98143 2.43652 8.98143 4.77173Z'
        stroke='#2F57A4'
        strokeWidth={0.939616}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

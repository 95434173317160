import React from 'react';
import './style.css';
import {Link, useNavigate} from 'react-router-dom';
import {setChapterById} from '../../../Store/Actions';
import {useDispatch} from 'react-redux';
import linkedInLogo from '../../../Assets/linkedInLogo.png';

const InitialFooter = ({chapterList, chapterLogo}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChapterClick = (chapterId, chapterName) => {
    dispatch(setChapterById(chapterId));
    navigate(`/chapter/${chapterName}`);
  };

  const visibleChapters = chapterList?.filter((chapter) => {
    return chapter?.is_visible_on_map === true;
  });

  return (
    <div className='initial-container'>
      <div className='column  icon-container-footer'>
        <img
          src={chapterLogo}
          className='footer-logo-img'
          crossOrigin='anonymous'
        />
      </div>
      <div className='column info-container'>
        <p className='column-heading'>Events</p>
        <div className='links-container'>
          {visibleChapters &&
            visibleChapters.map((item) => {
              return (
                <div
                  id={`newsletter-navigation-button-${item.id}`}
                  key={item.name}
                  className='icon-container-footer'>
                  <li>
                    <a
                      onClick={() =>
                        handleChapterClick(item.id, item.normalized_name)
                      }>
                      {item?.name}
                    </a>
                  </li>
                </div>
              );
            })}
        </div>
        <div className='icon-container-footer'>
          <li>
            <Link to={'/'}>PBSS- International</Link>
          </li>
        </div>
      </div>
      {/* <div className='column info-container'>
        <p className='column-heading'>{""}</p>
        <div className='links-container'>
          {chapterList &&
            chapterList.slice(4).map((item) => {
              return (
                <div key={item.name} className='icon-container-footer'>
                  <li>
                    <a onClick={() => handleChapterClick(item.id, item.normalized_name)}>
                      {item?.name}
                    </a>
                  </li>
                </div>
              );
            })}
          <div className='icon-container-footer'>
            <li>
              <Link to={'/'}>PBSS- International</Link>
            </li>
          </div>
        </div>
      </div> */}
      {/* <div className='column info-container'>
        <p className='column-heading'>About</p>
        <div className='links-container'>
          <div
            className='icon-container-footer'
            id='home-iconContainer-button_1'>
            <li>
              <Link to={'/aboutUs'}>About Us</Link>
            </li>
          </div>
          <div
            className='icon-container-footer'
            id='home-iconContainer-button_2'>
            <li>
              <Link to={'/contactUs'}>Contact Us</Link>
            </li>
          </div>
        </div>
      </div> */}
      <div className='column info-container'>
        <p className='column-heading'>Links</p>
        <div className='links-container'>
          <div
            className='icon-container-footer'
            id='home-iconContainer-button_3'>
            <li>
              <Link to={'/aboutUs'}>About Us</Link>
            </li>
          </div>
          <div
            className='icon-container-footer'
            id='home-iconContainer-button_3'>
            <li>
              <Link to={'/contactUs'}>Contact Us</Link>
            </li>
          </div>
          <div
            className='icon-container-footer'
            id='home-iconContainer-button_3'>
            <li>
              <Link to={'/jobs/approved'}>Jobs</Link>
            </li>
          </div>
          <div
            className='icon-container-footer'
            id='home-iconContainer-button_4'>
            <li>
              <Link to={'/ads/approved'}>Ads</Link>
            </li>
          </div>
        </div>
      </div>
      <div className='column info-container'>
        <div className='subscribe-container'>
          <div className='subscribe-form'>
            <a
              target='_blank'
              rel='noreferrer'
              className='subscribe-btn'
              href={'https://eepurl.com/iGGqQc'}
              id='home-newsletter-subscribe-button'>
              Subscribe to Newsletter
            </a>
          </div>
          <span className='form-privacy'>
            By subscribing, you agree to our Privacy Policy.
          </span>
          <a
            href={'https://www.linkedin.com/company/pbss-international/'}
            target='_blank'
            rel='noreferrer'
            className='footer-logo-icon'>
            <img src={linkedInLogo} className='footer-logo' />
          </a>
        </div>
      </div>
    </div>
  );
};

export default InitialFooter;

import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SOMETHING_WENT_WRONG,
  SHOW_MESSAGE,
  GET_ALL_EVENTTYPES,
  ADD_EVENTTYPES_SUCCESS,
  UPDATE_EVENTTYPES_BY_ID,
  DELETE_EVENTTYPES_BY_ID,
  FETCH_EVENTTYPES_BY_ID,
  GET_ALL_EVENTTYPES_BY_QUERY,
  POST_EVENT_TYPE_SUCCESS,
  UPDATE_EVENT_TYPE_SUCCESS,
  DELETE_EVENT_TYPE_SUCCESS,
  GET_ALL_EVENTTYPES_BY_QUERY_SEARCH,
} from '../../Constant/ActionTypes';

import Urls from '../../Constant/Urls';
import {httpClient} from '../../Api/client';
import {commonSortOrder, handleJwtExpireMsg} from '../../Util/commonUtility';

export const getAllEventTypesByQuery = (
  sortField,
  sortOrder,
  queryString,
  pagination,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.EVENTTYPES_GET_ALL}?page=${pagination.page}&pageCount=${
          pagination.pageCount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${queryString}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_EVENTTYPES_BY_QUERY, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllEventTypesByQuerySearch = (
  sortField,
  sortOrder,
  queryString,
  pagination,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.EVENTTYPES_GET_ALL}?page=${pagination.page}&pageCount=${
          pagination.pageCount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${queryString}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: GET_ALL_EVENTTYPES_BY_QUERY_SEARCH,
          payload: data?.data,
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllEventTypes = () => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(`${Urls.EVENTTYPES_GET_ALL}`);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_EVENTTYPES, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addEventTypes = (adData, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.post(Urls.EVENTTYPES_ADD, adData);
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: ADD_EVENTTYPES_SUCCESS, payload: response?.data});
        navigate && navigate('/admin/eventTypes');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const fetchEventTypeById = (eventTypesId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.get(
        Urls.EVENTTYPES_FETCH_BY_ID.replace('#{eventTypesId}', eventTypesId),
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_EVENTTYPES_BY_ID, payload: response?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateEventTypeById = (updatedAdData, eventTypesId, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.patch(
        Urls.EVENTTYPES_UPDATE_BY_ID.replace('#{eventTypesId}', eventTypesId),
        updatedAdData,
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: UPDATE_EVENTTYPES_BY_ID, payload: response.data});
        navigate && navigate('/admin/eventTypes');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteEventTypesById = (eventTypesId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.delete(
        Urls.EVENTTYPES_DELETE_BY_ID.replace('#{eventTypesId}', eventTypesId),
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: DELETE_EVENTTYPES_BY_ID, payload: eventTypesId});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

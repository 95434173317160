import React, {useEffect, useState} from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import ChaptersComponent from '../../../Components/AdminComponents/Chapters';
import {useDispatch, useSelector} from 'react-redux';
import {
  deleteChapterById,
  getAllChaptersByQueryString,
  getUserDetails,
} from '../../../Store/Actions';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';
const ChaptersContainer = () => {
  const dispatch = useDispatch();
  const {allChaptersQueryData} = useSelector(({chapters}) => chapters);
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.chapters,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.chapters,
  );
  const [listData, setListData] = useState();
  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pageCount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });

  const [sortParams, setSortParams] = useState({
    field: 'order_number',
    order: 'ascend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);
  const searchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.chapters,
  );

  useEffect(() => {
    if (allChaptersQueryData?.forMaster) {
      setListData(allChaptersQueryData?.forMaster?.data?.chapters);
      setPagination({
        ...pagination,
        page: allChaptersQueryData?.forMaster?.data?.currentPage,
        totalPages: allChaptersQueryData?.forMaster?.data?.totalPage,
        totalCount: allChaptersQueryData?.forMaster?.data?.totalCount,
      });
    } else {
      fetchChapters();
    }
  }, [allChaptersQueryData?.forMaster?.data?.chapters]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchChapters(searchValues);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  // useEffect(() => {
  //   if (allChaptersQueryData) {
  //     fetchChapters();
  //   }
  // }, [
  //   pagination.page,
  //   pagination.pageCount,
  //   sortParams.field,
  //   sortParams.order,
  // ]);

  useEffect(() => {
    if (searchValues) {
      dispatch(setPageNumber('chapters', 1));
      setPagination({
        ...pagination,
        page: 1,
      });
      setPaginationAndSortChanged(true);
    }
  }, [searchValues]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };

  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('chapters', pageSize));
    dispatch(setPageNumber('chapters', current));
    setPagination({
      ...pagination,
      page: current,
      pageCount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };

  const fetchChapters = async (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllChaptersByQueryString(
        sortField,
        sortOrder,
        searchQueryData,
        pagination,
      ),
    );
  };

  const DeleteChapters = async (id) => {
    dispatch(deleteChapterById(id));
  };

  return (
    <div>
      <ChaptersComponent
        allChaptersData={listData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        DeleteChapters={(id) => {
          DeleteChapters(id);
        }}
        fetchChapters={(searchValues) => {
          fetchChapters(searchValues);
        }}
        handleTableChange={handleTableChange}
        sortParams={sortParams}
        searchValues={searchValues}
      />
    </div>
  );
};
export default ChaptersContainer;

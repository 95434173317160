import React, {useEffect, useState} from 'react';
import './style.css';
import {Button, Card, Col, DatePicker, Modal, Row, Select, Tag} from 'antd';
import {Option} from 'antd/es/mentions';
import {FilterOutlined} from '@ant-design/icons';
import SectionLayout from '../Layout/sectionLayout';
import ChapterEventCard from './EventCard2.0';
import {useSelector, useDispatch} from 'react-redux';
import {
  getAllEvents,
  getAllUserEvents,
  getEventsByChapters,
} from '../../../Store/Actions/events';
import dayjs from 'dayjs';
import moment from 'moment';
import {createSelectionOptionsUtil} from '../../../Util/commonUtility';
import {getAllChapters, getAllEventTypes} from '../../../Store/Actions';
import {LoadMoreSize} from './../../../Util/commonUtility';
import RecentOpenings from '../RecentOpenings/jobs';
import RecentOpeningsJobs from '../RecentOpenings/jobs';
import RecentOpeningsAds from '../RecentOpenings/ads';
import filter_by from '../../../Assets/filter-by.svg';
import DateSvg from '../../../Assets/svgComponents/DateSvg';
import DownArrowSvg from '../../../Assets/svgComponents/DownArrowSvg';
import CommonTitleWithFilters from '../CommonTitleWithFilters';
import {useNavigate} from 'react-router-dom';

const Event2Listing = ({
  countLoadMoreData,
  chaptersList,
  eventTypeList,
  handleEventTypeFilter,
  handleChapterFilter,
  filteredEvents,
  usedForChapPage,
  totalEventCount,
  handleLoadMore,
  selectedChapterValue,
  selectedEventTypeValue,
  selectedDateValue,
  selectedYearValue,
  isChapSelect,
  dateToggle,
  dateOrderHandler,
  eventTypeBool = true,
}) => {
  let dispatch = useDispatch();
  const Navigate = useNavigate();
  const [isRegistrationModalClosed, setIsRegistrationModalClosed] =
    useState(false);
  const [clickedEventID, setClickedEventID] = useState(null);

  // useEffect(() => {
  //   dispatch(
  //     getAllUserEvents(
  //       selectedChapterValue,
  //       selectedEventTypeValue,
  //       selectedDateValue,
  //     ),
  //   );
  // }, []);

  const registrationClosedModalOkHandler = () => {
    Navigate(`/eventDetails/${clickedEventID}`);
    setIsRegistrationModalClosed(false);
  };

  const registrationClosedModalCancelHandler = () => {
    setIsRegistrationModalClosed(false);
  };

  const content = (
    <div
      className={`event2-listing-container ${
        usedForChapPage && 'event2-listing-container-margin-for-chapPage'
      } `}>
      <Modal
        open={isRegistrationModalClosed}
        centered
        title='Registration Closed'
        onOk={registrationClosedModalOkHandler}
        onCancel={registrationClosedModalCancelHandler}>
        <p>
          The registrations for this event is closed, please reach out to the
          admin at{' '}
          <a
            href='mailto:info@pbss.com'
            target='_blank'
            rel='noopener noreferrer'>
            info@pbss.com
          </a>{' '}
          for more information.
        </p>
      </Modal>
      <Row gutter={[16, 30]} className='pbssMainContainerRowEventListing'>
        <Col span={24}>
          <Row
            className={`${
              !usedForChapPage && 'upcoming-event-card-mobile-row'
            }`}>
            <CommonTitleWithFilters
              title={'Upcoming Events'}
              handleChapterFilter={handleChapterFilter}
              handleEventTypeFilter={handleEventTypeFilter}
              newChaptersList={chaptersList}
              eventTypeList={eventTypeList}
              eventTypeSelect={eventTypeBool}
              chapterSelect={isChapSelect ? false : true}
              dateSelect={true}
              usedForChapPage={usedForChapPage}
              selectedEventTypeValue={selectedEventTypeValue}
              selectedChapterValue={selectedChapterValue}
              selectedYearValue={selectedYearValue}
              dateToggle={dateToggle}
              dateOrderHandler={dateOrderHandler}
            />
          </Row>
        </Col>
        <Col
          span={24}
          className={`${
            !usedForChapPage && 'upcoming-event-card-mobile-background-column'
          }`}>
          <Row
            className={`${
              !usedForChapPage && 'upcoming-event-card-mobile-row'
            } upcoming-event-card-mobile-row-2`}>
            <div className='events-new-listing-cards'>
              {filteredEvents?.length > 0 ? (
                filteredEvents?.map((event, index) => {
                  return (
                    <ChapterEventCard
                      setIsRegistrationModalClosed={
                        setIsRegistrationModalClosed
                      }
                      status={event?.status}
                      usedForChapPage={usedForChapPage}
                      isExpand={true}
                      key={event?.key}
                      id={event?.key}
                      sponsorshipAvailable={event?.sponsorshipAvailable}
                      eventName={event?.eventName}
                      eventDetails={event?.eventDetails}
                      date={event?.date}
                      dateObj={event?.dateObject}
                      speakers={event?.speakers}
                      registerText={
                        Number(event?.status) !== 2
                          ? 'Register Now'
                          : 'Registration Closed'
                      }
                      price={event?.price}
                      location={event?.location}
                      moreDetails={false}
                      descriptionLabel={'Speaker'}
                      setClickedEventID={setClickedEventID}
                      eventType={event?.eventType}
                      chapter={event?.eventChapter}
                    />
                  );
                })
              ) : (
                <div>
                  <Row>
                    <Col span={24}>
                      <Card>
                        <div className='not-found-event-heading'>
                          No Event Found
                        </div>
                        <div className='not-found-event-description'>
                          We are sorry, but it seems that there are no events
                          matching your filter criteria. Please try adjusting
                          the filters and searching again.
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </Row>
        </Col>
        {/* {totalEventCount > filteredEvents?.length && (
          <Col span={24}>
            <div className='view-more-container'>
              <Button
                id='home-view-more-events-button'
                className='view-more-button'
                onClick={handleLoadMore}>
                <span className='view-more-text'>View More Events</span>
                <DownArrowSvg />
              </Button>
            </div>
          </Col>
        )} */}
      </Row>
    </div>
  );

  return (
    <React.Fragment>
      {usedForChapPage ? (
        content
      ) : (
        <SectionLayout extendClass='event-card-mobile'>{content}</SectionLayout>
      )}
      {/* <RecentOpeningsJobs
          title={'Recent Job Openings'}
          buttonName={'View Details'}
          data={allApprovedJobsData}
          flex={true}
          handleNext={handleNext}
          handlePrev={handlePrev}
        />
        <RecentOpeningsAds
          title={'Ads'}
          buttonName={'Visit Now'}
          data={allApprovedAdsData}
          flex={true}
          handleNextAds={handleNextAds}
          handlePrevAds={handlePrevAds}
        /> */}
    </React.Fragment>
  );
};
export default Event2Listing;

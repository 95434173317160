import {
  GET_ALL_ADS,
  POST_ADS_PAYMENT,
  UPDATE_AD_BY_ID,
  DELETE_AD_BY_ID,
  FETCH_AD_BY_ID,
  FETCH_ADS_BY_USERID,
  ADD_AD,
  GET_ALL_ADS_APPROVED,
  UPDATE_AD_STATUS_BY_ID,
  GET_ALL_ACCOUNTS,
  POST_ADS_PAYMENT_SUCCESS,
  RESET_ADS_PAYMENT,
  GET_ALL_ADS_WITHOUT_PAGINATION,
} from '../../Constant/ActionTypes';

const initialState = {
  allAds: false,
  isCreateAd: false,
  isUpdateAd: false,
  fetchEditData: false,
  isUpdateAdsStatus: false,
  getAdsDataByUser: false,
  getApprovedAds: false,
  allAdsWithoutPagination: false,
  adsPayment: false,
  adsPaymentSuccess: false,
};

const AdsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ADS:
      return {
        ...state,
        allAds: action.payload,
        isCreateAd: false,
        isUpdateAd: false,
        isUpdateAdsStatus: false,
      };
    case GET_ALL_ADS_WITHOUT_PAGINATION:
      return {
        ...state,
        allAdsWithoutPagination: action.payload,
        isCreateJob: false,
        isUpdateJob: false,
        isUpdateJobStatus: false,
      };
    case GET_ALL_ACCOUNTS:
      return {
        ...state,
        allAccountAds: action.payload,
        isCreateAd: false,
        isUpdateAd: false,
        isUpdateAdsStatus: false,
      };
    case FETCH_AD_BY_ID:
      return {
        ...state,
        fetchEditData: action.payload,
      };
    case GET_ALL_ADS_APPROVED:
      return {
        ...state,
        getApprovedAds: action.payload,
        isCreateJob: false,
        isUpdateJob: false,
        isUpdateAdsStatus: false,
      };
    case FETCH_ADS_BY_USERID:
      return {
        ...state,
        getAdsDataByUser: action.payload,
      };
    case ADD_AD:
      return {
        ...state,
        isCreateAd: true,
        allAds: false,
        getApprovedAds: false,
        getAdsDataByUser: false,
        isUpdateAdsStatus: false,
      };
    case UPDATE_AD_BY_ID:
      return {
        ...state,
        isUpdateAd: true,
        allAds: false,
        fetchEditData: false,
        getAdsDataByUser: false,
      };
    case DELETE_AD_BY_ID:
      return {
        ...state,
        allAds: false,
        getApprovedAds: false,
      };
    case UPDATE_AD_STATUS_BY_ID:
      return {
        ...state,
        isUpdateAd: true,
        isUpdateAdsStatus: true,
        allAds: false,
        getApprovedAds: false,
      };
    case POST_ADS_PAYMENT:
      return {
        ...state,
        adsPayment: action.payload,
        getAdsDataByUser: false,
      };
    case POST_ADS_PAYMENT_SUCCESS:
      return {
        ...state,
        adsPaymentSuccess: true,
        getAdsDataByUser: false,
      };
    case RESET_ADS_PAYMENT:
      return {
        ...state,
        adsPayment: false,
        adsPaymentSuccess: false,
      };
    default:
      return state;
  }
};

export default AdsReducer;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import {
  getAllRegistrationByQueryString,
  getRegistrationById,
} from '../../../Store/Actions/eventRegister';
import EventRegistrationsComponent from '../../../Components/AdminComponents/EventRegistration';
import {useParams} from 'react-router-dom';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';
import EventRefundComponent from '../../../Components/AdminComponents/EventRefund';
import {getRefundByEventId} from './../../../Store/Actions/eventRefund';

const EventRefundContainer = () => {
  const dispatch = useDispatch();

  const [eventRefundData, setEventRefundData] = useState();
  const [querySearchValues, setQuerySearchValues] = useState();
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.eventRefunds,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.eventRefunds,
  );
  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pageCount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const {allRefundDetails} = useSelector(({eventRefund}) => eventRefund);
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);

  const {id} = useParams();
  const reduxSearchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.event_registrations,
  );
  const reduxSearchValueID = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValueID,
  );
  const searchValues =
    parseInt(id) === reduxSearchValueID ? reduxSearchValues : null;

  useEffect(() => {
    fetchEventRefunds();
  }, [id]);

  useEffect(() => {
    if (allRefundDetails?.data?.eventRegistrations) {
      setEventRefundData(allRefundDetails);
    } else {
      fetchEventRefunds();
    }
  }, [allRefundDetails]);

  useEffect(() => {
    if (eventRefundData) {
      setPagination({
        ...pagination,
        page: eventRefundData?.data?.currentPage,
        totalPages: eventRefundData?.data?.totalPage,
        totalCount: eventRefundData?.data?.totalCount,
      });
    }
  }, [eventRefundData]);

  useEffect(() => {
    if (paginationAndSortChanged) {
      fetchEventRefunds(searchValues);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged]);

  useEffect(() => {
    if (searchValues) {
      dispatch(setPageNumber('eventRefunds', 1));
      setPagination({
        ...pagination,
        page: 1,
      });
      setPaginationAndSortChanged(true);
    }
  }, [searchValues]);

  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('eventRefunds', pageSize));
    dispatch(setPageNumber('eventRefunds', current));
    setPagination({
      ...pagination,
      page: current,
      pageCount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
  };

  const fetchEventRefunds = (searchValues) => {
    setQuerySearchValues(searchValues);
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getRefundByEventId(pagination, sortField, sortOrder, searchQueryData, id),
    );
  };

  return (
    <div>
      <EventRefundComponent
        allEventRefundData={eventRefundData?.data?.eventRegistrations}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        handleTableChange={handleTableChange}
        sortParams={sortParams}
        fetchEventRefunds={(searchValues) => {
          fetchEventRefunds(searchValues);
        }}
        searchValues={searchValues}
      />
    </div>
  );
};

export default EventRefundContainer;

import {SEND_MAil_SUCCESS} from '../../Constant/ActionTypes';

const initialState = {
  allChapters: false,
  sendMailSuccess: false,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_MAil_SUCCESS:
      return {
        ...state,
        sendMailSuccess: action.payload,
        allChapters: false,
      };
    default:
      return state;
  }
};

export default AuthReducer;

import React, {useEffect} from 'react';
import NormalAdsCartComponent from './NormalAdsCartForm';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Row} from 'antd';
import {Elements} from '@stripe/react-stripe-js';
import CommonPaymentCheckoutForm from '../../../CommonComponents/CommonPaymentCheckoutForm';
import SectionLayout from '../../../CommonComponents/Layout/sectionLayout';
import {useParams} from 'react-router-dom';
import './index.css';
import {postAdsPaymentSuccess, resetAdPayment} from '../../../../Store/Actions';
import CommonPageHeroText from '../../../CommonComponents/CommonPageHeroText';
import {createStipePromise} from '../../../../Util/commonUtility';

const AdsCartComponent = ({adData, adFeesOptions}) => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {clientSecret, publishable_key} = useSelector(
    (state) => state?.ads?.adsPayment,
  );

  const stripePromise = createStipePromise(`${publishable_key}`);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    return () => {
      dispatch(resetAdPayment());
    };
  }, []);

  const dispatchSuccess = (id, intent) => {
    dispatch(
      postAdsPaymentSuccess({
        adsId: id,
        paymentIntentId: intent,
      }),
    );
  };

  //  const handleSubmit = async (event) => {
  //    event.preventDefault();

  //    if (!stripe || !elements) {
  //      return;
  //    }
  //    try {
  //      const result = await stripe.confirmPayment({
  //        elements,
  //        redirect: 'if_required',
  //      });
  //      if (result?.paymentIntent?.status === 'succeeded') {
  //        dispatch(showMessage('Payment Successful'));
  //        dispatch(
  //          postAdsPaymentSuccess({
  //            adsId: parseInt(adsId),
  //            paymentIntentId: result?.paymentIntent?.id,
  //          }),
  //        );
  //        navigate(`/ads`);
  //      } else {
  //        dispatch(fetchError('Payment Failed, Please try again'));
  //        navigate(`/ads/cart/${adsId}`);
  //      }
  //    } catch (error) {
  //      dispatch(fetchError('Payment Failed, Please try again'));
  //      navigate(`/ads/cart/${adsId}`);
  //    }
  //  };

  return (
    <React.Fragment>
      <Row gutter={[0, 28]} className='pbssMainContainerRow'>
        <Col span={24}>
          {clientSecret ? (
            <SectionLayout>
              <Elements options={options} stripe={stripePromise}>
                <div className='card-stripe-container'>
                  <CommonPaymentCheckoutForm
                    clientSecret={clientSecret}
                    price={adData?.length > 0 && adData[0]?.ad_fee}
                    DataId={parseInt(id)}
                    resetPayment={resetAdPayment}
                    goBackURL={`/ads/cart/${parseInt(id)}`}
                    dispatchSuccess={(id, intent) => {
                      dispatchSuccess(id, intent);
                    }}
                    successUrl={'/paymentSuccess/ads'}
                  />
                </div>
              </Elements>
            </SectionLayout>
          ) : (
            <NormalAdsCartComponent
              adData={adData}
              adFeesOptions={adFeesOptions}
            />
          )}
          {/* <NormalAdsCartComponent
            adData={adData}
            adFeesOptions={adFeesOptions}
          /> */}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AdsCartComponent;

import React, {useEffect, useState} from 'react';
import './style.css';
import SectionLayout from '../Layout/sectionLayout';
import {Card, Col, Pagination, Row} from 'antd';
import CommonTitleWithFilters from '../CommonTitleWithFilters';
import PastEventsListingCard from './PastEventsListingCard';
import {getAllPastEvents} from '../../../Store/Actions/events';
import {
  DefaultPaginationValuesForPastEvents,
  PageSizeArray,
  createSelectionOptionsUtil,
} from '../../../Util/commonUtility';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultPaginationValues} from './../../../Util/commonUtility';
import CommonAddNewListingWithFilter from '../Common_AddNewListing_with_Filter';
import ComponentTitle from '../CommonComponentTitle';
import {getAllChapters} from '../../../Store/Actions';

const PastEventsListing = ({
  pastEventData,
  pagination,
  handlePaginationChange,
  handleYearFilter,
  totalPastEventsPages,
  selectedYearValue,
  usedForBio,
}) => {
  let dispatch = useDispatch();
  const {allChapters} = useSelector(({chapters}) => chapters);
  const [searchValues, setSearchValues] = useState('');
  const [filterValues, setFilterValues] = useState('');
  const [chaptersList, setChaptersList] = useState([]);

  const onSearch = () => {
    dispatch(
      getAllPastEvents(
        pagination,
        selectedYearValue,
        filterValues,
        searchValues,
      ),
    );
  };

  const searchOnChange = (e) => {
    let search = e.target.value;
    if (search) {
      setSearchValues(e.target.value);
    } else {
      setSearchValues('');
    }
  };

  const handleChange = (value) => {
    if (!value && value !== 0 && value !== -1) {
      dispatch(
        getAllPastEvents(
          pagination,
          selectedYearValue,
          filterValues,
          searchValues,
        ),
      );
      setFilterValues('');
    } else {
      dispatch(
        getAllPastEvents(
          pagination,
          selectedYearValue,
          filterValues,
          searchValues,
        ),
      );
      setFilterValues(value);
    }
  };

  useEffect(() => {
    if (searchValues || filterValues) {
      dispatch(
        getAllPastEvents(
          pagination,
          selectedYearValue,
          filterValues,
          searchValues,
        ),
      );
    }
  }, [searchValues]);

  useEffect(() => {
    if (allChapters) {
      let filteredChaptersForMap = allChapters?.data?.chapters?.filter(
        (chapter) => chapter.is_visible_on_map,
      );
      const chapters = createSelectionOptionsUtil(
        filteredChaptersForMap,
        'id',
        'name',
      );
      setChaptersList([{value: '', label: 'Clear Filter'}, ...chapters]);
    } else {
      dispatch(getAllChapters());
    }
  }, [allChapters, dispatch]);

  return (
    <React.Fragment>
      <SectionLayout>
        <Row gutter={[16, 30]} className='pbssMainContainerRowPastEvent'>
          <Col span={24}>
            <Row className='past-event-container'>
              <ComponentTitle
                hideButton
                title={usedForBio && 'Past Events'}
                textColor={'black'}
                extraClass='past-event-title'
              />
              <CommonAddNewListingWithFilter
                selectedYearValue={selectedYearValue}
                selectedStatusValue={filterValues}
                addNew={false}
                year
                search
                pageName={'Past Event'}
                List={chaptersList}
                searchOnChange={searchOnChange}
                handleChange={handleChange}
                onSearch={onSearch}
                selectPlaceholder={'Chapter'}
                handleYearFilter={handleYearFilter}
                placeHolder='Search by event name'
              />
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <div className='past-events-listing-cards-container'>
                {pastEventData?.length > 0 ? (
                  pastEventData?.map((item, index) => (
                    <PastEventsListingCard
                      key={`${item.chapter_name}-${index}`}
                      name={item.name}
                      date={item.event_date}
                      event_type={item.event_type}
                      event_id={item.key}
                      chapter_name={item.chapter_name}
                    />
                  ))
                ) : (
                  <div>
                    <Row>
                      <Col span={24}>
                        <Card>
                          <div className='not-found-event-heading'>
                            No Past Event Found
                          </div>
                          <div className='not-found-event-description'>
                            We are sorry, but it seems that there are no past
                            events matching your filter criteria. Please try
                            adjusting the filters and searching again.
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </Row>
          </Col>
          {pastEventData?.length > 0 && totalPastEventsPages > 1 ? (
            <Col span={24} className='pagination-past-events-col'>
              <Pagination
                prefixCls='pagination-past-events'
                onChange={handlePaginationChange}
                current={pagination?.page}
                total={pagination?.totalCount}
                pageSizeOptions={PageSizeArray}
                showSizeChanger={true}
                onShowSizeChange={handlePaginationChange}
                pageSize={pagination?.pagecount}
              />
            </Col>
          ) : (
            ''
          )}
        </Row>
      </SectionLayout>
    </React.Fragment>
  );
};

export default PastEventsListing;

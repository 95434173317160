// import * as React from 'react';
// const DateSvg = (props) => (
//   <svg
//     width={15}
//     height={15}
//     viewBox='0 0 16 15'
//     fill='none'
//     xmlns='http://www.w3.org/2000/svg'
//     {...props}>
//     <path
//       id='&#229;&#189;&#162;&#231;&#138;&#182;'
//       fillRule='evenodd'
//       clipRule='evenodd'
//       d='M6.12155 8.33333C6.97364 8.33333 7.4174 9.24334 6.89622 9.827L6.82601 9.89817L3.09253 13.3333H6.34019C6.84041 13.3333 7.2459 13.7064 7.2459 14.1667C7.2459 14.594 6.89627 14.9463 6.44582 14.9944L6.34019 15H1.12461C0.272522 15 -0.171216 14.09 0.349926 13.5063L0.420135 13.4352L4.15363 10H0.905958C0.405743 10 0.000247983 9.62692 0.000247983 9.16667C0.000247983 8.73929 0.349884 8.38708 0.800332 8.33894L0.905958 8.33333H6.12155ZM11.7744 0.833333C12.2747 0.833333 12.6802 1.20643 12.6802 1.66667V12.0117L13.6957 11.0772C14.0495 10.7518 14.6229 10.7518 14.9767 11.0772C15.3304 11.4027 15.3304 11.9303 14.9767 12.2557L12.4149 14.6128C12.0612 14.9382 11.4877 14.9382 11.134 14.6128L8.57231 12.2557C8.21863 11.9303 8.21863 11.4027 8.57231 11.0772C8.92599 10.7518 9.49949 10.7518 9.85317 11.0772L10.8687 12.0117V1.66667C10.8687 1.20643 11.2742 0.833333 11.7744 0.833333ZM3.62309 0C4.2338 0 4.78298 0.329687 5.03242 0.834616L5.0812 0.945442L7.19319 6.38642C7.36138 6.81983 7.11593 7.29667 6.64487 7.45142C6.20746 7.59519 5.72928 7.41066 5.52854 7.03655L5.48728 6.94692L5.05501 5.83333H2.19116L1.7589 6.94692C1.59067 7.38033 1.0724 7.60625 0.601331 7.45142C0.16391 7.30772 -0.0790087 6.88631 0.0232264 6.47982L0.0530146 6.38642L2.16498 0.945442C2.38505 0.378467 2.96875 0 3.62309 0ZM3.62309 2.14434L2.8381 4.16667H4.40807L3.62309 2.14434Z'
//       fill='#2E56A4'
//     />
//   </svg>
// );
// export default DateSvg;
import * as React from 'react';
const DateSvg = () => (
  <svg
    viewBox='0 0 256 256'
    xmlns='http://www.w3.org/2000/svg '
    width={20}
    height={20}>
    <path fill='none' d='M0 0h256v256H0z' />
    <path
      fill='none'
      stroke='#2f57a4'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={16}
      d='m144 88 40-40 40 40M184 144V48'
      className='stroke-000000'
    />
    <path
      stroke='#2f57a4'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={16}
      d='M48 128h72M48 64h56M48 192h136'
      fill='#2f57a4'
      className='fill-000000 stroke-000000'
    />
  </svg>
);
export default DateSvg;

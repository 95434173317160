import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SOMETHING_WENT_WRONG,
  SHOW_MESSAGE,
  UPDATING_CONTENT,
  GET_ALL_COMPANIES,
  POST_COMPANIES,
  POST_COMPANIES_SUCCESS,
  DELETE_COMPANIES_SUCCESS,
  UPDATE_COMPANIES_SUCCESS,
  UPDATE_COMPANIES_BY_ID,
  GET_COMPANIES_BY_ID,
  GET_ALL_COMPANIES_BY_QUERY,
  DELETE_COMPANIES_BY_ID,
} from '../../Constant/ActionTypes';
import {commonSortOrder, handleJwtExpireMsg} from '../../Util/commonUtility';
import Urls from '../../Constant/Urls';
import {httpClient} from '../../Api/client';

export const getAllCompaniesByQuery = (
  pagination,
  sortField,
  sortOrder,
  searchQueryData,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.COMPANIES_GET_ALL}?page=${pagination.page}&pageCount=${
          pagination.pagecount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${searchQueryData}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_COMPANIES_BY_QUERY, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const getAllCompanies = () => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(`${Urls.COMPANIES_GET_ALL}`);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_COMPANIES, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteCompaniesById = (id) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.delete(
        `${Urls.COMPANIES_DELETE_BY_ID.replace('#{companiesId}', id)}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: DELETE_COMPANIES_BY_ID});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const createCompanies = (Data, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.post(Urls.COMPANIES_ADD, Data);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: POST_COMPANIES});
        navigate('/admin/companies');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const editCompanies = (Data, id, navigate) => {
  return async (dispatch) => {
    dispatch({type: UPDATING_CONTENT});
    try {
      let data = await httpClient.patch(
        `${Urls.COMPANIES_UPDATE_BY_ID.replace('#{companiesId}', id)}`,
        Data,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
        dispatch({type: UPDATE_COMPANIES_BY_ID});
        navigate('/admin/companies');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getCompaniesById = (id) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.COMPANIES_GET_BY_ID.replace('#{companiesId}', id)}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_COMPANIES_BY_ID, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

import React, {useEffect, useState} from 'react';
import Modal from 'antd/es/modal/Modal';
import {
  Card,
  Form,
  Input,
  DatePicker,
  Button,
  Upload,
  Row,
  Col,
  Divider,
  message,
  Select,
} from 'antd';
import {CloseCircleFilled, UploadOutlined} from '@ant-design/icons';
import {useDispatch} from 'react-redux';
import {addAds, updateAdById} from '../../../../Store/Actions';
import {
  NoEmptySpaceInput,
  UploadFileMaxSize,
  disablePrevDatesHandler,
  removeHttpsFromUrl,
  urlInputValidator,
  validateAdsDescriptionInput,
  validateEmailInput,
  validateMonthsInput,
} from '../../../../Util/commonUtility';
import SectionLayout from '../../../CommonComponents/Layout/sectionLayout';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import {applicationConfig} from '../../../../Util/config';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {FETCH_ERROR} from '../../../../Constant/ActionTypes';

const AdsFormComponent = (props) => {
  const {isModalOpen, setIsModalOpen, editData, adFeesOptions, zeroFeeData} =
    props;
  const [validFileList, setValidFileList] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onFinish = (values) => {
    const addHttpsToAttachmentUrl = `https://${values?.attachment_url}`;

    const data = new FormData();
    // const file_logo = values.file_logo
    //   ? values.file_logo.map((item) => {
    //       data.append('logos', item.originFileObj);
    //     })
    //   : null;
    editData?.adsLogo
      ? editData?.adsLogo?.id === validFileList[0]?.uid
        ? data.append('logo_ids', `[${editData?.adsLogo?.id}]`)
        : data.append('logos', validFileList[0] || [])
      : data.append('logos', validFileList[0] || []);
    data.append('vendor', values.vendor ? values.vendor : '');
    data.append(
      'attachment_url',
      values.attachment_url ? addHttpsToAttachmentUrl : '',
    );
    data.append('email', values?.email ? values.email : '');
    data.append(
      'start_date',
      values.start_date ? dayjs(values?.start_date).format('YYYY-MM-DD') : '',
    );
    data.append('description', values.description ? values.description : '');
    data.append(
      'ad_fee_id',
      values.ad_fee_id ? parseInt(values.ad_fee_id) : undefined,
    );
    data.append('months', values?.months ? values?.months : '');
    if (editData && editData?.adsStatus === 'draft') {
      dispatch(
        updateAdById(data, editData?.id, form, null, null, setIsModalOpen),
      );
    } else {
      const isZeroFee = values?.ad_fee_id === zeroFeeData[0]?.id;
      dispatch(
        addAds(
          data,
          form,
          navigate,
          setIsModalOpen,
          setValidFileList,
          isZeroFee,
        ),
      );
    }
    // form.resetFields();
    // setIsModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  const handleClose = () => {
    form.resetFields();
    setIsModalOpen(false);
    setValidFileList((prev) => []);
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const beforeUploadsLogos = (file) => {
    const reader = new FileReader();
    const fileSize = file.size / 1024 / 1024;

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        if (width !== 250 && height !== 250) {
          message.error('Image must be 250px x 250px!');
          return Upload.LIST_IGNORE;
        }
      };
      if (fileSize > 2) {
        message.error('File size must not exceed 2MB!');
        return Upload.LIST_IGNORE;
      }
      return true;
    };

    reader.readAsDataURL(file);
  };

  const filesFormats = ['image/png', 'image/jpeg', 'image/jpg'];

  const uploadProps = {
    name: 'file',
    multiple: true,
    maxCount: 10,
    // listType: 'picture',
    beforeUpload: (file) => {
      const isRightFormat = filesFormats.includes(file.type);
      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'You can only upload png, jpg, jpeg files',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSize;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSize}MB!`,
        });
        return false;
      }
      if (file?.type?.includes('image')) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            if (img.width >= 200 && img.height >= 200) {
              setValidFileList((prev) => [file]);
              resolve(isRightFormat && isLt2M);
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Image must be minimum 200x200!',
              });
              setValidFileList((prev) =>
                prev.filter((f) => f.uid !== file.uid),
              );
              reject();
            }
          };
          img.onerror = reject;
        });
      } else {
        setValidFileList((prev) => [file]);
        return isRightFormat && isLt2M;
      }
    },
  };

  const removeFileHandler = (file) => {
    setValidFileList((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
  };

  useEffect(() => {
    if (editData) {
      const findAdFeesId = adFeesOptions?.find(
        (obj) => obj?.details === editData?.ad_fee_note,
      )?.id;
      form.setFieldsValue({
        vendor: editData?.adsHeading,
        attachment_url: removeHttpsFromUrl(editData?.url),
        email: editData?.adsEmail,
        description: editData?.adsDescription,
        // logos: editData?.adsLogo
        //   ? {
        //       uid: editData?.adsLogo?.id,
        //       name: editData?.adsLogo?.name,
        //       status: 'done',
        //       url: `${applicationConfig.UPLOAD_URL}/${editData?.adsLogo?.name}`,
        //     }
        //   : [],
        start_date: editData?.adsStart_date
          ? dayjs(editData?.adsStart_date)
          : null,
        months: editData?.months,
        ad_fee_id: findAdFeesId,
      });
    }
  });

  useEffect(() => {
    if (isModalOpen) {
      editData?.adsLogo &&
        setValidFileList((prev) => [
          {
            uid: editData?.adsLogo?.id,
            name: editData?.adsLogo?.name,
            status: 'done',
            url: `${applicationConfig.UPLOAD_URL}/${editData?.adsLogo?.name}`,
          },
        ]);
    }
  }, [isModalOpen]);

  return (
    <SectionLayout>
      <div className='form-container'>
        <Modal
          bodyStyle={{overflowY: 'scroll', maxHeight: 'calc(100vh - 200px)'}}
          open={isModalOpen}
          className='common-form-modal'
          prefixCls='customized-modal-v2'
          footer={null}
          onCancel={handleClose}
          closeIcon={
            <CloseCircleFilled
              className='close-icon-modal-v2'
              id='UserAdsComponent-closeButton-addAds'
            />
          }>
          <Card
            title={
              <div className='modal-container-v2'>
                <div>
                  <span className='modal-heading-v2'>
                    {ApplicationString['userAdsFormComponent.heading']}
                  </span>
                </div>
                <div>
                  <span className='modal-desc-v2'>
                    {ApplicationString['userAdsFormComponent.subheading']}
                  </span>
                </div>
              </div>
            }
            bordered={false}
            className='form-card'>
            <div className='main-content-container'>
              <Form onFinish={onFinish} form={form} initialValues={''}>
                <Row>
                  <Col span={24}>
                    <div className='common-input-container-v2'>
                      <Form.Item
                        name='vendor'
                        rules={[
                          {
                            required: true,
                            message: 'Please fill out vendor name!',
                          },
                          {
                            validator: (_, value) =>
                              NoEmptySpaceInput(value, 'Vendor'),
                          },
                        ]}>
                        <Input
                          placeholder='Vendor Name'
                          className='form-input-v2'
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row gutter={15}>
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <div className='common-input-container-v2'>
                      <Form.Item
                        name='attachment_url'
                        rules={[
                          {
                            required: true,
                            message: 'Please fill out attachment url!',
                          },
                          {validator: urlInputValidator},
                        ]}>
                        <Input
                          placeholder='Url'
                          prefixCls='attachment-url-input-v2'
                          addonBefore='https://'
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <div className='common-input-container-v2'>
                      <Form.Item
                        name='email'
                        rules={[
                          {
                            required: true,
                            message: 'Please fill out email!',
                          },
                          {
                            validator: (_, value) =>
                              validateEmailInput(value, 'Email'),
                          },
                        ]}>
                        <Input placeholder='Email' className='form-input-v2' />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row gutter={15}>
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <div>
                      <Form.Item
                        name='start_date'
                        prefixCls='form-input-form-item-class'
                        rules={[
                          {
                            required: true,
                            message: 'Please select start date!',
                          },
                        ]}>
                        <DatePicker
                          disabledDate={(current) =>
                            disablePrevDatesHandler(current, true)
                          }
                          placeholder='Start date of Ad'
                          prefixCls='form-input-datepicker-v2'
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <div>
                      <Form.Item
                        name={'months'}
                        className='common-input-container-v2'
                        rules={[
                          {
                            required: true,
                            message: '',
                          },
                          {
                            validator: (_, value) =>
                              validateMonthsInput(value, 'Months'),
                          },
                        ]}>
                        <Input
                          placeholder='Enter the months of the ad'
                          className='form-input-v2'
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div className='common-input-container-v2'>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'Please select ad type!',
                          },
                        ]}
                        name='ad_fee_id'>
                        <Select
                          id='adminAds-adTypeSelect-select'
                          className='form-select-v2'
                          placeholder={`Select ads type`}
                          options={adFeesOptions}></Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div className='common-input-container-v2'>
                      <Form.Item
                        name='description'
                        rules={[
                          {
                            validator: (_, value) =>
                              validateAdsDescriptionInput(value, 'Description'),
                          },
                        ]}>
                        <Input.TextArea
                          placeholder='Textual ads description  (150 character ) '
                          className='form-input-v2 form-input-text-area-class'
                          rows={7}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <div className='common-input-container-v2 upload'>
                      <Form.Item
                        name='file_logo'
                        // valuePropName='fileList'
                        getValueFromEvent={normFile}>
                        <Upload
                          {...uploadProps}
                          accept='image/*'
                          customRequest={dummyRequest}
                          onRemove={removeFileHandler}
                          fileList={validFileList}
                          showUploadList={true}>
                          <div>
                            <UploadOutlined className='upload-logo-v2' />
                            <div>
                              <span className='upload-text-logos-v2'>
                                Upload Logo
                              </span>
                            </div>
                            <div className='upload-type-admin'>
                              <span className='upload-desc-v2'>
                                Accepts only image files (png, jpg, jpeg)
                              </span>
                              <br />
                              <span className='upload-desc-v2'>
                                {`Maximum file size is ${UploadFileMaxSize}MB.`}
                              </span>
                            </div>
                          </div>
                        </Upload>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                {/* <Divider /> */}
                <div className='modal-footer-container'>
                  <div>
                    {/* <span className='payment-text-v2'>
                      First, complete the payment to proceed with the listing
                      process.
                    </span> */}
                    {/* <div className='alert-info'>
                      <Alert
                        message={
                          <div className='alert-container'>
                            <div>
                              <span className='alert-heading'>
                                Ads Added Successfully in queue !
                              </span>
                            </div>
                            <div>
                              <span className='alert-desc'>
                                it will take 24 Hours to be listed & once
                                moderator approve the details & Payment status
                              </span>
                            </div>
                          </div>
                        }
                        type='success'
                        closeIcon={<CloseCircleFilled className='close-icon' />}
                      />
                    </div> */}
                  </div>
                  <div className='modal-footer-button'>
                    <Button
                      htmlType='submit'
                      id='UserAdsComponent-submitButton-addAds'
                      className='checkout-button-v2'>
                      <span className='checkout-text-v2'>
                        {editData?.adsStatus === 'draft'
                          ? 'Save'
                          : 'Submit & checkout'}{' '}
                      </span>
                    </Button>
                    <Button
                      id='UserAdsComponent-cancelButton-addAds'
                      className='cancel-button-v2'
                      onClick={handleCancel}>
                      <span className='cancel-text-v2'>Cancel</span>
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </Card>
        </Modal>
      </div>
    </SectionLayout>
  );
};

export default AdsFormComponent;

import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL_KEYWORDS,
  SOMETHING_WENT_WRONG,
  FETCH_KEYWORD_BY_ID,
  UPDATE_KEYWORD_BY_ID,
  DELETE_KEYWORD_BY_ID,
  ADD_KEYWORD_SUCCESS,
  SHOW_MESSAGE,
  GET_ALL_KEYWORDS_BY_QUERY,
  GET_ALL_KEYWORDS_BY_QUERY_SEARCH,
  UPDATE_KEYWORD_SUCCESS,
  UPDATE_INTEREST_SUCCESS,
  DELETE_INTEREST_SUCCESS,
  POST_INTEREST_SUCCESS,
} from '../../Constant/ActionTypes';

import Urls from '../../Constant/Urls';
import {httpClient} from '../../Api/client';
import {commonSortOrder, handleJwtExpireMsg} from '../../Util/commonUtility';

export const getAllKeywords = () => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(Urls.KEYWORD_GET_ALL);
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_KEYWORDS, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllKeywordsByQuery = (
  sortField,
  sortOrder,
  queryString,
  pagination,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.KEYWORD_GET_ALL}?page=${pagination.page}&pageCount=${
          pagination.pageCount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&${queryString}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_KEYWORDS_BY_QUERY, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getAllKeywordsByQuerySearch = (
  sortField,
  sortOrder,
  queryString,
  pagination,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await httpClient.get(
        `${Urls.KEYWORD_GET_ALL}?page=${pagination.page}&pageCount=${
          pagination.pageCount
        }&sortOrder=${commonSortOrder(
          sortOrder,
        )}&sortField=${sortField}&status=1&${queryString}`,
      );
      if (data?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_KEYWORDS_BY_QUERY_SEARCH, payload: data?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const fetchKeywordById = (keywordId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.get(
        Urls.KEYWORD_FETCH_BY_ID.replace('#{keywordId}', keywordId),
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_KEYWORD_BY_ID, payload: response?.data});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      errorMessage = handleJwtExpireMsg(error, errorMessage);
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addKeyword = (keywordData, navigate) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.post(Urls.KEYWORD_ADD, keywordData);
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: ADD_KEYWORD_SUCCESS, payload: response?.data});
        navigate && navigate('/admin/interests');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateKeywordById = (
  updatedKeywordData,
  keywordId,
  navigate,
  status,
) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.patch(
        Urls.KEYWORD_UPDATE_BY_ID.replace('#{keywordId}', keywordId),
        updatedKeywordData,
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: UPDATE_KEYWORD_BY_ID, payload: response?.data});
        navigate && navigate('/admin/interests');
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.BadRequest:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteKeywordById = (keywordId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.delete(
        Urls.KEYWORD_DELETE_BY_ID.replace('#{keywordId}', keywordId),
      );
      if (response?.status === HttpCode.Ok) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: SHOW_MESSAGE,
          payload: response?.data?.message,
        });
        dispatch({type: DELETE_KEYWORD_BY_ID, payload: keywordId});
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case HttpCode.NotFound:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const resetKeywordSearchData = (state) => {
  return async (dispatch) => {
    try {
      dispatch({type: GET_ALL_KEYWORDS_BY_QUERY_SEARCH, payload: state});
    } catch (error) {}
  };
};

import React, {useEffect, useState} from 'react';
import {Dropdown, Tooltip, Popconfirm, Button} from 'antd';
import {
  EditOutlined,
  CopyOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
  FormOutlined,
  ExportOutlined,
  SaveOutlined,
  CloseOutlined,
  SendOutlined,
  CheckCircleFilled,
  CheckOutlined,
  CloseCircleFilled,
  DeleteFilled,
  EditFilled,
  DeleteRowOutlined,
  CloseSquareFilled,
  EyeOutlined,
} from '@ant-design/icons';
import UpdateHistoryTableInfo from '../../AdminComponents/HistoryView';
import {useNavigate} from 'react-router-dom';

const AdminActionsDropdown = ({
  editBool,
  attendeeEditingKey,
  copyBool,
  viewBool = false,
  handleView,
  statusBool,
  deleteBool,
  saveBool,
  record,
  handleEditClick,
  handleCopyClick,
  usedForTable,
  handleDeleteClick,
  handleSaveClick,
  handleUpdateStatusClick,
  handleCancelClick,
  eventNavigate = false,
  sendRecordinEdit,
  text = '',
  usedForAttendeeTable = false,
  handleEditTemplate,
  handleEditSchedule,
  sendDirectEmail,
  handleSendDirectEmailClick,
  cancelBool = true,
  isAddAttendee,
  ToolTipTitle = '',
  usedForAdminSide = false,
  usedForAdsAndJobsCart = false,
  setIsAddAttendee,
  setDisableAttendeeClicks,
  setDisablePaymentClicks,
  disableEditClicks = false,
  setDisableEditClicks,
}) => {
  const navigate = useNavigate();
  const isEditing = (record) => record.id === attendeeEditingKey;
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    setEditable(isEditing(record));
  }, [attendeeEditingKey]);

  return (
    <>
      {usedForAttendeeTable ? (
        <>
          {editable ? (
            <span
              className={
                usedForAdsAndJobsCart
                  ? 'saveAndCancelWrapperForAds'
                  : 'saveAndCancelWrapperForAttendees'
              }>
              <Tooltip title={`Save ${ToolTipTitle}`}>
                <Button
                  id={`${text}-action-save-attendee-${record.id}`}
                  prefixCls={
                    usedForAdminSide ? 'admin-save-attendees' : 'attendees-save'
                  }
                  type='text'
                  icon={<CheckCircleFilled />}
                  onClick={() => handleSaveClick(record)}
                />
              </Tooltip>
              {cancelBool && (
                <Tooltip title={`Cancel ${ToolTipTitle}`}>
                  <Button
                    id={`${text}-action-cancel-attendee-${record.id}`}
                    prefixCls='attendees-close'
                    type='text'
                    icon={<CloseCircleFilled />}
                    onClick={handleCancelClick}
                  />
                </Tooltip>
              )}
            </span>
          ) : (
            <span
              className={
                usedForAdsAndJobsCart
                  ? 'saveAndCancelWrapperForAds'
                  : 'saveAndCancelWrapperForAttendees'
              }>
              {editBool && (
                <Tooltip title={`Edit ${ToolTipTitle}`}>
                  <Button
                    id={`${text}-action-edit-attendee-${record.id}`}
                    prefixCls={
                      usedForAdminSide
                        ? 'admin-save-attendees'
                        : 'attendees-save'
                    }
                    type='text'
                    disabled={disableEditClicks}
                    icon={<EditFilled />}
                    onClick={() => {
                      setIsAddAttendee && setIsAddAttendee(true);
                      setDisableEditClicks && setDisableEditClicks(true);
                      setDisableAttendeeClicks &&
                        setDisableAttendeeClicks(true);
                      setDisablePaymentClicks && setDisablePaymentClicks(true);
                      handleEditClick(record);
                    }}
                  />
                </Tooltip>
              )}
              {deleteBool && (
                <Tooltip title='Delete'>
                  <Popconfirm
                    title={`Delete the ${text}`}
                    description='Are you sure you want to delete?'
                    onConfirm={() => {
                      handleDeleteClick(record.id);
                    }}
                    okText='Yes'
                    cancelText='No'
                    okType='danger'
                    icon={
                      <QuestionCircleOutlined
                        style={{
                          color: 'red',
                        }}
                      />
                    }>
                    <Button
                      id={`${text}-action-delete-attendee-${record.id}`}
                      prefixCls='attendees-close'
                      type='text'
                      disabled={disableEditClicks}
                      icon={<DeleteFilled />}
                    />
                  </Popconfirm>
                </Tooltip>
              )}
            </span>
          )}
        </>
      ) : (
        <Dropdown
          menu={{
            items: [
              editBool && {
                key: 1,
                label: (
                  // <Tooltip prefixCls = 'common-pbss-tooltip' title='Click here to edit your data!'>
                  <div
                    className='action-container'
                    onClick={() => {
                      handleEditClick(sendRecordinEdit ? record : record.id);
                    }}
                    id={`${text}-action-edit-${record.id}`}>
                    <div className='icon-container edit'>
                      <EditOutlined />
                      Edit {text}
                    </div>
                  </div>
                  // </Tooltip>
                ),
              },

              viewBool && {
                key: 15,
                label: (
                  <div
                    onClick={() => handleView(record)}
                    id={`${text}-action-copy-${record.id}`}
                    className='icon-container edit'>
                    <EyeOutlined /> View {text}
                  </div>
                ),
              },
              copyBool && {
                key: 2,
                label: (
                  <Popconfirm
                    title='Duplicate the event'
                    prefixCls='status-modal-popconfirm'
                    description='Are you sure you want to duplicate?'
                    onConfirm={() => handleCopyClick(record.id)}
                    okText='Yes'
                    cancelText='No'
                    okType='primary'
                    icon={
                      <QuestionCircleOutlined
                        style={{
                          color: 'warning',
                        }}
                      />
                    }>
                    <div
                      id={`${text}-action-copy-${record.id}`}
                      className='icon-container edit'>
                      <CopyOutlined /> Copy {text}
                    </div>
                  </Popconfirm>
                ),
              },
              deleteBool && {
                key: 3,
                label: (
                  <Popconfirm
                    title={`Delete the ${text}`}
                    description='Are you sure you want to delete?'
                    onConfirm={() => handleDeleteClick(record.id)}
                    okText='Yes'
                    cancelText='No'
                    okType='danger'
                    icon={
                      <QuestionCircleOutlined
                        style={{
                          color: 'red',
                        }}
                      />
                    }>
                    <div
                      className='action-container'
                      id={`${text}-action-delete-${record.id}`}>
                      <div className='icon-container delete'>
                        <DeleteOutlined />
                        Delete {text}
                      </div>
                    </div>
                  </Popconfirm>
                ),
              },
              statusBool && {
                key: 4,
                label: (
                  // <Tooltip
                  //   className='common-pbss-tooltip'
                  //   title='Click here to Update Status of Event!'
                  //   color='#F24E1E'>
                  <div
                    className='action-container'
                    onClick={() => handleUpdateStatusClick(record)}
                    id={`${text}-action-status-${record.id}`}>
                    <div className='icon-container status'>
                      <FormOutlined />
                      Change {text} Status
                    </div>
                  </div>
                  // </Tooltip>
                ),
              },
              eventNavigate && {
                key: 5,
                label: (
                  <div
                    className='action-container'
                    onClick={() =>
                      navigate(`/admin/eventRegistration/${record?.id}`)
                    }>
                    <div
                      className='icon-container'
                      id={`${text}-action-registration-${record.id}`}>
                      <ExportOutlined /> Event Registration
                    </div>
                  </div>
                ),
              },
              eventNavigate && {
                key: 6,
                label: (
                  <div
                    className='action-container'
                    onClick={() =>
                      navigate(
                        `/admin/${/*record.id/*/ ''}eventOptions/${record?.id}`,
                      )
                    }>
                    <div
                      className='icon-container'
                      id={`${text}-action-options-${record.id}`}>
                      <ExportOutlined /> Event Options
                    </div>
                  </div>
                ),
              },
              eventNavigate && {
                key: 7,
                label: (
                  <div
                    className='action-container'
                    onClick={() =>
                      navigate(
                        `/admin/eventEmailTemplatesScheduler/${record?.id}`,
                      )
                    }>
                    <div
                      className='icon-container'
                      id={`${text}-action-schedular-${record.id}`}>
                      <ExportOutlined /> Event Email Scheduler
                    </div>
                  </div>
                ),
              },
              eventNavigate && {
                key: 8,
                label: (
                  <div
                    className='action-container'
                    onClick={() =>
                      navigate(`/admin/eventRefund/${record?.id}`)
                    }>
                    <div
                      className='icon-container'
                      id={`${text}-action-refund-${record.id}`}>
                      <ExportOutlined /> Event Refund
                    </div>
                  </div>
                ),
              },
              !usedForAttendeeTable && {
                key: 9,
                label: (
                  <div className='action-container'>
                    <div className='icon-container' id={`History-${record.id}`}>
                      <UpdateHistoryTableInfo
                        userInfo={record}
                        hideBool={true}
                        text={text}
                      />
                    </div>
                  </div>
                ),
              },
              usedForTable && {
                key: 9,
                label: (
                  <div className='action-container'>
                    <div className='icon-container'>
                      <UpdateHistoryTableInfo
                        userInfo={record}
                        hideBool={true}
                        text={text}
                      />
                    </div>
                  </div>
                ),
              },
              saveBool && {
                key: 10,
                label: (
                  <Tooltip
                    className='common-pbss-tooltip'
                    title='Click here to save your data!'>
                    <div
                      className='action-container'
                      onClick={() => handleSaveClick(record)}
                      id={`eventsGroupComponent-action-save-${record.id}`}>
                      <div className='icon-container edit'>
                        <SaveOutlined />
                        Save {text}
                      </div>
                    </div>
                  </Tooltip>
                ),
              },
              handleEditTemplate && {
                key: 11,
                label: (
                  // <Tooltip prefixCls = 'common-pbss-tooltip' title='Click here to edit your data!'>
                  <div
                    className='action-container'
                    onClick={() => {
                      handleEditTemplate(true, record.id);
                    }}
                    id={`eventsGroupComponent-action-edit-${record.id}`}>
                    <div className='icon-container edit'>
                      <EditOutlined />
                      Edit Email Template
                    </div>
                  </div>
                  // </Tooltip>
                ),
              },
              handleEditSchedule && {
                key: 12,
                label: (
                  // <Tooltip prefixCls = 'common-pbss-tooltip' title='Click here to edit your data!'>
                  <div
                    className='action-container'
                    onClick={() => {
                      handleEditSchedule(false, record.id);
                    }}
                    id={`eventsGroupComponent-action-edit-${record.id}`}>
                    <div className='icon-container edit'>
                      <EditOutlined />
                      Edit Schedule of Template
                    </div>
                  </div>
                  // </Tooltip>
                ),
              },
              sendDirectEmail && {
                key: 13,
                label: (
                  // <Tooltip prefixCls = 'common-pbss-tooltip' title='Click here to edit your data!'>
                  <div
                    className='action-container'
                    onClick={() => {
                      handleSendDirectEmailClick(record.id);
                    }}
                    id={`eventsGroupComponent-action-edit-${record.id}`}>
                    <div className='icon-container edit'>
                      <SendOutlined />
                      Send Direct Email
                    </div>
                  </div>
                  // </Tooltip>
                ),
              },
            ],
          }}
          trigger={['click']}>
          <span className='adminActionsDropdown'>
            <span className='adminActionsDropdown2'>
              <svg
                width='20px'
                height='20px'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <circle cx='12' cy='4' r='2' fill='#000' />
                <circle cx='12' cy='12' r='2' fill='#000' />
                <circle cx='12' cy='20' r='2' fill='#000' />
              </svg>
            </span>
          </span>
        </Dropdown>
      )}
    </>
  );
};

export default AdminActionsDropdown;

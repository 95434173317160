import React, {useEffect, useState} from 'react';
import {Button, Card, DatePicker, Form, Input} from 'antd';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {ApplicationString} from '../../../../Localization/Locales/en';
import {
  NoEmptySpaceInput,
  validateCompanyName,
  validateEmailInput,
  validatePhoneNumber,
} from '../../../../Util/commonUtility';
import CommonEditor from '../../../CommonComponents/TextEditor';

const SpeakersFormComponent = (props) => {
  const [submit, setSubmit] = useState(false);
  const [submitLabel, setSubmitLabel] = useState(
    ApplicationString['common.button.submitForm'],
  );
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const {postSpeakers, EditData, updateSpeakers, fetchSpeakersById} = props;
  const [editorCommentState, setEditorCommentState] = useState(`<p></p>`);

  useEffect(() => {
    if (id) {
      fetchSpeakersById(id);
      setSubmitLabel(ApplicationString['common.button.updateForm']);
    }
  }, []);
  useEffect(() => {
    if (id) {
      EditData &&
        form.setFieldsValue({
          ...EditData,
        });
      setEditorCommentState(EditData?.comments);
    }
  }, [EditData]);

  const onFinish = (values) => {
    delete values.confirm_email;
    const Data = {
      ...values,
      comments: editorCommentState,
    };
    if (id) {
      updateSpeakers(Data, EditData.id);
      // form.resetFields();
    } else {
      postSpeakers(Data, form);
      // form.resetFields();
    }
    setSubmit(true);
  };
  const {id} = useParams();
  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };
  const onReset = () => {
    form.resetFields();
    setSubmit(false);
  };
  const backToSpeakers = () => {
    navigate('/admin/speakers');
  };

  return (
    <div className='head-main-container'>
      <div className='main-form-container'>
        <Form
          form={form}
          name='basic'
          onFinish={onFinish}
          layout='vertical'
          className='two-column-form'
          onFinishFailed={onFinishFailed}
          autoComplete='off'>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='First Name'
                name='first_name'
                rules={[
                  {
                    required: true,
                    message: 'First name should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'First name'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter speaker first name'
                />
              </Form.Item>
              <Form.Item
                label='Address'
                name='address'
                rules={[
                  {
                    required: false,
                    message: 'Address should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Address'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter speaker address'
                />
              </Form.Item>

              <Form.Item
                label='Email'
                name='email'
                rules={[
                  {
                    required: false,
                    message: 'Email should not be empty',
                  },
                  {
                    validator: (_, value) => validateEmailInput(value, 'Email'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter speaker email'
                />
              </Form.Item>

              <Form.Item
                label='Degree/Affiliation'
                name='title'
                rules={[
                  {
                    validator: (_, value) => NoEmptySpaceInput(value, 'Title'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter speaker Degree/Affiliation'
                />
              </Form.Item>
            </div>

            <div className='form-column'>
              <Form.Item
                label='Last Name'
                name='last_name'
                rules={[
                  {
                    required: false,
                    message: 'Last name should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Last name'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter speaker last name'
                />
              </Form.Item>
              <Form.Item
                label='Phone Number'
                name='phone_number'
                rules={[
                  {
                    required: false,
                    message: 'Phone number should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      validatePhoneNumber(value, 'Phone number'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter speaker phone number'
                />
              </Form.Item>
              <Form.Item
                label='Confirm email'
                name='confirm_email'
                dependencies={['email']}
                rules={[
                  {
                    required: false,
                    message: 'Email should not be empty',
                  },
                  {
                    validator: (_, value) => {
                      validateEmailInput(value, 'Email');
                      if (!value || form.getFieldValue('email') === value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error('Email IDs must match'),
                        );
                      }
                    },
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter speaker confirm email'
                />
              </Form.Item>

              <Form.Item
                label='Company Name'
                name='company_name'
                rules={[
                  {
                    required: false,
                    message: 'Company name should not be empty!',
                  },
                  {
                    validator: (_, value) =>
                      validateCompanyName(value, 'Company name'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter speaker company name'
                />
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item
                label='Details'
                name='details'
                rules={[
                  {
                    validator: (_, value) =>
                      NoEmptySpaceInput(value, 'Details'),
                  },
                ]}>
                <Input
                  prefixCls='common-input-user'
                  className='genral-form'
                  placeholder='Enter speaker details'
                />
              </Form.Item>
            </div>
          </div>
          <div className='input-container'>
            <div className='form-column'>
              <Form.Item label='Comments' name='comments'>
                <CommonEditor
                  value={editorCommentState}
                  onChange={setEditorCommentState}
                  text={'comments'}
                  placeholderValue={'comments'}
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <div className='button-container'>
              <Button
                id='speakersComponent-button-back'
                className='common-submit-login common-form-submit common-form-cancel'
                onClick={() => {
                  backToSpeakers();
                }}>
                {ApplicationString['common.button.backForm']}
              </Button>
              <div className='form-flex-button'>
                <Button
                  type='primary'
                  htmlType='submit'
                  //disabled={submit ? true : false}
                  className='common-submit-login common-form-submit'
                  id='speakersComponent-button-submit'>
                  {submitLabel}
                </Button>
                <Button
                  className='common-submit-login common-form-submit common-form-cancel'
                  htmlType='button'
                  id='speakersComponent-button-reset'
                  onClick={onReset}>
                  {ApplicationString['dynamicSearch.button.reset']}
                </Button>{' '}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>{' '}
    </div>
  );
};

export default SpeakersFormComponent;

import {
  DELETE_SPEAKERS_BY_ID,
  GET_ALL_SPEAKERS,
  GET_ALL_SPEAKERS_BY_QUERY,
  GET_ALL_SPEAKERS_BY_QUERY_SEARCH,
  GET_SPEAKERS_BY_ID,
  POST_SPEAKERS,
  UPDATE_SPEAKERS_BY_ID,
} from '../../Constant/ActionTypes';

const initialState = {
  allSpeakers: false,
  getSpeakersData: false,
  isCreateSpeaker: false,
  isUpdateSpeaker: false,
  allSpeakersQueryData: {
    forSearch: false,
    forMaster: false,
  },
};

const SpeakersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SPEAKERS:
      return {
        ...state,
        allSpeakers: action.payload,
        isCreateSpeaker: false,
        isUpdateSpeaker: false,
      };
    case GET_ALL_SPEAKERS_BY_QUERY:
      return {
        ...state,
        allSpeakersQueryData: {
          ...state.allSpeakersQueryData,
          forMaster: action.payload,
        },
        isCreateSpeaker: false,
        isUpdateSpeaker: false,
      };
    case GET_ALL_SPEAKERS_BY_QUERY_SEARCH:
      return {
        ...state,
        allSpeakersQueryData: {
          ...state.allSpeakersQueryData,
          forSearch: action.payload,
        },
        isCreateSpeaker: false,
        isUpdateSpeaker: false,
      };
    case GET_SPEAKERS_BY_ID:
      return {
        ...state,
        getSpeakersData: action.payload,
      };
    case POST_SPEAKERS:
      return {
        ...state,
        isCreateSpeaker: true,
        allSpeakers: false,
        allSpeakersQueryData: false,
      };
    case UPDATE_SPEAKERS_BY_ID:
      return {
        ...state,
        isUpdateSpeaker: true,
        allSpeakers: false,
        allSpeakersQueryData: false,
      };
    case DELETE_SPEAKERS_BY_ID:
      return {
        ...state,
        allSpeakers: false,
        allSpeakersQueryData: false,
      };
    default:
      return state;
  }
};

export default SpeakersReducer;

import {Col, Divider, Row} from 'antd';
import React, {useEffect} from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';
import './style.css';
import RegisterForm from '../../CommonComponents/RegisterForm';
import CommonWantToSeeAdsListing from '../../CommonComponents/CommonWantToSeeAdsListing';
import CommonPageHeroText from '../../CommonComponents/CommonPageHeroText';
import {ApplicationString} from '../../../Localization/Locales/en';

const RegisterComponent = ({
  chaptersList,
  fetching,
  setFetching,
  SetKeywordsOptions,
  keywordsOptions,
  chaptersListForAnnouncements,
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <React.Fragment>
      <div className='register-container'>
        <CommonPageHeroText
          heading={ApplicationString['userRegisterComponent.heading']}
          subheading={ApplicationString['userRegisterComponent.subheading']}
        />
        <SectionLayout>
          <div className='registration-content-container'>
            <Row
              gutter={{
                xs: 4,
                sm: 4,
                md: 40,
                lg: 100,
              }}>
              <Col xs={24} sm={24} md={24} lg={16}>
                <div className='registration-heading-container'>
                  <div className='registration-description'>
                    {ApplicationString['userRegisterComponent.description']}
                  </div>
                  <div className='registration-disclaimer'>
                    {ApplicationString['userRegisterComponent.disclaimer']}
                  </div>
                </div>
                <RegisterForm
                  chaptersList={chaptersList}
                  chaptersListForAnnouncements={chaptersListForAnnouncements}
                  keywordsOptions={keywordsOptions}
                  SetKeywordsOptions={SetKeywordsOptions}
                  fetching={fetching}
                  setFetching={setFetching}
                />
                <Row>
                  <div className='registration-disclaimer2 '>
                    {ApplicationString['userRegisterComponent.disclaimer2']}
                  </div>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} prefixCls='ads-column'>
                <CommonWantToSeeAdsListing
                  isVisible={false}
                  usedForChapPage={true}
                  usedForRegister={true}
                />
              </Col>
            </Row>
          </div>
        </SectionLayout>
      </div>
    </React.Fragment>
  );
};

export default RegisterComponent;

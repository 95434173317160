import {Col, Row} from 'antd';
import './style.css';
import React from 'react';
import RightArrowSvg from '../../../../Assets/svgComponents/RightArrowSvg';
import {useNavigate} from 'react-router-dom';

const PastEventsListingCard = ({
  name,
  date,
  event_type,
  chapter_name,
  event_id,
}) => {
  const Navigate = useNavigate();

  return (
    <div
      className='cardForListing'
      onClick={() => {
        Navigate(`/eventDetails/${event_id}`);
      }}>
      <div className='past-event-card-title'>{name}</div>
      <div className='row2'>
        <div className='past-event-card-date'>
          <span>{date}</span>
        </div>
        <div className='past-event-card-extraDetails'>
          <span>{event_type}</span>
        </div>
        <div className='past-event-card-location'>
          {chapter_name && <span>{chapter_name}</span>}
        </div>
      </div>
      {/* <div className='row3'></div> */}
    </div>
  );
};

export default PastEventsListingCard;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import RegisterComponent from '../../../Components/UserComponents/Register';
import {
  getAllApprovedAds,
  getAllApprovedJobs,
  getAllChapters,
  getAllKeywords,
  getAllKeywordsByQuerySearch,
  resetKeywordSearchData,
} from '../../../Store/Actions';
import {createSelectionOptionsUtil} from '../../../Util/commonUtility';

const RegisterContainer = () => {
  const dispatch = useDispatch();
  const {allChapters} = useSelector(({chapters}) => chapters);
  // const {allKeywords} = useSelector(({keywords}) => keywords);
  const [chapterList, setChapterList] = useState([]);
  // const [keywordsList, setKeywordsList] = useState([]);
  // const {getEventsData} = useSelector(({events}) => events);
  // const [eventsData, setEventsData] = useState([]);
  // const {fetchUserData} = useSelector(({users}) => users);
  const {allKeywordsQueryData} = useSelector(({keywords}) => keywords);
  const [fetching, setFetching] = useState(false);
  const [keywordsOptions, SetKeywordsOptions] = useState([]);
  const {getApprovedJobs} = useSelector(({jobs}) => jobs);
  const {getApprovedAds} = useSelector(({ads}) => ads);
  const [allApprovedJobsData, setAllApprovedJobsData] = useState([]);
  const [allApprovedAdsData, setAllApprovedAdsData] = useState([]);
  const [chaptersListForAnnouncements, setChaptersListForAnnouncements] =
    useState([]);

  useEffect(() => {
    dispatch(resetKeywordSearchData(false));
  }, []);

  useEffect(() => {
    if (allChapters) {
      const visibleChapters = allChapters?.data?.chapters?.filter((chapter) => {
        return chapter?.is_visible_on_map === true;
      });
      setChapterList(createSelectionOptionsUtil(visibleChapters, 'id', 'name'));
      setChaptersListForAnnouncements([
        {label: 'Select all', value: 'all'},
        ...createSelectionOptionsUtil(visibleChapters, 'id', 'name'),
      ]);
    } else {
      dispatch(getAllChapters());
    }
  }, [allChapters]);

  // useEffect(() => {
  //   if (allKeywords) {
  //     setKeywordsList(
  //       createSelectionOptionsUtil(allKeywords?.data?.keywords, 'name', 'name'),
  //     );
  //   } else {
  //     dispatch(getAllKeywords());
  //   }
  // }, [allKeywords]);

  useEffect(() => {
    if (allKeywordsQueryData.forSearch) {
      SetKeywordsOptions(
        createSelectionOptionsUtil(
          allKeywordsQueryData.forSearch?.data?.keywords,
          'id',
          'name',
        ),
      );
      setFetching(false);
    } else {
      dispatch(
        getAllKeywordsByQuerySearch('', '', '', {
          page: 1,
          pageCount: 10,
        }),
      );
    }
  }, [allKeywordsQueryData.forSearch]);

  useEffect(() => {
    if (getApprovedJobs) {
      const Data = getApprovedJobs?.data?.jobs.slice(0, 4);
      setAllApprovedJobsData(Data);
    } else {
      dispatch(getAllApprovedJobs());
    }
  }, [getApprovedJobs]);

  useEffect(() => {
    if (getApprovedAds) {
      const Data = getApprovedAds?.data?.ads.slice(0, 4);
      setAllApprovedAdsData(Data);
    } else {
      dispatch(getAllApprovedAds());
    }
  }, [getApprovedAds]);
  return (
    <RegisterComponent
      chaptersList={chapterList}
      chaptersListForAnnouncements={chaptersListForAnnouncements}
      allApprovedJobsData={allApprovedJobsData}
      allApprovedAdsData={allApprovedAdsData}
      keywordsOptions={keywordsOptions}
      SetKeywordsOptions={SetKeywordsOptions}
      fetching={fetching}
      setFetching={setFetching}
    />
  );
};

export default RegisterContainer;

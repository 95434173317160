import React, {useEffect, useState} from 'react';
import AdminSpecialPaymentFormComponent from '../../../../Components/AdminComponents/AdminSpecialPaymentComponent/AdminSpecialPaymentFormComponent';
import {useDispatch, useSelector} from 'react-redux';
import {
  createSelectionOptionsUtilForEmailCode,
  createSelectionOptionsUtilForEmailToMembers,
  queryString,
} from '../../../../Util/commonUtility';
import {
  getAllEmailCodesSearch,
  getAllUsersByQuerySearch,
} from '../../../../Store/Actions';
import useDebouncedCallback from '../../../../hooks/useDebounce';
import {all} from 'axios';
import {postSpecialPaymentDetails} from '../../../../Store/Actions/specialPayment';
import {useNavigate} from 'react-router-dom';

const AdminSpecialPaymentFormContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // User data
  const {allUsersByQuery} = useSelector(({users}) => users);
  const [userEmailOptions, setUserEmailOptions] = useState([]);
  const [userFetching, setUserFetching] = useState(false);

  // Email template
  const {allEmailCodes} = useSelector(({emailCodes}) => emailCodes);
  const [editorState, setEditorState] = useState('');

  // Email template useEffect
  useEffect(() => {
    if (allEmailCodes.forSearch) {
      setEditorState(
        allEmailCodes.forSearch?.data?.emailCodes?.filter(
          (item) => item?.email_template_type === 'Special Payment Request',
        )[0]?.email_template,
      );
    } else {
      dispatch(
        getAllEmailCodesSearch('', '', 'details=special', {
          page: 1,
          pageCount: 10,
        }),
      );
    }
  }, [allEmailCodes.forSearch?.data?.emailCodes]);

  // User data useEffect
  useEffect(() => {
    if (allUsersByQuery.forSearch) {
      setUserEmailOptions(
        createSelectionOptionsUtilForEmailToMembers(
          allUsersByQuery?.forSearch?.data?.users,
          'id',
          'first_name',
          'last_name',
        ),
      );
      setUserFetching(false);
    } else {
      dispatch(
        getAllUsersByQuerySearch('', '', '', {
          page: 1,
          pageCount: 10,
        }),
      );
    }
  }, [allUsersByQuery?.forSearch]);

  // User data handlers -------------------------------------------
  // User search handler
  const handleUserSearch = useDebouncedCallback((value) => {
    setUserEmailOptions([]);
    setUserFetching(true);
    const Data = {
      first_name: value,
    };
    const searchQueryData = queryString(Data);
    dispatch(
      getAllUsersByQuerySearch('', '', searchQueryData, {
        page: 1,
        pageCount: 10,
      }),
    );
  }, 500);

  // User clear handler
  const handleUserAllowClear = () => {
    dispatch(
      getAllUsersByQuerySearch('', '', '', {
        page: 1,
        pageCount: 10,
      }),
    );
  };

  // User clear handler for mouse leave
  const handleUserAllowClearForMouseLeave = () => {
    if (userEmailOptions?.length === 0) {
      dispatch(
        getAllUsersByQuerySearch('', '', '', {
          page: 1,
          pageCount: 10,
        }),
      );
    }
  };

  const postSpecialPayment = async (values) => {
    dispatch(postSpecialPaymentDetails(values, navigate));
  };

  return (
    <AdminSpecialPaymentFormComponent
      userEmailOptions={userEmailOptions}
      setUserEmailOptions={setUserEmailOptions}
      userFetching={userFetching}
      handleUserSearch={handleUserSearch}
      handleUserAllowClear={handleUserAllowClear}
      handleUserAllowClearForMouseLeave={handleUserAllowClearForMouseLeave}
      editorState={editorState}
      setEditorState={setEditorState}
      postSpecialPayment={postSpecialPayment}
    />
  );
};

export default AdminSpecialPaymentFormContainer;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  DefaultPaginationValues,
  queryString,
} from '../../../Util/commonUtility';
import JobsComponent from '../../../Components/AdminComponents/Jobs';
import {
  deleteJobsById,
  editStatusById,
  getAllJobsByQueryString,
  getAllJobsWithoutPagination,
  resetJobCsvDownloadState,
} from '../../../Store/Actions/jobs';
import {setPageNumber, setPageSize} from '../../../Store/Actions/pageSize';
import dayjs from 'dayjs';

const JobsContainer = () => {
  const dispatch = useDispatch();
  const {allJobsQueryData, allJobsWithoutPagination} = useSelector(
    ({jobs}) => jobs,
  );
  const memorizedPageNumber = useSelector(
    ({pageSizes}) => pageSizes.pageNumbers.jobs,
  );
  const memorizedPageSize = useSelector(
    ({pageSizes}) => pageSizes.pageSizes.jobs,
  );

  const [jobsData, setJobsData] = useState();
  const [allJobsWithoutPaginationData, setAllJobsWithoutPaginationData] =
    useState();
  const [pagination, setPagination] = useState({
    page: memorizedPageNumber || DefaultPaginationValues.PAGE,
    pageCount: memorizedPageSize || DefaultPaginationValues.PAGE_SIZE,
    totalPages: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [sortParams, setSortParams] = useState({
    field: 'id',
    order: 'descend',
  });
  const [totalJobsCount, setTotalJobsCount] = useState(10);
  const [jobsFetchingWithoutPagination, setJobsFetchingWithoutPagination] =
    useState(false);
  const [totalJobFees, setTotalJobFees] = useState(0);
  const [paginationAndSortChanged, setPaginationAndSortChanged] =
    useState(false);
  const searchValues = useSelector(
    ({dynamicSearch}) => dynamicSearch.searchValues.jobs,
  );

  useEffect(() => {
    if (allJobsQueryData) {
      setJobsData(allJobsQueryData?.data?.jobs);
      setPagination({
        ...pagination,
        page: allJobsQueryData?.data?.currentPage,
        totalPages: allJobsQueryData?.data?.totalPage,
        totalCount: allJobsQueryData?.data?.totalCount,
      });
      setTotalJobsCount(allJobsQueryData?.data?.totalCount);
    } else {
      fetchJobs();
    }
  }, [allJobsQueryData?.data?.jobs]);
  useEffect(() => {
    if (allJobsWithoutPagination) {
      setAllJobsWithoutPaginationData(allJobsWithoutPagination?.data?.jobs);
      setTotalJobFees(allJobsWithoutPagination?.data?.totalJobFees);
    }
  }, [allJobsWithoutPagination?.data?.jobs]);
  useEffect(() => {
    if (paginationAndSortChanged) {
      const formattedSearchValues = {
        ...searchValues,
        date:
          searchValues?.date?.length > 0
            ? [
                dayjs(searchValues?.date[0]).format('YYYY-MM-DD'),
                dayjs(searchValues?.date[1]).format('YYYY-MM-DD'),
              ]
            : '',
      };
      fetchJobs(formattedSearchValues);
      setPaginationAndSortChanged(false);
    }
  }, [paginationAndSortChanged, searchValues]);
  useEffect(() => {
    if (searchValues) {
      dispatch(setPageNumber('jobs', 1));
      setPagination({
        ...pagination,
        page: 1,
      });
      setPaginationAndSortChanged(true);
    }
    dispatch(resetJobCsvDownloadState());
    setJobsFetchingWithoutPagination(false);
  }, [searchValues]);

  const handlePaginationChange = (current, pageSize) => {
    dispatch(setPageSize('jobs', pageSize));
    dispatch(setPageNumber('jobs', current));

    setPagination({
      ...pagination,
      page: current,
      pageCount: pageSize,
    });
    setPaginationAndSortChanged(true);
  };
  const handleTableChange = (pagination, filter, sorter) => {
    setSortParams({
      field: sorter.field,
      order: sorter.order,
    });
    setPaginationAndSortChanged(true);
    dispatch(resetJobCsvDownloadState());
    setJobsFetchingWithoutPagination(false);
  };

  //CRUD
  const fetchJobs = (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllJobsByQueryString(
        pagination,
        sortField,
        sortOrder,
        searchQueryData,
      ),
    );
  };

  const deleteJobs = (id) => {
    dispatch(deleteJobsById(id));
  };

  const updateStatusById = async (jobData, status) => {
    const id = jobData?.id;
    dispatch(editStatusById(id, status));
  };

  const fetchJobsWithoutPagination = async (searchValues) => {
    const sortField = sortParams.field || '';
    const sortOrder = sortParams.order || '';
    const searchQueryData = queryString(searchValues);
    dispatch(
      getAllJobsWithoutPagination(
        sortField,
        sortOrder,
        searchQueryData,
        totalJobsCount,
        setJobsFetchingWithoutPagination,
      ),
    );
  };

  return (
    <div>
      <JobsComponent
        allJobsData={jobsData}
        paging={pagination}
        handlePaginationChange={(page, pageSize) => {
          handlePaginationChange(page, pageSize);
        }}
        handleTableChange={handleTableChange}
        sortParams={sortParams}
        fetchJobs={(searchValues) => {
          fetchJobs(searchValues);
        }}
        deleteJobs={(id) => {
          deleteJobs(id);
        }}
        updateStatusById={(jobData, status) => {
          updateStatusById(jobData, status);
        }}
        searchValues={searchValues}
        fetchJobsWithoutPagination={(searchValues) => {
          fetchJobsWithoutPagination(searchValues);
        }}
        jobsFetchingWithoutPagination={jobsFetchingWithoutPagination}
        totalJobFees={totalJobFees}
        allJobsWithoutPaginationData={allJobsWithoutPaginationData}
      />
    </div>
  );
};

export default JobsContainer;

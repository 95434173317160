import {message} from 'antd';
import {httpClient} from '../../Api/client';
import Urls from '../../Constant/Urls';
import {HttpCode} from '../../Constant/HttpCode';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  SOMETHING_WENT_WRONG,
} from '../../Constant/ActionTypes';

export const postContactForm = (data, form) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      const response = await httpClient.post(Urls.CONTACT_US, data);
      if (response?.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SHOW_MESSAGE, payload: 'Thank you for contacting us!'});
        form.resetFields();
      }
    } catch (error) {
      let errorMessage;
      switch (error?.response?.status) {
        case HttpCode.InternalServerError:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        case HttpCode.Unauthorized:
          errorMessage = '';
          break;
        case HttpCode.Forbidden:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export default postContactForm;
